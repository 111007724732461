import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import {find, groupBy, get as _get} from 'lodash';

/* import helper functions */
import { get, doc, post } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions, genGradeString, parseJSONArray } from '../../../Helper/Helper';
import { momentToHtmlInput } from '../../../Helper/TimeHelper';

import { genSimpleExcel } from '../../../Helper/ExcelHelper';

/* import components */
import {
  PageHeader,
  MyTable,
  ConfirmButtonAndModal,
  MySelect,
  FullscreenDimmer,
  Buttons
} from '../../../Components';

class OutsideActivitySummary extends Component {
  constructor(props) {
    super(props);

    this.tableColumnData = [
      { headerName: '類型', width: 3, cellRender: 'outsideActivityType' },
      { headerName: '科目', width: 3, cellRender: 'subjectName' },
      { headerName: '活動名稱', width: 7, cellRender: 'name' },
      { headerName: '詳情', width: 3, cellRender: (item => (
      <>
        <Buttons.ViewButton onClick={this.viewDetail(item.id)} />
        <ConfirmButtonAndModal
          buttonProps={{
            color: 'red',
            icon: 'times',
            content: undefined,
            circular: true,
          }}
          modalProps={{
            confirmText: '刪除',
            confirmIcon: 'remove',
            description: <>確定刪除外出帶隊活動 <span className="bold">{item.name}</span>？<span className="red bold">注意：這會一併刪除學生獎項之資料</span></>
          }}
          onConfirm={this.deleteActivity(item.id)}
          onFinish={this.fetchData}
        />
      </>)) },
    ];

    this.state = {
      finishedLoading: false,
      yearOptions: [],
      yearId: '',
      outsideActivities: null,
      studentMap: new Map(),
      dimmerOpen: false
    }
  }

  setStateAsync = (state) => {
    const x = new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    });
    return x;
  }

  componentDidMount = async () => {
    this.mounted = true;
    const yearResultReady = await this.fetchYear();
    if(yearResultReady)
      await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }
  
  componentWillUnmount = () => {
    this.mounted = false;
  }

  fetchYear = async () => {
    const result = await get('getYear');
    try{
      let yearId = defaultYear(result);
      let yearOptions = selectOptions(result, 'displayName', 'id');
      await this.setStateAsync({ yearOptions, yearId });
      return true;
    }catch(err){console.log('Error when fetching year for OASummary',err)}
    return false;
  }

  fetchData = async () => {
    try{
      const outsideActivities = await get('getOutsideActivity/' + this.state.yearId);
      const students = await get('getStudentEssentialInfo/' + this.state.yearId)

      await this.setStateAsync({
        outsideActivities,
        studentMap: new Map(students.map(x=>[x.id, `${x.classCode} ${x.chiName||x.engName}`]))
      });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching data", err)}
  };

  yearChange = async (event, { value }) => {
    try{
      await this.setStateAsync({ finishedLoading: false, yearId: value });
      await this.fetchData();
    }catch(err){
      if(err!=='unmounted')console.log('Error when changing year for OASummary',err);
    }
    this.setStateAsync({ finishedLoading: true });
  }

  newOutsideActivity = () => {
    this.mounted = false;
    this.props.history.push('/eca/outsideActivity/summary/edit/' + this.state.yearId);
  }

  viewDetail = (id) => () => {
    this.mounted = false;
    this.props.history.push('/eca/outsideActivity/summary/detail/' + this.state.yearId + '/' + id);
  }

  deleteActivity = (id) => async () => {
    try{
      const result = await post('deleteOutsideActivity', {id});
      if(!result.status){
        alert('刪除時發生錯誤');
      }
    }catch(err){
      if(err!=='unmounted')console.log('Error when deleting OASummary',err);
    }
  }

  downloadAwardList = async () => {
    try{
      await this.setStateAsync({finishedLoading: false});
      const {yearId} = this.state;
      const theYear = find(this.state.yearOptions,{value: yearId}) || {text: '-'};
      await doc('generate/AwardList', {
        yearId,
      }, `${theYear.text} 年度課外活動獲獎成績`);
    }catch(err){
      console.log('Error when exporting award list',err);
    }
    this.setStateAsync({finishedLoading: true});
  }

  downloadParticipationList = async () => {
    try{
      await this.setStateAsync({dimmerOpen: true});
      const {yearId, outsideActivities, studentMap} = this.state;

      const [studentCount, teachers, awards] = (await get('getAllOutsideActivityParticulars/'+yearId)).map(x=>groupBy(x, 'outsideActivityId'));
      const year = find(this.state.yearOptions, { value: yearId });
  
      const genAwardString = ({id}) => {
        let awardString = [];
        for(let award of awards[id]||[]){
          let students = parseJSONArray(award.students);
          if(award.groupAlias || !students.length){
            awardString.push([award.groupAlias || '全體參與學生', award.eventName, award.prizeName].filter(x=>x).join(' '))
          }else{
            for(let s of students){
              awardString.push([studentMap.get(s)||'?', award.eventName, award.prizeName].filter(x=>x).join(' '))
            }
          }
        }
        return awardString.length?awardString.join("\n"):"/";
      };
  
      genSimpleExcel(outsideActivities.filter(x=>x.outsideActivityType==='比賽'), `學生參與比賽紀錄表 - ${year?year.text:''}`, [
        {width: 18, headerTitle: '日期', render: x=>momentToHtmlInput(x.date)},
        {width: 18, headerTitle: '科目', render: x=>x.subjectName||'跨學科'},
        {width: 32, headerTitle: '比賽名稱', render: 'name'},
        {width: 18, headerTitle: '參與級別', render: x=>genGradeString(x)},
        {width: 18, headerTitle: '人數', render: x=>_get(studentCount, [x.id, 0, 'studentCount'], 0)},
        {width: 18, headerTitle: '負責老師', render: x=>_get(teachers, [x.id], []).map(x=>x.shortName||x.name||"外聘導師").join("、")},
        {width: 40, headerTitle: '成績', render: genAwardString},
      ]);
    }catch(err){
      if(err!=='unmounted'){
        alert("生成學生參與比賽紀錄表時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({dimmerOpen: false});
  }

  render() {
    const {
      finishedLoading,
      yearOptions,
      yearId,
      outsideActivities,
      dimmerOpen
    } = this.state;

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='外出帶隊列表' subTitle='管理每年度的外出帶隊' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MySelect 
                disabled={!finishedLoading}
                onChange={this.yearChange}
                options={yearOptions}
                value={yearId}
              />
              <Buttons.DownloadButton
                floated='right'
                className='button-margin-bottom'
                content='校外獲獎紀錄'
                disabled={!finishedLoading}
                onClick={this.downloadAwardList}
              />
              <Buttons.DownloadButton
                floated='right'
                className='button-margin-bottom'
                content='學生參與比賽紀錄表'
                disabled={!finishedLoading}
                onClick={this.downloadParticipationList}
              />
              <Buttons.AddButton
                floated='right'
                className='button-margin-bottom'
                content='新增外出帶隊'
                disabled={!finishedLoading}
                onClick={this.newOutsideActivity}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MyTable
                tableColumnData={this.tableColumnData}
                data={outsideActivities}
                noDataMsg="找不到活動資料"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <FullscreenDimmer active={dimmerOpen} isLoading={true} />
      </>
    )
  }
}

export default withRouter(OutsideActivitySummary);