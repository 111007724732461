import React, { useState, useMemo, useReducer, useCallback, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { find, get as _get, pick, mapValues, findIndex } from 'lodash';

import {
	PageHeader,
	ConfirmModal,
	FileInputInstantUpload,
	FullscreenDimmer,
	MySortableTable,
	MySelect,
	Buttons,
	BlockerPrompt,
} from '../../Components';

import { get, post } from '../../Helper/ApiHelper';
import { selectOptions, defaultYear, whetherReadOnlyForTeacher } from '../../Helper/Helper';
import { inputHandler } from '../../Helper/FormHelper';
import { exportExcel } from '../../Helper/ExcelHelper';
import { floatRight } from '../../Helper/StyleHelper';
import { useValidator } from '../../Helper/HookHelper';

import { excelFormats } from '../../Const/Const';
import { User } from '../../Context';

import { Grid, Modal, Form, Segment, Checkbox, Input } from 'semantic-ui-react';

const excelCols = [
	{
		headerName: '職位',
		cellRender: 'displayName',
	},
	{
		headerName: '姓名',
		cellRender: 'name',
	},
	{
		headerName: '電郵',
		cellRender: 'email',
	},
	{
		headerName: '簡稱',
		cellRender: 'shortName',
	},
	{
		headerName: '編號',
		cellRender: 'index',
	},
	{
		headerName: '職級',
		cellRender: 'rank',
	},
	{
		headerName: '班別',
		cellRender: 'className',
	},
	{
		headerName: '職務',
		cellRender: 'duty',
	},
];

const Teacher = () => {
	const [validator] = useValidator({
		messages: {
			email: '請輸入有效的電郵',
			required: '請輸入:attribute',
		},
	});
	const history = useHistory();
	const { pingUser, userId } = useContext(User);
	const [loadState, setLoadState] = useState({ isLoading: true, dimmerOpen: false, isBlocking: false });
	const [reload, setReload] = useState(0);
	const [modalState, setModalState] = useState({
		id: null,
		edit: false,
		activate: false,
		inactivate: false,
		delete: false,
	});
	const [positions, setPositions] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [years, setYears] = useState({ data: [], options: [] });
	const [year, setYear] = useState({ yearId: null, readOnly: true });
	const [classes, setClasses] = useState([]);
	const [editData, setEditData] = useState({
		id: null,
		positionId: null,
		name: '',
		shortName: '',
		email: '',
		index: null,
		rank: '',
		classId: null,
		duty: '',
	});
	const [tableDisplay, setTableDisplay] = useReducer(
		(state) => ({
			showHidden: !state.showHidden,
			genRowClassName: !state.showHidden
				? (x) => (x.status ? '' : 'left-school')
				: (x) => (x.status ? '' : 'hidden-row'),
		}),
		{ showHidden: false, genRowClassName: (x) => (x.status ? '' : 'hidden-row') }
	);

	useEffect(() => {
		setLoadState((x) => ({ ...x, loading: true, isBlocking: false }));
		const abortController = new AbortController();
		const f = async () => {
			try {
				const [positions, years, classes] = await Promise.all(['getPosition', 'getYear', 'getClass'].map(get));
				setModalState({ edit: false, delete: false });
				setYears({
					data: years,
					options: selectOptions(years, 'displayName', 'id'),
				});
				setPositions(selectOptions(positions, 'displayName', 'id'));
				setClasses(classes);
				if (!year.yearId) {
					setYear({
						yearId: defaultYear(years),
						readOnly: true,
					});
				}
			} catch (err) {
				if (err.name !== 'AbortError') console.log(err);
			}
		};
		f();
		return () => {
			abortController.abort();
		};
	}, [reload]); // deliberately omit year.yearId because we'll only do it once.

	useEffect(() => {
		if (!years.data.length || !year.yearId) return;
		setLoadState((x) => ({ ...x, loading: true, isBlocking: false }));
		setYear((x) => ({
			...x,
			readOnly: whetherReadOnlyForTeacher(years.data, x.yearId),
		}));
		const abortController = new AbortController();
		const fetchTeachers = async () => {
			try {
				let [teachers, teacherYearDuty] = await Promise.all(
					['getAllTeacherWithAdminWithIndex', 'getTeacherYearDuty'].map((x) => get(x, [year.yearId]))
				);
				const classMap = new Map(classes.map((x) => [x.name, x.id]));
				const dutyMap = new Map(teacherYearDuty.map((x, i) => [x.teacherId, i]));
				setTeachers(
					teachers.map((t) =>
						dutyMap.has(t.id)
							? {
									...t,
									...pick(teacherYearDuty[dutyMap.get(t.id)], ['className', 'duty']),
									classId: classMap.get(teacherYearDuty[dutyMap.get(t.id)].className),
							  }
							: t
					)
				);

				setLoadState((x) => mapValues(x, () => false));
			} catch (err) {
				if (err.name !== 'AbortError') {
					console.log(err);
					setLoadState((x) => mapValues(x, () => false));
				}
			}
		};
		fetchTeachers();
		return () => {
			abortController.abort();
		};
	}, [years, year.yearId]);

	const modalToggle = useCallback(
		(eventOrStateName) => {
			let modalData = {};
			if (typeof eventOrStateName === 'object') {
				if (eventOrStateName.target.classList.contains('modals')) {
					modalData = eventOrStateName.target.firstElementChild.dataset;
				} else {
					modalData = eventOrStateName.target.closest('.ui, button').dataset;
				}
			} else {
				modalData = {
					modalname: eventOrStateName,
				};
			}
			const { modalname, id } = modalData;

			if (modalState[modalname]) {
				validator.hideMessages();
			}

			let editData = {
				id: null,
				positionId: null,
				name: '',
				shortName: '',
				email: '',
				index: null,
				rank: '',
				classId: null,
				duty: '',
			};

			if (id) {
				const obj = find(teachers, { id: +id });
				if (obj) {
					editData = Object.assign(
						{},
						pick(editData, ['positionId', 'name', 'shortName', 'email', 'index', 'rank', 'classId', 'duty']),
						obj
					);
				}
			}

			setLoadState((x) => ({ ...x, isBlocking: false }));
			setEditData(editData);
			setModalState((x) => ({ ...x, [modalname]: !x[modalname] }));
		},
		[teachers, modalState, validator]
	);

	const tableColumnProps = useMemo(
		() => [
			{
				headerName: '職位',
				columnName: 'displayName',
				cellRender: 'displayName',
				width: 4,
				sortMethod: 'positionId',
				allowSort: true,
			},
			{
				headerName: '姓名',
				columnName: 'name',
				cellRender: (x) => (x.index ? `${x.index}. ` : '') + `${x.name}` + (x.shortName ? ` (${x.shortName})` : ''),
				width: 4,
				sortMethod: 'index',
				allowSort: true,
			},
			{
				headerName: '電郵',
				columnName: 'email',
				cellRender: 'email',
				width: 4,
				allowSort: true,
			},
			{
				headerName: '職級',
				columnName: 'rank',
				cellRender: 'rank',
				width: 2,
				allowSort: true,
			},
			{
				headerName: '班別/職務',
				columnName: 'duty',
				cellRender: (x) => x.className || x.duty || '-',
				width: 3,
				allowSort: true,
			},
			{
				headerName: '行動',
				cellClassName: 'textlessCell',
				columnName: 'action',
				cellRender: (t) =>
					// t.positionId === 1 ? "-" :
					t.status ? (
						<>
							<Buttons.EditButton data-modalname="edit" data-id={t.id} onClick={modalToggle} />
							<Buttons.DeleteButton
								title="設為離校"
								data-modalname="inactivate"
								data-id={t.id}
								color="orange"
								icon="eye slash"
								onClick={modalToggle}
							/>
						</>
					) : (
						<>
							<Buttons.EditButton disabled />
							<Buttons.AddButton
								title="取消離校"
								data-modalname="activate"
								data-id={t.id}
								icon="eye"
								onClick={modalToggle}
							/>
						</>
					),
				width: 4,
			},
		],
		[modalToggle]
	);

	const inputChange = useCallback((event, data) => {
		const { inputType, stateName } = event.target.closest('.ui, textarea').dataset;
		setEditData((d) => ({
			...d,
			[stateName]: inputHandler(inputType, data),
		}));
		setLoadState((x) => ({ ...x, isBlocking: true }));
	}, []);

	const yearChange = useCallback((event, data) => {
		setYear((x) => ({ ...x, yearId: inputHandler('select', data) }));
	}, []);

	const downloadData = useCallback(() => {
		const f = async () => {
			try {
				const curYear = find(years.data, { id: year.yearId });
				let filename = (curYear ? curYear.displayName + '年度' : '') + '教師資料';
				let data = [excelCols.map((x) => x.headerName)];

				data = data.concat(
					teachers
						.filter((t) => t.status)
						.map((t) => excelCols.map((f) => (typeof f.cellRender === 'function' ? f.cellRender(t) : t[f.cellRender])))
				);

				const { fromBlankAsync } = (await import('xlsx-populate')).default;
				const workbook = await fromBlankAsync();
				const sheet = workbook.sheet(0);
				sheet.cell('A1').value(data);
				sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true } });
				sheet.range('A1:I1').style({ bold: true }).style('fill', 'dddddd');

				sheet
					.cell('K2')
					.value([['注意：電郵不可留空或重複'], ['職位不可留空並必須是以下的一個']])
					.style({ bold: true, fontColor: 'ff0000' });
				sheet.cell('K4').value(positions.map((x) => [x.text]));
				sheet
					.cell(positions.length + 4, 'K')
					.value('留空的資料將不會處理')
					.style({ bold: true, fontColor: 'ff0000' });
				sheet.column('K').width(37.5);

				const file = await workbook.outputAsync();
				exportExcel(file, filename);
			} catch (err) {
				console.log(err, 'while downloading teacher info');
			}
		};
		f();
	}, [years, year.yearId, teachers, positions]);

	const classOptions = useMemo(() => {
		const classWithTeacher = new Map(teachers.filter((x) => x.classId).map((x) => [x.classId, x]));
		return classes.map((x) => ({
			disabled: classWithTeacher.has(x.id) && editData.id !== classWithTeacher.get(x.id).id,
			text: classWithTeacher.has(x.id) ? `${x.name} (${classWithTeacher.get(x.id).name})` : x.name,
			value: x.id,
		}));
	}, [classes, teachers, editData.id]);

	const rePing = useCallback(
		({ path, signal }) => {
			if (editData.id === userId) {
				pingUser({ path, signal }).then((r) => {
					if (r) setReload((x) => x + 1);
					else {
						setLoadState((x) => ({ ...x, isBlocking: false }));
						alert('抱歉, 你沒有存取本頁的權限');
						history.push('/');
						return;
					}
				});
			} else {
				setReload((x) => x + 1);
			}
		},
		[editData.id, userId]
	);

	const actInactDel = useCallback(
		(event) => {
			const { modalname } = event.target.closest('.ui').dataset;
			setLoadState((x) => ({ ...x, dimmerOpen: true }));
			const abortController = new AbortController();
			const f = async () => {
				try {
					const result = await post(
						modalname !== 'delete' ? 'editTeacherStatus' : 'deleteTeacher',
						{ id: editData.id, status: modalname === 'activate' || null },
						'JSON',
						abortController
					);
					if (_get(result, 'status')) {
						rePing({
							path: '/setting/teacher',
							signal: abortController.signal,
						});
					} else {
						setLoadState((x) => ({ ...x, dimmerOpen: false }));
					}
				} catch (err) {
					if (err.name !== 'AbortError') console.log(err);
				}
			};
			f();
			return () => {
				abortController.abort();
			};
		},
		[editData.id, rePing]
	);

	const save = () => {
		if (!validator.allValid()) {
			validator.showMessages();
			return;
		}
		setLoadState((x) => ({ ...x, dimmerOpen: true }));
		const abortController = new AbortController();
		const f = async () => {
			try {
				const result = await post('editTeacherWithDuty', { data: editData, yearId: year.yearId });
				if (_get(result, 'status')) {
					rePing({
						path: '/setting/teacher',
						signal: abortController.signal,
					});
				} else {
					setLoadState((x) => ({ ...x, dimmerOpen: false }));
				}
			} catch (err) {
				if (err.name !== 'AbortError') console.log(err);
			}
		};
		f();
		return () => {
			abortController.abort();
		};
	};

	const fileHandling = async (xlsFile) => {
		//file parse
		const reader = new FileReader();
		const { fromDataAsync, RichText } = (await import('xlsx-populate')).default;
		const abortController = new AbortController();
		return new Promise((res, rej) => {
			reader.onload = (e) => {
				fromDataAsync(e.target.result).then(res).catch(rej);
			};
			reader.onerror = (evt) => {
				console.log('err', evt);
				rej('讀取檔案時發生錯誤');
			};
			// more stable than binary strings
			reader.readAsArrayBuffer(xlsFile);
		})
			.then((workbook) => {
				let worksheet = workbook.sheet(0).usedRange().value();
				if (JSON.stringify(worksheet[0].filter((x) => !!x)) !== JSON.stringify(excelCols.map((x) => x.headerName))) {
					throw '這應該不是教師資料';
				}
				const result = {
					yearId: year.yearId,
					data: [],
				};
				const emailSet = new Set();
				let errors = [];
				const positionMap = new Set(positions.map((x) => x.text));
				for (let r of worksheet.slice(1)) {
					const row = r.map((x) => {
						const _x = x instanceof RichText ? x.text() : x;
						return typeof _x === 'string' ? _x.trim() : _x;
					});
					const resultRow = {};
					const nameIndex = findIndex(excelCols, { headerName: '姓名' });
					if (!row[nameIndex]) break;
					const posIndex = findIndex(excelCols, { headerName: '職位' });
					if (!row[posIndex] || !positionMap.has(String(row[posIndex]).trim())) {
						errors.push(`${row[nameIndex]}老師的職位(${row[posIndex]})無效`);
					}
					const emailIndex = findIndex(excelCols, { headerName: '電郵' });
					if (!row[emailIndex]) {
						errors.push(`${row[nameIndex]}老師沒有電郵`);
					} else {
						if (emailSet.has(row[emailIndex])) {
							errors.push(`${row[nameIndex]}老師電郵(${row[emailIndex]})重複`);
						}
					}
					emailSet.add(row[emailIndex]);
					if (row[0] !== undefined) resultRow.position = String(row[0]).trim();
					for (let col = 1; col < excelCols.length; col++) {
						resultRow[excelCols[col].cellRender] = row[col] === undefined ? null : row[col];
					}
					resultRow.status = 1;
					if (resultRow.position && resultRow.email) result.data.push(resultRow);
				}

				if (errors.length) throw errors;
				return post('batchEditTeacher/', result, 'JSON', abortController);
			})
			.then((response) => {
				if (response && response.status) {
					rePing({
						path: '/setting/teacher',
						signal: abortController.signal,
					});
					return { status: true };
				} else {
					console.log('teacher batch failed', response);
					return { status: false };
				}
			})
			.catch((err) => {
				console.log('Error when batch processing teachers');
				console.log(err);
				return { status: false, err };
			});
	};

	return (
		<>
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<PageHeader title="教師管理" subTitle="設定教師資料" />
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column>
						<MySelect
							disabled={loadState.isLoading}
							options={years.options}
							value={year.yearId}
							onChange={yearChange}
						/>
						<Checkbox
							disabled={loadState.isLoading}
							label="顯示已離校教師"
							checked={tableDisplay.showHidden}
							onChange={setTableDisplay}
							data-input-type="checkbox"
							data-state-name="showHidden"
							className="margin-left-1rem"
						/>
						<Buttons.DownloadButton
							content="下載教師資料"
							floated="right"
							onClick={downloadData}
							disabled={loadState.isLoading}
						/>
						<FileInputInstantUpload
							disabled={loadState.isLoading || year.readOnly}
							buttonColor="green"
							buttonText="上載教師檔案"
							formatErrorText="請上載正確格式(xlsx)"
							accept={excelFormats}
							wrapperStyle={floatRight}
							fileHandling={fileHandling}
						/>
						<Buttons.AddButton
							disabled={loadState.isLoading || year.readOnly}
							content="新增教師"
							floated="right"
							data-modalname="edit"
							onClick={modalToggle}
						/>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column>
						<MySortableTable
							data={teachers}
							tableColumnData={tableColumnProps}
							genRowClassName={tableDisplay.genRowClassName}
							finishedLoading={!loadState.isLoading}
							compact={true}
							unstackable={true}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>

			{loadState.dimmerOpen ? (
				<FullscreenDimmer active={true} isLoading={true} />
			) : (
				<>
					<Modal
						open={modalState.edit}
						data-modalname="edit"
						onClose={modalToggle}
						closeOnEscape={false}
						closeOnDimmerClick={false}
					>
						<Modal.Header>請輸入教師資料</Modal.Header>
						<Modal.Content>
							<Segment basic>
								<Form>
									<Form.Group className="form-group-margin">
										<Form.Field width={6} required>
											<label>姓名</label>
											<Input
												value={editData.name || ''}
												onChange={inputChange}
												data-input-type="text"
												data-state-name="name"
												placeholder="姓名"
											/>
											{validator.message('姓名', editData.name, 'required')}
										</Form.Field>
										<Form.Field width={5} required>
											<label>簡稱</label>
											<Input
												value={editData.shortName || ''}
												onChange={inputChange}
												data-input-type="text"
												data-state-name="shortName"
												placeholder="簡稱"
											/>
											{validator.message('簡稱', editData.shortName, 'required')}
										</Form.Field>
										<Form.Field width={5}>
											<label>編號</label>
											<Input
												type="number"
												value={editData.index || ''}
												onChange={inputChange}
												data-input-type="text"
												data-state-name="index"
												disabled={!!year.readOnly}
												placeholder="編號"
											/>
										</Form.Field>
									</Form.Group>

									<Form.Group className="form-group-margin">
										<Form.Field width={6} required>
											<label>電郵</label>
											<Input
												value={editData.email || ''}
												onChange={inputChange}
												data-input-type="text"
												data-state-name="email"
												placeholder="電郵"
											/>
											{validator.message('電郵', editData.email, 'required|email')}
										</Form.Field>
										<Form.Field width={5} required>
											<label>職級</label>
											<Input
												value={editData.rank || ''}
												onChange={inputChange}
												data-input-type="text"
												data-state-name="rank"
												placeholder="職級"
											/>
											{validator.message('職級', editData.rank, 'required')}
										</Form.Field>
										<Form.Field width={5} required>
											<label>職位</label>
											<MySelect
												value={editData.positionId}
												onChange={inputChange}
												data-input-type="text"
												data-state-name="positionId"
												options={positions}
												placeholder="職位"
											/>
											{validator.message('職位', editData.positionId, 'required')}
										</Form.Field>
									</Form.Group>
									<div className="form-group-margin" />
									<Form.Group widths={2}>
										<Form.Field>
											<label>今年擔任班別</label>
											<MySelect
												value={editData.classId}
												onChange={inputChange}
												data-input-type="text"
												data-state-name="classId"
												options={classOptions}
												disabled={!!editData.duty || year.readOnly}
												placeholder="擔任班別 (請清除年度職務欄位)"
												clearable
											/>
										</Form.Field>
										<Form.Field>
											<label>今年職務 (如非班主任)</label>
											<Input
												value={editData.duty || ''}
												onChange={inputChange}
												data-input-type="text"
												data-state-name="duty"
												disabled={!!editData.classId || year.readOnly}
												placeholder="年度職務 (請清除擔任班別欄位)"
											/>
										</Form.Field>
									</Form.Group>
								</Form>
							</Segment>
						</Modal.Content>
						<Modal.Actions>
							<Buttons.CancelButton data-modalname="edit" onClick={modalToggle} />
							<Buttons.SaveButton onClick={save} />
						</Modal.Actions>
					</Modal>

					<ConfirmModal
						open={modalState.activate}
						description="確定將教師轉回為「非離校」並預設顯示？"
						modalname="activate"
						cancel={modalToggle}
						confirm={actInactDel}
						confirmText="取消離校"
						confirmIcon="eye"
					/>
					<ConfirmModal
						open={modalState.inactivate}
						description="確定將教師轉為「已離校」並預設隱藏？"
						modalname="inactivate"
						cancel={modalToggle}
						confirm={actInactDel}
						confirmText="離校"
						confirmIcon="eye slash"
					/>
					<ConfirmModal
						open={modalState.delete}
						description="確定刪除教師？"
						modalname="delete"
						cancel={modalToggle}
						confirm={actInactDel}
					/>
				</>
			)}
			<BlockerPrompt isBlocking={loadState.isBlocking} />
		</>
	);
};

export default Teacher;
