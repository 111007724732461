import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Buttons,
  MySelect,
  PageHeader,
} from '../../../Components';

import { get } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../Helper/Helper';
import { displayDate } from '../../../Helper/TimeHelper';

import { Grid, Card, Segment, Header } from 'semantic-ui-react';

class OutsideActivitySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,
      yearOptions: [],
      selectedYear: '',
      outsideActivities: [],
    }
  }

  setStateAsync = (state) => {
    const x = new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    });
    return x;
  }

  fetchYear = async () => {
    try{
      const result = await get('getYear');
      await this.setStateAsync({
        yearOptions: selectOptions(result, 'displayName', 'id'),
        selectedYear: defaultYear(result)
      });
    }catch(err){console.log('Error when fetching year for OAPersonal',err)}
  }

  fetchData = async () => {
    try{
      const outsideActivities = await get('getPersonalOutsideActivity/' + this.state.selectedYear);
      for(let oA of outsideActivities){
        oA.date = displayDate(oA.date);
      }
      await this.setStateAsync({
        outsideActivities,
      });
    }catch(err){console.log('Error when fetching year for OAPersonal',err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchYear();
    await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }
  
  componentWillUnmount = () => {
    this.mounted = false;
  }

  yearChange = async (event, { value }) => {
    try{
      await this.setStateAsync({ finishedLoading: false, selectedYear: value });
      await this.fetchData();
    }catch(err){
      if(err!=='unmounted')console.log('Error when changing year for OASummary',err);
    }
    this.setStateAsync({ finishedLoading: true });
  }

  newOutsideActivity = () => {
    this.mounted = false;
    this.props.history.push('/eca/outsideActivity/personal/edit/' + this.state.selectedYear);
  }

  viewDetail = (event) => {
    const {itemId} = event.target.closest('a').dataset;
    this.props.history.push('/eca/outsideActivity/personal/detail/' + this.state.selectedYear + '/' + itemId);
  }

  render() {
    const {
      finishedLoading,
      yearOptions,
      selectedYear,
      outsideActivities,
    } = this.state;

    return (
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='外出帶隊列表' subTitle='管理每年度的外出帶隊' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <MySelect onChange={this.yearChange} options={yearOptions} value={selectedYear} />
            <Buttons.AddButton
              floated='right'
              className='button-margin-bottom'
              content='新增外出帶隊'
              disabled={!finishedLoading}
              onClick={this.newOutsideActivity}
            />
          </Grid.Column>
        </Grid.Row>

        {finishedLoading ? ( outsideActivities.length > 0 ? (
            <Grid.Row columns={2}>
              {outsideActivities.map(({ id, date, name }) => (
                <Grid.Column key={id} className='form-group-margin'>
                  <Card
                    key={id}
                    data-item-id={id}
                    fluid
                    raised
                    header={name}
                    meta={date}
                    onClick={this.viewDetail}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Column>
                <Segment textAlign='center' basic>
                  <Header icon>本年度沒有負責的外出活動</Header>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          )
        ) : (
          <Grid.Row>
            <Grid.Column>
              <Segment textAlign='center' basic>
                <Header icon>載入資料中</Header>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    )
  }
}

export default withRouter(OutsideActivitySummary);