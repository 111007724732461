import { find, mapValues, get as _get, throttle } from 'lodash';
import moment from 'moment';
import React, { useState, useReducer, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
	PageHeader,
	ConfirmModal,
  MySortableTable,
  MySelect,
  FullscreenDimmer,
  Buttons
} from '../../Components';

import { get, post } from '../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../Helper/Helper';
import { momentToDate } from '../../Helper/TimeHelper'; //consent time display
import { checkHasSearchOn } from '../Talent/helper';

import { Grid, Modal, Form, Table, Button, Segment, Checkbox, Icon, Accordion } from 'semantic-ui-react';

const filterReducer = (state, data) => {
  const { category, checked } = data;
  switch (category) {
    case 'class':
      if (checked) {
        return {
          ...state,
          gradeClass: {
            ...state.gradeClass,
            [data.gradeId]: {
              ...state.gradeClass[data.gradeId],
              [data.classId]: true
            }
          }
        }
      } else {
        const gradeInfo = { ...state.gradeClass[data.gradeId] };
        gradeInfo[data.classId] = false;
        if (Object.values(gradeInfo).some(x => x)) {
          return {
            ...state,
            gradeClass: {
              ...state.gradeClass,
              [data.gradeId]: gradeInfo
            }
          }
        }
        return {
          ...state,
          gradeClass: {
            ...state.gradeClass,
            [data.gradeId]: undefined
          }
        }
      }
    case 'classGrade':
      return {
        ...state,
        gradeClass: {
          ...state.gradeClass,
          [data.gradeId]: checked ? JSON.parse(data.classes).reduce((prev, cur) => ({ ...prev, [cur]: true }), {}) : undefined
        }
      }
    case 'showHidden':
      return {
        ...state,
        showHidden: !state.showHidden
      }
    default: return state;
  }
}

const Consent = (props) => {
  const [yearOptions, setYearOptions] = useState([]);
  const [reload, setReload] = useReducer(x=>!x, 0);
  const [yearInfo, setYearInfo] = useState({id: '', isPast: true});
  const [loadState, setLoadState] = useState({finished: false, dimmerOpen: false});
  const [showFilter, setShowFilter] = useReducer(x=>!x, true);
  const [data, setData] = useState({
    senInfo: [],
    strnOptions: [],
  });
  const [grade, setGrade] = useState([]);
  const [classMap, setClassMap] = useState(new Map());

  const [modalOpen, setModalOpen] = useState({add: false, delete: false, id: null});
  const [studentId, setStudentId] = useState(null);
  const [searchFilter, setFilter] = useReducer(filterReducer, {
    showHidden: false,
    grade: {},
    gradeClass: {},
  });

  useEffect(()=>{
    const abortController = new AbortController();
    const f = async () => {
      try{
        const years = await get('getYear', [], abortController);
        const grade = await get('getGrade', [], abortController);
        const classArray = await get('getClass', [], abortController);
        const classMap = new Map();
        for (let c of classArray) {
          if (!classMap.has(c.grade)) {
            classMap.set(c.grade, []);
          }
          classMap.get(c.grade).push(c);
        }
        setGrade(grade);
        setClassMap(classMap);
        const yearId = defaultYear(years);
        const curYear = find(years, { id: yearId });
        setYearOptions(years.map(x=>({key: x.id, value: x.id, text: x.displayName, 'data-end-date': x.endDate})))
        setYearInfo({
          id: defaultYear(years),
          isPast: moment(curYear.endDate).isBefore(moment())
        })
      }catch(err){}
    }
    f();
    return () => {
      abortController.abort();
    }
  }, []);

  const yearChange = useCallback((event, {value})=> {
    setYearInfo({
      id: value,
      isPast: moment(find(yearOptions, { value })['data-end-date']).isBefore(moment())
    });
    setLoadState(x=>({...x, finished: false}))
  }, [yearOptions])

  useEffect(()=>{
    const f = async () => {
      if(!yearInfo.id) return;
      try{
        const [senInfo, strnInfo] = await Promise.all(['getSenStudentByYear/', 'getnonSenStudent/'].map(x=>get(x+yearInfo.id)));
        setData({
          senInfo,
          strnOptions: selectOptions(strnInfo.filter(x=>x.status), x=>`${x.strn} (${[x.chiName, x.engName].filter(x=>x).join(",")})`, 'id')
        })
      }catch(err){}
    }
    f().catch().finally(()=>setLoadState({finished: true, dimmerOpen: false}));
  }, [yearInfo.id, reload]);

  const tableColumnData = useMemo(()=>[
    { headerName: 'STRN', width: 2, allowSort: true, cellRender: 'strn' },
    { headerName: '班別', width: 2, allowSort: true, cellRender: 'classCode', sortMethod: 'classId' },
    { headerName: '中文姓名', width: 3, allowSort: true, cellRender: 'chiName' },
    { headerName: '英文姓名', width: 3, allowSort: true, cellRender: 'engName' },
    { headerName: '家長同意', width: 2, allowSort: true, cellRender: x=>x.consent == null ? '未有' : x.consent ? '同意' : '不同意' },
    { headerName: '簽署日期', width: 2, allowSort: true, cellRender: x=>x.consent == null ? '未有' : x.signDate ? momentToDate(x.signDate) : '未有' },
    { headerName: '已完成', width: 2, allowSort: true, cellRender: x=><Icon name={x.complete ? 'check' : 'x'} /> },
    { headerName: '行動', width: 2, cellClassName: 'textlessCell', cellRender: yearInfo.isPast ? x=>(
      <Link to={`/sen/record/${x.studentId}`}>
        <Button color='blue' icon='eye' circular />
      </Link>) : x=>(<>
        <Link to={`/sen/record/${x.studentId}`}>
          <Buttons.ViewButton />
        </Link>
        <Buttons.DeleteButton
          data-modalname='delete'
          data-selected-id={x.id}
          onClick={modalToggle}
        />
      </>)}
  ], [yearInfo.isPast]);

  const genRowClassName = useMemo(()=>{
    return searchFilter.showHidden ? x=>x.status?"":"left-school" : x=>x.status?"":"hidden-row";
  }, [searchFilter.showHidden]);

  const modalToggle = useCallback((eventOrStateName) => {
    let modalData = {};
    if(typeof eventOrStateName === 'object'){
      if(eventOrStateName.target.classList.contains('modals')){
        modalData = eventOrStateName.target.firstElementChild.dataset;
      }else{
        modalData = eventOrStateName.target.closest('.ui, button').dataset;
      }
    }
    const {modalname, selectedId} = modalData;
    setModalOpen(x=>({...x, [modalname]: !x[modalname], id: selectedId || null}));
  }, [])

  const add = useCallback(()=>{
    setLoadState({dimmerOpen: true, finished: false});
    post('editSenStudent', {studentId}).then(result=>{
      if(result && result.status){
        setModalOpen(x=>mapValues(x, _=>false));
        setReload();
      }else{
        return Promise.reject();
      }
    }).catch(err=>{
      alert("儲存SEN學生時發生錯誤");
    }).finally(x=>{
      setLoadState(x=>({...x, finished: true}));
    })
  }, [studentId]);

  const del = useCallback(()=>{
    setLoadState({dimmerOpen: true, finished: false});
    post('deleteSenStudent', {id: modalOpen.id}).then(result=>{
      if(result && result.status){
        setModalOpen(x=>mapValues(x, _=>false));
        setReload();
      }else{
        return Promise.reject();
      }
    }).catch(err=>{
      alert("刪除SEN學生時發生錯誤");
    }).finally(x=>{
      setLoadState(x=>({...x, finished: true}));
    })
  }, [modalOpen.id]);

  const changeStudentId = useCallback((event, data)=>{
    setStudentId(data.value)
  }, []);

  const throttledInputHandler = throttle((event, data) => {
    switch (data['data-category']) {
      case 'class':
        setFilter({
          category: 'class',
          checked: data.checked,
          gradeId: +data['data-grade-id'],
          classId: +data.name,
        });
        return;
      case 'classGrade':
        setFilter({
          category: 'classGrade',
          checked: data.checked,
          classes: data['data-classes'],
          gradeId: +data.name,
        });
        return;
      default:
        setFilter({
          category: data['data-category'],
          checked: data.checked,
        });
        return;
    }
  }, 300);

  const filteredData = useMemo(() => {
    const hasSearchOn = checkHasSearchOn(searchFilter);
    if (!hasSearchOn) return data.senInfo;
    const _gradeClass = Object.values(searchFilter.gradeClass).filter(x => x).reduce((prev, cur) => Object.assign({}, prev, cur), {});
    const correctedGradeClass = Object.keys(_gradeClass).filter(x => _gradeClass[x]).map(x => +x);
    return data.senInfo.filter(x => {
      if (correctedGradeClass.length && correctedGradeClass.indexOf(x.classId) === -1) {
        return false;
      }
      return true;
    });
  }, [data.senInfo, searchFilter]);

  return (<>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <PageHeader title='學生記錄' />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Segment padded>
            <Form.Group className='button-margin-bottom'>
              <MySelect
                onChange={yearChange}
                options={yearOptions}
                value={yearInfo.id}
                placeholder='年度'
                disabled={!loadState.finished}
              />
              <Checkbox
                label='顯示中途離校學生'
                checked={searchFilter.showHidden}
                onChange={throttledInputHandler}
                data-category='showHidden'
                className="margin-left-1rem"
              />
              {!yearInfo.isPast && (
                <Buttons.AddButton
                  content='新增學生記錄'
                  floated='right'
                  data-modalname='add'
                  onClick={modalToggle}
                  disabled={!loadState.finished}
                />
              )}
            </Form.Group>
            <Form.Group>
              <Accordion fluid>
                <Accordion.Title active={showFilter} onClick={setShowFilter} className='ui talent_accordion'><strong>{showFilter ? '隱藏' : '展開'}篩選器 (預設顯示所有記錄)</strong><Icon name='dropdown' /></Accordion.Title>
                <Accordion.Content active={showFilter}>
                  <Form.Group inline className='talent_search_field'>
                    <Table celled fixed>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>級別</Table.HeaderCell>
                          {grade.map(x => <Table.HeaderCell key={x.id}><Form.Checkbox
                            disabled={!loadState.finished}
                            data-category='classGrade'
                            data-classes={JSON.stringify(classMap.has(x.id) ? classMap.get(x.id).map(x => x.id) : [])}
                            name={"" + x.id}
                            label={x.chiName || x.engName}
                            checked={!!searchFilter.gradeClass[x.id]}
                            onChange={throttledInputHandler}
                          /></Table.HeaderCell>)}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>班別</Table.Cell>
                          {grade.map(x => <Table.Cell key={x.id}>{classMap.has(x.id) && searchFilter.gradeClass[x.id] ? classMap.get(x.id).map(x => <Form.Checkbox
                            key={x.id}
                            disabled={!loadState.finished}
                            data-category='class'
                            data-grade-id={x.grade}
                            name={"" + x.id}
                            label={x.name}
                            checked={!!_get(searchFilter, ['gradeClass', x.grade, x.id], false)}
                            onChange={throttledInputHandler}
                          />) : '-'}</Table.Cell>)}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Form.Group>
                </Accordion.Content>
              </Accordion>
            </Form.Group>
          </Segment>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <MySortableTable
            data={filteredData}
            tableColumnData={tableColumnData}
            unstackable={true}
            genRowClassName={genRowClassName}
            finished={loadState.finished}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    {loadState.dimmerOpen ? <FullscreenDimmer active={true} isLoading={true} /> : (
      <Modal open={modalOpen.add} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>請選擇學生</Modal.Header>
        <Modal.Content>
          <Segment basic>
            <Form>
              <Form.Group className='form-group-margin' grouped>
                <Form.Field>
                  <label>STRN或中英文名稱</label>
                  <MySelect
                    search
                    options={data.strnOptions}
                    value={studentId}
                    onChange={changeStudentId}
                    data-input-type='select'
                    data-state-name='addStudentId'
                    noResultsMessage="找不到非SEN學生"
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Buttons.CancelButton data-modalname='add' onClick={modalToggle} />
          <Buttons.AddButton long disabled={!studentId} onClick={add} />
        </Modal.Actions>
      </Modal>
    )}

    <ConfirmModal
      open={modalOpen.delete}
      closeOnEscape
      closeOnDimmerClick
      description='確定刪除學生記錄？'
      modalname='delete'
      cancel={modalToggle}
      confirm={del}
    />
  </>)
}

export default Consent;