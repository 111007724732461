/* import react */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import ErrorLabel from '../../../Components/ErrorLabel';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import SignModal from '../../../Components/SignModal';

/* import helper */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import { markSelectOptions, selectOptions } from '../../../Helper/Helper';
import { momentToDate, htmlInputDate } from '../../../Helper/TimeHelper';

/* Appraisal css */
import '../../../Styles/Appraisal.css';

/* import lodash */
import { map, filter, find, orderBy } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import {
	Grid,
	Button,
	Segment,
	Form,
	TextArea,
	Label,
	GridColumn,
	GridRow,
	SegmentGroup,
	FormGroup,
	FormInput,
	FormSelect,
	Icon,
	Checkbox,
} from 'semantic-ui-react';

const tableColor = ['#FFFFFF', '#eaeaea', '#cfcfcf'];

const EditBefore = (props) => {
	const history = useHistory();
	const FileRef = useRef();
	const [, forceUpdate] = useState();
	const validator = useRef(
		new SimpleReactValidator({
			autoForceUpdate: { forceUpdate: () => forceUpdate(1) },
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				default: '請輸入資料',
			},
		})
	);

	const { section, teacherId, appraisalItemId, appraisalTargetId } = useParams();
	const [loading, setLoading] = useState(false);
	const [teacherInfo, setTeacherInfo] = useState({});
	const [appraisalBefore, setAppraisalBefore] = useState({});
	const [files, setFiles] = useState([]);
	const [appraisalItem, setAppraisalItem] = useState({});
	const [appraisalTarget, setAppraisalTarget] = useState({});
	const [markingTeachers, setMarkingTeachers] = useState([]);
	const [appraisalContents, setAppraisalContents] = useState([]);
	const [appraisalContentTree, setAppraisalContentTree] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [appraisalTextOptions, setAppraisalTextOptions] = useState([]);
	const [appraisalStatus, setAppraisalStatus] = useState(null);
	const [applicableContent, setApplicableContent] = useState([]);
	const [applicableContentTree, setApplicableContentTree] = useState([]);
	const [applicable, setApplicable] = useState([]);
	const [toggleAppraisalContentId, setToggleAppraisalContentId] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	const [isSignModalOpen, setIsSignModalOpen] = useState(false);
	const isAppeal = section === 'appeal';

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const teacherInfo = await get(`getOneTeacher/${teacherId}`);
			const appraisalItem = await get(`getAppraisalItem/${appraisalItemId}`);
			const appraisalTarget = await get(`getOneAppraisalFormTarget/${appraisalTargetId}`);
			const markingTeachers = await get(`getMarkingTeacherByAppraisalTargetId/${appraisalTargetId}`);
			let appraisalContents = await get(`getAppraisalContentBefore/${appraisalItemId}`);
			const appraisalFormBefore = await get(`getAppraisalFormBefore/${appraisalTargetId}`);
			const appraisalFiles = await get(`getAppraisalFile/${appraisalTargetId}`);
			const subjects = await get('getSubject');
			const appraisalTextOptions = await get(`getAppraisalTextOption/${appraisalItemId}`);

			let appraisalBefore = {};
			appraisalContents = orderBy(appraisalContents, ['order', 'id']);
			appraisalContents.map(({ id, inputType, multiple, notApplicable }) => {
				const record = find(appraisalFormBefore, { appraisalContentId: id });
				let value = record && record.text ? record.text : '';

				if (inputType === 'date') value = record && record.date ? htmlInputDate(record.date) : htmlInputDate();
				else if (inputType === 'score') {
					if (notApplicable) value = record && record.score ? record.score : 'Not applicable';
					else value = record && record.score ? record.score : 0;
				} else if (inputType === 'textSelect' && multiple) value = record && record.text ? record.text.split(', ') : [];
				return (appraisalBefore[id] = value);
			});

			const files = [];
			appraisalFiles.map(({ id, url, name }) => {
				files.push({ id, url, name, uploaded: true });
			});

			setTeacherInfo(teacherInfo.length > 0 ? teacherInfo[0] : {});
			setAppraisalItem(appraisalItem.length > 0 ? appraisalItem[0] : {});
			setAppraisalTarget(appraisalTarget.length > 0 ? appraisalTarget[0] : {});
			setMarkingTeachers(markingTeachers);
			setAppraisalContents(appraisalContents);
			setAppraisalContentTree(buildTree(appraisalContents));
			setIsEdit(appraisalFormBefore.length > 0);
			setAppraisalBefore(appraisalBefore);
			setFiles(files);
			setSubjects(selectOptions(subjects, 'displayName', 'displayName'));
			setAppraisalTextOptions(appraisalTextOptions);
			setLoading(false);
		};

		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const appraisalNonApplicable = await get(`getAppraisalNonApplicable/${appraisalTargetId}`);
			let appraisalContentOther = await get(`getAppraisalContentOther/${appraisalItemId}`);
			appraisalContentOther = filter(appraisalContentOther, { allowSetApplicable: 1 });
			appraisalContentOther = orderBy(appraisalContentOther, ['order', 'id']);

			const nonApplicable = map(appraisalNonApplicable, 'appraisalContentId');
			let applicable = [];

			map(appraisalContentOther, ({ id }) => {
				if (nonApplicable.indexOf(id) < 0) applicable.push(id);
			});

			setApplicableContent(appraisalContentOther);
			setApplicableContentTree(buildTree(appraisalContentOther));
			setApplicable(applicable);
			setLoading(false);
		};

		if (!!appraisalItem && ['觀課', '科主/同儕觀課'].indexOf(appraisalItem.displayName) >= 0) fetch();
	}, [appraisalItem]);

	const inputChange = (inputType, stateName) => (event, data) => {
		let value = inputHandler(inputType, data);
		setAppraisalBefore((appraisalBefore) => ({
			...appraisalBefore,
			[stateName]: value,
		}));
	};

	const displayForm = (appraisalContentTree, level = 1) => {
		return (
			<React.Fragment>
				{appraisalContentTree.map(
					(
						{
							id,
							description,
							notes,
							needInput,
							inputType,
							min,
							max,
							step,
							notApplicable,
							count,
							multiple,
							percentage,
							avgCount,
							children,
						},
						index
					) => {
						const value = appraisalBefore[id];
						let label = description;
						if (count) {
							if (avgCount) label += '（平均計算）';
							else if (!!percentage) label += '（' + percentage + '%）';
						}

						return (
							<Fragment key={index}>
								<GridColumn
									computer={!needInput || inputType === 'text' ? 16 : 8}
									largeScreen={!needInput || inputType === 'text' ? 16 : 8}
									mobile={16}
									style={{ backgroundColor: `${tableColor[level - 1] || '#FFFFFF'}` }}
								>
									<div style={{ height: '100%', display: 'flex', alignItems: 'flex-start' }}>
										<FormGroup className="no-margin" grouped style={{ width: '100%' }}>
											{needInput ? (
												<React.Fragment>
													{inputType === 'text' && (
														<FormInput
															control={TextArea}
															onChange={inputChange('text', id)}
															value={value || ''}
															type="text"
															label={label}
														/>
													)}

													{inputType === 'date' && (
														<FormInput
															type="date"
															onChange={inputChange('text', id)}
															value={value || htmlInputDate()}
															label={label}
														/>
													)}

													{['score', 'subject', 'textSelect'].indexOf(inputType) >= 0 && (
														<FormSelect
															label={label}
															clearable
															search={!!multiple}
															multiple={!!multiple}
															onChange={inputChange('select', id)}
															options={
																inputType === 'subject'
																	? subjects
																	: inputType === 'score'
																	? markSelectOptions(min, max, step, notApplicable)
																	: selectOptions(
																			filter(appraisalTextOptions, { appraisalContentId: id }),
																			'displayName',
																			'displayName'
																	  )
															}
															value={multiple ? (Array.isArray(value) ? value : []) : value || ''}
														/>
													)}
													{validator.current.message(description, value, 'required')}
												</React.Fragment>
											) : (
												<Form.Field label={label} />
											)}
										</FormGroup>
									</div>
									<div className="paragraph">{notes}</div>
								</GridColumn>
								{!!children && displayForm(children, level + 1)}
							</Fragment>
						);
					}
				)}
			</React.Fragment>
		);
	};

	const displayApplicableContent = (applicableContentTree, level = 1) => {
		return (
			<React.Fragment>
				{applicableContentTree.map(({ id, description, needInput, inputType, children }) => {
					const value = applicable.indexOf(id) >= 0;
					return (
						<Fragment key={id}>
							<GridColumn
								computer={!needInput || inputType === 'text' ? 16 : 8}
								largeScreen={!needInput || inputType === 'text' ? 16 : 8}
								mobile={16}
								style={{ backgroundColor: `${tableColor[level - 1] || '#FFFFFF'}` }}
							>
								<div style={{ height: '100%', display: 'flex', alignItems: 'flex-start' }}>
									<FormGroup className="no-margin" grouped style={{ width: '100%' }}>
										{needInput ? (
											<Form.Field
												onChange={(event, data) => toggleApplicable(id, data)}
												checked={value}
												control={Checkbox}
												label={description}
											/>
										) : (
											<Form.Field label={description} />
										)}
									</FormGroup>
								</div>
							</GridColumn>
							{!!children && displayApplicableContent(children, level + 1)}
						</Fragment>
					);
				})}
			</React.Fragment>
		);
	};

	const toggleApplicable = (appraisalContentId, data) => {
		const checked = inputHandler('checkbox', data);
		const index = applicable.indexOf(appraisalContentId);
		if (checked) {
			if (index < 0) setApplicable((applicable) => [...applicable, appraisalContentId]);
		} else {
			if (index >= 0) setApplicable((applicable) => [...applicable.slice(0, index), ...applicable.slice(index + 1)]);
		}
		setToggleAppraisalContentId(appraisalContentId);
	};

	useEffect(() => {
		if (toggleAppraisalContentId) {
			const record = find(applicableContent, { id: toggleAppraisalContentId });
			const { parentId = null } = record;

			if (!!parentId) {
				const contents = filter(applicableContent, { parentId });
				let parentCheck = false;
				map(contents, ({ id }) => {
					if (applicable.indexOf(id) >= 0) {
						parentCheck = true;
					}
				});

				const index = applicable.indexOf(parentId);
				if (parentCheck) {
					if (index < 0) setApplicable((applicable) => [...applicable, parentId]);
				} else {
					if (index >= 0)
						setApplicable((applicable) => [...applicable.slice(0, index), ...applicable.slice(index + 1)]);
				}

				setToggleAppraisalContentId(parentId);
			}
		}
	}, [toggleAppraisalContentId]);

	const back = () => {
		let url = `/appraisal/item/form_list/${appraisalItemId}`;
		if (isAppeal) url = `/appraisal/appeal/form_list/${appraisalItemId}/${teacherId}`;
		history.push(url);
	};

	const openSign = (appraisalStatus = null) => {
		if (appraisalStatus !== 'save' && !validator.current.allValid()) {
			validator.current.showMessages();
			alert('資料不完整');
			return;
		}
		setAppraisalStatus(appraisalStatus);
		setIsSignModalOpen(true);
	};

	const save = async () => {
		const appraisalBeforeArray = Object.keys(appraisalBefore).map((id) => {
			const contentRecord = find(appraisalContents, { id: Number(id) });
			if (contentRecord.inputType === 'date') {
				return { appraisalContentId: id, date: appraisalBefore[id] };
			}

			if (contentRecord.inputType === 'score') {
				return {
					appraisalContentId: id,
					score: appraisalBefore[id] === 'Not applicable' || appraisalBefore[id] === '' ? null : appraisalBefore[id],
				};
			}

			if (contentRecord.inputType === 'textSelect' && contentRecord.multiple) {
				return { appraisalContentId: id, text: appraisalBefore[id].join(', ') };
			}

			return { appraisalContentId: id, text: appraisalBefore[id] };
		});

		const nonApplicable = [];
		map(applicableContent, ({ id }) => {
			if (applicable.indexOf(id) < 0) nonApplicable.push(id);
		});

		const data = {
			isEdit,
			appraisalTargetId,
			appraisalBeforeArray,
			teacherId: isAppeal ? teacherId : null,
			appraisalStatus,
			nonApplicable,
		};

		setLoading(true);
		const result = await post('editAppraisalFormBefore', data);
		if (result) {
			validator.current.hideMessages();
			await uploadFile();
			await deleteFile();
			back();
		}
	};

	const uploadFile = async () => {
		let count = 0;
		const formData = new FormData();
		formData.append('appraisalTargetId', appraisalTargetId);
		formData.append('teacherName', appraisalTarget.name);
		files.map((file) => {
			const { name, removed, uploaded } = file;
			if (!removed && !uploaded) {
				formData.append('files', file, name);
				count += 1;
			}
		});

		if (count > 0) await post('uploadAppraisalFile', formData, 'FORMAPPRAISAL');
		return;
	};

	const deleteFile = async () => {
		let urls = [];
		let deleteFileIds = [];
		files.map(({ id, url, removed, uploaded }) => {
			if (!!id && removed && uploaded) {
				urls.push(url);
				deleteFileIds.push(id);
			}
		});

		if (deleteFileIds.length > 0) await post('deleteAppraisalFile', { appraisalTargetId, urls, deleteFileIds });
		return;
	};

	const selectFile = (newFiles) => {
		if (newFiles.length > 0) {
			setFiles((files) => [...files, ...newFiles]);
		}
	};

	const removeFile = (index) => {
		setFiles((files) => [
			...files.slice(0, index),
			{
				...files[index],
				removed: true,
			},
			...files.slice(index + 1),
		]);
	};

	const buttons = [
		{ key: 'cancel', color: 'red', content: '取消', action: back },
		{ key: 'save', color: 'orange', content: '儲存', action: () => openSign('save'), display: !isAppeal },
		{ key: 'check', color: 'green', content: '提交', action: () => openSign('complete') },
	];

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid>
				<GridRow>
					<GridColumn>
						{isAppeal ? (
							<PageHeader
								title={teacherInfo.name ? '編輯被評估前設定: ' + teacherInfo.name : '編輯被評估前設定'}
								prevPage={back}
							/>
						) : (
							<PageHeader title="新增被評估前設定" prevPage={back} />
						)}
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<SegmentGroup>
							<Segment className="bold">
								<Grid columns={1} stackable>
									<GridRow className="larger-font" verticalAlign="middle">
										<GridColumn>
											{appraisalItem.yearName} : {appraisalItem.displayName}
										</GridColumn>
									</GridRow>
									<GridRow>
										<GridColumn>
											評估老師:
											{markingTeachers.map(({ teacherName }, index) => {
												return <Label style={{ marginLeft: 5 }} key={index} content={teacherName} />;
											})}
										</GridColumn>
									</GridRow>
								</Grid>
								<Grid columns={4} stackable>
									<GridRow>
										<GridColumn>被評老師: {appraisalTarget.name}</GridColumn>
										<GridColumn>評估科目: {appraisalTarget.subject}</GridColumn>
										<GridColumn>評估班別: {appraisalTarget.className}</GridColumn>
										<GridColumn>遞交限期: {momentToDate(appraisalTarget.deadline)}</GridColumn>
									</GridRow>
								</Grid>
							</Segment>
							<Segment padded="very">
								<Form>
									<Grid>{displayForm(appraisalContentTree)}</Grid>
								</Form>
							</Segment>
							{Boolean(appraisalItem.needUpload) && (
								<Segment padded="very">
									<div style={{ marginBottom: 10 }}>
										<b>上載教案、簡報、工作紙、電子平台截圖(如適用)：</b>
									</div>
									{files.length > 0 && (
										<div>
											{files.map(({ name, removed = false }, index) => {
												if (!removed)
													return (
														<div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
															<div>{name}</div>
															<div style={{ marginLeft: 5, cursor: 'pointer' }}>
																<Icon name="times" onClick={() => removeFile(index)} />
															</div>
														</div>
													);
											})}
										</div>
									)}
									<Button
										color="green"
										content="上載"
										icon="upload"
										type="button"
										onClick={() => FileRef.current.click()}
									/>
									<input
										ref={FileRef}
										type="file"
										style={{ display: 'none' }}
										multiple
										onChange={(event) => selectFile(event.currentTarget.files)}
									/>
								</Segment>
							)}
							{['觀課', '科主/同儕觀課'].indexOf(appraisalItem.displayName) >= 0 && (
								<Segment padded="very">
									<div style={{ marginBottom: 10 }}>
										<b>選擇適用項目：</b>
									</div>
									<Grid>{displayApplicableContent(applicableContentTree)}</Grid>
								</Segment>
							)}
							<Segment>
								<Grid>
									<GridRow>
										<GridColumn textAlign="center">
											{buttons.map(({ key, color, content, action, display = true }) => {
												if (display)
													return (
														<Button
															key={key}
															className="button-margin-bottom"
															color={color}
															content={content}
															icon={key}
															type="button"
															onClick={action}
															circular
														/>
													);
											})}
										</GridColumn>
									</GridRow>
								</Grid>
							</Segment>
						</SegmentGroup>
					</GridColumn>
				</GridRow>
			</Grid>
			<SignModal open={isSignModalOpen} close={() => setIsSignModalOpen(false)} save={save} />
		</React.Fragment>
	);
};

export default EditBefore;
