/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {fromBlankAsync} from 'xlsx-populate';

/* import components */
import {
	FullscreenDimmer,
	PageHeader,
	EmptyTableMsg,
} from '../../../../Components';

/* import helper functions */
import { get } from '../../../../Helper/ApiHelper';
import { displayDate } from '../../../../Helper/TimeHelper';
import { inputHandler } from '../../../../Helper/FormHelper';
import { selectOptions, genAttendanceString } from '../../../../Helper/Helper';

import genNormalReport from '../../../../Layout/ECA/normalGroupReport';

import {activityReportGradeOptions as gradeOptions} from '../../../../Const/Const';
import {genLocationString } from './../FridayHelpers';
import { exportExcel, convertToColumnTitle } from '../../../../Helper/ExcelHelper';

/* import lodash */
import { keyBy, groupBy, mapValues } from 'lodash';

/* import semantic-ui element */
import { Grid, Tab, Button, Header, Segment, Label, Table, Select } from 'semantic-ui-react';

class NormalGroupDetail extends Component {
  constructor(props) {
    super(props);

    let search = this.props.location.search.match(/\d+$/);
    if(search){
      search = +search[0];
      if(search>2)search=2;
      if(search<0)search=0;
    }else{
      search=0;
    }

    this.attendanceTableHeader = [
      { headerName: '班別', width: 3},
      { headerName: '學號', width: 3},
      { headerName: '姓名', width: 6},
      { headerName: '出席狀態', width: 4},
    ];

    this.reportTableHeader = [
      { headerName: '日期', width: 1},
      { headerName: '興趣小組活動名稱 (#)', width: 3},
      { headerName: '負責老師', width: 4},
      { headerName: '學習內容及學習活動', width: 4},
      { headerName: '學生學習興趣評估', width: 3},
    ];

    this.state = {
      dimmerOpen: true,
      loaderText: '',
      normalGroupClassId: '',
      dateOptions: '',
      activeIndex: search !==null ? search : 0,
      normalGroupId: this.props.match.params.normalGroupId || null,
      normalGroup: null,
      normalGroupClasses: [],
      normalGroupAttendances: {},

    }
  }
  
  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
    this.setStateAsync({ dimmerOpen: false });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  setStateAsync = (state, errMsg = 'error') => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    }).catch(err=>{console.log(errMsg);console.log(err);})
  )

  fetchData = async () => {
    const {normalGroupId} = this.state;

    try{
      const [normalGroup, normalGroupClasses, normalGroupStudents, rawAttendances, rawAttendanceStatus] = await Promise.all([
        get('getOneFridayActivityNormalGroup/'+normalGroupId),
        get('getNormalGroupFridayActivityClasses/'+normalGroupId),
        get('getNormalGroupStudent/'+normalGroupId),
        get('getAllNormalGroupAttendance/'+normalGroupId),
        get('getAttendanceStatus')
      ]);

      await this.setStateAsync({
        normalGroup: normalGroup[0] || {},
        normalGroupClasses,
        normalGroupStudents,
        dateOptions: selectOptions(normalGroupClasses, x=>displayDate(x.date), 'id'),
        normalGroupAttendances: groupBy(rawAttendances, 'normalFridayActivityClassId'),
        attendanceStatusMap: rawAttendanceStatus.reduce((prev,cur)=>({...prev,[cur.id]: cur.name}),{})
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity', err)}
  }

  panes = () => {
    return [
      { menuItem: '小組詳情', render:this.displayInfo},
      { menuItem: '點名', render:this.displayAttendance},
      { menuItem: '學習紀錄', render:this.displayReport},
    ]
  }

  displayInfo = () => {
    const {
      normalGroup,
      normalGroupStudents
    } = this.state;

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top'>
          <Header as='h3'><u>基本資料</u></Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={2}><b>上課地點:</b></Grid.Column>
              <Grid.Column width={14}>{genLocationString(normalGroup)}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={2}><b>小組學生:</b></Grid.Column>
              <Grid.Column width={14}>
                {normalGroupStudents.length > 0 ?
                  <Label.Group size='large'>
                    {normalGroupStudents.map(x => (
                      <Label key={x.id}>{x.chiName || x.engName }&nbsp;({x.classCode} {x.classNo})</Label>
                    ))}
                  </Label.Group>
                  :
                  '-'
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Tab.Pane>
    )
  }

  downloadAttendance = async () => {
    const students = this.state.normalGroupStudents;
    const classes = this.state.normalGroupClasses;
    const attendance = this.state.normalGroupAttendances;

    const {normalGroup, attendanceStatusMap} = this.state;
     
    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);

    const dates = classes.map(x=>displayDate(x.date));

    const attendanceLookup = mapValues(attendance, x=>x.reduce((prev,cur)=>({
      ...prev,
      [cur.normalFridayActivityStudentId]: genAttendanceString(cur, attendanceStatusMap)
    }),{}));

    sheet.cell('A3').value("學生");
    sheet.cell('B3').value([dates.concat(Array.from({length: 25 - dates.length}))]);
    sheet.cell('A4').value(students.map(x=>([`${x.classCode} ${x.classNo>9?x.classNo:" "+x.classNo} ${x.chiName||x.engName}`])));

    students.forEach((student,i)=>{
      classes.forEach((classObj, j)=>{
        if(attendanceLookup[classObj.id] && attendanceLookup[classObj.id][student.id]){
          sheet.cell(`${convertToColumnTitle(j+2)}${i+4}`).value(attendanceLookup[classObj.id][student.id]);
        }
      });
    });

    sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true }, fontSize: 12 });

    sheet.range(1,1,2,sheet.usedRange().endCell().columnNumber()).merged(true).startCell().value(`${process.env.REACT_APP_SCHOOL_NAME} ${normalGroup && normalGroup.displayName}年度週五興趣小組點名表 - 普通活動學生小組${normalGroup.code} (${normalGroup.locationName})`);
    sheet.range('A1:A2').style({bold: true, fontSize: 16});
    sheet.row(1).height(21);
    sheet.row(2).height(21);

    sheet.usedRange().style({ fontFamily: "標楷體", horizontalAlignment: 'center', verticalAlignment: 'center', wrapText: true});
    for(let i=2;i<=sheet.usedRange().endCell().columnNumber();i++){
      sheet.column(i).width(11);
    }
    sheet.column("A").width(21).style({horizontalAlignment: 'left'});
    
    sheet.range('A1:B1').style({ horizontalAlignment: 'center' });
    
    const file = await workbook.outputAsync();
    exportExcel(file, `週五興趣小組點名表 - 普通活動學生小組${normalGroup.code}`);
  }

  changeDate = (event, data) => {
    this.setStateAsync({
      normalFridayActivityClassId: inputHandler('select',data)
    })
  }

  displayAttendance = () => {
    const {
      normalGroupAttendances,
      normalGroupStudents,
      normalFridayActivityClassId,
      dateOptions,
      attendanceStatusMap
    } = this.state;

    const lookup = keyBy(normalGroupAttendances[normalFridayActivityClassId] || {}, 'normalFridayActivityStudentId');

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top'>
          <Grid>
            <Grid.Row columns="1">
              <Grid.Column>
                <Select
                  options={dateOptions}
                  value={normalFridayActivityClassId}
                  onChange={this.changeDate}
                  placeholder="請選擇上課日期"
                />
                <Button color='blue' content='匯出小組總點名表' icon='download' onClick={this.downloadAttendance} circular floated="right"/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Table textAlign='center' padded selectable celled stackable fixed>
                  <Table.Header>
                    <Table.Row>
                      {this.attendanceTableHeader.map(({headerName, width}) => (
                        <Table.HeaderCell key={headerName} width={width}>{headerName}</Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {normalFridayActivityClassId ? (normalGroupStudents.length > 0 && normalGroupAttendances[normalFridayActivityClassId] ? normalGroupStudents.map(x => (
                          <Table.Row key={x.id}>
                            <Table.Cell>{x.classCode}</Table.Cell>
                            <Table.Cell>{x.classNo}</Table.Cell>
                            <Table.Cell>{x.chiName || x.engName}</Table.Cell>
                            <Table.Cell>{lookup.hasOwnProperty(String(x.id))?genAttendanceString(lookup[x.id], attendanceStatusMap)||'-':'-'}</Table.Cell>
                          </Table.Row>
                        )) : <EmptyTableMsg colSpan={this.attendanceTableHeader.length} msg="未有學生點名資料" />
                    ) : <EmptyTableMsg colSpan={this.attendanceTableHeader.length} msg="請先選擇日期" />}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Tab.Pane>
    )
  }

  downloadNormalReport = () => {
    const {normalGroup, normalGroupClasses} = this.state;

    genNormalReport({
      yearName: normalGroup && normalGroup.displayName,
      normalGroupCode: normalGroup && normalGroup.code,
      normalGroupClasses
    })
  }

  displayReport = () => {
    const {
      normalGroupClasses,
    } = this.state;

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top'>
          <Grid>
            <Grid.Row columns="1">
              <Grid.Column>
                <Button color='blue' content='匯出普通小組總學習紀錄表' onClick={this.downloadNormalReport} icon='download' circular />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Table textAlign='center' padded selectable celled stackable>
                  <Table.Header>
                    <Table.Row>
                      {this.reportTableHeader.map(({headerName, width}) => (
                        <Table.HeaderCell key={headerName} width={width}>{headerName}</Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {normalGroupClasses.length > 0 ? normalGroupClasses.map(x => {
                      return (
                        <Table.Row key={x.id}>
                          <Table.Cell>{displayDate(x.date)}</Table.Cell>
                          <Table.Cell>{x.fridayActivityName}&nbsp;({x.fridayActivityCode})</Table.Cell>
                          <Table.Cell>{x.teachers.join("、")||'-'}</Table.Cell>
                          <Table.Cell>
                            {x.content || '-'}
                          </Table.Cell>
                          <Table.Cell>
                            {gradeOptions[x.mark] !== undefined && gradeOptions[x.mark] !== null ? (x.mark ? gradeOptions[x.mark] : x.remark) : '-'}
                          </Table.Cell>
                        </Table.Row>
                      )
                    }) : <EmptyTableMsg colSpan={this.reportTableHeader.length} />}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Tab.Pane>
    )
  }

  handleTabChange = (e, { activeIndex }) => this.setStateAsync({ activeIndex })

  render() {
    const {
      dimmerOpen,
      loaderText,
      activeIndex,
      normalGroup,
    } = this.state;

    return (
      <>
        {dimmerOpen ?
          <FullscreenDimmer active={dimmerOpen} isLoading={true} loaderText={loaderText} />
          :
          <>
            <Grid stackable doubling>
              <Grid.Row>
                <Grid.Column>
                  <PageHeader title={normalGroup?`${normalGroup.displayName || '-'}年度週五普通活動學生小組 ${normalGroup.code || '?'} 詳情`:''} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  {normalGroup?(
                    <Tab
                      panes={this.panes()}
                      activeIndex={activeIndex}
                      onTabChange={this.handleTabChange}
                    />
                  ):<Header as='h2' textAlign="center" icon="warning">{normalGroup===null?"找不到週五興趣小組":"載入資料中"}</Header>}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        }
      </>
    )
  }
}

export default withRouter(NormalGroupDetail);