import {
  escapeHtml,
  printLayout,
} from '../../Helper/PrintHelper';

const getLayout = info => {
  let tBody = [];

  info.data.forEach((levelData, level)=>{
    let i=0;
    levelData.forEach((roomData, roomName)=>{
      tBody.push(`<tr${i?"":" class='new-row'"}><th>${roomName}</th>${roomData.map(x=>`<td>${x.join("<br/>\n")}</td>`).join("\n")}</tr>`)
      i=1;
    })
  });

  return `
<h2 class='center break-page'><span class="header">${escapeHtml(info.filename)}</span></h2>
<table class="venue-table" fixed>
  <colgroup>
    <col>
    <col span="5">
  </colgroup>
  <thead>
    <tr>
      <th></th>
      <th>星期一</th>
      <th>星期二</th>
      <th>星期三</th>
      <th>星期四</th>
      <th>星期五</th>
    </tr>
  </thead>
  <tbody>
    ${tBody.join("\n")}
  </tbody>
</table>
`};

/**
 * Print Friday Activity Plan Record(s)
 * @param {Object[]|Object} info Info for record printing (support 1 object or array of objects) 
 * @param {Function} callback 
 */
const printRecord = (info, callback = () => {}) =>{
  try{
      const HTMLLayout = getLayout(info);

    printLayout(HTMLLayout, info.filename, 'venue',  callback);

  }catch(err){
      alert('ERROR');
      console.clear();
      console.log(err);
      console.log(info);
      callback();
  }
}

export default printRecord;
