/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  PageHeader,
} from '../../../Components';

/* import helper functions */
import { get } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Select, Card, Segment, Header } from 'semantic-ui-react';

class FridayActivitySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,
      yearOptions: [],
      selectedYear: '',
      fridayActivites: [],
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchYear = async () => {
    try{
    const result = await get('getYear');
      await this.setStateAsync({
        yearOptions: selectOptions(result, 'displayName', 'id'),
        selectedYear: defaultYear(result),
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday personal activity year', err)}
  }

  fetchData = async () => {
    try{
      const fridayActivites = await get('getPersonalFridayActivity/' + this.state.selectedYear);

      await this.setStateAsync({
        fridayActivites,
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday personal activity data', err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchYear();
    await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  yearChange = async (event, { value }) => {
    try{
      await this.setStateAsync({ finishedLoading: false, selectedYear: value });
      await this.fetchData(value);
      this.setStateAsync({ finishedLoading: true });
    }catch(err){if(err!=='unmounted')console.log('Error when changing friday personal activity year', err)}
  }

  viewDetail = (event) => {
    const {itemId, type} = event.target.closest('a').dataset;
    this.props.history.push('/eca/fridayActivity/personal/detail/' + type + '/' + this.state.selectedYear + '/' + itemId);
  }

  render() {
    const {
      finishedLoading,
      yearOptions,
      selectedYear,
      fridayActivites,
    } = this.state;

    return (
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='負責週五興趣小組' subTitle='管理每年度的週五興趣小組' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Select onChange={this.yearChange} options={yearOptions} value={selectedYear} />
          </Grid.Column>
        </Grid.Row>

        {finishedLoading ? (fridayActivites.length > 0 ? (
            <Grid.Row columns={2}>
              {fridayActivites.map(({ id, name, type }) => (
                <Grid.Column key={id} className='form-group-margin'>
                  <Card
                    key={id}
                    data-item-id={id}
                    data-type={type}
                    fluid
                    raised
                    header={name}
                    meta={type === 'special' ? '專項小組' : '普通小組'}
                    onClick={this.viewDetail}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          ):(
            <Grid.Row>
              <Grid.Column>
                <Segment textAlign='center' basic>
                  <Header icon>本年度沒有負責的外出活動</Header>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          )
        ) : (
          <Grid.Row>
            <Grid.Column>
              <Segment textAlign='center' basic>
                <Header icon>載入資料中</Header>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    )
  }
}

export default withRouter(FridayActivitySummary);