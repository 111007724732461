import OneYear from './1Year';
import ThreeYear from './3Year';
import Group from './Group';
import ExportFile from './ExportFile';
import FileManagement from './FileManagement';
import SurveyDesign from './SurveyDesign';
// import ActionGroup from './ActionGroup';
// import Fields from './Fields';
// import Allowance from './Allowance';

export default {
  OneYear,
  ThreeYear,
  Group,
  ExportFile,
  FileManagement,
  SurveyDesign,
  // ActionGroup,
  // Fields,
  // Allowance
}