/* import react */
import React from 'react';

/* import semantic-ui element */
import { Dimmer, Loader } from 'semantic-ui-react';

const dimmerStyle = { position: 'fixed', zIndex: 10000 };

/* KEEP THEM UNDEFINED */
const FullscreenDimmer = ({ active, content, isLoading }) => {
	return (
		<Dimmer style={dimmerStyle} active={active}>
			{isLoading ? <Loader size="big" content={content} /> : content}
		</Dimmer>
	);
};

export default FullscreenDimmer;
