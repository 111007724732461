import {SECTIONDATA} from '../Modules/Sen/Helper';
import {find} from 'lodash';
export const escapeHtml = (html) => {
  var text = document.createTextNode(html);
  var p = document.createElement('p');
  p.appendChild(text);
  return p.innerHTML;
}

//UNESCAPED
export const Optional = (value, text) => {
  if (value) return text;
  return '';
}

export const CheckBoxWithLabel = (title, value, params = {}) => {
  if (typeof params !== 'object') return '';
  const inline = params.hasOwnProperty('inline') ? params.inline : true;
  const labelTag = params.labelTag || 'label';
  return (
    `<${labelTag}${Optional(inline, ' class="inline"')}><input type="checkbox"${Optional(value, ' checked')}/>${escapeHtml(title)}${Optional(params.others && value, `${Optional(!(params.others instanceof Array), '　')}${escapeHtml(params.others)}
      ${Optional(value, `: <span class="others">${escapeHtml(value)}</span>`)}`)}
    </${labelTag}>`);
}

export const BooleanCheckBoxInline = (value, trueLabel = "是", falseLabel = "否", prefix = '') => {
  return `${prefix}${CheckBoxWithLabel(trueLabel, value)}${CheckBoxWithLabel(falseLabel, value === false)}`;
}

export const BooleanCheckBoxSection = (title, value, trueLabel = "是", falseLabel = "否", unescapedAdditionalContent = "") => {
  return `<div class="section">
    ${Optional(!!title, "<h3>" + escapeHtml(title) + "</h3>")}
    ${BooleanCheckBoxInline(value, trueLabel, falseLabel)}
    ${unescapedAdditionalContent}
</div>`;
}

export const FlexListSection = (obj, unescapedAdditionalContent = "") => {
  return `<div class="flex section">
    ${Object.keys(obj).map(x => `<div class="flex-child"><strong>${escapeHtml(x)}</strong>: ${escapeHtml(obj[x])}</div>`).join('')}
    ${unescapedAdditionalContent}
</div>`;
}

export const TableWithHeader = (title, objArray, idName = null, objHeader = false, unescapedAdditionalContent = "") => {
  if (objArray.length === 0)
    return `<div class="section">
    ${Optional(!!title, "<h3>" + escapeHtml(title) + "</h3>")}
    <div class="others">無</div>
    ${unescapedAdditionalContent}
</div>`;

  objHeader = objHeader || Object.keys(objArray[0]);
  return `<div class="section" ${Optional(idName === 'sen-result', `style="padding-top: 0.5rem"`)}>
    ${Optional(!!title, "<h3>" + escapeHtml(title) + "</h3>")}
    <table${Optional(idName, ` id="${idName}"`)}>
        <thead><tr>
            ${objHeader.map(fieldName => `<th scope="col">${escapeHtml(fieldName)}</th>`).join('')}
        </tr></thead>
        <tbody>
            ${objArray.map(entry => `<tr>${objHeader.map(fieldName => `<td>${entry[fieldName]}</td>`).join('')}</tr>`).join('')}
        </tbody>
    </table>
    ${unescapedAdditionalContent}
</div>`;
}

export const GridListSection = (title, contentArray, rows, params = {}) => {
  if (typeof params !== 'object') return '';
  const unescapedAdditionalContent = params.hasOwnProperty('unescapedAdditionalContent') ? params.unescapedAdditionalContent : '';
  const showParent = params.hasOwnProperty('showParent') ? params.showParent : true;
  let tableContent = '';

  if (contentArray.length === 0) {
    tableContent = '<div class="others">無</div>';
  } else {
    rows = Number(rows);
    for (var i = 0; i < contentArray.length / rows; i++) {
      tableContent += `<tr>`;
      for (var j = 0; j < rows; j++) {
        const z = i * rows + j;
        tableContent += `<td${Optional(params.borderless, ' class="borderless"')}>${Optional(z < contentArray.length, contentArray[z])}</td>`;
      }
      tableContent += `</tr>`;
    }
    tableContent = `<table class="row-${rows}">
        <tbody>${tableContent}</tbody>
        </table>`;
  }

  return `${Optional(!params.stripSection, '<div class="section">')}
    ${Optional(showParent && !!title, "<h3>" + escapeHtml(title) + "</h3>")}
    ${tableContent}
    ${unescapedAdditionalContent}
${Optional(!params.stripSection, '</div>')}`;
}

export const treatedDataToAggregateData = (data) => {
  const adjustments = {};

  for(let SECTION of SECTIONDATA.slice(13)){
    const {displayName, subFields} = SECTION;
    adjustments[displayName] = {};
    const fieldLookup = data;

    adjustments[displayName].value = fieldLookup[displayName];

    for(let field of subFields){
      const arr = field.split("|");
      let j=0;
      let obj = adjustments[displayName];
      while(j<arr.length){
        if(!obj.hasOwnProperty('subFields')){
          obj.subFields={};
        }
        if(!obj.subFields.hasOwnProperty(arr[j])){
          obj.subFields[arr[j]] = {}
        }
        obj = obj.subFields[arr[j]];
        j++;
      }
      obj.value = fieldLookup.hasOwnProperty(displayName + "|" + field) ? fieldLookup[displayName + "|" + field] : null;
    }
  }
  return adjustments;
}

const AdjustmentRecursiveSubFields = (displayName, data, isSchool) => {
  if(!data.value && !isSchool) return '';
  let subFields = [], thisField='';
  if(displayName !== '家長同意' && data.value !=='是' && data.value !== '' && data.value !== undefined){
    thisField = CheckBoxWithLabel(displayName, data.value, {others: []});
  }else{
    if(displayName === '家長同意')
      thisField = BooleanCheckBoxInline(treatBoolean(data.value, "同意", "不同意"), "同意", "不同意", "家長同意與否:　");
    else
      thisField = CheckBoxWithLabel(displayName, data.value);
  }

  if(data.subFields && (data.value||isSchool)){
    let isInline = displayName !== '其他';
    let hasParentConsent = data.subFields.hasOwnProperty('家長同意');
    subFields = Object.entries(data.subFields).map(([displayName, x],i)=>{
      if(x.hasOwnProperty('subFields') && displayName !== '家長同意') isInline = false;
      return AdjustmentRecursiveSubFields(displayName, x, isSchool);
    });
    if(hasParentConsent){
      return `${thisField}
      <div class="subsection">
        <div${isInline?" class=\"inline\"":""}>${subFields.slice(0,subFields.length-1).map(x=>`<div>${x}</div>`).join("\n")}</div>
        <div${isInline?" class=\"inline\"":""}>${subFields[subFields.length-1]}</div>
      </div>`;
    }
    return `${thisField}
    <div class="subsection${isInline?" inline":""}">
      ${subFields.map(x=>`<div>${x}</div>`).join("\n")}
    </div>`;
  }

  return thisField;
}

export const AdjustmentRecursiveFields = (displayName, data, isSchool, isFirstAdjustment) => {
  if(!data.value && !isSchool) return '';

  let subFields = [];
  let hasParentConsent = false;
  let isInline = true;

  if(data.subFields && (data.value||isSchool)){
    subFields = Object.entries(data.subFields).map(([displayName, x],i)=>{
      if(x.hasOwnProperty('subFields') && displayName !== '家長同意') isInline = false;
      return AdjustmentRecursiveSubFields(
        displayName,
        x,
        isSchool
      )
    });
    hasParentConsent = data.subFields.hasOwnProperty('家長同意');
  }
  if(hasParentConsent){
    return `<div class="section">${isFirstAdjustment?'<h3>各項支援安排</h3>':''}
    ${CheckBoxWithLabel(displayName, data.value, { inline: false, labelTag: 'h3' })}
    <div class="subsection">
      <div${isInline?" class=\"inline\"":""}>${subFields.slice(0,subFields.length-1).map(x=>`<div>${x}</div>`).join("\n")}</div>
      <div${isInline?" class=\"inline\"":""}>${subFields[subFields.length-1]}</div>
    </div>
  </div>`
  }
  return `<div class="section">${isFirstAdjustment?'<h3>各項支援安排</h3>':''}
    ${CheckBoxWithLabel(displayName, data.value, { inline: false, labelTag: 'h3' })}
    <div class="subsection">
      ${subFields.map(x=>`<div>${x}</div>`).join("\n")}
    </div>
  </div>`
}

/**
 * Print the HTML file as pdf
 * @param {string} content page main content
 * @param {string} title Page title
 * @param {string} cssType Any css class to be added to content container
 * @param {function} callback 
 */
export const printLayout = (content, title = '', cssType = '', callback = () => { }) => {
  //Claimed to Work with Chome, Firefox, IE, Safari
  var printWindow = window.open("", "_blank", "");

  printWindow.document.open();
  printWindow.document.write(`
<html>
  <head>
    <title>${title}</title>
    <link rel="stylesheet" type="text/css" href="/css/printLayout.css">
  </head>
  <body>
    <div class="${cssType}">
      ${content}
    </div>
  </body>
</html>
    `);
  printWindow.document.close();
  printWindow.focus();
  printWindow.onload = () => {
    printWindow.print();
    printWindow.close();
    callback();
  }
}

/**
 * Generate SEN type NAMES for Excel (\n is for ADHD)
 */
export const SENNAME = find(SECTIONDATA, {displayName: '特殊教育需要類別'}).subFields.map(x=>x.replace('（','\n（'));

// mostly for string parsing so we use === here
export const treatBoolean = (value, trueValue, falseValue) => {
  if (value === trueValue) return true;
  if (value === falseValue) return false;
  return null;
}

export const SENREPORTFIELDS = find(SECTIONDATA, {displayName: '評估報告'}).subFields.filter(x=>x!=='仍有報告需上報');

export const StudentFillForm = (
  `<div class="section" style="padding-top: 0.5rem">
<h3>負責人員：</h3>
<table id="staff-in-charge">
  <thead>
    <tr>
      <th scope="col">SENCO</th>
      <th scope="col">學校社工</th>
      <th scope="col">班主任</th>
      <th scope="col">中文科</th>
      <th scope="col">英文科</th>
      <th scope="col">數學科</th>
      <th scope="col">常識科</th>
    </tr>
  </thead>
  <tbody>
    <tr style="height: 3rem">
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>
</div>
<div class="section">
<h3>聲明</h3>
<p>本人已了解及同意學校對　敝子弟所進行的調適安排。</p>
<br/>
<div class="flex">
  <div class="flex flex-child"><span>家長姓名：</span><span class="underscore"></span></div>
  <div class="flex flex-child"><span>家長簽署：</span><span class="underscore"></span></div>
  <div class="flex flex-child"><span>日期：</span><span class="underscore"></span></div>
</div>
</div>`);