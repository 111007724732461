import {
  escapeHtml,
  printLayout,
} from '../../Helper/PrintHelper';

import {displayDate} from '../../Helper/TimeHelper';

const getLayout = info => `
<div class='center section break-page'>
  <p>${process.env.REACT_APP_SCHOOL_NAME}</p>
  <p>${escapeHtml(info.yearName||"-")}年度&emsp;周五興趣小組</p>
  <p>普通小組學習紀錄表</p>
</div>
<div class="section">
<p>組別：<span class='others'>&nbsp;${escapeHtml(info.normalGroupCode)}&nbsp;</span></p></
</div>
<table class="normal-report-table section" fixed>
  <colgroup>
    <col class="date"/>
    <col class="activityName"/>
    <col class="teachers"/>
    <col class="content"/>
    <col class="excellent"/>
    <col class="okay"/>
    <col class="normal"/>
    <col class="bad"/>
    <col class="other"/>
  </colgroup>
  <thead>
    <tr>
      <th rowSpan="2">日期</th>
      <th rowSpan="2">興趣小組<br/>活動名稱</th>
      <th rowSpan="2">負責老師</th>
      <th rowSpan="2">學習內容及學習活動</th>
      <th colSpan="5">學生學習興趣評估</th>
    </tr>
    <tr>
      <th>優</th>
      <th>良</th>
      <th>常</th>
      <th>可</th>
      <th>其他</th>
    </tr>
  </thead>
  <tbody>
    ${info.normalGroupClasses.map(x=>`<tr>
  <th scope="row">${displayDate(x.date)}</th>
  <td>${escapeHtml(x.fridayActivityName)}&nbsp;(${escapeHtml(x.fridayActivityCode)})</td>
  <td>${escapeHtml(x.teacherShortNames.join("")||'-')}</td>
  <td>${escapeHtml(x.content||"-")}</td>
  ${Array.from({length: 4}).map((y,i)=>`<td>${4-i===x.mark?"&#10004;":""}</td>`).join("\n")}<td>${(x.mark?"":escapeHtml(x.remark||''))}</td></tr>`).join("\n")}
  </tbody>
</table>
<div>
  * 請負責老師於活動後評估學生學習的興趣及記錄意見，作為日後總結檢討之用。<br/>
  * 普通小組每次上課後將文件夾交到(泰)的桌上。
</div>
`;

/**
 * Print Normal Group Report(s)
 * @param {Object[]|Object} info Info for record printing (support 1 object or array of objects) 
 * @param {Function} callback 
 */
const printRecord = (info, callback = () => {}) =>{
  try{
    
    const HTMLLayout = Array.isArray(info)?info.map(getLayout).join('\n'):getLayout(info);

    printLayout(HTMLLayout, `${info.yearName} 普通小組學習紀錄表${info.normalGroupCode}`, 'normal-report',  callback);

  }catch(err){
      alert('ERROR');
      //console.clear();
      console.log(err);
      console.log(info);
      callback();
  }
}

export default printRecord;
