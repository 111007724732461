/* import react */
import React, { Component, memo } from 'react';
import { withRouter } from 'react-router-dom';

/* import lodash */
import { find, findIndex, pick, get as _get } from 'lodash';
/* import components */
import {
  PageHeader,
  ErrorLabel,
  ConfirmModal,
  FullscreenDimmer,
  MySortableTable,
  Buttons
} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

import '../../Styles/InlineField.css';

/* import semantic-ui element */
import { Grid, Modal, Segment, Form, Container, Header, Icon } from 'semantic-ui-react';

const GroupModalSegment = memo((props) => (
  <Segment>
    <Form.Group>
      <Form.Input type="text" width={15} value={props.title} data-id={props.id} onChange={props.editActivity} />
      <Buttons.DeleteButton data-id={props.id} onClick={props.deleteActivity} />
    </Form.Group>
  </Segment>
))

class Group extends Component {
  constructor(props) {
    super(props);
    this.newId = -1;
    this.state = {
      finishedLoading: false,
      dimmerOpen: false, //now for modal only

      groups: [],

      isEditModalOpen: false,
      isDeleteModalOpen: false,
      isSaveModalOpen: false,

      selectedId: null,
      name: '',
      info: {},
      type: '科組',
      activities: [],
    }
    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        default: '請輸入資料'
      }
    });
    this.negativeTopMargin = { marginTop: "-1rem" };
    this.tableProps = { unstackable: true, compact: true };


    this.group_types = process.env.REACT_APP_GROUP_TYPES.split("|");
    this.group_info_fields = process.env.REACT_APP_GROUP_INFO_FIELDS ? process.env.REACT_APP_GROUP_INFO_FIELDS.split("|") : [];
    this.tableColumnData = [
      {
        headerName: '名稱',
        width: 1,
        cellRender: 'name',
        allowSort: true,
        sortMethod: 'id',
      },
      {
        headerName: '種類',
        width: 1,
        cellRender: 'type',
        allowSort: true,
      },
      {
        headerName: '行動',
        width: 1,
        allowSort: false,
        cellRender: x => (<>
          <Buttons.EditButton
            data-id={x.id}
            data-modalname='isEditModalOpen'
            onClick={this.modalToggle}
          />
          <Buttons.DeleteButton
            data-id={x.id}
            data-modalname='isDeleteModalOpen'
            onClick={this.modalToggle}
          />
        </>),
        cellClassName: 'textlessCell'
      },
    ];
    if (process.env.REACT_APP_GROUP_INFO_FIELDS) {
      this.tableColumnData.splice(-1, 0,
        ...this.group_info_fields.map((field) => ({
          headerName: field.replace(/\s.*$/, ''),
          width: 4,
          allowSort: false,
          cellRender: x => {
            if (!x.info[field]) return "-";

            const value = x.info[field].split('\n');
            return (<>
              <ol className="list-less-padding">
                {value.slice(0, 5).map((row, i) => <li key={i}>{row}</li>)}
              </ol>
              {value.length > 5 && <Container style={this.negativeTopMargin}><Icon name="ellipsis vertical" size="small" /></Container>}
            </>);
          },
          cellProps: { textAlign: "left" }
        }))
      )
    }
    if (process.env.REACT_APP_GROUP_ACTIVITY_SUPPORT) {
      this.tableColumnData.splice(-1, 0,
        {
          headerName: '恆常活動',
          width: 4,
          allowSort: false,
          cellRender: x => {
            const hasActivities = Array.isArray(x.activities) && x.activities.length;
            return (<>
              <ol className="list-less-padding">
                {hasActivities ? x.activities.slice(0, 5).map(activity => <li key={activity.id}>{activity.title}</li>) : "-"}
              </ol>
              {hasActivities && x.activities.length > 5 && <Container style={this.negativeTopMargin}><Icon name="ellipsis vertical" size="small" /></Container>}
            </>);
          },
          cellProps: { textAlign: "left" }
        }
      )
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try {
      const groups = await get('getGroup');
      for (let group of groups) {
        if (!group.info) {
          group.info = {};
        } else {
          group.info = pick(group.info, this.group_info_fields);
        }
      }
      if (process.env.REACT_APP_GROUP_ACTIVITY_SUPPORT) {
        const groupLookUp = new Map(groups.map((x, i) => [x.id, x]));
        const groupActivities = await get('getGroupActivity');
        for (let activity of groupActivities) {
          if (!groupLookUp.has(activity.groupId)) { continue; }
          if (!groupLookUp.get(activity.groupId).hasOwnProperty('activities')) {
            groupLookUp.get(activity.groupId).activities = [];
          }
          groupLookUp.get(activity.groupId).activities.push(activity);
        }
      }

      await this.setStateAsync({ groups });
    } catch (err) {
      console.log(err)
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;

    await this.fetch();
    await this.setStateAsync({
      finishedLoading: true
    });
  }

  /* input update handler */
  inputChange = (event, data) => {
    const { inputType, stateName } = event.target.closest('.ui, button, textarea').dataset;
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value })
  }

  /* info update handler */
  infoChange = (event, data) => {
    const { inputType, stateName } = event.target.closest('.ui, button, textarea').dataset;
    const info = { ...this.state.info };
    info[stateName] = inputHandler(inputType, data);
    this.setStateAsync({ info })
  }

  editActivity = (event, data) => {
    let value = inputHandler('text', data);
    const { id } = event.target.closest('.ui').dataset;
    const activities = [...this.state.activities];
    const itemIndex = findIndex(activities, { id: +id });
    if (itemIndex > -1) {
      const activity = { ...activities[itemIndex] };
      activity.title = value;
      activities[itemIndex] = activity;
      this.setStateAsync({
        activities
      });
    }
  }

  addActivity = () => {
    const activities = [...this.state.activities].concat({
      title: '',
      groupId: this.state.selectedId || undefined,
      id: this.newId--,
    });
    this.setStateAsync({
      activities
    });
  }

  deleteActivity = (event) => {
    const { id } = event.target.closest('.ui').dataset;
    this.setStateAsync({
      activities: this.state.activities.filter(x => x.id !== +id)
    });
  }

  /* modal toggle */
  modalToggle = (eventOrStateName) => {
    let modalname, id;
    if (typeof eventOrStateName === 'object') {
      let modalData = {};
      if (eventOrStateName.target.classList.contains('modals')) {
        modalData = eventOrStateName.target.firstElementChild.dataset;
      } else {
        modalData = eventOrStateName.target.closest('.ui, button').dataset;
      }
      modalname = modalData.modalname;
      id = +modalData.id;
    } else {
      modalname = eventOrStateName;
    }
    const data = {
      [modalname]: !this.state[modalname],
    }
    if (modalname === 'isSaveModalOpen') {
      this.setStateAsync(data);
      return;
    }
    
    data.selectedId = id;

    this.validator.hideMessages();
    if (id) {
      const gp = find(this.state.groups, { 'id': id }) || {};
      Object.assign(data, {
        ...(pick(gp, ['name', 'info', 'type'])),
        activities: Array.isArray(gp.activities) ? gp.activities : []
      });
    } else {
      Object.assign(data, {
        name: '',
        info: {},
        type: this.group_types[0],
        activities: [],
      });
    }

    for (let info_field of this.group_info_fields) {
      if (!data.info[info_field]) {
        data.info[info_field] = '';
      }
    }

    this.setStateAsync(data);
  }


  save = async (event) => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }

    const data = pick(this.state, ['name', 'info', 'type']);
    data.id = this.state.selectedId;

    if (process.env.REACT_APP_GROUP_ACTIVITY_SUPPORT) {
      data.activities = this.state.activities.map(x => x.id < 0 ? { ...x, id: undefined } : x);
    }

    try {
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      try {
        const result = await post('editGroup', data);
        if (result && result.status) {
          await this.fetch();
          this.modalToggle('isEditModalOpen');
        } else {
          throw new Error(result);
        }
      } catch (err) {
        console.log("error when editing group data:");
        console.log(err);
      }
    } catch (err) {
      if (err !== 'unmounted') {
        alert("儲存科組時發生錯誤");
        console.log('Error when editing group data', err);
      }
    }
    this.modalToggle('isSaveModalOpen');
    this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true
    });
  }

  delete = async () => {
    const { selectedId } = this.state;
    let data = { id: selectedId };
    try {
      await this.setStateAsync({
        dimmerOpen: true,
        finishedLoading: false
      });
      const result = await post('deleteGroup', data)
      if (result && result.status) {
        await this.fetch();
      } else {
        throw result;
      }
    } catch (err) {
      if (err !== 'unmounted') {
        alert("刪除科組時發生錯誤");
        console.log('Error when deleting group', err);
      }
    }
    this.modalToggle('isDeleteModalOpen');
    this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true
    });
  }

  render() {
    const {
      dimmerOpen,

      isEditModalOpen,
      isDeleteModalOpen,
      isSaveModalOpen,

      name,
      info,
      type,
      activities,

      groups,
    } = this.state;

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='科組設置' subTitle='設置科目及行政組別' />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Buttons.AddButton
                content='新增科組'
                floated='right'
                data-modalname='isEditModalOpen'
                onClick={this.modalToggle}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <MySortableTable
                data={groups}
                tableColumnData={this.tableColumnData}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen ? (<FullscreenDimmer active={dimmerOpen} isLoading={true} />) : (
          <>
            <Modal open={isEditModalOpen} data-modalname='isEditModalOpen' onClose={this.modalToggle} closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>編輯科組</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    <Form.Group className="form-group-margin">
                      <Form.Input inline required value={name} onChange={this.inputChange} data-input-type='text' data-state-name='name' label='組名' placeholder='組名' />
                      {this.group_types.map(the_type => (
                        <Form.Checkbox
                          key={the_type}
                          name="type"
                          value={the_type}
                          radio
                          checked={type === the_type}
                          label={the_type}
                          onChange={this.inputChange}
                          data-input-type='select'
                          data-state-name='type'
                        />
                      ))}
                    </Form.Group>
                    <Form.Group>{this.validator.message('name', name, 'required')}</Form.Group>
                    {this.group_info_fields.map(field => <Form.Group className="form-group-margin" key={field}>
                      <Form.TextArea
                        width={16}
                        rows={5}
                        value={info[field]}
                        label={`${field} (每個毋須輸入1,2,3,4,5...)`}
                        onChange={this.infoChange}
                        data-input-type='text'
                        data-state-name={field}
                      />
                    </Form.Group>)}
                    {process.env.REACT_APP_GROUP_ACTIVITY_SUPPORT && (<Form.Group>
                      <Form.Field width={16}>
                        <label>恆常活動</label>
                        <Segment.Group>
                          {activities.length ? activities.map(activity => (
                            <GroupModalSegment
                              key={activity.id}
                              title={activity.title}
                              id={activity.id}
                              editActivity={this.editActivity}
                              deleteActivity={this.deleteActivity}
                            />
                          )) : <Segment><Header as='h4' textAlign="center">無活動</Header></Segment>}
                        </Segment.Group>
                        <Container textAlign='center'>
                          <Buttons.AddButton onClick={this.addActivity} />
                        </Container>
                      </Form.Field>
                    </Form.Group>)}
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Buttons.CancelButton data-modalname='isEditModalOpen' onClick={this.modalToggle} />
                <Buttons.SaveButton data-modalname='isSaveModalOpen' onClick={this.modalToggle} />
              </Modal.Actions>
            </Modal>
            <ConfirmModal
              open={isSaveModalOpen}
              description='確定儲存科組資料？'
              modalname='isSaveModalOpen'
              cancel={this.modalToggle}
              confirm={this.save}
              confirmIcon='check'
              confirmText='儲存'
            />
            <ConfirmModal
              open={isDeleteModalOpen}
              description={<>確定刪除 <span className='red'>{name}</span> 科組？<span className='red bold'>注意：科組不同年度的評核、人員等資料將一併被刪除，此操作<span className='underline'>無法還原</span></span></>}
              modalname='isDeleteModalOpen'
              cancel={this.modalToggle}
              confirm={this.delete}
            />
          </>
        )}
      </>
    )
  }
}

export default withRouter(Group);