import { useEffect, useRef } from 'react';
import { orderBy, findIndex, keyBy, find } from 'lodash';
import renderHTML from 'react-render-html';

import {get} from './ApiHelper';
import {dateToMoment} from './TimeHelper';

/* sorting table */

// {column: string, sortDirection: "ascending"|"descending", isIntegerSort: boolean}[]
const sortStack = [];
const sortLookup = {ascending: "asc", descending: "desc"};

/**
 * Call this function when retrieving data everywhere in your component
 * @param {Object[]} data 
 */
export const sortData = (data) => {
  if(sortStack.length){
    return orderBy(data, sortStack.map(x=>x.isIntegerSort?(o=>+o[x.column]):x.column), sortStack.map(x=>sortLookup[x.sortDirection]))
  }
  return data;
}

/**
 * Table Sorting now stores the sorting stack, should be backward compatible
 * @param {string} clickedColumn your column name
 * @param {Object[]} data your data array
 * @param {any} sortColumn Unused
 * @param {any} sortDirection Unused
 * @param {Object.<string, boolean>} intSort an object of format {[columnName]: boolean} showing if integer sorting is desired (i.e. 2<10)
 */
export const tableSorting = (clickedColumn, data = [], sortColumn, sortDirection, intSort={}) => {
  if(sortStack.length && sortStack[0].column === clickedColumn){
    if(sortStack[0].sortDirection === 'ascending')
      sortStack[0].sortDirection = 'descending';
    else
      sortStack[0].sortDirection = 'ascending';
  }else{
    let index = findIndex(sortStack, {column: clickedColumn});
    if(index>-1) sortStack.splice(index,1);
    sortStack.unshift({
      column: clickedColumn,
      sortDirection: "ascending",
      isIntegerSort: intSort.hasOwnProperty(clickedColumn)
    });
  }
  return ({
    sortDirection: sortStack[0].sortDirection,
    data: sortData(data)
  })
}

/**
 * Generate select options for semantic-ui-react
 * @param {any[]} data source data
 * @param {(string|function)} textField  Property name or function on each entry
 * @param {(string|function)} valueField Property name or function on each entry
 * @param {boolean} useValueAsKey whether value is used as key
 * @param {(item: any)=>boolean} disabledField generator for whether the option is disabled
 */
export const selectOptions = (result, textField, valueField, useValueAsKey = false, disabledField = ()=>{}) => {
  const genValue = typeof(valueField)==='function' ? valueField : x=>x[valueField];
  const genText = typeof(textField)==='function' ? textField : x=>x[textField];
  const genDisabled = typeof(disabledField)==='function' ? disabledField : x=>x[disabledField];
  return result.map((record, i) => ({
    key: useValueAsKey ? genValue(record, i) : i,
    text: genText(record, i),
    value: genValue(record, i),
    disabled: genDisabled(record, i),
  }));
}

/**
 * Generate select options by joining different fields as the displayName
 * @param {any[]} data 
 * @param {string[]} textField 
 * @param {(string|function)} valueField 
 */
export const selectOptionsWithCombineName = (data, textField = [], valueField) => {
  return selectOptions(data, record=>(textField.map(field=>(record[field]||'')).join(' ').trim()), valueField);
}

/* mark options for select box */
export const markSelectOptions = (min, max, step, notApplicable) => {
    let options = [];

    if(notApplicable) {
        options.push({text: '不適用', value: 'Not applicable'});
    }
    
    for(let i = min; i <= max; i += step) {
        options.push({text: i, value: i})
    }

    return options;
}

/* turn text area text to html format */
export const textareaText = (text) => {
    if(text) {
        return renderHTML(text.replace(/\n\r?/g, '<br />'));
    }else{
        return '-';
    }
}

/**
 * Get the default yearId (default to this year)
 * @param {object[]} year data retrieved from getYear
 * @returns {''|number} the default yearId
 */
export const defaultYear = (year) => {
  if(!year.length) return '';
  let lastIndex = year.length - 1;
  let defaultId = year[lastIndex].id;
  for(let record of year){
    if (dateToMoment().isBetween(record.startDate, record.endDate)) {
      return record.id;
    }
  }
  return defaultId;
}

/**
 * Get the default year for planning (originally intended to default to next year) now just an alias for defaultYear.
 * @param {object[]} year data retrieved from getYear
 * @returns {''|number} the default yearId
 */
export const defaultYearForPlan = (year) => {
  if(!year.length) return '';
  let lastIndex = year.length - 1;
  let defaultId = year[lastIndex].id;
  const nextYear = dateToMoment()//.add(1, 'y');
  for(let record of year){
    if (nextYear.isBetween(record.startDate, record.endDate)) {
      return record.id;
    }
  }
  return defaultId;
}

export const threeYearPlanAndWhichYearFromYear = (years, threeYearPlans, yearId) => {
  if(!threeYearPlans || threeYearPlans.length < 1){
    return [null,null];
  }
  const yearLookUp = keyBy(years, 'id');
  
  let m;
  if(!yearId) yearId = defaultYearForPlan(years);
  try{
    if(yearId){
      m = dateToMoment(yearLookUp[yearId].startDate);
    }else{
      m = dateToMoment().add(1,'y');
    }
  }catch(err){
    m = dateToMoment().add(1,'y');
  }
  let diff = null;

  let typ = find(threeYearPlans, x=>{
    diff = m.diff(yearLookUp[x.beginYear].startDate, 'years');
    return diff>=0 && diff<3;
  });
  if(typ)
    return [typ.id, Math.floor(diff)];
  return [null,null];
}

/* round number to 2 dp */
export const roundTwoDp = (num) => {
    return Math.round(num * 100) / 100;
}

/* map each array content to text,value pair (same) */
export const arrayToOption = (ar) => ar.map(x=>({text:x, value:x}))

/* map each array content to text,value pair (same) */
export const arrayToSearch = (ar) => ar.map(x=>({title:x}))

export const promiseTest = () => {
  const x = new Promise((res, rej) => { setTimeout(() => res(true), 1000) });
  return x;
}

export const extractStartYear = (yearObject) => +(yearObject.startDate.replace(/-.+/,''));

/**
 * Round a number without 1.005 error
 * @param {number} num number to be rounded
 * @param {number} sig number of decimal places to be rounded
 */
export const numRound = (num, sig=2) => Math.round( ( num + Number.EPSILON ) * Math.pow(10,+sig) ) / Math.pow(10,+sig)

export const getQuestionAverage = q => {
  const result = numRound(Array.from({length:5}).reduce((prev,cur,i)=>prev+q[i]*i, 0) / q.slice(1).reduce((prev,cur)=>prev+cur, 0), 2)
  return Number.isNaN(result) ? "NaN": result;
};

export const genTeacherOptionName = x => x?(x.name+(x.email?` (${x.email.split('@')[0].replace(/\..+/,'')})`:'')):"";
export const genStudentOptionName = x => x?`${[x.chiName,x.engName].filter(x=>x).join(", ")} (${x.classCode}${x.classNo<10?"0":""}${x.classNo})`:"";

// const targetMap = ['', '教師', '學生', '師生'];
// const engTargetMap = ['', 'Teachers ', 'Students ', 'Teachers and students '];

export const genEvaluationTitle = e=> e.criteria; //(e, isEnglish) => [`${e.criteria}${isEnglish?' achieves ':'達到'}${e.threshold}${e.unit}`, `${(isEnglish?engTargetMap:targetMap)[e.target]}${e.criteria}`, e.criteria][e.isSurvey] || '' ;

/**
 * Returns true if it's before the year started AND after the school year finished for 1 year
 * @param {Object[]} yearInfo 
 * @param {number} yearId 
 */
export const whetherReadOnlyForReport = (yearInfo, yearId) => {
  const curYear = find(yearInfo, {id: yearId});
  if(!curYear) return true;
  const lastCanEdit = dateToMoment(curYear.endDate).add(1, 'years');
  const earliestEdit = dateToMoment(curYear.startDate);
  const now = dateToMoment();
  let cantEdit = now.diff(lastCanEdit)>0 || earliestEdit.diff(now)>0;
  return cantEdit;
}

/**
 * Returns true if it's before 1 year prior to start of school year OR after the school year ended
 * @param {Object[]} yearInfo 
 * @param {number} yearId 
 */
export const whetherReadOnlyForYearPlan = (yearInfo, yearId) => {
  const curYear = find(yearInfo, {id: yearId});
  if(!curYear) return true;
  const startDate = dateToMoment(curYear.startDate);
  const earliestEdit = startDate.subtract(1, 'years').month(8).date(1);
  const latestEdit = dateToMoment(curYear.endDate);
  const now = dateToMoment();
  let cantEdit = now.diff(latestEdit)>0 || earliestEdit.diff(now)>0;
  return cantEdit;
}


/**
 * Returns false
 * @param {Object[]} yearInfo 
 * @param {number} yearId 
 */
export const whetherReadOnlyForTYP = (yearInfo, TYP, TYPId) => {
  return false;
  const curTYP = find(TYP, {id: TYPId});
  if(!curTYP) return true;
  const startYear = find(yearInfo, {id: curTYP.beginYear});
  if(!startYear) return true;
  const startDate = dateToMoment(startYear.startDate);

  const now = dateToMoment();
  let cantEdit = now.diff(startDate)>0
  return cantEdit;
}


/**
 * Returns true if it's before 1 year prior to END of Three Year Period OR after the Three Year Period ended for 1 year
 * @param {Object[]} yearInfo 
 * @param {number} yearId 
 */
export const whetherReadOnlyForTYPResult = (yearInfo, TYP, TYPId) => {
  const curTYP = find(TYP, {id: TYPId});
  if(!curTYP) return true;
  const startYear = find(yearInfo, {id: curTYP.beginYear});
  if(!startYear) return true;
  const startDate = dateToMoment(startYear.startDate).clone().add(2, 'years').month(8).date(1);

  const now = dateToMoment();
  let cantEdit = now.diff(startDate, 'years')>=2 || now.diff(startDate)<0;
  return cantEdit;
}


/**
 * Returns true if it's before 1 year prior to start of school year OR after the school year ended
 * @param {Object[]} yearInfo 
 * @param {number} yearId 
 */
export const whetherReadOnlyForTeacher = whetherReadOnlyForYearPlan

/**
 * Returns true if it's before the start of school year OR after the school year's midDate
 * @param {Object[]} yearInfo 
 * @param {number} yearId 
 */
export const whetherReadOnlyForMidReport = (yearInfo, yearId) => {
  const curYear = find(yearInfo, {id: yearId});
  if(!curYear) return true;
  const startDate = dateToMoment(curYear.startDate);
  const midDate = dateToMoment(curYear.midDate);
  const endDate = dateToMoment(curYear.endDate);

  return startDate.isAfter() || endDate.isBefore()//midDate.isBefore();
}

export const toConcernAggregate = (aggregate) => {
  const aggregateInfo = {};
      
  aggregate.forEach(x=>{
    aggregateInfo[x.Cid] = (aggregateInfo[x.Cid]||[]).concat(x.x)
  });

  Object.keys(aggregateInfo).forEach(x=>{
    aggregateInfo[x] = aggregateInfo[x].reduce((p,c)=>p+c,0)/aggregateInfo[x].length
  });

  return aggregateInfo;
}

function makeCancelable(promise) {
  let isCanceled = false;
  const wrappedPromise =
    new Promise((resolve, reject) => {
      promise
        .then(
          val => (isCanceled
            ? reject(new Error({ isCanceled })) 
            : resolve(val))
        )
        .catch(
          error => (isCanceled
            ? reject(new Error({ isCanceled }))
            : reject(error))
        );
    });
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

export function useCancellablePromise() {
  const promises = useRef();
  useEffect(() => {
    promises.current = promises.current || [];
    return function cancel() {
      promises.current.forEach(p => p.cancel());
      promises.current = [];
    };
  }, []);

  function cancellablePromise(p) {
    const cPromise = makeCancelable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }
  return { cancellablePromise };
}

export function hasDuplicate(array){
  const a = new Set(array);
  return array.length === a.size;
}

export const genAttendanceString = (item, attendanceMap) => item.attendanceStatusId === 5 ? item.remark: attendanceMap[item.attendanceStatusId];

/**
 * Get and generate teacher options to be used for SEMANTIC UI REACT.
 * @param {number?} yearId The year id. If left blank, the current year is used.
 * @return {Promise<{key: any, value: any, text: any}[]>} The processed array
 */
export const getTeacherOptions = async (yearId) => {
  try{
    if(!yearId){
      const yearInfo = await get('getYear');
      yearId = defaultYear(yearInfo);
    }
    const teachers = await get('getAllTeacherWithAdminWithIndex/'+yearId);
    return selectOptions(teachers.filter(x=>x.status), t=>`${t.index?t.index+'. ':''}${genTeacherOptionName(t)}`.trim(), 'id');
  }catch(err){alert('無法取得教師選項');}
  return [];
}

/**
 * Generate the grade string for ECA
 * @param {object} activity 
 */
export const genGradeString = (activity) => {
  let isContinuous = true;

  let i=1;
  while(i<=6&&!activity['grade_'+i])i++;
  if(i === 7) return '-';
  let minGrade = i;
  while(i<=6&&activity['grade_'+i])i++;
  let maxGrade = i-1;
  while(i<=6){
    if(activity['grade_'+i]) isContinuous = false;
    i++;
  }
  if(!isContinuous) return "P."+[1,2,3,4,5,6].filter(x=>!!activity['grade_'+x]).join(",");
  if(minGrade===maxGrade) return "P."+minGrade;
  return `P.${minGrade}-${maxGrade}`;
}

export const parseJSONArray = str => {
  try{
    const x = JSON.parse(str);
    if(!Array.isArray(x)) throw "";
    return x;
  }catch(err){return [];}
}