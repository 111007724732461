import React, { useEffect, useState, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Loader, Container } from 'semantic-ui-react';
import { User } from '../Context';

const AuthRoute = ({ path, exact, children, redirectTo = '/', antiLogin = false, wide}) => {
  const [{ loading, isAuth }, setLoading] = useState({
    loading: true,
    isAuth: false,
  });
  const { pingUser } = useContext(User);

  useEffect(()=>{
    setLoading({ loading: true, isAuth: false });
    const abortController = new AbortController();
    pingUser({path, signal: abortController.signal}).then((isAuth)=>{
      setLoading({
        loading: false,
        isAuth
      });
    })
    return () => {
      abortController.abort();
    }
  }, [path, pingUser]);

  useEffect(() => {
    if(redirectTo === '/' && !loading && !isAuth){
      console.log('redirect to home');
      window.location.href = '/';
      return;
    }
  }, [redirectTo, loading, isAuth]);

  return (
    <Route path={path} exact={exact}>
      <Container fluid={wide}>
        {
        loading ? <Loader size='big' active /> : (
          !antiLogin !== !isAuth || isAuth === undefined ? children : <Redirect to={redirectTo} />
        )}
      </Container>
    </Route>
  );
};

export default AuthRoute;
