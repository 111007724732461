import moment from 'moment';
import { forEach, filter, find, sumBy, compact, map, uniqBy } from 'lodash';
import { roundTwoDp } from '../../../../Helper/Helper';
const base = 100;

export const FinalReport = (printData, callback) => {
	try {
		const {
			deadline,
			yearInfo,
			appraisalItemTree,
			appraisalContentTree,
			appraisalLevelOption,
			principalInfo,
			teacherData,
		} = printData;
		let content = '';

		forEach(teacherData, (data) => {
			const {
				teacherInfo,
				personalTableRecord,
				personalTarget,
				personalFormRecord,
				personalAppraisalItem,
				personalMarking,
			} = data;

			/* report header */
			if (yearInfo) {
				content += `
                    <div class="header break-page">
                        <div class="title">馬鞍山聖若瑟小學</div>
                        <div class="subtitle">教師個人評鑑總表</div>
                        <div class="subtitle">${yearInfo.displayName}年度</div>
                    </div>
                `;
			}

			/* year and teacher info */
			if (yearInfo && teacherInfo) {
				content += `
                    <div class="teacher-info">
                        <div>年分: ${yearInfo.displayName}</div>
                        <div>老師名稱: ${teacherInfo.name}</div>
                        <div>職位: ${teacherInfo.displayName}</div>
                    </div>
                `;
			}

			/* summary */
			if (appraisalItemTree) {
				let countTips = deadline ? '(總分只計算' + moment(deadline).format('DD/MM/YYYY') + '或以前輸入的分數)' : '';
				content += `
                    <div class="section">
                        <div class="text-bold">整體報告</div>
                        <div>(*所有分數以${base}分為滿分，沒有評估之項目將不會計算百分比)</div>
                        <div>${countTips}</div>
                        <table class="table" border="1">
                            <thead>
                                <tr>
                                    <th class='text-center' width='50%'>評估項目</th>
                                    <th class='text-center' width='10%'>百分比</th>
                                    <th class='text-center' width='40%'>分數/等級</th>
                                </tr>
                            </thead>
                            ${DisplayItem(
															deadline,
															appraisalItemTree,
															personalAppraisalItem,
															appraisalContentTree,
															appraisalLevelOption,
															personalTableRecord,
															personalTarget,
															personalFormRecord,
															personalMarking
														)}  
                        </table>
                    </div>
                `;
			}

			/* sign */
			if (teacherInfo && principalInfo) {
				content += `
                    <div class="section signature">
                        <div>
                            <div class="signature-line"></div>
                            <div>教師簽名</div>
                            <div>(${teacherInfo.name})</div>
                        </div>
                        <div>
                            <div class="signature-line"></div>
                            <div>校長簽名</div>
                            <div>(${principalInfo.name})</div>
                        </div>
                        <div>
                            <div class="signature-line"></div>
                            <div>日期</div>
                        </div>
                    </div>
                `;
			}
		});

		/* print */
		let printWindow = window.open('', '_blank', '');
		printWindow.document.open();
		printWindow.document.write(`
            <html>
                <head>
                    <title>馬鞍山聖若瑟小學教師個人評鑑總表</title>
                    <link rel="stylesheet" type="text/css" href="/css/appraisalReportLayout.css">
                </head>
                <body>
                    <div class="appraisal-report">
                        ${content}
                    </div>
                </body>
            </html>
        `);
		printWindow.document.close();
		printWindow.focus();
		printWindow.onload = () => {
			printWindow.print();
			callback();
		};
	} catch (err) {
		alert('發生錯誤，請再嘗試');
		callback();
	}
};

const DisplayItem = (
	deadline,
	appraisalItemTree,
	personalAppraisalItem,
	appraisalContentTree,
	appraisalLevelOption,
	personalTableRecord,
	personalTarget,
	personalFormRecord,
	personalMarking
) => {
	let content = '';
	let markArray = [];

	forEach(appraisalItemTree, ({ id, tableFormat, children }) => {
		/* not count */
		if (!children && personalAppraisalItem.indexOf(id) < 0) {
			return markArray.push({ id, mark: null, count: false });
		}

		/* table */
		if (tableFormat) {
			let countTableContent = find(appraisalContentTree, { appraisalItemId: id, addUp: 1 });
			let tableLevelOption = filter(appraisalLevelOption, { appraisalItemId: id });
			let tableRecords = compact(personalTableRecord[id]);
			let tableMark = TableMark(deadline, countTableContent, tableLevelOption, tableRecords);
			return markArray.push({ id, mark: tableMark, count: true });
		}

		/* form with children */
		if (children) {
			let totalMark = 0;
			let totalPercentage = 0;
			let countTotalMark = false;
			forEach(children, ({ id, percentage }) => {
				let targets = filter(personalTarget, { appraisalItemId: id, otherStatus: 'complete' });
				let distinctTargets = uniqBy(map(targets, 'appraisalTargetId'));
				let countFormContent = filter(appraisalContentTree, { appraisalItemId: id, section: 'other', count: 1 });
				let mark = FormItemMark(
					deadline,
					id,
					targets,
					distinctTargets,
					countFormContent,
					personalFormRecord,
					personalMarking
				);
				if (mark) {
					totalMark += mark * percentage;
					totalPercentage += percentage;
					countTotalMark = true;
				}
			});

			return markArray.push({
				id,
				mark: countTotalMark ? totalMark / totalPercentage : null,
				count: countTotalMark ? true : false,
			});
		}

		/* form */
		if (!children) {
			let targets = filter(personalTarget, { appraisalItemId: id, otherStatus: 'complete' });
			let distinctTargets = uniqBy(map(targets, 'appraisalTargetId'));
			let countFormContent = filter(appraisalContentTree, { appraisalItemId: id, section: 'other', count: 1 });
			let mark = FormItemMark(
				deadline,
				id,
				targets,
				distinctTargets,
				countFormContent,
				personalFormRecord,
				personalMarking
			);

			return markArray.push({
				id,
				mark: mark,
				count: mark ? true : false,
			});
		}
	});

	const itemTotalPercentage = sumBy(map(appraisalItemTree, 'percentage'));
	let finalTotal = 0;
	let countFinalTotal = false;
	forEach(appraisalItemTree, ({ id, displayName, percentage }) => {
		const item = find(markArray, { id });
		let mark = '不適用';
		if (item && item.count) {
			mark = (item.mark * percentage) / itemTotalPercentage;
			finalTotal += mark;
			countFinalTotal = true;
		}

		content += `
            <tr>
                <td>${displayName}</td>
                <td class="text-center">${percentage}%</td>
                <td class="text-center">${roundTwoDp(mark)}</td>
            </tr>
        `;
	});

	content += `
        <tr>
            <td colspan="2">總分</td>
            <td class="text-center">${countFinalTotal ? roundTwoDp(finalTotal) : '不適用'} / ${base}</td>
        </tr>
    `;

	return content;
};

const TableMark = (deadline, countTableContent, tableLevelOption, tableRecords, useBase = true) => {
	if (deadline) {
		tableRecords = filter(tableRecords, ({ submitDate }) => {
			return moment(submitDate).isSameOrBefore(deadline, 'day');
		});
	}

	let total = sumBy(tableRecords, countTableContent.id);
	let contentMark = 0;

	forEach(tableLevelOption, ({ mark, min }) => {
		if (total >= min && mark > contentMark) {
			return (contentMark = mark);
		}
	});

	if (useBase) {
		let max = Math.max(...map(tableLevelOption, 'mark'));
		return (contentMark / max) * base;
	}

	return contentMark;
};

const FormItemMark = (
	deadline,
	id,
	targets,
	distinctTargets,
	countFormContent,
	personalFormRecord,
	personalMarking
) => {
	let totalTargetMark = 0;
	let totalTargetPrecentage = 0;
	let countTotalTargetMark = false;

	forEach(distinctTargets, (appraisalTargetId) => {
		let markingTeacher = map(
			filter(personalMarking, (marking) => {
				return (
					marking.appraisalTargetId === appraisalTargetId && moment(marking.submitDate).isSameOrBefore(deadline, 'day')
				);
			}),
			'teacherId'
		);
		let targetWithRecord = filter(targets, { appraisalTargetId });
		let totalMark = 0;
		let totalPercentage = 0;
		let countTotalMark = false;
		forEach(targetWithRecord, ({ appraisalTargetId, teacherId, percentage }) => {
			let records = filter(personalFormRecord, { appraisalItemId: id, appraisalTargetId, teacherId });
			let targetMark = FormMark(countFormContent, records);
			if (targetMark !== null && markingTeacher.indexOf(teacherId) >= 0) {
				totalMark += targetMark * percentage;
				totalPercentage += percentage;
				countTotalMark = true;
			}
		});

		if (countTotalMark) {
			totalTargetMark += totalMark / totalPercentage;
			totalTargetPrecentage += 1;
			countTotalTargetMark = true;
		}
	});

	return countTotalTargetMark ? totalTargetMark / totalTargetPrecentage : null;
};

const FormMark = (countFormContent, records) => {
	let totalMark = null;
	let totalPercentage = null;
	let countMark = false;
	forEach(countFormContent, ({ id, max, percentage, children }) => {
		let mark = null;
		if (children) {
			let returnMark = FormMark(filter(children, { count: 1 }), records);
			if (returnMark !== null) mark = returnMark;
		} else {
			let markRecord = find(records, { appraisalContentId: id });
			if (markRecord && markRecord.score) mark = (markRecord.score / max) * base;
		}

		if (mark !== null) {
			totalMark += mark * percentage;
			totalPercentage += percentage;
			countMark = true;
		}
	});

	return countMark ? totalMark / totalPercentage : null;
};
