import {fromBlankAsync} from 'xlsx-populate';
import {momentToHtmlInput} from './TimeHelper';

export const exportExcel = (file, filename) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // If IE, you must uses a different method.
    window.navigator.msSaveOrOpenBlob(file, filename + '.xlsx');
  } else {
    var url = window.URL.createObjectURL(file);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = filename + '.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}

/**
 * Function to convert number to excel title (1 -> A, 26->Z, 27->AA)
 * @param {number} n 
 * @returns {string} The Column Title
 */
export const convertToColumnTitle = n => {
  if(!Number.isInteger(n)) return "";
  if (n < 27) {
    return String.fromCharCode(((n - 1) % 26) + 65);
  }
  return (
    convertToColumnTitle(Math.floor((n - 1) / 26)) +
    String.fromCharCode(((n - 1) % 26) + 65)
  );
};

/**
 * Generate a simple Excel Table
 * @param {object[]} data 
 * @param {string} title 
 * @param {object[]} colData 
 */
export const genSimpleExcel = async (data = [], title='', colData, showSchoolName = true) => {
  if([data, colData].some(x=>!Array.isArray(x) || !x.length)){
    alert("無資料");
    return;
  }

  const aggregateData = [colData.map(x=>x.headerTitle)];
  for(let entry of data){
    const row = colData.map(x=>typeof x.render === 'string'?entry[x.render]:x.render(entry));
    aggregateData.push(row);
  }

  let filename = `${title} (${momentToHtmlInput()}更新版)`;
  
  const workbook = await fromBlankAsync();
  const sheet = workbook.sheet(0);

  const tableStartRow = 3+!!showSchoolName;
  
  sheet.cell(`A${tableStartRow}`).value(aggregateData);
  sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true } }).autoFilter();

  const colSpan = convertToColumnTitle(colData.length);
  
  sheet.range(`A${tableStartRow-2}:${colSpan}${tableStartRow-2}`).merged(true).startCell().value(filename);
  sheet.range(`A1:${colSpan}${tableStartRow}`).style({ bold: true, underline: true });

  if(showSchoolName){
    sheet.range(`A1:${colSpan}1`).merged(true).startCell().value(process.env.REACT_APP_SCHOOL_NAME);
  }
  
  sheet.usedRange().style({ horizontalAlignment: 'center', verticalAlignment: 'center', fontFamily: '標楷體', wrapText: true, fontSize: 12});
  
  sheet.row(1).height(31).style({fontSize: 18});
  sheet.row(tableStartRow).height(26).style({fontSize: 14});

  let widthSum = 0;
  for(let i=0;i<colData.length;i++){
    const colName = convertToColumnTitle(i+1);
    widthSum += Number(colData[i].width)||0;
    sheet.column(colName).width(colData[i].width);
  }

  sheet.cell("A"+(tableStartRow-2)).style({fontSize: 18}).row().height(widthSum>=96?31:60);
  

  const file = await workbook.outputAsync();
  exportExcel(file, filename);
}

export {fromBlankAsync};