/* need open, close, save function */

/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import ErrorLabel from './ErrorLabel';
import FullscreenDimmer from './FullscreenDimmer';

/* import helper */
import { post } from '../Helper/ApiHelper';
import { inputHandler } from '../Helper/FormHelper';

/* import semantic-ui element */
import { Modal, Button, Form, Segment } from 'semantic-ui-react';

class SignModal extends Component {
    state = {
        dimmerOpen: false,
        sign: '',
        error: false,
    }

    /* Input change */
    inputChange = (inputType, stateName) => (event, data) => {
        let value = inputHandler(inputType, data);
        this.setState({[stateName]: value});
    }

    confirm = () => {
        const { sign } = this.state;
        this.setState({dimmerOpen: true});
        post('verifySign', {sign}).then(({status}) => {
            this.setState({dimmerOpen: false});
            if(status) {
                this.setState({
                    sign: '',
                    error: false
                });
                this.props.close();
                this.props.save();
            }else{
                this.setState({error: true});
            }
        });
    }

    render() {
        const  { open, close } = this.props;
        const  { dimmerOpen, sign, error } = this.state;
        return (
            <Fragment>
                {dimmerOpen?
                    <FullscreenDimmer />
                    :
                    <Modal open={open} onClose={close} closeOnEscape={false} closeOnDimmerClick={false}>
                        <Modal.Content>
                            <Segment basic>
                                    <Form>
                                        <Form.Group className={'form-group-margin'} grouped>
                                            <Form.Input onChange={this.inputChange('text', 'sign')} type='password' value={sign} label='簽名密碼' placeholder='簽名密碼' />
                                            {error &&
                                                <ErrorLabel message='簽名密碼錯誤' />
                                            }
                                        </Form.Group>
                                    </Form>
                                </Segment>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='red' content='取消' icon='close' onClick={close} circular />
                            <Button color='green' content='提交' icon='check' onClick={this.confirm} circular />
                        </Modal.Actions>    
                    </Modal>
                }
            </Fragment>
        )
    }
}

export default withRouter(SignModal);