/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

/* import components */
import {
  FullscreenDimmer,
	PageHeader,
	EmptyTableMsg,
  Buttons
} from '../../../Components';

/* import helper functions */
import { get } from '../../../Helper/ApiHelper';
import { timeToStringFormat, timeToDescription } from '../../../Helper/TimeHelper';
import { exportExcel } from '../../../Helper/ExcelHelper';
import {parseJSONArray} from '../../../Helper/Helper';

/* import lodash */
import { map, groupBy, get as _get } from 'lodash';

/* import semantic-ui element */
import { Grid, Header, Segment, Table, Label } from 'semantic-ui-react';

/* import xlsx populate */
import {fromBlankAsync, RichText} from 'xlsx-populate';

class OutsideActivityDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: true,
      finishedLoading: false,
      section: this.props.match.params.section || null,
      yearId: this.props.match.params.yearId || null,
      canEdit: false,
      outsideActivityId: this.props.match.params.outsideActivityId || null,
      outsideActivity: {
        parents: [],
      },
      outsideActivityTeachers: [],
      outsideActivityStudents: [],
      events: new Map(),
      awards: new Map(),
    }
  }

  setStateAsync = (state) => {
    const x = new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    });
    return x;
  }

  fetchData = async () => {
    const {outsideActivityId} = this.props.match.params;
    try{
      const [
        outsideActivity,
        outsideActivityTeachers,
        outsideActivityStudents,
      ] = await Promise.all([
        get('getOneOutsideActivityWithDetails/' + outsideActivityId),
        get('getOutsideActivityTeacher/' + outsideActivityId),
        get('getOutsideActivityStudent/' + outsideActivityId),
      ]);

      const events = new Map(), awards = new Map();

      if(_get(outsideActivity, [0, 'outsideActivityType']) === '比賽'){
        let [eventRawData, awardRawData] = await Promise.all([
          get('getOutsideActivityEvents/' + outsideActivityId),
          get('getOutsideActivityAwards/' + outsideActivityId)
        ]);
        const groupedAwards = groupBy(awardRawData, 'eventId');
        for(let e of eventRawData){
          let studentIds = parseJSONArray(e.studentIds);
          if(e.name){
            for(let s of studentIds){
              if(!events.has(s)){events.set(s, [])}
              events.get(s).push(e.name)
            }
          }
          if(groupedAwards[e.id]){
            for(let award of groupedAwards[e.id]){
              const students = parseJSONArray(award.students);
              if(students.length){
                for(let s of students){
                  if(!awards.has(s)){awards.set(s, [])}
                  awards.get(s).push([e.name, award.prizeName].filter(x=>x).join(' - '));
                }
              }else{
                for(let s of studentIds){
                  if(!awards.has(s)){awards.set(s, [])}
                  awards.get(s).push([e.name, award.prizeName].filter(x=>x).join(' - '));
                }
              }
            }
          }
        }
        for(let sId of events.keys()){
          events.set(sId, events.get(sId).join('、'));
        }
        for(let sId of awards.keys()){
          awards.set(sId, awards.get(sId).join('\n'));
        }
      }

      const date = _get(outsideActivity, [0, 'date']);

      await this.setStateAsync({
        outsideActivity: outsideActivity[0] || {},
        finishedLoading: true,
        canEdit: date?moment().isBefore(moment(date).add(1, 'y')):false,
        outsideActivityTeachers,
        outsideActivityStudents,
        events,
        awards
      });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching data", err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
    this.setStateAsync({ dimmerOpen: false });
  }
  
  componentWillUnmount = () => {
    this.mounted = false;
  }

  editOutsideActivity = () => {
    this.mounted = false;
    this.props.history.push(`/eca/outsideActivity/${this.state.section}/edit/${this.state.yearId}/${this.state.outsideActivityId}`);
  }

  editStudents = () => {
    this.mounted = false;
    this.props.history.push(`/eca/outsideActivity/${this.state.section}/students/${this.state.yearId}/${this.state.outsideActivityId}`);
  }

  downloadAttendance = async () => {
    let {
      outsideActivity,
      outsideActivityStudents,
    } = this.state;

    if(!outsideActivityStudents.length){
      alert("未有學生資料");
      return;
    }

    let filename = outsideActivity.yearName + ' ' + outsideActivity.name;

    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);

    sheet.cell('A5').value([["學生", "點名"]]);

    sheet.cell('A6').value(
      outsideActivityStudents.map(x=>(
        [
          `${x.classCode} ${x.classNo>9?x.classNo:" "+x.classNo} ${x.chiName||x.engName}`,
          `${x.attendanceStatus || '-'} ${x.attendanceStatus === '其他' && x.remark ? ' - ' + x.remark : ""}`
        ]
      ))
    );

    sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true }, fontSize: 12 });

    sheet.range('A1:B2').merged(true).startCell().value(filename);
    sheet.range('A1:A2').style({fontSize: 16});
    
    sheet.range('A3:B3').merged(true).startCell().value(`日期：${timeToDescription(outsideActivity.date)}`);
    sheet.range('A4:B4').merged(true).startCell().value(`集合地點時間：${outsideActivity.meetLocation||''} ${timeToStringFormat(outsideActivity.meetTime)==='Invalid date'?'未有資料':timeToStringFormat(outsideActivity.meetTime)}`);

    sheet.range('A1:B4').style({bold: true});

    sheet.row(1).height(21);
    sheet.row(2).height(21);
    sheet.row(3).height(21);
    sheet.row(4).height(21);
    
    sheet.usedRange().style({ horizontalAlignment: 'center' });
    sheet.column("A").width(35).style({horizontalAlignment: 'left'});
    sheet.column("B").width(42);
    
    sheet.cell(`A${outsideActivityStudents.length+6}`).value("點名人：").style({horizontalAlignment: 'right'}).row().height(21);
    sheet.cell(`B${outsideActivityStudents.length+6}`).style({border: {bottom:true}});
    sheet.range('A1:A2').style({ horizontalAlignment: 'center' });
    sheet.usedRange().style({ fontFamily: "標楷體", verticalAlignment: 'center', wrapText: true});
    
    const file = await workbook.outputAsync();
    exportExcel(file, filename);
  }

  editAwards = () => {
    this.props.history.push(`/eca/outsideActivity/${this.state.section}/awards/${this.state.yearId}/${this.state.outsideActivityId}`);
  }

  downloadOutsideActivity = async () => {
    let {
      outsideActivity,
      outsideActivityTeachers,
      outsideActivityStudents,
      awards,
    } = this.state;

    const isCompetition = outsideActivity.outsideActivityType==='比賽';

    let filename = outsideActivity.yearName + ' ' + outsideActivity.name;

    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);
    // for classNo
    sheet.column('D').style({horizontalAlignment: 'left'});

    sheet.range('A1:G1').merged(true).startCell().value(process.env.REACT_APP_SCHOOL_NAME).style({ horizontalAlignment: 'center' });

    sheet.range('A2:G2').merged(true).startCell().value('學生參與課外活動/比賽記錄表').style({ horizontalAlignment: 'center' });

    sheet.range('A4:B4').merged(true).startCell().value('活動名稱:');
    sheet.cell('C4').value(outsideActivity.name || '-').style({ border: { bottom: true } });

    sheet.range('D4:E4').merged(true).style({ horizontalAlignment: 'right' }).startCell().value('帶隊老師/家長義工:');
    sheet.cell('F4').value(map(outsideActivityTeachers, 'name').concat(outsideActivity.parents).join(',')).style({ border: { bottom: true } });


    sheet.cell('G4').value(new RichText());
      
    sheet.cell('G4').style({ horizontalAlignment: 'right'}).value().add('活動日期:', {}).add(timeToDescription(outsideActivity.date) || '-', {underline: true, fontFamily: '標楷體'});

    sheet.range('A5:B5').merged(true).startCell().value('活動類型:');
    sheet.cell('C5').value(outsideActivity.outsideActivityType || '-').style({ border: { bottom: true } });
    sheet.range('D5:G5').merged(true).startCell().value('(興趣/服務/學術/比賽/訓練)');

    sheet.range('A6:B6').merged(true).startCell().value('學生人數:');
    sheet.cell('C6').value(outsideActivityStudents.length).style({ border: { bottom: true }, horizontalAlignment: 'left' });
    sheet.range('D6:G6').merged(true).startCell().value('(活動結束後請交 2 份已批改之學生自學課業給泰存檔)');

    sheet.range('A8:B8').merged(true).startCell().value('活動目標:');
    sheet.range('C8:G8').merged(true).style({ border: { bottom: true } }).startCell().value(outsideActivity.target || '-');

    sheet.range('A9:B9').merged(true).startCell().value('關注事項:');
    sheet.range('C9:G9').merged(true).style({ border: { bottom: true } }).startCell().value(outsideActivity.concern || '-');

    sheet.range('A10:B10').merged(true).startCell().value('成效評估:');
    sheet.range('C10:G10').merged(true).style({ border: { bottom: true } }).startCell().value(outsideActivity.outcome || '-');

    sheet.range('A12:B12').merged(true).startCell().value('活動地點:');
    sheet.cell('C12').value(outsideActivity.location || '-').style({ border: { bottom: true } });

    sheet.range('A13:B13').merged(true).startCell().value('集合時間:');
    sheet.cell('C13').value(outsideActivity.meetTime ? timeToStringFormat(outsideActivity.meetTime) : '-').style({ border: { bottom: true } });

    sheet.cell('E13').value('集合地點:');
    sheet.cell('F13').value(outsideActivity.meetLocation || '-').style({ border: { bottom: true } });

    sheet.range('A14:B14').merged(true).startCell().value('解散時間:');
    sheet.cell('C14').value(outsideActivity.leaveTime ? timeToStringFormat(outsideActivity.leaveTime) : '-').style({ border: { bottom: true } });

    sheet.cell('E14').value('解散地點:');
    sheet.cell('F14').value(outsideActivity.leaveLocation || '-').style({ border: { bottom: true } });

    sheet.cell('B16').value([['班別', '學生姓名', '學號', '點名', '備註'].concat(isCompetition?'成績':[])]);
    let studentData = [];
    map(outsideActivityStudents, (x, index) => {
      const a = awards.get(x.studentId) || '-';
      const attendanceRemark = (x.attendanceStatus === '其他' && x.remark) ? ' - ' + x.remark : '';
      studentData.push([
        index + 1,
        x.classCode,
        x.chiName||x.engName,
        Number(x.classNo),
        (x.attendanceStatus||'') + attendanceRemark,
        x.studentRemark,
      ].concat(isCompetition?a:[]));
    });
    if(studentData.length){
      sheet.cell('A17').value(studentData);
      if(isCompetition){
        sheet.column('G').style('wrapText', true);
      }
    }
    sheet.range('A16:' + (isCompetition?'G':'F') + (16 + outsideActivityStudents.length)).style({ border: { left: true, right: true, top: true, bottom: true } });
    sheet.usedRange().style({ fontSize: 12, fontFamily: '標楷體'});
    sheet.range('A1:A2').style({ fontSize: 18 });

    Object.entries({
      A: 36,
      B: 48,
      C: 221,
      D: 62,
      E: 131,
      F: 166,
      G: 304,
    }).forEach(([col, width])=>{
      sheet.column(col).width(width/7.2);
    });

    const file = await workbook.outputAsync();
    exportExcel(file, filename);
  }

  render() {
    const {
      dimmerOpen,
      outsideActivity,
      outsideActivityTeachers,
      outsideActivityStudents,
      events,
      awards,
      canEdit
    } = this.state;

    const isCompetition = outsideActivity.outsideActivityType === '比賽';

    return (
      <>
        {dimmerOpen ?
          <FullscreenDimmer active={dimmerOpen} isLoading={true} />
          :
          <>
            <Grid stackable doubling>
              <Grid.Row>
                <Grid.Column>
                  <PageHeader title='外出帶隊詳情' />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Buttons.EditButton disabled={!canEdit} content='編輯外出帶隊' onClick={this.editOutsideActivity} />
                  <Buttons.EditButton disabled={!canEdit} content='編輯點名' onClick={this.editStudents} />
                  <Buttons.DownloadButton content='下載點名紙' onClick={this.downloadAttendance} />
                  {outsideActivity && isCompetition && <Buttons.EditButton disabled={!canEdit} content='編輯獎項' onClick={this.editAwards} />}
                  <Buttons.DownloadButton content='下載活動報告' onClick={this.downloadOutsideActivity} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Segment.Group className='form-group-margin'>
                    <Segment vertical className='segment-padding'>
                      <Header as='h3'><u>基本資料</u></Header>
                      <Grid stackable>
                        <Grid.Row>
                          <Grid.Column width={4}><b>類型:</b></Grid.Column>
                          <Grid.Column width={12}>{outsideActivity.outsideActivityType || '-'}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}><b>名稱:</b></Grid.Column>
                          <Grid.Column width={12}>{outsideActivity.name || '-'}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}><b>日期:</b></Grid.Column>
                          <Grid.Column width={12}>{timeToDescription(outsideActivity.date) || '-'}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}><b>學生人數:</b></Grid.Column>
                          <Grid.Column width={12}>{outsideActivityStudents.length}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}><b>負責老師:</b></Grid.Column>
                          <Grid.Column width={12}>
                            {outsideActivityTeachers.length > 0 ?
                              <Label.Group size='large'>
                                {outsideActivityTeachers.map(({ teacherId, name }) => (
                                  <Label key={teacherId}>{name}</Label>
                                ))}
                              </Label.Group>
                              :
                              '-'
                            }
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}><b>帶隊家長:</b></Grid.Column>
                          <Grid.Column width={12}>
                            {outsideActivity.parents.length > 0 ?
                              <Label.Group size='large'>
                                {outsideActivity.parents.map((name, i) => (
                                  <Label key={i}>{name}</Label>
                                ))}
                              </Label.Group>
                              :
                              '-'
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    <Segment vertical className='segment-padding'>
                      <Grid stackable>
                        <Grid.Row>
                          <Grid.Column width={4}><b>活動目標:</b></Grid.Column>
                          <Grid.Column width={12} className='textarea-text'>{outsideActivity.target || '-'}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}><b>關注事項:</b></Grid.Column>
                          <Grid.Column width={12} className='textarea-text'>{outsideActivity.concern || '-'}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}><b>成效評估:</b></Grid.Column>
                          <Grid.Column width={12} className='textarea-text'>{outsideActivity.outcome || '-'}</Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    <Segment vertical className='segment-padding'>
                      <Grid stackable>
                        <Grid.Row>
                          <Grid.Column width={4}><b>活動地點:</b></Grid.Column>
                          <Grid.Column width={12}>{outsideActivity.location || '-'}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}><b>集合:</b></Grid.Column>
                          <Grid.Column width={12}>
                            {(outsideActivity.meetTime && outsideActivity.meetLocation) ?
                              timeToStringFormat(outsideActivity.meetTime) + ' ' + outsideActivity.meetLocation
                              :
                              '-'
                            }
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={4}><b>解散:</b></Grid.Column>
                          <Grid.Column width={12}>
                            {(outsideActivity.leaveTime && outsideActivity.leaveLocation) ?
                              timeToStringFormat(outsideActivity.leaveTime) + ' ' + outsideActivity.leaveLocation
                              :
                              '-'
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    <Segment vertical className='segment-padding'>
                      <Header as='h3'><u>學生{isCompetition && '及參賽項目'}資料</u></Header>
                      <Table textAlign='center' fixed padded selectable celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={1} className='textlessCell'>班別</Table.HeaderCell>
                            <Table.HeaderCell width={3}>姓名</Table.HeaderCell>
                            <Table.HeaderCell width={1} className='textlessCell'>學號</Table.HeaderCell>
                            <Table.HeaderCell width={3}>點名</Table.HeaderCell>
                            <Table.HeaderCell width={3}>備註</Table.HeaderCell>
                            {isCompetition&&<><Table.HeaderCell width={5}>項目</Table.HeaderCell>
                            <Table.HeaderCell width={5}>獎項</Table.HeaderCell></>}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {outsideActivityStudents.length > 0 ?
                            <>
                              {outsideActivityStudents.map((x,i,a) => {
                                return (
                                  <Table.Row key={x.studentId}>
                                    <Table.Cell className='textlessCell'>{x.classCode}</Table.Cell>
                                    <Table.Cell>{x.chiName || x.engName}</Table.Cell>
                                    <Table.Cell className='textlessCell'>{x.classNo}</Table.Cell>
                                    <Table.Cell>
                                      {x.attendanceStatus || '-'}  {(x.attendanceStatus === '其他' && x.remark) && ' - ' + x.remark}
                                    </Table.Cell>
                                    <Table.Cell>{x.studentRemark ? x.studentRemark : '-'}</Table.Cell>
                                    {isCompetition && <>
                                      {(!!events.size || !i) && <Table.Cell rowSpan={!i&&!events.size?a.length:undefined}>{!i&&!events.size?'比賽無分項目或未輸入參賽資料':(events.get(x.studentId)||'-')}</Table.Cell>}
                                      <Table.Cell className='textarea-text'>{awards.get(x.studentId)||'-'}</Table.Cell>
                                    </>}
                                  </Table.Row>
                                )
                              })}
                            </>
                            :
                            <EmptyTableMsg colSpan={isCompetition?7:5} />
                          }
                        </Table.Body>
                      </Table>
                    </Segment>
                  </Segment.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        }
      </>
    )
  }
}

export default withRouter(OutsideActivityDetail);