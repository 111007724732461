/* import packages */
import { find, pick } from 'lodash';

/* import react */
import React, { Component, } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  ErrorLabel,
  ConfirmModal,
  MySortableTable,
  FullscreenDimmer,
  MySelect
} from '../../Components';

import {SECTIONDATA} from './Helper';
import {handleExport} from './printHelper';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../Helper/Helper';
import { inputHandler } from '../../Helper/FormHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Segment, Button, Form, List, Modal, Checkbox } from 'semantic-ui-react';

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: [],
      selectedYear: '',
  
      format: [],
      formatOptions: [],
      exportId: '',
      selectedId: '',
  
      finishedLoading: false,
      dimmerOpen: false,
  
      displayName: '',
      section: Array.from({length:SECTIONDATA.length}).fill(null),
      remark: '',
  
      isEditModalOpen: false,
      isDeleteModalOpen: false,
    }

    this.tableColumnData = [
      {width: 4, headerName: '格式名稱', cellRender: 'displayName'},
      {width: 8, headerName: '匯出部份', cellRender: x=>(
        <List
          bulleted
          items={x.section.map((x, i) => x && SECTIONDATA[i].displayName).filter(x => !!x)}
        />
      )},
      {width: 4, headerName: '行動', cellClassName: 'textlessCell', cellRender: x=>(
        <>
          <Button color='blue' icon='edit' data-modalname='isEditModalOpen' data-selected-id={x.id} onClick={this.modalToggle} circular />
          <Button color='red' icon='delete' data-modalname='isDeleteModalOpen' data-selected-id={x.id} onClick={this.modalToggle} circular />
        </>
      )},
    ];

    this.tableProps = {unstackable: true};

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => <ErrorLabel message={message} />,
      messages: {
        required: '請輸入資料',
        some: '請選擇最少一個欄位'
      },
      validators: {
        some: {  // name the rule
          rule: (val, params, validator) => {
            return val.some(x => x)
          },
        },
      }
    });
  }

  tableFooterRender = (data) => (
    <Button
      color='green'
      content='新增預設格式'
      icon='add'
      type='button'
      data-modalname='isEditModalOpen'
      onClick={this.modalToggle}
      circular
    />
  )

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount = () => {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;

    try{
      await Promise.all([
        this.fetchYear(),
        this.fetchFormat()
      ]);
      this.setStateAsync({ finishedLoading: true });
    }catch(err){if(err!=='unmounted')console.log("Error when mounting", err)};
  }

  fetchYear = async () => {
    const result = await get('getYear');
    let selectedYear = defaultYear(result);
    let year = selectOptions(result, 'displayName', 'id');
    await this.setStateAsync({
      year, selectedYear
    })
  }

  fetchFormat = async () => {
    try{
      let format = await get('getAllSenFormat');
      if(Array.isArray(format)){
        format = format.map(x=>({
          ...pick(x, ['id', 'displayName', 'remark']),
          section: SECTIONDATA.map(z => x[z.dbName]),
        }));
        await this.setStateAsync({
          format,
          formatOptions: selectOptions(format, 'displayName', 'id')
        });
      }
      if(!Array.isArray(this.state.format) || !this.state.format.length){
        await this.setStateAsync({ exportId: '' })
      }else{
        if(!find(this.state.format, {id: this.state.exportId})){
          await this.setStateAsync({ exportId: this.state.format[0].id })
        }
      }
    }catch(err){if(err!=='unmounted')console.log("Error when formating SEN report format", err)};
  }
  /* input update handler */
  inputChange = (event, data) => {
    const {inputType, stateName} = event.target.closest('.ui, textarea, button').dataset;
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value })
  }

  /* input update handler */
  formatCheckboxChange = (event, data) => {
    const index = event.target.closest('.ui, button, textbox').dataset.itemIndex;
    let value = inputHandler('checkbox', data);
    const trueData = [...this.state.section];
    trueData[index] = +value || null;
    this.setStateAsync({ section: trueData })
  }

  /* year change */
  yearChange = (event, { value }) => {
    const { selectedYear } = this.state;
    if (selectedYear !== value) {
      this.setStateAsync({ selectedYear: value });
    }
  }

  /* senFormat change */
  senFormatChange = (event, { value }) => {
    const { exportId } = this.state;
    if (exportId !== value) {
      this.setStateAsync({ exportId: value });
    }
  }

  /* export handler */
  export = async (event) => {
    event.preventDefault();
    const { exportId, selectedYear, format, year } = this.state;

    const useFormat = find(format, { id: exportId });

    let yearName = find(year, {value: selectedYear});
    if(yearName){
      yearName = yearName.text.replace(/\s/g, '');
    }else{
      alert('請選擇年份');
      return;
    }

    if (exportId && useFormat && selectedYear){
      try{
        await this.setStateAsync({dimmerOpen: true});
        const [result, groups] = await Promise.all([
          get(`exportSen/${exportId}/${selectedYear}`),
          get(`getYearSenGroup/${selectedYear}`)
        ]);
        if(result && result.data && result.data.length){
          await handleExport(useFormat, groups, yearName, result);
        }else{
          alert('該年度無SEN學生');
        }
      }catch(err){if(err!=='unmounted'){
        alert('匯出時發生錯誤');
        console.log("Error when exporting", err);
      }}
    }else {
      if (format.length === 0)
        alert('請先新增匯出格式');
      else
        alert('請選擇任一匯出格式');
    }
    await this.setStateAsync({dimmerOpen: false});
  }

  /* modal toggle */
  modalToggle = async (eventOrStateName) => {
    try{
      this.validator.hideMessages();
      let editObject = {
        displayName: '',
        section: Array.from({length:SECTIONDATA.length}).fill(null),
        remark: '',
      };
      let modalname, selectedId;
      if(typeof eventOrStateName === 'object'){
        let element;
        if(eventOrStateName.target.classList.contains('modals')){
          element = eventOrStateName.target.firstElementChild;
        }else{
          element = eventOrStateName.target.closest('.ui, button, textarea');
        }

        modalname = element.dataset.modalname;
        selectedId = element.dataset.selectedId;

        if (selectedId) {
          editObject = pick(find(this.state.format, { id: +selectedId }), ['displayName', 'section', 'remark']);
        }
        this.setStateAsync({
          [modalname]: !this.state[modalname],
          selectedId: selectedId||null,
          ...editObject
        });
      }else{
        this.setStateAsync({
          [eventOrStateName]: !this.state[eventOrStateName],
          selectedId: null,
          ...editObject
        });
      }
    }catch(err){}
  }

  /* save default format */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    const { section, selectedId } = this.state;
    let data = pick(this.state, ['displayName', 'remark']);
    section.forEach((x, i) => {
      data[SECTIONDATA[i].dbName] = x;
    });
    data.id = selectedId;

    try{
      this.setStateAsync({ dimmerOpen: true, finishedLoading: false });
      const result = await post('editSenFormat', data);
      if(result && result.status){
        await this.fetchFormat();
        await this.modalToggle('isEditModalOpen');
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存格式時發生錯誤");
        console.log("Error when saving SEN format", err);
      }
    }
    this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true, 
    });
  }

  /* delete format */
  delete = async () => {
    let data = { id: this.state.selectedId };
    try {
      const result = await post('deleteSenFormat', data);
      if (result && result.status) {
        await this.fetchFormat();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("刪除格式時發生錯誤");
        console.log("Error when deleting SEN format", err);
      }
    };
    await this.modalToggle('isDeleteModalOpen');
    this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true, 
    });
  }

  render() {
    const {
      year,
      selectedYear,
      format,
      formatOptions,
      exportId,
      displayName,
      section,
      remark,

      finishedLoading,
      dimmerOpen,

      isEditModalOpen,
      isDeleteModalOpen,
    } = this.state;

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='匯出年度報告' />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment padded>
                <Form onSubmit={this.export}>
                  <Form.Group inline>
                    <Form.Field>
                      <label>預設格式</label>
                      <MySelect
                        options={year}
                        value={selectedYear}
                        onChange={this.yearChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>預設格式</label>
                      <MySelect
                        options={formatOptions}
                        search
                        noResultsMessage='找不到格式'
                        value={exportId}
                        onChange={this.senFormatChange}
                      />
                    </Form.Field>
                    <Button
                      color='green'
                      content='匯出報告'
                      icon='file alternate'
                      type='submit'
                      circular
                    />
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <PageHeader title='預設格式' />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment padded='very'>
                <MySortableTable
                  data={format}
                  tableColumnData={this.tableColumnData}
                  tableFooterRender={this.tableFooterRender}
                  finishedLoading={finishedLoading}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen?(<FullscreenDimmer active={true} isLoading={true} />):(
          <>
            <Modal
              open={isEditModalOpen}
              data-modalname='isEditModalOpen'
              onClose={this.modalToggle}
              size='large'
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <Modal.Header>編輯預設格式</Modal.Header>
              <Modal.Content>
                <Segment padded basic>
                  <Form>
                    <Form.Group grouped>
                      <Form.Input
                        label='格式名稱'
                        placeholder='格式名稱'
                        value={displayName}
                        data-input-type='text'
                        data-state-name='displayName'
                        onChange={this.inputChange}
                      />
                      {this.validator.message('displayName', displayName, 'required')}
                    </Form.Group>
                    <Form.Group grouped>
                      <label>匯出部份</label>
                      <Grid columns={3} padded='vertically' stackable>
                        {SECTIONDATA.map(({ displayName }, index) => (
                          <Grid.Column key={displayName} className='no-vertical-padding'>
                            <Form.Field
                              control={Checkbox}
                              label={`${index + 1}. ${displayName}`}
                              defaultChecked={!!section[index]}
                              data-item-index={index}
                              onChange={this.formatCheckboxChange} />
                          </Grid.Column>
                        )
                        )}
                      </Grid>
                      {this.validator.message('parts', section, 'some')}
                    </Form.Group>
                    <Form.Group grouped>
                      <Form.TextArea
                        label='備註'
                        placeholder='多行備註'
                        value={remark}
                        data-input-type='text'
                        data-state-name='remark'
                        onChange={this.inputChange}
                      />
                    </Form.Group>
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color='red'
                  content='取消'
                  icon='cancel'
                  data-modalname='isEditModalOpen'
                  onClick={this.modalToggle}
                  circular
                />
                <Button
                  color='green'
                  content='儲存'
                  icon='save'
                  onClick={this.save}
                  circular
                />
              </Modal.Actions>
            </Modal>
            <ConfirmModal
              open={isDeleteModalOpen}
              description={<>確定刪除預設格式 <span className="red">{displayName}</span>？本操作不可逆轉！</>}
              modalname='isDeleteModalOpen'
              cancel={this.modalToggle}
              confirm={this.delete}
            />
          </>
        )}
      </>
    )
  }
}

export default withRouter(Report);