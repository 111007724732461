import Summary from './Summary';
import Personal from './Personal';
import Conflict from './Conflict';
import Edit from './Edit';
import Detail from './Detail';
import Attendance from './Attendance';

export default {
  Summary,
  Personal,
  Conflict,
  Edit,
  Detail,
  Attendance,
}