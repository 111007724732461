import Summary from './Summary';
import Personal from './Personal';
import Edit from './Edit';
import Detail from './Detail';
import Attendance from './Attendance';
import Awards from './Awards';

export default {
  Summary,
  Personal,
  Edit,
  Detail,
  Attendance,
  Awards,
}