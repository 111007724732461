/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {keyBy, pick} from 'lodash';

/* import components */
import {
	PageHeader,
	ConfirmModal,
	ErrorLabel,
	BlockerPrompt,
	FlexibleTextField,
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import {
  selectOptions,
  defaultYear,
  whetherReadOnlyForReport
} from '../../../Helper/Helper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator'; //need verify sth

/* import semantic-ui element */
import { Grid, Button, Form, Segment, Select, Table } from 'semantic-ui-react';

class L_And_T extends Component {
  constructor(props) {
    super(props);

    this.actualId = null;

    this.subjectLookup = [
      ['中國語文教育','chi'],
      ['英國語文教育','eng'],
      ['數學教育','math'],
      ['常識科','ge'],
      ['藝術教育','art'],
      ['體育','pe'],
      ['其他','other'],
    ];

    this.fieldLookup = [
      ['測考日', 'testDay', 'integer|min:0,num'],
      ['學生出席率', 'attendance','numeric|min:0,num|max:100,num'],
    ]

    this.state = {
      finishedLoading: false,
      isSaveModalOpen: false,
      isBlocking: false,
      readOnly: true,

      year: [],
      yearInfo: {},

      grades: [],
      reportGradeInfo: [],

      selectedYear: '',
      l_and_t: '',
      l_and_t_fb_and_fu: '',
      schoolDay: 365,
      ...this.subjectLookup.reduce((prev,[a,subName])=>({...prev,[`${subName}Ratio`]: ''}),{})
    }

    this.validator = new SimpleReactValidator({
      element: (message) => {
        let pointing = true;
        switch (message) {
          case '數值太小':
          case '數值太大': pointing = false; break;
          default:break;
        }
        return <ErrorLabel message={message} pointing={pointing} />
      },
      messages: {
        required: '請輸入資料',
        numeric: '請輸入有效數字',
        min: '數值太小',
        max: '數值太大',
        integer: '請輸入有效整數'
      },
      autoForceUpdate: this,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when fetching 3 year Review & SWOP');
      console.log(err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try{
      //get year info
      const rawYear = await get('getYear');
      let year = selectOptions(rawYear, 'displayName', 'id');
      let yearInfo = keyBy(rawYear, 'id');
      const selectedYear = this.state.selectedYear ? this.state.selectedYear : defaultYear(rawYear);

      await this.setStateAsync({year, yearInfo, selectedYear});

      await this.setStateAsync({
        readOnly: whetherReadOnlyForReport(this.state.yearInfo, this.state.selectedYear)
      });

      await this.fetchYearReportData();
    }catch(err){
      console.log('Error when fetching 1 year Report background info');
      console.log(err);
    }
  }

  fetchYearReportData = async () => {
    try{

      const [oneYearReportData] = await get('get1YearReport/' + this.state.selectedYear);
      if(oneYearReportData){
        this.actualId = oneYearReportData.hasOwnProperty('id') ? oneYearReportData.id : null;
        await this.setStateAsync(
          pick(
            oneYearReportData,
            this.subjectLookup.map(([a,subName])=>`${subName}Ratio`).concat('l_and_t', 'l_and_t_fb_and_fu', 'schoolDay')
          )
        );
      }else{
        this.actualId = null;
        const data = {
          l_and_t: '',
          l_and_t_fb_and_fu: '',
          schoolDay: 365,
          reportGradeInfo: []
        };
        this.subjectLookup.forEach(([a,subName])=>{
          data[`${subName}Ratio`] = '';
        });
        await this.setStateAsync(data);
      }
      
      const grades = await get('getGrade');
      await this.setStateAsync({ grades });

      const data = {};

      await Promise.all(
        this.state.grades.map(({id})=>get(`getReportGradeInfo/${+this.state.selectedYear}/${id}`).then(info=>{
          if(info.length){
            data[id] = info[0];
          }else{
            data[id] = {testDay: null, attendance: null, gradeId: id}
            if(this.actualId){
              data[id]["1yearreportId"] = this.actualId;
            }
          }
        }))
      );
      await this.setStateAsync({
        reportGradeInfo: data
      });
    }catch(err){
      console.log('Error when fetching 1 year Report');
      console.log(err);
    }
  }

  save = async () => {
    try{
      await this.setStateAsync({
        finishedLoading: false,
      })
      const data = pick(this.state, this.subjectLookup.map(([a,subName])=>`${subName}Ratio`).concat('l_and_t', 'l_and_t_fb_and_fu', 'schoolDay'));
      if(this.state.selectedYear){
        data.yearId = this.state.selectedYear;
      }
      if(this.actualId){
        data.id = this.actualId;
      }
      const result = await post('edit1YearReport', data);

      const gradeResult = await Promise.all(Object.values(this.state.reportGradeInfo).map(x=>{
        const data = {...x}
        if(result && result.status && result.insertId){
          data["1yearreportId"] = result.insertId;
        }
        return post('editReportGradeInfo', data);
      }));

      if([result,...gradeResult].every(x=>x&&x.status)){
        this.setStateAsync({
          isBlocking: false,
        });
        await this.fetch();
      }else{
        throw result;
      }   
    }catch(err){
      if(err!=='unmounted'){
        alert('儲存學與教資料時發生錯誤');
        console.log('Error when saving 學與教', err);
      }
    }
    
    await this.saveModalToggle();
    this.setStateAsync({
      finishedLoading: true,
    })
  }

  /* modal toggle */
  saveModalToggle = async () => {
    this.setStateAsync({
      isSaveModalOpen: !this.state.isSaveModalOpen
    });
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({
      [stateName]: value,
      isBlocking: true
    })
  }

  yearChange = async (_,data) => {
    if(this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要切換年度嗎？")){
        return;
      }
    }
    try{
      let value = inputHandler('select', data);
      await this.setStateAsync({
        selectedYear: value,
        finishedLoading: false,
      });
      
      await this.setStateAsync({
        readOnly: whetherReadOnlyForReport(this.state.yearInfo, this.state.selectedYear)
      });

      await this.fetchYearReportData();
    }catch(err){
      console.log('Error when changing selected OYP');
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
      isBlocking: false,
    });
  }
  
  gradeInfoChange = (inputType, grade, field) => (event, data) => {
    const info = this.state.reportGradeInfo;
    const theGradeInfo = {...info[grade]}
    theGradeInfo[field] = inputHandler(inputType, data);
    info[grade] = theGradeInfo;
    this.setStateAsync({
      reportGradeInfo: info,
      isBlocking: true,
    })
  }

  tryOpenSaveModal = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    this.saveModalToggle();
  }

  render() {
    const {
      isSaveModalOpen,
      finishedLoading,

      year,
      selectedYear,
      readOnly,
      isBlocking,

      grades,
      reportGradeInfo,

      l_and_t,
      l_and_t_fb_and_fu,
      schoolDay,
    } = this.state;

    return (<>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='周年報告 - 我們的學與教' subTitle='輸入回饋與跟進，以及級別的資料' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Field inline>
                  <label>周年</label>
                  <Select
                    disabled={!finishedLoading}
                    options={year}
                    value={selectedYear}
                    onChange={this.yearChange}
                  />
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form>
              <Segment.Group>
                <Segment>
                  <Form.Field>
                    <label>正文項目 (無需 1,2,3...)</label>
                    <FlexibleTextField
                      multiline={true}
                      readOnly={readOnly}
                      value={l_and_t}
                      options={{
                        rows:16,
                        disabled:!finishedLoading,
                        onChange:this.inputChange('text', 'l_and_t')
                      }}
                    />
                  </Form.Field>
                </Segment>
                <Segment>
                  <Form.Field>
                    <label>小一至小六各主要學習領域的課時 (百分比)</label>
                    <Table celled unstackable fixed>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={4}>科目</Table.HeaderCell>
                          <Table.HeaderCell width={12}>比例 (毋須%號)</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.subjectLookup.map(([title,fieldPrefix])=>(<Table.Row key={fieldPrefix}>
                          <Table.Cell>{title}</Table.Cell>
                          <Table.Cell>
                            <FlexibleTextField
                              multiline={false}
                              readOnly={readOnly}
                              value={this.state[`${fieldPrefix}Ratio`]}
                              options={{
                                fluid: true,
                                disabled:!finishedLoading,
                                onChange:this.inputChange('number', `${fieldPrefix}Ratio`)
                              }}
                            />
                            {this.validator.message(`${fieldPrefix}Ratio`, this.state[`${fieldPrefix}Ratio`], 'numeric|min:0,num|max:100,num')}
                          </Table.Cell>
                        </Table.Row>))}
                      </Table.Body>
                    </Table>
                  </Form.Field>
                </Segment>
                <Segment>
                  <Form.Field inline>
                    <label>校曆表顯示的上學日</label>
                    <FlexibleTextField
                      multiline={false}
                      readOnly={readOnly}
                      value={schoolDay}
                      options={{
                        disabled:!finishedLoading,
                        onChange:this.inputChange('number', 'schoolDay')
                      }}
                    />
                    {this.validator.message('schoolDay', schoolDay, 'integer|min:0,num|max:365,num')}
                  </Form.Field>
                  <Form.Field>
                    <label>級別資料</label>
                    <Table celled unstackable fixed>
                      <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell width={2}>資料</Table.HeaderCell>
                        {grades.map(({id,engName})=>(<Table.HeaderCell key={id} width={2}>{engName}</Table.HeaderCell>))}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.fieldLookup.map(([title,field,validator])=>(<Table.Row key={field}>
                          <Table.Cell>{title}</Table.Cell>
                          {grades.map(g=>{
                            const item = reportGradeInfo[g.id];
                            if(item){
                              return (<Table.Cell key={g.id}>
                                <FlexibleTextField
                                  multiline={false}
                                  readOnly={readOnly}
                                  value={item[field]}
                                  options={{
                                    fluid: true,
                                    disabled:!finishedLoading,
                                    onChange:this.gradeInfoChange('number', g.id, field),
                                    labelPosition:'right',
                                    label:field==='attendance'?'%':'日'
                                  }}
                                />
                                {this.validator.message(`${g.engName}-${title}`, item[field], validator)}
                              </Table.Cell>)
                            }else{
                              return <Table.Cell key={g.id}></Table.Cell>;
                            }
                          })}
                        </Table.Row>))}
                      </Table.Body>
                      </Table>
                  </Form.Field>
                </Segment>
                <Segment>
                  <Form.Field>
                    <label>回饋與跟進 (無需 1,2,3...)</label>
                    <FlexibleTextField
                      multiline={true}
                      readOnly={readOnly}
                      value={l_and_t_fb_and_fu}
                      options={{
                        rows:12,
                        disabled:!finishedLoading,
                        onChange:this.inputChange('text', 'l_and_t_fb_and_fu')
                      }}
                    />
                  </Form.Field>
                </Segment>
                {!readOnly && (<Segment>
                  <Form.Field
                    control={Button}
                    color="green"
                    onClick={this.tryOpenSaveModal}
                    disabled={!finishedLoading}
                  >儲存</Form.Field>
                </Segment>)}
              </Segment.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ConfirmModal
        open={isSaveModalOpen}
        description='確定儲存周年報告學與教資料？'
        cancel={this.saveModalToggle}
        confirm={this.save}
        confirmIcon='check'
        confirmText='儲存'
      />
      <BlockerPrompt isBlocking={isBlocking}/>
    </>)
  }
}

export default withRouter(L_And_T);