/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import react router */
import { Link } from 'react-router-dom';

/* import components */
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import DisplayBeforeComponent from './DisplayBeforeComponent';

/* import helper */
import { get, ping } from '../../../Helper/ApiHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import { textareaText, roundTwoDp } from '../../../Helper/Helper';
import { momentToDate, dateToMoment } from '../../../Helper/TimeHelper';

/* import calculation function */
import { formContentMarkForOne, base } from '../Report/Calculation';

/* import lodash */
import { forEach } from 'lodash';

/* import semantic-ui element */
import { Grid, Form, Segment, Table, Button, Message } from 'semantic-ui-react';

class ViewOtherWrite extends Component {
	constructor(props) {
		super(props);
		ping(this);
	}

	state = {
		dimmerOpen: true,

		section: this.props.match.params.section,
		appeal: this.props.match.params.section === 'appeal' ? true : false,
		teacherId: this.props.match.params.teacherId,
		teacherInfo: {},

		appraisalItemId: this.props.match.params.appraisalItemId,
		appraisalTargetId: this.props.match.params.appraisalTargetId,
		appraisalMarkingId: this.props.match.params.appraisalMarkingId,

		appraisalItem: {},
		appraisalTarget: {},
		appraisalMarking: {},

		appraisalContentBefore: [],
		appraisalContentBeforeTree: [],
		appraisalBefore: {},

		appraisalContentOther: [],
		appraisalContentOtherTree: [],
		appraisalOther: {},

		comment: '',
		dataUrl: null,

		sketcher: null,
		sketcherMode: 'write',
		isDeleteModalOpen: false,

		isEdit: false,
	};

	fetch = async () => {
		const { appraisalItemId, appraisalTargetId, appraisalMarkingId, teacherId } = this.state;
		const [
			appraisalItem,
			appraisalTarget,
			appraisalMarking,
			appraisalContentBefore,
			appraisalFormBefore,
			appraisalContentOther,
			appraisalFormOther,
			appraisalWriting,
		] = await Promise.all([
			get('getAppraisalItem/' + appraisalItemId),
			get('getOneAppraisalFormTarget/' + appraisalTargetId),
			get('getOneAppraisalMarkingById/' + appraisalMarkingId),
			get('getAppraisalContentBefore/' + appraisalItemId),
			get('getAppraisalFormBefore/' + appraisalTargetId),
			get('getAppraisalContentOther/' + appraisalItemId),
			get('getAppraisalFormOther/' + appraisalTargetId + '/' + teacherId),
			get('getAppraisalWriting/' + appraisalTargetId + '/' + teacherId),
		]);

		let appraisalBefore = {};
		appraisalFormBefore.forEach(({ appraisalContentId, text, date, score }) => {
			if (score) {
				appraisalBefore[appraisalContentId] = score;
			} else {
				appraisalBefore[appraisalContentId] = '不適用';
			}
			if (text) {
				appraisalBefore[appraisalContentId] = text;
			}
			if (date) {
				appraisalBefore[appraisalContentId] = dateToMoment(date);
			}
		});

		let appraisalOther = {};
		appraisalFormOther.forEach(({ appraisalContentId, text, date, score }) => {
			if (score) {
				appraisalOther[appraisalContentId] = score;
			} else {
				appraisalOther[appraisalContentId] = '不適用';
			}
			if (text) {
				appraisalOther[appraisalContentId] = text;
			}
			if (date) {
				appraisalOther[appraisalContentId] = dateToMoment(date);
			}
		});

		this.setState({
			appraisalItem: appraisalItem.length > 0 ? appraisalItem[0] : {},
			appraisalTarget: appraisalTarget.length > 0 ? appraisalTarget[0] : {},
			appraisalMarking: appraisalMarking.length > 0 ? appraisalMarking[0] : {},
			appraisalContentBefore,
			appraisalContentBeforeTree: buildTree(appraisalContentBefore),
			appraisalBefore,
			appraisalContentOther,
			appraisalContentOtherTree: buildTree(appraisalContentOther),
			appraisalOther,
			comment: appraisalWriting.length > 0 ? appraisalWriting[0]['comment'] : '',
			dataUrl: appraisalWriting.length > 0 ? appraisalWriting[0]['dataUrl'] : null,
		});
	};

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidMount = async () => {
		this.mounted = true;
		await this.fetch();
		this.setState({ dimmerOpen: false });
	};

	displayOther = (appraisalContentOtherTree, level = 1) => {
		const { appraisalOther } = this.state;
		return (
			<Fragment>
				{appraisalContentOtherTree.map(
					({ id, description, needInput, inputType, min, max, step, count, avgCount, percentage, children }, index) => {
						let showlabel = description;
						if (count) {
							if (avgCount) {
								showlabel += ' (平均計算)';
							} else if (percentage) {
								showlabel += ' (' + percentage + '%)';
							}
						}

						return (
							<Fragment key={index}>
								<Form.Group className={level > 1 ? 'next-layer' : 'form-group-margin'} grouped>
									<label>{showlabel}</label>
									{!!Boolean(needInput) && (
										<Message>
											{inputType === 'date' && <Fragment>{momentToDate(appraisalOther[id])}</Fragment>}
											{(inputType === 'text' || inputType === 'subject' || inputType === 'textSelect') && (
												<Fragment>{textareaText(appraisalOther[id])}</Fragment>
											)}
											{inputType === 'score' && <Fragment>{appraisalOther[id]}</Fragment>}
										</Message>
									)}
									{!!children && this.displayOther(children, level + 1)}
								</Form.Group>
							</Fragment>
						);
					}
				)}
			</Fragment>
		);
	};

	/* return url */
	returnUrl = () => {
		const { appeal, teacherId, appraisalItemId } = this.state;
		if (appeal) {
			return '/appraisal/appeal/form_list/' + appraisalItemId + '/' + teacherId;
		} else {
			return '/appraisal/item/form_list/' + appraisalItemId;
		}
	};

	/* display avg */
	displayAvg = () => {
		const { appraisalContentOtherTree, appraisalOther } = this.state;

		let mark = 0;
		let storePercentage = 0;

		forEach(appraisalContentOtherTree, ({ id, percentage, max, count, children }) => {
			if (count) {
				let returnMark = formContentMarkForOne(id, max, count, appraisalOther, children) * percentage;
				if (!isNaN(returnMark)) {
					mark += returnMark;
					storePercentage += percentage;
				}
			}
		});

		if (storePercentage > 0) {
			return roundTwoDp(mark / storePercentage);
		} else {
			return '-';
		}
	};

	render() {
		const {
			dimmerOpen,
			appraisalItem,
			appraisalTarget,
			appraisalMarking,
			appraisalContentBeforeTree,
			appraisalBefore,
			appraisalContentOtherTree,
			comment,
			dataUrl,
		} = this.state;
		return (
			<Fragment>
				{dimmerOpen ? (
					<FullscreenDimmer active={dimmerOpen} isLoading={true} />
				) : (
					<Fragment>
						<Grid celled="internally" doubling>
							<Grid.Row>
								<Grid.Column className="appraisal-form" computer={8} mobile={16}>
									<Grid>
										<Grid.Row>
											<Grid.Column>
												<Segment.Group>
													<Segment className="bold">
														<Grid columns={3} stackable>
															<Grid.Row className="larger-font" verticalAlign="middle">
																<Grid.Column>
																	{appraisalItem.yearName} : {appraisalItem.displayName}
																</Grid.Column>
															</Grid.Row>
															<Grid.Row>
																<Grid.Column>被評老師:{appraisalTarget.name}</Grid.Column>
																<Grid.Column>評估老師:{appraisalMarking.name}</Grid.Column>
																<Grid.Column>遞交限期:{momentToDate(appraisalTarget.deadline)}</Grid.Column>
															</Grid.Row>
															<Grid.Row>
																<Grid.Column>評估科目:{appraisalTarget.subject}</Grid.Column>
																<Grid.Column>評估班別:{appraisalTarget.className}</Grid.Column>
															</Grid.Row>
														</Grid>
													</Segment>
													<Segment secondary>
														<Table basic="very" padded celled>
															<Table.Body>
																<DisplayBeforeComponent
																	appraisalContentBeforeTree={appraisalContentBeforeTree}
																	appraisalBefore={appraisalBefore}
																/>
															</Table.Body>
														</Table>
													</Segment>
												</Segment.Group>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column>
												<Segment.Group>
													<Segment className="bold larger-font">
														<Grid columns={2} stackable>
															<Grid.Row verticalAlign="middle">
																<Grid.Column>評估內容</Grid.Column>
															</Grid.Row>
														</Grid>
													</Segment>
													<Segment padded="very" secondary>
														<Form>
															{this.displayOther(appraisalContentOtherTree)}

															<Form.Group className="form-group-margin" grouped>
																<label>輸入評語</label>
																<Message>{textareaText(comment ? comment : '沒有評語')}</Message>
															</Form.Group>

															<Message>
																<Message.Header>
																	平圴分數:
																	{this.displayAvg()} / {base}
																</Message.Header>
															</Message>
														</Form>
													</Segment>
												</Segment.Group>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Grid.Column>
								<Grid.Column className="appraisal-canvas" computer={8} mobile={16}>
									<div className="bold larger-font">手寫評語</div>
									{dataUrl ? <img src={dataUrl} alt="手寫評語" width="100%" height="100%" /> : ''}
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Link to={this.returnUrl()}>
										<Button type="button" content="返回" icon="arrow left" circular />
									</Link>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

export default withRouter(ViewOtherWrite);
