import React, {memo, useContext} from 'react';
import { useMemo } from 'react';
import {Segment, Form, Button, Grid, Checkbox} from 'semantic-ui-react';
import { keyBy } from 'lodash';

import {MySelect, MyTable} from '../../Components';

import DynamicField from './DynamicField';
import DynamicContext from './dynamicFieldContext';

const helperArray = ['課堂外的支援'];

const RecordPage3 = (props) => {
  const {
    complete,
    newRecord,
    senGroup,
    senGroupForStudent,
    changeSenGroup,
    unmatchedGroups
  } = props;

  const trueSenGroup = useMemo(()=>senGroupForStudent.map(x=>x.senGroupId), [senGroupForStudent.map(x=>x.senGroupId).join("\b")]);

  const senGroupLookup = useMemo(()=>keyBy(senGroup, 'value'), [senGroup]);

  const tableColumnData = useMemo(()=>[
    { headerName: '支援組名', width: 8, cellRender: x=>senGroupLookup[String(x.senGroupId)] && senGroupLookup[String(x.senGroupId)].text },
    { headerName: '家長不同意', width: 4, cellRender: x=>(
      <Form.Field>
        <Checkbox
          disabled={readOnly}
          checked={!x.parentConsent}
          onClick={changeSenGroup}
          className='narrow'
          data-sen-group-id={x.senGroupId}
        />
      </Form.Field>
    )},
  ], [senGroupLookup]);

  const {readOnly, inputChange, changeStep, dynamicInputChange} = useContext(DynamicContext);
  
  return (
    <Segment padded attached>

      <DynamicField
        index={12}
        {...newRecord['課堂上的支援']}
        displayName='課堂上的支援'
      />
      <Form.Group className='form-group-margin' grouped>
        <Form.Group inline>
          <label>13. 課堂外的支援</label>
          <Form.Checkbox
            inline
            checked={newRecord['課堂外的支援'].value}
            onChange={dynamicInputChange('checkbox',helperArray)}
            toggle
            readOnly={readOnly}
            disabled={readOnly}
          />
        </Form.Group>
        {newRecord['課堂外的支援'].value &&
          <div className='next-layer'>
            <Form.Group grouped>
              <MySelect
                multiple
                search
                options={senGroup}
                value={trueSenGroup}
                onChange={changeSenGroup}
                placeholder='課堂外的支援...'
                disabled={readOnly}
              />
              {!!unmatchedGroups.length && <p className='red'>未能匹配的組別：{unmatchedGroups.join("、")}</p>}
            </Form.Group>
            <MyTable
              tableColumnData={tableColumnData}
              data={senGroupForStudent}
              keyHandle='senGroupId'
            />
          </div>
        }
      </Form.Group>
      <DynamicField
        index={14}
        {...newRecord['家課調適']}
        displayName='家課調適'
      />
      <DynamicField
        index={15}
        {...newRecord['評估調適']}
        displayName='評估調適'
      />
      <DynamicField
        index={16}
        {...newRecord['家長配合']}
        displayName='家長配合'
      />
      <DynamicField
        index={17}
        {...newRecord['課前輔導班']}
        displayName='課前輔導班'
      />
      <DynamicField
        index={18}
        {...newRecord['加強輔導班']}
        displayName='加強輔導班'
      />
      
      <Form.Group className={'form-group-margin' + (!complete ? ' incomplete' : '')} grouped>
        <Form.Checkbox
          checked={!!complete}
          onChange={inputChange('checkbox', 'student', 'complete')}
          label='已完成'
          disabled={readOnly}
        />
      </Form.Group>

      <Grid>
        <Grid.Column>
          <Button
            content='上一歩'
            floated='left'
            icon='arrow left'
            type='button'
            data-step-name='needs'
            onClick={changeStep}
            circular
          />
          {!readOnly && (<Button
            color='green'
            content='儲存'
            icon='save'
            floated='right'
            type='submit'
          />)}
        </Grid.Column>
      </Grid>
    </Segment>
  )
}

export default memo(RecordPage3);