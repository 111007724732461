import File from './File';
import MidTermReport from './MidTermReport';
import Strategies from './Strategies';
import Summary from './Summary';

export default {
  File,
  MidTermReport,
  Strategies,
  Summary,
}