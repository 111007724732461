import React, {useCallback, useContext, memo, useState, useEffect} from 'react';
import { Grid, Segment, Form, Button, Input, Radio } from 'semantic-ui-react';

import { SECTIONDATA, genFieldClassName } from './Helper';
import { momentToDate } from '../../Helper/TimeHelper';
import { useValidator } from '../../Helper/HookHelper';

import DynamicContext from './dynamicFieldContext';

import {find} from 'lodash';

import {
  MyTable,
  MySelect,
  Buttons
} from '../../Components';

const grades = [
  { text: '0', value: 0 },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 }
];

const senTypes = find(SECTIONDATA, {dbName: 'senType'}).subFields;

const tableProps = {unstackable: true, compact: true}
const difficultyHelperArray = ['有顯著的學習困難','有輕微的學習困難','沒有學習困難'];
const hasRecordHelperArray = [[true, '有'],[false, '無'],[null, '未有資料']];

const RecordPage2 = (props) => {
  const {
    record,
    report,
    result,
    tableInputChange,
    addResultTableRow,

    editModalToggle,
    deleteModalToggle,
  } = props;

  const {readOnly, inputChange, changeStep} = useContext(DynamicContext);

  const isSen = useCallback(()=>{
    return senTypes.some(x => record['特殊教育需要類別|' + x]);
  }, [record]);

  const [validator, forceUpdate] = useValidator({
    messages: {
      default: '請輸入資料'
    }
  })

  const toThirdPage = useCallback((event) => {
    if (validator.allValid()) {
      event.preventDefault();
      changeStep('support');
    }else{
      const f = async () => {await props.setP2allvalid();}
      f();
    }
  }, [validator, changeStep]);
  
  // for report table
  const [reportTableData, setReportTableData] = useState({tableColumnData: []});

  useEffect(()=>{
    const tableColumnData = [
      { width: 3, headerName: '評估機構', cellClassName: 'overflow-wrap', cellRender: 'organisation' },
      { width: 1, headerName: '評估日期', cellRender: x=>momentToDate(x.date)},
      { width: 4, headerName: '評估結論', cellClassName: 'overflow-wrap textarea-text', cellRender: 'result'},
      { width: 4, headerName: '評估後建議', cellClassName: 'overflow-wrap textarea-text', cellRender: 'suggestion'},
      { width: 1, headerName: '上報與否', cellRender: x=>x.willReport ? '是' : '否' },
    ];

    let tableFooterRender = undefined;

    if(!readOnly){
      tableColumnData.push(
        { width: 1, headerName: '行動', cellClassName: 'textlessCell', cellRender: (x,index)=>(
          <>
            <Buttons.EditButton
              data-edit-index={index}
              onClick={editModalToggle}
            />
            <Button
              color='red'
              icon='delete'
              type='button'
              onClick={deleteModalToggle(index, '確定刪除評估報告？', 'report')}
              circular
            />
          </>
        ) },
      )
      tableFooterRender = () => (<Buttons.AddButton
        content='新增評估報告'
        onClick={editModalToggle}
      />);
    }
    setReportTableData({tableColumnData, tableFooterRender});
  },[readOnly, deleteModalToggle, editModalToggle]);

  
  // for result table
  const [resultTableData, setResultTableData] = useState({tableColumnData: []});

  useEffect(()=>{
    let tableColumnData = [], tableFooterRender = undefined;
    
    if(readOnly){
      tableColumnData = [
        { width: 3, headerName: '年度', cellRender: 'year' },
        { width: 4, headerName: '中文', cellRender: 'chi'},
        { width: 4, headerName: '英文', cellRender: 'eng'},
        { width: 4, headerName: '數學', cellRender: 'math'},
      ];
    }else{
      tableColumnData = [
        { width: 3, headerName: '年度', cellRender: (result,index)=>(
          <>
            <Form.Field>
              <Input
                value={result.year||''}
                data-input-type='text'
                data-index={index}
                data-field='year'
                onChange={tableInputChange}
                name={"year"+index}
                placeholder='年度'
                fluid
                required
              />
            </Form.Field>
            {validator.message('year' + index, result.year, 'required')}
          </>
        ) },
        { width: 4, headerName: '中文', cellClassName: 'table-cell-with-dropdown', cellRender: (result,index)=>(
          <>
            <Form.Field>
              <MySelect
                value={Number.isInteger(result.chi)?result.chi:''}
                data-input-type='select'
                data-index={index}
                data-field='chi'
                onChange={tableInputChange}
                name={"chi"+index}
                options={grades}
                search
                placeholder='中文'
                fluid
                required
              />
            </Form.Field>
            {validator.message('chi' + index, result.chi, 'required')}
          </>
        )},
        { width: 4, headerName: '英文', cellClassName: 'table-cell-with-dropdown', cellRender: (result,index)=>(
          <>
            <Form.Field>
              <MySelect
                value={Number.isInteger(result.eng)?result.eng:''}
                data-input-type='select'
                data-index={index}
                data-field='eng'
                onChange={tableInputChange}
                name={"eng"+index}
                options={grades}
                search
                placeholder='英文'
                fluid
                required
              />
            </Form.Field>
            {validator.message('eng' + index, result.eng, 'required')}
          </>
        )},
        { width: 4, headerName: '數學', cellClassName: 'table-cell-with-dropdown', cellRender: (result,index)=>(
          <>
            <Form.Field>
              <MySelect
                value={Number.isInteger(result.math)?result.math:''}
                data-input-type='select'
                data-index={index}
                data-field='math'
                onChange={tableInputChange}
                name={"math"+index}
                options={grades}
                search
                placeholder='數學'
                fluid
                required
              />
            </Form.Field>
            {validator.message('math' + index, result.math, 'required')}
          </>
        )},
        { width: 1, headerName: '刪除', cellClassName: 'textlessCell', cellRender: (x,index)=>(
          <Button
            color='red'
            icon='delete'
            type='button'
            onClick={deleteModalToggle(index, '確定刪除成績？', 'result')}
            circular
          />
        ) },
      ];
      tableFooterRender = props => (<Buttons.AddButton
        content='新增成績'
        onClick={addResultTableRow}
      />);
    }
    setResultTableData({tableColumnData, tableFooterRender});
  },[readOnly, validator]);

  validator.purgeFields();

  return (
    <Segment padded attached>
      <Form.Group className={genFieldClassName(readOnly || isSen() || null)} grouped>
        <label>7. 特殊教育需要類別：</label>
        {senTypes.map(x=>(
          <Form.Checkbox
            key={x}
            checked={!!record['特殊教育需要類別|'+x]}
            onChange={inputChange('checkbox', 'record', '特殊教育需要類別|'+x)}
            name={'特殊教育需要類別|'+x}
            label={x}
            disabled={readOnly}
            readOnly={readOnly}
          />
        ))}
        {record['特殊教育需要類別|其他'] && (<Form.Input
          className='next-layer'
          value={record['特殊教育需要類別|其他|其他請註明']||''}
          name='特殊教育需要類別|其他|其他請註明'
          onChange={inputChange('text', 'record', '特殊教育需要類別|其他|其他請註明')}
          placeholder='其他請註明'
          required
          disabled={readOnly}
        />)}
        {
          record['特殊教育需要類別|其他'] && validator.message('othersen', record['特殊教育需要類別|其他|其他請註明'], 'required')
        }
      </Form.Group>

      <Form.Group className={genFieldClassName(readOnly || record['學前兒童發展進度綜合報告'])} grouped>
        <label>8. 學前兒童發展進度綜合報告：</label>
        {hasRecordHelperArray.map(([value, label])=>(
          <Form.Radio
            key={label}
            checked={record['學前兒童發展進度綜合報告'] === value}
            onChange={inputChange('radio', 'record', '學前兒童發展進度綜合報告')}
            name='學前兒童發展進度綜合報告'
            label={label}
            value={String(value)}
            disabled={readOnly}
            readOnly={readOnly}
          />
        ))}
      </Form.Group>

      <Form.Group className='form-group-margin' grouped>
        <label>9. 評估報告：</label>
        <MyTable
          data={report}
          {...reportTableData}
          noDataMsg="未有資料"
          tableProps={tableProps}
        />
      </Form.Group>

      <Form.Group className={genFieldClassName(readOnly || record['及早識別和輔導有學習困難的小一學生'])} grouped>
        <label>10. 及早識別和輔導有學習困難的小一學生：</label>
        <Form.Radio
          checked={record['及早識別和輔導有學習困難的小一學生'] === true}
          onChange={inputChange('radio', 'record', '及早識別和輔導有學習困難的小一學生')}
          name='及早識別和輔導有學習困難的小一學生'
          label='曾參加此計劃'
          value='true'
          readOnly={readOnly}
          disabled={readOnly}
        />
        {record['及早識別和輔導有學習困難的小一學生'] && (
          <Form.Group className='next-layer' grouped>
            <Form.Field>
              <label>年度</label>
              <Input
                value={record['及早識別和輔導有學習困難的小一學生|曾參加此計劃|年度']||''}
                onChange={inputChange('text', 'record', '及早識別和輔導有學習困難的小一學生|曾參加此計劃|年度')}
                name='及早識別和輔導有學習困難的小一學生|曾參加此計劃|年度'
                placeholder='年度'
                required
                disabled={readOnly}
              />
            </Form.Field>
            {difficultyHelperArray.map((x,i,a)=>(
              <Form.Field key={x}>
                <Radio
                  checked={record['及早識別和輔導有學習困難的小一學生|曾參加此計劃|結果'] === x}
                  onChange={inputChange('radio', 'record', '及早識別和輔導有學習困難的小一學生|曾參加此計劃|結果')}
                  label={x}
                  value={x}
                  name='及早識別和輔導有學習困難的小一學生|曾參加此計劃|結果'
                  required
                  disabled={readOnly}
                />  
              </Form.Field>
            ))}
            {validator.message('p1year', record['及早識別和輔導有學習困難的小一學生|曾參加此計劃|年度'], 'required')}
          </Form.Group>
        )}
        <Form.Radio
          checked={record['及早識別和輔導有學習困難的小一學生'] === false}
          onChange={inputChange('radio', 'record', '及早識別和輔導有學習困難的小一學生')}
          label='沒有參加此計劃'
          name='及早識別和輔導有學習困難的小一學生'
          value='false'
          disabled={readOnly}
        />
        <Form.Radio
          checked={record['及早識別和輔導有學習困難的小一學生'] === null}
          onChange={inputChange('radio', 'record', '及早識別和輔導有學習困難的小一學生')}
          name='及早識別和輔導有學習困難的小一學生'
          label='未有資料'
          value='null'
          disabled={readOnly}
        />
      </Form.Group>

      <Form.Group className='form-group-margin' grouped>
        <label>11. 學習程度測量卷3.0成績（落後年級）</label>
        <MyTable
          data={result}
          {...resultTableData}
          noDataMsg="未有資料"
          tableProps={tableProps}
        />
      </Form.Group>

      <Grid>
        <Grid.Column>
          <Button content='上一歩' floated='left' icon='arrow left' labelPosition='left' type='button' data-step-name='information' onClick={changeStep} circular />
          <Button color='green' content='下一歩' icon='arrow right' labelPosition='right' floated='right' type='submit' circular onClick={toThirdPage} />
        </Grid.Column>
      </Grid>
    </Segment>
  )
}

export default memo(RecordPage2);