/* import packages */
import { keyBy, pick, findIndex } from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import {DynamicProvider} from './dynamicFieldContext';

/* import components */
import {
  PageHeader,
  ErrorLabel,
  ConfirmModal,
  BlockerPrompt,
  FullscreenDimmer,
  DateInput,
  MySearch,
  Buttons
} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { momentToDate, now, momentToDbFormat, dateToMoment } from '../../Helper/TimeHelper';
import { inputHandler } from '../../Helper/FormHelper';

import RecordPage1 from './RecordPage1';
import RecordPage2 from './RecordPage2';
import RecordPage3 from './RecordPage3';

import {
  rawRecordDataToDynamicStates,dynamicFieldToSaveFormat,
  organisationOptions,assessmentResultOptions,assessmentSuggestionOptions
} from './Helper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Step, Segment, Form, Checkbox, Modal } from 'semantic-ui-react';
import ReportMultipleLabel from './ReportMultipleLabel';
import { selectOptions } from '../../Helper/Helper';
import { User } from '../../Context';

const steps = [
  { displayName: '基本資料', stepName: 'information' },
  { displayName: '特殊教育需要類別', stepName: 'needs' },
  { displayName: '各項支援安排', stepName: 'support' }
];

class Record extends Component {
  constructor(props) {
    super(props);

    const validatorTemplate = {
      element: message => <ErrorLabel message={message} />,
      messages: { default: '請輸入資料' },
      autoForceUpdate: this
    }

    //form validators
    this.modalValidator = new SimpleReactValidator(validatorTemplate);
    this.counter = 0;

    this.state = {
      activeStep: 'information',

      // for edit modal use (section 8)
      isEditReportModalOpen: false,
      editIndex: null,
      editReport: {
        organisation: '',
        date: now(),
        result: [],
        suggestion: [],
        willReport: false,
      },

      // for delete modal use (section 8 and 10)
      isDeleteModalOpen: false,
      deleteModalIndex: null,
      deleteModalDescription: null,
      deleteStateName: '',

      isBlocking: false,

      // sen student (section 1 - 6)
      student: {
        name: '',
        classCode: '',
        classNo: '',
        senStudentId: null,
        studentId: null,
        strn: '',
        consent: null,
        signDate: null,
        financial: '',
        nonChinese: false,
        complete: false,
      },

      context: {
        dynamicInputChange: this.dynamicInputChange,
        readOnly: true,
        inputChange: this.inputChange,
        changeStep: this.changeStep,
      },

      isPast: true,
      finishedLoading: false,
      dimmerOpen: false,
      loaderText: '',

      // section 9 (database: SenReport)
      report: null,

      // section 11 (database: SenResult)
      result: null,

      // sen record
      record: {
        // section 7
        '特殊教育需要類別|成績稍遜': false,
        '特殊教育需要類別|特殊學習困難（讀寫障礙）': false,
        '特殊教育需要類別|智障': false,
        '特殊教育需要類別|視障': false,
        '特殊教育需要類別|聽障': false,
        '特殊教育需要類別|肢體傷殘': false,
        '特殊教育需要類別|自閉症': false,
        '特殊教育需要類別|注意力不足/過度活躍症': false,
        '特殊教育需要類別|言語障礙': false,
        '特殊教育需要類別|精神病': false,
        '特殊教育需要類別|其他': false,
        '特殊教育需要類別|其他|其他請註明': '',

        // section 8
        '學前兒童發展進度綜合報告': null,

        // section 10
        '及早識別和輔導有學習困難的小一學生': null,
        '及早識別和輔導有學習困難的小一學生|曾參加此計劃|年度': '',
        '及早識別和輔導有學習困難的小一學生|曾參加此計劃|結果': '沒有學習困難',
      },
      senGroup: [],
      senGroupForStudent: [],
      unmatchedGroups: [],
      p2allvalid: true
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    //get sen student info
    try{
      const senStudentInfoArray = await get('getSenStudent/' + this.props.match.params.studentId);
      if (!senStudentInfoArray) {
        alert("找不到學生")
        return;
      }

      const senStudentInfo = senStudentInfoArray[0];
      const student = {
        ...pick(senStudentInfo, ['classCode','classNo','studentId','strn','status','financial','nonChinese','complete',]),
        name: senStudentInfo.chiName || senStudentInfo.engName,
        senStudentId: senStudentInfo.id,
        consent: senStudentInfo.consent === 1 ? "1" : senStudentInfo.consent === 0 ? "0" : null,
        signDate: senStudentInfo.signDate ? dateToMoment(senStudentInfo.signDate) : now(),
        yearId: senStudentInfo.yearId,
      };

      let [senGroup,senGroupForStudent] = await Promise.all([
        get('getYearSenGroup/'+student.yearId),
        get('getSenGroupForStudent/'+student.senStudentId)
      ]);

      const senGroupMap = keyBy(senGroup, 'id');

      const isPast = new Date(senStudentInfo.endDate) < new Date();

      await this.setStateAsync({
        student,
        isPast,
        readOnly: !student.status || isPast,
        context: {...this.state.context, readOnly: !student.status || isPast},
        senGroup: selectOptions(senGroup, 'name', 'id'),
      });

      const [rawRecord, report, result] = await Promise.all([
        get('getSenRecord/'+senStudentInfo.id),
        get('getSenReport/'+senStudentInfo.id),
        get('getSenResult/'+senStudentInfo.id),
      ]);
      report.map(x=>x.date = dateToMoment(x.date));

      const record = {};

      rawRecord.forEach(senRecord => {
        const key = [senRecord.level1, senRecord.level2, senRecord.level3, senRecord.level4].filter(x => x !== null).join("|");
        record[key] = senRecord.checked === null ? senRecord.text : !!senRecord.checked;
      });

      const [newRecord, existingGroups, unmatchedGroups] = rawRecordDataToDynamicStates(rawRecord, senGroup);

      const stateResult = {
        record,
        report,
        result,
        newRecord,
        senGroupForStudent: senGroupForStudent.concat(existingGroups.map(x=>({senGroupId: x, parentConsent: true, name: senGroupMap[x.senGroupId].name}))),
        unmatchedGroups,
        finishedLoading: true
      };

      if(!rawRecord.length){
        delete stateResult.record;
      }
      this.setStateAsync(stateResult);
    }catch(err){if(err!=='unmounted')console.log('Error when fetching SEN student record', err)}
  }

  /* change between steps */
  changeStep = (eventOrStepName) => {
    let stepName;
    if(typeof eventOrStepName === 'object')
      stepName = eventOrStepName.target.closest('a, button').dataset.stepName;
    else
      stepName = eventOrStepName;
    if(stepName) this.setStateAsync({ activeStep: stepName });
  }

  /* Input change */
  inputChange = (inputType, objectName, stateName) => (event, data) => {
    this.setStateAsync({
      [objectName]:  {
        ...this.state[objectName],
        [stateName]: inputHandler(inputType, data)
      },
      isBlocking: true
    });
  }

  setP2allvalid = (value) => {
    return this.setStateAsync({p2allvalid: value})
  }

  /* edit modal toggle */
  editModalToggle = (event) => {
    const editIndex = +event.target.closest('.ui, button, textarea').dataset.editIndex;

    let editReport = {
      organisation: '',
      date: now(),
      result: [],
      suggestion: [],
      willReport: false
    };

    if(Number.isInteger(editIndex)) {
      editReport = {...this.state.report[editIndex]};
      if(editReport.result)
        editReport.result = String(editReport.result).split('\n');
      else
        editReport.result = [];
      if(editReport.suggestion)
        editReport.suggestion = String(editReport.suggestion).split('\n');
      else
        editReport.suggestion = [];
    }

    this.setStateAsync({
      isEditReportModalOpen: !this.state.isEditReportModalOpen,
      editIndex,
      editReport
    })
  }

  /* save report */
  saveReport = () => {
    if (this.modalValidator.allValid()) {
      const { editIndex, editReport, report } = this.state;

      const trueEditReport = pick(editReport, ["id", "senStudentId", "organisation", "date", "result", "suggestion", "willReport"]);

      ["result", "suggestion"].forEach(x=>{
        trueEditReport[x]=trueEditReport[x].join("\n");
      });

      const entries = [...report];
      if (Number.isNaN(editIndex)) {
        entries.push({
          ...trueEditReport,
          id: "N"+(this.counter++)
        });
      } else {
        entries[editIndex] = trueEditReport;
      }

      this.setStateAsync({
        report: entries,
        editReport: {
          organisation: '',
          date: now(),
          result: [],
          suggestion: [],
          willReport: false
        },
        isEditReportModalOpen: false,
      });
    } else {
      this.modalValidator.showMessages();
    }
  }

  /* add table row */
  addResultTableRow = () => {
    this.setStateAsync({
      result: this.state.result.concat({
        id: 'N'+(this.counter++),
        year: '', chi: '', eng: '', math: '',
      })
    })
  }

  tableInputChange = (event, data) => {
    const {inputType, index, field} = event.target.closest('.ui, textarea, button').dataset;
    const entry = [...this.state.result];
    entry[+index]= {
      ...entry[+index],
      [field]: inputHandler(inputType, data)
    }
    this.setStateAsync({
      result: entry
    });
  }

  /* delete table row */
  deleteTableRow = () => {
    const { deleteStateName, deleteModalIndex } = this.state;
    const entry = [...this.state[deleteStateName]];
    entry.splice(deleteModalIndex, 1);
    this.setStateAsync({
      [deleteStateName]: entry,
      isDeleteModalOpen: false,
    })
  }

  /* delete modal toggle */
  deleteModalToggle = (deleteModalIndex = null, deleteModalDescription = null, deleteStateName = null) => () => {
    this.setStateAsync({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
      deleteModalIndex,
      deleteModalDescription,
      deleteStateName,
    })
  }

  /* save form */
  save = async (event) => {
    if(this.state.activeStep === 'needs' && !this.state.p2allvalid){return;}
    event.preventDefault();
    const { senStudentId } = this.state.student;
    const signDate = momentToDbFormat(this.state.student.signDate);

    const report = this.state.report.map(x=>({
      ...x,
      id: Number.isInteger(+x.id)?x.id:undefined,
      date: momentToDbFormat(x.date)
    }));

    const result = this.state.result.map(x=>({
      ...x,
      senStudentId,
      id: Number.isInteger(+x.id)?x.id:undefined,
    }));

    const newRecord = dynamicFieldToSaveFormat(this.state.newRecord);

    try{
      await this.setStateAsync({ dimmerOpen: true });
      const allResult = await Promise.all([
        post('editSenStudent', Object.assign(pick(this.state.student, ['consent','financial','nonChinese','complete']), {signDate, id: senStudentId})),
        post('editSenRecord', {
          data: {
            ...this.state.record,
            ...newRecord,
            report, result,
          },
          senStudentId
        }),
        post('batchEditSenGroupForStudent', {
          senStudentId,
          senGroups: this.state.senGroupForStudent
        })
      ]);
      if(allResult.every(x=>x.status)){
        await this.setStateAsync( { isBlocking: false } );
        this.props.history.push('/sen/consent');
        return;
      }else{
        throw allResult;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存SEN學生記錄發生錯誤");
        console.log(err);
      }
    }
    await this.setStateAsync({ dimmerOpen: false });
  }

  setBlocking = async () => {
    try{
      await this.setStateAsync({ isBlocking: true });
    }catch(err){}
  }

  dynamicInputChange = (inputType, stack) => (event, data) => {
    const result = inputHandler(inputType,data);
    const arr = [{...this.state.newRecord[stack[0]]}];
    for(let i=1;i<stack.length;i++){
      if(arr[i-1].hasOwnProperty('subFields'))
        arr.push({...arr[i-1].subFields[stack[i]]});
    }
    arr[arr.length-1].value = result;
    for(let i=arr.length-1;i>0;i--){
      arr[i-1].subFields = {
        ...arr[i-1].subFields,
        [stack[i]]: arr[i]
      }
    }
    this.setStateAsync({
      newRecord: {
        ...this.state.newRecord,
        [stack[0]]: arr[0]
      }
    });
  }

  changeSenGroup = (event, data) => {
    const orig = this.state.senGroupForStudent;
    const {senGroupId} = event.target.closest('.ui').dataset;

    if(senGroupId){
      const processedData = inputHandler('checkbox', data);
      const index = findIndex(orig, {senGroupId: +senGroupId});

      this.setStateAsync({
        senGroupForStudent: orig.map((x,i)=>i===index?({
          ...x,
          parentConsent: !processedData,
        }):x)
      });
    }else{
      const origMap = new Map(orig.map(x=>[x.senGroupId, x.parentConsent]));
      const processedData = inputHandler('select', data);
      const dataLookup = new Set(processedData);
      // remove
      if(orig.length >= processedData.length){
        this.setStateAsync({
          senGroupForStudent: orig.filter(x=>dataLookup.has(x.senGroupId)),
        });
      }else{
        // add
        this.setStateAsync({
          senGroupForStudent: processedData.map(x=>({
            senGroupId: x,
            parentConsent: origMap.has(x) ? origMap.get(x) : true
          })),
        });
      }
    }
  }

  render() {
    this.modalValidator.purgeFields();
    const {
      activeStep,
      isEditReportModalOpen,
      isDeleteModalOpen,
      deleteModalDescription,
      editReport,

      student, record, newRecord, report, result,
      context,
      senGroup,senGroupForStudent,unmatchedGroups,

      readOnly, isBlocking, finishedLoading
    } = this.state;
    try{
      if(!this.context.hasMenuAccess('/sen/consent')){
        return <Redirect to='/' />
      }
    }catch(err){}

    return (
      <DynamicProvider value={context}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='學生詳細記錄' />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form id="sen-student-record" onSubmit={this.save}>
                <Step.Group attached='top'>
                  {steps.map(({ displayName, stepName }, index) => (
                    <Step
                      disabled={!finishedLoading}
                      key={index}
                      title={`${index+1}. ${displayName}`}
                      data-step-name={stepName}
                      active={activeStep === stepName}
                      onClick={this.changeStep}
                    />
                  ))}
                </Step.Group>

                {activeStep === 'information' && (
                  <RecordPage1
                    student={student}
                  />
                )}

                {activeStep === 'needs' && (
                  <RecordPage2
                    record={record}
                    report={report}
                    result={result}
                    setP2allvalid={this.setP2allvalid}
                    tableInputChange={this.tableInputChange}
                    addResultTableRow={this.addResultTableRow}
                    editModalToggle={this.editModalToggle}
                    deleteModalToggle={this.deleteModalToggle}
                  />
                )}

                {activeStep === 'support' && (
                  <RecordPage3
                    complete={student.complete}
                    newRecord={newRecord}
                    senGroup={senGroup}
                    senGroupForStudent={senGroupForStudent}
                    changeSenGroup={this.changeSenGroup}
                    unmatchedGroups={unmatchedGroups}
                  />
                )}
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        
        {isEditReportModalOpen && (
          <Modal open={isEditReportModalOpen} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>評估報告詳情</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <Form>
                  <Form.Group widths={2}>
                    <MySearch
                      label='評估機構'
                      readOnly={readOnly}
                      value={editReport.organisation||''}
                      setValue={this.inputChange('SENsearch', 'editReport', 'organisation')}
                      setBlocking={this.setBlocking}
                      reference={organisationOptions}
                    />
                    <Form.Field>
                      <DateInput
                        value={momentToDate(editReport.date)}
                        onChange={!readOnly && this.inputChange('date', 'editReport', 'date')}
                        label='評估日期'
                        placeholder='評估日期'
                      />
                      {this.modalValidator.message('date', editReport.date, 'required')}
                    </Form.Field>
                  </Form.Group>
                  <ReportMultipleLabel
                    label='評估結論'
                    readOnly={readOnly}
                    data={editReport.result}
                    setData={this.inputChange('SENsearch', 'editReport', 'result')}
                    setBlocking={this.setBlocking}
                    reference={assessmentResultOptions}
                  />
                  <ReportMultipleLabel
                    label='評估後建議'
                    readOnly={readOnly}
                    data={editReport.suggestion}
                    setData={this.inputChange('SENsearch', 'editReport', 'suggestion')}
                    setBlocking={this.setBlocking}
                    reference={assessmentSuggestionOptions}
                  />
                  <Form.Group grouped>
                    <Form.Field
                      checked={!!editReport.willReport}
                      onChange={this.inputChange('toggle', 'editReport', 'willReport')}
                      control={Checkbox}
                      label={<label><b>上報</b></label>}
                      readOnly={readOnly}
                      disabled={readOnly}
                    />
                  </Form.Group>
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Buttons.CancelButton onClick={this.editModalToggle} />
              <Buttons.SaveButton onClick={this.saveReport} />
            </Modal.Actions>
          </Modal>)}

        <ConfirmModal
          open={isDeleteModalOpen}
          description={deleteModalDescription}
          cancel={this.deleteModalToggle()}
          confirm={this.deleteTableRow}
        />
        <BlockerPrompt isBlocking={!readOnly && isBlocking} />
        <FullscreenDimmer
          active={this.state.dimmerOpen}
          content={this.state.loaderText}
          isLoading={true}
        />
      </DynamicProvider>
    )
  }
}

Record.contextType = User;

export default withRouter(Record);