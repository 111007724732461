import { useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ErrorLabel } from '../Components';

/**
 * Validator hook for SimpleReactValidator, default with ErrorLabel
 * @param {Object} props the object you normally use for Simple React Validator
 */
export const useValidator = (props) => {
  const [, forceUpdate] = useState(0);

  const validator = useRef(new SimpleReactValidator({
    element: message => <ErrorLabel message={message} />,
    ...props,
    autoForceUpdate: { forceUpdate: () => forceUpdate(x=>x+1) }
  }));

  return [validator.current, forceUpdate];
}