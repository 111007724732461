import React, { useState, useCallback, useContext, useEffect, memo } from 'react';
import { GoogleLogout } from 'react-google-login';
import { useHistory, useLocation, Link } from 'react-router-dom';

import FullscreenDimmer from './FullscreenDimmer';
import { post } from '../Helper/ApiHelper';
import { User, Media } from '../Context';

import { Menu, Dropdown } from 'semantic-ui-react';

const imgDir = process.env.PUBLIC_URL+(process.env.REACT_APP_DEMO?"/demo":"");
const Nav = (props) => {
  const [dimmerOpen, setDimmer] = useState(false);
  const history = useHistory();
  const {name, menus} = useContext(User);
  const {pathname} = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  useEffect(()=>{
    setSubMenuOpen(false);
  }, [pathname])

  const logout = useCallback(() => {
    const abortController = new AbortController();
    const f = async () => {
      try {
        setDimmer(true);
        const result = await post('signOut', {}, 'JSON', abortController);
        if (result && result.status) history.push('/'); else throw result;
      } catch (err) {
        setDimmer(false);
      }
    }
    f();
    return () => {
      abortController.abort();
    }
  }, [history]);

  const manualLogout = () => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    if (auth2 != null) {
      auth2.signOut().then(() => {
        auth2.disconnect();
        return logout();
      }).catch(console.log);
    }
  }

  const renderLogout = (renderProps) => (
    <Menu.Item position='right' onClick={() => {
      manualLogout();
      renderProps.onClick();
    }}>
      登出
    </Menu.Item>
  )

  const onFailure = (err) => console.log('google logout fail', err);

  const navigation = useCallback((eventOrRoute) => {
    if(typeof eventOrRoute === 'string'){
      history.push(eventOrRoute);
      return;
    }
    const {href} = eventOrRoute.target.closest('.item');
    if(href){
      document.activeElement.blur();
      history.push('/'+href.split('/').slice(3).join('/'));
    }
    eventOrRoute.preventDefault();
  }, [history]);

  let title = props.title || '';
  if (title) title = ` - ${title}`;

  return (
    <div id="top-nav">
      <FullscreenDimmer active={dimmerOpen} isLoading={true} />
      <Menu fixed='top' size='huge' color='blue' inverted borderless>
        <Link to='/panel'>
          <Menu.Item>
            <img src={imgDir+'/logo.png'} id='nav-logo' alt={process.env.REACT_APP_SCHOOL_NAME} />
            <span id='nav-title'>{process.env.REACT_APP_NAME}{title}</span>
          </Menu.Item>
        </Link>

        <Menu.Menu position='right'>
          <Media.Media at="mobile">
            {(mediaClassNames, renderChildren) => renderChildren && (<Menu.Item icon='sidebar' id='mobile-toggle' className={mediaClassNames} onClick={()=>{setSubMenuOpen(x=>!x)}} />)}
          </Media.Media>
          <Media.Media greaterThan="mobile">
            {(mediaClassNames, renderChildren) => renderChildren && (<Dropdown text={name ? name : ''} item simple>
              <Dropdown.Menu>
                {(!process.env.REACT_APP_DEMO || process.env.REACT_APP_DEMO === 'APPRAISAL' || process.env.REACT_APP_DEMO === 'ALL') && <Menu.Item href='/user/setting' onClick={navigation}>簽名密碼</Menu.Item>}
                <GoogleLogout
                  clientId={process.env.REACT_APP_CLIENT_ID}
                  onFailure={onFailure}
                  render={renderLogout}
                />
              </Dropdown.Menu>
            </Dropdown>)}
          </Media.Media>
        </Menu.Menu>
      </Menu>
      <Media.Media at="mobile">
        {(mediaClassNames, renderChildren) => renderChildren && (
          <Menu size='huge' stackable id="top-mobile-nav" className={mediaClassNames+ (subMenuOpen ? ' open' : ' close')}>
            {menus.map(m => (
              <Menu.Item
                key={m.id}
                content={<><span className='menu-index'>{m.index}</span>{m.displayName}</>}
                disabled={!!m.header}
                href={m.header?undefined:m.url}
                onClick={m.header?undefined:navigation}
                active={pathname === m.url}
              />
            ))}
            {(!process.env.REACT_APP_DEMO || process.env.REACT_APP_DEMO === 'APPRAISAL' || process.env.REACT_APP_DEMO === 'ALL') && <Menu.Item href='/user/setting' onClick={navigation}>簽名密碼</Menu.Item>}
            <GoogleLogout
              clientId={process.env.REACT_APP_CLIENT_ID}
              onFailure={onFailure}
              render={renderLogout}
            />
          </Menu>)}
      </Media.Media>
    </div>
  )
}

export default memo(Nav);