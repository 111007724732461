/* import lodash */
import {keyBy, flatten, groupBy} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

/* import components */
import {
	PageHeader,
  EmptyTableMsg,
  MySelect
} from '../../../Components';

/* import helper functions */
import { get } from '../../../Helper/ApiHelper';
import {
  selectOptions, 
  threeYearPlanAndWhichYearFromYear,
  defaultYear,
  whetherReadOnlyForReport
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

/* import semantic-ui element */
import { Grid, Form, Button, Table, Segment } from 'semantic-ui-react';

class GroupFile extends Component {
  constructor(props) {
    super(props);

    this.TYPs = [];
    this.selectedTYP = '';
    this.whichYear = '';

    this.actionMap = {};
    this.groupActionFlattened = [];

    this.state = {
      finishedLoading: false,
      readOnly: true,

      year: [],
      yearInfo: [],
      yearId: '',
      canApprove: {
        teacherId: null,
        canApprove: false,
      },

      teacherLookup: {},
      teachersForAction: [],

      groupId: null,
      groupOptions: [],

      aims: [],
      aimOptions: [],
      selectedAim: '',

      strategies: [],
      actions: [],
      actionOptions: [],

      groupActions: [],
      actionTeachers: [],
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log("Error when mounting Group Actions");
      console.log(err);
    }
  }

  fetch = async () => {
    try {
      //get year info
      const [yearInfo, groups, teachers] = await Promise.all([
        get('getYear'),
        get('getGroup'),
        get('getAllTeacherInSchool'),
      ]);
      
      const yId = this.state.yearId;

      const yearId = yId || defaultYear(yearInfo);

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId,
        readOnly: whetherReadOnlyForReport(yearInfo, yearId),

        groupOptions: selectOptions(groups, 'name', 'id'),
        teacherLookup: keyBy(teachers, 'id'),
      });
      
      await this.fetch3YearPlanDetails();
      await this.fetchGroupInfo();
      
    } catch (err) {
      console.log('Error when fetching budgets');
      console.log(err);
    }
  }

  fetch3YearPlanDetails = async () => {
    try{
      
      const TYPs = await get('getTYP');
      
      const yId = this.state.yearId;

      const [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(this.state.yearInfo, TYPs, yId || undefined);

      const hasTYPchanged = this.selectedTYP !== selectedTYP;

      this.TYPs = TYPs;
      if(!this.selectedTYP || hasTYPchanged){
        this.selectedTYP = +selectedTYP;
      }
      this.whichYear = +whichYear;

      const concerns = await get(`get3YearConcern/${this.selectedTYP}`);

      const rawAims = await Promise.all(concerns.map(concern => get(`get3YearAim/${+concern.id}`)));
      const aimPrefix = {};

      rawAims.forEach((aims,i)=>{
        aims.forEach((aim,j)=>{
          aim.title = `${i+1}.${j+1}. ${aim.title}`;
          aimPrefix[aim.id] = `${i+1}.${j+1}.`;
        })
      });

      const aims = flatten(rawAims);

      await this.setStateAsync({
        aims,
        selectedAim: hasTYPchanged ? (aims && aims[0] ? aims[0].id : null) : this.state.selectedAim,
        aimOptions: selectOptions(aims, "title", "id")
      });

      const strategies = await get(`get3YearStrategy/${+this.state.selectedAim}`);

      strategies.forEach((s,i)=>{
        s.title = `${aimPrefix[s.aimId]}${i+1}. `+s.title;
      });

      await this.setStateAsync({
        strategies: strategies.filter(s => +s[`year${1+this.whichYear}`]),
      });

      const rawActions = await Promise.all(this.state.strategies.map(s => get(`get1YearAction/${this.state.yearId}/${+s.id}`)));
      const actions = flatten(rawActions);

      this.actionMap = keyBy(actions, "3yearstrategyId");

      const strategyLookup = this.state.strategies.reduce((prev,cur)=>({
        ...prev,
        [cur.id]: cur.title
      }),{})

      await this.setStateAsync({
        actions,
        actionOptions: selectOptions(actions, a=>strategyLookup[a["3yearstrategyId"]], "id")
      });

    }catch(err){
      console.log('Error when fetching TYP detail for group strategies');
      console.log(err);
    }
  };

  fetchGroupInfo = async () => {
    try{
      const canApprove = await get(`getGroupApprove/${+this.state.groupId}/${+this.state.yearId}`);
      const rawGroupActions = await Promise.all(this.state.actions.map(yA => get(`getGroupAction/${+this.state.groupId}/${+yA.id}`)));

      const groupActions = {};

      this.state.actions.forEach((a,i)=>{
        groupActions[a.id] = rawGroupActions[i];
      });
      
      this.groupActionFlattened = flatten(rawGroupActions);

      const rawActionTeachers = await Promise.all(this.groupActionFlattened.map(gA => get(`getGroupActionTeacher/${+gA.id}`)));
  
      await this.setStateAsync({
        canApprove,
        groupActions,
        actionTeachers: groupBy(flatten(rawActionTeachers), 'groupActionId')
      });

    }catch(err){
      console.log('Error when fetching group strategies');
      console.log(err);
    }
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value });
  }

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId } = this.state;
      if (yearId !== value) {
        await this.setStateAsync({
          yearId: value,
          finishedLoading: false,
          readOnly: whetherReadOnlyForReport(this.state.yearInfo, value),
        });

        await this.fetch3YearPlanDetails();
        await this.fetchGroupInfo();
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      console.log("Error when changing year for Group Action");
      console.log(err);
    }
  }

  /* Aim Change */
  aimChange = async (event, { value }) => {
    try{
      const { seletedAim } = this.state;
      if (seletedAim !== value) {
        await this.setStateAsync({
          selectedAim: value,
          finishedLoading: false,
        });
        
        await this.fetch3YearPlanDetails();
        await this.fetchGroupInfo();
        this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      console.log("Error when changing aim for One Year Evaluation");
      console.log(err);
    }
  }

  groupChange = async (event, { value }) => {
    try {
      const { groupId } = this.state;
      if (groupId !== value) {
        await this.setStateAsync({
          finishedLoading: false,
          groupId: value,
        });
        
        await this.fetchGroupInfo();
        await this.setStateAsync({ finishedLoading: true });
      }
    } catch (err) {
      console.log("Error when changing group for Group Action");
      console.log(err);
    }
  }

  render() {
    const {
      finishedLoading,

      year,
      yearId,
      readOnly,
      canApprove,

      aims,
      aimOptions,
      selectedAim,

      strategies,

      groupActions,

      actionTeachers,

      teacherLookup,

      groupOptions,
      groupId,
    } = this.state;

    return (
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='科組計劃 - 策略項目檔案提交' subTitle='按照科組計劃，為每個策略項目提交檔案' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Group>
                  <Form.Field inline>
                    <label>周年</label>
                    <MySelect
                      compact
                      disabled={!finishedLoading}
                      options={year}
                      value={yearId}
                      onChange={this.yearChange}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <label>目標</label>
                    <MySelect
                      compact
                      disabled={!finishedLoading||!aimOptions.length}
                      options={aimOptions}
                      value={selectedAim}
                      onChange={this.aimChange}
                      placeholder={aimOptions.length?"請選擇":"沒有三年計劃目標"}
                      error={!aimOptions.length}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <label>科組</label>
                    <MySelect
                      error={!groupId}
                      disabled={!finishedLoading}
                      options={groupOptions}
                      value={groupId}
                      onChange={this.groupChange}
                      placeholder="請先選擇科組"
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Table textAlign='center' celled unstackable fixed structured>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>標題</Table.HeaderCell>
                  <Table.HeaderCell width={5}>標題</Table.HeaderCell>
                  <Table.HeaderCell width={2}>時間表</Table.HeaderCell>
                  <Table.HeaderCell width={2}>負責人</Table.HeaderCell>
                  <Table.HeaderCell width={2}>所需資源</Table.HeaderCell>
                  <Table.HeaderCell width={2}>行動</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finishedLoading ?
                  <>{(aims.length === 0 || !groupId) ?
                    <EmptyTableMsg colSpan='6' msg={groupId?'沒有策略目標':'請先選擇科組'} />
                    : (
                        <>
                          {strategies.length ? strategies.map(s => {
                            const action = this.actionMap[s.id];
                            if(!action){
                              return (
                                <Table.Row key={"s"+s.id}>
                                  <Table.Cell textAlign="left">{s.title}</Table.Cell>
                                  <Table.Cell colSpan={5} textAlign="center"><strong>未有<span className='red'>周年</span>計劃項目</strong></Table.Cell>
                                </Table.Row>
                              );
                            }
                            const groupAction = groupActions[action.id];
                            if(!groupAction||!groupAction.length){
                              return (
                                <Table.Row key={"a"+s.id}>
                                  <Table.Cell textAlign="left">{s.title}</Table.Cell>
                                  <Table.Cell colSpan={5} textAlign="center"><strong>未有<span className='red'>科組</span>計劃項目</strong></Table.Cell>
                                </Table.Row>
                              )
                            }

                            if(groupAction.length===1 && groupAction[0].beginMonth+groupAction[0].endMonth === 0){
                              return (
                                <Table.Row key={"a" + s.id}>
                                  <Table.Cell disabled textAlign="left" className='line-through'>
                                    {s.title}
                                  </Table.Cell>
                                  <Table.Cell colSpan={5} textAlign="center">
                                    <span className='red'>{groupAction[0].title||'不適用'}</span>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            }
                            
                            return groupAction.map((gA,j)=>{

                              const teachers = (actionTeachers[gA.id]||[]).map(x=>x.teacherId) || [];
                              
                              const trueReadOnly = readOnly || (!teachers.includes(canApprove.teacherId) && !canApprove.canApprove);

                              return (<Table.Row key={"gA"+gA.id}>
                                {!j && <Table.Cell textAlign="left" rowSpan={groupAction.length}>{s.title}</Table.Cell>}
                                <Table.Cell>{gA.title}</Table.Cell>
                                <Table.Cell>{`${gA.beginMonth || '?'}月—${gA.endMonth || '?'}月`}</Table.Cell>
                                <Table.Cell>
                                  {
                                    teachers && teachers.length ? teachers.map(x => (teacherLookup[x] ? teacherLookup[x].shortName : "?")).join("") : "-"
                                  }
                                </Table.Cell>
                                <Table.Cell>{(gA.resourceNeeded) ? gA.resourceNeeded.split('\n').map((t,i)=><p key={i} className='form-group-no-margin'>{t}</p>) : "-"}</Table.Cell>
                                <Table.Cell key='actions'>
                                  {trueReadOnly ? "-" : (
                                    <Link to={'/report/file/group_action/' + gA.id}>
                                      <Button
                                        color='yellow'
                                        icon='folder open'
                                        circular
                                      />
                                    </Link>
                                  )}
                                </Table.Cell>
                              </Table.Row>)
                            })
                          }) : <EmptyTableMsg colSpan='6' msg="未有策略大綱" />}
                        </>
                      )
                  }</>
                  : <EmptyTableMsg colSpan='6' msg='載入資料中' />
                }
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(GroupFile);