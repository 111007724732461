/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	EmptyTableMsg,
  Buttons
} from '../../../../Components';

/* import helper functions */
import { get } from '../../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../../Helper/Helper';
import { displayDate } from '../../../../Helper/TimeHelper';

/* import lodash */
import { map, find, findIndex, compact, uniq, filter } from 'lodash';

/* import semantic-ui element */
import { Grid, Select, Table, Tab, Segment, Icon } from 'semantic-ui-react';

class FridayActivityLesson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,
      activeIndex: 0,
      yearOptions: [],
      selectedYear: '',
      students: [],
      normalFridayActivities: [],
      specialFridayActivities: [],
      normalDates: [],
      specialDates: [],
      schedules: [],

      tableHeader: [
        { displayName: '編號', allowSort: true, width: 1 },
        { displayName: '名稱', allowSort: true }
      ]
    }
  
    this.panes =  [
      { menuItem: '普通小組', render: this.displayNormal},
      { menuItem: '專項小組', render: this.displaySpecial},
    ];
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchYear = async () => {
    try{
      const result = await get('getYear');
      await this.setStateAsync({
        yearOptions: selectOptions(result, 'displayName', 'id'),
        selectedYear: defaultYear(result)
      })
    }catch(err){if(err!=='unmounted')console.log('Error when fetching year', err)}
  }

  fetchData = async () => {
    try{
      const {selectedYear} = this.state;
      const [students, fridayActivities, normalFridayActivityClasses, specialFridayActivityClasses] = await Promise.all([
        get('getStudentEssentialInfo/' + selectedYear),
        get('getFridayActivity/' + selectedYear),
        get('getAllNormalFridayActivityClass/' + selectedYear),
        get('getAllSpecialFridayActivityClass/' + selectedYear)
      ]);

      const scheduleGroups = compact(map(fridayActivities, ({ id, type }) => {
        if (type === 'normal') {
          return ({
            id: null,
            fridayActivityId: id,
            group: null
          })
        }
      }));

      const specialDates = uniq(map(specialFridayActivityClasses, 'date'));
      specialDates.sort();

      const normalDates = uniq(map(normalFridayActivityClasses, 'date'));
      normalDates.sort();

      let schedules = [];
      for(let date of normalDates){
        schedules.push({
          date,
          groups: map(scheduleGroups, group => {
            const classRecord = find(normalFridayActivityClasses, { date, fridayActivityId: group.fridayActivityId });
            if (classRecord) {
              return {
                id: classRecord.id,
                fridayActivityId: classRecord.fridayActivityId,
                group: classRecord.groupName,
                remark: classRecord.remark
              }
            } else {
              return group;
            }
          })
        })
      }

      await this.setStateAsync({
        students,
        normalFridayActivities: filter(fridayActivities, { type: 'normal' }),
        specialFridayActivities: filter(fridayActivities, { type: 'special' }),
        normalDates,
        specialDates,
        schedules
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity data', err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchYear();
    await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  yearChange = async (event, { value }) => {
    try{
      await this.setStateAsync({ finishedLoading: false, selectedYear: value });
      await this.fetchData();
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity', err)}
    this.setStateAsync({ finishedLoading: true });
  }

  handleTabChange = (e, { activeIndex }) => this.setStateAsync({ activeIndex })

  editFridayActivityLesson = () => {
    this.mounted = false;
    this.props.history.push('/eca/fridayActivity/lessonEdit/' + this.state.selectedYear);
  }

  displayNormal = () => {
    const {
      finishedLoading,
      normalFridayActivities,
      schedules,
      tableHeader
    } = this.state;

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top'>
          <Buttons.EditButton content='編輯普通小組分組及日期' onClick={this.editFridayActivityLesson} />
        </Segment>
        <Segment vertical className='segment-padding'>
          <Table textAlign='center' compact selectable celled fixed className="friday-lessons">
            <Table.Header>
              <Table.Row>
                {tableHeader.map(({ displayName, width }, index) => (
                  <Table.HeaderCell key={'t' + index} width={width}>{displayName}</Table.HeaderCell>
                ))}
                {schedules.map(({ date }, index) => (
                  <Table.HeaderCell key={'s' + index} width={1}>
                    {displayDate(date)}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {finishedLoading ? ( normalFridayActivities.length > 0 ? normalFridayActivities.map(({ id, code, name }) => {
                  return (
                    <Table.Row key={id}>
                      <Table.Cell>{code}</Table.Cell>
                      <Table.Cell>{name}</Table.Cell>
                      {schedules.map(({ groups }, scheduleIndex) => {
                        const groupIndex = findIndex(groups, { fridayActivityId: id });
                        return (
                          <Table.Cell key={scheduleIndex}>
                            {groups[groupIndex]['group']}
                          </Table.Cell>
                        )
                      })}
                    </Table.Row>
                  )
                })
                :
                <EmptyTableMsg colSpan={2} /> ) : <EmptyTableMsg colSpan={2} msg='載入資料中' />}
            </Table.Body>
          </Table>
        </Segment>
      </Tab.Pane>
    )
  }

  displaySpecial = () => {
    const {
      finishedLoading,
      specialFridayActivities,
      specialDates,
      tableHeader
    } = this.state;

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top' style={{ paddingLeft: 5, paddingTop: 20, paddingBottom: 20 }}>
          <Icon name='bell' /> 專項小組會採用普通小組的分組日期，毋需另外設定
        </Segment>
        <Segment vertical className='segment-padding'>
          <Table textAlign='center' compact selectable celled fixed className="friday-lessons">
            <Table.Header>
              <Table.Row>
                {tableHeader.map(({ displayName, width }, index) => (
                  <Table.HeaderCell key={'t' + index} width={width}>{displayName}</Table.HeaderCell>
                ))}
                {specialDates.map((date, index) => (
                  <Table.HeaderCell key={'s' + index} width={1}>
                    {displayDate(date)}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {finishedLoading ? (specialFridayActivities.length > 0 ? specialFridayActivities.map(({ id, code, name }) => {
                return (
                  <Table.Row key={id}>
                    <Table.Cell>{code}</Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    {specialDates.map((date, index) => (
                      <Table.Cell key={index}> - </Table.Cell>
                    ))}
                  </Table.Row>
                )
              }) : <EmptyTableMsg colSpan='2' /> ) : <EmptyTableMsg colSpan={2} msg='載入資料中' />}
            </Table.Body>
          </Table>
        </Segment>
      </Tab.Pane>
    )
  }

  render() {
    const {
      activeIndex,
      yearOptions,
      selectedYear,
    } = this.state;

    return (
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='普通小組上課日分配表' subTitle='設定每年度的普通小組上課日分配表' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Select onChange={this.yearChange} options={yearOptions} value={selectedYear} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Tab
              panes={this.panes}
              activeIndex={activeIndex}
              onTabChange={this.handleTabChange}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(FridayActivityLesson);