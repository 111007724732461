/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

/* import components */
import {
	PageHeader,
	FullscreenDimmer,
	EmptyTableMsg,
	ErrorLabel,
	BlockerPrompt,
  Buttons,
  MySelect
} from '../../../../Components';

/* import helper functions */
import { get, post } from '../../../../Helper/ApiHelper';
import { inputHandler } from '../../../../Helper/FormHelper';
import { htmlInputDate } from '../../../../Helper/TimeHelper';
import { hasDuplicate, selectOptions } from '../../../../Helper/Helper';

/* import lodash */
import { map, find, findIndex, filter, chain, cloneDeep } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Segment, Form, Table } from 'semantic-ui-react';

class FridayActivityLessonEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: true,
      isBlocking: false,
      normalGroupOptions: [],
      newScheduleGroups: [],

      yearId: this.props.match.params.yearId,
      year: {},
      normalFridayActivities: [],
      specialFridayActivities: [],
      schedules: [],
      hasPressedNew: false,
    }
    this.tableHeader = [
      { displayName: '編號', key: 'Code', allowSort: true },
      { displayName: '名稱', key: 'Name', allowSort: true },
    ];

    this.fileInputRef = React.createRef();

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => <ErrorLabel message={message} />,
      messages: {
        required: '請輸入資料',
        accepted: '此欄發生重複'
      }
    });
    this.newestDropdown = React.createRef();
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchData = async () => {
    const {yearId} = this.props.match.params;
    try{
      const [
        year,
        fridayActivities,
        normalFridayActivityGroups,
        normalFridayActivityClasses,
      ] = await Promise.all([
        get('getOneYear/' + yearId),
        get('getFridayActivity/' + yearId),
        get('getAllFridayActivityNormalGroup/' + yearId),
        get('getAllNormalFridayActivityClass/' + yearId)
      ]);

      const normalGroupOptions = selectOptions(normalFridayActivityGroups, 'code', 'id');

      const newScheduleGroups = chain(fridayActivities).filter(x=>x.type==='normal').map(x=>({
        id: null,
        fridayActivityId: x.id,
        group: null
      })).value();

      const dates = chain(normalFridayActivityClasses).map('date').uniq().sortBy().value();
      let schedules = [];
      map(dates, date => {
        schedules.push({
          original: true,
          date: htmlInputDate(date),
          groups: map(newScheduleGroups, group => {
            const classRecord = find(normalFridayActivityClasses, { date, fridayActivityId: group.fridayActivityId });
            if (classRecord) {
              return {
                id: classRecord.id,
                fridayActivityId: classRecord.fridayActivityId,
                group: classRecord.normalFridayActivityGroupId,
                remark: classRecord.remark
              }
            } else {
              return group;
            }
          })
        })
      })

      await this.setStateAsync({
        normalGroupOptions,
        newScheduleGroups,
        year: year ? year[0] : null,
        normalFridayActivities: filter(fridayActivities, { type: 'normal' }),
        specialFridayActivities: filter(fridayActivities, { type: 'special' }),
        schedules
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity', err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
    this.setStateAsync({ dimmerOpen: false });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  addSchedule = () => {
    const {schedules} = this.state;
    const data = {date: '', groups: []};
    if(schedules.length){
      data.date = moment(schedules[schedules.length-1].date, 'YYYY-MM-DD').add(1,'week').format('YYYY-MM-DD');
      data.groups = schedules[schedules.length-1].groups.map(x=>({...x, id: null}));
    }else{
      data.date = htmlInputDate();
      data.groups = this.state.newScheduleGroups;
    }
    this.setStateAsync({schedules: schedules.concat(data), isBlocking: true, hasPressedNew: true});
  }

  removeSchedule = (event) => {
    const schedules = this.state.schedules.slice(); //make a copy
    const index = findIndex(schedules, {date: event.target.closest('.ui').dataset.date});
    schedules.splice(index, 1);
    this.setStateAsync({schedules, isBlocking: true});
  }

  dateChange = (event, data) => {
    const {myindex} = event.target.closest('.ui').dataset;
    let value = inputHandler('text', data);
    const formattedDate = htmlInputDate(this.state.year.startDate);
    if(formattedDate.slice(5)>value.slice(5)){
      value = htmlInputDate(this.state.year.endDate).slice(0,5) + value.slice(5);
    }else{
      value = formattedDate.slice(0,5) + value.slice(5);
    }
    let schedules = cloneDeep(this.state.schedules);
    schedules[myindex]['date'] = value;
    this.setStateAsync({ schedules, isBlocking: true });
  }

  groupChange = (event, data) => {
    const {scheduleindex, groupindex} = event.target.closest('.ui').dataset;
    let value = inputHandler('select', data);
    let schedules = cloneDeep(this.state.schedules);
    schedules[scheduleindex]['groups'][groupindex] = {
      ...schedules[scheduleindex]['groups'][groupindex],
      group: value
    };
    this.setStateAsync({ schedules, isBlocking: true });
  }

  return = () => {
    this.mounted = false;
    this.props.history.push('/eca/fridayActivity/lesson');
  }

  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }

    const {schedules, specialFridayActivities} = this.state;

    let normalClasses = [];
    let specialClasses = [];
    map(schedules, ({ date, groups }) => {
      map(groups, ({ fridayActivityId, group }) => {
        normalClasses.push({
          fridayActivityId,
          date: date,
          group
        })
      })

      map(specialFridayActivities, ({ id }) => {
        specialClasses.push({
          fridayActivityId: id,
          date: date,
        })
      })
    })

    let data = {
      yearId: this.state.yearId,
      normalClasses,
      specialClasses
    };

    try{
      await this.setStateAsync({ dimmerOpen: true });
      const result = await post('editFridayActivityClasses', data);
      if (result&&result.status) {
        await this.setStateAsync({isBlocking: false});
        this.return();
        return;
      }
      throw result;
    }catch(err){
      if(err!=='unmounted'){
        alert(`儲存${this.state.year.displayName || null}普通小組分組資料時發生錯誤`);
        this.setStateAsync({ dimmerOpen: false });
        console.log('Error when saving normal activity grouping', err);
      }
    }
  }

  render() {
    const {
      dimmerOpen,
      normalGroupOptions,
      year,
      normalFridayActivities,
      schedules,
      hasPressedNew,
      isBlocking
    } = this.state;

    return (
      <>
        {dimmerOpen ?
          <FullscreenDimmer active={dimmerOpen} isLoading={true} />
          :
          <Grid stackable doubling>
            <Grid.Row>
              <Grid.Column>
                <PageHeader title='編輯普通小組分組分配' />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Form>
                  <Segment.Group className='form-group-margin'>
                    <Segment className='bold' size='big'>{year.displayName || null} 普通小組分組資料</Segment>
                    <Segment secondary>
                      <Buttons.AddButton className='button-margin-bottom' content='新增日期' onClick={this.addSchedule} />
                      <div style={{ marginTop: 10, marginLeft: 5 }}>(專項小組會採用相同分組日期)</div>
                    </Segment>
                    <Segment secondary style={{ overflowX: 'scroll' }}>
                      <Table textAlign='center' selectable celled fixed>
                        <Table.Header>
                          <Table.Row>
                            {this.tableHeader.map((x, index) => (
                              <Table.HeaderCell key={x.key} className={"fridayActivity"+x.key}>{x.displayName}</Table.HeaderCell>
                            ))}
                            {schedules.map(({ date, original }, index) => (
                              <Table.HeaderCell key={'s' + index} className="fridayActivityDateInput table-cell-with-dropdown">
                                {original ? date : <Form.Input
                                  type='date'
                                  data-myindex={index}
                                  onChange={this.dateChange}
                                  value={date}
                                  placeholder='日期'
                                  error={this.validator.message('date', date, 'required')}
                                  min={year.startDate}
                                  max={year.endDate}
                                  tabIndex={index*(normalFridayActivities.length+1)+1}
                                />}
                                {!original && (<Form.Field>
                                  <Buttons.DeleteButton data-date={date} onClick={this.removeSchedule}/>
                                </Form.Field>)}
                              </Table.HeaderCell>
                            ))}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {normalFridayActivities.length > 0 ?
                            normalFridayActivities.map(({ id, code, name }, i) => {
                              return (
                                <Table.Row key={id}>
                                  <Table.Cell>{code}</Table.Cell>
                                  <Table.Cell>{name}</Table.Cell>
                                  {schedules.map(({ groups }, scheduleIndex) => {
                                    const groupIndex = findIndex(groups, { fridayActivityId: id });
                                    return (
                                      <Table.Cell key={scheduleIndex} className="table-cell-with-dropdown">
                                        <MySelect
                                          fluid
                                          search
                                          data-scheduleindex={scheduleIndex}
                                          data-groupindex={groupIndex}
                                          onChange={this.groupChange}
                                          value={groups[groupIndex]['group']}
                                          options={normalGroupOptions}
                                          placeholder='普通小組'
                                          noResultsMessage="找不到小組"
                                          searchInput={!i && scheduleIndex===schedules.length-1 && hasPressedNew?{ autoFocus: true }:undefined}
                                          tabIndex={scheduleIndex*(normalFridayActivities.length+1)+i+2}
                                        />
                                        {this.validator.message('group', groups[groupIndex]['group'], 'required')}
                                      </Table.Cell>
                                    )
                                  })}
                                </Table.Row>
                              )
                            })
                            :
                            <EmptyTableMsg colSpan='2' />
                          }
                        </Table.Body>
                        <Table.Footer>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}></Table.HeaderCell>
                            {schedules.map(({groups}, i)=>(
                              <Table.HeaderCell key={i}>
                                {this.validator.message('duplicate'+i, hasDuplicate(groups.map(x=>x.group)), 'accepted')}
                              </Table.HeaderCell>
                            ))}
                          </Table.Row>
                        </Table.Footer>
                      </Table>
                    </Segment>
                  </Segment.Group>

                  <div className='text-center'>
                    <Buttons.CancelButton onClick={this.return} tabIndex={schedules.length*(normalFridayActivities.length+1)+1} />
                    <Buttons.SaveButton onClick={this.save} tabIndex={schedules.length*(normalFridayActivities.length+1)+2} />
                  </div>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(FridayActivityLessonEdit);