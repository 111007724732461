import { SECTIONDATA as sData } from './Helper';
import { cloneDeep } from 'lodash';
import { fromBlankAsync } from 'xlsx-populate';
import { exportExcel, convertToColumnTitle } from '../../Helper/ExcelHelper';

const falsy = x => x === null || x === undefined || x === "";
const getDisplayName = x => typeof x === 'string' ? x : x.displayName;

const renderHeader = ({
  pos,
  length,
  title,
  maxDepth,
  prevDepth,
  i,
  rowOffset,
  sheet
}) => {
  let cell = {
    row: i ? maxDepth - prevDepth + i + 1 : [1, maxDepth - prevDepth + i + 1],
    col: length > 1 ? [pos - length, pos - 1] : pos - 1
  };
  // correction for single-level fields (so that the filter field isn't merged for usability purpose)
  if (prevDepth === 1) {
    if (Array.isArray(cell.row)) cell.row[1] = cell.row[1] - 1;
  }
  // for groups not rendered before
  if (cell.row !== maxDepth && length) {
    let cellHandle;
    if (Array.isArray(cell.row) || Array.isArray(cell.col)) {
      cellHandle = sheet.range(`${convertToColumnTitle(
        typeof cell.col === 'number' ? cell.col : cell.col[0]
      )}${rowOffset + (typeof cell.row === 'number' ? cell.row : cell.row[0])
        }:${convertToColumnTitle(
          typeof cell.col === 'number' ? cell.col : cell.col[1]
        )}${rowOffset + (typeof cell.row === 'number' ? cell.row : cell.row[1])
        }`).merged(true);
      cellHandle = cellHandle.startCell();
    } else {
      cellHandle = sheet.cell(`${convertToColumnTitle(cell.col)}${rowOffset + cell.row}`);
    }
    cellHandle.value(title);
  }
}

/**
 * Reusable table renderer.
 * @param {{section: boolean[] | function, remark: string?, displayName: string}} format format from SEN Format
 * @param {object[]} groups SEN groups
 * @param {string} yearName name of year
 * @param {{data: object[], maxGradeDelayWidth: number?}} result SEN result
 * @param {object[]} sectionData custom sectionData override
 */
export const handleExport = (format, groups, yearName, result, sectionData, returnSheet = false) => {
  const rowOffset = 0;
  const SECTIONDATA = sectionData || sData;
  // shallow copy of filtered section data to incorporate new properties
  const filteredSection = SECTIONDATA.filter((s, i) => typeof format.section === 'function' ? format.section(i) : format.section[i]).map(x => ({ ...x }));

  const sectionDepths = filteredSection.reduce((prev, cur) => ({
    ...prev,
    [cur.displayName]: cur.dbName === 'gradeDelay' ? 2 : (cur.subFields ? (
      1 + Math.max(...cur.subFields.map(y => {
        const tempArray = getDisplayName(y).split("|");
        return tempArray.length - (tempArray.length && tempArray[tempArray.length - 1] === '註明');
      }))
    ) : 1)
  }), {});
  // get the maxDepth needed for hierarchical data
  const maxDepth = Math.max(...Object.values(sectionDepths));
  // never mutate source data (which is provided from state instead of fetched for Talent)
  const clonedData = cloneDeep(result.data);

  // change groups property from array to set for quick lookup (for SEN only)
  for (let r of clonedData) {
    if (Array.isArray(r.groups)) {
      r.groups = new Set(r.groups.map(x => x.name));
    } else {
      r.groups = new Set();
    }
  }

  // create the bottommost row where filter sits (filterFields) as well as the row render methods (rowRender)
  let filterFields = [];
  let rowRender = [];

  for (let x of filteredSection) {
    if (!x.noHeader) {
      filterFields.push(x.displayName);
      rowRender.push(x.fieldName?x.fieldName:x.displayName);
    }
    switch (x.dbName) { // special case for SEN only, as my custom format in Talent won't have dbName
      case 'others':
        for (let g of groups) {
          filterFields.push(`${x.displayName}|${g.name}`);
          rowRender.push(data => data.groups.has(g.name) ? "有" : null);
        }
        break;
      case 'gradeDelay':
        for (let i = 0; i < result.maxGradeDelayWidth; i++) {
          ['年度', '中文', '英文', '數學'].forEach(field => {
            filterFields.push(`${x.displayName}|${field + '#' + i}`);
            rowRender.push(`落後年級|${field + '#' + i}`);
          });
        }
        break;
      default:
        if (!x.subFields) break;

        x.subFields.forEach((_sF, i, a) => {
          const sF = getDisplayName(_sF);
          const hasChild = i < a.length - 1 && getDisplayName(a[i + 1]).indexOf(sF) >= 0;
          // omit if there are subFields AND no subHeader flag is ON (Talent)
          // omit 註明 fields since they're merged to parent fields in backend (for SEN only)
          if ((!x.noSubHeader || !hasChild) && sF.slice(sF.length - 3) !== '|註明') {
            const fieldName = `${x.displayName}|${sF}`;
            filterFields.push(fieldName);
            if (x.dbName === 'senReport') {
              rowRender.push(data => (data[fieldName] || '').replace(/[\b]/g, '\n'));
            } else {
              rowRender.push(typeof _sF === 'string' ? fieldName : _sF.fieldName);
            }
          }
        })
    }
  }

  // Names too long will make the "Excel error" nag.
  const filename = `${yearName}年度_${format.displayName}`.replace(/[\\/?*[\]]/g, '').slice(0, 31);
  return fromBlankAsync().then(workBook => {
    // create sheet with name
    let sheet = workBook.sheet(0).name(filename);
    // clone row by row to save memory
    // the [] is present because cell().value() takes in a 2-D array.
    let curRow = maxDepth + 1 + rowOffset;
    for (let rowData of clonedData) {
      sheet.cell('A' + (curRow++)).value([rowRender.map(col => {
        const value = typeof col === 'string' ? rowData[col] : col(rowData);
        return falsy(value) ? null : value;
      })])
    }

    if (maxDepth){
      filterFields.forEach((x,i)=>{
        const val = x.replace(/^.+\|/, '');
        sheet.cell(convertToColumnTitle(i+1) + (maxDepth + rowOffset)).value(val).style({ bold: true }).column().width(val.length*2.5+2.7);
      });
    }

    // unify horizontalAlignment, wrapText and apply autoFilter
    sheet.usedRange().style({ horizontalAlignment: 'left' }).autoFilter();

    let helperObj, i, colItems, prevDepth;
    if (maxDepth > 1) {
      let pos = 1;
      helperObj = Array.from({ length: maxDepth }).map(x => ({ title: '', length: 0 }));
      prevDepth = null;
      // generate header cells
      for (let col of filterFields) {
        colItems = col.split("|");
        if (pos > 1) {
          i = 0;
          while (i < colItems.length && colItems[i] === helperObj[i].title) {
            helperObj[i++].length++;
          }
          // some fields have ended, because no 2 consecutive items are the same, this MUST happen for every col
          while (i < maxDepth) {
            // if it's the top level that's completed, merge from top for alignment; otherwise only 1 row.
            renderHeader({
              ...helperObj[i],
              maxDepth,
              prevDepth,
              i,
              rowOffset,
              sheet,
              pos
            })
            if (i < colItems.length) // inherit data
              helperObj[i] = { title: colItems[i], length: 1 };
            else // clear data
              helperObj[i] = { title: '', length: 0 };
            i++;
          }
        } else {
          colItems.forEach((c, i) => {
            helperObj[i] = { title: c, length: 1 }
          })
        }
        pos += 1;
        prevDepth = sectionDepths[colItems[0]];
      }
    }

    if (helperObj) {
      helperObj.forEach(({ title, length }, i) => {
        if (length) {
          renderHeader({
            title,
            length,
            maxDepth,
            prevDepth,
            i,
            rowOffset,
            sheet,
            pos: filterFields.length + 1,
          })
        }
      });
    }

    // beautify the top rows
    if (maxDepth > 1 || filterFields.length) {
      sheet.range(`A${rowOffset + 1}:${convertToColumnTitle(filterFields.length)}${Math.max(1, rowOffset + maxDepth - 1)}`).style({
        horizontalAlignment: 'center',
        verticalAlignment: 'top',
        fill: 'eeeeee',
        bold: true
      });
    }

    // beautify the whole region
    sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true } });

    // add remark if any
    if (format.remark) {
      const remarkRow = clonedData.length + maxDepth + 2 + rowOffset;
      sheet.range(`A${remarkRow}:E${remarkRow}`).merged(true).startCell().value(format.remark);
      sheet.row(remarkRow).height((format.remark || '').split('\n').length * 16);
    }

    sheet.usedRange().style({ fontSize: 12, wrapText: true });

    if(returnSheet) return [workBook, sheet, filename];

    return workBook.outputAsync().then(file => {
      exportExcel(file, filename);
      return true;
    });
  })
}