/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import ErrorLabel from '../../../Components/ErrorLabel';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import ConfirmModal from '../../../Components/ConfirmModal';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../Helper/Helper';
import { buildTree } from '../../../Helper/TreeHelper';
import { inputHandler } from '../../../Helper/FormHelper';

/* Appraisal css */
import '../../../Styles/Appraisal.css';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Select, Button, List, Segment, Modal, Form, Label } from 'semantic-ui-react';

class Content extends Component {
    constructor(props) {
        super(props);
        ping(this);

        //form validator
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            element: message =>  <ErrorLabel message={message} />,
            messages: {
                default: '請輸入資料'
            }
        });
    }
    
    state = {
        dimmerOpen: true,

        year: [],
        selectedYear: '',
        
        appraisalItem: [],
        appraisalLock: {},

        copyYear: '',
        weighting: {},

        isEditModalOpen: false,
        isCopyModalOpen: false,
        isLockModalOpen: false,
        isUnlockModalOpen: false
    }

    fetchYear = async () => {
        return new Promise(async (resolve, reject) => {
            const result = await get('getYear');
            let selectedYear = defaultYear(result);
            let year = selectOptions(result, 'displayName', 'id');
            this.setState({ selectedYear, year })
            resolve(selectedYear);
        });
    }

    fetch = async (selectedYear) => {
        const [appraisalItem, appraisalLock] = await Promise.all([
            get('getAllAppraisalItem/'+selectedYear), 
            get('getOneAppraisalLock/'+selectedYear)
        ]);
        let weighting = {};
        appraisalItem.forEach(({id, percentage}) => { weighting[id] = percentage; })
        this.setState({
            weighting,
            appraisalItem: buildTree(appraisalItem),
            appraisalLock: appraisalLock.length > 0? appraisalLock[0] : {},
        })
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    componentDidMount = async () => {
        this.mounted = true;
        const selectedYear = await this.fetchYear();
        await this.fetch(selectedYear);
        this.setState({dimmerOpen: false});
    }

    yearChange = async (event, { value }) => {
        const { selectedYear } = this.state;
        if(selectedYear !== value) {
            this.setState({dimmerOpen: true});
            await this.fetch(value);
            this.setState({
                selectedYear: value,
                dimmerOpen: false
            })
        }
    }

    copyYearChange = (event, { value }) => {
        this.setState({ copyYear: value })
    }

    modalToggle = (modalStateName) => () => {
        this.setState({[modalStateName]: !this.state[modalStateName]});
    }

    inputChange = (inputType, id) => (event, data) => {  
        let { weighting } = this.state;
        let value = inputHandler(inputType, data);
        weighting[id] = value;
        let newWeighting = weighting;
        this.setState({ weighting: newWeighting })
    }    

    save = async (event) => {
        event.preventDefault();
        // if (!this.validator.allValid()) {
        //     this.validator.showMessages();
        //     this.forceUpdate();
        //     return;
        // }

        const { weighting, selectedYear } = this.state;
        this.setState({dimmerOpen: true})
        let edit = await post('editAppraisalItemPercentage', { weighting });
        if(edit.status) {
            await this.fetch(selectedYear);
            this.validator.hideMessages();
            this.forceUpdate();
            this.setState({ 
                isEditModalOpen: false,
                dimmerOpen: false 
            })
        }
    }

    details = (id, tableFormat) => () => {
        const yearId = this.state.selectedYear;
        if(tableFormat) {
            this.props.history.push('/appraisal/content/table/' + yearId + '/' + id);
            return;
        }
        this.props.history.push('/appraisal/content/form/' + yearId + '/' + id);
    }

    lock = async () => {
        const { selectedYear } = this.state;
        this.setState({dimmerOpen: true})
        let lock = await post('lockAppraisalContent', { yearId: selectedYear });
        if(lock && lock.status) {
            await this.fetch(selectedYear);
            this.setState({ 
                isLockModalOpen: false,
                dimmerOpen: false 
            })
        }
    }

    unlock = async () => {
        const { selectedYear } = this.state;
        this.setState({dimmerOpen: true})
        let unlock = await post('unlockAppraisalContent', { yearId: selectedYear });
        if(unlock && unlock.status) {
            await this.fetch(selectedYear)
            this.setState({ 
                isUnlockModalOpen: false,
                dimmerOpen: false 
            })
        }
    }

    displayForm = (appraisalItem, level = 1) => {
        const { weighting } = this.state;
        return (
            <Fragment>
                {appraisalItem.map(({id, displayName, children}, index) => {
                    return (
                        <Form.Group key={index} className={'form-group-margin'} grouped>
                            <Form.Input 
                                onChange={this.inputChange('number', id)} 
                                type='number' 
                                className={level > 1? 'next-layer' : ''} 
                                value={weighting[id] || 0} 
                                label={displayName} 
                                placeholder='比例(0-100%)' min='0' max='100' 
                                error={this.validator.message('weight'+id, weighting[id], 'required|min:0,num|max:100,num')}
                            />
                            {children && 
                                <Fragment>{this.displayForm(children, level+1)}</Fragment>
                            }
                        </Form.Group>
                    )
                })}
            </Fragment>
        )
    }

    displayItem = (appraisalItem) => {
        const { appraisalLock } = this.state;
        return (
            <Fragment>
                {appraisalItem.map(({id, displayName, percentage, tableFormat, children}, index) => {
                    return (
                        <List.Item key={index}>
                            <Segment>
                                <Grid columns={2} stackable >
                                    <Grid.Row verticalAlign='middle'>
                                        <Grid.Column>
                                            {percentage !== null &&
                                                <Label style={{marginLeft: '10px'}}  color='blue' horizontal basic>{percentage}%</Label>
                                            }
                                            <b style={{marginLeft: '10px'}} >{displayName}</b>
                                        </Grid.Column>
                                        {!children && 
                                            <Grid.Column textAlign='right'>
                                                <Button onClick={this.details(id, tableFormat)} color='blue' icon={appraisalLock.contentLock? 'eye' : 'edit'} circular />  
                                            </Grid.Column>
                                        }
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            {children && 
                                <List.Item>
                                    <List.List>
                                        {this.displayItem(children)}
                                    </List.List>
                                </List.Item>
                            }
                        </List.Item>
                    )
                })}
            </Fragment>
        )
    }

    displayCopyModal = () => {
        const { isCopyModalOpen, year, copyYear } = this.state;
        this.validator.purgeFields();
        return (
            <Modal open={isCopyModalOpen} onClose={this.modalToggle('isCopyModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
                <Modal.Header>複製以往評估內容</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group className='form-group-margin' grouped>
                            <Form.Select 
                                onChange={this.copyYearChange}
                                value={copyYear}
                                options={year} 
                                label='選擇複製年度' 
                                placeholder='選擇複製年度' 
                                clearable
                                error={this.validator.message('copyYear', copyYear, ['required'])}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isCopyModalOpen')} circular />
                    <Button color='green' content='複製' icon='copy' onClick={this.copy} circular />
                </Modal.Actions>    
            </Modal>
        )
    }

    copy = async (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        const { selectedYear, copyYear } = this.state;
        this.setState({dimmerOpen: true})
        let copy = await post('copyAppraisalContent', { yearId: selectedYear, copyYearId: copyYear });
        if(copy.status) {
            await this.fetch(selectedYear);
            this.validator.hideMessages();
            this.setState({ 
                isCopyModalOpen: false,
                dimmerOpen: false 
            })
        }else{
            alert(copy.error)
            this.setState({ 
                dimmerOpen: false 
            })
        }
    }

    render() {
        this.validator.purgeFields();
        const { dimmerOpen, year, selectedYear, appraisalLock, appraisalItem, isEditModalOpen, isLockModalOpen, isUnlockModalOpen } = this.state;
        
        return (
            <Fragment>
                {dimmerOpen? 
                    <FullscreenDimmer active={dimmerOpen} isLoading={true} />
                    :
                    <Fragment>                
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <PageHeader title='評估內容設定' subTitle='設定每年度之評估內容' />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Select className='button-margin-bottom' onChange={this.yearChange} options={year} value={selectedYear} />
                                    <div>
                                        {Boolean(appraisalLock.contentLock)?
                                            <Button color='red' content='解鎖本年度評估內容' icon='unlock' onClick={this.modalToggle('isUnlockModalOpen')} circular />                                
                                            :
                                            <Fragment>
                                                {appraisalItem.length === 0?
                                                    <Button className='button-margin-bottom' color='blue' content='複製以往內容' icon='copy' onClick={this.modalToggle('isCopyModalOpen')} circular />                                
                                                    :
                                                    <Fragment>
                                                        <Button className='button-margin-bottom' color='orange' content='鎖定本年度內容' icon='lock' onClick={this.modalToggle('isLockModalOpen')} circular />                                
                                                        <Button className='button-margin-bottom' color='green' content='編輯計分比例' icon='edit' onClick={this.modalToggle('isEditModalOpen')} circular />                                
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }
                                    </div>
                                </Grid.Column>                            
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment padded='very' secondary>
                                        {appraisalItem.length > 0?
                                            <List>
                                                {this.displayItem(appraisalItem)}
                                            </List>
                                            :
                                            <Fragment>
                                                <Segment textAlign='center' basic>沒有評估項目</Segment>
                                            </Fragment>
                                        }
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Modal open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
                            <Modal.Header>編輯計分比例(0-100%)</Modal.Header>
                            <Modal.Content>
                                <Segment basic>
                                    <Form>
                                        {this.displayForm(appraisalItem)}
                                    </Form>
                                </Segment>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
                                <Button color='green' content='儲存' icon='save' onClick={this.save} circular />
                            </Modal.Actions>    
                        </Modal>

                        {this.displayCopyModal()}

                        <ConfirmModal open={isLockModalOpen} description='確定鎖定今年評估內容？' cancel={this.modalToggle('isLockModalOpen')} confirm={this.lock} confirmText='鎖定' confirmIcon='lock' />
                        <ConfirmModal open={isUnlockModalOpen} description='確定解鎖今年評估內容？（不建議）' cancel={this.modalToggle('isUnlockModalOpen')} confirm={this.unlock} confirmText='鎖定' confirmIcon='lock' />
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default withRouter(Content);