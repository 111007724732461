export const tableColumnData = [
  { headerName: '班別', cellRender: 'classCode', width: 1, allowSort: true },
  { headerName: '班號', cellRender: 'classNo', width: 1, allowSort: true },
  { headerName: '學生姓名', cellRender: x=>x.chiName||x.engName, width: 3, allowSort: true },
  { headerName: '性別', cellRender: 'gender', width: 1, allowSort: true },
  { headerName: '專項活動/普通小組', cellRender: x=>x.group||'-', width: 3, allowSort: true, sortMethod: x=>{
    let str = String(x.group);
    if(typeof x.group === 'number'){
      str = Array.from({length: 3-str.length}, x=>"0").join("")+str;
    }
    return str;
  } },
  { headerName: '專項活動名稱', cellRender: x=>x.fridayActivity||'-', width: 3, allowSort: true, sortMethod: 'fridayActivity' }
];