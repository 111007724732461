/* import react */
import React, { useMemo, useState, useEffect, useReducer, useCallback } from 'react';

/* import components */
import {
  PageHeader,
  MyTable,
  Buttons,
  BlockerPrompt,
  FullscreenDimmer
} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import {findIndex} from 'lodash';

/* import semantic-ui element */
import { Grid, Input, Segment } from 'semantic-ui-react';

const reducer = (state, action) => {
  switch(action.type){
    case 'SET_ALL':
      return Array.isArray(action.payload)?{array: action.payload, newCount: -1}:{array: [], newCount: -1};
    case 'ADD':
      return {array: state.array.concat({id: state.newCount, name: ''}), newCount: state.newCount-1};
    case 'SET_ONE':
      const i = findIndex(state.array, {id: action.id});
      if(i<0) return state;
      const ar = [...state.array];
      ar[i] = {...ar[i], name: action.payload};
      return {...state, array: ar};
    default:
      return state;
  }
}

const TalentType = (props) => {  
  const [isBlocking, setBlocking] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [dimmerOpen, setDimmer] = useState(false);
  const [data, setData] = useReducer(reducer, {array: [], newCount: -1});
  const [reloadCount, setReload] = useState(0);

  useEffect(()=>{
    const abortController = new AbortController();
    const fetchData = async () => {
      try{
        const data = await get('getTalentTypes',[],abortController);
        setData({type: 'SET_ALL', payload: data});
        setLoading(false);
        return () => {
          abortController.abort();
        }
      }catch(err){
        if(err.name!=='AbortError')
          console.log(err);
      }
    };
    fetchData();
  }, [reloadCount]);

  const save = useCallback(()=>{
    const abortController = new AbortController();
    const saveData = async () => {
      try{
        setDimmer(true);
        const result = await post('setTalentTypes', {
          talents: data.array
        }, 'JSON', abortController);
        if(result.status){
          setBlocking(false);
          setReload(reloadCount+1);
        }
        setDimmer(false);
        return () => {
          abortController.abort();
        }
      }catch(err){
        if(err.name!=='AbortError')
          console.log(err);
      }
    };
    saveData();
  }, [data.array,reloadCount]);

  const tableInfo = useMemo(()=>{
    return {
      data:[
        {headerName: '資優特質', width: 5, useIndex: true, cellRender: item=>(
          <Input type='text' fluid value={item.name} onChange={(event, data)=>{
            const value = inputHandler('text', data);
            setData({type: 'SET_ONE', payload: value, id: item.id})
            setBlocking(true)
          }}/>
        )},
      ],
      footer: ()=><Buttons.AddButton onClick={(event)=>{
        setData({type: 'ADD'})
        setBlocking(true)
      }} disabled={isLoading} />
    }
  }, [isLoading]);
  return (<>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <PageHeader title='教師推薦資優特質' />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <MyTable
            finishedLoading={!isLoading}
            tableColumnData={tableInfo.data}
            tableFooterRender={tableInfo.footer}
            data={data.array}
            footer
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <Buttons.SaveButton disabled={isLoading} onClick={save} />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <FullscreenDimmer active={dimmerOpen} isLoading={true} />
    <BlockerPrompt isBlocking={isBlocking}/>
  </>)
}

export default TalentType;