import download from 'downloadjs';
import { fileTimeStamp } from './TimeHelper';

export const localhost =
	process.env.NODE_ENV === 'development' ? 'http://localhost:' + process.env.REACT_APP_BACKEND_PORT : '';

/**
 * Ping for class components
 * @param {*} component React Class Component
 */
export const ping = (component) => {
	const {
		location: { pathname },
	} = component.props;
	post('ping', { pathname }).then((response) => {
		if (!response || !response.status) {
			console.log('PING', response);
			component.props.history.push('/');
		}
	});
};

/**
 * Custom API handle for Get, with abort control
 * @param {string} method main method string
 * @param {(string|number)[]?} params Each directory to be appended to the main method
 * @param {AbortController?} abortController https://developers.google.com/web/updates/2017/09/abortable-fetch
 */
export const get = async (method, params = [], abortController) => {
	let paramText = '';
	for (let i = 0; i < params.length; i++) {
		paramText += '/' + params[i];
	}
	try {
		const response = await fetch(localhost + '/' + method + paramText, {
			method: 'GET',
			credentials: 'include',
			signal: abortController ? abortController.signal : undefined,
		});
		const r = await response.json();
		if (!r) throw { status: 404, err: '無法連至伺服器' };
		if (r.status) return r.result;
		return [];
	} catch (err) {
		if (err && err.name === 'AbortError') return Promise.reject(err);
		//alert(`發生錯誤${err.status}，無法讀取 ${method}`);
		console.log('Error during get', method, err);
		return [];
	}
};

/**
 *
 * @param {string} method main method string
 * @param {object} data data object
 * @param {'JSON'|'FORMDATA'?} type use FORMDATA for files
 * @param {AbortController?} abortController https://developers.google.com/web/updates/2017/09/abortable-fetch
 */
export const post = async (method, data = {}, type = 'JSON', abortController) => {
	try {
		let response;
		switch (type) {
			case 'TEXT':
			case 'JSON':
				response = await fetch(localhost + '/' + method, {
					method: 'POST',
					body: JSON.stringify(data),
					headers: { 'Content-Type': 'application/json' },
					credentials: 'include',
					signal: abortController ? abortController.signal : undefined,
				});
				break;
			case 'FORMDATA':
				const formData = new FormData();
				Object.entries(data).map(([i, v]) => {
					formData.append(i, v);
				});
				response = await fetch(localhost + '/' + method, {
					method: 'POST',
					body: formData,
					credentials: 'include',
					signal: abortController ? abortController.signal : undefined,
				});
				break;
			case 'FORMAPPRAISAL':
				response = await fetch(localhost + '/' + method, {
					method: 'POST',
					body: data,
					credentials: 'include',
					headers: {
						'Keep-Alive': 'timeout=500',
					},
					signal: abortController ? abortController.signal : undefined,
				});
				break;
			default:
				return false;
		}

		if (response.status === 200) {
			if (type === 'TEXT') return await response.text();
			return await response.json();
		} else {
			const text = await response.text();
			try {
				const info = JSON.parse(text);
				if (info && typeof info === 'object') {
					if (info.err === '請先登入') alert('用戶登錄逾時，用戶可在另一個視窗重新登入再回到本視窗進行操作');
					else alert(`發生錯誤${response.status}，無法編輯\n(${info.err})`);
				} else {
					throw new Error();
				}
				return info;
			} catch (err) {
				alert(`發生錯誤${response.status}，無法編輯\n(${text})`);
			}
			return { status: false };
		}
	} catch (err) {
		alert(`發生錯誤，無法編輯\n(${method})`);
		console.log(err);
	}
};

export const doc = async (uri, data = {}, fileName, method = 'GET') => {
	if (fileName.match(/^[^<>:;,?"*|/]+$/)) {
		try {
			let queryString, wholeURI, response;
			switch (method) {
				case 'GET':
					queryString = Object.entries(data)
						.map(([x, j]) => `${encodeURIComponent(x)}=${encodeURIComponent(j)}`)
						.join('&');
					wholeURI = localhost + '/' + uri + (queryString ? '?' + queryString : '');
					response = await fetch(wholeURI, {
						method,
						credentials: 'include',
					});
					break;
				case 'POST':
					wholeURI = localhost + '/' + uri;
					response = await fetch(wholeURI, {
						method,
						body: JSON.stringify(data),
						headers: { 'Content-Type': 'application/json' },
						credentials: 'include',
					});
					break;
				default:
					alert('HTTP方法錯誤');
			}
			if (response && response.status === 200) {
				const blob = await response.blob();
				download(
					blob,
					`${fileName} ${fileTimeStamp()}.docx`,
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
				);
			} else {
				const text = await response.text();
				alert(`發生錯誤${response.status}，無法取得檔案\n(${text})`);
			}
		} catch (err) {
			console.log(err);
		}
	} else {
		throw new Error('invalid Filename');
	}
};

export const getFile = async (data = {}, fileName) => {
	if (fileName.match(/^[^<>:;,?"*|/]+$/)) {
		try {
			const queryString = Object.entries(data)
				.map(([x, j]) => `${encodeURIComponent(x)}=${encodeURIComponent(j)}`)
				.join('&');
			const wholeURI = localhost + '/file' + (queryString ? '?' + queryString : '');
			const response = await fetch(wholeURI, {
				method: 'GET',
				credentials: 'include',
			});
			if (response.status === 200) {
				const blob = await response.blob();
				download(blob, `${fileName}`);
			} else {
				const text = await response.text();
				alert(`發生錯誤${response.status}，無法取得檔案\n(${text})`);
			}
		} catch (err) {
			console.log(err);
		}
	} else {
		throw new Error('invalid Filename');
	}
};

export const getTaskFile = async (data = {}, fileName) => {
	if (fileName.match(/^[^<>:;,?"*|/]+$/)) {
		try {
			const queryString = Object.entries(data)
				.map(([x, j]) => `${encodeURIComponent(x)}=${encodeURIComponent(j)}`)
				.join('&');
			const wholeURI = localhost + '/taskFile' + (queryString ? '?' + queryString : '');
			const response = await fetch(wholeURI, {
				method: 'GET',
				credentials: 'include',
			});
			if (response.status === 200) {
				const blob = await response.blob();
				download(blob, `${fileName}`);
			} else {
				const text = await response.text();
				alert(`發生錯誤${response.status}，無法取得檔案\n(${text})`);
			}
		} catch (err) {
			console.log(err);
		}
	} else {
		throw new Error('invalid Filename');
	}
};

export const getAppraisalFile = async (method, data = {}, abortController) => {
	try {
		const response = await fetch(localhost + '/' + method, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include',
			signal: abortController ? abortController.signal : undefined,
		});

		if (response.status === 200) {
			const blob = await response.blob();
			download(blob, data.name);
		} else {
			const text = await response.text();
			alert(`發生錯誤${response.status}，無法取得檔案\n(${text})`);
		}
	} catch (err) {}
};
