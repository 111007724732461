/* import react */
import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {keyBy, find, pick} from 'lodash';

/* import components */
import {
	FullscreenDimmer,
	ErrorLabel,
	EmptySegmentMsg,
  MySelect,
  DateInput
} from '../../Components';

import {getTeacherOptions} from '../../Helper/Helper';
import {get, post} from '../../Helper/ApiHelper';
import {inputHandler, extOptions} from '../../Helper/FormHelper';
import {momentToDate, isValid, timeToDescription, dateToMoment, momentToDbFormat} from '../../Helper/TimeHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

import {Grid, Segment, Header, Table, Button, Modal, Form, Input, Select, Checkbox} from 'semantic-ui-react';
import '../../Styles/SurveyDashboard.css';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myTasks: null,
      pendingTasks: null,
      dimmerOpen: false,
      finishedLoading: false,
      teacherLookup: {},
      teacherOptions: [],
      teacher_target: [],
      
      dueDate: dateToMoment(),
      title: '',
      extension: '',
    }
    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      messages: {
        required: '請提供:attribute',
        accepted: '請最少選擇:attribute',
      },
      autoForceUpdate: this
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true
      });
    }catch(err){
      console.log("Error when loading surveys to be completed");
      console.log(err);
    }
  }
  
  fetch = async () => {
    try {
      const [myTasks,pendingTasks, teachers, teacherOptions] = await Promise.all([
        get('getMyTask'),
        get('getPendingTask'),
        get('getAllTeacherWithAdminInSchool'),
        getTeacherOptions(),
      ]);
      await this.setStateAsync({
        myTasks,
        pendingTasks,
        teacherLookup: keyBy(teachers, 'id'),
        teacherOptions,
      });
    } catch (err) {
      console.log("Error when loading file submission tasks");
      console.log(err);
    }
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({
      [stateName]: value,
    })
  }

  /* modal toggle */
  modalToggle = (modalStateName, selectedId = null) => () => {
    const data = {
      [modalStateName]: !this.state[modalStateName],
      selectedId,
      teacher_target: []
    };

    if (selectedId) {
      const task = find(this.state.pendingTasks, { id: selectedId });
      Object.assign(data, pick(task, ['title', 'extension']));
      data.dueDate = dateToMoment(task.dueDate);
    } else {
      Object.assign(data, {
        dueDate: dateToMoment(),
        title: '',
        extension: '',
      });
    }
    this.setStateAsync(data);
  }

  /* edit year */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }

    const { dueDate, selectedId } = this.state;
    let data = pick(this.state, ['title', 'extension']);
    if(selectedId) data.id = selectedId;
    data.dueDate = momentToDbFormat(dueDate);
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true
      });
      const result = await post('editTask', data);
      if(!result.status){
        alert("編輯時發生錯誤");
      }else{
        await post('batchEditTaskTarget', {
          taskId: result.insertId,
          teacher: this.state.teacher_target
        });
      }
    }catch(err){
      console.log("Error when editing task");
      console.log(err);
    }
    
    await this.fetch();
    try{
      await this.setStateAsync({
        isEditModalOpen: false,
        finishedLoading: true,
        dimmerOpen: false
      });
    }catch(err){}
  }

  navToTask = (id) => () => {
    this.props.history.push(`/task/submit/${+id}`)
  }

  // Return "green" if done, "red" if there's only 7 days left, "orange" if there's only 1 month left, else undefined
  getClassName = (done, date) => {
    if(done){
      return 'green cursor';
    }
    const dMoment = dateToMoment(date), now = dateToMoment();
    if(dMoment.isBefore(now)){
      return 'inverted red cursor';
    }
    if(dMoment.diff(now, "days")<7){
      return 'red cursor';
    }
    if(dMoment.diff(now, "month")<1){
      return 'orange cursor'
    }
    return 'cursor';    
  }


  render() {
    const {
      myTasks, 
      pendingTasks, 
      dimmerOpen, 
      isEditModalOpen, 
      title, 
      extension,
      dueDate,
      showComplete,

      teacherOptions,
      teacher_target,
      teacherLookup,
    } = this.state;

    const truePendingTasks = (pendingTasks||[]).filter(x=>showComplete||!x.done);

    return (
      <>
      <Grid padded='vertically'>
        <Grid.Row>
          <Grid.Column>
            <Checkbox label='顯示已完成的項目' checked={showComplete} onChange={this.inputChange('checkbox', 'showComplete')} />
            <Button color='green' content='新增檔案提交任務' icon='add' floated='right' onClick={this.modalToggle('isEditModalOpen')} circular />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
              <Segment>
                <Header as='h2' textAlign='center'>
                  <Header.Content>已收到的檔案提交任務</Header.Content>
                </Header>
              </Segment>
              {
                pendingTasks===null?(
                  <EmptySegmentMsg loading={true} msg="資料載入中"/>
                ):(truePendingTasks.length?(
                  <Table fixed selectable celled>
                    <colgroup>
                      <col/>
                      <col style={{width: '5rem'}}/>
                      <col style={{width: '10rem'}}/>
                    </colgroup>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>標題</Table.HeaderCell>
                        <Table.HeaderCell>發起人</Table.HeaderCell>
                        <Table.HeaderCell>截止日期</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {truePendingTasks.map(({id, title, teacherId, dueDate, done})=>{
                      let className = this.getClassName(done, dueDate);
                      return (
                        <Table.Row
                          className={className}
                          onClick={this.navToTask(+id)}
                          key={id}
                        >
                          <Table.Cell><Header as="h4" inverted={/inverted/.test(className)}>{title}</Header></Table.Cell>
                          <Table.Cell>{teacherLookup[teacherId].name}</Table.Cell>
                          <Table.Cell>{timeToDescription(dueDate)}</Table.Cell>
                        </Table.Row>
                      )
                    })}
                    </Table.Body>
                  </Table>
                ):(<EmptySegmentMsg msg="您沒有要完成的檔案提交任務"/>))
              }
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment>
              <Header as='h2' textAlign='center'>
                <Header.Content>我發起的檔案提交任務</Header.Content>
              </Header>
            </Segment>
            {
              myTasks===null?(
                <EmptySegmentMsg loading={true} msg="資料載入中"/>
              ):(myTasks.length?(
                <Table fixed selectable celled>
                  <colgroup>
                    <col/>
                    <col style={{width: '10rem'}}/>
                  </colgroup>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>標題</Table.HeaderCell>
                      <Table.HeaderCell>截止日期</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {myTasks.map(({id, title, dueDate})=>(
                      <Table.Row
                        onClick={this.navToTask(+id)}
                        key={id}
                      >
                        <Table.Cell><Header as="h4">{title}</Header></Table.Cell>
                        <Table.Cell>{timeToDescription(dueDate)}</Table.Cell>
                      </Table.Row>
                  ))}
                  </Table.Body>
                </Table>
              ):(<EmptySegmentMsg msg="您未有設立檔案提交任務"/>))
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {dimmerOpen?(<FullscreenDimmer active={dimmerOpen} isLoading={true} />):(
        <Modal open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
          <Modal.Header>設定檔案提交任務</Modal.Header>
          <Modal.Content>
            <Segment basic>
              <Form>
                <Form.Field required>
                  <label>標題</label>
                  <Input
                    value={title || ''}
                    placeholder='標題'
                    onChange={this.inputChange('text', 'title')}
                  />
                  {this.validator.message('標題', title, 'required')}
                </Form.Field>
                <Form.Group className='form-group-margin' widths={2}>
                  <Form.Field required>
                    <label>檔案格式</label>
                    <Select
                      options={extOptions}
                      value={extension || ''}
                      onChange={this.inputChange('select', 'extension')}
                      placeholder='檔案格式'
                    />
                    {this.validator.message('檔案格式', extension, 'required')}
                  </Form.Field>
                  <Form.Field required>
                    <label>截止日期</label>
                    <DateInput
                      popupPosition='bottom center'
                      value={momentToDate(dueDate)}
                      onChange={this.inputChange('date', 'dueDate')}
                      minDate={momentToDate()}
                      placeholder='開始日期'
                    />
                    {this.validator.message('dueDate', isValid(dueDate), 'accepted')}
                  </Form.Field>
                </Form.Group>
                <Form.Field required>
                  <label>要完成任務的教師</label>
                  <MySelect
                    fluid
                    multiple
                    search
                    required
                    onChange={this.inputChange('select', 'teacher_target')}
                    value={teacher_target}
                    options={teacherOptions}
                    noResultsMessage="找不到教師"
                    placeholder='負責老師'
                  />
                  {this.validator.message(`一位教師`, !!teacher_target.length, 'accepted')}
                </Form.Field>
              </Form>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
            <Button color='green' content='儲存' icon='save' onClick={this.save} circular />
          </Modal.Actions>
        </Modal>)}
      </>
    );
  }
}

export default withRouter(Dashboard);