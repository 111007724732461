import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

const UnAuthRoute = ({ path, exact, Layout, children, wide }) => (
  <Route path={path} exact={exact}>
    <Container fluid={wide}>
      {children}
    </Container>
  </Route>
);

export default UnAuthRoute;