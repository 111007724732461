/* import lodash */
import {keyBy, flatten, find, pick, filter} from 'lodash';

/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	EmptyTableMsg,
	FullscreenDimmer,
  FlexibleTextField,
  MySelect
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions, 
  threeYearPlanAndWhichYearFromYear,
  defaultYear,
  whetherReadOnlyForMidReport
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment } from 'semantic-ui-react';

class SchoolMidTermReport extends Component {
  constructor(props) {
    super(props);

    this.action = null;
    this.concernLookup = []; //index->id
    this.strategyLookup = {}; //id->index
    this.actionLookup = {};

    this.state = {
      finishedLoading: false,
      dimmerOpen: false,
      readOnly: true,

      year: [],
      yearInfo: [],
      yearId: '',

      teacherLookup: {},

      threeYearPlans: [],
      selectedTYP: '',
      whichYear: 0,

      concerns: [],
      concernOptions: [],
      concernIndex: '',

      aims: [],

      strategies: [],
      selectedStrategy: null,

      actions: [],

      title: '',
      reflection: '',
      suggestion: '',

      isEditModalOpen: false,
      selectedId: null,
    }

    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        required: '請輸入:attribute',
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    try{
      this.mounted = true;

      await this.fetch();
      this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log("Error when mounting Group Actions");
      console.log(err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try {
      const [yearInfo, TYPs] = await Promise.all([
        get('getYear'),
        get('getTYP')
      ]);
      
      const yearId = defaultYear(yearInfo);
      const [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs, yearId);

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId,
        threeYearPlans: TYPs,
        selectedTYP,
        whichYear,
      });

      await this.setStateAsync({
        readOnly: whetherReadOnlyForMidReport(this.state.yearInfo, this.state.yearId),
      });
      
      await this.fetch3YearPlanDetails(true);      
    } catch (err) {
      console.log('Error when fetching 1 year strategies');
      console.log(err);
    }
  }

  fetch3YearPlanDetails = async (resetConcern = false) => {
    try{
      const concerns = await get(`get3YearConcern/${+this.state.selectedTYP}`);

      this.concernLookup = concerns.map(x=>x.id);

      await this.setStateAsync({
        concerns,
        concernIndex: resetConcern ? (concerns && concerns.length? 0 : '') : this.state.concernIndex,
        concernOptions: selectOptions(concerns, (c,i)=>{
          const wYear = +(this.state.whichYear);
          const subTopic = c["subTopic"+(1+wYear)];
          return `${i+1}. `+(subTopic?`${c.title} (${subTopic})`:c.title)
        }, (c,i)=>i),
      });

      const aims = await get('get3YearAim/'+this.concernLookup[this.state.concernIndex]);
      await this.setStateAsync({
        aims
      });

      const rawStrategies = await Promise.all(aims.map(aim => get(`get3YearStrategy/${+aim.id}`)));

      this.strategyLookup = {};
      rawStrategies.forEach((strategies, i)=>{
        Object.assign(this.strategyLookup, strategies.reduce((prev, s, j)=>({
          ...prev,
          [s.id]: j,
        }), {}));
      })

      const strategies = flatten(rawStrategies);

      const wYear = this.state.whichYear;

      await this.setStateAsync({
        strategies: Number.isInteger(wYear) ? strategies.filter(s => +s[`year${1+wYear}`]) : [],
      });

      const rawActions = await (await Promise.all(this.state.strategies.map(s => get(`get1YearAction/${this.state.yearId}/${+s.id}`)))).map((x,i)=>x&&x.map(y=>({...y, title: this.state.strategies[i].title,})));
      const actions = flatten(rawActions);

      this.actionLookup = keyBy(actions, "3yearstrategyId");

      await this.setStateAsync({
        actions,
      });
    }catch(err){
      console.log('Error when fetching TYP detail for 1 year strategies');
      console.log(err);
    }
  };

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value });
  }

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId } = this.state;
      if (yearId !== value) {
        await this.setStateAsync({
          yearId: value,
          finishedLoading: false,
        });
      
        await this.setStateAsync({
          readOnly: whetherReadOnlyForMidReport(this.state.yearInfo, this.state.yearId)
        });

        await this.fetch3YearPlanDetails();
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      console.log("Error when changing year for Group Action");
      console.log(err);
    }
  }

  /* Concern Change */
  concernChange = async (event, {value}) => {
    try {
      const { concernIndex } = this.state;
      if (concernIndex !== value) {
        await this.setStateAsync({
          concernIndex: value,
          finishedLoading: false,
        });

        await this.fetch3YearPlanDetails();
        await this.setStateAsync({ finishedLoading: true });
      }
    } catch (err) {
      console.log("Error when changing corcern for One Year Action");
      console.log(err);
    }
  }

  /* modal toggle */
  modalToggle = (modalStateName, selectedId = null) => () => {

    const data = {
      [modalStateName]: !this.state[modalStateName],
      selectedId
    };

    if (selectedId) {
      const ref = find(this.state.actions, {id: selectedId});
      Object.assign(data, pick(ref, ['title', 'reflection', 'suggestion']));
    } else {
      Object.assign(data, {
        title: '',
        reflection: '',
        suggestion: '',
      });
    }
    this.setStateAsync(data);
  }

  /* save record */
  save = async () => {
    if(!this.validator.allValid()){
      this.validator.showMessages();
      return;
    }
    try{
      await this.setStateAsync({
        dimmerOpen: true,
        finishedLoading: false,
      });
      const data = pick(this.state, ['reflection', 'suggestion']);
      data.skipGroups = true;

      if(this.state.selectedId){
        data.id = this.state.selectedId;
      }else{
        alert("未提供ID");
        return;
      }

      const result = await post('edit1YearAction', data);
      if(result && result.status){
        this.modalToggle('isEditModalOpen')();
        await this.fetch();
      }else{
        throw result;
      }

    }catch(err){
      if(err!=='unmounted'){
        alert("儲存周年中期報告時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true,
    })
  }

  render() {
    const {
      finishedLoading,
      dimmerOpen,

      year,
      yearId,
      readOnly,

      concernOptions,
      concernIndex,

      aims,
      strategies,

      isEditModalOpen,

      title,
      suggestion,
      reflection
    } = this.state;

    return (<>
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='周年中期報告 - 成就、反思及建議' subTitle='為每個周年策略項目設定成就、反思及建議以供中期報告使用' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Group>
                  <Form.Field inline>
                    <label>周年</label>
                    <MySelect compact disabled={!finishedLoading} options={year} value={yearId} onChange={this.yearChange} />
                  </Form.Field>
                  <Form.Field inline>
                    <label>關注事項</label>
                    <MySelect
                      compact
                      disabled={!finishedLoading || !concernOptions.length}
                      options={concernOptions}
                      value={concernIndex}
                      onChange={this.concernChange}
                      placeholder={concernOptions.length?"請選擇":"沒有三年計劃關注事項"}
                      error={!concernOptions.length}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Table textAlign='center' celled unstackable fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={5}>標題</Table.HeaderCell>
                  <Table.HeaderCell width={2}>時間表</Table.HeaderCell>
                  <Table.HeaderCell width={3}>成就與反思</Table.HeaderCell>
                  <Table.HeaderCell width={3}>建議</Table.HeaderCell>
                  <Table.HeaderCell width={2}>行動</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finishedLoading ?
                  <>{aims.length === 0 ?
                    <EmptyTableMsg colSpan='5' msg='沒有策略目標' />
                    : (aims.map((aim, i) => {

                      const aimStrategies = filter(strategies, {aimId: aim.id})

                      return (
                        <Fragment key={aim.id}>
                          <EmptyTableMsg colSpan='5' msg={`${+concernIndex+1}.${+i+1}. ${aim.title}`} />
                          {aimStrategies.length ? aimStrategies.map((s, j) => {
                            const action = this.actionLookup[s.id];
                            if(action){
                              return (
                                <Table.Row key={s.id}>
                                  <Table.Cell textAlign="left">{+concernIndex+1}.{+i+1}.{+this.strategyLookup[s.id]+1}. {s.title}</Table.Cell>
                                  <Table.Cell>{action.beginMonth||'?'}月—{action.endMonth||'?'}月</Table.Cell>
                                  <Table.Cell textAlign="left">
                                    <FlexibleTextField
                                      value={action.reflection}
                                      multiline={true}
                                      readOnly={true}
                                      options={{ul: true}}
                                    />
                                  </Table.Cell>
                                  <Table.Cell textAlign="left">
                                    <FlexibleTextField
                                      value={action.suggestion}
                                      multiline={true}
                                      readOnly={true}
                                      options={{ul: true}}
                                    />
                                  </Table.Cell>
                                  <Table.Cell key='actions'>
                                  {readOnly?(
                                    <Button
                                      color='blue'
                                      icon='eye'
                                      onClick={this.modalToggle('isEditModalOpen', action.id)}
                                      circular
                                    />
                                  ):(
                                    <Button
                                      color='blue'
                                      icon='edit'
                                      onClick={this.modalToggle('isEditModalOpen', action.id)}
                                      circular
                                    />
                                  )}
                                </Table.Cell>
                                </Table.Row>
                              )
                            }else{
                              return (
                                <Table.Row key={s.id}>
                                  <Table.Cell textAlign="left">{+concernIndex+1}.{+i+1}.{+this.strategyLookup[s.id]+1}. {s.title}</Table.Cell>
                                  <Table.Cell colSpan='4'><strong>未制定周年策略項目</strong></Table.Cell>
                                </Table.Row>
                              )
                            }
                          }): <EmptyTableMsg colSpan='5' msg="未有策略大綱" />}
                        </Fragment>
                      )
                    }))
                  }</>
                  : <EmptyTableMsg colSpan='5' msg='載入資料中'/>
                }
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {dimmerOpen ? <FullscreenDimmer active={true} isLoading={true}/>:(
        <Modal open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
          <Modal.Header>{title} - 成就、反思及建議</Modal.Header>
          <Modal.Content>
            <Segment basic>
              <Form>
                <Form.Group className='form-group-margin' widths={2}>
                  <Form.Field>
                    <label>成就與反思</label>
                    <FlexibleTextField
                      multiline={true}
                      readOnly={readOnly}
                      value={reflection}
                      options={{
                        rows:5,
                        disabled:!finishedLoading,
                        onChange:this.inputChange('text', 'reflection')
                      }}
                    />
                    {this.validator.message('成就及反思', reflection, 'required')}
                  </Form.Field>
                  <Form.Field>
                    <label>建議</label>
                    <FlexibleTextField
                      multiline={true}
                      readOnly={readOnly}
                      value={suggestion}
                      options={{
                        rows:5,
                        disabled:!finishedLoading,
                        onChange:this.inputChange('text', 'suggestion')
                      }}
                    />
                    {this.validator.message('建議', suggestion, 'required')}
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
            <Button color='green' content='儲存' icon='save' disabled={readOnly} onClick={this.save} circular />
          </Modal.Actions>
        </Modal>
      )}
    </>)
  }
}

export default withRouter(SchoolMidTermReport);