/* import lodash */
import {pick, find} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  ErrorLabel,
  ConfirmModal,
  FullscreenDimmer,
  MySelect,
  MyTable,
  Buttons,
} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';

import {levelOptions} from '../../Const/Const';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Segment, Form } from 'semantic-ui-react';

class Location extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: false,
      locations: null,
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      selectedId: null,

      name: '',
      level: null,
    }

    this.tableColumnData = [
      {headerName: '地點名稱', width: 5, cellRender: 'name'},
      {headerName: '地點樓層', width: 5, cellRender: 'level'},
      {headerName: '行動', width: 6, cellClassName: 'textlessCell', cellRender: item=>(
        <>
          <Buttons.EditButton data-itemid={item.id} data-modalname='isEditModalOpen' onClick={this.modalToggle} />
          <Buttons.DeleteButton data-itemid={item.id} data-modalname='isDeleteModalOpen' onClick={this.modalToggle} />
        </>
      )},
    ];

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => <ErrorLabel message={message} />,
      messages: {
        default: '請輸入資料'
      }
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    //get location
    try{
      const locations = await get('getLocation');
      this.setStateAsync({ locations });
    }catch(err){if(err!=='unmounted')console.log("Error when getting location", err);}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetch();
    this.setStateAsync({ dimmerOpen: false, finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  /* input update handler */
  inputChange = (event, data) => {
    const {inputtype, statename} = event.target.closest('.ui, textarea').dataset;
    this.setStateAsync({
      [statename]: inputHandler(inputtype, data)
    })
  }

  getDataset = (event) => {
    if(event.hasOwnProperty('myDataSet')){
      return event.myDataSet;
    };
    if(event.target.classList.contains('modals')){
      return event.target.firstElementChild.dataset;
    }
    return event.target.closest('button, textarea, .ui').dataset;
  }

  /* modal toggle */
  modalToggle = (event) => {
    let modalname, itemid;
    const dataset = this.getDataset(event);
    modalname = dataset.modalname;
    itemid = dataset.itemid;
    
    let editObject = {
      name: '',
      level: null,
    };

    if (itemid) {
      editObject = pick(find(this.state.locations, { id: +itemid }), ['name', 'level']);
    }

    this.setStateAsync({
      ...editObject,
      selectedId: itemid,
      [modalname]: !this.state[modalname]
    });
  }

  /* edit */
  save = async (event) => {
    const myDataSet = this.getDataset(event);
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }

    let data = {
      name: this.state.name,
      level: this.state.level,
      id: this.state.selectedId
    };
    try{
      this.setStateAsync({ dimmerOpen: true, finishedLoading: false });
      const result = await post('editLocation', data);
      if(result && result.status){
        await this.fetch();
        this.modalToggle({myDataSet});
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存地點時發生錯誤");
        console.log("Error when saving location", err);
      }
    }
    this.setStateAsync({dimmerOpen: false, finishedLoading: true});
  }

  /* delete */
  delete = async (event) => {
    const myDataSet = this.getDataset(event);
    let data = { id: this.state.selectedId };
    try{
      this.setStateAsync({ dimmerOpen: true, finishedLoading: false });
      const result = await post('deleteLocation', data);
      if(result && result.status){
        await this.fetch();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存地點時發生錯誤");
        console.log("Error when deleting location", err);
      }
    }
    this.modalToggle({myDataSet});
    this.setStateAsync({dimmerOpen: false, finishedLoading: true});
  }

  render() {
    const {
      dimmerOpen,
      finishedLoading,
      locations,
      isEditModalOpen,
      isDeleteModalOpen,
      name, level
    } = this.state;

    this.validator.purgeFields();

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='地點管理' subTitle='設定可供活動之地點' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Buttons.AddButton content='新增地點' floated='right' data-modalname="isEditModalOpen" onClick={this.modalToggle} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MyTable
                tableColumnData={this.tableColumnData}
                data={locations}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen ? <FullscreenDimmer active={dimmerOpen} isLoading={true} /> : (
          <>
            <Modal
              open={isEditModalOpen}
              data-modalname="isEditModalOpen"
              onClose={this.modalToggle}
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <Modal.Header>編輯地點</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    <Form.Group grouped>
                      <Form.Input
                        value={name}
                        onChange={this.inputChange}
                        data-inputtype='text'
                        data-statename='name'
                        label='地點名稱'
                        placeholder='地點名稱'
                        disabled={!finishedLoading}
                      />
                      {this.validator.message('地點名稱', name, 'required')}
                      <Form.Field
                        control={MySelect}
                        onChange={this.inputChange}
                        options={levelOptions}
                        data-inputtype='select'
                        data-statename='level'
                        label='樓層'
                        value={level}
                        placeholder='請選擇樓層'
                        disabled={!finishedLoading}
                      />
                      {this.validator.message('樓層', level, 'required')}
                    </Form.Group>
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Buttons.CancelButton
                  data-modalname='isEditModalOpen'
                  onClick={this.modalToggle}
                />
                <Buttons.SaveButton
                  onClick={this.save}
                  data-modalname='isEditModalOpen'
                />
              </Modal.Actions>
            </Modal>

            <ConfirmModal
              open={isDeleteModalOpen}
              description={<>確定刪除<span className='red bold'>{name}</span>地點？</>}
              modalname='isDeleteModalOpen'
              cancel={this.modalToggle}
              confirm={this.delete}
            />
          </>
        )}
      </>
    )
  }
}

export default withRouter(Location);