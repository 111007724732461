import React, {useState, useCallback} from 'react';
import FullscreenDimmer from '../../Components/FullscreenDimmer';
import { post } from '../../Helper/ApiHelper';
import { Grid, Segment, Header, Icon, Message, Image } from 'semantic-ui-react';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import { get as _get } from 'lodash';

const Login = () => {
  const [dimmerOpen, setDimmerOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();

  const successHandler = useCallback((response)=>{
    const abortController = new AbortController();
    post('signIn', { id_token: response.tokenObj.id_token }, 'JSON', abortController).then(result => {
      if (_get(result, 'status')) {
        history.push('/panel');
      } else {
        setDisabled(false);
        setDimmerOpen(false);
        setErrorMsg(_get(result, 'error'));
      }
    }).catch(err=>{
      if(_get(err, 'name')!=='AbortError'){
        console.log(err);
        alert("登入時發生了意想不到的錯誤")
      }
    });
    return ()=>{
      abortController.abort();
    }
  }, []);

  return (
    <>
      <FullscreenDimmer active={dimmerOpen} isLoading={true} />
      <Grid className='full-height' verticalAlign='middle' centered>
        <Grid.Column mobile={14} computer={8}>
          <Image src={process.env.REACT_APP_DEMO?'/demo/logo.png':'/logo.png'} size='small' centered alt={process.env.REACT_APP_SCHOOL_NAME} />
          <Header textAlign='center'>
            <Header.Content>{process.env.REACT_APP_NAME}</Header.Content>
          </Header>
          <Segment textAlign='center' attached raised padded>
            <GoogleLogin
              disabled={disabled}
              className="google-login"
              clientId={process.env.REACT_APP_CLIENT_ID}
              buttonText='以已登記 Google 帳戶登入'
              prompt='select_account'
              onRequest={()=>{setDimmerOpen(true)}}
              onSuccess={successHandler}
              onFailure={(err) => {
                console.log("loginFailed", err);
                if(_get(err, 'error') ==='popup_closed_by_user'){
                  setDimmerOpen(false);
                  return;
                }
                if (Array.isArray(_get(err, 'details')) && err.details.indexOf('Cookies are not enabled') > -1) {
                  alert("目前瀏覽器環境不支援來自 Google 的第三方 Cookie，請更改瀏覽器設定");
                  setDimmerOpen(false);
                  setDisabled(true);
                  return;
                }
                setDimmerOpen(false);
                setErrorMsg('請再試一次');
              }}
              scope="email"
            />
          </Segment>
          {errorMsg &&
            <Message negative attached='bottom'><Icon name='warning' /> {errorMsg}</Message>
          }
          {process.env.REACT_APP_DEMO && <><Header as="h3" color="red">注意事項：</Header>
            <ul className="main-page-description">
              <li>這個示例系統會由不同學校或教學團體共用，故切勿將　貴校或　貴機構的任何敏感資料寫入本系統。</li>
              <li>本公司或會在未有通知用戶的情況下不定時重設資料庫或進行系統維護。</li>
            </ul></>}
        </Grid.Column>
      </Grid>
    </>
  )
}
export default Login;