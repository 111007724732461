/* import react */
import React, {useEffect} from 'react';
import { Prompt } from "react-router-dom";

const BlockerPrompt = ({isBlocking, message='您尚未儲存您的資料，真的要離開此頁嗎？'}) => {
  useEffect(()=>{
    if(isBlocking)
      window.onbeforeunload = () => true;
    else
      window.onbeforeunload = undefined;
    return ()=>{
      window.onbeforeunload = undefined;
    }
  }, [isBlocking]);
  
  return (
      <Prompt
        when={isBlocking}
        message={message}
      />
  )
}
export default BlockerPrompt;