/* import react */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import { Button, Grid, GridColumn, GridRow, Label, Menu, Segment, Select, Tab, Table } from 'semantic-ui-react';
import PageHeader from '../../../Components/PageHeader';
import { get } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../Helper/Helper';
import { buildTree } from '../../../Helper/TreeHelper';
import EmptyTableMsg from '../../../Components/EmptyTableMsg';
import { momentToDate } from '../../../Helper/TimeHelper';
import { filter, find, map, orderBy } from 'lodash';
import { fromBlankAsync } from 'xlsx-populate';
import { exportExcel } from '../../../Helper/ExcelHelper';

const Notification = (props) => {
	const [loading, setLoading] = useState(false);
	const [years, setYears] = useState([]);
	const [selectedYear, setSelectedYear] = useState(null);
	const [appraisalItems, setAppraisalItems] = useState([]);
	const [selectedAppraisalItemId, setSelectedAppraisalItemId] = useState(null);
	const [before, setBefore] = useState([]);
	const [self, setSelf] = useState([]);
	const [other, setOther] = useState([]);
	const [tabData, setTabData] = useState({});
	const [sort, setSort] = useState([null, null, null, null]);

	const headers = [
		{ displayName: '評估科目', columnName: 'subject' },
		{ displayName: '評估班別', columnName: 'className' },
		{ displayName: '評估老師', columnName: 'teacherName' },
		{ displayName: '遞交限期', columnName: 'deadline' },
	];

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const yearResult = await get('getYear');
			setYears(selectOptions(yearResult, 'displayName', 'id'));
			setSelectedYear(defaultYear(yearResult));
			setLoading(false);
		};
		fetch();
	}, []);

	const getRecordCount = (before, self, other, appraisalItemId) => {
		if (!!appraisalItemId) {
			const beforeCount = filter(before, { appraisalItemId }).length;
			const selfCount = filter(self, { appraisalItemId }).length;
			const otherCount = filter(other, { appraisalItemId }).length;
			return beforeCount + selfCount + otherCount;
		}
		return 0;
	};

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const before = await get(`getAllOutstandingFormTargetBefore/${selectedYear}`);
			const self = await get(`getAllOutstandingFormTargetSelf/${selectedYear}`);
			const other = await get(`getAllOutstandingFormTargetOther/${selectedYear}`);

			const appraisalItems = await get(`getAllAppraisalItem/${selectedYear}`);
			const appraisalItemTree = buildTree(appraisalItems);
			const options = [];
			appraisalItemTree.map(({ id, displayName, tableFormat, children }) => {
				if (!children) {
					if (!tableFormat) {
						const count = getRecordCount(before, self, other, id);
						return options.push({ id, displayName: `${displayName} (${count})` });
					}
					return;
				}
				return children.map(({ id, displayName, tableFormat }) => {
					if (!tableFormat) {
						const count = getRecordCount(before, self, other, id);
						return options.push({ id, displayName: `${displayName} (${count})` });
					}
					return;
				});
			});
			setAppraisalItems(selectOptions(options, 'displayName', 'id'));
			setSelectedAppraisalItemId(options.length > 0 ? options[0].id : null);
			setBefore(before);
			setSelf(self);
			setOther(other);
			setLoading(false);
		};
		if (!!selectedYear) fetch();
	}, [selectedYear]);

	useEffect(() => {
		if (!!selectedAppraisalItemId) {
			setLoading(true);
			const beforeRecord = filter(before, { appraisalItemId: selectedAppraisalItemId });
			const selfRecord = filter(self, { appraisalItemId: selectedAppraisalItemId });
			const otherRecord = filter(other, { appraisalItemId: selectedAppraisalItemId });
			setTabData({
				before: { label: '被評估前設定', length: beforeRecord.length, data: beforeRecord },
				self: { label: '被評估後反思', length: selfRecord.length, data: selfRecord },
				other: { label: '他人評估', length: otherRecord.length, data: otherRecord },
			});
			setLoading(false);
		}
	}, [selectedAppraisalItemId, before, self, other]);

	const sorting = (index = null) => {
		let direction = sort[index];
		if (index >= 0) {
			if (direction === null) direction = 'ascending';
			else if (direction === 'ascending') direction = 'descending';
			else direction = null;
			setSort((sort) => [...sort.slice(0, index), direction, ...sort.slice(index + 1)]);
		}
	};

	useEffect(() => {
		let columns = [];
		let orders = [];

		sort.map((direction, index) => {
			if (!!direction) {
				columns.push(headers[index].columnName);
				orders.push(direction === 'ascending' ? 'asc' : 'desc');
			}
		});

		if (columns.length > 0) {
			setBefore((before) => orderBy(before, columns, orders));
			setSelf((self) => orderBy(self, columns, orders));
			setOther((other) => orderBy(other, columns, orders));
		}
	}, [sort]);

	const getExportData = (appraisalItemId) => {
		const displayData = [];

		const beforeRecord = filter(before, { appraisalItemId });
		map(beforeRecord, ({ subject, className, teacherName, deadline }) => {
			displayData.push(['被評估前設定', subject, className, teacherName, momentToDate(deadline)]);
		});

		const selfRecord = filter(self, { appraisalItemId });
		map(selfRecord, ({ subject, className, teacherName, deadline }) => {
			displayData.push(['被評估後反思', subject, className, teacherName, momentToDate(deadline)]);
		});

		const otherRecord = filter(other, { appraisalItemId });
		map(otherRecord, ({ subject, className, teacherName, deadline }) => {
			displayData.push(['他人評估', subject, className, teacherName, momentToDate(deadline)]);
		});

		return displayData;
	};

	const download = async () => {
		const year = find(years, { value: selectedYear });
		const filename = `${year.text} - 未完成評估列表`;
		const workbook = await fromBlankAsync();

		appraisalItems.map(({ value }, index) => {
			const appraisalItem = find(appraisalItems, { value });
			const sheet = workbook.addSheet(appraisalItem.text.replace(/\/|\*|\[|\]|\\|\:|\?/g, ''), index);
			const displayData = getExportData(value);
			sheet.cell('A1').value([['種類', ...map(headers, 'displayName')], ...displayData]);
			sheet.range('A1:E1').style('bold', true);
		});
		workbook.deleteSheet('Sheet1');

		const file = await workbook.outputAsync();
		exportExcel(file, filename);
	};

	const displayMenu = (key) => {
		const length = tabData[key]?.length || 0;
		return (
			<Menu.Item key={key}>
				{tabData[key]?.label}
				{length > 0 && <Label color="orange">{length}</Label>}
			</Menu.Item>
		);
	};

	const displayContent = (key) => {
		const values = tabData[key]?.data || [];
		return (
			<Tab.Pane>
				<Segment padded basic>
					<Table textAlign="center" columns={5} celled selectable sortable>
						<Table.Header>
							<Table.Row>
								{headers.map(({ displayName }, index) => {
									return (
										<Table.HeaderCell key={index} collapsing sorted={sort[index]} onClick={() => sorting(index)}>
											{displayName}
										</Table.HeaderCell>
									);
								})}
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{values.length > 0 ? (
								<React.Fragment>
									{values.map((value, index) => {
										return (
											<Table.Row key={index}>
												{headers.map(({ columnName }) => {
													let displayValue = value[columnName];
													if (columnName === 'deadline') displayValue = momentToDate(displayValue);
													return <Table.Cell key={columnName}>{displayValue}</Table.Cell>;
												})}
											</Table.Row>
										);
									})}
								</React.Fragment>
							) : (
								<EmptyTableMsg colSpan={headers.length} />
							)}
						</Table.Body>
					</Table>
				</Segment>
			</Tab.Pane>
		);
	};

	const panes = [
		{ menuItem: displayMenu('before'), render: () => displayContent('before') },
		{ menuItem: displayMenu('self'), render: () => displayContent('self') },
		{ menuItem: displayMenu('other'), render: () => displayContent('other') },
	];

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid stackable>
				<GridRow>
					<GridColumn>
						<PageHeader title="未完成評估列表" subTitle="列出所有未完成之報告" />
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn style={{ display: 'flex', alignItems: 'center' }}>
						<Select onChange={(event, { value }) => setSelectedYear(value)} options={years} value={selectedYear} />
						<Select
							style={{ marginLeft: 5 }}
							onChange={(event, { value }) => setSelectedAppraisalItemId(value)}
							options={appraisalItems}
							value={selectedAppraisalItemId}
						/>
					</GridColumn>
				</GridRow>
				<Grid.Row>
					<GridColumn>
						<Button color="green" content="匯出" icon="download" circular onClick={download} />
					</GridColumn>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Segment padded="very" className="segmentOverflow" secondary>
							<Tab panes={panes} />
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</React.Fragment>
	);
};

export default Notification;
