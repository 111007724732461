import { forEach, filter, find, map, uniqBy, findIndex } from 'lodash';
import { momentToDate } from '../../../../Helper/TimeHelper';
import { roundTwoDp } from '../../../../Helper/Helper';

export const TeachingReport = (printData, callback) => {
	try {
		const { yearInfo, appraisalItemTree, appraisalContentTree, appraisalNonApplicable, teacherData } = printData;
		let content = '';

		let teachingItem = find(appraisalItemTree, { displayName: '學與教工作' });
		if (!teachingItem) throw new Error();

		let lessonItem = find(teachingItem.children, { displayName: '觀課' });
		if (!lessonItem) throw new Error();
		let lessonContent = filter(appraisalContentTree, { appraisalItemId: lessonItem.id });

		let lessonWithoutMarkItem = find(appraisalItemTree, { displayName: '科主/同儕觀課' });
		if (!lessonWithoutMarkItem) throw new Error();
		let lessonWithoutMarkContent = filter(appraisalContentTree, { appraisalItemId: lessonWithoutMarkItem.id });

		let homeworkItem = find(teachingItem.children, { displayName: '查簿' });
		if (!homeworkItem) throw new Error();
		let homeworkContent = filter(appraisalContentTree, { appraisalItemId: homeworkItem.id });

		let homeworkWithoutMarkItem = find(appraisalItemTree, { displayName: '下學期查簿' });
		if (!homeworkWithoutMarkItem) throw new Error();
		let homeworkWithoutMarkContent = filter(appraisalContentTree, { appraisalItemId: homeworkWithoutMarkItem.id });

		forEach(teacherData, (data) => {
			const { teacherInfo, personalTarget, personalFormRecord, personalWriting } = data;
			const lessonTargets = filter(personalTarget, { appraisalItemId: lessonItem.id, otherStatus: 'complete' });

			const lessonWithoutMarkTargets = filter(personalTarget, {
				appraisalItemId: lessonWithoutMarkItem.id,
				otherStatus: 'complete',
			});

			const homeworkTargets = filter(personalTarget, { appraisalItemId: homeworkItem.id, otherStatus: 'complete' });
			const homeworkWithoutMarkTargets = filter(personalTarget, {
				appraisalItemId: homeworkWithoutMarkItem.id,
				otherStatus: 'complete',
			});

			/* report header */
			if (yearInfo) {
				content += `
		            <div class="header break-page">
		                <div class="title">馬鞍山聖若瑟小學</div>
		                <div class="subtitle">教師學與教報告</div>
		                <div class="subtitle">${yearInfo.displayName}年度</div>
		            </div>
		        `;
			}

			/* year and teacher info */
			if (yearInfo && teacherInfo) {
				content += `
		            <div class="teacher-info">
		                <div>年分: ${yearInfo.displayName}</div>
		                <div>老師名稱: ${teacherInfo.name}</div>
		                <div>職位: ${teacherInfo.displayName}</div>
		            </div>
		        `;
			}

			if (lessonTargets.length === 0 && homeworkTargets.length === 0 && lessonWithoutMarkTargets.length === 0) {
				content += `
			            <div class="section" style="font-size: 12px">
			                <div class="text-bold text-center">沒有觀課或查簿記錄</div>
			            </div>
			        `;
			}

			if (lessonTargets.length > 0) {
				content += displayLesson(
					'校長觀課',
					lessonContent,
					lessonTargets,
					personalFormRecord,
					appraisalNonApplicable,
					personalWriting
				);
			}

			if (lessonWithoutMarkTargets.length > 0) {
				content += displayLesson(
					'科主/同儕觀課',
					lessonWithoutMarkContent,
					lessonWithoutMarkTargets,
					personalFormRecord,
					appraisalNonApplicable,
					personalWriting
				);
			}

			if (homeworkTargets.length > 0) {
				content += displayLesson('查簿', homeworkContent, homeworkTargets, personalFormRecord);
			}

			if (homeworkWithoutMarkTargets.length > 0) {
				content += displayLesson(
					'下學期查簿',
					homeworkWithoutMarkContent,
					homeworkWithoutMarkTargets,
					personalFormRecord
				);
			}
		});

		/* print */
		let printWindow = window.open('', '_blank', '');
		printWindow.document.open();
		printWindow.document.write(`
            <html>
                <head>
                    <title>馬鞍山聖若瑟小學年度教師學與教報告</title>
                    <link rel="stylesheet" type="text/css" href="/css/appraisalReportLayout.css">
                </head>
                <body>
                    <div class="appraisal-report">
                        ${content}
                    </div>
                </body>
            </html>
        `);
		printWindow.document.close();
		printWindow.focus();
		printWindow.onload = () => {
			printWindow.print();
			callback();
		};
	} catch (err) {
		console.log(err);
		alert('發生錯誤，請再嘗試');
		callback();
	}
};

const displayLesson = (displayName, contentTree, targets, formRecords, nonApplicable, writings = null) => {
	const distinctTargets = uniqBy(map(targets, 'appraisalTargetId'));
	const beforeContentTree = filter(contentTree, { section: 'before' });
	const selfContentTree = filter(contentTree, { section: 'self' });
	const otherContentTree = filter(contentTree, { section: 'other' });
	let content = '';

	forEach(distinctTargets, (id, index) => {
		const markingTeachers = map(filter(targets, { appraisalTargetId: id }), 'teacherId');
		const markingTeacherPercentages = map(filter(targets, { appraisalTargetId: id }), ({ teacherId, percentage }) => ({
			teacherId,
			percentage,
		}));
		const targetFormRecords = filter(formRecords, { appraisalTargetId: id });
		const targetWritings = writings ? filter(writings, { appraisalTargetId: id }) : [];
		const targetNonApplicable = filter(nonApplicable, { appraisalTargetId: id });

		if (beforeContentTree.length > 0) {
			content += `
	            <div class="section" style="font-size: 12px">
	                <div class="text-bold">${displayName}${index + 1}</div>
	                <table class="table" style="font-size: 12px" border="1">
	                    <thead>
	                        <tr class="text-bold">
	                            <td colspan="4">評估前設定</td>
	                        </tr>
	                    </thead>
	                    ${displayBefore(beforeContentTree, targetFormRecords)}
	                </table>
	            </div>
	        `;
		}

		if (selfContentTree.length > 0) {
			content += `
			        <div class="section" style="font-size: 12px">
			            <table class="table" style="font-size: 12px" border="1">
			                <thead>
			                    <tr class="text-bold">
			                        <td colspan="2">被評估後反思</td>
			                    </tr>
			                </thead>
			                ${displayRecord(selfContentTree, targetFormRecords)}
			            </table>
			        </div>
			    `;
		}

		if (otherContentTree.length > 0) {
			content += `
			        <div class="section" style="font-size: 12px">
			            <table class="table" style="font-size: 12px" border="1">
			                <thead>
			                    <tr class="text-bold">
			                        <td colspan="4">他人評估</td>
			                    </tr>
			                </thead>
			                ${displayOtherRecord(
												otherContentTree,
												markingTeacherPercentages,
												targetFormRecords,
												targetNonApplicable
											)}
			                ${targetWritings.length > 0 ? displayOtherComment(markingTeachers, targetWritings) : ''}
			            </table>
			        </div>
			    `;
		}
	});

	return content;
};

const displayBefore = (contentTree, formRecords) => {
	let content = '';
	let temp = '';
	let count = 0;

	forEach(contentTree, ({ id, description, inputType }, index) => {
		const record = find(formRecords, { appraisalContentId: id });
		let response = '';
		if (record) {
			response = record.text;
			if (inputType === 'date') response = momentToDate(record.date);
			if (inputType === 'score') response = record.score;
		}

		temp += `
			<td style="padding-left: 10px" width="20%">${description}</td>
			<td width="30%">${response}</td>
        `;

		count += 1;

		if (count === 2 || contentTree.length === index) {
			content += `<tr style="vertical-align: top;">${temp}</tr>`;
			temp = '';
			count = 0;
		}
	});
	return content;
};

const displayRecord = (contentTree, formRecords, level = 1) => {
	let content = '';
	forEach(contentTree, ({ id, description, inputType, children }) => {
		const record = find(formRecords, { appraisalContentId: id });
		let response = '';
		if (record) {
			response = record.text;
			if (inputType === 'date') response = momentToDate(record.date);
			if (inputType === 'score') response = record.score;
		}

		content += `
            <tr style="vertical-align: top;">
                <td style="padding-left: ${10 * level}px" width="35%">${description}</td>
                <td width="65%">${response}</td>
            </tr>
        `;

		if (children) {
			content += displayRecord(children, formRecords, level + 1);
		}
	});
	return content;
};

const displayOtherRecord = (contentTree, markingTeacherPercentages, formRecords, nonApplicable = [], level = 1) => {
	let content = '';
	let temp = '';
	let count = 0;
	forEach(contentTree, ({ id, description, inputType, needInput, children }, index) => {
		let responseContent = '';
		const nonApplicableRecord = findIndex(nonApplicable, { appraisalContentId: id });

		if (nonApplicableRecord < 0) {
			if (needInput) {
				if (inputType === 'score') {
					let total = 0;
					let totalPercentage = 0;

					forEach(markingTeacherPercentages, ({ teacherId, percentage }) => {
						const record = find(formRecords, { appraisalContentId: id, teacherId });
						if (record && record.score !== null) {
							total += record.score * percentage;
							totalPercentage += percentage;
						}
					});
					responseContent = totalPercentage > 0 ? roundTwoDp(total / totalPercentage) : '不適用';
				} else {
					let response = '';
					forEach(markingTeacherPercentages, ({ teacherId }) => {
						const record = find(formRecords, { appraisalContentId: id, teacherId });
						if (record) {
							response += `<li style="margin-bottom: 5px;">${
								inputType === 'date' ? momentToDate(record.date) : record.text
							}</li>`;
						}
					});
					responseContent = `<ul style="margin: 0; padding-left: 20px;">${response}</ul>`;
				}
			}

			const newLine = contentTree.length === index || children || !needInput || inputType !== 'score';

			temp += `<td colspan="${newLine && !responseContent ? 4 : 1}" style="padding-left: ${10 * level}px" "${
				newLine ? 100 : 35
			}%">${description}</td>`;

			if (responseContent) {
				temp += `<td colspan="${newLine ? 3 : 1}" width="${newLine ? 65 : 15}%">${responseContent}</td>`;
			}

			count += 1;
		}

		if (count === 2 || contentTree.length === index || !!children || !needInput || inputType !== 'score') {
			content += `<tr style="vertical-align: top;">${temp}</tr>`;
			temp = '';
			count = 0;
		}

		if (nonApplicableRecord < 0) {
			if (children) {
				content += displayOtherRecord(children, markingTeacherPercentages, formRecords, nonApplicable, level + 1);
			}
		}
	});

	if (!!temp) {
		content += `<tr style="vertical-align: top;">${temp}</tr>`;
		temp = '';
		count = 0;
	}
	return content;
};

const displayOtherComment = (markingTeachers, targetWritings) => {
	let content = ``;

	forEach(markingTeachers, (teacherId) => {
		const writing = find(targetWritings, { teacherId });
		content += `<li style="margin-bottom: 5px;">${writing?.comment ? writing.comment : ''}</li>`;
	});

	return `
	    <tr>
	        <td style="padding-left: 10px" width="35%">評語</td>
	        <td colspan="3">
	        	<ul style="margin: 0; padding-left: 20px;">
					${content}
				</ul>
			</td>
	    </tr>
	`;
};

const displayWritingComment = (markingTeachers, targetWritings) => {
	let content = '';

	forEach(markingTeachers, (teacherId) => {
		const writing = find(targetWritings, { teacherId });
		if (writing.dataUrl) {
			content += `<img width="50%" src="${writing.dataUrl}" />`;
		}
	});

	return `
        <div class="section">
            <div class="text-bold">手寫評語</div>
            ${content}            
        </div>
    `;
};
