import React, { forwardRef, Fragment, useImperativeHandle, useState } from 'react';
import { Checkbox, Divider, Form, Grid, Header, Message, Modal, Segment } from 'semantic-ui-react';
import { textareaText } from '../../../../Helper/Helper';

const sectionOptions = {
	before: '評估前設定',
	self: '被評估後反思',
	other: '他人評估',
};

const FormDisplayModal = forwardRef(({ fetch }, ref) => {
	const [open, setOpen] = useState(false);
	const [details, setDetails] = useState({});
	const [textSelectOptions, setTextSelectOptions] = useState([]);

	const {
		section,
		description,
		notes,
		needInput,
		inputType,
		multiple,
		min,
		max,
		step,
		notApplicable,
		count,
		avgCount,
		percentage,
		allowSetApplicable,
	} = details || {};

	useImperativeHandle(ref, () => ({
		open: (details, textSelectOptions) => {
			setDetails(details);
			setTextSelectOptions(textSelectOptions);
			setOpen(true);
		},
	}));

	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<Modal.Header>項目詳情: {sectionOptions[section]}</Modal.Header>
			<Modal.Content>
				<Segment basic>
					<Form>
						<Header color="blue" as="h2" content="基本資料" />

						<Form.Group className="form-group-margin" grouped>
							<label>描述</label>
							<Message>{textareaText(description)}</Message>
						</Form.Group>

						<Form.Group className="form-group-margin" grouped>
							<label>備註</label>
							<Message>{textareaText(notes)}</Message>
						</Form.Group>

						<Divider />

						<Header color="blue" as="h2" content="需要輸入" />

						{Boolean(needInput) ? (
							<Fragment>
								<Form.Group className="form-group-margin" grouped>
									<label>輸入類別</label>
									<Message>
										{inputType === 'textSelect' && <span>文字選擇 ({Boolean(multiple) ? '可多選' : '不可多選'})</span>}
										{inputType === 'date' && '日期'}
										{inputType === 'score' && '分數'}
										{inputType === 'subject' && '科目'}
										{inputType === 'text' && '文字'}
									</Message>
								</Form.Group>

								{inputType === 'textSelect' && (
									<Fragment>
										<Form.Group className="form-group-margin" grouped>
											<label>選項</label>
										</Form.Group>
										{textSelectOptions.length > 0 && (
											<Grid>
												<Grid.Row verticalAlign="bottom">
													{textSelectOptions.map(({ displayName }, index) => {
														return (
															<Grid.Column key={index} computer={8} mobile={16}>
																<Form.Group className="form-group-margin" grouped>
																	<Message>{displayName}</Message>
																</Form.Group>
															</Grid.Column>
														);
													})}
												</Grid.Row>
											</Grid>
										)}
									</Fragment>
								)}

								{inputType === 'score' && (
									<Fragment>
										<Grid columns={3}>
											<Grid.Row>
												<Grid.Column>
													<Form.Group className="form-group-margin" grouped>
														<label>最小</label>
														<Message>{min}</Message>
													</Form.Group>
												</Grid.Column>
												<Grid.Column>
													<Form.Group className="form-group-margin" grouped>
														<label>最大</label>
														<Message>{max}</Message>
													</Form.Group>
												</Grid.Column>
												<Grid.Column>
													<Form.Group className="form-group-margin" grouped>
														<label>每級相隔</label>
														<Message>{step}</Message>
													</Form.Group>
												</Grid.Column>
											</Grid.Row>
										</Grid>
										<Form.Group className="form-group-margin">
											<Form.Field checked={Boolean(notApplicable)} control={Checkbox} disabled />
											<label>可以填寫「不適用」</label>
										</Form.Group>
									</Fragment>
								)}
							</Fragment>
						) : (
							<Message>不需輸入</Message>
						)}

						<Divider />

						<Header color="blue" as="h2" content="需計算比例" />
						{Boolean(count) ? (
							<Fragment>
								<Form.Group className="form-group-margin" grouped>
									<label>佔同層比例(%)</label>
									<Message>{avgCount ? '平圴計算' : `${percentage}%`}</Message>
								</Form.Group>
							</Fragment>
						) : (
							<Message>不計算比例</Message>
						)}

						<Divider />

						<Header color="blue" as="h2" content="設定前選擇是否適用" />
						<Form.Group className="form-group-margin" grouped>
							<Message>{Boolean(allowSetApplicable) ? '可以' : '不可以'}</Message>
						</Form.Group>
					</Form>
				</Segment>
			</Modal.Content>
		</Modal>
	);
});

export default FormDisplayModal;
