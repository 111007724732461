/* import react */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  MySelect,
  MySortableTable,
  ConfirmModal,
  FullscreenDimmer,
  Buttons
} from '../../../Components';

import { get, post } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions, genGradeString } from '../../../Helper/Helper';
import { exportExcel, genSimpleExcel } from '../../../Helper/ExcelHelper';

import { getFridayActivityPlanInfo, genLocationString } from './FridayHelpers';
import genReport from '../../../Layout/ECA/fridayActivityPlan';
import genNormalReport from '../../../Layout/ECA/normalGroupReport';

import { keyBy, pick, find, sortBy, get as _get, mapValues, groupBy } from 'lodash';

import { Grid, Label, Table, Checkbox, Form } from 'semantic-ui-react';

import { fromBlankAsync } from 'xlsx-populate';

class FridayActivitySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,
      dimmerOpen: false,
      loaderText: '',
      yearOptions: [],
      yearId: '',
      fridayActivities: [],
      isDeleteModalOpen: false,
      selectedId: null,
    }

    this.tableColumnData = [
      {
        headerName: '',
        HeaderRender: ({ width }) => (<Table.HeaderCell width={width} onClick={this.selectAllChange}>
          <Checkbox checked={this.state.allChecked} />
        </Table.HeaderCell>),
        cellRender: x => (
          <Checkbox checked={!!x.checked} value={x.id} onChange={this.selectChange} />
        ),
        width: 1,
        columnName: 'cb',
        allowSort: false
      },
      { headerName: '類別', cellRender: x => x.type === 'special' ? '專項' : '普通', width: 1, columnName: 'type', allowSort: true },
      { headerName: '編號', cellRender: 'code', width: 1, columnName: 'code', allowSort: true },
      { headerName: '名稱', cellRender: 'name', width: 2, columnName: 'name', allowSort: true },
      { headerName: '負責教師', cellRender: x=>x.teachers.map(y=><Label content={y.name||'外聘導師'} key={y.teacherId}/>), width: 2, columnName: 'teacher'},
      {
        headerName: '詳情',
        cellRender: x => (<>
          <Link to={'/eca/fridayActivity/summary/detail/' + x.type + '/' + x.yearId + '/' + x.id}><Buttons.ViewButton/></Link>
          <Buttons.DeleteButton data-id={x.id} data-name={x.name} onClick={this.toggleModal} />
        </>),
        width: 1,
        columnName: 'detail',
        allowSort: false
      },
    ];
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchYear();
    await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  setStateAsync = (state, errMsg = 'error') => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    }).catch(err => { console.log(errMsg); console.log(err); })
  )

  fetchYear = async () => {
    try {
      const result = await get('getYear');
      let yearId = defaultYear(result);
      let yearOptions = selectOptions(result, 'displayName', 'id');
      await this.setStateAsync({ yearOptions, yearId });
      return true;
    } catch (err) { if (err !== 'unmounted') console.log("Error when fetching year for FASummary", err); }
    return false;
  }

  fetchData = async () => {
    try {
      const { yearId } = this.state;
      const [ fridayActivities, fridayActivityTeachers, teacherDuty, specialActivityStudents] = await Promise.all(['getFridayActivity/', 'getAllFridayActivityTeacher/', 'getTeacherYearDuty/', 'getAllSpecialFridayActivityStudent/'].map(x => get(x + yearId)));

      const groupedTeachers = groupBy(fridayActivityTeachers, 'fridayActivityId');
      const lookup = new Map(teacherDuty.map(x=>[x.teacherId, pick(x, ['index', 'duty', 'className'])]));
      for(let a of fridayActivities){
        a.teachers = (groupedTeachers[a.id] || []).map(x=>({...x, ...(lookup.get(x.teacherId)||{})}))
      }
      const specialActivityStudentCounts = mapValues(groupBy(specialActivityStudents, 'fridayActivityId'), x=>x.length);

      await this.setStateAsync({
        fridayActivities,
        specialActivityStudentCounts
      });
    } catch (err) { if (err !== 'unmounted') console.log("Error when fetching data for FASummary", err); }
  }

  yearChange = async (event, { value }) => {
    try {
      await this.setStateAsync({ finishedLoading: false, yearId: value });
      await this.fetchData();
    } catch (err) { if (err !== 'unmounted') console.log("Error when changing year for FASummary", err); }
    this.setStateAsync({ finishedLoading: true });
  }


  downloadFridayActivityList = async () => {
    const { fridayActivities, yearId } = this.state;
    const fridayActivityTeachers = [].concat(...fridayActivities.map(x=>x.teachers));

    const year = find(this.state.yearOptions, { value: yearId });
    const fridayActivityLookup = keyBy(fridayActivities, 'id');

    genSimpleExcel(sortBy(fridayActivityTeachers, 'index'), `週五興趣小組總表 - ${year ? year.text : ''}`, [
      { width: 13, headerTitle: '教師編號', render: 'index' },
      { width: 13, headerTitle: '班別/職務', render: x => x.className || x.duty || '-' },
      { width: 22, headerTitle: '教師姓名', render: x => x.name || '外聘導師' },
      { width: 16, headerTitle: '類別', render: x => fridayActivityLookup[x.fridayActivityId] && fridayActivityLookup[x.fridayActivityId].type === 'special' ? '專項' : '普通' },
      { width: 13, headerTitle: '項目編號', render: x => fridayActivityLookup[x.fridayActivityId] && fridayActivityLookup[x.fridayActivityId].code },
      { width: 16, headerTitle: '小組名稱', render: x => fridayActivityLookup[x.fridayActivityId] && fridayActivityLookup[x.fridayActivityId].name },
      { width: 14, headerTitle: '上課地點', render: x => genLocationString(fridayActivityLookup[x.fridayActivityId]) },
      { width: 14, headerTitle: '適合年級', render: x => genGradeString(fridayActivityLookup[x.fridayActivityId]) },
      { width: 14, headerTitle: '備註欄', render: () => '' },
    ]);
  }

  genTitleDate = () => {
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
    const [{ value: m }, , { value: d }, , { value: y }] = dateTimeFormat.formatToParts(new Date());
    return `${y}-${m}-${d}`;
  }


  downloadSpecificFridayActivityList = async (event) => {
    const { requestType } = event.target.closest('button').dataset;
    const year = find(this.state.yearOptions, { value: this.state.yearId });
    const { fridayActivities, specialActivityStudentCounts } = this.state;

    let filename = year.text + (requestType === 'special' ? '週五專項小組總表' : '週五普通小組總表');
    let data = [];
    let endCol = 'G';
    let sum = 0;

    if (requestType === 'special') {
      data.push(['教師姓名', '項目編號', '小組名稱', '上課地點', '適合年級', '學生人數', '備註欄']);
      fridayActivities.forEach(x => {
        if (x.type === requestType) {
          sum += +specialActivityStudentCounts[x.id] || 0;
          const teachers = x.teachers.map(x => x.name || '外聘導師');
          data.push([
            teachers.join(', '),
            x.code,
            x.name,
            genLocationString(x),
            genGradeString(x),
            specialActivityStudentCounts[x.id],
            ''
          ]);
        }
        return;
      });
    } else {
      endCol = 'E';
      data.push(['教師姓名', '項目編號', '小組名稱', '適合年級', '備註欄']);
      fridayActivities.forEach(x => {
        if (x.type === requestType) {
          const teachers = x.teachers.filter(y=>y.fridayActivityId === x.id ).map(x => x.name || '外聘導師');
          data.push([
            teachers.join(', '),
            x.code,
            x.name,
            genGradeString(x),
            ''
          ]);
        }
        return;
      });
    }

    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);
    sheet.cell('A4').value(data);
    sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true } });
    sheet.row(4).style({ bold: true, underline: true });

    sheet.range(`A1:${endCol}1`).merged(true).startCell().value(process.env.REACT_APP_SCHOOL_NAME);
    sheet.range(`A2:${endCol}2`).merged(true).startCell().value(`${year ? year.text + '年度' : ''}周五${requestType === 'special' ? '週五專項小組總表' : '週五普通小組總表'} (${this.genTitleDate()}更新版)`);

    sheet.range(`A1:${endCol}2`).style({ fontSize: 16 });

    if (endCol === 'G') {
      sheet.usedRange().endCell().relativeCell(1, -1).value(sum).style({ border: { left: true, right: true, top: true, bottom: true } });
    }

    sheet.usedRange().style({ horizontalAlignment: 'center', verticalAlignment: 'center', fontFamily: '標楷體', wrapText: true });

    (endCol === 'G' ? [36, 8, 24, 24, 8, 8, 36] : [36, 8, 24, 8, 36]).forEach((width, i) => {
      sheet.column(String.fromCharCode([i + 65])).width(width);
    });

    const file = await workbook.outputAsync();
    exportExcel(file, filename);
  }


  downloadCover = async () => {
    const year = find(this.state.yearOptions, { value: this.state.yearId });
    const { fridayActivities } = this.state;
    const fridayActivityTeachers = [].concat(...fridayActivities.map(x=>x.teachers));

    let filename = year.text + '專項小組挑選學生封面頁';
    let data = fridayActivityTeachers.reduce((prev, { fridayActivityId, index, name, shortName, className, duty }) => {
      const fridayActivity = find(fridayActivities, { id: fridayActivityId });
      if (!fridayActivity || fridayActivity.type !== 'special') return prev;
      return prev.concat([[
        index,
        className || duty,
        name || '外聘導師',
        shortName,
        fridayActivity.name,
        '專項',
        fridayActivity.code,
        genLocationString(fridayActivity),
        genGradeString(fridayActivity),
        ''
      ]]);
    }, [['教師編號', '班別/職務', '教師姓名', '教師代號', '負責興趣小組項目', '項目類別', '項目編號', '專項小組上課地點', '適合年級', '完成挑選後簽署']]);

    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);
    sheet.cell('A7').value(data);
    sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true }, fontSize: 12 });
    sheet.range('A7:J7').style({
      bold: true, underline: true, wrapText: true, verticalAlignment: 'center'
    });
    sheet.range(`F${7}:G${6 + data.length}`).style('fill', 'F2F2F2');

    sheet.range('A1:J1').merged(true).startCell().value(process.env.REACT_APP_SCHOOL_NAME);
    sheet.range('A2:J2').merged(true).startCell().value(`${year.text}年度專項小組挑選學生封面頁`);
    sheet.range('A1:A2').style({ bold: true, fontSize: 16 });
    sheet.range('A4:J5').merged(true).value('請各專項小組負責老師於學生姓名旁邊的方格內寫上自己組別的代號，如果發現該學生已有老師填寫代號，請與該組老師協商選人，選人程序於_____________前完成。').style({ wrapText: true, bold: true, underline: true, fontSize: 14 });
    sheet.row(4).height(21); sheet.row(5).height(21);

    [6.2, 8.5, 10, 6.2, 19, 6.2, 6.2, 24, 12, 18].forEach((width, i) => {
      sheet.column(String.fromCharCode([i + 65])).width(width);
    })

    sheet.usedRange().style({ fontFamily: "標楷體", horizontalAlignment: 'center' });
    sheet.range('A4:J5').style({ horizontalAlignment: 'left' })

    const file = await workbook.outputAsync();
    exportExcel(file, filename);
  }


  /* select all fridayActivities checkbox change */
  selectAllChange = (event, { checked }) => {
    let { allChecked, fridayActivities } = this.state;
    this.setStateAsync({
      allChecked: !allChecked,
      fridayActivities: fridayActivities.map((activity) => ({ ...activity, checked }))
    })
  }

  /* select checkbox change */
  selectChange = (event, { value, checked }) => {

    const acts = this.state.fridayActivities.map(x => x.id === value ? { ...x, checked } : x);
    let allChecked = acts.every(x => x.checked);

    this.setStateAsync({
      fridayActivities: acts,
      allChecked
    })
  }

  batchDownloadPlan = async () => {
    try {
      const plansToExport = this.state.fridayActivities.filter(x => x.checked).map(x => x.id);
      if (!plansToExport.length) {
        alert('請選擇要匯出的項目');
        return;
      }

      await this.setStateAsync({
        finishedLoading: false,
        loaderText: '從資料庫中獲取資料中'
      });

      const planData = [];
      await plansToExport.map(id => getFridayActivityPlanInfo(id)).reduce((seq, cur) => {
        return seq.then(() => cur).then(([activityInfo, activityTeachers]) => {
          if (activityInfo.length > 0) {
            planData.push({ ...activityInfo[0], teachers: activityTeachers });
          }
        })
      }, Promise.resolve());

      this.setStateAsync({ loaderText: '將資料轉換成PDF中' });
      await new Promise((res, rej) => { genReport(planData, res) });
      await this.setStateAsync({ finishedLoading: true, });
    } catch (err) {
      await this.setStateAsync({ loaderText: typeof err === 'string' ? err : '發生錯誤' });
      setTimeout(
        () => { this.setStateAsync({ finishedLoading: true, }) }
        , 2000);
    }
  }

  batchDownloadNormalReport = async () => {
    let result;
    try {
      result = await get('getAllFridayActivityNormalGroupWithDetail/' + this.state.yearId);
      genNormalReport(result);
    } catch (err) {
      console.log(err);
      alert("無法取得資料");
    }
  }

  deleteActivity = async () => {
    try {
      const result = await post('deleteFridayActivity', { id: this.state.selectedId });
      if (!result.status) {
        alert('刪除時發生錯誤');
      }else{
        await this.fetchData();
      }
      this.setState({
        selectedId: null,
        loaderText: '',
        isDeleteModalOpen: false,
      })
    } catch (err) {
      if (err !== 'unmounted') console.log('Error when deleting Friday Activity', err);
    }
  }

  toggleModal = (e) => {
    const {id, name} = e.target.closest('.ui').dataset;
    if(id){
      this.setState({
        selectedId: id,
        loaderText: name,
        isDeleteModalOpen: true,
      })
    }else{
      this.setState({
        selectedId: null,
        loaderText: '',
        isDeleteModalOpen: false,
      })
    }
  }

  render() {
    const {
      finishedLoading,
      yearOptions,
      yearId,
      fridayActivities,
      allChecked,
      dimmerOpen,
      isDeleteModalOpen,
      loaderText,
    } = this.state;

    return (
      <>
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='週五興趣小組列表' subTitle='管理每年度的週五興趣小組' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form>
              <Link to={'/eca/fridayActivity/summary/edit/' + this.state.yearId}>
                <Buttons.AddButton
                  disabled={!finishedLoading}
                  className='button-margin-bottom'
                  content='新增週五興趣小組'
                  floated="right"
                />
              </Link>
              <Form.Group inline className='form-group-no-margin'>
                <Form.Field>
                  <MySelect
                    onChange={this.yearChange}
                    disabled={!finishedLoading}
                    options={yearOptions}
                    value={yearId}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Buttons.DownloadButton
              className='button-margin-bottom'
              content='週五興趣小組總表 (以教師編號排序)'
              disabled={!finishedLoading}
              onClick={this.downloadFridayActivityList}
            />
            <Buttons.DownloadButton
              className='button-margin-bottom'
              content='專項小組總表'
              data-request-type='special'
              disabled={!finishedLoading}
              onClick={this.downloadSpecificFridayActivityList}
            />
            <Buttons.DownloadButton
              className='button-margin-bottom'
              content='普通小組總表'
              data-request-type='normal'
              disabled={!finishedLoading}
              onClick={this.downloadSpecificFridayActivityList}
            />
            <Buttons.DownloadButton
              className='button-margin-bottom'
              content='專項小組封面頁'
              disabled={!finishedLoading}
              onClick={this.downloadCover}
              circular
            />
            <Buttons.DownloadButton
              className='button-margin-bottom'
              content='批次匯出興趣小組計劃表'
              disabled={!finishedLoading}
              onClick={this.batchDownloadPlan}
            />
            <Buttons.DownloadButton
              className='button-margin-bottom'
              content='匯出全部普通小組總學習紀錄表'
              disabled={!finishedLoading}
              onClick={this.batchDownloadNormalReport}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
              <MySortableTable
                tableColumnData={this.tableColumnData}
                data={fridayActivities}
                finishedLoading={finishedLoading}
                allChecked={allChecked}
                compact={true}
              />
          </Grid.Column>
        </Grid.Row>
      </Grid>
        <FullscreenDimmer active={dimmerOpen} content={loaderText} isLoading={true}/>
        <ConfirmModal open={isDeleteModalOpen} description={<>確定刪除聯課活動 <span className="bold red">{loaderText}</span>？<span className="bold">注意：這會一併刪除課堂時間等資料</span></>} cancel={this.toggleModal} confirm={this.deleteActivity} />
      </>
    )
  }
}

export default withRouter(FridayActivitySummary);