/* import lodash */
import {find} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  ErrorLabel,
  ConfirmModal,
  FullscreenDimmer,
  EmptyTableMsg,
  DateInput,
} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import { momentToDate, momentToDbFormat, dateToMoment, isValid } from '../../Helper/TimeHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Button, Table, Segment, Form, Input } from 'semantic-ui-react';

class Year extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimmerOpen: false,
  
      year: [],
  
      isEditModalOpen: false,
      isDeleteModalOpen: false,
  
      id: null,
      displayName: '',
      startDate: '',
      endDate: '',
    }
    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      messages: {
        required: '請輸入資料',
        accepted: '請輸入資料',
        integer: "開始日期、中期、結束日期要順序",
      },
      autoForceUpdate: this
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try{
      const year = await get('getYear');
      await this.setStateAsync({ year });
    }catch(err){
      console.log("Error when fetching year");
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetch();
    try{   
      await this.setStateAsync({ dimmerOpen: false });
    }catch(err){
      console.log("Error when closing dimmer");
      console.log(err);
    }
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value })
  }

  /* modal toggle */
  modalToggle = (modalStateName, id = null) => async () => {
    let editObject;
    this.validator.hideMessages();

    if (id) {
      const { year } = this.state;
      editObject = find(year, { id });
      ['startDate','midDate','endDate'].forEach(x=>{
        editObject[x] = dateToMoment(editObject[x]);
      })
    }else{
      editObject = {
        displayName: '',
        startDate: '',
        midDate: '',
        endDate: '',
      };
    }
    try{
      await this.setStateAsync({
        [modalStateName]: !this.state[modalStateName],
        id,
        ...editObject,
      });
    }catch(err){}
  }

  /* edit year */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }

    const { displayName, startDate, endDate, midDate, id } = this.state;
    let data = {
      id,
      displayName,
      startDate: momentToDbFormat(startDate),
      endDate: momentToDbFormat(endDate),
      midDate: momentToDbFormat(midDate)
    }
    try{
      await this.setStateAsync({ dimmerOpen: true });
      const result = await post('editYear', data);
      if(result && result.status){
        await this.fetch();
        await this.modalToggle('isEditModalOpen')();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存年份時發生錯誤");
        console.log("Error when saving year", err);
      }
    }
    this.setStateAsync({
      dimmerOpen: false
    });
  }

  delete = async () => {
    const { id } = this.state;
    let data = { id };
    try{
      await this.setStateAsync({ dimmerOpen: true });
      const result = await post('deleteYear', data);
      if(result && result.status){
        await this.fetch();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("刪除年份時發生錯誤");
        console.log("Error when deleting year", err);
      }
    }
    this.modalToggle('isDeleteModalOpen')();
    this.setStateAsync({
      dimmerOpen: false
    });
  }

  render() {
    const { dimmerOpen, year, displayName, startDate, midDate, endDate, isEditModalOpen, isDeleteModalOpen } = this.state;

    const now = new Date();

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='年度管理' subTitle='設定年度資料' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button color='green' content='新增年度' icon='add' floated='right' onClick={this.modalToggle('isEditModalOpen')} circular />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Table textAlign='center' selectable celled unstackable fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>名稱</Table.HeaderCell>
                    <Table.HeaderCell width={3}>開始日期</Table.HeaderCell>
                    <Table.HeaderCell width={3}>中期日期</Table.HeaderCell>
                    <Table.HeaderCell width={3}>結束日期</Table.HeaderCell>
                    <Table.HeaderCell width={4}>行動</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {year.length > 0 ?
                    <>
                      {year.map(x => (
                        <Table.Row key={x.id}>
                          <Table.Cell>{x.displayName}</Table.Cell>
                          <Table.Cell>{momentToDate(x.startDate)}</Table.Cell>
                          <Table.Cell>{momentToDate(x.midDate)}</Table.Cell>
                          <Table.Cell>{momentToDate(x.endDate)}</Table.Cell>
                          <Table.Cell>
                            <Button color='blue' disabled={dateToMoment(x.endDate) < now} icon='edit' onClick={this.modalToggle('isEditModalOpen', x.id)} circular />
                            <Button color='red' disabled={dateToMoment(x.endDate) < now} icon='delete' onClick={this.modalToggle('isDeleteModalOpen', x.id)} circular />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </>
                    :
                    <EmptyTableMsg colSpan='5' />
                  }
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen ? <FullscreenDimmer active={dimmerOpen} isLoading={true} /> : (<>
          <Modal open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>編輯年度</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <Form>
                  <Form.Group className='form-group-margin' grouped>
                    <Form.Field inline required>
                      <label>名稱</label>
                      <Input value={displayName} onChange={this.inputChange('text', 'displayName')} placeholder='名稱' />
                    </Form.Field>
                    {this.validator.message('displayName', displayName, 'required')}
                  </Form.Group>
                  <Form.Group className='form-group-margin' widths={3}>
                    <Form.Field required>
                      <label>開始日期</label>
                      <DateInput
                        popupPosition='bottom center'
                        value={momentToDate(startDate)}
                        onChange={this.inputChange('date', 'startDate')}
                        placeholder='開始日期'
                      />
                      {this.validator.message('startDate', isValid(startDate), 'accepted')}
                    </Form.Field>
                    <Form.Field required>
                      <label>中期日期</label>
                      <DateInput
                        popupPosition='bottom center'
                        value={momentToDate(midDate)}
                        onChange={this.inputChange('date', 'midDate')}
                        placeholder='中期日期'
                      />
                      {this.validator.message('midDate', isValid(midDate), 'accepted')}
                    </Form.Field>
                    <Form.Field required>
                      <label>結束日期</label>
                      <DateInput
                        popupPosition='bottom center'
                        value={momentToDate(endDate)}
                        onChange={this.inputChange('date', 'endDate')}
                        placeholder='結束日期'
                      />
                      {this.validator.message('endDate', isValid(endDate), 'accepted')}
                    </Form.Field>
                  </Form.Group>
                  {this.validator.message('中期日期', (!startDate || !midDate || !endDate || (midDate.isAfter(startDate) && midDate.isBefore(endDate)))?1:1.5,"integer")}
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
              <Button color='green' content='儲存' icon='save' onClick={this.save} circular />
            </Modal.Actions>
          </Modal>

          <ConfirmModal
            open={isDeleteModalOpen}
            description={<>確定刪除 <span className='red'>{displayName}</span> 年度？<span className='red bold'>注意：全個年度的評核、計劃、課外活動、學生等資料將一併被刪除，此操作<span className='underline'>無法還原</span></span></>}
            cancel={this.modalToggle('isDeleteModalOpen')}
            confirm={this.delete}
          />
        </>)}
      </>
    )
  }
}

export default withRouter(Year);