/* import react */
import React, { useMemo, useState, useEffect, useCallback, useReducer } from 'react';

/* import components */
import {
  PageHeader,
  MyTable,
  MySelect,
  Buttons,
  BlockerPrompt,
  FullscreenDimmer
} from '../../Components';

/* import helper functions */
import { selectOptions, defaultYear, whetherReadOnlyForTeacher, parseJSONArray } from '../../Helper/Helper';
import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import {genSimpleExcel} from '../../Helper/ExcelHelper';

import { stdTests, subjects, characters, nonAcademics, } from './helper';
import {gradeOptions} from '../../Const/Const';

/* import semantic-ui element */
import { Grid, Segment, Form, Modal, Icon } from 'semantic-ui-react';

import {get as _get, find} from 'lodash';

const yearsReducer = (state, data) => {
  const d = Array.isArray(data) ? data : [];
  return {
    array: d,
    options: selectOptions(d, 'displayName', 'id')
  }
}

const talentReducer = (state, talent) => {
  return {
    map: new Map(
      [].concat(
        stdTests.map((x,i)=>[x.fieldName, `1.${i+1} ${x.displayName}`]),
        subjects.map((x,i)=>[x.fieldName, `2.1.${i+1} ${x.displayName}`]),
        characters.map((x,i)=>[x.fieldName, `3.1.${i+1} ${x.displayName}`]),
        nonAcademics.map((x,i)=>[x, `3.2.${i+1} ${x}`]),
        talent.map((x,i)=>[x.id, `3.3.${i+1} ${x.name}`])
      )
    ),
    other_types: talent
  }
}

const ActivityTalent = (props) => {  
  const [isBlocking, setBlocking] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [dimmerOpen, setDimmer] = useState(false);
  const [years, setYears] = useReducer(yearsReducer, {array: [], options: []});
  const [readOnly, setReadOnly] = useState(true);
  const [yearId, setYear] = useState('');
  const [data, setData] = useState([]);
  const [talentTypes, setTypes] = useReducer(talentReducer, {other_types: [], map: new Map()});
  const [reloadCount, setReload] = useState(0);
  const [modalOpen, toggleModal] = useState({edit: false, id: null});
  const [shortName, setShortName] = useState('');
  const [editData, setEditData] = useState(new Set());

  useEffect(()=>{
    const abortController = new AbortController();
    const fetchData = async () => {
      try{
        const years = await get('getYear', [], abortController);
        setYears(years);
        if(!yearId){
          const talentTypes = await get('getTalentTypes', [], abortController);
          setTypes(talentTypes);
          setYear(defaultYear(years));
        }
      }catch(err){
        if(err.name!=='AbortError')
          console.log(err);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    }
  }, [reloadCount, yearId]);

  useEffect(()=>{
    const abortController = new AbortController();
    const fetchData = async () => {
      try{
        if(yearId){
          const activities = await get('getAllActivityInfoForTalent', [yearId], abortController);
          const talentStudents = await get('getTalentedStudents',[yearId],abortController);

          const talentStudentMap = new Map( talentStudents.map(x=>[x.studentId, `${x.classCode} ${x.chiName||x.engName}`]));

          setReadOnly(whetherReadOnlyForTeacher(years.array, yearId));
          const talentMap = [].concat(stdTests, subjects, characters).map(x=>x.fieldName).concat(nonAcademics, talentTypes.other_types.map(x=>x.id)).reduce((prev,cur)=>({...prev, [cur]: []}),{});
          for(let s of talentStudents){
            for(let f of stdTests){
              if(s[f.fieldName]>=f.threshold){
                talentMap[f.fieldName].push([s.grade, s.studentId])
              }
            }
            for(let f of subjects){
              if(s[f.fieldName]){
                talentMap[f.fieldName].push([s.grade, s.studentId])
              }
            }
            for(let f of characters){
              if(s[f.fieldName]>=4){
                talentMap[f.fieldName].push([s.grade, s.studentId])
              }
            }
            for(let f of nonAcademics){
              if((s.non_academic||'').indexOf(`\b${f}\b`)>=0){
                talentMap[f].push([s.grade, s.studentId])
              }
            }
            for(let f of talentTypes.other_types){
              if((s.other_gifted||'').indexOf(f.id)>=0){
                talentMap[f.id].push([s.grade, s.studentId])
              }
            }
          }

          for(let a of activities){
            try{
              a.potentialStudents = [];
              a.students = new Set(parseJSONArray(a.students));
            }catch(err){a.students = new Set();}

            if(a.talentFields){
              for(let f of [].concat(stdTests,subjects,characters)){
                if(a.talentFields.indexOf(`\b${f.fieldName}\b`)>=0){
                  a.potentialStudents = a.potentialStudents.concat(talentMap[f.fieldName]);
                }
              }
              for(let f of nonAcademics){
                if(a.talentFields.indexOf(`\b${f}\b`)>=0){
                  a.potentialStudents = a.potentialStudents.concat(talentMap[f]);
                }
              }
              for(let f of talentTypes.other_types){
                if(a.talentFields.indexOf(`\b${f.id}\b`)>=0){
                  a.potentialStudents = a.potentialStudents.concat(talentMap[f.id]);
                }
              }
            }
            a.potentialStudents = Array.from(
              new Set(
                a.potentialStudents.filter(x=>!a.students.has(x[1]) && a['grade_'+x[0]]).map(x=>x[1])
              )
            ).map(x=>talentStudentMap.get(x)||'-').join("、") || '-'
          }

          setData(activities);
          setLoading(false);
        }else{
          setReadOnly(true);
          setData([]);
        }
        setDimmer(false);
      }catch(err){
        if(err.name!=='AbortError')
          console.log(err);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    }
  }, [reloadCount, yearId, talentTypes]);

  useEffect(()=>{
    const _data = find(data, {id: modalOpen.id});
    const editData = _get(_data, 'talentFields') ? new Set(_data.talentFields.split("\b").filter(x=>x).map(x=>Number.isSafeInteger(+x)?+x:x)) : new Set();
    setShortName(_get(_data, 'shortName')||'');
    setEditData(editData);
  }, [modalOpen.id, data]);

  const tableInfo = useMemo(()=>{
    return [
      {headerName: '名稱', width: 4, cellRender: 'name'},
      {headerName: '簡稱', width: 2, cellRender: x=>x.shortName||'-'},

      // hard code because the grade data are hard coded in grade_1, grade_2 fields
      ...gradeOptions.map(x=>({grade: x.value, headerName: x.text, width: 1,cellRender: y=> !!y['grade_'+x.value] && <Icon name='check'/>, cellClassName: 'textlessCell'})),

      {headerName: '人才庫屬性', width: 4, cellRender: x=>{
        if(!x.talentFields) return '-';
        const talents = new Set(x.talentFields.split("\b").filter(x=>x).map(x=>Number.isSafeInteger(+x)?+x:x));
        return Array.from(talentTypes.map.entries()).filter(x=>talents.has(x[0])).map(x=>x[1]).join("、");
      }},
      {headerName: '年級範圍內的未參加人才', width: 5, cellRender: 'potentialStudents'},
      
    ].concat(readOnly?[]:[
      {headerName: '行動', width: 1, cellClassName: 'textlessCell', cellRender: x=>(
        <Buttons.EditButton
          onClick={()=>{
            toggleModal(y=>({...y, edit: true, id: x.id}))
          }}
        />
      ) }
    ]);
  }, [isLoading, readOnly, talentTypes.map]);
  
  const editInputChange = useCallback((event, data)=>{
    setBlocking(true);
    if(data['data-field-name']){
      setEditData(x=>{
        const x2 = new Set(x);
        const field = (x=>Number.isSafeInteger(+x)?+x:x)(data['data-field-name']);
        if(data.checked && !x2.has(field)){
          x2.add(field);
          return x2;
        }
        if(!data.checked && x2.has(field)){
          x2.delete(field);
          return x2;
        }
        return x;
      });
    }else{
      setShortName(data.value);
    }
  }, []);

  const save = useCallback(()=>{
    const abortController = new AbortController();
    const saveData = async () => {
      try{
        setDimmer(true);
        const result = await post('setActivityTalents', {
          id: modalOpen.id,
          shortName,
          talentFields: `\b${Array.from(editData).join("\b")}\b`,
        }, 'JSON', abortController);
        if(_get(result,'status')){
          setBlocking(false);
          setReload(c=>c+1);
          toggleModal(x=>({...x, edit: false, id: null}));
        }
        setDimmer(false);
        return () => {
          abortController.abort();
        }
      }catch(err){
        if(err.name!=='AbortError')
          console.log(err);
      }
    };
    saveData();
  }, [shortName, editData]);

  const downloadExcel = useCallback(() => {
    const yearName = _get(find(years.array, {id: yearId}), 'displayName', '??-??年度');
    genSimpleExcel(data, `${yearName}課後活動人才庫資料`, 
      tableInfo.slice(0,10).map(x=>({
        width: x.width*7,
        headerTitle: x.headerName,
        render: x.grade?(y=> y['grade_'+x.grade] ? '✓' : ''):x.cellRender, 
      }))
    );
  }, [data, tableInfo, years.array, yearId]);

  return (<>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <PageHeader title='課後活動人才庫資料' />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Segment padded>
            <Form>
              <Form.Group>
                <Form.Field inline disabled={isLoading}>
                  <label>周年</label>
                  <MySelect
                    options={years.options}
                    value={yearId}
                    onChange={(event, data)=>{
                      setLoading(true);
                      setYear(inputHandler('select', data))
                    }}
                  />
                </Form.Field>
                <Form.Field inline disabled={isLoading}>
                  <Buttons.DownloadButton
                    content='下載成 Excel (xlsx) 檔'
                    onClick={downloadExcel}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <MyTable
            finishedLoading={!isLoading}
            tableColumnData={tableInfo}
            data={data}
            footer
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    {dimmerOpen ? <FullscreenDimmer active={true} isLoading={true} /> : (<Modal
      size='large'
      open={modalOpen.edit}
      onClose={()=>{
        toggleModal(x=>({...x, edit: false, id: null}))
        setBlocking(false);
      }}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>課後活動人才庫資料</Modal.Header>
      <Modal.Content>
        <Segment basic>
          <Form>
            <Form.Group inline className='form-group-margin'>
              <Form.Input
                label='簡稱'
                value={shortName}
                onChange={editInputChange}
              />
            </Form.Group>
            <Form.Group inline className='talent_search_field'>
              <Form.Field>
                <label>1. 標準化測驗</label>
              </Form.Field>
              {stdTests.map(({fieldName, displayName}, i)=>(
                <Form.Checkbox
                  key={fieldName}
                  inline
                  type='checkbox'
                  data-field-name={fieldName}
                  checked={editData.has(fieldName)}
                  onChange={editInputChange}
                  label={`1.${i+1} ${displayName}`}
                />
              ))}
            </Form.Group>
            <Form.Group inline className='talent_search_field'>
              <Form.Field>
                <label>2. (上年) 校內成績表現</label>
              </Form.Field>
              {subjects.map(({fieldName, displayName}, i)=>(
                <Form.Checkbox
                  key={fieldName}
                  inline
                  type='checkbox'
                  data-field-name={fieldName}
                  checked={editData.has(fieldName)}
                  onChange={editInputChange}
                  label={`2.1.${i+1} ${displayName}`}
                />
              ))}
            </Form.Group>
            <Form.Group><Form.Field><label>3. 教師推薦</label></Form.Field></Form.Group>
            <Form.Group inline className='margin-left-1rem talent_search_field'>
              <Form.Field>
                <label>3.1 資優生行為特質問卷</label>
              </Form.Field>
              {characters.map(({fieldName, displayName},i)=>(
                <Form.Checkbox
                  key={fieldName}
                  inline
                  type='checkbox'
                  data-field-name={fieldName}
                  checked={editData.has(fieldName)}
                  onChange={editInputChange}
                  label={`3.1.${i+1} ${displayName}`}
                />
              ))}
            </Form.Group>
            <Form.Group inline className='margin-left-1rem talent_search_field'>
              <Form.Field>
                <label>3.2 非學科</label>
              </Form.Field>
              {nonAcademics.map((name,i)=>(
                <Form.Checkbox
                  key={name}
                  inline
                  type='checkbox'
                  data-field-name={name}
                  checked={editData.has(name)}
                  onChange={editInputChange}
                  label={`3.2.${i+1} ${name}`}
                />
              ))}
            </Form.Group>
            <Form.Group inline className='margin-left-1rem talent_search_field'>
              <Form.Field>
                <label>3.3 資優特質</label>
              </Form.Field>
              {talentTypes.other_types.map((type,i)=>(
                <Form.Checkbox
                  key={type.id}
                  name='other_gifted'
                  data-field-name={type.id}
                  checked={editData.has(type.id)}
                  onChange={editInputChange}
                  label={`3.3.${i+1} ${type.name}`}
                />
              ))}
            </Form.Group>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Buttons.CancelButton onClick={()=>{
          toggleModal(x=>({...x, edit: false, id: null}))
          setBlocking(false);
        }} />
        <Buttons.SaveButton disabled={readOnly} onClick={save} />
      </Modal.Actions>
    </Modal>)}
    <BlockerPrompt isBlocking={isBlocking}/>
  </>)
}

export default ActivityTalent;