import { forEach, filter, find, map, uniqBy } from 'lodash';
import { roundTwoDp } from '../../../../Helper/Helper';
import { momentToDate } from '../../../../Helper/TimeHelper';
const base = 100;

const printYearInfo = (yearInfo, reportName) => {
	if (yearInfo) {
		return `
			<div class="header break-page">
				<div class="title">馬鞍山聖若瑟小學</div>
				<div class="subtitle">${reportName}</div>
				<div class="subtitle">${yearInfo.displayName}年度</div>
			</div>
		`;
	}
	return '';
};

const printTeacherInfo = (yearInfo, teacherInfo) => {
	if (yearInfo && teacherInfo) {
		return `
			<div class="teacher-info">
				<div>年分: ${yearInfo.displayName}</div>
				<div>老師名稱: ${teacherInfo.name}</div>
				<div>職位: ${teacherInfo.displayName}</div>
			</div>
		`;
	}
	return '';
};

const printEmpty = (text) => {
	return `
		<div class="section">
			<div class="text-bold text-center">${text}</div>
		</div>
	`;
};

export const WorkingReport = (printData, callback) => {
	try {
		const { yearInfo, appraisalItemTree, appraisalContentTree, principalInfo, teacherData } = printData;
		let content = '';

		let workingItem = find(appraisalItemTree, { displayName: '行政職務' });
		if (!workingItem) throw new Error();
		let workingContent = filter(appraisalContentTree, { appraisalItemId: workingItem.id });

		let teachingItem = find(appraisalItemTree, { displayName: '學與教工作' });
		if (!teachingItem) throw new Error();

		let examItem = find(teachingItem.children, { displayName: '擬卷' });
		if (!examItem) throw new Error();
		let examContent = filter(appraisalContentTree, { appraisalItemId: examItem.id });

		forEach(teacherData, (data) => {
			const { teacherInfo, personalTarget, personalFormRecord, personalMarking } = data;
			const workingTargets = filter(personalTarget, { appraisalItemId: workingItem.id, otherStatus: 'complete' });

			/* Working */
			content += printYearInfo(yearInfo, '教師工作報告');
			content += printTeacherInfo(yearInfo, teacherInfo);

			if (workingTargets.length > 0) {
				const distinctWorkingTargets = uniqBy(map(workingTargets, 'appraisalTargetId'));

				forEach(distinctWorkingTargets, (appraisalTargetId) => {
					const targetWithRecord = filter(workingTargets, { appraisalTargetId });
					const countFormContent = filter(workingContent, { section: 'other', count: 1 });

					const workingTargetGroups = [];
					for (let index = 0; index < targetWithRecord.length; index += 5) {
						let workingTargetGroup = [];
						if (!!targetWithRecord[index]) workingTargetGroup.push(targetWithRecord[index]);
						if (!!targetWithRecord[index + 1]) workingTargetGroup.push(targetWithRecord[index + 1]);
						if (!!targetWithRecord[index + 2]) workingTargetGroup.push(targetWithRecord[index + 2]);
						if (!!targetWithRecord[index + 3]) workingTargetGroup.push(targetWithRecord[index + 3]);
						if (!!targetWithRecord[index + 4]) workingTargetGroup.push(targetWithRecord[index + 4]);
						if (workingTargetGroup.length > 0) workingTargetGroups.push(workingTargetGroup);
					}

					forEach(workingTargetGroups, (workingTargetGroup, index) => {
						const markingTeacherInfos = map(workingTargetGroup, ({ teacherId }) => {
							return find(personalMarking, { teacherId });
						});

						const workingFormRecords = map(workingTargetGroup, ({ appraisalTargetId, teacherId }) => {
							return filter(personalFormRecord, { appraisalTargetId, teacherId });
						});

						const workingFormCountRecords = map(workingTargetGroup, ({ appraisalTargetId, teacherId }) => {
							return filter(personalFormRecord, {
								appraisalItemId: workingItem.id,
								appraisalTargetId,
								teacherId,
							});
						});

						content += `
							<div class="section ${index !== 0 ? 'break-page' : ''}">
								<div class="text-bold">行政職務${index + 1}</div>
								<table class="table" border="1">
									<thead>
										<tr class="grey-background text-bold">
											<td width="30%">個人技能及專長評核</td>
											${displayTeacherName(markingTeacherInfos)}
										</tr>
									</thead>
									${displayItemRow(workingContent, workingFormRecords)}
									 <tr>
										<td width="30%" class="text-bold text-center">總分</td>
										${displayTotal(countFormContent, workingFormCountRecords)}
									</tr>
								</table>
							</div>
						`;
					});
				});

				content += `
					<div class="section break-page">
						<table class="table" border="1">
							<thead>
								<tr>
									<th class='center'>分數</th>
									<th class='center'>情況</th>
									<th class='center'>說明</th>
								</tr>
							</thead>
							<tr>
								<td>17-20</td>
								<td>經常超越學校要求</td>
								<td>獲得此評級的教師，工作表現屬於優異，值得予以表揚。</td>
							</tr>
							<tr>
								<td>13-16</td>
								<td>一般在要求以上</td>
								<td>獲得此評級的教師，大部份工作表現高於學校要求的水平。</td>
							</tr>
							<tr>
								<td>9-12</td>
								<td>間中在要求以上</td>
								<td>獲得此評級的教師，工作表現穩定而稱職。</td>
							</tr>
							<tr>
								<td>5-8</td>
								<td>僅可符合學校要求</td>
								<td>獲此評級之教師，工作態度及表現僅符合學校要求的水平。</td>
							</tr>
							<tr>
								<td>1-4</td>
								<td>經常不能達到要求</td>
								<td>教師獲給予此評級，表示許多時候未能達到學校要求的水平。因此，該教師有需要在這方面多作改善。</td>
							</tr>
						</table>
						<div>註:</div>
						<div>1. 凡獲評1-4分及17-20分的，評核者均需在備註欄內舉事例加以說明。</div>
						<div>2. 教師所獲最後分數佔整體校本評核20%，總分除五。</div>
					</div>
				`;
			} else {
				content += printEmpty('沒有工作報告記錄');
			}

			/* Exam Report */
			content += printYearInfo(yearInfo, '教師擬卷報告');
			content += printTeacherInfo(yearInfo, teacherInfo);

			const examTargets = filter(personalTarget, { appraisalItemId: examItem.id, otherStatus: 'complete' });

			if (examTargets.length > 0) {
				let responseColumn = '';
				forEach(examTargets, () => {
					responseColumn += '<td></td>';
				});
				content += `
                    <div class="section">
                        <div class="text-bold">學與教工作: 擬卷</div>
                        <table class="table" border="1">
                            <thead>
                                <tr>
                                    <th class='text-center' width='30%'>擬卷評核</th>
                                    ${responseColumn}
                                </tr>
                            </thead>
                            ${displayItem(examContent, examTargets, personalFormRecord)}
                        </table>
                    </div>
                `;
			} else {
				content += printEmpty('沒有擬卷記錄');
			}
		});

		/* print */
		let printWindow = window.open('', '_blank', '');
		printWindow.document.open();
		printWindow.document.write(`
            <html>
                <head>
                    <title>馬鞍山聖若瑟小學教師工作報告</title>
                    <link rel="stylesheet" type="text/css" href="/css/appraisalReportLayout.css">
                </head>
                <body>
                    <div class="appraisal-report">
                        ${content}
                    </div>
                </body>
            </html>
        `);
		printWindow.document.close();
		printWindow.focus();
		printWindow.onload = () => {
			printWindow.print();
			callback();
		};
	} catch (err) {
		alert('發生錯誤，請再嘗試');
		callback();
	}
};

const formMark = (countFormContent, records) => {
	let totalMark = null;
	let totalPercentage = null;
	let countMark = false;
	forEach(countFormContent, ({ id, max, percentage, children }) => {
		let mark = null;
		if (children) {
			let returnMark = formMark(filter(children, { count: 1 }), records);
			if (returnMark !== null) mark = returnMark;
		} else {
			let markRecord = find(records, { appraisalContentId: id });
			if (markRecord && markRecord.score) mark = (markRecord.score / max) * base;
		}

		if (mark !== null) {
			totalMark += mark * percentage;
			totalPercentage += percentage;
			countMark = true;
		}
	});

	return countMark ? totalMark / totalPercentage : null;
};

const displayTeacherName = (teachers) => {
	let content = '';
	const width = 70 / teachers.length;

	map(teachers, ({ teacherName }) => {
		content += `<td width="${width}%">${teacherName}</td>`;
	});
	return content;
};

const displayItemRow = (contentTree, formRecords, level = 1, parentDescription = null) => {
	let content = '';
	const width = 70 / formRecords.length;

	forEach(contentTree, ({ id, needInput, inputType, description, children }) => {
		if (Boolean(needInput)) {
			let responseDisplay = '';
			map(formRecords, (formRecord) => {
				const record = find(formRecord, { appraisalContentId: id });
				let response = '';
				if (record) {
					response = record.text;
					if (inputType === 'date') response = momentToDate(record.date);
					if (inputType === 'score') response = record.score;
				}
				return (responseDisplay += `<td width="${width}%">${response}</td>`);
			});

			let padding = 10 * level;
			let label = description;
			if (description === '分數') {
				padding = 10 * (level - 1);
				label = parentDescription;
			}

			content += `
				<tr style="vertical-align: top;">
					<td style="padding-left: ${padding}px" width="20%">
						${label}
					</td>
					${responseDisplay}
				</tr>
			`;
		}
		if (children) {
			content += displayItemRow(children, formRecords, level + 1, description);
		}
	});
	return content;
};

const displayTotal = (countFormContent, formRecords) => {
	let content = '';
	const width = 70 / formRecords.length;

	map(formRecords, (formRecords) => {
		const mark = formMark(countFormContent, formRecords);
		content += `<td width="${width}%" class="text-bold text-center">${roundTwoDp(mark)}</td>`;
	});

	return content;
};

const displayItem = (contentTree, targets, formRecords, level = 1) => {
	let content = '';
	forEach(contentTree, ({ id, inputType, description, children }) => {
		let responseContent = '';
		forEach(targets, ({ appraisalTargetId, teacherId }) => {
			const record = find(formRecords, { appraisalContentId: id, appraisalTargetId, teacherId });
			let response = '';
			if (record) {
				response = record.text;
				if (inputType === 'date') response = momentToDate(record.date);
				if (inputType === 'score') response = record.score === null ? '不適用' : record.score;
			}
			responseContent += `<td>${response}</td>`;
		});

		content += `
            <tr>
                <td style="padding-left: ${10 * level}px">${description}</td>
                ${responseContent}
            </tr>
        `;
		if (children) {
			content += displayItem(children, targets, formRecords, level + 1);
		}
	});
	return content;
};
