/* import lodash */
import {find, flatten, keyBy, pick} from 'lodash';

/* import react */
import React, { Component, memo } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	ConfirmModal,
	EmptyTableMsg,
	FullscreenDimmer,
  DateInput,
  MySelect
} from '../../../Components';

import {evaluationTypes, surveyTargets} from '../../../Const/Const';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions,
  defaultYearForPlan,
  threeYearPlanAndWhichYearFromYear,
  whetherReadOnlyForYearPlan,
  whetherReadOnlyForReport,
  getTeacherOptions,
  genEvaluationTitle
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';
import { momentToDate, dateToMoment, now, momentToDbFormat } from '../../../Helper/TimeHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, Input, TextArea } from 'semantic-ui-react';

const RawEvaluationTable = ({
  finishedLoading, strategies, actionLookup, evaluations, myActions, readOnly, addModalToggle, modalToggle, canApprove, canInputManually
}) => (
  <Table textAlign='center' celled unstackable fixed structured>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={3}>周年計劃項目</Table.HeaderCell>
        <Table.HeaderCell width={5}>成功準則</Table.HeaderCell>
        <Table.HeaderCell width={2}>方法</Table.HeaderCell>
        <Table.HeaderCell width={2}>截止日期</Table.HeaderCell>
        <Table.HeaderCell width={1}>結果</Table.HeaderCell>
        <Table.HeaderCell width={3}>行動</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {finishedLoading ? (
        strategies.length ? (
          strategies.map(strategy => {
            const action = actionLookup[strategy.id];
            if (!action)
              return (
                <Table.Row key={`strategy${strategy.id}`}>
                  <Table.Cell textAlign="left">{strategy.title}</Table.Cell>
                  <Table.Cell colSpan={5}><strong>未制定周年策略項目</strong></Table.Cell>
                </Table.Row>
              );
            const es = evaluations[action.id] || [];
            const isMyAction = myActions.hasOwnProperty(action.id);

            const firstCell = (<Table.Cell textAlign="left" rowSpan={es.length || 1}>
              {strategy.title}
              {!readOnly && !!(isMyAction || canApprove.canApprove) && <p className="addEvaluationButton"><Button color='green' icon='add' circular onClick={addModalToggle(action.id)} /></p>}
            </Table.Cell>)

            return es.length ? (es.map((e, j) => (
              <Table.Row key={`evaluation${e.id}`}>
                {!j && firstCell}
                <Table.Cell>{genEvaluationTitle(e)}</Table.Cell>
                <Table.Cell className="textlessCell">
                  {e.isSurvey === 1 ? (
                    (e.teacherId !== canApprove.teacherId && !canApprove.canApprove) ? (
                      <Button
                        disabled
                        compact
                        color='yellow'
                        icon='share'
                        labelPosition='right'
                        content='問卷'
                      />
                    ) : (
                        <Link to={`/plan/survey/${+e.id}`}>
                          <Button
                            compact
                            color='yellow'
                            icon='share'
                            labelPosition='right'
                            content='問卷'
                          />
                        </Link>
                      )
                  ) : (
                      <Button
                        disabled={!e.approved || !canInputManually || (e.teacherId !== canApprove.teacherId && !canApprove.canApprove)}
                        compact
                        color='yellow'
                        icon='pencil'
                        labelPosition='right'
                        content={e.methodDescription || (+e.isSurvey ? '手動輸入描述' : '手動輸入數字')}
                        onClick={modalToggle('isEntryModalOpen', e.id)}
                      />
                    )}
                </Table.Cell>
                <Table.Cell>{e.dueDate ? momentToDate(e.dueDate) : "不適用"}</Table.Cell>
                <Table.Cell>{e.result === null ? '-' : e.result}</Table.Cell>
                <Table.Cell>
                  {(readOnly || e.approved || (canApprove.teacherId !== e.teacherId && !canApprove.canApprove)) ? (
                    <Button color={canApprove.teacherId === e.teacherId && e.approved ? 'green' : 'blue'} icon='eye' onClick={modalToggle('isEditModalOpen', e.id)} circular />
                  ) : (
                      <Button color='blue' icon='edit' onClick={modalToggle('isEditModalOpen', e.id)} circular />
                    )}
                  {!readOnly && !!canApprove.canApprove && (+e.approved ? (
                    <Button color='grey' icon='calendar times outline' onClick={modalToggle('isDisapproveModalOpen', e.id)} circular />
                  ) : (
                      <Button color='purple' icon='calendar check outline' onClick={modalToggle('isApproveModalOpen', e.id)} circular />
                    ))}
                  {(!readOnly && !e.approved && canApprove.teacherId === e.teacherId) && (
                    <Button color='red' icon='delete' onClick={modalToggle('isDeleteModalOpen', e.id)} circular />
                  )}
                </Table.Cell>
              </Table.Row>
            ))) : (
                <Table.Row key={`action${action.id}`}>
                  {firstCell}
                  <Table.Cell colSpan={5}><strong>未有評估</strong></Table.Cell>
                </Table.Row>
              );
          })
        ) : <EmptyTableMsg colSpan='6' msg='此目標未有設定任何策略大綱' />
      ) : <EmptyTableMsg colSpan='6' msg='載入資料中' />}
    </Table.Body>
  </Table>
)

const EvaluationTable = memo(RawEvaluationTable);

class SchoolStrategyEvaluation extends Component {
  constructor(props) {
    super(props);
    this.mounted = false;

    this.TYPs = [];
    this.selectedTYP = '';
    this.whichYear = '';

    this.strategyLookup = {};
    this.actionLookup = {};
    this.evaluationFlattened = [];
    this.dueDateBackup = null;
    this.myActions = {};

    this.state = {

      year: [],
      yearId: '',
      yearInfo: [],
      readOnly: true,
      canApprove: {
        teacherId: null,
        canApprove: false,
      },
      canInputManually: false,

      finishedLoading: false,
      dimmerOpen: false,

      aims: [],
      aimOptions: [],
      aimId: '',

      strategies: [],

      actions: [],
      actionOptions: [],

      evaluations: [],

      actionId: '',
      isSurvey: 1,
      target: 1,
      dueDate: null,
      criteria: '',
      threshold: '',
      unit: '',
      approved: false,
      methodDescription: null,
      result: null,
      teacherId: null,

      onceASurvey: false,
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      selectedId: null,
    }

    this.evaluationValidator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        required: '請輸入:attribute',
        numeric: '請輸入數字:attribute',
        min: '請輸入最少 0',
      }
    });
    this.inputValidator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        default: '請輸入資料'
      }
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch(true,true);
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when mounting 1 year strategies');
      console.log(err);
    }
  }

  fetch = async (resetYear = false, resetAim = false) => {
    try{
      const [yearInfo, TYPs, canApprove] = await Promise.all([
        get('getYear'),
        get('getTYP'),
        get('getApprove'),
      ]);

      const [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs, this.state.yearId || undefined);

      this.TYPs = TYPs;
      this.selectedTYP = selectedTYP;
      this.whichYear = whichYear;
      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId: resetYear ? defaultYearForPlan(yearInfo): this.state.yearId,
      });
      const teacherOptions = await getTeacherOptions(this.state.yearId);
      await this.setStateAsync({
        readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId),
        canApprove, 
        teacherOptions,
        canInputManually: !whetherReadOnlyForReport(this.state.yearInfo, this.state.yearId),
      });
      await this.fetch3YearPlanDetails(resetAim);
    }catch(err){
      console.log('Error when fetching 1 year strategies');
      console.log(err);
    }
  }

  fetch3YearPlanDetails = async (resetAim = false) => {
    try{

      const concerns = await get(`get3YearConcern/${+this.selectedTYP}`);

      const rawAims = await Promise.all(concerns.map(concern => get(`get3YearAim/${+concern.id}`)));

      const aimPrefix = {};
      rawAims.forEach((aims,i)=>{
        aims.forEach((aim,j)=>{
          aim.title = `${i+1}.${j+1}. ${aim.title}`;
          aimPrefix[aim.id] = `${i+1}.${j+1}.`;
        })
      });

      const aims = flatten(rawAims);

      await this.setStateAsync({
        aims,
        aimId: resetAim ? (aims && aims[0] ? aims[0].id : null) : this.state.aimId,
        aimOptions: selectOptions(aims, "title", "id")
      });

      await this.fetchStratToEvaluation();

    }catch(err){
      console.log('Error when fetching TYP detail for 1 year evaluation');
      console.log(err);
    }
  };

  fetchStratToEvaluation = async () => {
    try{
      const {aimId, yearId} = this.state;

      const aimPrefix = find(this.state.aimOptions,{value: aimId}).text.match(/^(自訂 )?((\d+\.){2})/)[2];
      const strategies = await get(`get3YearStrategyByYear/${yearId}/${aimId}`);

      for(let s of strategies){
        s.title = aimPrefix+s.title;
      }

      this.strategyLookup = keyBy(strategies, "id");

      const wYear = this.whichYear;

      await this.setStateAsync({
        strategies: Number.isInteger(wYear) ? strategies.filter(s => +s[`year${1+wYear}`]) : [],
      });

      const [rawActions, myActions] = await Promise.all([
        Promise.all(this.state.strategies.map(s => get(`get1YearAction/${this.state.yearId}/${+s.id}`))),
        get(`getMy1YearAction/${+this.state.yearId}`)
      ]);

      this.myActions = myActions.reduce((prev,cur)=>({
        ...prev,
        [cur.id]: true
      }), {});

      const actions = flatten(rawActions);

      this.actionLookup = keyBy(actions, '3yearstrategyId');
      await this.setStateAsync({
        actions,
        actionOptions: selectOptions(actions, a=>this.strategyLookup[a["3yearstrategyId"]].title, "id")
      });
      
      const rawEvaluations = await Promise.all(this.state.actions.map(a => get(`getEvaluation/${+a.id}`)));

      const evaluations = {};

      this.state.actions.forEach((action,i)=>{
        evaluations[action.id] = rawEvaluations[i];
      })

      this.evaluationFlattened = flatten(rawEvaluations);

      await this.setStateAsync({evaluations});
    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching strategies for 1 year evaluation', err);
    }
  }

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId, yearInfo } = this.state;
      if (yearId !== value) {
        const [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, this.TYPs, value);
        const TYPchanged = selectedTYP != this.selectedTYP;
        this.selectedTYP = selectedTYP;
        this.whichYear = whichYear;
        await this.setStateAsync({
          yearId: value,
          finishedLoading: false,
        });
        const teacherOptions = await getTeacherOptions(this.state.yearId);
        await this.setStateAsync({
          readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId),
          teacherOptions,
          canInputManually: !whetherReadOnlyForReport(this.state.yearInfo, this.state.yearId),
        });
        await this.fetch3YearPlanDetails(TYPchanged);
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      console.log("Error when changing year for One Year Evaluation");
      console.log(err);
    }
  }

  /* Aim Change */
  aimChange = async (event, { value }) => {
    try{
      const { seletedAim } = this.state;
      if (seletedAim !== value) {
        await this.setStateAsync({
          aimId: value,
          finishedLoading: false,
        });
        
        await this.fetchStratToEvaluation();
        this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      console.log("Error when changing aim for One Year Evaluation");
      console.log(err);
    }
  }

  /* input update handler */
  inputChange = (event, value) => {
    let inputData;
    //special case for remove icon in semantic-ui-react select
    if(event.target.tagName==='I'){
      inputData = event.target.closest('.ui.dropdown, textarea, button').dataset;
    }else{
      inputData = event.target.closest('.ui, textarea, button').dataset;
    }
    const {inputType, stateName} = inputData;
    this.setStateAsync({
      [stateName]: inputHandler(inputType, value)
    });
  }

  inputChangeForMethod = (event, { value }) => {
    const { isSurvey } = this.state;
    if(value === isSurvey) return;

    const data = { isSurvey: value };
    if(value !== 1){
      data.dueDate = null;
      if(isSurvey === 1){
        data.methodDescription = "手動輸入";
      }
    }else{
      const curYear = find(this.state.yearInfo, {id: this.state.yearId}); 
      if(this.dueDateBackup){
        data.dueDate = this.dueDateBackup;
      }else{
        if(curYear){
          data.dueDate = dateToMoment(curYear.endDate);
        }else{
          data.dueDate = now().month(7).date(31);
        }
      }
      Object.assign(data, {
        threshold: null,
        unit: '',
        methodDescription: '',
      })
    }
    this.setStateAsync(data);
  }

  dueDateChange = (event, data) => {
    let value = inputHandler('date', data);
    this.setStateAsync({ dueDate: value });
    this.dueDateBackup = value;
  }

  addDefaultData = (data) => {
    const curYear = find(this.state.yearInfo, {id: this.state.yearId});
    const dueDate = curYear?dateToMoment(curYear.endDate):now().month(7).date(31);
    const result = {
      selectedId: null,
      actionId: null,
      onceASurvey: false,
      criteria: '',
      isSurvey: 1,
      target: 1,
      approved: false,
      dueDate,
      threshold: null,
      unit: '',
      result: '',
      methodDescription: null,
      teacherId: this.state.canApprove?this.state.canApprove.teacherId:null,
      ...data,
    };
    return result;
  }

  /* modal toggle */
  addModalToggle = (selectedId = null) => () => {
    this.setStateAsync(this.addDefaultData({
      isEditModalOpen: true,
      actionId: selectedId,
    }));
  }

  /* modal toggle */
  modalToggle = (modalStateName, selectedId = null) => () => {
    let data = {
      [modalStateName]: !this.state[modalStateName],
      selectedId,
    };
    if (selectedId) {
      data.selectedId = selectedId;
      const ref = find(this.evaluationFlattened, { id: selectedId }) || {};
      data.teacherId = ref.teacherId;
      switch (modalStateName) {
        case 'isEditModalOpen':
          // backup when show edit modal for method switching
          if(data.isEditModalOpen && ref.dueDate){
            this.dueDateBackup = ref.dueDate;
          }else{
            this.dueDateBackup = null;
          }
          Object.assign(data, pick(ref, ['actionId', 'isSurvey', 'dueDate', 'target', 'criteria', 'threshold', 'unit', 'approved', 'methodDescription', 'teacherId', 'disapprovalReason']));
          
          data.onceASurvey = data.isSurvey === 1;
          data.dueDate = ref.dueDate ? dateToMoment(ref.dueDate) : null;
          break;
        case 'isEntryModalOpen':
          Object.assign(data, pick(ref, ['result', 'teacherId', 'isSurvey']));
          break;
        default:break;
      }      
    } else {
      data = this.addDefaultData(data);
    }
    this.evaluationValidator.hideMessages();
    this.inputValidator.hideMessages();
    this.setStateAsync(data);
  }

  /* modal toggle */
  approveModalToggle = (modalStateName, selectedId = null) => () => {
    const ref = find(this.evaluationFlattened, { id: selectedId }) || {};
    this.setStateAsync({
      [modalStateName]: !this.state[modalStateName],
      ...pick(ref, ['isSurvey', 'dueDate', 'target', 'criteria', 'threshold', 'unit', 'approved', 'methodDescription', 'teacherId', 'disapprovalReason']),
      selectedId
    });
  }

  /* save record */
  save = async () => {
    if (!this.evaluationValidator.allValid()) {
      this.evaluationValidator.showMessages();
      return;
    }
    
    try{
      const data = pick(this.state, ['actionId', 'isSurvey', 'target', 'criteria', 'threshold', 'unit', 'methodDescription', 'teacherId', 'disapprovalReason']);
      if(this.state.selectedId){
        data.id = this.state.selectedId;
      }
      data.dueDate = this.state.dueDate===null?null:momentToDbFormat(this.state.dueDate);
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('editEvaluation', data);
      if(result && result.status){
        this.setStateAsync(this.addDefaultData({isEditModalOpen: false}));
      }else{
        throw result;
      }
      this.evaluationValidator.hideMessages();
      await this.fetch();
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存周年計劃評估項目時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  saveInput = async () => {
    if(!this.inputValidator.allValid()){
      this.inputValidator.showMessages();
      return;
    }
    try{
      const data = pick(this.state, ['result', 'teacherId']);
      if(this.state.selectedId){
        data.id = this.state.selectedId;
      }
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('editEvaluation', data);
      if(result && result.status){
        this.setStateAsync(this.addDefaultData({isEntryModalOpen: false}));
      }else{
        throw result;
      }
      this.inputValidator.hideMessages();
      await this.fetch3YearPlanDetails();
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存周年計劃評估項目手動輸入數值時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  /* delete record */
  delete = async () => {
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('deleteEvaluation', { id: this.state.selectedId });
      if(!result || !result.status){
        throw result;
      }
      await this.fetch3YearPlanDetails();
    }catch(err){
      if(err!=='unmounted'){
        alert("刪除周年計劃評估項目時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync(this.addDefaultData({
      isDeleteModalOpen: false,
      finishedLoading: true,
      dimmerOpen: false,
    }));
  }

  approve = async () => {
    if(!this.state.selectedId){
      alert("未有選擇評核");
      return;
    }
    try{
      const data = {
        id: this.state.selectedId,
        teacherId: this.state.teacherId,
        approved: true,
      }
      data.id = this.state.selectedId;
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('editEvaluation', data);
      if(!result || !result.status){
        throw result;
      }
      this.inputValidator.hideMessages();
      await this.fetch3YearPlanDetails();
    }catch(err){
      if(err!=='unmounted'){
        alert("批核周年計劃評估項目時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync(this.addDefaultData({
      isApproveModalOpen: false,
      finishedLoading: true,
      dimmerOpen: false,
    }));
  }

  disapprove = async () => {
    if(!this.state.selectedId){
      alert("未有選擇評核");
      return;
    }
    try{
      const data = {
        id: this.state.selectedId,
        teacherId: this.state.teacherId,
        approved: false,
      }
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('editEvaluation', data);
      if(!result || !result.status){
        throw result;
      }
      this.inputValidator.hideMessages();
      await this.fetch3YearPlanDetails();
    }catch(err){
      if(err!=='unmounted'){
        alert("取消批核周年計劃評估項目時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync(this.addDefaultData({
      isDisapproveModalOpen: false,
      finishedLoading: true,
      dimmerOpen: false,
    }));
  }


  render() {
    const {
      finishedLoading,
      dimmerOpen,

      year,
      yearId,
      readOnly,
      canInputManually,
      canApprove,

      evaluations,

      aimOptions,
      aimId,

      strategies,

      actionOptions,
      actionId,

      selectedId,
      isSurvey,
      dueDate,
      target,
      criteria,
      threshold,
      unit,
      result,
      methodDescription,
      teacherId,
      teacherOptions,

      onceASurvey,

      isEditModalOpen,
      isDeleteModalOpen,
      isEntryModalOpen,
      isApproveModalOpen,
      isDisapproveModalOpen,
    } = this.state;

    this.evaluationValidator.purgeFields();
    this.inputValidator.purgeFields();

    const e = find(flatten(Object.values(evaluations)), {id: selectedId});
    let modalReadOnly = !!selectedId;
    if(e){
      modalReadOnly = !!(readOnly || e.approved || ( canApprove.teacherId !== e.teacherId && !canApprove.canApprove));
    }

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='周年計劃 - 成功準則及評估方法' subTitle='為每個周年策略項目設定評估方法' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Segment padded>
                <Form>
                  <Form.Group>
                    <Form.Field inline>
                      <label>周年</label>
                      <MySelect compact disabled={!finishedLoading} options={year || []} value={yearId} onChange={this.yearChange} />
                    </Form.Field>
                    <Form.Field inline>
                      <label>目標</label>
                      <MySelect
                        compact
                        disabled={!finishedLoading || !aimOptions.length}
                        options={aimOptions}
                        value={aimId}
                        onChange={this.aimChange}
                        placeholder={aimOptions.length?"請選擇":"沒有三年計劃目標"}
                        error={!aimOptions.length}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <EvaluationTable
                finishedLoading={finishedLoading}
                strategies={strategies}
                actionLookup={this.actionLookup}
                evaluations={evaluations}
                myActions={this.myActions}
                readOnly={readOnly}
                addModalToggle={this.addModalToggle}
                modalToggle={this.modalToggle}
                canApprove={canApprove}
                canInputManually={canInputManually}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen ? (<FullscreenDimmer active={dimmerOpen} isLoading={true} />) : (
          <>
            <Modal open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>設定策略評估方法</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    {finishedLoading && (
                      <Form.Group className='form-group-margin'>
                        <Form.Field inline required>
                          <label>策略項目</label>
                          <MySelect
                            value={actionId||''}
                            disabled={modalReadOnly}
                            options={actionOptions}
                            data-input-type='select'
                            data-state-name='actionId'
                            onChange={this.inputChange}
                            placeholder='請選擇'
                          />
                          {this.evaluationValidator.message('策略項目', !!actionId, 'required|accepted')}
                        </Form.Field>
                        <Form.Field inline required style={{flex: 1, textAlign: 'right'}}>
                          <label>負責教師</label>
                          <MySelect
                            search
                            value={teacherId||''}
                            disabled={modalReadOnly || !canApprove.canApprove}
                            options={teacherOptions}
                            data-input-type='select'
                            data-state-name='teacherId'
                            onChange={this.inputChange}
                            placeholder='請選擇教師'
                            noResultsMessage='找不到教師'
                          />
                          {this.evaluationValidator.message('教師', teacherId, 'required')}
                        </Form.Field>
                      </Form.Group>
                    )}

                    <Form.Group className='form-group-margin'>
                      <Form.Field width={8}>
                        <label>方法</label>
                        <MySelect
                          options={evaluationTypes}
                          disabled={modalReadOnly}
                          value={isSurvey}
                          onChange={this.inputChangeForMethod}
                          placeholder='請選擇'
                        />
                        {(!!onceASurvey && isSurvey!==1) && <p className='red'>這樣做會清除之前建立的問卷項目 (如存在)</p>}
                        {this.evaluationValidator.message('方法', isSurvey, 'required')}
                      </Form.Field>
                      {isSurvey === 1 ? (
                        <>
                          <Form.Field width={4}>
                            <label>設計問卷截止日期</label>
                            <DateInput
                              popupPosition='bottom center'
                              value={dueDate===null?'':momentToDate(dueDate)}
                              onChange={this.dueDateChange}
                              disabled={modalReadOnly}
                            />
                          </Form.Field>
                          <Form.Field width={4}>
                            <label>對象</label>
                            <MySelect
                              options={surveyTargets}
                              disabled={modalReadOnly}
                              value={target}
                              data-input-type='select'
                              data-state-name='target'
                              onChange={this.inputChange}
                              placeholder='請選擇'
                            />
                            {this.evaluationValidator.message('對象', target, 'required')}
                          </Form.Field>
                        </>
                      ) : (
                        <Form.Field width={8}>
                          <label>評估方法描述</label>
                          <Input
                            value={methodDescription||''}
                            data-input-type='text'
                            data-state-name='methodDescription'
                            onChange={this.inputChange}
                            placeholder='方法描述'
                            disabled={modalReadOnly}
                            className="more-opaque"
                          />
                        </Form.Field>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Field width={8} required>
                        <label>成功準則</label>
                        <Input
                          value={criteria||''}
                          data-input-type='text'
                          data-state-name='criteria'
                          onChange={this.inputChange}
                          placeholder='成功準則'
                          disabled={modalReadOnly}
                          className="more-opaque"
                        />
                        {this.evaluationValidator.message('成功準則', criteria, 'required')}
                      </Form.Field>
                      {isSurvey === 0 && (<>
                        <Form.Field width={4} required>
                          <label>門檻 (數字)</label>
                          <Input
                            type="number"
                            value={threshold===null?'':threshold}
                            data-input-type='number'
                            data-state-name='threshold'
                            onChange={this.inputChange}
                            placeholder='標題'
                            disabled={modalReadOnly}
                            className="more-opaque"
                          />
                          {this.evaluationValidator.message('門檻', threshold, 'required|numeric|min:0,num')}
                        </Form.Field>
                        <Form.Field width={4} required>
                          <label>門檻單位</label>
                          <Input
                            value={unit||''}
                            data-input-type='text'
                            data-state-name='unit'
                            onChange={this.inputChange}
                            placeholder='標題'
                            disabled={modalReadOnly}
                            className="more-opaque"
                          />
                          {this.evaluationValidator.message('單位', unit, 'required')}
                        </Form.Field>
                      </>)}
                    </Form.Group>
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
                <Button disabled={modalReadOnly} color='green' content='儲存' icon='save' onClick={this.save} circular />
              </Modal.Actions>
            </Modal>

            <Modal open={isEntryModalOpen} onClose={this.modalToggle('isEntryModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>輸入策略評估結果</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    <Form.Group>
                      <Form.Field inline>
                        <label>結果</label>
                        {+isSurvey?(
                          <TextArea
                            value={result===null?'':result}
                            data-input-type='text'
                            data-state-name='result'
                            onChange={this.inputChange}
                            placeholder='請輸入'
                          />
                        ):(
                          <Input
                            type="number"
                            value={result===null?'':result}
                            data-input-type='text'
                            data-state-name='result'
                            onChange={this.inputChange}
                            placeholder='請輸入純數字'
                          />
                        )}
                        {this.inputValidator.message('result', result, 'required')}
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color='red'
                  content='取消'
                  icon='cancel'
                  onClick={this.modalToggle('isEntryModalOpen')}
                circular />
                <Button
                  color='green'
                  content='儲存'
                  icon='save'
                  onClick={this.saveInput}
                  circular
                />
              </Modal.Actions>
            </Modal>

            <ConfirmModal
              open={isDeleteModalOpen}
              description={<>確定<span className='red'>刪除</span>策略評估方法？</>}
              cancel={this.approveModalToggle('isDeleteModalOpen', false)}
              confirm={this.delete}
            />

            <ConfirmModal
              open={isApproveModalOpen}
              description={<>確定<span className='red'>批核</span>策略評估方法？</>}
              cancel={this.approveModalToggle('isApproveModalOpen', false)}
              confirm={this.approve}
              confirmText="批核"
              confirmIcon="calendar check"
            />

            <ConfirmModal
              open={isDisapproveModalOpen}
              description={<>確定<span className='red'>取消批核</span>策略評估方法？</>}
              cancel={this.approveModalToggle('isDisapproveModalOpen', false)}
              confirm={this.disapprove}
              confirmText="取消批核"
              confirmIcon="calendar times"
            />
          </>
        )}
      </>
    )
  }
}

export default withRouter(SchoolStrategyEvaluation);