/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {pick} from 'lodash';

/* import components */
import {
  PageHeader,
  FullscreenDimmer,
  ConfirmModal,
  BlockerPrompt,
} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';

/* import semantic-ui element */
import { Grid, Button, Form, Segment, Header } from 'semantic-ui-react';

class SchoolInfo extends Component {
  constructor(props) {
    super(props);

    this.fields = ['supervisor','principal','schoolType','studentGender','campusArea','organization','religion','motto','purpose','assocSecSchool','language','longPurpose','longAim','coreValues' ,'foundingYear', 'pta', 'alumni'];

    this.state = {
      finishedLoading: false,
      dimmerOpen: false,

      isSaveModalOpen: false,
      isBlocking: false,

      ...this.fields.reduce((prev,cur)=>({...prev, [cur]:''}), {})
    };
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try {
      const [schoolInfo] = await get('getSchoolInfo');
      this.setStateAsync(schoolInfo);
    } catch (err) {
      console.log("Error when fetching school info");
      console.log(err);
    }
  }

  save = async () => {
    try{
      await this.setStateAsync({dimmerOpen: true, finishedLoading: false});
      const data = pick(this.state, this.fields);
      const result = await post('editSchoolInfo', data);
      if(result && result.status){
        this.setStateAsync({
          isBlocking: false,
        })
        await this.fetch();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存學校資料時發生錯誤");
        console.log("Error when saving school info", err);
      }
    }
    this.modalToggle('isSaveModalOpen')();
    this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true,
    })
  }

  /* modal toggle */
  modalToggle = (modalStateName) => () => {
    this.setStateAsync({
      [modalStateName]: !this.state[modalStateName]
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;

    await this.fetch();
    await this.setStateAsync({
      finishedLoading: true
    });
  }

  /* input update handler */
  inputChange = (event, data) => {
    const {inputType, stateName} = event.target.closest('.ui, button, textarea').dataset;
    let value = inputHandler(inputType, data);
    this.setStateAsync({
      [stateName]: value,
      isBlocking :true,
    })
  }

  render() {
    const {
      finishedLoading,
      dimmerOpen,
      isSaveModalOpen,
      
      supervisor,
      principal,
      schoolType,
      studentGender,
      campusArea,
      organization,
      religion,
      foundingYear,
      motto,
      purpose,
      assocSecSchool,
      language,
      pta,
      alumni,
      longPurpose,
      longAim,
      coreValues,
      isBlocking,
    } = this.state;

    return (<>
      <style>{".inline.field>label {min-width: 9em;}"}</style>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='學校資料' subTitle='設定學校資料' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form>
              <Segment>
                <Header as='h3'>基本資料</Header>
                <Form.Group widths='equal'>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='校監／校管會主席' value={supervisor} onChange={this.inputChange} data-input-type='text' data-state-name='supervisor' /></Form.Field>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='校長' value={principal} onChange={this.inputChange} data-input-type='text' data-state-name='principal' /></Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='學校類別' value={schoolType} onChange={this.inputChange} data-input-type='text' data-state-name='schoolType' /></Form.Field>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='學生性別' value={studentGender} onChange={this.inputChange} data-input-type='text' data-state-name='studentGender' /></Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='學校佔地面積' value={campusArea} onChange={this.inputChange} data-input-type='text' data-state-name='campusArea' /></Form.Field>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='辦學團體' value={organization} onChange={this.inputChange} data-input-type='text' data-state-name='organization' /></Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='宗教' value={religion} onChange={this.inputChange} data-input-type='text' data-state-name='religion' /></Form.Field>
                  <Form.Field><Form.Input disabled={!finishedLoading} type='number' inline label='創校年份' value={foundingYear} onChange={this.inputChange} data-input-type='text' data-state-name='foundingYear' /></Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='校訓' value={motto} onChange={this.inputChange} data-input-type='text' data-state-name='motto' /></Form.Field>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='辦學宗旨' value={purpose} onChange={this.inputChange} data-input-type='text' data-state-name='purpose' /></Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='聯繫中學' value={assocSecSchool} onChange={this.inputChange} data-input-type='text' data-state-name='assocSecSchool' /></Form.Field>
                  <Form.Field><Form.Input disabled={!finishedLoading} inline label='教學語言' value={language} onChange={this.inputChange} data-input-type='text' data-state-name='language' /></Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field><Form.Checkbox disabled={!finishedLoading} inline label='家長教師會' checked={!!(+pta)} onChange={this.inputChange} data-input-type='checkbox' data-state-name='pta' /></Form.Field>
                  <Form.Field><Form.Checkbox disabled={!finishedLoading} inline label='舊生會／校友會' checked={!!(+alumni)} onChange={this.inputChange} data-input-type='checkbox' data-state-name='alumni' /></Form.Field>
                </Form.Group>
              </Segment>
              <Segment>
                <Header as='h3'>詳細資料</Header>
                <Form.TextArea disabled={!finishedLoading} label='辦學宗旨' value={longPurpose} onChange={this.inputChange} data-input-type='text' data-state-name='longPurpose' rows={6} />
                <Form.TextArea disabled={!finishedLoading} label='辦學目標' value={longAim} onChange={this.inputChange} data-input-type='text' data-state-name='longAim' rows={12} />
                <Form.TextArea disabled={!finishedLoading} label='教育信念' value={coreValues} onChange={this.inputChange} data-input-type='text' data-state-name='coreValues' rows={6} />
              </Segment>
              <Segment>
                <Form.Field disabled={!finishedLoading} control={Button} onClick={this.modalToggle('isSaveModalOpen')}>儲存</Form.Field>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {dimmerOpen ? <FullscreenDimmer active={true} isLoading={true} />:(<ConfirmModal
        open={isSaveModalOpen}
        description='確定儲存學校資料？'
        cancel={this.modalToggle('isSaveModalOpen')}
        confirm={this.save}
        confirmIcon='check'
        confirmText='儲存'
      />)}
      <BlockerPrompt isBlocking={isBlocking}/>
    </>)
  }
}

export default withRouter(SchoolInfo);