/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	FullscreenDimmer,
  ErrorLabel,
  BlockerPrompt,
  Buttons
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { displayDate } from '../../../Helper/TimeHelper';

import {activityReportGradeOptions as gradeOptions} from '../../../Const/Const';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Segment, Form, TextArea, Button, Radio } from 'semantic-ui-react';

class FridayActivityReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: false,
      finishedLoading: false,
      isBlocking: false,
      fridayActivityClassId: this.props.match.params.fridayActivityClassId || null,
      fridayActivityId: this.props.match.params.fridayActivityId || null,
      type: this.props.match.params.type || null,
      fridayActivity: {},
      fridayActivityClass: {},
      fridayActivityReport: {
        id: null,
        content: null,
        mark: 4,
        remark: null
      }
    }

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => <ErrorLabel message={message} />,
      messages: {
        default: '請輸入資料'
      }
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchData = async () => {
    const {fridayActivityId, fridayActivityClassId, type} = this.state;
    const classRequest = type === 'special' ? 'getOneSpecialFridayActivityClass' : 'getOneNormalFridayActivityClass';
    const reportRequest = type === 'special' ? 'getOneSpecialFridayActivityReport' : 'getOneNormalFridayActivityReport';

    try{
      const [fridayActivity, fridayActivityClass, fridayActivityReport] = await Promise.all([
        get('getOneFridayActivityWithDetails/' + fridayActivityId),
        get(classRequest + '/' + fridayActivityClassId),
        get(reportRequest + '/' + fridayActivityClassId),
      ]);

      await this.setStateAsync({
        fridayActivity: fridayActivity[0] || {},
        fridayActivityClass: fridayActivityClass[0] || {},
        fridayActivityReport: fridayActivityReport[0] || {
          id: null,
          content: null,
          mark: 4,
          remark: null
        }
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity', err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  fridayActivityReportChange = (type, stateName) => (event, data) => {
    const value = inputHandler(type, data);
    this.setStateAsync({
      isBlocking: true,
      fridayActivityReport: {
        ...this.state.fridayActivityReport,
        [stateName]: value
      }
    });
  }

  return = () => {
    this.mounted = false;
    const {section, type, yearId} = this.props.match.params;
    this.props.history.push(`/eca/fridayActivity/${section}/detail/${type}/${yearId}/${this.state.fridayActivityId}?q=2`);
  }

  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }

    let fridayActivityReport = {
      id: this.state.fridayActivityReport.id,
      content: this.state.fridayActivityReport.content,
      mark: this.state.fridayActivityReport.mark,
      remark: this.state.fridayActivityReport.mark === 0 ? this.state.fridayActivityReport.remark : null
    }

    if (this.state.type === 'special') {
      fridayActivityReport.specialFridayActivityClassId = this.state.fridayActivityClassId;
    } else {
      fridayActivityReport.normalFridayActivityClassId = this.state.fridayActivityClassId;
    }

    const postRequest = this.state.type === 'special' ? 'editSpecialFridayActivityReport' : 'editNormalFridayActivityReport';
    try{
      await this.setStateAsync({ dimmerOpen: true, finishedLoading: false });
      const result = await post(postRequest, { fridayActivityReport });
      if (result.status) {
        await this.setStateAsync({isBlocking: false});
        this.return();
        return;
      }
      throw result;
    }catch(err){
      if(err!=='unmounted'){
        alert(`儲存${this.state.fridayActivity.yearName || ''} ${this.state.fridayActivity.name || ''} (${displayDate(this.state.fridayActivityClass.date)})的學習紀錄時發生錯誤`);
        this.setStateAsync({ dimmerOpen: false, finishedLoading: true });
        console.log('Error when saving friday activity report', err);
      }
    }
  }

  render() {
    const {
      dimmerOpen,
      finishedLoading,
      isBlocking,
      fridayActivity,
      fridayActivityClass,
      fridayActivityReport
    } = this.state;

    return (
      <>
        {dimmerOpen && <FullscreenDimmer active={dimmerOpen} isLoading={true} /> }
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='編輯學習紀錄' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form>
                <Segment.Group className='form-group-margin'>
                  <Segment className='bold' size='big'>{fridayActivity.yearName || ''} {fridayActivity.name || ''} ({displayDate(fridayActivityClass.date)})</Segment>
                  <Segment secondary>
                    <Form.Group widths='equal'>
                      <Form.Input
                        className='form-group-margin'
                        disabled={!finishedLoading}
                        control={TextArea}
                        onChange={this.fridayActivityReportChange('text', 'content')}
                        value={fridayActivityReport.content || ''}
                        label='學習內容及學習活動'
                        placeholder='學習內容及學習活動'
                        error={this.validator.message('type', fridayActivityReport.content, 'required')}
                      />
                    </Form.Group>
                    <Form.Group className='form-group-margin'>
                      <Form.Field disabled={!finishedLoading}>
                        <label>學生學習興趣評估</label>
                      </Form.Field>
                      {gradeOptions.map((_, value)=>(
                        <Form.Field
                          disabled={!finishedLoading}
                          name='mark'
                          key={gradeOptions.length-1-value}
                          control={Radio}
                          label={gradeOptions[gradeOptions.length-1-value]}
                          value={gradeOptions.length-1-value}
                          checked={fridayActivityReport.mark === gradeOptions.length-1-value}
                          onChange={this.fridayActivityReportChange('radio', 'mark')}
                        />
                      ))}
                    </Form.Group>
                    {fridayActivityReport.mark === 0 &&
                      <Form.Group widths='equal'>
                        <Form.Input
                          disabled={!finishedLoading}
                          className='form-group-margin'
                          type='text'
                          onChange={this.fridayActivityReportChange('text', 'remark')}
                          value={fridayActivityReport.remark || ''}
                          label='其他請註明'
                          placeholder='其他請註明'
                        />
                      </Form.Group>
                    }
                  </Segment>
                </Segment.Group>
                <div className='text-center'>
                  <Buttons.CancelButton disabled={!finishedLoading} onClick={this.return} />
                  <Buttons.SaveButton disabled={!finishedLoading} onClick={this.save} />
                </div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(FridayActivityReport);