/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import ErrorLabel from '../../../Components/ErrorLabel';
import ConfirmModal from '../../../Components/ConfirmModal';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import EmptyTableMsg from '../../../Components/EmptyTableMsg';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { momentToDate, now, htmlInputDate } from '../../../Helper/TimeHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import lodash */
import { compact, find, difference, map, orderBy } from 'lodash';

/* import semantic-ui element */
import { Grid, Segment, Button, Modal, Form, Table, Checkbox } from 'semantic-ui-react';

const tableHeader = [
    {displayName: '編號', columnName: 'index'},
    {displayName: '老師名稱', columnName: 'teacherName'},
    {displayName: '遞交限期', columnName: 'deadline'}
]

const modalTableHeader = [
    {displayName: '編號', columnName: 'index'},
    {displayName: '老師名稱', columnName: 'displayName'}
]

class EditForm extends Component {
    constructor(props) {
        super(props);
        ping(this);

        // form validator
        this.validator = new SimpleReactValidator({
            element: message =>  <ErrorLabel message={message} />,
            messages: { default: '請選擇老師' }
        })
    }
    
    state = {
        dimmerOpen: true,

        yearId: this.props.match.params.yearId,
        appraisalItemId: this.props.match.params.appraisalItemId,
        appraisalItem: {},
        appraisalTarget: [],
        appraisalTargetTeacherIdArray: [], 

        teacher: [],
        selectedTeacher: [],

        isEditModalOpen: false,
        isDeleteModalOpen: false,

        deadline: now(),
        checkAllTeacher: false,
        selectedId: null,

        sortColumn: ['ascending', 'ascending', null],
        modalSortColumn: ['ascending', null],
    }

    fetch = async () => {
        const { yearId, appraisalItemId } = this.state;
        const [appraisalItem, teacher, appraisalTarget] = await Promise.all([
            get('getAppraisalItem/'+appraisalItemId),
            get('getAllTeacherWithAdminWithIndex/'+yearId),
            get('getAppraisalTableTarget/'+appraisalItemId)
        ]);

        map(appraisalTarget, ({teacherId}, index) => {
            const teacherReocrd = find(teacher, {id: teacherId});
            appraisalTarget[index]['index'] = teacherReocrd? teacherReocrd.index : null;
            appraisalTarget[index]['name'] = teacherReocrd? teacherReocrd.name : null;
        });

        this.setState({
            appraisalItem: appraisalItem.length > 0? appraisalItem[0] : {},
            teacher: teacher,
            appraisalTargetTeacherIdArray: appraisalTarget.length > 0? appraisalTarget.map(({teacherId}) => { return teacherId; }) : [], 
            appraisalTarget: orderBy(appraisalTarget, ['index', 'name'], ['asc', 'asc'])
        });        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    componentDidMount = async () => {
        this.mounted = true;
        await this.fetch();
        this.setState({ dimmerOpen: false });
    }

    /* modal toggle */
    modalToggle = (modalStateName, selectedId = null) => () => {
        let deadline = htmlInputDate();
        if(selectedId) {
            const { appraisalTarget } = this.state; 
            let selectedRecord = find(appraisalTarget, {id: selectedId});
            deadline = htmlInputDate(selectedRecord.deadline);
        }

        this.setState({
            selectedId,
            deadline: deadline,
            selectedTeacher: [],
            checkAllTeacher: false,
            [modalStateName]:  !this.state[modalStateName],
        })
    }

    /* Input change */
    inputChange = (inputType, stateName) => (event, data) => {
        let value = inputHandler(inputType, data);
        this.setState({[stateName]: value});
    }

    /* table sorting */
    sorting = (index = null) => {
        let { appraisalTarget, sortColumn } = this.state;
        let column = [], order = [], newDirection = null;

        if(index >= 0) {
            if(sortColumn[index] === null) newDirection = 'ascending';
            if(sortColumn[index] === 'ascending') newDirection = 'descending';
            sortColumn[index] = newDirection;
        }

        map(sortColumn, (direction, index) => {
            if(direction) {
                column.push(tableHeader[index]['columnName']);
                order.push(direction === 'ascending'? 'asc' : 'desc');
            }
        })
        this.setState({ sortColumn, appraisalTarget: orderBy(appraisalTarget, column, order) });
    }

    /* modal table sorting */
    modalSorting = (index) => {
        let { teacher, modalSortColumn } = this.state;
        let column = [], order = [], newDirection = null;
        if(modalSortColumn[index] === null) newDirection = 'ascending';
        if(modalSortColumn[index] === 'ascending') newDirection = 'descending';
        modalSortColumn[index] = newDirection;
        map(modalSortColumn, (direction, index) => {
            if(direction) {
                column.push(modalTableHeader[index]['columnName']);
                order.push(direction === 'ascending'? 'asc' : 'desc');
            }
        })
        this.setState({ modalSortColumn, teacher: orderBy(teacher, column, order) });
    }

    toggleTeacher = (id) => (event, data) => {
        const { selectedTeacher, teacher, appraisalTargetTeacherIdArray } = this.state;
        let value = data.checked;
        let index = selectedTeacher.indexOf(id);

        if(value && index < 0) {
            selectedTeacher.push(id);
        }

        if(!value && index > -1) {
            selectedTeacher.splice(index, 1);
        }

        let checkAllTeacher = false;
        if(selectedTeacher.length === difference(map(teacher, 'id'), appraisalTargetTeacherIdArray).length) {
            checkAllTeacher = true;
        }

        let newSelectedTeacher = selectedTeacher;
        this.setState({
            checkAllTeacher,
            selectedTeacher: newSelectedTeacher
        });
    }

    toggleAllTeacher = (event, data) => {
        const { teacher, appraisalTarget } = this.state;
        let value = data.checked;
        let newSelectedTeacher = [];
        let targetTeacherId = appraisalTarget.map(({teacherId}) => { return teacherId; })

        if(value) {
            newSelectedTeacher = compact(teacher.map(({id}) => {
                if(targetTeacherId.indexOf(id) < 0){
                    return id;
                }
                return null;
            }))
        }
        this.setState({
            checkAllTeacher: value,
            selectedTeacher: newSelectedTeacher
        });
    }

    save = async () => {
        const { selectedId, deadline, appraisalItemId, selectedTeacher } = this.state;

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return;
        }

        let data = {
            id: selectedId,
            deadline,
            appraisalItemId,
            target: selectedTeacher
        }

        this.setState({ dimmerOpen: false })
        let edit = await post('editAppraisalTableTarget', data);
        if(edit.status) {
            await this.fetch();
            this.validator.hideMessages();
            this.forceUpdate();
            this.sorting();
            this.setState({
                isEditModalOpen: false,
                dimmerOpen: false
            })
        }
    }

    delete = async () => {
        const { selectedId } = this.state;
        this.setState({ dimmerOpen: false })
        let remove  = await post('deleteAppraisalTarget', { id: selectedId });
        if(remove.status) {
            await this.fetch();
            this.sorting();
            this.setState({
                isDeleteModalOpen: false,
                dimmerOpen: false
            })
        }
    }

    render() {
        this.validator.purgeFields();
        const { dimmerOpen, appraisalItem, appraisalTarget, appraisalTargetTeacherIdArray, selectedId, deadline, checkAllTeacher, teacher, selectedTeacher, isEditModalOpen, isDeleteModalOpen, sortColumn, modalSortColumn } = this.state;
        return (
            <Fragment>
                {dimmerOpen? 
                    <FullscreenDimmer active={dimmerOpen} isLoading={true} />
                    :
                    <Fragment>      
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <PageHeader title='編輯評估對象' prevPage={() => this.props.history.push('/appraisal/target')} />
                                </Grid.Column>
                            </Grid.Row>

                            {appraisalItem &&
                                <Fragment>                       
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div>
                                                <Segment.Group>                                    
                                                    <Segment className='bold larger-font'>
                                                        <Grid columns={2} stackable >
                                                            <Grid.Row verticalAlign='middle'>
                                                                <Grid.Column>{appraisalItem.yearName} : {appraisalItem.displayName}</Grid.Column>
                                                                <Grid.Column textAlign='right'>
                                                                    <Button onClick={this.modalToggle('isEditModalOpen')} color='green' icon='add' content='新增被評老師' circular />  
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Segment>  
                                                    <Segment padded='very' secondary>
                                                        <Table textAlign='center' sortable selectable celled unstackable>
                                                            <Table.Header>
                                                                <Table.Row >
                                                                    {tableHeader.map(({displayName}, index) => {
                                                                        return (
                                                                            <Table.HeaderCell key={index} sorted={ sortColumn[index] } onClick={() => this.sorting(index)}>
                                                                                {displayName}
                                                                            </Table.HeaderCell>
                                                                        )
                                                                    })}
                                                                    <Table.HeaderCell>行動</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>
                                                            <Table.Body>
                                                                {appraisalTarget.length > 0?
                                                                    <Fragment>
                                                                        {appraisalTarget.map(({id, deadline, index, name }) => (
                                                                            <Table.Row key={id}>
                                                                                <Table.Cell>{index}</Table.Cell>
                                                                                <Table.Cell>{name}</Table.Cell>
                                                                                <Table.Cell>{momentToDate(deadline)}</Table.Cell>
                                                                                <Table.Cell>
                                                                                    <Button onClick={this.modalToggle('isEditModalOpen', id)} color='blue' icon='edit' circular />  
                                                                                    <Button onClick={this.modalToggle('isDeleteModalOpen', id)} color='red' icon='delete' circular />  
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        ))}
                                                                    </Fragment>
                                                                    :
                                                                    <EmptyTableMsg colSpan='4' />
                                                                }
                                                            </Table.Body>
                                                        </Table>
                                                    </Segment>
                                                </Segment.Group>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Fragment>
                            }
                        </Grid>

                        <Modal open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
                            <Modal.Header>新增被評估對象</Modal.Header>
                            <Modal.Content>
                                <Segment basic>
                                    <Form>
                                        <Form.Group className='form-group-margin' grouped>
                                            <Form.Input
                                                className='form-group-margin'
                                                type='date'
                                                onChange={this.inputChange('text', 'deadline')}
                                                value={deadline}
                                                label='遞交限期*'
                                                placeholder='遞交限期*'
                                                error={this.validator.message('deadline', deadline, 'required')}    
                                            />
                                        </Form.Group>

                                        {!selectedId && 
                                            <Fragment>
                                                <Table textAlign='center' sortable selectable celled unstackable>
                                                    <Table.Header>
                                                        <Table.Row >
                                                            <Table.HeaderCell collapsing>
                                                                <Form.Field 
                                                                    onChange={this.toggleAllTeacher}
                                                                    checked={checkAllTeacher}
                                                                    control={Checkbox} 
                                                                /> 
                                                            </Table.HeaderCell>
                                                            {modalTableHeader.map(({displayName}, index) => {
                                                                return (
                                                                    <Table.HeaderCell key={index} sorted={ modalSortColumn[index] } onClick={() => this.modalSorting(index)}>
                                                                        {displayName}
                                                                    </Table.HeaderCell>
                                                                )
                                                            })}
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {difference(map(teacher, 'id'), appraisalTargetTeacherIdArray).length > 0?
                                                            <Fragment>
                                                                {teacher.map(({id, index, name}) => {
                                                                    return (
                                                                        <Fragment key={id}>
                                                                            {appraisalTargetTeacherIdArray.indexOf(id) < 0 &&
                                                                                <Table.Row>
                                                                                    <Table.Cell collapsing>
                                                                                        <Form.Field 
                                                                                            onChange={this.toggleTeacher(id)}
                                                                                            checked={selectedTeacher.indexOf(id) > -1}
                                                                                            control={Checkbox} 
                                                                                        /> 
                                                                                    </Table.Cell>
                                                                                    <Table.Cell>{index}</Table.Cell>
                                                                                    <Table.Cell>{name}</Table.Cell>
                                                                                </Table.Row>
                                                                            }
                                                                        </Fragment>
                                                                    )
                                                                })}
                                                            </Fragment>
                                                            :
                                                            <EmptyTableMsg colSpan='4' msg='沒有更多未選老師' />
                                                        }
                                                    </Table.Body>
                                                </Table>
                                                {this.validator.message('selectedTeacher', selectedTeacher, 'required')}
                                            </Fragment>
                                        }
                                    </Form>
                                </Segment>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
                                <Button color='green' content='儲存' icon='save' onClick={this.save} circular />
                            </Modal.Actions>    
                        </Modal>

                        <ConfirmModal open={isDeleteModalOpen} description='確定刪除評估對象？' cancel={this.modalToggle('isDeleteModalOpen')} confirm={this.delete} />
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default withRouter(EditForm);