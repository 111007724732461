/* import react */
import React, { useEffect, useState } from 'react';

/* import react router */
import { Link } from 'react-router-dom';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';

/* import helper */
import { get, ping } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../Helper/Helper';
import { buildTree } from '../../../Helper/TreeHelper';

/* import lodash */
import { uniq, map, filter } from 'lodash';

/* import semantic-ui element */
import { Grid, Select, Button, List, Segment, GridColumn, GridRow, Header, ListItem, Label } from 'semantic-ui-react';

const Item = () => {
	const [loading, setLoading] = useState(false);
	const [years, setYears] = useState([]);
	const [selectedYear, setSelectedYear] = useState(null);
	const [isSignSet, setIsSignSet] = useState(false);
	const [appraisalItems, setAppraisalItems] = useState([]);
	const [personalAppraisalItems, setPersonalAppraisalItems] = useState([]);
	const [outstandings, setOutstandings] = useState([]);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const yearResult = await get('getYear');
			const isSignSet = await get('isSignSet');

			setYears(selectOptions(yearResult, 'displayName', 'id'));
			setSelectedYear(defaultYear(yearResult));
			setIsSignSet(!!isSignSet);
			setLoading(false);
		};

		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const appraisalItems = await get(`getAllAppraisalItem/${selectedYear}`);
			const personalAppraisalItems = await get(`getPersonalAppraisalItem/${selectedYear}`);
			const before = await get(`getAllPersonalOutstandingFormTargetBefore/${selectedYear}`);
			const self = await get(`getAllPersonalOutstandingFormTargetSelf/${selectedYear}`);
			const other = await get(`getAllPersonalOutstandingFormTargetOther/${selectedYear}`);

			setAppraisalItems(buildTree(appraisalItems));
			setPersonalAppraisalItems(uniq(map(personalAppraisalItems, 'appraisalItemId')));
			setOutstandings([...before, ...self, ...other]);
			setLoading(false);
		};

		if (!!selectedYear) fetch();
	}, [selectedYear]);

	const display = (items) => {
		return (
			<React.Fragment key={Math.random()}>
				{items.map(({ id, displayName, tableFormat, children }) => {
					if (!!children) return display(children);

					if (personalAppraisalItems.indexOf(id) >= 0) {
						const link = `/appraisal/item/${!!tableFormat ? 'table' : 'form'}_list/${id}`;
						const length = filter(outstandings, { appraisalItemId: id }).length;
						return (
							<ListItem key={id}>
								<Segment className="hoverable">
									<Grid columns={2}>
										<GridRow verticalAlign="middle">
											<GridColumn>
												<Header size="tiny">
													{displayName}
													{length > 0 && <Label color="orange">{length}</Label>}
												</Header>
											</GridColumn>
											<GridColumn textAlign="right">
												<Link to={link}>
													<Button onClick={() => {}} color="blue" icon="edit" circular />
												</Link>
											</GridColumn>
										</GridRow>
									</Grid>
								</Segment>
							</ListItem>
						);
					}
				})}
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid stackable>
				<GridRow>
					<GridColumn>
						<PageHeader title="個人評估項目" subTitle="每年度個人需要進行之評估" />
					</GridColumn>
				</GridRow>
				<GridRow columns={1}>
					<GridColumn>
						<Select onChange={(event, { value }) => setSelectedYear(value)} options={years} value={selectedYear} />
					</GridColumn>
				</GridRow>
				{!isSignSet && (
					<GridRow columns={1}>
						<GridColumn>
							<Link to={'/user/setting'}>
								<Button color="red" content="評估前請先設定簽名密碼" icon="warning" circular />
							</Link>
						</GridColumn>
					</GridRow>
				)}
				<Grid.Row>
					<Grid.Column>
						<Segment disabled={!isSignSet} padded="very" secondary>
							{personalAppraisalItems.length > 0 ? (
								<List>{display(appraisalItems)}</List>
							) : (
								<Header size="medium" textAlign="center">
									沒有評估項目
								</Header>
							)}
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</React.Fragment>
	);
};

export default Item;
