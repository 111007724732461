export const weekdayOptions = [
  { text: '星期一', value: "1" },
  { text: '星期二', value: "2" },
  { text: '星期三', value: "3" },
  { text: '星期四', value: "4" },
  { text: '星期五', value: "5" },
  { text: '星期六', value: "6" },
  { text: '星期日', value: "7" }
];

export const weekdayLookup = {
  "1": '一',
  "2": '二',
  "3": '三',
  "4": '四',
  "5": '五',
  "6": '六',
  "7": '日',
}

export const fridayActivityTypeOptions = [
  { text: '專項', value: 'special' },
  { text: '普通', value: 'normal' }
]

export const gradeOptions = [
  { text: process.env.REACT_APP_SCHOOL_TYPE+'一', value: 1 },
  { text: process.env.REACT_APP_SCHOOL_TYPE+'二', value: 2 },
  { text: process.env.REACT_APP_SCHOOL_TYPE+'三', value: 3 },
  { text: process.env.REACT_APP_SCHOOL_TYPE+'四', value: 4 },
  { text: process.env.REACT_APP_SCHOOL_TYPE+'五', value: 5 },
  { text: process.env.REACT_APP_SCHOOL_TYPE+'六', value: 6 }
]

export const activityReportGradeOptions = ['其他', '可', '常', '良', '優'];

export const excelFormats = [
  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const levelOptions = ['G', '1', '2', '3', '4', '5', '6', '7'].map(x => ({ key: x, value: x, text: x }));


export const evaluationTypes = [
  { text: '問卷', value: 1 },
  { text: '手動輸入數值', value: 0 },
  { text: '手動輸入描述', value: 2 },
];

export const surveyTargets = [
  { text: '教師', value: 1 },
  { text: '學生', value: 2 },
  { text: '師生', value: 3 },
];

export const removeButtonProps = {
  color: 'red',
  icon: 'times',
  content: undefined,
  circular: true,
}

export const groupTypeOptions = (process.env.REACT_APP_GROUP_TYPES || "").split("|").map(type => ({
  text: type,
  value: type,
  key: type
}));

export const privilegeTypes = [];