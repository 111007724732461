/* import react */
import React, { useEffect, useState } from 'react';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import ConfirmModal from '../../../Components/ConfirmModal';
import EmptyTableMsg from '../../../Components/EmptyTableMsg';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { buildTree, treeLayer, treeLength } from '../../../Helper/TreeHelper';
import { momentToDate, isDeadlineOver } from '../../../Helper/TimeHelper';
import { textareaText } from '../../../Helper/Helper';

/* import moment */
import moment from 'moment';

/* Appraisal css */
import '../../../Styles/Appraisal.css';

/* import lodash */
import { filter, orderBy, compact, sumBy, find, forEach } from 'lodash';

/* import semantic-ui element */
import {
	Grid,
	Button,
	Segment,
	SegmentGroup,
	Table,
	Label,
	Message,
	GridRow,
	GridColumn,
	Header,
	TableHeaderCell,
} from 'semantic-ui-react';
import { Link, useLocation, useParams } from 'react-router-dom';

const TableList = () => {
	const { section, appraisalItemId, teacherId } = useParams();
	const [loading, setLoading] = useState(false);
	const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
	const [teacherInfo, setTeacherInfo] = useState({});
	const [appraisalLock, setAppraisalLock] = useState({});
	const [appraisalItem, setAppraisalItem] = useState({});
	const [appraisalTarget, setAppraisalTarget] = useState({});
	const [appraisalContents, setAppraisalContents] = useState([]);
	const [appraisalContentTree, setAppraisalContentTree] = useState([]);
	const [personalTableRecords, setPersonalTableRecords] = useState([]);
	const [appraisalLevelOptions, setAppraisalLevelOptions] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [returnUrl, setReturnUrl] = useState('/');
	const appeal = section === 'appeal';

	const status = appraisalLock.appraisalLock
		? 'lock'
		: isDeadlineOver(appraisalTarget.deadline)
		? 'expire'
		: !appeal
		? 'add'
		: null;

	const fetchRecord = async () => {
		const appraisalTableRecords = await get(`getPersonalAppraisalTableRecord/${appraisalItemId}/${teacherId}`);
		let personalTableRecords = [];
		appraisalTableRecords.forEach(({ appraisalTableRecordId, appraisalContentId, text, date, score, submitDate }) => {
			if (!personalTableRecords[appraisalTableRecordId]) personalTableRecords[appraisalTableRecordId] = {};
			if (submitDate) personalTableRecords[appraisalTableRecordId]['submitDate'] = submitDate;
			if (text) return (personalTableRecords[appraisalTableRecordId][appraisalContentId] = text);
			if (date) return (personalTableRecords[appraisalTableRecordId][appraisalContentId] = date.split(','));
			if (score !== null) return (personalTableRecords[appraisalTableRecordId][appraisalContentId] = score);
		});
		setPersonalTableRecords(personalTableRecords);
	};

	useEffect(() => {
		fetchRecord();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);

			const teacherInfo = await get(`getOneTeacher/${teacherId}`);
			setTeacherInfo(teacherInfo.length > 0 ? teacherInfo[0] : {});

			const appraisalItem = await get(`getAppraisalItem/${appraisalItemId}`);
			setAppraisalItem(appraisalItem.length > 0 ? appraisalItem[0] : {});

			const appraisalTarget = await get(`getPersonalAppraisalTableTarget/${appraisalItemId}/${teacherId}`);
			setAppraisalTarget(appraisalTarget.length > 0 ? appraisalTarget[0] : {});

			let appraisalContents = await get(`getAppraisalContent/${appraisalItemId}`);
			appraisalContents = orderBy(appraisalContents, ['order', 'id']);
			setAppraisalContents(appraisalContents);
			setAppraisalContentTree(buildTree(appraisalContents));

			const appraisalLevelOptions = await get(`getAppraisalLevelOption/${appraisalItemId}`);
			setAppraisalLevelOptions(appraisalLevelOptions);

			setLoading(false);
		};

		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);

			const appraisalLock = await get(`getOneAppraisalLock/${appraisalItem.yearId}`);
			setAppraisalLock(appraisalLock.length ? appraisalLock[0] : {});

			setLoading(false);
		};

		if (!!appraisalItem.yearId) fetch();
	}, [appraisalItem.yearId]);

	useEffect(() => {
		setReturnUrl(appeal ? `/appraisal/appeal/item/${appraisalItem.yearId}/${teacherId}` : '/appraisal/item');
	}, [appeal, appraisalItem.yearId, teacherId]);

	const editTableRecordUrl = (index = null) => () => {
		if (appeal) return `/appraisal/${section}/edit_table/${appraisalItemId}/${index}/${teacherId}`;
		else return `/appraisal/${section}/edit_table/${appraisalItemId}${!!index ? `/${index}` : ''}`;
	};

	const remove = async () => {
		setLoading(true);
		const data = { id: selectedId };
		const result = await post('deleteAppraisalTableRecord', data);
		if (result) {
			await fetchRecord();
			setLoading(false);
			setIsRemoveModalOpen(false);
		}
	};

	const displayResult = (appraisalContentId) => {
		const itemContent = find(appraisalContentTree, { id: appraisalContentId });

		if (!!itemContent) {
			const deadline = appraisalLock ? appraisalLock.deadline : null;
			const levelOption = filter(appraisalLevelOptions, { appraisalContentId });
			const tableRecords = filter(compact(personalTableRecords), ({ submitDate }) => {
				if (deadline) {
					return moment(submitDate).isSameOrBefore(deadline, 'day');
				}
				return true;
			});

			const total = sumBy(tableRecords, itemContent.id);
			let contentMark = 0;
			forEach(levelOption, ({ mark, min }) => {
				if (total >= min && mark > contentMark) {
					return (contentMark = mark);
				}
			});

			return (
				<div>
					<span style={{ marginRight: '20px' }}>
						{itemContent.description}: {total}
					</span>
					<span>分數: {contentMark}</span>
				</div>
			);
		}
	};

	const displayLevelOption = (id) => {
		const options = filter(appraisalLevelOptions, { appraisalContentId: id });

		return (
			<div>
				{options.map(({ id, min, description, mark }) => (
					<div key={id} style={{ marginBottom: 5 }}>{`${min}或以上： ${mark}${
						description ? `（${description}）` : ''
					}`}</div>
				))}
			</div>
		);
	};

	const displayInfo = () => {
		const contents = appraisalContents.filter(({ addUp }) => !!addUp);

		return (
			<React.Fragment>
				{contents.map(({ id }) => (
					<Message key={id}>
						<Message.Header>
							{displayResult(id)}
							{appraisalLock.deadline && (
								<div style={{ marginTop: 5 }}>
									(總分只計算<b>{momentToDate(appraisalLock.deadline)}</b>或以前輸入的分數)
								</div>
							)}
						</Message.Header>
						<div style={{ marginTop: 20 }}>{displayLevelOption(id)}</div>
					</Message>
				))}
			</React.Fragment>
		);
	};

	const displayTableHeader = (appraisalContentTree, rowSpan, level = 1) => {
		return (
			<React.Fragment>
				<Table.Row>
					{appraisalContentTree.map(({ description, children }, index) => {
						if (children) {
							return (
								<TableHeaderCell key={index} colSpan={treeLength(children)}>
									{description}
								</TableHeaderCell>
							);
						} else {
							return (
								<TableHeaderCell key={index} rowSpan={rowSpan}>
									{description}
								</TableHeaderCell>
							);
						}
					})}
					<Table.HeaderCell rowSpan={rowSpan}>輸入日期</Table.HeaderCell>
					{level === 1 && !Boolean(appraisalLock.appraisalLock) && (
						<Table.HeaderCell rowSpan={rowSpan}>行動</Table.HeaderCell>
					)}
				</Table.Row>

				{appraisalContentTree.map(({ children }, index) => {
					return (
						<React.Fragment key={index}>
							{children && displayTableHeader(children, rowSpan - 1, level + 1)}
						</React.Fragment>
					);
				})}
			</React.Fragment>
		);
	};

	const displayRecord = (appraisalContentTree, record) => {
		return (
			<React.Fragment>
				{appraisalContentTree.map(({ id, needInput, inputType }, index) => {
					return (
						<Table.Cell key={index}>
							{Boolean(needInput) ? (
								<React.Fragment>
									{inputType === 'text' && record[id] && <React.Fragment>{textareaText(record[id])}</React.Fragment>}
									{inputType === 'date' && record[id] && (
										<React.Fragment>
											{record[id].map((date, index) => (
												<Label style={{ margin: '2px' }} key={index} color="teal">
													{date}
												</Label>
											))}
										</React.Fragment>
									)}
									{inputType !== 'text' && inputType !== 'date' && record[id] && (
										<React.Fragment>{record[id]}</React.Fragment>
									)}
								</React.Fragment>
							) : (
								<React.Fragment>-</React.Fragment>
							)}
						</Table.Cell>
					);
				})}
			</React.Fragment>
		);
	};

	const displayTableBody = () => {
		return (
			<React.Fragment>
				{personalTableRecords.length > 0 ? (
					<React.Fragment>
						{personalTableRecords.map((record, index) => {
							return (
								<Table.Row key={index}>
									{displayRecord(appraisalContentTree, record)}
									<Table.Cell collapsing>{momentToDate(record.submitDate)}</Table.Cell>
									{!Boolean(appraisalLock.appraisalLock) && (
										<Table.Cell collapsing>
											<Link to={editTableRecordUrl(index)}>
												<Button color="blue" icon="edit" circular />
											</Link>
											<Button
												color="red"
												icon="delete"
												onClick={() => {
													setIsRemoveModalOpen(true);
													setSelectedId(index);
												}}
												// onClick={this.modalToggle('isRemoveModalOpen', index)}
												circular
											/>
										</Table.Cell>
									)}
								</Table.Row>
							);
						})}
					</React.Fragment>
				) : (
					<EmptyTableMsg colSpan={appraisalContents.length + 1} />
				)}
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid>
				<GridRow>
					<GridColumn>
						<PageHeader
							title={appeal ? `覆核評估分數${!!teacherInfo.name ? `：${teacherInfo.name}` : ''}` : '個人評估項目'}
							subTitle={appeal ? '選擇需要覆核之評估項目' : '每年度個人需要進行之評估'}
							returnUrl={returnUrl}
						/>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<SegmentGroup>
							<Segment>
								<Grid stackable>
									<GridRow columns={3} verticalAlign="middle">
										<GridColumn>
											<Header size="small">
												{appraisalItem.yearName} : {appraisalItem.displayName}
											</Header>
										</GridColumn>
										<GridColumn>
											<Header size="small">遞交期限：{momentToDate(appraisalTarget.deadline)}</Header>
										</GridColumn>
										<GridColumn textAlign="right" className="mobile-align-left">
											{status === 'lock' && (
												<Label color="red" basic>
													已鎖定本年度分數
												</Label>
											)}
											{status === 'expire' && (
												<Label color="red" basic>
													已過限期
												</Label>
											)}
											{status === 'add' && (
												<Link to={editTableRecordUrl()}>
													<Button color="green" icon="add" content="新增記錄" circular />
												</Link>
											)}
										</GridColumn>
									</GridRow>
								</Grid>
							</Segment>
							<Segment padded="very" secondary>
								{displayInfo()}

								<div className="overflowX">
									<Table textAlign="center" sortable selectable celled unstackable>
										<Table.Header>
											{displayTableHeader(appraisalContentTree, treeLayer(appraisalContentTree))}
										</Table.Header>
										<Table.Body>{displayTableBody()}</Table.Body>
									</Table>
								</div>
							</Segment>
						</SegmentGroup>
					</GridColumn>
				</GridRow>
			</Grid>
			<ConfirmModal
				open={isRemoveModalOpen}
				description="確定刪除此記錄？"
				cancel={() => setIsRemoveModalOpen(false)}
				confirm={remove}
			/>
		</React.Fragment>
	);
};

export default TableList;
