import { groupBy } from 'lodash';
import { arrayToOption, arrayToSearch } from '../../Helper/Helper';

export const SECTIONDATA = [
  {
    dbName: 'chiName', displayName: '學生姓名', noHeader: true, subFields: [
      '中文姓名',
      '英文姓名',
    ]
  },
  { dbName: 'classCode', displayName: '班別（按年度）' },
  { dbName: 'classNo', displayName: '學號（按年度）' },
  { dbName: 'strn', displayName: 'STRN' },
  { dbName: 'gender', displayName: '性別' },
  { dbName: 'dob', displayName: '出生日期' },
  {
    dbName: 'consentField', noHeader: true, displayName: 'SEMIS家長同意書', subFields: [
      '家長同意',
      '簽署日期',
    ]
  },
  { dbName: 'nonChinese', displayName: '非華語學生' },
  {
    dbName: 'senType', noHeader: true, displayName: '特殊教育需要類別', subFields: [
      '成績稍遜',
      '特殊學習困難（讀寫障礙）',
      '智障',
      '視障',
      '聽障',
      '肢體傷殘',
      '自閉症',
      '注意力不足/過度活躍症',
      '言語障礙',
      '精神病',
      '其他',
    ]
  },
  { dbName: 'hkcasp', displayName: '學前兒童發展進度綜合報告' },
  {
    dbName: 'senReport', noHeader: true, displayName: '評估報告', subFields: [
      '評估機構',
      '評估日期',
      '評估結論',
      '評估後建議',
      '上報',
      '仍有報告需上報',
    ]
  },
  {
    dbName: 'earlyP1', noHeader: true, displayName: '及早識別和輔導有學習困難的小一學生', subFields: [
      '年度',
      '結果',
    ]
  },
  { dbName: 'gradeDelay', noHeader: true, displayName: '學習程度測量卷3.0成績（落後年級）' },
  {
    dbName: 'classAdj', displayName: '課堂上的支援', subFields: [
      '座位安排',
      '座位安排|坐較前位置，靠近老師',
      '座位安排|坐近能力較佳或較專注的同學',
      '朋輩支援',
      '朋輩支援|安排同學提醒學生自我檢查或執拾物品',
      '朋輩支援|安排同學提醒學生專心上課和學習',
      '輔助提示',
      '輔助提示|提供清晰課堂常規指示',
      '輔助提示|運用視覺策略協助學生跟從常規',
      '輔助提示|給予一致的口頭提示',
      '輔助提示|運用合適及/或口號提示恰當行為',
      '課堂安排',
      '課堂安排|採用小步驟教學',
      '課堂安排|運用多感官/多媒體教學',
      '課堂安排|運用增加互動的教學策略',
      '具體明確的回饋 (獎勵計劃)',
      '其他',
    ]
  },
  {
    dbName: 'others', displayName: '課堂外的支援', subFields: [
      '個別獎勵計劃',
      '個別學習計劃',
      '讀寫訓練',
      'ASD訓練',
      '社交小組',
      '專注力小組',
      '情緒訓練',
      '言語治療',
      '非華語生學習小組',
      '新來港學童學習小組',
      '長期心智成長訓練',
      '其他',
    ]
  },
  {
    dbName: 'homeworkAdj', displayName: '家課調適', subFields: [
      '減少功課量',
      '減少功課量|中文抄寫',
      '減少功課量|英文抄寫',
      '減少功課量|數學計算題',
      '減少改正分量',
      '減少改正分量|中文',
      '減少改正分量|英文',
      '減少改正分量|數學',
      '減少改正分量|常識',
      '減少寫作字數',
      '容許隔行/用較大格的工作紙/功課簿完成作業',
      '容許隔行/用較大格的工作紙/功課簿完成作業|中文',
      '容許隔行/用較大格的工作紙/功課簿完成作業|英文',
      '容許隔行/用較大格的工作紙/功課簿完成作業|數學',
      '容許隔行/用較大格的工作紙/功課簿完成作業|常識',
      '把工作紙字體放大',
      '豁免做較艱深的功課',
      '豁免做較艱深的功課|註明',
      '批改時，如學生能寫出正確答案，在錯別字方面作較寬鬆處理',
      '批改時，如學生能寫出正確答案，在錯別字方面作較寬鬆處理|中文',
      '批改時，如學生能寫出正確答案，在錯別字方面作較寬鬆處理|數學',
      '批改時，如學生能寫出正確答案，在錯別字方面作較寬鬆處理|常識',
      '課業內提供多些示例',
      '課業內提供多些示例|中文',
      '課業內提供多些示例|英文',
      '課業內提供多些示例|數學',
      '課業內提供多些示例|常識',
      '容許學生用其他方法顯示答案，如圈出、數字顯示',
      '容許學生用其他方法顯示答案，如圈出、數字顯示|中文',
      '容許學生用其他方法顯示答案，如圈出、數字顯示|英文',
      '容許學生用其他方法顯示答案，如圈出、數字顯示|數學',
      '容許學生用其他方法顯示答案，如圈出、數字顯示|常識',
      '容許學生用其他方法顯示答案，如圈出、數字顯示|註明',
      '其他',
      '家長同意',
    ]
  },
  {
    dbName: 'assessAdj', displayName: '評估調適', subFields: [
      '默書調適',
      '默書調適|提早通知默書範圍',
      '默書調適|使用創意默書方法加強默寫的趣味性',
      '默書調適|減少要溫習的範圍',
      '默書調適|減少要溫習的範圍|中文',
      '默書調適|減少要溫習的範圍|英文',
      '默書調適|給予學生額外時間默書',
      '默書調適|用填充方式代替默寫整段課文',
      '默書調適|用給分制取代扣分制',
      '默書調適|讓學生自訂幾個「信心詞語」（可取得雙倍分數的詞語）',
      '默書調適|其他',
      '默書調適|家長同意',
      '測考調適',
      '測考調適|容許學生用其他方法顯示答案，如圈出、數字顯示',
      '測考調適|容許學生用其他方法顯示答案，如圈出、數字顯示|註明',
      '測考調適|放大試卷',
      '測考調適|單面影印試卷',
      '測考調適|容許考核期間，中途小休',
      '測考調適|抽離特別室應考',
      '測考調適|特別座位安排',
      '測考調適|特別座位安排|註明',
      '測考調適|於紙筆考試加時5至12分鐘',
      '測考調適|進行測考評估時(包括口試)，給予不多於25%的評估時間',
      '測考調適|不要因學生的字體欠工整而扣分',
      '測考調適|安排學校人員為學生讀出試卷',
      '測考調適|安排學校人員為學生讀出試卷|中文',
      '測考調適|安排學校人員為學生讀出試卷|英文',
      '測考調適|安排學校人員為學生讀出試卷|數學',
      '測考調適|安排學校人員為學生讀出試卷|常識',
      '測考調適|其他',
      '測考調適|家長同意',
    ]
  },
  {
    dbName: 'parentCoop', displayName: '家長配合', subFields: [
      '與孩子進行伴讀',
      '使用「每日評估、記錄及施教」方法，幫助孩子認讀及默寫（中/英）詞語',
      '訓練孩子個人自理能力，如收拾文具和書包等',
      '利用多媒體或網上資源協助孩子學習',
      '利用多媒體或網上資源協助孩子學習|註明',
      '安排孩子女參加活動提升自信心',
      '安排孩子女參加活動提升自信心|註明',
      '其他',
    ]
  },
  {
    dbName: 'prelessonTutor', displayName: '課前輔導班', subFields: [
      '中文',
      '英文',
      '數學',
      '家長同意',
    ]
  },
  {
    dbName: 'reinforceTutor', displayName: '加強輔導班', subFields: [
      '中文',
      '英文',
      '數學',
      '家長同意',
    ]
  }
];

export const financialOptions = arrayToOption([
  '沒有申請任何資助',
  '綜緩',
  '全額書津',
  '半額書津',
]);

/**
 * Add incomplete className if parameter is null for showing red border
 * @param {any} x data to be checked
 */
export const genFieldClassName = (x) => 'form-group-margin'+(x==null?' incomplete':'');

const treatRawData = (rawData) => {
  return groupBy(rawData.map((rawData) => {
    rawData.value=rawData.checked === null ? rawData.text : !!rawData.checked;
    return rawData;
  }), 'level1');
}

export const treatedDataToAggregateData = (data) => {
  const adjustments = {};
  for(let SECTION of SECTIONDATA.slice(13)){
    const {displayName, subFields} = SECTION;
    adjustments[displayName] = {};
    const sectionFields = data[displayName];
    const fieldLookup = (sectionFields||[]).reduce((prev,cur)=>({
      ...prev,
      [[cur.level2, cur.level3, cur.level4].filter(x=>x).join("|")]: cur.value,
    }), {});

    adjustments[displayName].value = fieldLookup[""];

    for(let field of subFields){
      const arr = field.split("|");
      let j=0;
      if(arr[arr.length-1] === '家長同意'){
        arr[arr.length-1] = '家長同意與否';
      }
      let obj = adjustments[displayName];
      while(j<arr.length){
        if(!obj.hasOwnProperty('subFields')){
          obj.subFields={};
        }
        if(!obj.subFields.hasOwnProperty(arr[j])){
          obj.subFields[arr[j]] = {}
        }
        obj = obj.subFields[arr[j]];
        if(arr[j] === '其他'){
          let specify = arr.concat('其他請註明').join("|");
          obj.subFields={
            '其他請註明': {value: fieldLookup.hasOwnProperty(specify)?fieldLookup[specify] : null}
          }
        }
        j++;
      }
      obj.value = fieldLookup.hasOwnProperty(arr.join("|")) ? fieldLookup[arr.join("|")] : null;
    }
  }
  return adjustments;
}

/**
 * Build tree once and for all, mutates rawData
 * @param {object[]} rawData 
 */
export const rawRecordDataToDynamicStates = (rawData, senGroup = []) => {
  const senGroupLookup = senGroup.reduce((prev, cur)=>({
    ...prev,
    [cur.name]: cur.id,
  }), {});
  const senGroups = [];

  const data = treatRawData(rawData);

  const adjustments = treatedDataToAggregateData(data);

  const unmatchedGroups = [];

  Object.entries(adjustments['課堂外的支援'].subFields).forEach(([groupName, field])=>{
    if(field.value){
      if(groupName === '其他'){
        const others = field.subFields['其他請註明'].value.split(/\s*[,，、]\s*/);
        for(let candidate of others){
          if(senGroupLookup.hasOwnProperty(candidate)){
            senGroups.push(senGroupLookup[candidate])
          }else{
            unmatchedGroups.push(candidate);
          }
        }
      }else{
        if(senGroupLookup.hasOwnProperty(groupName)){
          senGroups.push(senGroupLookup[groupName]);
        }else{
          unmatchedGroups.push(groupName);
        }
      }
    }
  });
  delete adjustments['課堂外的支援'].subFields;
  return [adjustments, senGroups, unmatchedGroups];
};

const traverse = (fieldName, subField, result) => {
  result[fieldName] = subField.value;
  if(subField.hasOwnProperty('subFields')){
    Object.entries(subField.subFields).forEach(([subName, subField])=>{
      traverse(fieldName+"|"+subName, subField, result);
    });
  }
}

export const dynamicFieldToSaveFormat = (dynamicData) => {
  const results = {};
  Object.entries(dynamicData).forEach(([displayName, field])=>{
    results[displayName] = field.value;
    if(field.hasOwnProperty('subFields')){
      Object.entries(field.subFields).forEach(([subName, subField])=>{
        traverse(displayName+"|"+subName, subField, results);
      })
    }
  })
  return results;
}
  
export const organisationOptions = arrayToSearch([
  '保良局區制言語治療服務隊',
  '尤德夫人兒童體能智力測驗中心(沙田)',
  '雅麗氏何妙齡那打素醫院:兒童心理科',
  '協康會',
  '香港耀能協會',
  '匡智會匡晴計劃',
  '威爾斯親王醫院言語治療部',
  '明愛康復服務',
  '保良局傲翔計劃',
  '庭恩兒童中心',
  '精神專業聯盟',
  '思卓言語及吞嚥治療中心',
  '中九龍兒童體能智力測驗中心(沙田)',
  '教育局',
  'Student Health Service, Department of Health',
  '循道衛理觀塘社會服務處復康服務',
  '醫院管理局',
  'The Alpha Clinic',
  '粉嶺兒童體能智力測驗中心',
  '香港耀能協會隆亨幼兒中心早期教育及訓練服務',
  '優質治療及教育中心QTEC',
  '天主教領島學校言語治療服務',
  'Audiological Consultation Report',
  '衛生署藍田健康評估中心',
]);

export const assessmentResultOptions = arrayToSearch([
  '自閉症譜系障礙',
  '說話組織及咬字能力較弱',
  '發音:咬字不清',
  '語言理解、表達發展緩慢',
  '語言障礙',
  'ADHD',
  '有潛在特殊學習困難',
  '語言表達發展緩慢 、咬字不清',
  '智力障礙(輕度至有限)',
  '言語障礙',
  '專注力問題',
  '專注力不足/過度活躍症',
  '早期讀寫障礙',
  '社交能力較弱',
  '語言表達較弱',
  '專注力較弱及較活躍',
  '對立反抗問題',
  '懷疑自閉症譜系障礙',
  '大肌肉能力弱(跑跳及平衡力較弱)',
  '有限智能',
  '小肌肉、抄寫技巧及自理較弱',
  '嚴重溝通障礙',
  '輕度溝通障礙',
  '認讀文字能力較弱',
  '中度溝通障礙',
  '認讀及默寫文字力顯著較弱',
  '懷疑專注力不足/過度活躍',
  '語言能力較弱',
  'Specific Learning Difficulties in Reading and Writing',
  'Misarticulation (exclude stuttering)',
  'Attention-Deficit Hyperactivity Disorder(Predominantly inattentive presentation), with hyperactivity/impulsivity features',
  '自閉症',
  '專注控制較弱',
  'autistic spectrum disorder and attention deficit hyperactivity disorder',
  '自制力較弱',
  '特殊學習困難',
  '衝動行為',
  '對抗性反叛行為問題',
  'An overall mild hearing impairment in both ears',
  '默寫文字困難',
  '認寫英文字母較弱',
  '語言能力中下水平',
  '發音輕微障礙',
  '小肌肉及抄寫能力較弱',
  '認讀及默寫能力缺損',
  '溝通能力適齡',
]);

export const assessmentSuggestionOptions = arrayToSearch([
  '加強輔導服務 (行為及情緒方面)',
  '教育心裡學家跟進',
  '繼續接受言語治療',
  '轉介言語治療',
  '建議提供可行的功課及考測調適',
  '教師在課堂上特別關顧',
  '加強輔導服務 (學習、行為及情緒方面)',
  '及早識別學習困難計劃',
  '運用多感官學習',
  '教授字結構及分拆部件',
  '家長伴讀',
  '加入讀寫小組、測考調適',
  '加強輔導服務 (學習、行為及情緒方面、言語治療服務)',
  '安排功課及測考調適',
  '行為獎勵計劃',
  '接受物理治療訓練',
  '朋輩協助',
  '批改功課時著重內容而非字體/減少抄寫的功課量 (重覆的抄寫、騰文改正)',
  '課堂內教與學的額外支援',
  '加強輔導教學',
  '特別考試安排',
  '讀寫技巧訓練',
  '支援計劃',
  '朋輩或家長義工輔導',
  '課堂及教學方面的調適',
  '家課調適 (減少家課量/減少抄寫家課/減少改正抄寫)',
  '評估調適 (默書調適/考試加時)',
  '有關讀寫技巧的訓練',
  '與家長共同訂立支援計劃',
  'sit close to the teacher(s) or near the sound source in class',
  'face his communication partners whenever possible',
  'receive regular audiological review',
  '職業治療',
  'arranging central front seat in classes, providing extra time and separate space in tests and examinations and reduction in homework load',
  '評估調適(斁書調適/考試加時及讀卷-非語文科)',
  '無需接受言語治療',
  '小組訓練',
  '為讀寫較弱兒童而設的教學服務',
  '評估調適 (包括減默書範圍、以給分代替扣分制、加時、給予讀卷安排、容許學生以特別的方式答題)',
  '按能力減少抄寫的功課量',
  'reduce amount of homework',
  'work on stress coping',
  '輔導人員跟進 (包括情緒及社交訓練)',
]);