import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {pick, get as _get} from 'lodash';

import {
  PageHeader,
  ConfirmModal,
  EmptyTableMsg,
  FullscreenDimmer,
  BlockerPrompt,
  FlexibleTextField,
  AggregateNumber,
  MySelect
} from '../../../Components';

import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions,
  defaultYear,
  threeYearPlanAndWhichYearFromYear,
  whetherReadOnlyForReport,
  numRound,
  toConcernAggregate
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

import { Grid, Form, Button, Table, Segment, Header } from 'semantic-ui-react';

class Report_Concern extends Component {
  constructor(props) {
    super(props);

    this.meetTargetLookup = {
      all: "完全達標 (4)",
      most: "大部分達標 (3)",
      part: "部分達標 (2)",
      no: "未達標 (1)",
    }

    this.TYPs = [];
    this.selectedTYP = '';
    this.whichYear = '';
    this.selectStyles = {minWidth: 'unset', marginTop: '0.25rem'};

    this.meetTargetOptions = Object.entries(this.meetTargetLookup).map(([value, text])=>({value,text}));

    this.actualId = null;

    this.state = {
      finishedLoading: false,
      dimmerOpen: false,
      isBlocking: false,
      readOnly: true,

      year: [], // for select fields
      yearInfo: {}, //dictionary for info search
      yearId: '', // variable name changed for convenience

      concerns: [],
      overall_fb_and_fu: null,

      isSaveModalOpen: false,
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      this.setStateAsync({
        finishedLoading: true
      });
    }catch(err){
      console.log('did mount');
      console.log(err);
    }
  }

  fetch = async () => {
    try{
      //get year info
      const [yearInfo, TYPs] = await Promise.all([
        get('getYear'),
        get('getTYP'),
      ]);
      const yId = this.state.yearId;

      let selectedTYP, whichYear;

      if(yId){
        [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs, yId);
      }else{
        [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs);
      }

      const yearId = yId ? yId : defaultYear(yearInfo);

      this.TYPs = TYPs;
      this.selectedTYP = selectedTYP;
      this.whichYear = whichYear;

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId,
      });

      await this.setStateAsync({
        readOnly: whetherReadOnlyForReport(this.state.yearInfo, this.state.yearId)
      });

      await this.fetchOtherData();

    }catch(err){
      console.log('Error when fetching 1 year concerns');
      console.log(err);
    }
  }

  fetchOtherData = async () => {
    try{

      //retrieve and calculate average
      let aggregate = await get(`get1yearSurveyResultAggregate/${+this.state.yearId}`);
      aggregate = toConcernAggregate(aggregate);

      const [concerns, oneYearConcerns] = await Promise.all([
        get(`get3YearConcern/${+this.selectedTYP}`),
        get(`get1YearConcern/${+this.state.yearId}`)
      ]);

      const quickMap = new Map(concerns.map((x,i)=>[x.id, i]));

      oneYearConcerns.forEach(oc=>{
        if(!oc.meetTarget && aggregate[oc.concernId]){
          oc.meetTarget = ['dafuq','no','part','most','all'][numRound(aggregate[oc.concernId], 0)]
        }
        concerns.aggregate = aggregate[oc.concernId];
        concerns[quickMap.get(oc.concernId)].oneYearConcern = oc;
      })

      for(let c of concerns){
        if(!c.oneYearConcern){
          c.oneYearConcern = {id: null, }
        }
      }

      await this.setStateAsync({concerns});

      const oneYearReport = await get(`get1YearReport/${+this.state.yearId}`);
      if(oneYearReport.length)
        this.actualId = oneYearReport[0].id||null;

      await this.setStateAsync({
        overall_fb_and_fu: oneYearReport.length?oneYearReport[0].overall_fb_and_fu:null
      });

    }catch(err){
      console.log('Error when fetching Annual Concern and Report Data');
      console.log(err);
    }
  }

  feedbackChange = (event, data) => {
    this.setStateAsync({
      overall_fb_and_fu: inputHandler('text', data),
      isBlocking: true
    })
  }

  /* year change */
  yearChange = async (event, data) => {
    if(this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要切換年度嗎？")){
        return;
      }
    }
    try{
      let value = inputHandler('select', data);
      const { yearId } = this.state;
      if (yearId !== value) {
        await this.setStateAsync({
          yearId: value,
          finishedLoading: false,
        });
        await this.fetch();
      }
    }catch(err){
      console.log("Error when changing year for One Year Evaluation");
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
      isBlocking: false,
    });
  }

  /* modal toggle */
  saveModalToggle = () => {
    this.setStateAsync({
      isSaveModalOpen: !this.state.isSaveModalOpen
    });
  }

  /* save record */
  save = async () => {
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      let errored = false;

      let result = await post('batchEdit1YearConcern', {
        yearId: this.state.yearId,
        oneYearConcerns: this.state.concerns.map(x=>x.oneYearConcern)
      });

      if(!result.status){
        errored = true;
        alert("修改周年關注項目資料時發生問題");
      }

      result = await post('edit1YearReport', {
        ...pick(this.state, ['overall_fb_and_fu', 'yearId']),
        id: this.actualId||undefined
      });

      if(!result.status){
        errored = true;
        alert("修改周年整體回饋與跟進時發生問題");
      }
      if(!errored){
        this.setStateAsync({isBlocking: false,})
        await this.fetchOtherData();
      }

    }catch(err){
      console.log("error when editing concern data:");
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
      isSaveModalOpen: false,
      dimmerOpen: false,
    });
  }
  
  concernInfoChange = (event, data) => {
    // concern id
    const id = +event.target.closest('tr').dataset.concernId;
    const target = event.target.closest('textarea, .ui.selection');
    this.setStateAsync({
      isBlocking: true,
      concerns: this.state.concerns.map(x=>x.id===id?{
        ...x,
        oneYearConcern: {
          ...x.oneYearConcern,
          concernId: id,
          [target.attributes.name.nodeValue]: inputHandler(target.nodeName==='TEXTAREA'?'text':'select', data)
        }
      }:x)
    })
  }

  render() {
    const {
      isSaveModalOpen,
      finishedLoading,
      readOnly,
      isBlocking,
      dimmerOpen,

      year,
      concerns,

      overall_fb_and_fu,

      yearId,
    } = this.state;

    const flexibleCellProps = {};

    if(readOnly){
      flexibleCellProps.textAlign = "left";
    }

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                title='周年報告'
                subTitle='檢視及修改周年計劃中關注項目的達標程度及跟進方法等資料'
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Field inline>
                  <label>周年</label>
                  <MySelect
                    disabled={!finishedLoading}
                    options={year}
                    value={yearId}
                    onChange={this.yearChange}
                  />
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

          <Grid.Row>
            <Grid.Column>
            <Form>
              <Segment.Group>
                <Segment>
                  <Table textAlign='center' celled unstackable fixed>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={3}>關注事項及達標程度</Table.HeaderCell>
                        <Table.HeaderCell width={6}>成就</Table.HeaderCell>
                        <Table.HeaderCell width={5}>反思</Table.HeaderCell>
                        <Table.HeaderCell width={2}>跟進方法</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {finishedLoading ? (
                        concerns.length ? (concerns.map(concern => {
                          let subTopic = "", trueTitle = "---";
                          if (concern) {
                            subTopic = concern["subTopic" + (+this.whichYear + 1)];
                            trueTitle = `${concern.title}${subTopic ? ` (${subTopic})` : ''}`;
                          }
                          let c = concern.oneYearConcern;

                          const aggregate = _get(concern, 'aggregate') !== undefined ? numRound(concern.aggregate, 2) : undefined;

                          return (<Table.Row key={concern.id} data-concern-id={concern.id}>
                            <Table.Cell style={{overflow: 'visible'}}>
                              {trueTitle}<br/>
                              <AggregateNumber value={aggregate}/>
                              {readOnly?(
                                <span className='red'> ({this.meetTargetLookup[c.meetTarget]||'-'})</span>
                              ):(
                                <Form.Field>
                                  <MySelect
                                    disabled={!finishedLoading}
                                    clearable
                                    value={c.meetTarget}
                                    onChange={this.concernInfoChange}
                                    name='meetTarget'
                                    options={this.meetTargetOptions}
                                    style={this.selectStyles}
                                  />
                                </Form.Field>
                              )}
                            </Table.Cell>
                            <Table.Cell {...flexibleCellProps}>
                              <FlexibleTextField
                                multiline={true}
                                readOnly={readOnly}
                                value={c.pro}
                                name='pro'
                                disabled={!finishedLoading}
                                onChange={this.concernInfoChange}
                              />
                            </Table.Cell>
                            <Table.Cell {...flexibleCellProps}>
                              <FlexibleTextField
                                multiline={true}
                                readOnly={readOnly}
                                value={c.con}
                                name='con'
                                disabled={!finishedLoading}
                                onChange={this.concernInfoChange}
                              />
                            </Table.Cell>
                            <Table.Cell {...flexibleCellProps}>
                              <FlexibleTextField
                                multiline={true}
                                readOnly={readOnly}
                                value={c.followUp}
                                name='followUp'
                                disabled={!finishedLoading}
                                onChange={this.concernInfoChange}
                              />
                            </Table.Cell>
                          </Table.Row>);
                        })) : <EmptyTableMsg colSpan='4' msg='周年計劃不存在，請確認已建立三年計劃' />
                      ): <EmptyTableMsg colSpan='4' msg='載入資料中' />
                    }
                    </Table.Body>
                  </Table>
                </Segment>
                <Segment>
                  <Header size="medium">整體回饋與跟進</Header>
                  <FlexibleTextField
                    multiline={true}
                    readOnly={readOnly}
                    value={overall_fb_and_fu}
                    disabled={!finishedLoading}
                    onChange={this.feedbackChange}
                  />
                </Segment>
                {!readOnly && (<Segment>
                  <Form.Field
                    control={Button}
                    disabled={!finishedLoading}
                    color="green"
                    onClick={this.saveModalToggle}
                  >儲存</Form.Field>
                </Segment>)}
              </Segment.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {dimmerOpen?<FullscreenDimmer isLoading={true}/>:
        <ConfirmModal
          open={isSaveModalOpen}
          description='確定儲存周年報告關注項目資料？'
          cancel={this.saveModalToggle}
          confirm={this.save}
          confirmIcon='check'
          confirmText='儲存'
        />}
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(Report_Concern);