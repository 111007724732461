import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RouterConfig from './RouterConfig';
import UnAuthRoute from './Route';
import AuthRoute from './AuthRoute';
import Login from '../Modules/Login/login';
import ModuleLayout from '../Layout/ModuleLayout';

const Router = () => (
  <BrowserRouter>
    <Switch>
      {/* automatically use Plain Layout */}
      <AuthRoute
        path='/'
        exact={true}
        antiLogin={true}
        redirectTo='/panel'
      >
        <Login/>
      </AuthRoute>
      <Route path="*">
        <ModuleLayout>
          <Switch>
            {RouterConfig.map(({ Content, path, exact, auth, wide }) => {
              if (auth === false) {
                return <UnAuthRoute
                  key={path || '404'}
                  path={path}
                  exact={exact}
                >
                  <Content />
                </UnAuthRoute>
              }
              return (<AuthRoute
                key={path || '404'}
                path={path}
                exact={exact}
                wide={wide}
              >
                <Content />
              </AuthRoute>)
            })}
          </Switch>
        </ModuleLayout>
      </Route>
    </Switch>
  </BrowserRouter>
)

export default Router;