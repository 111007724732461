/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';

/* import helper */
import { get, ping } from '../../../Helper/ApiHelper';
import { buildTree } from '../../../Helper/TreeHelper';

/* import lodash */
import { uniq } from 'lodash';

/* import semantic-ui element */
import { Grid, Button, Segment, List, Label } from 'semantic-ui-react';

class Item extends Component {
    constructor(props) {
        super(props);
        ping(this);
    }

    state = {
        dimmerOpen: true,

        yearId: this.props.match.params.yearId,
        teacherId: this.props.match.params.teacherId,
        teacherInfo: {},

        yearInfo: {},
        appraisalLock: {},
        appraisalItem: [],
        personalAppraisalItem: []
    }

    fetch = async () => {
        const { yearId, teacherId } = this.state;
        const [yearInfo, appraisalLock, teacherInfo, appraisalItem, personalAppraisalItem] = await Promise.all([
            get('getOneYear/'+yearId),
            get('getOneAppraisalLock/'+yearId),
            get('getOneTeacher/'+teacherId),
            get('getAllAppraisalItem/'+yearId),
            get('getPersonalAppraisalItem/'+yearId+'/'+teacherId)
        ]);
        this.setState({
            yearInfo: yearInfo.length > 0? yearInfo[0] : {},
            appraisalLock: appraisalLock.length > 0? appraisalLock[0] : {},
            teacherInfo: teacherInfo.length > 0? teacherInfo[0] : {},
            appraisalItem: buildTree(appraisalItem),
            personalAppraisalItem: uniq(personalAppraisalItem.map(({appraisalItemId}) => { return appraisalItemId; }))
        });
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    componentDidMount = async () => {
        this.mounted = true;
        await this.fetch();
        this.setState({dimmerOpen: false});
    }

    /* render appraisal item */
    display = (appraisalItem) => {
        const { personalAppraisalItem, appraisalLock } = this.state;
        return (
            <Fragment>
                {appraisalItem.map(({id, displayName, tableFormat, children}) => {
                    return (
                        <Fragment key={id}>
                            {children?
                                <Fragment>
                                    {this.display(children)}
                                </Fragment>
                                :
                                <Fragment>
                                    {personalAppraisalItem.indexOf(id) >= 0 && 
                                        <Segment>
                                            <Grid columns={2} stackable >
                                                <Grid.Row verticalAlign='middle'>
                                                    <Grid.Column>
                                                        <b>{displayName}</b>
                                                    </Grid.Column>
                                                    <Grid.Column textAlign='right'>
                                                        <Button onClick={this.editContent(id, tableFormat)} color='blue' icon={Boolean(appraisalLock.appraisalLock)? 'eye' : 'edit'} circular />  
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    }
                                </Fragment>
                            }
                        </Fragment>
                    )
                })}
            </Fragment>
        )
    }

    /* navigate to edit content form */
    editContent = (id, tableFormat) => () => {
        const { teacherId } = this.state;
        if(tableFormat) {
            this.props.history.push('/appraisal/appeal/table_list/' + id + '/' + teacherId);
            return;
        }
        this.props.history.push('/appraisal/appeal/form_list/' + id + '/' + teacherId);
    }
    
    render() {
        const { dimmerOpen, teacherInfo, personalAppraisalItem, appraisalItem, yearInfo, appraisalLock } = this.state;
        return (
            <Fragment>
                {dimmerOpen? 
                    <FullscreenDimmer active={dimmerOpen} isLoading={true} />
                    :
                    <Fragment>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <PageHeader title={teacherInfo.name? '覆核評估分數: '+teacherInfo.name : '覆核評估分數'} subTitle='選擇需要覆核之評估項目' prevPage={() => this.props.history.push('/appraisal/appeal')}  /> 
                                </Grid.Column>                                
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Segment.Group>                                    
                                        <Segment className='bold larger-font'>
                                            <Grid stackable>
                                                <Grid.Row verticalAlign='middle' columns={2}>
                                                    <Grid.Column>{yearInfo.displayName}</Grid.Column>
                                                    <Grid.Column textAlign='right'>
                                                        {Boolean(appraisalLock.appraisalLock) &&
                                                            <Label color='red' basic>已鎖定本年度分數</Label>
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>  
                                        <Segment padded='very' secondary>
                                            {personalAppraisalItem.length > 0?
                                                <List>
                                                    {this.display(appraisalItem)}
                                                </List>
                                                :
                                                <Fragment>
                                                    <Segment textAlign='center' basic>沒有評估項目</Segment>
                                                </Fragment>
                                            }
                                        </Segment>
                                    </Segment.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default withRouter(Item);