/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	FullscreenDimmer,
	BlockerPrompt,
	MySelect,
  Buttons
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler, genGradeSet, genStudentOptions } from '../../../Helper/FormHelper';
import { selectOptions, getTeacherOptions } from '../../../Helper/Helper';
import { timeToStringFormat, displayDate, momentToDate, momentToDbFormat, momentToHtmlInput } from '../../../Helper/TimeHelper';

/* import lodash */
import { map, find, findIndex, pick, keyBy } from 'lodash';

import {fromBlankAsync} from 'xlsx-populate';
import { exportExcel } from '../../../Helper/ExcelHelper';
import ActivityStudentTable from './ActivityStudentTable';

/* import date input */
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Segment, Form, TextArea, Label, Icon, Input, Checkbox } from 'semantic-ui-react';

/* import const */
import { weekdayOptions, excelFormats, gradeOptions } from '../../../Const/Const';

class ActivityEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: true,
      isBlocking: false,
      teacherOptions: [],
      subjectOptions: [],
      locationOptions: [],
      leaveMethodOptions: [],
      studentOptions: [],
      students: [],

      activityId: this.props.match.params.activityId || null,
      activity: {
        id: this.props.match.params.activityId || null,
        yearId: this.props.match.params.yearId,
        name: null,
        weekday: [],
        startTime: null,
        endTime: null,
        locationId: null,
        subjectId: null,
        remark: null
      },
      activityTeachers: [],
      activityStudents: [],
      activityClasses: [],

      dateSelected: new Date(),

      activityStudentHeader: ['班別', '姓名', '學號', '放學方式[自行回家/家長接送/返回補習班/其他/不適用]', '放學方式其他請註明(如有)']
    }

    this.fileInputRef = React.createRef();

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => <ErrorLabel message={message} />,
      messages: {
        default: '請輸入資料'
      }
    });

    this.expand = {flex: 1};
    this.newcount = 0;
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchActivityData = async () => {
    const {activityId} = this.props.match.params;
    try{
      const [activity, activityTeachers, activityStudents, activityClasses] = await Promise.all(['getOneActivity','getActivityTeacher','getActiveActivityStudent','getActivityClass', ].map(x=>get(x, [activityId])));

      if(activity[0]) {
        if(activity[0].weekday){
          activity[0].weekday = String(activity[0].weekday).split("");
        }else{
          activity[0].weekday = [];
        }
        activity[0].subjectId = +activity[0].subjectId;
      }
      for(let aC of activityClasses){
        aC.date = momentToHtmlInput(aC.date);
      }

      await this.setStateAsync({
        activity: activity[0] || {},
        activityTeachers: map(activityTeachers, 'teacherId'),
        activityStudents,
        activityClasses
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching activity', err)}
  }

  fetchOptions = async () => {
    const {yearId} = this.props.match.params;
    try{
      const [teacherOptions, subjects, locations, leaveMethods, students, year] = await Promise.all([
        getTeacherOptions(yearId),
        get('getSubject'),
        get('getLocation'),
        get('getLeaveMethod'),
        get('getStudentEssentialInfo/' + yearId),
        get('getOneYear/'+yearId)
      ]);

      teacherOptions.push({text: "外聘導師", value: null});

      await this.setStateAsync({
        teacherOptions,
        subjectOptions: selectOptions(subjects, 'displayName', 'id').concat({text: '跨學科', value: 0, key: -1}),
        locationOptions: selectOptions(locations, 'name', 'id'),
        leaveMethodOptions: selectOptions(leaveMethods, 'name', 'id'),
        students,
        year: year.length?year[0]:undefined
      });

      await this.setStateAsync({
        studentOptions: genStudentOptions(this.state.activity, this.state.students)
      });

    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity', err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    if (this.props.match.params.activityId) {
      await this.fetchActivityData();
    }
    await this.fetchOptions();
    this.setStateAsync({ dimmerOpen: false });
  }

  activityChange = (event, data) => {
    let inputData;
    //special case for remove icon in semantic-ui-react select
    if(event.target.tagName==='I'){
      inputData = event.target.closest('.ui.dropdown, textarea, button').dataset;
    }else{
      inputData = event.target.closest('.ui, textarea, button').dataset;
    }
    const {inputType, stateName} = inputData;
    let result = inputHandler(inputType, data);

    this.setStateAsync({
      isBlocking: true,
      activity: {
        ...this.state.activity,
        [stateName]: result
      }
    }).then(()=>{
      if(stateName.indexOf('grade_')>-1){
        const data = {studentOptions: genStudentOptions(this.state.activity, this.state.students)};
        if(!this.state.activity[stateName]){
          const gradeSet = genGradeSet(this.state.activity);
          const studentLookup = new Map(this.state.students.map(x=>([x.id, x.gradeId])));
          data.activityStudents = this.state.activityStudents.filter(x=>{
            if(!x.studentId || !studentLookup.has(x.studentId)) return false;
            return gradeSet.has(studentLookup.get(x.studentId));
          });
        }
        this.setStateAsync(data);
      }
    });
  }

  activityStartTimeChange = (event, data) => {
    this.setStateAsync({
      isBlocking: true,
      activity: {
        ...this.state.activity,
        startTime: inputHandler('time', data)
      }
    });
  }

  activityEndTimeChange = (event, data) => {
    this.setStateAsync({
      isBlocking: true,
      activity: {
        ...this.state.activity,
        endTime: inputHandler('time', data)
      }
    });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  activityTeacherChange = (event, data) => {
    let activityTeachers = inputHandler('select', data);
    this.setStateAsync({ activityTeachers, isBlocking: true });
  }

  dateChange = (event, data) => {
    this.setStateAsync({
      dateSelected: inputHandler('date', data), isBlocking: true
    });
  }

  addActivityClass = () => {
    const {activityClasses, dateSelected} = this.state;
    activityClasses.push({ date: momentToDbFormat(dateSelected) })
    this.setStateAsync({ activityClasses, isBlocking: true });
  }

  removeActivityClass = (event) => {
    const {index} = event.target.closest('i[data-index]').dataset;
    const {activityClasses} = this.state;
    activityClasses.splice(index, 1);
    this.setStateAsync({ activityClasses, isBlocking: true });
  }

  processActivityStudent = event => {
    const file = inputHandler('file', event);

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const f = async () => {
        try{
          await this.setStateAsync({ dimmerOpen: true });
          const XLSX = await import('xlsx');
          const workbook = XLSX.read(e.target.result, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const header_data = XLSX.utils.sheet_to_json(worksheet, { range: 3, header: 1, blankrows: false });
          const header = header_data[0];
  
          if (JSON.stringify(header) !== JSON.stringify(this.state.activityStudentHeader)) {
            alert('內容格式錯誤，請下載名單再作修改');
            this.setStateAsync({ dimmerOpen: false });
            return;
          } else {
            const excel_data = XLSX.utils.sheet_to_json(worksheet, { range: 4, header: 1, blankrows: false });
            let activityStudents = [];
            const gradeSet = genGradeSet(this.state.activity);

            map(excel_data, data => {
              const activityStudentsIndex = findIndex(this.state.activityStudents, { classCode: data[0], classNo: +data[2] });
              const student = find(this.state.students, { classCode: data[0], classNo: +data[2] });
              const leaveMethod = find(this.state.leaveMethodOptions, { text: String(data[3]).trim() });
              if(student && leaveMethod && gradeSet.has(student.gradeId)){
                activityStudents.push({
                  id: activityStudentsIndex > -1 ? this.state.activityStudents[activityStudentsIndex]['id'] : --this.newcount,
                  studentId: student.id,
                  leaveMethodId: leaveMethod ? leaveMethod.value : 1,
                  leaveMethodName: leaveMethod ? leaveMethod.text : this.state.leaveMethodOptions[0]['text'],
                  remark: data[4]
                })
              }else{
                console.log(data, student, leaveMethod);
              }
            });
  
            this.setStateAsync({
              activityStudents,
              isBlocking: true,
              dimmerOpen: false
            })
          }
        }catch(err){if(err!=='unmounted')console.log("Error when processing file");}
      }
      f();
    }
    reader.readAsArrayBuffer(file);
  }

  downloadActivityStudentTemplate = async () => {
    const {year, activity, activityStudents} = this.state;
    const filename = `${activity.name}課外活動學生名單 (${year?year.displayName:'-'})`;
    const data = [this.state.activityStudentHeader];
    const leaveMethodLookup = keyBy(this.state.leaveMethodOptions, 'value');
    const studentLookup = keyBy(this.state.students, 'id');
    map(activityStudents, ({ studentId, leaveMethodId, remark }) => {
      const student = studentLookup[studentId];
      
      data.push([
        student && student.classCode,
        student && (student.chiName || student.engName),
        student && student.classNo,
        leaveMethodLookup[leaveMethodId] && leaveMethodLookup[leaveMethodId].text,
        remark
      ]);
    });

    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);

    sheet.cell('A4').value(data);
    sheet.usedRange().style({
      border: { left: 'medium', right: 'medium', top: 'medium', bottom: 'medium',  },
    });

    sheet.column('A').width(5.2);
    sheet.column('B').width(24);
    sheet.column('C').width(5.2);
    sheet.column('D').width(32);
    sheet.column('E').width(30);

    sheet.range('A1:E1').merged(true).startCell().value(process.env.REACT_APP_SCHOOL_NAME);
    sheet.range('A2:E2').merged(true).startCell().value(filename);

    sheet.row('4').height(33).style({wrapText: true});

    sheet.range('A1:E2').style({fontSize: 16})

    sheet.usedRange().style({
      fontFamily: '標楷體',
      horizontalAlignment: 'center',
      verticalAlignment: 'center'
    });

    const file = await workbook.outputAsync();
    exportExcel(file, filename);
  }

  leave = () => {
    if (this.state.activityId) {
      this.props.history.push('/eca/activity/' + this.props.match.params.section + '/detail/' + this.props.match.params.yearId + '/' + this.state.activityId);
    } else {
      this.props.history.push('/eca/activity/' + this.props.match.params.section);
    }
  }

  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    const {activityStudents} = this.state;
    const activityStudentMap = new Map();
    for(let i=0;i<activityStudents.length;i++){
      const aS=activityStudents[i];
      if(!aS || typeof aS !== 'object' || !aS.hasOwnProperty('studentId') || !aS.studentId){
        alert("請確保已填好全部學生欄位");
        return;
      }
      if(activityStudentMap.has(aS.studentId)){
        const student = find(this.state.students, {id: aS.studentId});
        if(student){
          alert(`學生 ${student.chiName || student.engName} 出現重複`);
        }else{
          alert(`第${+activityStudentMap.get(aS.studentId)+1}行和第${i+1}行重複`);
        }
        return;
      }
      activityStudentMap.set(aS.studentId, i);
    }

    const data = pick(this.state, ['activityId','activity','activityTeachers','activityClasses']);
    data.activity = {
      ...data.activity,
      weekday: data.activity.weekday.join("") || null
    }
    data.activityStudents = map(this.state.activityStudents, ({ id, studentId, leaveMethodId, remark }) => ({
      id: +id || null,
      studentId,
      leaveMethodId,
      remark
    }));
    
    try{
      await this.setStateAsync({ dimmerOpen: true });
      const result = await post('editActivity', data);
      if (result.activityId) {
        await this.setStateAsync({ activityId: result.activityId, isBlocking: false });
        this.leave();
        return;
      }
      throw result;
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存活動時發生錯誤"+(err&&typeof err==='object'?": "+err.message:''))
        this.setStateAsync({ dimmerOpen: false });
        console.log("Error when saving Activity", err);
      }
    }
  }

  add = () => {
    this.setStateAsync({
      activityStudents: this.state.activityStudents.concat({
        studentId: null,
        id: --this.newcount,
        leaveMethodId: 1,
        status: 1,
      }),
      isBlocking: true
    })
  }
  
  remove = (event) => {
    const {itemId} = event.target.closest('button').dataset;
    this.setStateAsync({
      activityStudents: this.state.activityStudents.filter(x=>x.id!=itemId),
      isBlocking: true
    });
  }

  edit = (event, data) => {
    const {inputtype, propname, itemId} = event.target.closest('.ui, textarea').dataset;
    const activityStudents = [...this.state.activityStudents];
    const index = findIndex(activityStudents, {id: +itemId});
    if(index>-1){
      activityStudents[index] = {...activityStudents[index], [propname]: inputHandler(inputtype, data)};
      this.setStateAsync({activityStudents, isBlocking: true});
    }
  }

  clickFileInputRef = () => {
    this.fileInputRef.current.click();
  }

  render() {
    const {
      dimmerOpen,
      teacherOptions,
      subjectOptions,
      studentOptions,
      locationOptions,
      leaveMethodOptions,
      activity,
      activityTeachers,
      activityStudents,
      activityClasses,
      dateSelected,
      isBlocking,
    } = this.state;

    return (
      <>
        {dimmerOpen && ( <FullscreenDimmer active={dimmerOpen} isLoading={true} /> )}
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='編輯課外活動' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form>
                <Segment.Group className='form-group-margin'>
                  <Segment className='bold' size='big'>基本資料</Segment>
                  <Segment secondary className='form-padding'>
                    <Form.Group widths='2' className='form-group-margin'>
                      <Form.Field required>
                        <label>活動名稱</label>
                        <Input
                          fluid
                          type='text'
                          data-input-type='text'
                          data-state-name='name'
                          onChange={this.activityChange}
                          disabled={this.props.match.params.section==='personal'}
                          value={activity.name || ''}
                          className='more-opaque'
                          placeholder='活動名稱'
                        />
                        {this.validator.message('name', activity.name, 'required')}
                      </Form.Field>
                      <Form.Field required>
                        <label>活動所屬科目</label>
                        <MySelect
                          fluid
                          data-input-type='select'
                          data-state-name='subjectId'
                          onChange={this.activityChange}
                          value={activity.subjectId}
                          options={subjectOptions}
                          placeholder='活動所屬科目'
                          search
                        />
                        {this.validator.message('subject', activity.subjectId, 'integer')}
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal' className='form-group-margin'>
                      <Form.Field required>
                        <label>負責老師</label>
                        <MySelect
                          fluid
                          multiple
                          search
                          onChange={this.activityTeacherChange}
                          disabled={this.props.match.params.section==='personal'}
                          value={activityTeachers}
                          options={teacherOptions}
                          noResultsMessage="找不到老師"
                          placeholder='負責老師'
                        />
                        {this.validator.message('activityTeachers', activityTeachers.length, 'required|min:1,num')}
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal' className='form-group-margin'>
                      <Form.Field>
                        <label>備註欄</label>
                        <TextArea
                          data-input-type='text'
                          data-state-name='remark'
                          onChange={this.activityChange}
                          value={activity.remark || ''}
                          placeholder='備註欄'
                        />
                      </Form.Field>
                    </Form.Group>
                  </Segment>
                </Segment.Group>

                <Segment.Group className='form-group-margin'>
                  <Segment className='bold' size='big'>上課資料</Segment>
                  <Segment secondary className='form-padding'>
                    <Form.Group widths='equal' className='form-group-margin'>
                      <Form.Field>
                        <label>地點</label>
                        <MySelect
                          data-input-type='select'
                          data-state-name='locationId'
                          onChange={this.activityChange}
                          value={activity.locationId || ''}
                          options={locationOptions}
                          search
                          placeholder='地點'
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal' className='form-group-margin'>
                      <Form.Field>
                        <label>星期</label>
                        <MySelect
                          data-input-type='select'
                          data-state-name='weekday'
                          onChange={this.activityChange}
                          multiple
                          value={activity.weekday}
                          options={weekdayOptions}
                          placeholder='星期'
                      />
                      </Form.Field>
                      <Form.Field>
                        <label>開始時間</label>
                        <TimeInput
                          data-input-type='time'
                          data-state-name='startTime'
                          onChange={this.activityStartTimeChange}
                          value={activity.startTime ? timeToStringFormat(activity.startTime) : ''}
                          placeholder='開始時間'
                          popupPosition='right center'
                          fluid
                          closable
                          clearable
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>結束時間</label>
                        <TimeInput
                          data-input-type='time'
                          data-state-name='endTime'
                          onChange={this.activityEndTimeChange}
                          value={activity.endTime ? timeToStringFormat(activity.endTime) : ''}
                          placeholder='結束時間'
                          popupPosition='right center'
                          fluid
                          closable
                          clearable
                        />
                      </Form.Field>
                    </Form.Group>
                  </Segment>
                  <Segment secondary className='form-padding'>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Group className='input-with-button'>
                            <Form.Field className='wide inline input-with-button'>
                              <label>日期</label>
                              <DateInput
                                className='flex-1'
                                onChange={this.dateChange}
                                value={momentToDate(dateSelected)}
                                placeholder='日期'
                              />
                            </Form.Field>
                            <Buttons.AddButton onClick={this.addActivityClass} />
                          </Form.Group>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Label.Group size='large' className='form-group-margin activity-date-label'>
                      {activityClasses.map(({ date }, index) => (
                        <Label key={index} color='teal'>
                          {displayDate(date)}<Icon name='delete' data-index={index} onClick={this.removeActivityClass} />
                        </Label>
                      ))}
                    </Label.Group>
                  </Segment>
                </Segment.Group>
                <Segment.Group className='form-group-margin'>
                  <Segment className='bold' size='big'>學生資料</Segment>
                  <Segment secondary>
                    <Form.Group widths='equal'>
                        <Form.Field required>
                          <label>年級</label>
                        </Form.Field>
                        {gradeOptions.map(({text, value})=>(
                          <Form.Field key={value}>
                            <label data-i={value}>
                              <Checkbox
                                checked={!!activity['grade_'+value]}
                                data-input-type='checkbox'
                                data-state-name={'grade_'+value}
                                onChange={this.activityChange}
                                className='narrow'
                              />
                              {text}
                            </label>
                          </Form.Field>
                        ))}
                      </Form.Group>
                  </Segment>
                  <Segment secondary>
                    <Buttons.UploadButton className='button-margin-bottom' onClick={this.clickFileInputRef} />
                    <input
                      hidden
                      type="file"
                      ref={this.fileInputRef}
                      onChange={this.processActivityStudent}
                      accept={excelFormats}
                    />
                    <Buttons.DownloadButton content='下載修改' onClick={this.downloadActivityStudentTemplate} />
                    <ActivityStudentTable
                      add={this.add}
                      remove={this.remove}
                      edit={this.edit}
                      data={activityStudents}
                      studentOptions={studentOptions}
                      leaveMethodOptions={leaveMethodOptions}
                    />
                  </Segment>
                </Segment.Group>

                <div className='text-center'>
                  <Buttons.CancelButton onClick={this.leave} />
                  <Buttons.SaveButton onClick={this.save} />
                </div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(ActivityEdit);