import React, { forwardRef, useImperativeHandle, useState } from 'react';
import FullscreenDimmer from '../../../../Components/FullscreenDimmer';
import ConfirmModal from '../../../../Components/ConfirmModal';
import { post } from '../../../../Helper/ApiHelper';

const FormDeleteModal = forwardRef(({ fetch }, ref) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [id, setId] = useState(null);

	useImperativeHandle(ref, () => ({
		open: (id) => {
			setId(id);
			setOpen(true);
		},
	}));

	const deleteContent = async () => {
		const data = { id };
		setLoading(loading);
		const result = await post('deleteAppraisalContent', data);
		if (result) {
			await fetch();
			setLoading(false);
			setOpen(false);
		}
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<ConfirmModal
				open={open}
				description="確定刪除評估項目？所有子項目也會一併刪除"
				cancel={() => setOpen(false)}
				confirm={deleteContent}
			/>
		</React.Fragment>
	);
});

export default FormDeleteModal;
