/* import react */
import React, { Fragment } from 'react';

/* import helper */
import { textareaText } from '../../../Helper/Helper';
import { momentToDate } from '../../../Helper/TimeHelper';

/* import semantic-ui element */
import { Table } from 'semantic-ui-react';

const DisplayBeforeComponent = ({appraisalContentBeforeTree, appraisalBefore}) => {
    return (
        <Fragment>
            {appraisalContentBeforeTree.map(({id, description, needInput, inputType, children}, index) => {
                return(
                    <Fragment key={index}>
                        {needInput && 
                            <Table.Row>
                                <Table.Cell collapsing>
                                    <b>{description}</b>
                                </Table.Cell>
                                <Table.Cell>
                                    {inputType === 'date' && <Fragment>{momentToDate(appraisalBefore[id])}</Fragment> }
                                    {(inputType === 'text' || inputType === 'subject' || inputType === 'textSelect') && <Fragment>{textareaText(appraisalBefore[id])}</Fragment> }
                                    {inputType === 'score' && <Fragment>{appraisalBefore[id]}</Fragment> }
                                </Table.Cell>
                            </Table.Row>
                        }
                        {children && <DisplayBeforeComponent appraisalContentBeforeTree={appraisalContentBeforeTree} appraisalBefore={appraisalBefore} /> }
                    </Fragment>
                )
            })}
        </Fragment>
    )
}

export default DisplayBeforeComponent;