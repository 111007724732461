/* import lodash */
import {keyBy, find, pick} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	EmptyTableMsg,
	FullscreenDimmer,
  Buttons
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions,
  defaultYearForPlan,
  whetherReadOnlyForYearPlan
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

/* import semantic-ui element */
import { Grid, Select, Modal, Form, Button, Table, Segment, Icon } from 'semantic-ui-react';

import '../../../Styles/GroupTimetable.css';

class GroupTimetable extends Component {
  constructor(props) {
    super(props);

    this.months = Array.from({length: 12}).map((x,i)=>(i+8)%12+1);

    this.state = {
      finishedLoading: false,
      dimmerOpen: false,
      readOnly: true,

      year: [],
      yearInfo: [],
      yearId: '',

      groupId: null,
      groupOptions: [],
      editableGroups: new Set(),

      groupActivities: [],
      activityId: null,

      selectedId: '',
      isEditModalOpen: false,
      title: '',
      ...this.months.reduce((prev,x,i)=>({...prev,[i]:0}),{})
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    try{
      this.mounted = true;

      await this.fetch();
      this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log("Error when mounting Group Activity Timetable", err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try {
      //get year info
      const [yearInfo, groups] = await Promise.all([
        get('getYear'),
        get('getGroup'),
      ]);
      
      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId: this.state.yearId || defaultYearForPlan(yearInfo),

        groupOptions: selectOptions(groups, 'name', 'id'),
        groupId: this.state.groupId || '',
      });
      
      await this.fetchEditableGroups();
      
    } catch (err) {
      if(err!=='unmounted') console.log('Error when fetching core info for timetable', err);
    }
  }

  fetchEditableGroups = async () => {
    try {
      const groupsCanEdit = await get('getGroupEditRights/' + this.state.yearId);
      const editableGroups = new Set(groupsCanEdit.map(x => x.id));

      await this.setStateAsync({
        editableGroups,
        readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId) || !editableGroups.has(this.state.groupId),
      });

      await this.fetchGroupActivities();

    } catch (err) {
      if(err!=='unmounted') console.log('Error when fetching editable groups for timetable', err);
    }
  }

  fetchGroupActivities = async () => {
    try{
      const {groupId} = this.state;
      let groupActivities = [], groupTimetable = [];
      if(groupId){
        groupActivities = await get(`getGroupActivity/${+groupId}`);
        groupTimetable = await get(`getGroupTimetable/${this.state.yearId}/${groupId}`);
      }
      await this.setStateAsync({
        groupActivities,
        groupTimetable: keyBy(groupTimetable, 'activityId'),
      });

    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching group timetable', err);
    }
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value });
  }

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId } = this.state;
      if (yearId !== value) {
        await this.setStateAsync({
          yearId: value,
          finishedLoading: false,
        });

        await this.fetchEditableGroups();
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      if(err!=='unmounted') console.log("Error when changing year for Group timetable", err);
    }
  }

  groupChange = async (event, { value }) => {
    try{
      const { groupId } = this.state;
      if (groupId !== value && this.mounted) {
        await this.setStateAsync({
          finishedLoading: false,
          groupId: value,
        });
        await this.fetchEditableGroups();
        await this.setStateAsync({ finishedLoading: true });
      }
    } catch (err) {
      console.log("Error when changing group for Group timetable", err);
    }
  }

  /* modal toggle */
  modalToggle = (eventOrStateName) => {
    
    if(typeof eventOrStateName === 'object'){
      let modalData = {}, selectedId = null, activityId = null;
      if(eventOrStateName.target.classList.contains('modals')){
        modalData = eventOrStateName.target.firstElementChild.dataset;
      }else{
        modalData = eventOrStateName.target.closest('button').dataset;
        selectedId = +modalData.selectedId;
        activityId = +modalData.activityId;
      }
      const {modalname} = modalData;

      const data = {
        [modalname]: !this.state[modalname],
        selectedId,
        activityId,
      }
      const ref = this.state.groupTimetable[+activityId];
      const activity = find(this.state.groupActivities, {id: activityId});

      if(ref && selectedId){
        data.title = activity.title;
        this.months.forEach((month,i)=>{
          data[i] = ref.timetable[i];
        });
      }else{
        data.title = activity ? activity.title: '';
        this.months.forEach((month,i)=>{
          data[i] = 0;
        });
      }
      this.setStateAsync(data);
    }else{
      const data = {
        [eventOrStateName]: !this.state[eventOrStateName],
        selectedId: null,
        activityId: null
      }
      this.months.forEach((month,i)=>{
        data[i] = 0;
      });
      this.setStateAsync(data);
    }
  }

  /* save record */
  save = async () => {
    try{
      const data = pick(this.state, ['yearId', 'activityId']);
      if(this.state.selectedId)
        data.id = this.state.selectedId;
      
      const timetable = this.months.map((m,i)=>this.state[i]);
      data.timetable = timetable;
      await this.setStateAsync({
        dimmerOpen: true,
      });
      const result = await post('editGroupTimetable', data);
      if(result && result.status){
        this.modalToggle('isEditModalOpen');
        await this.setStateAsync({
          dimmerOpen: false,
          finishedLoading: false,
        });
        await this.fetch();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert('儲存組別恆常活動時間表發生錯誤', err);
      }
    }
    await this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true,
    });
  }

  switchMonth = (event) => {
    const {index} = event.target.closest('.ui.checkbox').dataset;
    this.setStateAsync({
      [index]:1-this.state[index]
    })
  }

  render() {
    const {
      finishedLoading,
      dimmerOpen,

      year,
      yearId,
      readOnly,

      groupOptions,
      groupId,

      groupActivities,
      groupTimetable,
      title,
      isEditModalOpen,
    } = this.state;

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='科組計劃 - 恆常活動' subTitle='為每個組別設定恆常活動時間表' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Segment padded>
                <Form>
                  <Form.Group>
                    <Form.Field inline>
                      <label>周年</label>
                      <Select
                        disabled={!finishedLoading}
                        options={year}
                        value={yearId}
                        onChange={this.yearChange}
                      />
                    </Form.Field>
                    <Form.Field inline>
                      <label>組別</label>
                      <Select
                        error={!groupId}
                        disabled={!finishedLoading}
                        options={groupOptions}
                        value={groupId}
                        onChange={this.groupChange}
                        placeholder="請先選擇組別"
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Table textAlign='center' celled unstackable fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={+!!readOnly+4}>工作項目</Table.HeaderCell>
                  {this.months.map(month=><Table.HeaderCell width={1} key={month}>{month}月</Table.HeaderCell>)}
                  {!readOnly && <Table.HeaderCell width={1}>行動</Table.HeaderCell>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finishedLoading ? (
                  groupId ? (
                    groupActivities && groupActivities.length ? groupActivities.map((activity,i)=>{
                      const item = groupTimetable[+activity.id];
                      return item?(
                        <Table.Row key={activity.id}>
                          <Table.HeaderCell textAlign="left" className="activityHeader">{i+1}. {activity.title}</Table.HeaderCell>
                          {this.months.map((month,i)=>{
                            return (<Table.Cell key={month}>
                              {item.timetable[i] === 1 && <Icon name="check"/>}
                            </Table.Cell>)
                          })}
                          {!readOnly && (<Table.Cell className="textlessCell">
                            <Buttons.EditButton
                              data-selected-id={item.id}
                              data-activity-id={activity.id}
                              data-modalname='isEditModalOpen'
                              onClick={this.modalToggle}
                            />
                          </Table.Cell>)}
                        </Table.Row>
                      ):(
                        <Table.Row key={activity.id}>
                          <Table.HeaderCell textAlign="left" className="activityHeader">{i+1}. {activity.title}</Table.HeaderCell>
                          <Table.Cell colSpan='12' textAlign="center">
                            未設定恆常活動時間表
                          </Table.Cell>
                          {!readOnly && (<Table.Cell className="textlessCell">
                            <Buttons.AddButton
                              data-activity-id={activity.id}
                              data-modalname='isEditModalOpen'
                              onClick={this.modalToggle}
                            />
                          </Table.Cell>)}
                        </Table.Row>
                      )
                    }):<EmptyTableMsg colSpan={readOnly?13:14} msg='未有恆常活動'/>
                  ) : <EmptyTableMsg colSpan={readOnly?13:14} msg='請先選擇科組'/>
                ) : <EmptyTableMsg colSpan={readOnly?13:14} msg='載入資料中'/>}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {dimmerOpen?(<FullscreenDimmer active={dimmerOpen} isLoading={true} />):(
        <Modal open={isEditModalOpen} data-modalname='isEditModalOpen' onClose={this.modalToggle} closeOnEscape={false} closeOnDimmerClick={false}>
          <Modal.Header>設定恆常活動時間表 (<span className='red'>{title}</span>)</Modal.Header>
          <Modal.Content>
            <Segment basic>
              <Form>
                <Form.Group widths={4}>
                  {this.months.map((month,i)=>(
                  <Form.Checkbox
                    key={month}
                    label={month+"月"}
                    data-index={i}
                    checked={this.state[i]===1}
                    onClick={this.switchMonth}
                    disabled={readOnly}
                  />))}
                </Form.Group>
              </Form>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Buttons.CancelButton data-modalname='isEditModalOpen' onClick={this.modalToggle} circular />
            <Button color='green' content='儲存' icon='save' onClick={this.save} circular />
          </Modal.Actions>
        </Modal>
      )}
      </>
    )
  }
}

export default withRouter(GroupTimetable);