/* import lodash */
import {find} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	FullscreenDimmer,
} from '../../Components';

/* import helper functions */
import { get, doc } from '../../Helper/ApiHelper';
import { threeYearPlanAndWhichYearFromYear, defaultYearForPlan, selectOptions, extractStartYear } from '../../Helper/Helper';
import { inputHandler } from '../../Helper/FormHelper';

/* import semantic-ui element */
import { Grid, Form, Button, Segment, Select } from 'semantic-ui-react';

class SurveyDesign extends Component {
  constructor(props) {
    super(props);

    this.state = {

      finishedLoading: false,
      dimmerOpen: false,

      yearInfo: [],
      year: [],
      selectedYear: '',

      TYPs: [],
      TYPOptions: [],
      selectedTYP: '',
      whichYear: '',
      
      teacherGroupOptions: [],
      selectedTeacherGroup: '',

      scope: '3year',
    }
  }

  setStateAsync = (state, errMsg = 'error') => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    }).catch(err=>{console.log(errMsg);console.log(err);})
  )


  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
    
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log("Error when mounting ExportFile");
      console.log(err);
    }
  }

  fetch = async () => {
    try{
      const [yearInfo, TYPs, teacherGroups] = await Promise.all([
        get('getYear'),
        get('getTYP'),
        get('getGroup')
      ]);
      
      const selectedYear = defaultYearForPlan(yearInfo);
      const [selectedTYP, whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs);

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        selectedYear,

        TYPs,
        selectedTYP,
        whichYear,

        teacherGroupOptions: selectOptions(teacherGroups, 'name', 'id'),
        selectedTeacherGroup: teacherGroups.length? teacherGroups[0].id : '',

        TYPOptions: selectOptions(TYPs, typ=>{
          const startYear = extractStartYear(find(yearInfo,{id: typ.beginYear}));
          return `${startYear}-${startYear+3}`;
        }, "id")
      });
    }catch(err){
      console.log("Error when fetching ExportFile");
      console.log(err);
    }
  }

  /* input update handler */
  inputChange = (inputType, stateName, callback = () => { }) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({
      [stateName]: value,
    }, callback)
  }

  exportPlan = async () => {
    const {scope, TYPs, yearInfo, teacherGroupOptions, selectedYear, selectedTYP, selectedTeacherGroup} = this.state;
    try{
      await this.setStateAsync({dimmerOpen: true});
      let theYear;
      switch(scope){
        case '3year':
          const theTYP = find(TYPs, {id: +selectedTYP});
          if(theTYP){
            const startYear = extractStartYear(find(yearInfo, {id: +(theTYP.beginYear)}));
            await doc('generate/3YearPlan', {typId: selectedTYP}, `學校三年計劃 (${startYear}-${startYear+3})`);
          }
          break;
        case '1year':
          theYear = find(yearInfo, {id: +selectedYear});
          if(theYear){
            await doc('generate/1YearPlan', {yearId: selectedYear}, `學校周年計劃 (${theYear.displayName})`);
          }
          break;
        case 'group':
          theYear = find(yearInfo, {id: +selectedYear});
          const theGroup = find(teacherGroupOptions, {value: +selectedTeacherGroup});
          if(theYear && theGroup){
            await doc('generate/GroupPlan', {
              yearId: selectedYear,
              groupId: selectedTeacherGroup,
            }, `${theGroup.text}周年計劃 (${theYear.displayName})`);
          }
          break;
        case 'strategy':
          theYear = find(yearInfo, {id: +selectedYear});
          if(theYear){
            await doc('generate/StrategySummary', {yearId: selectedYear}, `策略報告 (${theYear.displayName})`);
          }
          break;
        default: alert("You shouldn't be here");break;
      }
    }catch(err){
      console.log('Error when exporting plan');
      console.log(err);
    }
    this.setStateAsync({dimmerOpen: false});
  }


  render() {
    const {
      year,
      selectedYear,
      TYPOptions,
      selectedTYP,
      scope,

      teacherGroupOptions,
      selectedTeacherGroup,

      finishedLoading,
      dimmerOpen
    } = this.state;

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='匯出校本計劃' />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment padded='very'>
                <Form onSubmit={this.export}>
                  <Form.Group className='form-group-margin' inline>
                    <Form.Field disabled={!finishedLoading}>
                      <Form.Checkbox
                        radio
                        label='三年計劃'
                        name='scope'
                        value='3year'
                        checked={scope === '3year'}
                        onChange={this.inputChange('select', 'scope')}
                      />
                    </Form.Field>
                    <Form.Field disabled={!finishedLoading}>
                      <Form.Checkbox
                        radio
                        label='周年計劃'
                        name='scope'
                        value='1year'
                        checked={scope === '1year'}
                        onChange={this.inputChange('select', 'scope')}
                      />
                    </Form.Field>
                    <Form.Field disabled={!finishedLoading}>
                      <Form.Checkbox
                        radio
                        label='科組計劃'
                        name='scope'
                        value='group'
                        checked={scope === 'group'}
                        onChange={this.inputChange('select', 'scope')}
                      />
                    </Form.Field>
                    <Form.Field disabled={!finishedLoading}>
                      <Form.Checkbox
                        radio
                        label='策略報告'
                        name='scope'
                        value='strategy'
                        checked={scope === 'strategy'}
                        onChange={this.inputChange('select', 'scope')}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field inline>
                      <label>年份</label>
                      {scope !== '3year'?(<Select
                        disabled={!finishedLoading}
                        options={year}
                        value={selectedYear}
                        onChange={this.inputChange('select', 'selectedYear')}
                      />):(<Select
                        disabled={!finishedLoading}
                        options={TYPOptions}
                        value={selectedTYP}
                        onChange={this.inputChange('select', 'selectedTYP')}
                      />)}
                    </Form.Field>
                    {scope==='group' && (
                      <Form.Field inline disabled={!finishedLoading}>
                        <label>科組</label>
                        <Select
                          disabled={!finishedLoading}
                          options={teacherGroupOptions}
                          value={selectedTeacherGroup}
                          float="right"
                          onChange={this.inputChange('select', 'selectedTeacherGroup')}
                        />
                      </Form.Field>
                    )}
                    <Form.Field disabled={!finishedLoading}>
                      <Button
                        disabled={scope==='group'&&!selectedTeacherGroup}
                        color='green'
                        content='匯出計劃'
                        icon='file alternate'
                        circular
                        onClick={this.exportPlan}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {(!!dimmerOpen)&&(
          <FullscreenDimmer
            active={true}
            content="系統正在生成DOCX檔。請稍候。"
            isLoading={true}
          />
        )}
      </>
    )
  }
}

export default withRouter(SurveyDesign);