/* import lodash */
import {keyBy, find, filter, pick} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	ConfirmModal,
	EmptyTableMsg,
	FullscreenDimmer,
	SubTopicText,
  MySelect,
  BlockerPrompt
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { selectOptions, extractStartYear, whetherReadOnlyForTYP, getTeacherOptions } from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

import '../../../Styles/Concerns.css';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, Input } from 'semantic-ui-react';

class ThreeYearPlan extends Component {
  constructor(props) {
    super(props);
    
    this.state = {

      year: [], // for select fields
      yearInfo: {}, //dictionary for info search
      hasGotYear: false,

      TYPs: [],
      concerns: [],
      
      beginYear: '',
      tempConcerns: [],

      isBlocking: false,
      finishedLoading: false,
      isEditModalOpen: false,
      isSaveModalOpen: false,
      isDeleteModalOpen: false,
      selectedId: null,
    }

    //form validator
    this.validator = new SimpleReactValidator({
      element: (message) => {
        let pointing = true;
        switch (message) {
          case '請輸入全部三個分項': pointing = false; break;
          case '請輸入有效年份': pointing = 'left'; break;
          default:break;
        }
        return <ErrorLabel message={message} pointing={pointing} />
      },
      autoForceUpdate: this,
      messages: {
        accepted: '請輸入全部三個分項',
        required: '請輸入有效資料',
        year: '請輸入有效年份',
      },
      validators: {
        year: {
          message: '請輸入有效年份',
          rule: (val, params, validator) => !!val,
          required: true,
        }
      }
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({finishedLoading: true});
    }catch(err){
      if(err!=='unmounted') console.log('error when mounting 3year concerns', err);
    }
  }

  fetch = async () => {
    //get year info
    const rawYear = await get('getYear');
    let year = selectOptions(rawYear, extractStartYear, 'id');
    let yearInfo = keyBy(rawYear, 'id');

    await this.setStateAsync({year, yearInfo, hasGotYear: true});

    const [TYPs, concerns, teachers] = await Promise.all([
      get('getTYP'),
      get('get3YearConcern'),
      process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT ? getTeacherOptions() : Promise.resolve([])
    ]);

    await this.setStateAsync({
      TYPs,
      concerns,
      teachers: new Map(teachers.map(x=>[x.value, x.text])),
      teacherOption: teachers
    });
  }

  readOnly = (id) => {
    if(!id) return false;
    const selectedTYP = find(this.state.TYPs, {id});
    let readOnly = true;

    if(selectedTYP){
      readOnly = whetherReadOnlyForTYP(Object.values(this.state.yearInfo), this.state.TYPs, id);
    }
    return readOnly;
  }

  /* input update handler */
  inputChange = (event, data) => {
    const {inputType, stateName} = event.target.closest('.ui, textarea, button').dataset;
    let value = inputHandler(inputType, data);
    this.setStateAsync({
      [stateName]: value,
      isBlocking: true,
    })
  }

  /* modal toggle */
  modalToggle = (modalStateName, selectedId = null) => () => {
    if(modalStateName === 'isEditModalOpen' && this.state.isEditModalOpen && this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要關閉嗎？")){
        return;
      }
    }
    const data = {
      [modalStateName]: !this.state[modalStateName],
      isBlocking: false,
    };

    if(modalStateName === 'isSaveModalOpen'){  
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return;
      }
      this.setStateAsync(data);
      return;
    }
    Object.assign(data, {selectedId});

    if (selectedId) {
      const typRef = find(this.state.TYPs, { id: selectedId });
      data.beginYear = typRef.beginYear;
      const concernRef = filter(this.state.concerns, { typId: selectedId });
      data.tempConcerns = concernRef ? [...concernRef] : [];
    } else {
      Object.assign(data, {
        beginYear:'',
        tempConcerns:[],
      });
    }
    this.setStateAsync(data);
  }

  /* save record */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    const data = pick(this.state, ['beginYear', 'customName']);
    data.id = this.state.selectedId;
    data.concerns = this.state.tempConcerns;

    try{
      await this.setStateAsync({
        dimmerOpen: true,
      });
      let result = await post('editTYPwithConcerns', data);
      if(!result || !result.status){
        throw result;
      }
      this.validator.hideMessages();
      await this.setStateAsync({
        isBlocking: false,
      })
      this.modalToggle('isEditModalOpen')();
      await this.fetch();
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存三年計劃時發生錯誤");
        console.log(err);
      }
    }
    this.modalToggle('isSaveModalOpen')();
    this.setStateAsync({
      dimmerOpen: false,
    });
  }

  delete = async () => {
    const { selectedId } = this.state;
    let data = { id: selectedId };
    try{
      await this.setStateAsync({
        dimmerOpen: true,
      });
      const result = await post('deleteTYP', data)
      if (result && result.status) {
        await this.fetch();
      }else{
        throw result;
      }
    }catch(err){
      alert("刪除三年計劃時發生錯誤");
      console.log(err);
    }
    this.modalToggle('isDeleteModalOpen')();
    this.setStateAsync({
      dimmerOpen: false,
    });
  }

  addConcern = () => {
    const { selectedId } = this.state;
    this.setStateAsync({
      tempConcerns: this.state.tempConcerns.concat([{
        title: '',
        eng_title: '',
        typId: selectedId,
        meetTarget: '',
        followUp: ''
      }]),
      isBlocking: true,
    })
  }

  removeConcern = (event) => {
    const {concernIndex} = event.target.closest('.ui, button').dataset;
    const tempConcerns = [...this.state.tempConcerns];
    tempConcerns.splice(concernIndex, 1);
    this.setStateAsync({
      tempConcerns,
      isBlocking: true,
    });
  }

  inputChangeForConcern = (event, data) => {
    const {concernIndex,inputType,stateName} = event.target.closest('.ui:not(.label), button').dataset;
    const tempConcerns = [...this.state.tempConcerns];
    const concern = { ...tempConcerns[concernIndex] };
    concern[stateName] = inputHandler(inputType, data);
    tempConcerns[concernIndex] = concern;
    this.setStateAsync({
      tempConcerns,
      isBlocking: true,
    });
  }

  addAnnualSubTopic = (event) => {
    const i = event.target.closest('.ui').dataset.concernIndex;
    const tempConcerns = [...this.state.tempConcerns];
    const concern = { ...tempConcerns[i] };
    Object.assign(concern, {
      subTopic1: "一",
      subTopic2: "二",
      subTopic3: "三",
      eng_subTopic1: "",
      eng_subTopic2: "",
      eng_subTopic3: "",
    });
    tempConcerns[i] = concern;
    this.setStateAsync({
      tempConcerns,
      isBlocking: true,
    });
  }

  render() {
    const {
      dimmerOpen,

      year, yearInfo, hasGotYear,
      TYPs, concerns,

      isBlocking,
      finishedLoading,
      isEditModalOpen,
      isDeleteModalOpen,
      isSaveModalOpen,

      beginYear,
      selectedId,

      tempConcerns,
      teachers,
      teacherOption,
    } = this.state;

    const trueBeginYear = beginYear ? extractStartYear(yearInfo[beginYear]) : '';

    const readOnly = this.readOnly(selectedId);

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='三年計劃及關注事項' subTitle='新增三年計劃、設定關注事項' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button color='green' content='新增三年計劃' icon='add' floated='right' onClick={this.modalToggle('isEditModalOpen')} circular disabled={!finishedLoading} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Table textAlign='center' celled unstackable structured>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3} rowSpan={process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT?2:1}>年度</Table.HeaderCell>
                    <Table.HeaderCell width={9} colSpan={process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT?3:1}>關注事項</Table.HeaderCell>
                    <Table.HeaderCell width={4} rowSpan={process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT?2:1}>行動</Table.HeaderCell>
                  </Table.Row>
                  {!!process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT && (<Table.Row>
                    <Table.HeaderCell width={7}>標題</Table.HeaderCell>
                    <Table.HeaderCell width={2}>負責人</Table.HeaderCell>
                    <Table.HeaderCell width={2}>負責教師</Table.HeaderCell>
                  </Table.Row>)}
                </Table.Header>
                <Table.Body>
                  {finishedLoading && (TYPs.length > 0) ?
                    <>
                      {TYPs.map(({ id, beginYear }) => {
                        const startYear = extractStartYear(yearInfo[beginYear]);
                        const readOnly = this.readOnly(id);
                        const trueConcerns = concerns.filter(x=>x.typId===id);
                        return trueConcerns.length?(
                          trueConcerns.map((x, i, a) => {
                            return (
                              <Table.Row key={x.id + ' ' + i}>
                                {!i && <Table.Cell rowSpan={a.length} className="break-word">
                                  <p className='form-group-margin-narrow'>{startYear} - {startYear + 3}</p>
                                </Table.Cell>}
                                <Table.Cell>
                                  {x.title||''}
                                  <SubTopicText sT1={x.subTopic1} sT2={x.subTopic2} sT3={x.subTopic3} sYear={startYear}/>
                                </Table.Cell>
                                {!!process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT && (<>
                                  <Table.Cell>
                                    {x.chiefs?x.chiefs.map(t=>teachers.get(t)||"it").join("、"):'-'}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {x.teachers?x.teachers.map(t=>teachers.get(t)||"it").join("、"):'-'}
                                  </Table.Cell>
                                </>)}
                                {!i && (<Table.Cell rowSpan={a.length} className="textlessCell">
                                  <Button color='blue' icon={readOnly ? 'eye' : 'edit'} onClick={this.modalToggle('isEditModalOpen', id)} circular />
                                  {!readOnly && (<Button color='red' icon='delete' onClick={this.modalToggle('isDeleteModalOpen', id)} circular />)}
                                </Table.Cell>)}
                              </Table.Row>
                            )
                          })
                        ):(<Table.Row key={"TYP"+id}>
                            <Table.Cell className="break-word">
                              <p className='form-group-margin-narrow'>{startYear} - {startYear + 3}</p>
                            </Table.Cell>
                            <Table.Cell disabled>
                              <strong>未有關注事項</strong>
                            </Table.Cell>
                            <Table.Cell className="textlessCell">
                              <Button color='blue' icon={readOnly ? 'eye' : 'edit'} onClick={this.modalToggle('isEditModalOpen', id)} circular />
                              {!readOnly && (<Button color='red' icon='delete' onClick={this.modalToggle('isDeleteModalOpen', id)} circular />)}
                            </Table.Cell>
                        </Table.Row>)
                      })}
                    </>
                    :
                    <EmptyTableMsg colSpan={process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT?5:3} msg={finishedLoading ? undefined : "資料載入中"}finishedLoading />
                  }
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        
        {dimmerOpen?(<FullscreenDimmer active={dimmerOpen} isLoading={true} />):(
          <>
            <Modal id="editTYPModal" open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false} size="fullscreen">
              <Modal.Header>設定三年計劃關注項目</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    <Form.Group>
                    <Form.Field inline required disabled={readOnly}>
                        <label>開始年度</label>
                        <MySelect
                          search
                          options={year}
                          value={beginYear}
                          data-input-type='select'
                          data-state-name='beginYear'
                          onChange={this.inputChange}
                        />
                      </Form.Field>
                      {this.validator.message('', beginYear, 'year')}
                      <Form.Field inline disabled>
                        <label>完結年度</label>
                        <Input type='number' value={beginYear ? trueBeginYear + 3 : ''} />
                      </Form.Field>
                    </Form.Group>
                    <Table fixed>
                      <Table.Header>
                        <Table.Row textAlign='center'>
                          <Table.HeaderCell width={process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT?4:6}>關注項目</Table.HeaderCell>
                          <Table.HeaderCell width={process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT?5:9}>每年分項</Table.HeaderCell>
                          {!!process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT && (<Table.HeaderCell width={3}>組長</Table.HeaderCell>)}
                          {!!process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT && (<Table.HeaderCell width={3}>負責人</Table.HeaderCell>)}
                          <Table.HeaderCell width={1}>刪除</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {tempConcerns.map((x, i) => (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Form.Group widths='equal'>
                                <Form.Field disabled={readOnly}>
                                  <Input
                                    fluid
                                    placeholder='標題 (中)'
                                    value={x.title}
                                    data-concern-index={i}
                                    data-input-type='text'
                                    data-state-name='title'
                                    onChange={this.inputChangeForConcern}
                                  />
                                </Form.Field>
                                {this.validator.message('concerntitle', x.title, 'required')}
                              </Form.Group>
                              <Form.Group widths='equal'>
                                <Form.Field disabled={readOnly}>
                                  <Input
                                    fluid
                                    placeholder='標題 (英)'
                                    value={x.eng_title}
                                    data-concern-index={i}
                                    data-input-type='text'
                                    data-state-name='eng_title'
                                    onChange={this.inputChangeForConcern}
                                  />
                                </Form.Field>
                              </Form.Group>
                            </Table.Cell>
                            <Table.Cell textAlign='center' className='form-group-no-margin'>
                              {x.subTopic1 || x.subTopic2 || x.subTopic3 || x.eng_subTopic1 || x.eng_subTopic2 || x.eng_subTopic3 ? (
                                <>
                                  <Form.Group widths='equal'>
                                    <Form.Field disabled={readOnly}>
                                      <Input
                                        fluid
                                        className="collapsed-input"
                                        value={x.subTopic1}
                                        placeholder={trueBeginYear ? `${trueBeginYear + 0} (中)` : false}
                                        data-concern-index={i}
                                        data-input-type='text'
                                        data-state-name='subTopic1'
                                        onChange={this.inputChangeForConcern}
                                      />
                                    </Form.Field>
                                    <Form.Field disabled={readOnly}>
                                      <Input
                                        fluid
                                        className="collapsed-input"
                                        value={x.subTopic2}
                                        placeholder={trueBeginYear ? `${trueBeginYear + 1} (中)` : false}
                                        data-concern-index={i}
                                        data-input-type='text'
                                        data-state-name='subTopic2'
                                        onChange={this.inputChangeForConcern}
                                      />
                                    </Form.Field>
                                    <Form.Field disabled={readOnly}>
                                      <Input
                                        fluid
                                        className="collapsed-input"
                                        value={x.subTopic3}
                                        placeholder={trueBeginYear ? `${trueBeginYear + 2} (中)` : false}
                                        data-concern-index={i}
                                        data-input-type='text'
                                        data-state-name='subTopic3'
                                        onChange={this.inputChangeForConcern}
                                      />
                                    </Form.Field>
                                  </Form.Group>
                                  <Form.Group widths='equal'>
                                    <Form.Field disabled={readOnly}>
                                      <Input
                                        className="collapsed-input"
                                        value={x.eng_subTopic1}
                                        placeholder={trueBeginYear ? `${trueBeginYear + 0} (英)` : false}
                                        data-concern-index={i}
                                        data-input-type='text'
                                        data-state-name='eng_subTopic1'
                                        onChange={this.inputChangeForConcern}
                                      />
                                    </Form.Field>
                                    <Form.Field disabled={readOnly}>
                                      <Input
                                        className="collapsed-input"
                                        value={x.eng_subTopic2}
                                        placeholder={trueBeginYear ? `${trueBeginYear + 1} (英)` : false}
                                        data-concern-index={i}
                                        data-input-type='text'
                                        data-state-name='eng_subTopic2'
                                        onChange={this.inputChangeForConcern}
                                      />
                                    </Form.Field>
                                    <Form.Field disabled={readOnly}>
                                      <Input
                                        className="collapsed-input"
                                        value={x.eng_subTopic3}
                                        placeholder={trueBeginYear ? `${trueBeginYear + 2} (英)` : false}
                                        data-concern-index={i}
                                        data-input-type='text'
                                        data-state-name='eng_subTopic3'
                                        onChange={this.inputChangeForConcern}
                                      />
                                    </Form.Field>
                                  </Form.Group>
                                  {this.validator.message('', !!(x.subTopic1 && x.subTopic2 && x.subTopic3) || !!(!x.subTopic1 && !x.subTopic2 && !x.subTopic3), 'accepted')}
                                </>
                              ) : (
                                  <Button
                                    disabled={readOnly}
                                    color='green'
                                    icon='add'
                                    type='button'
                                    data-concern-index={i}
                                    onClick={this.addAnnualSubTopic}
                                    circular
                                  />
                                )}
                            </Table.Cell>
                            {!!process.env.REACT_APP_CONCERN_3YEAR_TIC_SUPPORT && (<>
                              <Table.Cell textAlign='center' className='form-group-no-margin table-cell-with-dropdown'>
                                <Form.Group widths='equal'>
                                  <Form.Field disabled={readOnly}>
                                    <MySelect
                                      options={teacherOption}
                                      value={x.chiefs}
                                      data-concern-index={i}
                                      data-input-type='select'
                                      data-state-name='chiefs'
                                      onChange={this.inputChangeForConcern}
                                      multiple
                                      search
                                      clearable
                                      fluid
                                    />
                                  </Form.Field>
                                </Form.Group>
                                {this.validator.message('concernteacher'+i, x.teachers, 'required')}
                              </Table.Cell>
                              <Table.Cell textAlign='center' className='form-group-no-margin table-cell-with-dropdown'>
                                <Form.Group widths='equal'>
                                  <Form.Field disabled={readOnly}>
                                    <MySelect
                                      options={teacherOption}
                                      value={x.teachers}
                                      data-concern-index={i}
                                      data-input-type='select'
                                      data-state-name='teachers'
                                      onChange={this.inputChangeForConcern}
                                      multiple
                                      search
                                      clearable
                                      fluid
                                    />
                                  </Form.Field>
                                </Form.Group>
                                {this.validator.message('concernteacher'+i, x.teachers, 'required')}
                              </Table.Cell>
                            </>)}
                            <Table.Cell textAlign='center'>
                              <Button
                                disabled={readOnly}
                                color='red'
                                type='button'
                                icon='delete'
                                data-concern-index={i}
                                onClick={this.removeConcern}
                                circular
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                      <Table.Footer fullWidth>
                        <Table.Row>
                          <Table.HeaderCell colSpan={6} textAlign='center'>
                            <Button
                              color='green'
                              content='新增關注項目'
                              icon='add'
                              type='button'
                              onClick={this.addConcern}
                              circular
                              disabled={!beginYear || readOnly}
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
                <Button disabled={readOnly/* || (!tempConcerns.length && !!beginYear)*/} color='green' content='儲存' icon='save' onClick={this.modalToggle('isSaveModalOpen')} circular />
              </Modal.Actions>
            </Modal>

            <ConfirmModal
              open={isDeleteModalOpen}
              description='確定刪除三年計劃？'
              cancel={this.modalToggle('isDeleteModalOpen', false)}
              confirm={this.delete}
            />
            <ConfirmModal
              open={isSaveModalOpen}
              description='確定儲存三年計劃的所有變更？'
              cancel={this.modalToggle('isSaveModalOpen', false)}
              confirm={this.save}
              confirmText='確定並儲存'
              confirmIcon='save'
            />
          </>
        )}
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(ThreeYearPlan);