/* import react */
import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import ErrorLabel from '../../../Components/ErrorLabel';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import SignModal from '../../../Components/SignModal';
import DisplayBeforeComponent from '../Item/DisplayBeforeComponent';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import { markSelectOptions, selectOptions } from '../../../Helper/Helper';
import { momentToDate, dateToMoment, htmlInputDate } from '../../../Helper/TimeHelper';

/* Appraisal css */
import '../../../Styles/Appraisal.css';

/* import lodash */
import { filter, find, orderBy, forEach } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import {
	Grid,
	Button,
	Segment,
	Form,
	Select,
	TextArea,
	Table,
	GridRow,
	GridColumn,
	TableBody,
	FormInput,
	FormSelect,
} from 'semantic-ui-react';
import DisplayBeforeFile from '../Item/DisplayBeforeFile';

const tableColor = ['#FFFFFF', '#eaeaea', '#cfcfcf'];

const EditSelf = (props) => {
	const history = useHistory();
	const [, forceUpdate] = useState();
	const validator = useRef(
		new SimpleReactValidator({
			autoForceUpdate: { forceUpdate: () => forceUpdate(1) },
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				default: '請輸入資料',
			},
		})
	);

	const { section, teacherId, appraisalItemId, appraisalTargetId } = useParams();
	const [loading, setLoading] = useState(false);
	const [teacherInfo, setTeacherInfo] = useState({});
	const [appraisalItem, setAppraisalItem] = useState({});
	const [appraisalTarget, setAppraisalTarget] = useState({});
	const [appraisalContentBeforeTree, setAppraisalContentBeforeTree] = useState([]);
	const [appraisalBefore, setAppraisalBefore] = useState({});
	const [files, setFiles] = useState([]);
	const [appraisalContentSelf, setAppraisalContentSelf] = useState([]);
	const [appraisalContentSelfTree, setAppraisalContentSelfTree] = useState([]);
	const [appraisalSelf, setAppraisalSelf] = useState({});
	const [subjects, setSubjects] = useState([]);
	const [appraisalTextOptions, setAppraisalTextOptions] = useState([]);
	const [appraisalStatus, setAppraisalStatus] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	const [isSignModalOpen, setIsSignModalOpen] = useState(false);
	const isAppeal = section === 'appeal';

	const fetch = async () => {
		setLoading(true);
		const teacherInfo = await get('getOneTeacher/' + teacherId);
		const appraisalItem = await get('getAppraisalItem/' + appraisalItemId);
		const appraisalTarget = await get('getOneAppraisalFormTarget/' + appraisalTargetId);
		let appraisalContentBefore = await get('getAppraisalContentBefore/' + appraisalItemId);
		const appraisalFormBefore = await get('getAppraisalFormBefore/' + appraisalTargetId);
		const appraisalFiles = await get('getAppraisalFile/' + appraisalTargetId);
		let appraisalContentSelf = await get('getAppraisalContentSelf/' + appraisalItemId);
		const appraisalFormSelf = await get('getAppraisalFormSelf/' + appraisalTargetId);
		const subjects = await get('getSubject');
		const appraisalTextOptions = await get('getAppraisalTextOption/' + appraisalItemId);

		let appraisalBefore = {};
		appraisalFormBefore.map(({ appraisalContentId, text, date, score }) => {
			let value = '不適用';
			if (!!score) value = score;
			else if (!!text) value = text;
			else if (!!date) value = dateToMoment(date);
			appraisalBefore[appraisalContentId] = value;
		});

		let appraisalSelf = {};
		appraisalContentSelf.map(({ id, inputType, multiple, notApplicable }) => {
			const record = find(appraisalFormSelf, { appraisalContentId: id });
			let value = record && record.text ? record.text : '';
			if (inputType === 'date') value = record && record.date ? htmlInputDate(record.date) : htmlInputDate();
			else if (inputType === 'score') {
				if (notApplicable) value = record && record.score ? record.score : 'Not applicable';
				else value = record && record.score ? record.score : 0;
			} else if (inputType === 'textSelect' && multiple) value = record && record.text ? record.text.split(', ') : [];
			return (appraisalSelf[id] = value);
		});

		console.log(appraisalFormSelf);

		appraisalContentBefore = orderBy(appraisalContentBefore, ['order', 'id']);
		appraisalContentSelf = orderBy(appraisalContentSelf, ['order', 'id']);

		setTeacherInfo(teacherInfo.length > 0 ? teacherInfo[0] : {});
		setAppraisalItem(appraisalItem.length > 0 ? appraisalItem[0] : {});
		setAppraisalTarget(appraisalTarget.length > 0 ? appraisalTarget[0] : {});
		setAppraisalContentBeforeTree(buildTree(appraisalContentBefore));
		setAppraisalBefore(appraisalBefore);
		setFiles(appraisalFiles);
		setAppraisalContentSelf(appraisalContentSelf);
		setAppraisalContentSelfTree(buildTree(appraisalContentSelf));
		setIsEdit(appraisalFormSelf.length > 0);
		setAppraisalSelf(appraisalSelf);
		setSubjects(selectOptions(subjects, 'displayName', 'displayName'));
		setAppraisalTextOptions(appraisalTextOptions);
		setLoading(false);
	};

	useEffect(() => {
		fetch();
	}, []);

	const inputChange = (inputType, stateName) => (event, data) => {
		let value = inputHandler(inputType, data);
		setAppraisalSelf((appraisalSelf) => ({
			...appraisalSelf,
			[stateName]: value,
		}));
	};

	const displaySelf = (appraisalContentSelfTree, level = 1) => {
		return (
			<React.Fragment>
				{appraisalContentSelfTree.map(
					({ id, description, needInput, inputType, min, max, step, notApplicable, multiple, children }, index) => {
						const label = description;
						const value = appraisalSelf[id];
						return (
							<React.Fragment key={index}>
								<GridColumn
									computer={!needInput || inputType === 'text' ? 16 : 8}
									largeScreen={!needInput || inputType === 'text' ? 16 : 8}
									mobile={16}
									style={{ backgroundColor: `${tableColor[level - 1] || '#FFFFFF'}` }}
								>
									{needInput ? (
										<React.Fragment>
											{inputType === 'text' && (
												<FormInput
													control={TextArea}
													onChange={inputChange('text', id)}
													value={value || ''}
													type="text"
													label={label}
												/>
											)}

											{inputType === 'date' && (
												<FormInput
													type="date"
													onChange={inputChange('text', id)}
													value={value || htmlInputDate()}
													label={label}
												/>
											)}

											{['score', 'subject', 'textSelect'].indexOf(inputType) >= 0 && (
												<FormSelect
													label={label}
													clearable
													search={!!multiple}
													multiple={!!multiple}
													onChange={inputChange('select', id)}
													options={
														inputType === 'subject'
															? subjects
															: inputType === 'score'
															? markSelectOptions(min, max, step, notApplicable)
															: selectOptions(
																	filter(appraisalTextOptions, { appraisalContentId: id }),
																	'displayName',
																	'displayName'
															  )
													}
													value={multiple ? (Array.isArray(value) ? value : []) : value || ''}
												/>
											)}
											{validator.current.message(description, value, 'required')}
										</React.Fragment>
									) : (
										<Form.Field label={label} />
									)}
								</GridColumn>
								{children && displaySelf(children, level + 1)}
							</React.Fragment>
						);
					}
				)}
			</React.Fragment>
		);
	};

	const back = () => {
		let url = `/appraisal/item/form_list/${appraisalItemId}`;
		if (isAppeal) url = `/appraisal/appeal/form_list/${appraisalItemId}/${teacherId}`;
		history.push(url);
	};

	const openSign = (appraisalStatus = null) => {
		if (appraisalStatus !== 'save' && !validator.current.allValid()) {
			validator.current.showMessages();
			alert('資料不完整');
			return;
		}
		setAppraisalStatus(appraisalStatus);
		setIsSignModalOpen(true);
	};

	const save = () => {
		const appraisalSelfArray = Object.keys(appraisalSelf).map((id) => {
			const contentRecord = find(appraisalContentSelf, { id: Number(id) });
			let content = { appraisalContentId: id };
			if (contentRecord.inputType === 'date') {
				content.date = appraisalSelf[id];
			} else if (contentRecord.inputType === 'score') {
				content.score = appraisalSelf[id] === 'Not applicable' || appraisalSelf[id] === '' ? null : appraisalSelf[id];
			} else if (contentRecord.inputType === 'textSelect' && contentRecord.multiple) {
				content.text = appraisalSelf[id].join(', ');
			} else {
				content.text = appraisalSelf[id];
			}
			return content;
		});

		let data = {
			isEdit,
			appraisalTargetId,
			appraisalSelfArray,
			teacherId: isAppeal ? teacherId : null,
			appraisalStatus,
		};

		setLoading(true);
		post('editAppraisalFormSelf', data).then((result) => {
			if (result) {
				validator.current.hideMessages();
				back();
			}
		});
	};

	const buttons = [
		{ key: 'cancel', color: 'red', content: '取消', action: back },
		{ key: 'save', color: 'orange', content: '儲存', action: () => openSign('save'), display: !isAppeal },
		{ key: 'check', color: 'green', content: '提交', action: () => openSign('complete') },
	];

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid>
				<GridRow>
					<GridColumn>
						{isAppeal ? (
							<PageHeader
								title={teacherInfo.name ? '編輯被評估後反思: ' + teacherInfo.name : '編輯被評估後反思'}
								prevPage={back}
							/>
						) : (
							<PageHeader title="進行被評估後反思" prevPage={back} />
						)}
					</GridColumn>
				</GridRow>
				<Grid.Row>
					<Grid.Column>
						<Segment.Group>
							<Segment className="bold">
								<Grid columns={4} stackable>
									<Grid.Row className="larger-font" verticalAlign="middle">
										<Grid.Column>
											{appraisalItem.yearName} : {appraisalItem.displayName}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>被評老師:{appraisalTarget.name}</Grid.Column>
										<Grid.Column>評估科目:{appraisalTarget.subject}</Grid.Column>
										<Grid.Column>評估班別:{appraisalTarget.className}</Grid.Column>
										<Grid.Column>遞交限期:{momentToDate(appraisalTarget.deadline)}</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
							{appraisalContentBeforeTree.length > 0 && (
								<Segment secondary>
									<Table basic="very" padded celled>
										<TableBody>
											<DisplayBeforeComponent
												appraisalContentBeforeTree={appraisalContentBeforeTree}
												appraisalBefore={appraisalBefore}
											/>
										</TableBody>
									</Table>
								</Segment>
							)}
							<DisplayBeforeFile files={files} teacherName={appraisalTarget?.name} />
						</Segment.Group>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Segment.Group>
							<Segment className="bold larger-font">
								<Grid columns={2} stackable>
									<Grid.Row verticalAlign="middle">
										<Grid.Column>評估內容</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
							<Segment padded="very">
								<Form>
									<Grid>{displaySelf(appraisalContentSelfTree)}</Grid>
									<Grid>
										<Grid.Row>
											<GridColumn textAlign="center">
												{buttons.map(({ key, color, content, action, display = true }) => {
													if (display)
														return (
															<Button
																key={key}
																className="button-margin-bottom"
																color={color}
																content={content}
																icon={key}
																type="button"
																onClick={action}
																circular
															/>
														);
												})}
											</GridColumn>
										</Grid.Row>
									</Grid>
								</Form>
							</Segment>
						</Segment.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<SignModal open={isSignModalOpen} close={() => setIsSignModalOpen(false)} save={save} />
		</React.Fragment>
	);
};

export default EditSelf;
