import React, { Fragment, useEffect, useState } from 'react';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import { useHistory, useParams } from 'react-router-dom';
import { get } from '../../../Helper/ApiHelper';
import { dateToMoment, momentToDate } from '../../../Helper/TimeHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import {
	Checkbox,
	Form,
	FormGroup,
	Grid,
	GridColumn,
	GridRow,
	Label,
	Segment,
	SegmentGroup,
	Table,
} from 'semantic-ui-react';
import PageHeader from '../../../Components/PageHeader';
import DisplayBeforeComponent from './DisplayBeforeComponent';
import DisplayBeforeFile from './DisplayBeforeFile';
import { filter, map, orderBy } from 'lodash';

const tableColor = ['#FFFFFF', '#eaeaea', '#cfcfcf'];

const ViewBefore = (props) => {
	const history = useHistory();
	const { section, teacherId, appraisalItemId, appraisalTargetId } = useParams();
	const [loading, setLoading] = useState(false);
	const [teacherInfo, setTeacherInfo] = useState({});
	const [appraisalItem, setAppraisalItem] = useState({});
	const [appraisalTarget, setAppraisalTarget] = useState({});
	const [markingTeachers, setMarkingTeachers] = useState([]);
	const [appraisalContentTree, setAppraisalContentTree] = useState([]);
	const [appraisalBefore, setAppraisalBefore] = useState({});
	const [applicableContentTree, setApplicableContentTree] = useState([]);
	const [applicable, setApplicable] = useState([]);
	const [files, setFiles] = useState([]);
	const isAppeal = section === 'appeal';

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const teacherInfo = await get('getOneTeacher/' + teacherId);
			const appraisalItem = await get('getAppraisalItem/' + appraisalItemId);
			const appraisalTarget = await get('getOneAppraisalFormTarget/' + appraisalTargetId);
			const markingTeachers = await get('getMarkingTeacherByAppraisalTargetId/' + appraisalTargetId);
			const appraisalContents = await get('getAppraisalContentBefore/' + appraisalItemId);
			const appraisalFormBefore = await get('getAppraisalFormBefore/' + appraisalTargetId);
			const appraisalFiles = await get('getAppraisalFile/' + appraisalTargetId);

			let appraisalBefore = {};
			appraisalFormBefore.forEach(({ appraisalContentId, text, date, score }) => {
				let value = '不適用';
				if (!!score) value = score;
				if (!!text) value = text;
				if (!!date) value = dateToMoment(date);
				appraisalBefore[appraisalContentId] = value;
			});

			setTeacherInfo(teacherInfo.length > 0 ? teacherInfo[0] : {});
			setAppraisalItem(appraisalItem.length > 0 ? appraisalItem[0] : {});
			setAppraisalTarget(appraisalTarget.length > 0 ? appraisalTarget[0] : {});
			setMarkingTeachers(markingTeachers);
			setAppraisalContentTree(buildTree(appraisalContents));
			setAppraisalBefore(appraisalBefore);
			setFiles(appraisalFiles);
			setLoading(false);
		};

		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const appraisalNonApplicable = await get(`getAppraisalNonApplicable/${appraisalTargetId}`);
			let appraisalContentOther = await get(`getAppraisalContentOther/${appraisalItemId}`);
			appraisalContentOther = filter(appraisalContentOther, { allowSetApplicable: 1 });
			appraisalContentOther = orderBy(appraisalContentOther, ['order', 'id']);

			const applicableContentNeedInput = filter(appraisalContentOther, { needInput: 1 });
			const nonApplicable = map(appraisalNonApplicable, 'appraisalContentId');
			let applicable = [];
			map(applicableContentNeedInput, ({ id }) => {
				if (nonApplicable.indexOf(id) < 0) applicable.push(id);
			});

			setApplicableContentTree(buildTree(appraisalContentOther));
			setApplicable(applicable);
			setLoading(false);
		};

		if (!!appraisalItem && ['觀課', '科主/同儕觀課'].indexOf(appraisalItem.displayName) >= 0) fetch();
	}, [appraisalItem]);

	const displayApplicableContent = (applicableContentTree, level = 1) => {
		return (
			<React.Fragment>
				{applicableContentTree.map(({ id, description, needInput, inputType, children }) => {
					const value = applicable.indexOf(id) >= 0;
					return (
						<Fragment key={id}>
							<GridColumn
								computer={!needInput || inputType === 'text' ? 16 : 8}
								largeScreen={!needInput || inputType === 'text' ? 16 : 8}
								mobile={16}
								style={{ backgroundColor: `${tableColor[level - 1] || '#FFFFFF'}` }}
							>
								<div style={{ height: '100%', display: 'flex', alignItems: 'flex-start' }}>
									<FormGroup className="no-margin" grouped style={{ width: '100%' }}>
										{needInput ? (
											<Form.Field disabled checked={value} control={Checkbox} label={description} />
										) : (
											<Form.Field label={description} />
										)}
									</FormGroup>
								</div>
							</GridColumn>
							{!!children && displayApplicableContent(children, level + 1)}
						</Fragment>
					);
				})}
			</React.Fragment>
		);
	};

	const back = () => {
		let url = `/appraisal/item/form_list/${appraisalItemId}`;
		if (isAppeal) url = `/appraisal/appeal/form_list/${appraisalItemId}/${teacherId}`;
		history.push(url);
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid>
				<GridRow>
					<GridColumn>
						<PageHeader
							title={`檢視評估詳情${isAppeal && !!teacherInfo.name ? teacherInfo.name : ''}`}
							prevPage={back}
						/>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<SegmentGroup>
							<Segment className="bold">
								<Grid columns={1} stackable>
									<GridRow className="larger-font" verticalAlign="middle">
										<GridColumn>
											{appraisalItem.yearName} : {appraisalItem.displayName}
										</GridColumn>
									</GridRow>
									<GridRow>
										<GridColumn>
											評估老師:
											{markingTeachers.map(({ teacherName }, index) => {
												return <Label style={{ marginLeft: 5 }} key={index} content={teacherName} />;
											})}
										</GridColumn>
									</GridRow>
								</Grid>
								<Grid columns={4} stackable>
									<GridRow>
										<GridColumn>被評老師: {appraisalTarget.name}</GridColumn>
										<GridColumn>評估科目: {appraisalTarget.subject}</GridColumn>
										<GridColumn>評估班別: {appraisalTarget.className}</GridColumn>
										<GridColumn>遞交限期: {momentToDate(appraisalTarget.deadline)}</GridColumn>
									</GridRow>
								</Grid>
							</Segment>
							<Segment>
								<Table basic="very" padded celled>
									<Table.Body>
										{appraisalContentTree.length > 0 && appraisalBefore ? (
											<Fragment>
												<DisplayBeforeComponent
													appraisalContentBeforeTree={appraisalContentTree}
													appraisalBefore={appraisalBefore}
												/>
											</Fragment>
										) : (
											<Table.Row textAlign="center">
												<Table.Cell collapsing>等候被評老師進行設定</Table.Cell>
											</Table.Row>
										)}
									</Table.Body>
								</Table>
							</Segment>
							<DisplayBeforeFile files={files} teacherName={appraisalTarget?.name} />
							{['觀課', '科主/同儕觀課'].indexOf(appraisalItem.displayName) >= 0 && (
								<Segment padded="very">
									<div style={{ marginBottom: 10 }}>
										<b>選擇適用項目：</b>
									</div>
									<Grid>{displayApplicableContent(applicableContentTree)}</Grid>
								</Segment>
							)}
						</SegmentGroup>
					</GridColumn>
				</GridRow>
			</Grid>
		</React.Fragment>
	);
};

export default ViewBefore;
