/* import moment */
import moment from 'moment-timezone';

/* Date To Moment */
export const dobToMoment = (date) => {
	return moment(date, 'DD/MM/YYYY');
};

/* Moment To Database Format */
export const momentToDbFormat = (date) => {
	return dobToMoment(date).format('YYYY-MM-DD');
};

/* Moment To Date */
export const momentToDate = (date) => {
	return moment(date).format('DD/MM/YYYY');
};

/* Date To Moment */
export const dateToMoment = (date) => {
	return moment.tz(date, 'Asia/Hong_Kong').utc();
};

/* Moment now */
export const now = () => {
	return moment();
};

/* File Timestamp */
export const fileTimeStamp = () => {
	return moment().format('YYYYMMDD-HHmmss');
};

/* Check if deadline over */
export const isDeadlineOver = (deadline) => {
	return moment().isAfter(deadline, 'day');
};

/* Display Last Upload Time */
export const displayDateTime = (time) => {
	return moment(time).format('YYYY/MM/DD HH:mm:ss');
};

export const isValid = (date) => moment(date).isValid();

export const timeToDescription = (t) => moment(t).format('YYYY年M月D日');

/* Display date */
export const displayDate = (date) => {
	return moment(date).format('D/M');
};

/* time to string format */
export const timeToStringFormat = (time) => {
	return moment(time, 'HH:mm').format('HH:mm');
};

/* Moment To HTML input */
export const momentToHtmlInput = (date) => {
	return moment(date).format('YYYY-MM-DD');
};

/* Moment To HTML input */
export const htmlInputDate = (date = null) => {
	if (date) {
		return moment(date).format('YYYY-MM-DD');
	} else {
		return moment().format('YYYY-MM-DD');
	}
};
