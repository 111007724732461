/* import lodash */
import { forEach } from 'lodash';

export const base = 100;

export const formContentMarkForOne = (appraisalContentId, max, count, itemFormRecord, contentTree = [], level = 1 ) => {
    if(!count && contentTree.length < 1) {
        return;
    }

    let mark = 0;
    if(contentTree.length > 0) {
        let childMark = 0;
        let childPercentage = 0;

        forEach(contentTree, ({id, max, count, percentage, children}) => {
            let returnMark = formContentMarkForOne(id, max, count, itemFormRecord, children, level + 1 );

            if(!isNaN(returnMark) && count) {
                childMark += returnMark * percentage;
                childPercentage += percentage
            }
        });

        mark += childMark / childPercentage;
    }else{
        let storeMark = 0;
        let score = itemFormRecord[appraisalContentId];
        if(score) {
            storeMark += score;
            mark = storeMark / max * base;
        }
    }
    return mark;
}