/* import lodash */
import {find, flatten, pick} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	ConfirmModal,
	EmptyTableMsg,
	FullscreenDimmer,
  DateInput,
  MySelect
} from '../../../Components';

import {evaluationTypes, surveyTargets} from '../../../Const/Const';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions,
  defaultYearForPlan,
  threeYearPlanAndWhichYearFromYear,
  whetherReadOnlyForYearPlan,
  whetherReadOnlyForReport,
  genEvaluationTitle,
  getTeacherOptions,
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';
import { momentToDate, dateToMoment, now, momentToDbFormat } from '../../../Helper/TimeHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, Input, TextArea } from 'semantic-ui-react';

const RawGroupEvaluationTable = ({
  finishedLoading, strategies, actions, groupId, groupActions, groupOptions, readOnly, canInputManually, evaluations, canApprove,
  myGroupActions, addModalToggle, modalToggle, 
}) => {

  const group = find(groupOptions, {value: groupId});
  const isEnglish = group && group.text === '英文科';

  if(!finishedLoading)
    return <EmptyTableMsg colSpan='6' msg='載入資料中' />;
  if (!strategies.length)
    return <EmptyTableMsg colSpan='6' msg={<>未有設定任何<span className='red'>周年策略大綱</span></>} />;
  if (!actions.length)
    return <EmptyTableMsg colSpan='6' msg={<>未有設定任何<span className='red'>周年計劃項目</span></>} />;
  if (!groupId)
    return <EmptyTableMsg colSpan='6' msg='請先選擇科組' />;
  if (!groupActions.length)
    return <EmptyTableMsg colSpan='6' msg={<>未有設定任何<span className='red'>科組計劃項目</span></>} />;
  if (groupActions.length === 1 && groupActions[0].beginMonth+groupActions[0].endMonth === 0){
    return <EmptyTableMsg colSpan='6' msg={<span className='red'>不適用{!!groupActions[0].title && ` (${groupActions[0].title})`}</span>} />;
  }

  return groupActions.map(gA => {
    const es = evaluations[gA.id] || [];
    const isMyAction = myGroupActions.hasOwnProperty(gA.id);

    const firstCell = (<Table.Cell textAlign="left" rowSpan={es.length || 1}>
      {gA.title}
      {!readOnly && !!(isMyAction || canApprove.canApprove) && <p className="addEvaluationButton"><Button color='green' icon='add' circular onClick={addModalToggle(gA.id)} /></p>}
    </Table.Cell>)
    return es.length ? (es.map((e, j) => (
      <Table.Row key={`evaluation${e.id}`}>
        {!j && firstCell}
        <Table.Cell>{genEvaluationTitle(e,isEnglish)}</Table.Cell>
        <Table.Cell className="textlessCell">
          {e.isSurvey === 1 ? (
            (e.teacherId !== canApprove.teacherId && !canApprove.canApprove) ? (
              <Button
                disabled
                compact
                color='yellow'
                icon='share'
                labelPosition='right'
                content='問卷'
              />
            ) : (
                <Link to={`/plan/group/survey/${+e.id}`}>
                  <Button
                    compact
                    color='yellow'
                    icon='share'
                    labelPosition='right'
                    content='問卷'
                  />
                </Link>
              )
          ) : (
              <Button
                disabled={!e.approved || !canInputManually || (e.teacherId !== canApprove.teacherId && !canApprove.canApprove)}
                compact
                color='yellow'
                icon='pencil'
                labelPosition='right'
                content={e.methodDescription || (+e.isSurvey ? '手動輸入描述' : '手動輸入數字')}
                onClick={modalToggle('isEntryModalOpen', e.id)}
              />
            )}
        </Table.Cell>
        <Table.Cell>{e.dueDate ? momentToDate(e.dueDate) : "不適用"}</Table.Cell>
        <Table.Cell>{e.result === null ? '-' : e.result}</Table.Cell>
        <Table.Cell>
          {(readOnly || e.approved || (canApprove.teacherId !== e.teacherId && !canApprove.canApprove)) ? (
            <Button color={canApprove.teacherId === e.teacherId && e.approved ? 'green' : 'blue'} icon='eye' onClick={modalToggle('isEditModalOpen', e.id)} circular />
          ) : (
              <Button color='blue' icon='edit' onClick={modalToggle('isEditModalOpen', e.id)} circular />
            )}
          {!readOnly && !!canApprove.canApprove && (+e.approved ? (
            <Button color='grey' icon='calendar times outline' onClick={modalToggle('isDisapproveModalOpen', e.id)} circular />
          ) : (
              <Button color='purple' icon='calendar check outline' onClick={modalToggle('isApproveModalOpen', e.id)} circular />
            ))}
          {(!readOnly && !e.approved && canApprove.teacherId === e.teacherId) && (
            <Button color='red' icon='delete' onClick={modalToggle('isDeleteModalOpen', e.id)} circular />
          )}
        </Table.Cell>
      </Table.Row>
    ))) : (
        <Table.Row key={`action${gA.id}`}>
          {firstCell}
          <Table.Cell colSpan={5}><strong>未有評估</strong></Table.Cell>
        </Table.Row>
      )
  })
}

const GroupEvaluationTable = React.memo(RawGroupEvaluationTable);

class GroupStrategyEvaluation extends Component {
  constructor(props) {
    super(props);
    this.mounted = false;

    this.TYPs = [];
    this.selectedTYP = '';
    this.whichYear = '';

    this.strategyTitleLookup = {};
    this.evaluationFlattened = [];
    this.dueDateBackup = null;
    this.myGroupActions = {};

    this.state = {

      year: [],
      yearId: '',
      yearInfo: [],
      readOnly: true,
      canApprove: {
        teacherId: null,
        canApprove: false,
      },
      canInputManually: false,
      
      finishedLoading: false,
      dimmerOpen: false,

      groupId: null,
      groupOptions: [],

      aims: [],

      strategies: [],

      actionId: null,
      actions: [],
      actionOptions: [],

      evaluations: [],

      groupActions: [],
      groupActionId: '',
      groupActionOptions: [],

      isSurvey: true,
      target: 1,
      dueDate: null,
      criteria: '',
      threshold: '',
      unit: '',
      approved: false,
      methodDescription: null,
      result: null,
      teacherId: null,

      onceASurvey: false,
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      selectedId: null,
    }

    this.evaluationValidator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        required: '請輸入:attribute',
        numeric: '請輸入數字:attribute',
        min: '請輸入最少 0',
      }
    });
    this.inputValidator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        default: '請輸入資料'
      }
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch(true,true);
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when mounting 1 year strategies');
      console.log(err);
    }
  }
  
  fetch = async (resetYear = false, resetAction = false) => {
    try{
      const [yearInfo, groups] = await Promise.all([
        get('getYear'),
        get('getGroup'),
      ]);


      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId: resetYear ? defaultYearForPlan(yearInfo) : this.state.yearId,

        groupOptions: selectOptions(groups, 'name', 'id'),
        groupId: this.state.groupId||'',
      });

      const teacherOptions = await getTeacherOptions(this.state.yearId);

      await this.setStateAsync({
        readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId),
        teacherOptions,
        canInputManually: !whetherReadOnlyForReport(this.state.yearInfo, this.state.yearId),
      });

      await this.fetch3YearPlanDetails(resetAction);
      await this.fetchGroupActions();
    }catch(err){
      console.log('Error when fetching basic info for group evaluation');
      console.log(err);
    }
  }

  fetch3YearPlanDetails = async (resetAction = false) => {
    try{
      
      const TYPs = await get('getTYP');

      const [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(this.state.yearInfo, TYPs, this.state.yearId || undefined);

      const hasTYPchanged = this.selectedTYP !== selectedTYP;

      this.TYPs = TYPs;
      if(!this.selectedTYP || hasTYPchanged){
        this.selectedTYP = +selectedTYP;
      }
      this.whichYear = +whichYear;

      const concerns = await get(`get3YearConcern/${+this.selectedTYP}`);

      const rawAims = await Promise.all(concerns.map(concern => get(`get3YearAim/${+concern.id}`)));

      const aimPrefix = {};
      rawAims.forEach((aims,i)=>{
        aims.forEach((aim,j)=>{
          aim.title = `${i+1}.${j+1}. ${aim.title}`;
          aimPrefix[aim.id] = `${i+1}.${j+1}.`;
        })
      });

      const aims = flatten(rawAims);

      await this.setStateAsync({aims,});

      const rawStrategies = await Promise.all(this.state.aims.map(a => get(`get3YearStrategy/${+a.id}`)));

      rawStrategies.forEach((ss, i)=>{
        ss.forEach((s, j)=>{
          s.title = `${aimPrefix[s.aimId]}${j+1}. `+s.title;
        });
      })

      const strategies = flatten(rawStrategies);

      await this.setStateAsync({
        strategies: strategies.filter(s => +s[`year${1+this.whichYear}`]),
      });

      const rawActions = await Promise.all(this.state.strategies.map(s => get(`get1YearAction/${this.state.yearId}/${+s.id}`)));

      const actions = flatten(rawActions);

      const actionNotInNewActions = !find(actions, {id: this.state.actionId});

      this.strategyTitleLookup = this.state.strategies.reduce((prev,cur)=>({
        ...prev,
        [cur.id]: cur.title
      }),{})

      await this.setStateAsync({
        actions,
        actionId: (resetAction || actionNotInNewActions) && actions.length ? actions[0].id : this.state.actionId,
        actionOptions: selectOptions(actions, a=>this.strategyTitleLookup[a["3yearstrategyId"]], "id"),
      });

    }catch(err){
      console.log('Error when fetching TYP detail for 1 year evaluation');
      console.log(err);
    }
  }

  fetchGroupActions = async () => {
    try{
      const [
        groupActions,
        myGroupActions,
        canApprove
      ] = await Promise.all([
        get(`getGroupAction/${+this.state.groupId}/${+this.state.actionId}`),
        get(`getMyGroupAction/${+this.state.groupId}/${+this.state.actionId}`),
        get(`getGroupApprove/${+this.state.groupId}/${this.state.yearId}`)
      ]);
  
      await this.setStateAsync({
        groupActions,
        canApprove,
        groupActionOptions: selectOptions(groupActions, "title", "id")
      });

      this.myGroupActions = myGroupActions.reduce((prev,cur)=>({
        ...prev,
        [cur.id]: true
      }), {});
      
      const rawEvaluations = await Promise.all(this.state.groupActions.map(a => get(`getGroupEvaluation/${+a.id}`)));

      const evaluations = {};

      this.state.groupActions.forEach((gA,i)=>{
        evaluations[gA.id] = rawEvaluations[i];
      })

      this.evaluationFlattened = flatten(rawEvaluations);

      await this.setStateAsync({evaluations});

    }catch(err){
      console.log('Error when fetching group strategies');
      console.log(err);
    }
  }

  /* input update handler */
  inputChange = (event, value) => {
    let inputData;
    //special case for remove icon in semantic-ui-react select
    if(event.target.tagName==='I'){
      inputData = event.target.closest('.ui.dropdown, textarea, button').dataset;
    }else{
      inputData = event.target.closest('.ui, textarea, button').dataset;
    }
    const {inputType, stateName} = inputData;
    let trueValue = inputHandler(inputType, value);
    if(trueValue==='' && inputType==='select'){
      trueValue = null;
    }
    this.setStateAsync({
      [stateName]: trueValue
    });
  }

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId } = this.state;
      if (yearId !== value) {

        await this.setStateAsync({
          yearId: value,
          finishedLoading: false,
        });

        const teacherOptions = await getTeacherOptions(this.state.yearId);

        await this.setStateAsync({
          readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId),
          teacherOptions,
          canInputManually: !whetherReadOnlyForReport(this.state.yearInfo, this.state.yearId),
        });

        await this.fetch3YearPlanDetails();
        await this.fetchGroupActions();
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      console.log("Error when changing year for Group Action");
      console.log(err);
    }
  }

  /* Aim Change */
  actionChange = async (event, { value }) => {
    try{
      const { actionId } = this.state;
      if (actionId !== value) {
        await this.setStateAsync({
          actionId: value,
          finishedLoading: false,
        });
        
        await this.fetch3YearPlanDetails();
        await this.fetchGroupActions();
        this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      console.log("Error when changing aim for One Year Evaluation");
      console.log(err);
    }
  }

  groupChange = async (event, { value }) => {
    try {
      const { groupId } = this.state;
      if (groupId !== value) {
        await this.setStateAsync({
          finishedLoading: false,
          groupId: value,
        });
        
        await this.setStateAsync({
          readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId),
          canInputManually: !whetherReadOnlyForReport(this.state.yearInfo, this.state.yearId),
        });

        await this.fetchGroupActions();
        await this.setStateAsync({ finishedLoading: true });
      }
    } catch (err) {
      console.log("Error when changing group for Group Action");
      console.log(err);
    }
  }

  inputChangeForMethod = (event, { value }) => {
    const { isSurvey } = this.state;
    if(value === isSurvey) return;

    const data = { isSurvey: value };
    if(value !== 1){
      data.dueDate = null;
      if(isSurvey === 1){
        data.methodDescription = "手動輸入";
      }
    }else{
      const curYear = find(this.state.yearInfo, {id: this.state.yearId}); 
      if(this.dueDateBackup){
        data.dueDate = this.dueDateBackup;
      }else{
        if(curYear){
          data.dueDate = dateToMoment(curYear.endDate);
        }else{
          data.dueDate = now().month(7).date(31);
        }
      }
      Object.assign(data, {
        threshold: null,
        unit: '',
        methodDescription: '',
      })
    }
    this.setStateAsync(data);
  }

  dueDateChange = (event, data) => {
    let value = inputHandler('date', data);
    this.setStateAsync({ dueDate: value });
    this.dueDateBackup = value;
  }

  addDefaultData = (data) => {
    const curYear = find(this.state.yearInfo, {id: this.state.selectedYear});
    const dueDate = curYear?dateToMoment(curYear.endDate):now().month(7).date(31);
    const result = {
      selectedId: null,
      groupActionId: null,
      onceASurvey: false,
      criteria: '',
      isSurvey: 1,
      target: 1,
      approved: false,
      dueDate,
      threshold: null,
      unit: '',
      result: '',
      methodDescription: null,
      teacherId: null,
      teacherId: this.state.canApprove?this.state.canApprove.teacherId:null,
      ...data,
    };
    return result;
  }
  
  /* modal toggle */
  addModalToggle = (selectedId = null) => () => {
    this.setStateAsync(this.addDefaultData({
      isEditModalOpen: true,
      groupActionId: selectedId,
    }));
  }

  /* modal toggle */
  modalToggle = (modalStateName, selectedId = null) => () => {
    let data = {
      [modalStateName]: !this.state[modalStateName],
      selectedId,
    };
    if (selectedId) {
      data.selectedId = selectedId;
      const ref = find(this.evaluationFlattened, { id: selectedId }) || {};
      data.teacherId = ref.teacherId;
      switch (modalStateName) {
        case 'isEditModalOpen':
          // backup when show edit modal for method switching
          if(data.isEditModalOpen && ref.dueDate){
            this.dueDateBackup = ref.dueDate;
          }else{
            this.dueDateBackup = null;
          }
          Object.assign(data, pick(ref, ['groupActionId', 'isSurvey', 'dueDate', 'target', 'criteria', 'threshold', 'unit', 'approved', 'methodDescription', 'teacherId', 'disapprovalReason']));
          
          data.onceASurvey = data.isSurvey === 1;
          data.dueDate = ref.dueDate ? dateToMoment(ref.dueDate) : null;
          break;
        case 'isEntryModalOpen':
          Object.assign(data, pick(ref, ['result', 'teacherId', 'isSurvey']));
          break;
        default:break;
      }
    } else {
      data = this.addDefaultData(data);
    }
    this.evaluationValidator.hideMessages();
    this.inputValidator.hideMessages();
    this.setStateAsync(data);
  }

  /* save record */
  save = async () => {
    if (!this.evaluationValidator.allValid()) {
      this.evaluationValidator.showMessages();
      return;
    }
    
    try{
      const data = pick(this.state, ['groupActionId', 'isSurvey', 'target', 'criteria', 'threshold', 'unit', 'methodDescription', 'teacherId', 'disapprovalReason']);
      if(this.state.selectedId){
        data.id = this.state.selectedId;
      }
      data.dueDate = this.state.dueDate===null?null:momentToDbFormat(this.state.dueDate);
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('editGroupEvaluation', data);
      if(result && result.status){
        this.setStateAsync(this.addDefaultData({isEditModalOpen: false}));
      }else{
        throw result;
      }
      this.evaluationValidator.hideMessages();
      await this.fetch();
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存科組計劃評估項目時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  saveInput = async () => {
    if(!this.inputValidator.allValid()){
      this.inputValidator.showMessages();
      return;
    }
    try{
      const data = pick(this.state, ['result', 'teacherId']);
      if(this.state.selectedId){
        data.id = this.state.selectedId;
      }
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('editGroupEvaluation', data);
      if(result && result.status){
        this.setStateAsync(this.addDefaultData({isEntryModalOpen: false}));
      }else{
        throw result;
      }
      this.inputValidator.hideMessages();
      await this.fetch();
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存科組計劃評估項目手動輸入數值時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  /* delete record */
  delete = async () => {
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('deleteGroupEvaluation', { id: this.state.selectedId });
      if(!result || !result.status){
        throw result;
      }
      await this.fetch();
    }catch(err){
      if(err!=='unmounted'){
        alert("刪除科組計劃評估項目時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync(this.addDefaultData({
      finishedLoading: true,
      dimmerOpen: false,
      isDeleteModalOpen: false,
    }));
  }

  approve = async () => {
    if(!this.state.selectedId){
      alert("未有選擇評核");
      return;
    }
    try{
      const data = {
        id: this.state.selectedId,
        teacherId: this.state.teacherId,
        approved: true,
      }
      data.id = this.state.selectedId;
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('editGroupEvaluation', data);
      if(!result || !result.status){
        throw result;
      }
      this.inputValidator.hideMessages();
      await this.fetch();
    }catch(err){
      if(err!=='unmounted'){
        alert("批核科組計劃評估項目時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync(this.addDefaultData({
      isApproveModalOpen: false,
      finishedLoading: true,
      dimmerOpen: false,
    }));
  }

  disapprove = async () => {
    if(!this.state.selectedId){
      alert("未有選擇評核");
      return;
    }
    try{
      const data = {
        id: this.state.selectedId,
        teacherId: this.state.teacherId,
        approved: false,
      }
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('editGroupEvaluation', data);
      if(!result || !result.status){
        throw result;
      }
      this.inputValidator.hideMessages();
      await this.fetch();
    }catch(err){
      if(err!=='unmounted'){
        alert("取消批核科組計劃評估項目時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync(this.addDefaultData({
      isDisapproveModalOpen: false,
      finishedLoading: true,
      dimmerOpen: false,
    }));
  }

  render() {
    const {
      finishedLoading,
      dimmerOpen,

      year,
      yearId,
      readOnly,
      canInputManually,
      canApprove,
      
      strategies,

      groupOptions,
      groupId,

      actions,
      actionId,
      actionOptions,

      groupActions,
      groupActionId,
      groupActionOptions,

      evaluations,

      selectedId,
      isSurvey,
      dueDate,
      target,
      criteria,
      threshold,
      unit,
      result,
      methodDescription,
      teacherId,
      teacherOptions,

      onceASurvey,

      isEditModalOpen,
      isDeleteModalOpen,
      isEntryModalOpen,
      isApproveModalOpen,
      isDisapproveModalOpen,
    } = this.state;

    this.evaluationValidator.purgeFields();
    this.inputValidator.purgeFields();
    
    const e = find(flatten(Object.values(evaluations)), {id: selectedId});
    let modalReadOnly = !!selectedId;
    if(e){
      modalReadOnly = readOnly || e.approved || ( canApprove.teacherId !== e.teacherId && !canApprove.canApprove);
    }

    return (<>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='科組計劃 - 成功準則及評估方法' subTitle='為每個科組策略項目設定評估方法' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Segment padded>
                <Form>
                  <Form.Group>
                    <Form.Field inline>
                      <label>周年</label>
                      <MySelect compact disabled={!finishedLoading} options={year || []} value={yearId} onChange={this.yearChange} />
                    </Form.Field>
                    <Form.Field inline>
                      <label>周年計劃項目</label>
                      <MySelect
                        compact
                        disabled={!finishedLoading || !actionOptions.length}
                        options={actionOptions}
                        value={actionId}
                        onChange={this.actionChange}
                        placeholder={actionOptions.length?"請選擇":"沒有周年計劃項目"}
                        error={!actionOptions.length}
                      />
                    </Form.Field>
                    <Form.Field inline>
                      <label>科組</label>
                      <MySelect
                        error={!groupId}
                        disabled={!finishedLoading}
                        options={groupOptions}
                        value={groupId}
                        onChange={this.groupChange}
                        placeholder="請先選擇科組"
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Table textAlign='center' celled unstackable fixed structured>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={5}>科組計劃項目</Table.HeaderCell>
                    <Table.HeaderCell width={3}>成功準則</Table.HeaderCell>
                    <Table.HeaderCell width={2}>方法</Table.HeaderCell>
                    <Table.HeaderCell width={2}>截止日期</Table.HeaderCell>
                    <Table.HeaderCell width={1}>結果</Table.HeaderCell>
                    <Table.HeaderCell width={3}>行動</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <GroupEvaluationTable
                    finishedLoading={finishedLoading}
                    strategies={strategies}
                    actions={actions}
                    groupId={groupId}
                    groupOptions={groupOptions}
                    groupActions={groupActions}
                    readOnly={readOnly}
                    canInputManually={canInputManually}
                    evaluations={evaluations}
                    canApprove={canApprove}
                    myGroupActions={this.myGroupActions}
                    addModalToggle={this.addModalToggle}
                    modalToggle={this.modalToggle}
                  />
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen ? (<FullscreenDimmer active={dimmerOpen} isLoading={true} />) : (
        <>
          <Modal open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>設定策略評估方法</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <Form>
                  {finishedLoading && (
                    <Form.Group className='form-group-margin'>
                      <Form.Field width={10} required>
                        <label>策略項目</label>
                        <MySelect
                          fluid
                          value={groupActionId||''}
                          disabled={modalReadOnly}
                          options={groupActionOptions}
                          data-input-type='select'
                          data-state-name='groupActionId'
                          onChange={this.inputChange}
                          placeholder='請選擇'
                        />
                        {this.evaluationValidator.message('策略項目', !!groupActionId, 'required|accepted')}
                      </Form.Field>
                      <Form.Field width={6} required>
                        <label>負責教師</label>
                        <MySelect
                          fluid
                          search
                          value={teacherId||''}
                          disabled={modalReadOnly || !canApprove.canApprove}
                          options={teacherOptions}
                          data-input-type='select'
                          data-state-name='teacherId'
                          onChange={this.inputChange}
                          placeholder='請選擇教師'
                          noResultsMessage='找不到教師'
                        />
                        {this.evaluationValidator.message('教師', teacherId, 'required')}
                      </Form.Field>
                    </Form.Group>
                  )}

                  <Form.Group className='form-group-margin'>
                    <Form.Field width={8}>
                      <label>方法</label>
                      <MySelect
                        options={evaluationTypes}
                        disabled={modalReadOnly}
                        value={isSurvey}
                        onChange={this.inputChangeForMethod}
                        placeholder='請選擇'
                      />
                      {(!!onceASurvey && isSurvey!==1) && <p className='red'>這樣做會清除之前建立的問卷項目 (如存在)</p>}
                      {this.evaluationValidator.message('方法', isSurvey, 'required')}
                    </Form.Field>
                    {isSurvey === 1 ? (
                      <>
                        <Form.Field width={4}>
                          <label>設計問卷截止日期</label>
                          <DateInput
                            popupPosition='bottom center'
                            value={dueDate===null?'':momentToDate(dueDate)}
                            onChange={this.dueDateChange}
                            disabled={modalReadOnly}
                          />
                        </Form.Field>
                        <Form.Field width={4}>
                          <label>對象</label>
                          <MySelect
                            options={surveyTargets}
                            disabled={modalReadOnly}
                            value={target}
                            data-input-type='select'
                            data-state-name='target'
                            onChange={this.inputChange}
                            placeholder='請選擇'
                          />
                          {this.evaluationValidator.message('對象', target, 'required')}
                        </Form.Field>
                      </>
                    ) : (
                      <Form.Field width={8}>
                        <label>評估方法描述</label>
                        <Input
                          value={methodDescription||''}
                          data-input-type='text'
                          data-state-name='methodDescription'
                          onChange={this.inputChange}
                          placeholder='方法描述'
                          disabled={modalReadOnly}
                          className="more-opaque"
                        />
                      </Form.Field>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Field width={8} required>
                      <label>成功準則</label>
                      <Input
                        value={criteria||''}
                        data-input-type='text'
                        data-state-name='criteria'
                        onChange={this.inputChange}
                        placeholder='成功準則'
                        disabled={modalReadOnly}
                        className="more-opaque"
                      />
                      {this.evaluationValidator.message('成功準則', criteria, 'required')}
                    </Form.Field>
                    {isSurvey === 0 && (<>
                      <Form.Field width={4} required>
                        <label>門檻 (數字)</label>
                        <Input
                          type="number"
                          value={threshold===null?'':threshold}
                          data-input-type='number'
                          data-state-name='threshold'
                          onChange={this.inputChange}
                          placeholder='標題'
                          disabled={modalReadOnly}
                          className="more-opaque"
                        />
                        {this.evaluationValidator.message('門檻', threshold, 'required|numeric|min:0,num')}
                      </Form.Field>
                      <Form.Field width={4} required>
                        <label>門檻單位</label>
                        <Input
                          value={unit||''}
                          data-input-type='text'
                          data-state-name='unit'
                          onChange={this.inputChange}
                          placeholder='標題'
                          disabled={modalReadOnly}
                          className="more-opaque"
                        />
                        {this.evaluationValidator.message('單位', unit, 'required')}
                      </Form.Field>
                    </>)}
                  </Form.Group>
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
              <Button disabled={modalReadOnly} color='green' content='儲存' icon='save' onClick={this.save} circular />
            </Modal.Actions>
          </Modal>
          
          <Modal open={isEntryModalOpen} onClose={this.modalToggle('isEntryModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header>輸入策略評估結果</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <Form>
                  <Form.Group>
                    <Form.Field inline>
                      <label>結果</label>
                      {+isSurvey?(
                        <TextArea
                          value={result===null?'':result}
                          data-input-type='text'
                          data-state-name='result'
                          onChange={this.inputChange}
                          placeholder='請輸入'
                        />
                      ):(
                        <Input
                          type="number"
                          value={result===null?'':result}
                          data-input-type='text'
                          data-state-name='result'
                          onChange={this.inputChange}
                          placeholder='請輸入純數字'
                        />
                      )}
                      {this.inputValidator.message('result', result, 'required')}
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEntryModalOpen')} circular />
              <Button color='green' content='儲存' icon='save' onClick={this.saveInput} circular />
            </Modal.Actions>
          </Modal>

          <ConfirmModal
            open={isDeleteModalOpen}
            description={<>確定<span className='red'>刪除</span>策略評估方法？</>}
            cancel={this.modalToggle('isDeleteModalOpen', false)}
            confirm={this.delete}
          />

          <ConfirmModal
            open={isApproveModalOpen}
            description={<>確定<span className='red'>批核</span>策略評估方法？</>}
            cancel={this.modalToggle('isApproveModalOpen', false)}
            confirm={this.approve}
            confirmText="批核"
            confirmIcon="calendar check"
          />

          <ConfirmModal
            open={isDisapproveModalOpen}
            description={<>確定<span className='red'>取消批核</span>策略評估方法？</>}
            cancel={this.modalToggle('isDisapproveModalOpen', false)}
            confirm={this.disapprove}
            confirmText="取消批核"
            confirmIcon="calendar times"
          />
        </>)}
      </>
    )
  }
}

export default withRouter(GroupStrategyEvaluation);