//import Concern from './Concern';
import Strategies from './Strategies';
import SWOP from './SWOP';
import Budget from './Budget';
import Evaluations from './Evaluations';
import Timetable from './Timetable';

export default {
  //Concern,
  Strategies,
  SWOP,
  Budget,
  Evaluations,
  Timetable,
}