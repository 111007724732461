/* import react */
import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';

import {
  PageHeader,
  ConfirmModal,
  FullscreenDimmer,
  MyTable,
  MySelect,
  Buttons,
  BlockerPrompt,
} from '../../Components';

import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import { getTeacherOptions } from '../../Helper/Helper';
import { useValidator } from '../../Helper/HookHelper';
import { privilegeTypes } from '../../Const/Const';

import { Grid, Modal, Segment, Form, Checkbox, Input, Icon } from 'semantic-ui-react';
import { find, get as _get, groupBy, mapValues } from 'lodash';
import { User } from '../../Context';
import { useRouteMatch, useHistory } from 'react-router-dom';

const fields = privilegeTypes;

const Permission = () => {
  const [validator] = useValidator({
    messages: {
      required: '請輸入:attribute',
    },
  })
  const [loadState, setLoadState] = useState({isLoading: true, dimmerOpen: false, isBlocking: false});
  const [modules, setModules] = useState([]);
  const [roles, setRoles] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [modalState, setModalState] = useState({edit: false, delete: false});
  const [editData, setEditData] = useState({
    id: null,
    displayName: '',
    permission: {},
    teacherRole: [],
  });
  const [reload, setReload] = useState(0);
  const {pingUser} = useContext(User);
  const {path} = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    setLoadState(x=>({ ...x, loading: true }));
    const abortController = new AbortController();
    const fetchLevels = async () => {
      try {
        const [module, menu, role, permission, teacherRole, teacherOptions] = await Promise.all(
          ['getAllModule', 'getAllMenu', 'getAllRole', 'getAllPermission', 'getAllTeacherRole'].map(get).concat(getTeacherOptions())
        );

        const groupedMenu = groupBy(menu, 'moduleId');
        for(let m of module) m.menus = groupedMenu[m.id] || [];

        const permissionByRole = groupBy(permission, 'roleId');
        const teacherByRole = groupBy(teacherRole, 'roleId');
        for(let r of role){
          r.teachers = teacherByRole[r.id] || [];
          r.permissions = permissionByRole[r.id] || [];
        }

        setModules(module);
        setRoles(role);
        setTeacherOptions(teacherOptions);
        setModalState(x=>mapValues(x, ()=>false));
        setLoadState(x=>mapValues(x, ()=>false));
      } catch (err) {
        if(err.name!=='AbortError')
          console.log(err);
      }
    };
    fetchLevels();
    return () => {
      abortController.abort();
    }
  }, [reload]);
  
  const modalToggle = useCallback((eventOrStateName) => {
    let modalData = {};
    if(typeof eventOrStateName === 'object'){
      if(eventOrStateName.target.classList.contains('modals')){
        modalData = eventOrStateName.target.firstElementChild.dataset;
      }else{
        modalData = eventOrStateName.target.closest('.ui, button').dataset;
      }
    }else{
      modalData = {
        modalname: eventOrStateName
      }
    }
    const {modalname, id} = modalData;
    
    if(modalState[modalname]){
      validator.hideMessages();
    }

    const editData = {
      id: +id||null,
      displayName: '',
      permission: modules.reduce((prev, cur) => ({
        ...prev,
        [cur.id]: new Set()
      }), {}),
      teacherRole: [],
    };

    const menuToModule = new Map();
    for(let m of modules){
      for(let m2 of m.menus){
        menuToModule.set(m2.id, m.id);
      }
    }

    if (id) {
      const obj = find(roles, { id: +id });
      if(obj){
        editData.displayName = obj.displayName;
        editData.teacherRole = obj.teachers.map(x=>x.teacherId);
        for(let p of obj.permissions){
          editData.permission[menuToModule.get(p.menuId)].add(p.menuId);
        }
        for(let {field} of fields){
          editData[field] = obj[field];
        }
      }
    }

    setLoadState(x=>({...x, isBlocking: false}));
    setEditData(editData);
    setModalState(x=>({...x, [modalname]: !x[modalname]}))
  }, [modules, roles, modalState, validator]);

  const inputChange = useCallback((event, data) => {
    let inputData;
    if(event.target.tagName === 'I'){
      inputData = event.target.closest('.ui.selection').dataset;
    }else{
      inputData = event.target.closest('.ui').dataset;
    }
    const {inputType, stateName} = inputData;
    setEditData(x => ({ ...x, [stateName]: inputHandler(inputType, data) }));
    setLoadState(x=>({...x, isBlocking: true}));
  }, []);

  const tableColumnData = useMemo(()=>{
    return [
      {headerName: '權限名稱', width: 3, cellRender: 'displayName'},
      ...fields.map(x=>({
        headerName: x.label, field: x.field, width: 2, cellRender: y=>!!y[x.field] && <Icon name='check'/>, cellClassName: 'textlessCell'
      })),
      {
        headerName: '行動',
        width: 2,
        cellClassName: 'textless-cell',
        cellRender: x=><><Buttons.EditButton data-id={x.id} data-modalname='edit' onClick={modalToggle} />
          {(x.id !== 26) && <Buttons.DeleteButton data-id={x.id} data-modalname='delete' onClick={modalToggle} />}</>
      }
    ]
  }, [modalToggle]);

  const save = () => {
    if (!validator.allValid()){
      validator.showMessages();
      return;
    }
    setLoadState(x=>({ ...x, dimmerOpen: true }));
    const abortController = new AbortController();
    const f = async () => {
      try {
        let permission = [];
        for(let menuIds of Object.values(editData.permission)){
          permission = permission.concat(...menuIds)
        }
        const result = await post('editRole', {...editData, permission});
        if(_get(result, 'status')){
          pingUser({
            path,
            signal: abortController.signal
          }).then(r=>{
            if(r) setReload(x=>x+1)
            else{
              setLoadState(x=>({...x, isBlocking: false}))
              alert("抱歉, 你沒有存取本頁的權限");
              history.push('/')
              return;
            }
          })
          setReload(x=>x+1);
        }else{
          setLoadState(x=>({...x, dimmerOpen: false}));
        }
      } catch (err) {
        if(err.name!=='AbortError')
          console.log(err);
      }
    };
    f();
    return () => {
      abortController.abort();
    }
  }

  const del = useCallback(()=>{
    setLoadState(x=>({ ...x, dimmerOpen: true }));
    const abortController = new AbortController();
    const f = async () => {
      try {
        const result = await post('deleteRole', {id: editData.id});
        if(_get(result, 'status')){
          setReload(x=>x+1);
        }else{
          setLoadState(x=>({...x, dimmerOpen: false}));
        }
      } catch (err) {
        if(err.name!=='AbortError')
          console.log(err);
      }
    };
    f();
    return () => {
      abortController.abort();
    }
  }, [editData.id]);

  
  const moduleToggle = useCallback((event, data) => {
    let {moduleId, menuId} = event.target.closest('.ui').dataset;
    const {checked} = data;
    setLoadState(x=>({...x, isBlocking: true}));

    if(!menuId){
      const moduleMenus = _get(find(modules, {id: +moduleId}), 'menus', []).map(x=>x.id);
      setEditData(x=>({...x, permission: {
        ...x.permission,
        [moduleId]: checked ? new Set(moduleMenus) : new Set()
      }}))
      return;
    }
    setEditData(x=>{
      const newSet = new Set(x.permission[moduleId]);
      if(checked){
        newSet.add(+menuId);
      }else{
        newSet.delete(+menuId);
      }
      return {...x, permission: {
        ...x.permission,
        [moduleId]: newSet
      }}
    })
  }, [modules]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='權限管理' subTitle='設定用戶權限' floated='left' />
            <Buttons.AddButton content='新增權限' floated='right' data-modalname='edit' onClick={modalToggle} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <MyTable
              data={roles}
              tableColumnData={tableColumnData}
              finishedLoading={!loadState.isLoading}
              unstackable={true}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <FullscreenDimmer active={loadState.dimmerOpen} isLoading={true} />
      <BlockerPrompt isBlocking={loadState.isBlocking}/>
      {!loadState.dimmerOpen && (<>
        <Modal open={modalState.edit} data-modalname='edit' onClose={modalToggle} closeOnEscape={false} closeOnDimmerClick={false} id="editPermission">
          <Modal.Header>編輯權限</Modal.Header>
          <Modal.Content>
            <Segment basic>
              <Form>
                <Form.Group className='form-group-margin' grouped>
                  <Form.Field required>
                    <label>權限名稱</label>
                    <Input value={editData.displayName} data-input-type='text' data-state-name='displayName' onChange={inputChange} placeholder='權限名稱' />
                    {validator.message('名稱', editData.displayName, 'required')}
                  </Form.Field>
                </Form.Group>
                {!!fields.length && (<Form.Group className='form-group-margin' inline>
                  {fields.map(x=><Form.Checkbox
                    key={x.field}
                    label={x.label}
                    checked={editData[x.field]}
                    data-input-type='checkbox'
                    data-state-name={x.field}
                    onChange={inputChange}
                  />)}
                </Form.Group>)}
                <Form.Group className='form-group-margin' grouped>
                  <label>權限範圍</label>
                  <Segment>
                    {modules.map(m => {
                      return (
                        <Form.Group className='form-group-margin' key={m.id} grouped>
                          <Form.Group inline>
                            <label>{m.displayName}</label>
                            <Form.Checkbox
                              checked={_get(editData, ['permission',m.id,'size'])>0}
                              data-module-id={m.id}
                              onChange={moduleToggle}
                              toggle
                            />
                          </Form.Group>
                          {_get(editData, ['permission',m.id,'size'])>0 &&
                            <Form.Group inline className="permission-group">
                              {m.menus.map(m2 => {
                                return m2.header ? <Form.Field width={16} key={m2.id}><label>{m2.displayName}</label></Form.Field> : (
                                  <Form.Field key={m2.id}>
                                    <Checkbox
                                      checked={editData.permission[m.id].has(m2.id)}
                                      data-module-id={m.id}
                                      data-menu-id={m2.id}
                                      onChange={moduleToggle}
                                      label={m2.displayName}
                                    />
                                  </Form.Field>
                                )
                              })}
                            </Form.Group>
                          }
                        </Form.Group>
                      )
                    })}
                  </Segment>
                </Form.Group>
                <Form.Group className='form-group-margin' grouped>
                  <Form.Field>
                    <label>新增擁有此權限之老師</label>
                    <MySelect
                      fluid
                      multiple
                      search
                      value={editData.teacherRole}
                      options={teacherOptions}
                      data-input-type='select'
                      data-state-name='teacherRole'
                      onChange={inputChange}
                      noResultsMessage="找不到教師"
                      placeholder='套用在...'
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Buttons.CancelButton data-modalname='edit' onClick={modalToggle} />
            <Buttons.SaveButton onClick={save} />
          </Modal.Actions>
        </Modal>
        <ConfirmModal
          closeOnEscape={true}
          open={modalState.delete}
          description={<>確定刪除<span className='red bold'>{editData.displayName}</span>權限？</>}
          modalname='delete'
          cancel={modalToggle}
          confirm={del}
        />
      </>)}
    </>
  )
}

export default Permission;