/* import lodash */
import { pick, keyBy, groupBy, findIndex } from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

/* import components */
import { PageHeader, ErrorLabel, FullscreenDimmer, ConfirmModal, BlockerPrompt, MySelect } from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { genEvaluationTitle, getTeacherOptions, selectOptions, genStudentOptionName } from '../../Helper/Helper';
import { inputHandler } from '../../Helper/FormHelper';
import { momentToDate, dateToMoment, momentToDbFormat, now } from '../../Helper/TimeHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

import '../../Styles/SurveyDesign.css';

/* import semantic-ui element */
import { Grid, Form, Button, Segment, Container, Input, Header, Ref, TextArea, Icon } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

class SurveyDesign extends Component {
	constructor(props) {
		super(props);
		this.targetMap = ['', '教師', '學生', '師生'];
		this.counter = 1;

		this.state = {
			isBlocking: false,
			finishedLoading: false,
			isSaveModalOpen: false,
			dimmerOpen: false,

			//backgroundInfo
			evaluation: null,

			id: null,
			title: '',
			description: '',
			startDate: null,
			endDate: null,
			studentTarget: '',
			studentSampleRate: 100,

			questions: [],

			teacherLookup: {},
			teacherOptions: {},
			teacher_target: [],

			studentLookup: {},
			studentOptions: {},
			student_target: [],

			teacherGroupLookup: {},
			teacherGroupOptions: [],
			teachergroup_target: [],

			studentGroupLookup: {},
			studentGroupOptions: [],
			studentgroup_target: [],
		};
		this.validator = new SimpleReactValidator({
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				required: '請提供:attribute',
				alpha: '請填寫',
				accepted: '請最少選擇:attribute',
				min: ':attribute不可小於:min:type',
				max: ':attribute不可超過:max:type',
				numeric: '請輸入有效數字',
			},
			autoForceUpdate: this,
		});
	}

	setStateAsync = (state) =>
		new Promise((res, rej) => {
			if (this.mounted) this.setState(state, res);
			else rej('unmounted');
		});

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidMount = async () => {
		this.mounted = true;
		try {
			await this.fetch();
			await this.setStateAsync({
				finishedLoading: true,
			});
		} catch (err) {
			console.log('Error when mounting survey');
			console.log(err);
		}
	};

	fetch = async () => {
		try {
			const isGroup = this.props.match.params.hasOwnProperty('groupEvaluationId');
			let evaluation, survey, canApprove;
			if (isGroup) {
				[evaluation, survey, canApprove] = await Promise.all([
					get(`getGroupEvaluationById/${+this.props.match.params.groupEvaluationId}`),
					get(`getGroupSurvey/${+this.props.match.params.groupEvaluationId}`),
					get(`getApprove`),
				]);
			} else {
				[evaluation, survey, canApprove] = await Promise.all([
					get(`getEvaluationById/${this.props.match.params.evaluationId}`),
					get(`getSurvey/${+this.props.match.params.evaluationId}`),
					get(`getApprove`),
				]);
			}
			if (evaluation.length) {
				let s = {};
				if (survey.length) s = pick(survey[0], ['id', 'title', 'description', 'studentTarget', 'started']);

				let startDate, endDate;

				if (s.started) {
					startDate = survey[0].startDate;
					endDate = survey[0].endDate;
				} else {
					startDate = dateToMoment((!survey.length || !survey[0].startDate ? evaluation : survey)[0].startDate);
					endDate = dateToMoment((!survey.length || !survey[0].startDate ? evaluation : survey)[0].endDate);
					if (endDate.isBefore(startDate)) {
						endDate = startDate;
					}
				}

				await this.setStateAsync({
					evaluation: evaluation[0],
					startDate,
					endDate,
					canApprove,
					...s,
				});
			} else {
				await this.setStateAsync({ evaluation: undefined });
			}

			if (!this.state.evaluation) return;

			await this.fetchTargetInfo();

			if (!this.state.id) return;

			const [questions, useless] = await Promise.all([
				await get(`getSurveyQuestion/${+this.state.id}`),
				this.fetchTarget(),
			]);
			//sanitize order
			questions.forEach((q, i) => {
				q.order = i;
			});
			await this.setStateAsync({ questions });
		} catch (err) {
			console.log('Error when fetching survey info');
			console.log(err);
		}
	};

	fetchTargetInfo = async () => {
		try {
			const { evaluation } = this.state;
			if (evaluation.target % 2 === 1) {
				const [teachers, teacherOptions] = await Promise.all([
					get('getAllTeacherInSchool'),
					getTeacherOptions(evaluation.yearId),
				]);

				const [rawGroups, groupTeachers, groupChief] = await Promise.all([
					get('getGroup'),
					get(`getGroupTeachers/${evaluation.yearId}`),
					get(`getGroupChief/${evaluation.yearId}`),
				]);

				const teacherGroupLookup = { ALL: { name: '全體教師', teachers: teachers.map((x) => x.id) } };

				rawGroups.forEach((g) => {
					const id = String(g.id);
					teacherGroupLookup[id] = {
						name: g.name,
						teachers: [],
					};
				});

				teacherGroupLookup.CHIEF = {
					name: '科組主任',
					teachers: groupChief.map((x) => x.teacherId),
				};

				groupTeachers.forEach((gT) => {
					teacherGroupLookup[String(gT.groupId)].teachers = gT.res
						.split(',')
						.filter((x) => x)
						.map((x) => Number(x));
				});

				const teachersByJob = groupBy(teachers, 'displayName');
				Object.keys(teachersByJob).forEach((job) => {
					teacherGroupLookup[`POS${job}`] = { name: job, teachers: teachersByJob[job].map((x) => x.id) };
				});

				await this.setStateAsync({
					teacherLookup: keyBy(teachers, 'id'),
					teacherOptions,
					teacherGroupLookup,
					teacherGroupOptions: Object.entries(teacherGroupLookup).map(([value, { name }]) => ({
						value,
						text: name,
						key: name,
					})),
				});
			}
		} catch (err) {
			if (err !== 'unmounted') console.log('Error occurred when fetching teacher info', err);
		}
		try {
			if (process.env.REACT_APP_STUDENT_SUPPORT && this.state.evaluation.target > 1) {
				const studentGroupOptions = [{ text: '全體學生', value: 'all', key: 'all' }];

				const [students, classes, grades] = await Promise.all([
					get(`getStudent/${+this.state.evaluation.yearId}`),
					get(`getClass`),
					get(`getGrade`),
				]);

				studentGroupOptions.push(...selectOptions(grades, 'chiName', (g) => `grade${g.id}`, true));
				studentGroupOptions.push(
					...selectOptions(
						classes,
						(c) => `${c.name}班`,
						(c) => `class${c.id}`,
						true
					)
				);

				await this.setStateAsync({
					studentLookup: keyBy(students, 'id'),
					studentOptions: selectOptions(students, genStudentOptionName, 'id'),
					studentGroupOptions,
				});

				await this.setStateAsync({
					studentgroup_target: this.state.studentTarget ? String(this.state.studentTarget).split('|') : [],
				});
			}
		} catch (err) {
			if (err !== 'unmounted') console.log('Error occurred when fetching student info', err);
		}
	};

	fetchTarget = async () => {
		try {
			const targets = await get(`getSurveyTarget/${+this.state.id}`);
			const t_target = [],
				s_target = [];

			targets.forEach((t) => {
				if (t.teacherId) {
					t_target.push(t);
				} else {
					s_target.push(t);
				}
			});

			await this.setStateAsync({
				teacher_target: t_target.map((t) => t.teacherId),
				student_target: s_target.map((t) => t.studentId),
			});
		} catch (err) {
			console.log('Error occurred when fetching targets');
			console.log(err);
		}
	};

	/* input update handler */
	inputChange = (event, data) => {
		const { inputType, stateName } = event.target.closest('[data-input-type]').dataset;
		let value = inputHandler(inputType, data);
		this.setStateAsync({
			[stateName]: value,
			isBlocking: true,
		});
	};

	/* input update handler */
	dateChange = (name) => (event, data) => {
		if (this.state.readOnly) return;
		let value = inputHandler('date', data);
		this.setStateAsync({
			[name]: value,
			isBlocking: true,
		});
	};

	startDateChange = this.dateChange('startDate');
	endDateChange = this.dateChange('endDate');

	/* modal toggle */
	saveModalToggle = () => {
		this.setStateAsync({ isSaveModalOpen: !this.state.isSaveModalOpen });
	};

	/* save record */
	save = async () => {
		try {
			await this.setStateAsync({
				finishedLoading: false,
				dimmerOpen: true,
			});

			const survey = pick(this.state, ['id', 'title', 'description', 'startDate', 'endDate', 'studentSampleRate']);
			survey.startDate = momentToDbFormat(survey.startDate);
			survey.endDate = momentToDbFormat(survey.endDate);

			Object.assign(survey, pick(this.props.match.params, ['evaluationId', 'groupEvaluationId']));

			const data = {
				id: survey.id,
				survey,
				questions: this.state.questions.map((x, i) => ({
					...x,
					id: x.id < 0 ? undefined : x.id,
					order: i,
				})),
				teacher: this.state.teacher_target,
			};

			if (process.env.REACT_APP_STUDENT_SUPPORT) {
				data.survey.studentTarget = this.state.studentgroup_target.join('|');
				data.student = this.state.student_target;
			} else {
				data.survey.studentTarget = null;
			}

			const surveyResult = await post('editSurvey', data);

			if (surveyResult.status) {
				this.setStateAsync({
					isBlocking: false,
				});
				await this.fetch();
			} else {
				throw surveyResult;
			}
		} catch (err) {
			if (err !== 'unmounted') {
				alert(
					'儲存問卷資料時發生錯誤' + (err && err.msg ? ` (${err.msg})` : typeof err === 'string' ? ` (${err})` : '')
				);
				console.log('Error when saving survey', err);
			}
		}
		this.saveModalToggle();
		this.setStateAsync({
			finishedLoading: true,
			dimmerOpen: false,
		});
	};

	addQuestion = () => {
		const { questions } = this.state;
		this.setStateAsync({
			questions: questions.concat({
				id: -this.counter++,
				title: '',
				type: 'mcq',
				optionLabels: '',
			}),
			isBlocking: true,
		});
	};

	deleteQuestion = (event) => {
		const { id } = event.target.closest('.ui, button').dataset;
		const questions = [...this.state.questions];
		const index = findIndex(questions, { id: +id });
		if (index > -1) {
			questions.splice(index, 1);
			this.setStateAsync({
				questions,
				isBlocking: true,
			});
		}
	};

	questionInfoChange = (event, data) => {
		const info = [...this.state.questions];
		const { id } = event.target.closest('.ui').dataset;
		const index = findIndex(info, { id: +id });
		if (index > -1) {
			info[index] = {
				...info[index],
				title: inputHandler('text', data),
			};
			this.setStateAsync({
				questions: info,
				isBlocking: true,
			});
		}
	};

	changeTeacherGroup = (event, data) => {
		const target = inputHandler('select', data);

		const teachers = new Set(this.state.teacher_target);
		(target || []).forEach((t) => {
			this.state.teacherGroupLookup[t].teachers.forEach((tId) => {
				teachers.add(tId);
			});
		});

		this.setStateAsync({
			teacher_target: Array.from(teachers),
			teachergroup_target: target,
			isBlocking: true,
		});
	};
	tryOpenSaveModal = () => {
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			return;
		}
		this.saveModalToggle();
	};

	// MUST BE SYNCHRONOUS
	reorderQuestion = (result) => {
		const { source, destination } = result;
		if (!destination) return;
		let arr = [...this.state.questions];
		const [remove] = arr.splice(source.index, 1);
		arr.splice(destination.index, 0, remove);
		this.setStateAsync({ questions: arr });
	};

	render() {
		const {
			isBlocking,
			finishedLoading,
			canApprove,
			dimmerOpen,

			evaluation,

			questions,

			title,
			description,
			minDate,
			startDate,
			endDate,
			studentSampleRate,
			started,

			teacherOptions,
			teacher_target,

			studentOptions,
			student_target,

			teacherGroupOptions,
			teachergroup_target,

			studentGroupOptions,
			studentgroup_target,

			isSaveModalOpen,
		} = this.state;

		switch (evaluation) {
			case null:
				return (
					<Grid>
						<Grid.Row>
							<Grid.Column>
								<Header textAlign="center" as="h1">
									評估資料載入中
								</Header>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				);
			case undefined:
				return (
					<Grid>
						<Grid.Row>
							<Grid.Column>
								<Header textAlign="center" as="h1">
									沒有評估資料
								</Header>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				);
			default:
				break;
		}

		this.validator.purgeFields();

		const readOnly = !finishedLoading || !!started || canApprove.teacherId !== evaluation.teacherId;
		const { target } = evaluation;

		let prefix = '';

		if (finishedLoading) {
			prefix = evaluation.displayName + ' 年度';
			if (evaluation.groupName) prefix += ` ${evaluation.groupName}`;
			prefix += ' - ';
		}

		return (
			<>
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<PageHeader title={`${prefix}策略評估問卷設計`} subTitle="為評估問卷設定問題及受眾等資料" />
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Form>
					<Grid stackable>
						<Grid.Row>
							<Grid.Column width={readOnly ? 16 : 13}>
								{finishedLoading && (
									<>
										<Header as="h3">成功準則：{genEvaluationTitle(evaluation)}</Header>
										<p>
											<strong>{evaluation.concernTitle}</strong>
											<Icon name="angle right" />
											<strong>{evaluation.aimTitle}</strong>
											<Icon name="angle right" />
											<strong>{evaluation.strategyTitle}</strong>
											{!!evaluation.groupActionTitle && (
												<>
													<Icon name="angle right" />
													<strong>{evaluation.groupActionTitle}</strong>
												</>
											)}
										</p>
									</>
								)}
							</Grid.Column>
							{!readOnly && (
								<Grid.Column width={3} textAlign="center">
									<Button
										disabled={readOnly}
										color="blue"
										content="儲存"
										icon="save"
										onClick={this.tryOpenSaveModal}
										circular
									/>
								</Grid.Column>
							)}
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={9}>
								<Form.Field required>
									<label>問卷標題</label>
									<Input
										type="text"
										value={title}
										data-input-type="text"
										data-state-name="title"
										onChange={this.inputChange}
										disabled={readOnly}
										className="more-opaque"
									/>
									{this.validator.message('問卷標題', title, 'required')}
								</Form.Field>
								<Form.Group widths={2} unstackable>
									<Form.Field required disabled={readOnly}>
										<label>開始日期</label>
										<DateInput
											popupPosition="bottom center"
											maxDate={momentToDate(endDate)}
											initialDate={momentToDate(startDate)}
											value={momentToDate(startDate)}
											onChange={this.startDateChange}
											placeholder="開始日期"
										/>
									</Form.Field>
									<Form.Field required disabled={readOnly}>
										<label>結束日期</label>
										<DateInput
											popupPosition="top center"
											minDate={momentToDate(startDate)}
											initialDate={momentToDate(endDate)}
											value={momentToDate(endDate)}
											onChange={this.endDateChange}
											placeholder="結束日期"
										/>
									</Form.Field>
								</Form.Group>
							</Grid.Column>
							<Grid.Column width={7}>
								<Form.Field>
									<label>問卷描述</label>
									<TextArea
										value={description}
										rows={5}
										data-input-type="text"
										data-state-name="description"
										onChange={this.inputChange}
										disabled={readOnly}
									/>
								</Form.Field>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={9}>
								<Form.Field>
									<Header as="h3">問題</Header>
									{readOnly ? (
										<Segment.Group>
											{questions.map((q) => (
												<Segment key={q.id}>
													<Form.Group className="input-with-button">{q.title}</Form.Group>
												</Segment>
											))}
										</Segment.Group>
									) : (
										<DragDropContext onDragEnd={this.reorderQuestion}>
											<Droppable droppableId="questions">
												{(provided) => (
													<Ref innerRef={provided.innerRef}>
														<Segment.Group {...provided.droppableProps}>
															{questions.map((q, i) => (
																<Draggable draggableId={String(q.id)} index={i} key={q.id}>
																	{(p) => (
																		<Ref innerRef={p.innerRef}>
																			<Segment
																				key={q.id}
																				{...p.draggableProps}
																				{...p.dragHandleProps}
																				className="draggable-questions"
																			>
																				<Form.Group className="input-with-button">
																					<Form.Input
																						fluid
																						type="text"
																						width={15}
																						value={q.title}
																						data-id={q.id}
																						onChange={this.questionInfoChange}
																						disabled={!finishedLoading}
																					/>
																					<Button
																						color="red"
																						icon="delete"
																						data-id={q.id}
																						onClick={this.deleteQuestion}
																						disabled={!finishedLoading}
																						circular
																					/>
																				</Form.Group>
																				{this.validator.message(`q${q.id}`, q.title ? 'A' : '1', 'alpha')}
																			</Segment>
																		</Ref>
																	)}
																</Draggable>
															))}
															{provided.placeholder}
														</Segment.Group>
													</Ref>
												)}
											</Droppable>
										</DragDropContext>
									)}
									{!readOnly && (
										<>
											<Container textAlign="center">
												<Button
													color="green"
													icon="add"
													onClick={this.addQuestion}
													circular
													disabled={!finishedLoading}
												/>
											</Container>
											<Container textAlign="center">{this.validator.message(`問題`, questions, 'required')}</Container>
										</>
									)}
								</Form.Field>
							</Grid.Column>
							<Grid.Column width={7}>
								<Header as="h3">選擇{this.targetMap[target]}</Header>
								{finishedLoading && target % 2 === 1 && (
									<Form.Group grouped>
										<Form.Field>
											<label>
												要完成問卷的教師群組或職級 <span className="red">(不會儲存)</span>
											</label>
											<MySelect
												fluid
												multiple
												search
												disabled={readOnly}
												onChange={this.changeTeacherGroup}
												value={teachergroup_target}
												options={teacherGroupOptions}
												noResultsMessage="找不到教師群組"
												placeholder="教師群組"
											/>
										</Form.Field>
										<Form.Field required>
											<label>要完成問卷的教師</label>
											<MySelect
												fluid
												multiple
												search
												disabled={readOnly}
												data-input-type="select"
												data-state-name="teacher_target"
												onChange={this.inputChange}
												value={teacher_target}
												options={teacherOptions}
												noResultsMessage="找不到教師"
												placeholder="教師"
											/>
											{this.validator.message(`一位教師`, !!teacher_target.length, 'accepted')}
										</Form.Field>
									</Form.Group>
								)}
								{process.env.REACT_APP_STUDENT_SUPPORT && finishedLoading && target > 1 && (
									<>
										<Form.Group>
											<Form.Field width={13}>
												<label>
													要完成問卷的學生群組　
													<abbr title="請確保在開始日期前上載好學生資料。實際學生會以當時的資料作準" className="red">
														注意
													</abbr>
												</label>
												<MySelect
													fluid
													multiple
													search
													disabled={readOnly}
													data-input-type="select"
													data-state-name="studentgroup_target"
													onChange={this.inputChange}
													value={studentgroup_target}
													options={studentGroupOptions}
													noResultsMessage="找不到學生群組"
													placeholder="學生群組"
												/>
												{!studentOptions.length &&
													this.validator.message(`一個學生群組`, !!studentgroup_target.length, 'accepted')}
											</Form.Field>
											<Form.Field width={3}>
												<label>取樣率</label>
												<Input
													type="number"
													value={studentSampleRate || ''}
													data-input-type="number"
													data-state-name="studentSampleRate"
													onChange={this.inputChange}
													disabled={readOnly}
													className="more-opaque"
												/>
												{this.validator.message(`取樣率`, studentSampleRate, 'required|numeric|min:1,num|max:100,num')}
											</Form.Field>
										</Form.Group>
										<Form.Group grouped>
											{studentOptions.length ? (
												<Form.Field>
													<label>一定要完成問卷的學生</label>
													<MySelect
														fluid
														multiple
														search
														disabled={readOnly}
														data-input-type="select"
														data-state-name="student_target"
														onChange={this.inputChange}
														value={student_target}
														options={studentOptions}
														noResultsMessage="找不到學生"
														placeholder="學生"
													/>
												</Form.Field>
											) : (
												<Form.Field>
													<Header color="red" as="h4">
														未有今年的學生資料
													</Header>
												</Form.Field>
											)}
										</Form.Group>
									</>
								)}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
				{dimmerOpen ? (
					<FullscreenDimmer active={true} isLoading={true} />
				) : (
					<ConfirmModal
						open={isSaveModalOpen}
						description="確定儲存問卷設計？"
						cancel={this.saveModalToggle}
						confirm={this.save}
						confirmIcon="check"
						confirmText="儲存"
					/>
				)}
				<BlockerPrompt isBlocking={isBlocking} />
			</>
		);
	}
}

export default withRouter(SurveyDesign);
