/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	FullscreenDimmer,
	BlockerPrompt,
	MySortableTable,
  Buttons,
  MySelect
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { selectOptions } from '../../../Helper/Helper';
import { displayDate } from '../../../Helper/TimeHelper';

/* import lodash */
import { map, findIndex, pick, keyBy } from 'lodash';

/* import semantic-ui element */
import { Grid, Segment, Form, Input, TextArea } from 'semantic-ui-react';

class FridayActivityAttendance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: false,
      isBlocking: false,
      finishedLoading: false,
      yearId: this.props.match.params.yearId || null,
      fridayActivityClassId: this.props.match.params.fridayActivityClassId || null,
      fridayActivityId: this.props.match.params.fridayActivityId || null,
      type: this.props.match.params.type || null,
      fridayActivity: {},
      fridayActivityClass: {},
      fridayActivityAttendances: [],
      attendanceStatusOptions: [],
    }

    this.tableProps = {padded: true, stackable: true}
    this.newCount = 0;

    this.genClassName = x=>!x.trueStudent&&Number.isInteger(x.id)?"line-through":undefined;

    this.tableColumnData = [
      { headerName: '班別', width: 1, cellRender: 'classCode', cellClassName: this.genClassName, allowSort: true, sortMethod: 'actualClassId' },
      { headerName: '學號', width: 1, cellRender: 'classNo', cellClassName: this.genClassName, allowSort: true, sortMethod: x=>+x.classNo },
      { headerName: '姓名', width: 3, cellRender: 'name', cellClassName: this.genClassName, allowSort: true },
      { headerName: '狀態', width: 5, cellClassName: "table-cell-with-dropdown", allowSort: false, cellRender: (x)=>(
        <Form.Group widths='equal' className='form-group-no-margin'>
          <Form.Field>
            <MySelect
              fluid
              disabled={!x.trueStudent&&Number.isInteger(x.id)}
              data-id={x.id}
              onChange={this.attendanceStatusChange}
              value={x.attendanceStatusId || ''}
              options={this.state.attendanceStatusOptions}
              placeholder='狀態'
            />
          </Form.Field>
          {x.attendanceStatusId >3 && (
            <Form.Field>
              <Input
                fluid
                disabled={!x.trueStudent&&Number.isInteger(x.id)}
                data-id={x.id}
                type='text'
                onChange={this.attendanceRemarkChange}
                value={x.remark || ''}
                placeholder='請註明'
              />
            </Form.Field>
          )}
        </Form.Group> 
      ) }
    ];
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchData = async () => {
    const {yearId, fridayActivityId, fridayActivityClassId, type} = this.state;
    const handle = type === 'special' ? 'Special' : 'Normal';
    const classRequest = `getOne${handle}FridayActivityClass`;
    const studentRequest = type === 'special' ?
      'getSpecialFridayActivityStudent/' + fridayActivityId :
      'getNormalFridayActivityStudent/' + yearId + '/' + fridayActivityClassId;
    const allFridayActivityStudentRequest = (type === 'special' ? 
      'getAllSpecialFridayActivityStudent/' :
      'getAllNormalFridayActivityStudent/') + yearId;
    const attendanceRequest = `get${handle}FridayActivityAttendance`;

    try{
      let [
        fridayActivity,
        fridayActivityClass,
        fridayActivityStudents,
        allFridayActivityStudents,
        allStudents,
        fridayActivityAttendances,
        attendanceStatus
      ] = await Promise.all([
        get('getOneFridayActivityWithDetails/' + fridayActivityId),
        get(classRequest + '/' + fridayActivityClassId),
        get(studentRequest),
        get(allFridayActivityStudentRequest),
        get('getStudentEssentialInfo/' + yearId),
        get(attendanceRequest + '/' + fridayActivityClassId),
        get('getAttendanceStatus')
      ]);

      fridayActivityStudents.map(student => {
        const index = findIndex(fridayActivityAttendances, { studentId: student.id });
        if(index===-1){
          fridayActivityAttendances.push({
            id: "N"+(this.newCount++), //allocate unique non-numeric Id
            classId: fridayActivityClassId,
            studentId: student.id,
            attendanceStatusId: 1,
            remark: null,
            trueStudent: true
          });
        }else {
          fridayActivityAttendances[index].trueStudent = true;
        }
      });

      const studentLookup = keyBy(allFridayActivityStudents, 'id');
      const allstudentLookup = keyBy(allStudents, 'id');
      fridayActivityAttendances = fridayActivityAttendances.filter(x=>{
        try{
          const student = allstudentLookup[studentLookup[x.studentId].studentId];
          if(student){
            x.name=student.chiName || student.engName;
            x.classNo=student.classNo;
            x.classCode=student.classCode;
            x.actualClassId=student.classId;
            return true;
          }
        }catch(err){}
        return false;
      });

      this.setStateAsync({
        fridayActivity: fridayActivity[0] || {},
        fridayActivityClass: fridayActivityClass[0] || {},
        fridayActivityAttendances,
        attendanceStatusOptions: selectOptions(attendanceStatus, 'name', 'id'),
        type: fridayActivity[0] ? fridayActivity[0].type: 'special'
      });
    }catch(err){if(err!=="unmounted")console.log("Error when fetching data for friday attendance", err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  remarkChange = (event, data) => {
    this.setStateAsync({
      isBlocking: true,
      fridayActivityClass: {
        ...this.state.fridayActivityClass,
        remark: inputHandler('text', data)
      }
    });
  }

  attendanceStatusChange = (event, data) => {
    const fridayActivityAttendances = [...this.state.fridayActivityAttendances];
    const id = event.target.closest('.ui').dataset.id;
    const index = findIndex(fridayActivityAttendances, {id: Number.isInteger(+id)?+id:id});
    if(index<0) return;
    const value = inputHandler('select', data);
    fridayActivityAttendances[index] = {...fridayActivityAttendances[index], attendanceStatusId: value}
    if (value < 4) {
      fridayActivityAttendances[index]['remark'] = null;
    }
    this.setStateAsync({
      isBlocking: true,
      fridayActivityAttendances
    });
  }

  attendanceRemarkChange = (event, data) => {
    const fridayActivityAttendances = [...this.state.fridayActivityAttendances];
    const id = event.target.closest('.ui').dataset.id;
    const index = findIndex(fridayActivityAttendances, {id: Number.isInteger(+id)?+id:id});
    if(index<0) return;
    fridayActivityAttendances[index] = {...fridayActivityAttendances[index], remark: inputHandler('text', data)}
    this.setStateAsync({
      isBlocking: true,
      fridayActivityAttendances
    });
  }

  return = () => {
    const {section, type, yearId} = this.props.match.params;
    this.props.history.push(`/eca/fridayActivity/${section}/detail/${type}/${yearId}/${this.state.fridayActivityId}?q=1`);
  }

  save = async () => {

    const type = this.state.type === 'special' ? 'Special' : 'Normal';
    const postRequest = `edit${type}FridayActivityAttendance`;

    let data = {
      attendances: map(this.state.fridayActivityAttendances, ({ id, classId, studentId, attendanceStatusId, remark }) => {
        return {
          id: Number.isInteger(+id)?id:undefined,
          [`${type.toLowerCase()}FridayActivityClassId`]: classId,
          [`${type.toLowerCase()}FridayActivityStudentId`]: studentId,
          attendanceStatusId,
          remark
        }
      })
    };
    try{
      await this.setStateAsync({ dimmerOpen: true, finishedLoading: false });
      const [attendanceResult, classResult] = await Promise.all([
        post(postRequest, data),
        post('editOneFridayActivityClass', {
          ...pick(this.state.fridayActivityClass, ['id', 'remark']),
          type: this.state.type
        })
      ]);
      if(attendanceResult && attendanceResult.status && classResult && classResult.status){
        await this.setStateAsync({ isBlocking: false });
        this.return();
        return;
      }
      throw [attendanceResult, classResult];
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存興趣小組課堂記錄時發生錯誤");
        await this.setStateAsync({ dimmerOpen: false, finishedLoading: true });
        console.log("Error when saving FridayActivity Attendances", err);
      }
    }
  }

  render() {
    const {
      dimmerOpen,
      finishedLoading,
      isBlocking,
      fridayActivity,
      fridayActivityClass,
      fridayActivityAttendances,
    } = this.state;

    return (
      <>
        {dimmerOpen && <FullscreenDimmer active={dimmerOpen} isLoading={true} />}
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='編輯興趣小組課堂記錄' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form>
                <Segment.Group className='form-group-margin'>
                  <Segment className='bold' size='big'>{fridayActivity.yearName || ''} {fridayActivity.name || ''} ({displayDate(fridayActivityClass.date)})</Segment>
                  <Segment secondary>
                    <MySortableTable
                      tableProps={this.tableProps}
                      tableColumnData={this.tableColumnData}
                      data={fridayActivityAttendances}
                      keyHandle="studentId"
                      finishedLoading={finishedLoading}
                    />
                  </Segment>
                  <Segment secondary>
                    <Form.Field
                      disabled={!finishedLoading}
                      control={TextArea}
                      onChange={this.remarkChange}
                      value={fridayActivityClass.remark||''}
                      label='備註'
                      placeholder='點名用備註'
                    />
                  </Segment>
                </Segment.Group>
                <div className='text-center'>
                  <Buttons.CancelButton disabled={!finishedLoading} onClick={this.return} />
                  <Buttons.SaveButton disabled={!finishedLoading} onClick={this.save} />
                </div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(FridayActivityAttendance);