/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
} from '../../../Components';

/* import helper functions */
import { get } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Select, Card, Segment, Header } from 'semantic-ui-react';

class ActivitySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,
      years: [],
      selectedYear: '',
      activites: []
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchYear = async () => {
    try{
      const result = await get('getYear');
      await this.setStateAsync({
        years: selectOptions(result, 'displayName', 'id'),
        selectedYear: defaultYear(result),
      })
    }catch(err){if(err!=='unmounted')console.log("Error when fetching year Info", err)}
  }

  fetchData = async () => {
    try{
      const activites = await get('getPersonalActivity/' + this.state.selectedYear);
      await this.setStateAsync({ activites });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching personal activity Info", err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchYear();
    await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  yearChange = async (event, { value }) => {
    try{
      await this.setStateAsync({ finishedLoading: false, selectedYear: value });
      await this.fetchData();
      this.setStateAsync({ finishedLoading: true });
    }catch(err){if(err!=='unmounted')console.log("Error when changing year", err)}
  }

  viewDetail = (event) => {
    const {itemId} = event.target.closest('a').dataset;
    this.props.history.push('/eca/activity/personal/detail/' + this.state.selectedYear + '/' + itemId);
  }

  render() {
    const {
      finishedLoading,
      years,
      selectedYear,
      activites
    } = this.state;

    return (
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='負責課外活動' subTitle='管理每年度負責的課外活動' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <Select onChange={this.yearChange} options={years} value={selectedYear} />
          </Grid.Column>
        </Grid.Row>

        {finishedLoading ? (activites.length > 0 ? (
            <Grid.Row columns={2}>
              {activites.map(({ id, name }) => (
                <Grid.Column key={id} className='form-group-margin'>
                  <Card
                    key={id}
                    data-item-id={id}
                    fluid
                    raised
                    header={name}
                    onClick={this.viewDetail}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Column>
                <Segment textAlign='center' basic>
                  <Header icon>本年度沒有負責的課外活動</Header>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            )
          ) : (
            <Grid.Row>
              <Grid.Column>
                <Segment textAlign='center' basic>
                  <Header icon>載入資料中</Header>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          )
        }
      </Grid>
    )
  }
}

export default withRouter(ActivitySummary);