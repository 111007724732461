/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import ErrorLabel from '../../../Components/ErrorLabel';
import ConfirmModal from '../../../Components/ConfirmModal';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import { textareaText } from '../../../Helper/Helper';

/* Appraisal css */
import '../../../Styles/Appraisal.css';

/* import lodash */
import { filter, find, map, orderBy } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import {
	Grid,
	Segment,
	Button,
	Modal,
	Form,
	Checkbox,
	Label,
	Divider,
	Header,
	Message,
	Table,
} from 'semantic-ui-react';

const inputTypeOptions = [
	{ text: '文字', value: 'text' },
	{ text: '文字選擇', value: 'textSelect' },
	{ text: '日期', value: 'date' },
	{ text: '科目', value: 'subject' },
	{ text: '數字', value: 'score' },
];

class EditTable extends Component {
	constructor(props) {
		super(props);
		ping(this);

		// form validator
		this.validator = new SimpleReactValidator({
			autoForceUpdate: this,
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				default: '請輸入資料',
			},
		});
	}

	state = {
		dimmerOpen: true,

		yearId: this.props.match.params.yearId,
		appraisalItemId: this.props.match.params.appraisalItemId,
		appraisalLock: {},
		appraisalItem: {},
		appraisalContent: [],
		appraisalContentTree: [],
		appraisalLevelOption: [],
		appraisalTextOption: [],

		isEditModalOpen: false,
		isDeleteModalOpen: false,
		isOrderModalOpen: false,

		sortContent: [],

		selectedId: null,
		content: {
			id: null,
			parentId: null,
			description: '',
			notes: '',
			needInput: false,
			inputType: '',
			addUp: false,
		},
		textSelectOption: [],
		levelOption: [],
	};

	fetch = async () => {
		const { yearId, appraisalItemId } = this.state;
		let [
			appraisalLock,
			appraisalItem,
			appraisalContent,
			appraisalLevelOption,
			appraisalTextOption,
		] = await Promise.all([
			get('getOneAppraisalLock/' + yearId),
			get('getAppraisalItem/' + appraisalItemId),
			get('getAppraisalContent/' + appraisalItemId),
			get('getAppraisalLevelOption/' + appraisalItemId),
			get('getAppraisalTextOption/' + appraisalItemId),
		]);

		appraisalContent = orderBy(appraisalContent, ['order', 'id']);
		this.setState({
			appraisalLock: appraisalLock.length > 0 ? appraisalLock[0] : {},
			appraisalItem: appraisalItem.length > 0 ? appraisalItem[0] : {},
			appraisalContent,
			appraisalContentTree: buildTree(appraisalContent),
			appraisalLevelOption,
			appraisalTextOption,
		});
	};

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidMount = async () => {
		this.mounted = true;
		await this.fetch();
		this.setState({ dimmerOpen: false });
	};

	/* modal toggle */
	modalToggle = (modalStateName, action = null, id = null) => () => {
		let edit = action === 'edit' ? true : false;
		const { appraisalContent, appraisalLevelOption, appraisalTextOption } = this.state;
		const contentRecord = find(appraisalContent, { id: id });
		const levelOptionRecord = filter(appraisalLevelOption, { appraisalContentId: id });
		const textOptionReocrd = filter(appraisalTextOption, { appraisalContentId: id }).map(({ displayName }) => {
			return { displayName };
		});

		let content = {
			id,
			parentId: edit ? contentRecord.parentId : id,
			description: edit ? contentRecord.description : '',
			notes: edit ? contentRecord.notes : '',
			needInput: edit ? contentRecord.needInput : false,
			inputType: edit ? contentRecord.inputType : '',
			multiple: edit ? contentRecord.multiple : false,
			addUp: edit ? contentRecord.addUp : false,
		};

		this.setState({
			selectedId: id,
			content,
			textSelectOption: edit ? textOptionReocrd : [],
			levelOption: edit ? levelOptionRecord : [],
			[modalStateName]: !this.state[modalStateName],
		});
	};

	orderModalToggle = () => () => {
		this.setState((prevState) => ({
			...prevState,
			sortContent: prevState.appraisalContent,
			isOrderModalOpen: !prevState.isOrderModalOpen,
		}));
	};

	contentChange = (inputType, stateName) => (event, data) => {
		this.setState((prevState) => ({
			content: {
				...prevState.content,
				[stateName]: inputHandler(inputType, data),
			},
		}));
	};

	addLevel = () => {
		const { levelOption } = this.state;
		levelOption.push({
			description: '',
			mark: 0,
			min: 0,
		});
		let newLevelOption = levelOption;
		this.setState({ levelOption: newLevelOption });
	};

	deleteLevel = (index) => () => {
		const { levelOption } = this.state;
		levelOption.splice(index, 1);
		let newLevelOption = levelOption;
		this.setState({ levelOption: newLevelOption });
	};

	levelChange = (inputType, index, stateName) => (event, data) => {
		const { levelOption } = this.state;
		let value = inputHandler(inputType, data);
		if (inputType === 'number' && !value) value = 0;
		levelOption[index][stateName] = value;
		let newLevelOption = levelOption;
		this.setState({ levelOption: newLevelOption });
	};

	save = (event) => {
		event.preventDefault();
		const { appraisalItemId, content, levelOption, textSelectOption } = this.state;
		const { needInput, inputType, multiple, addUp } = content;

		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
		}

		let isAppUp = needInput && inputType === 'score' ? addUp : false;

		let data = {
			...content,
			appraisalItemId,
			section: 'self',
			inputType: needInput ? inputType : null,
			multiple: needInput && inputType === 'textSelect' ? multiple : false,
			textSelectOptions: needInput && inputType === 'textSelect' ? textSelectOption : null,
			addUp: isAppUp,
			levelOption: isAppUp ? levelOption : null,
		};

		this.setState({ dimmerOpen: true });
		post('editAppraisalContent', data).then((result) => {
			if (result) {
				this.fetch().then(() => {
					this.validator.hideMessages();
					this.forceUpdate();
					this.setState({
						isEditModalOpen: false,
						dimmerOpen: false,
					});
				});
			}
		});
	};

	delete = () => {
		const { selectedId } = this.state;
		let data = { id: selectedId };
		this.setState({ dimmerOpen: true });
		post('deleteAppraisalContent', data).then((result) => {
			if (result) {
				this.fetch().then(() => {
					this.setState({
						isDeleteModalOpen: false,
						dimmerOpen: false,
					});
				});
			}
		});
	};

	/* add text select options */
	addTextSelectOption = () => {
		const { textSelectOption } = this.state;
		textSelectOption.push({ displayName: '' });
		let newTextSelectOption = textSelectOption;
		this.setState({ textSelectOption: newTextSelectOption });
	};

	/* change text select options */
	changeTextSelectOption = (index) => (event, data) => {
		const { textSelectOption } = this.state;
		let value = inputHandler('text', data);
		textSelectOption[index].displayName = value;
		let newTextSelectOption = textSelectOption;
		this.setState({ textSelectOption: newTextSelectOption });
	};

	/* delete text select options */
	deleteTextSelectOption = (index) => () => {
		const { textSelectOption } = this.state;
		textSelectOption.splice(index, 1);
		let newTextSelectOption = textSelectOption;
		this.setState({ textSelectOption: newTextSelectOption });
	};

	displayContent = (content) => {
		const { appraisalLock } = this.state;
		return (
			<Fragment>
				{content &&
					content.map(({ id, description, needInput, inputType, addUp }, index) => {
						return (
							<Fragment key={index}>
								<Table.Row>
									<Table.Cell>
										<b>{description}</b>
									</Table.Cell>
									<Table.Cell collapsing textAlign="center">
										{Boolean(addUp) && (
											<Label style={{ marginLeft: '5px', width: '80px' }} color="blue" horizontal basic>
												計算等級
											</Label>
										)}
									</Table.Cell>
									<Table.Cell collapsing textAlign="center">
										{Boolean(needInput) && (
											<Label style={{ marginLeft: '5px', width: '80px' }} color="orange" horizontal basic>
												{inputType === 'text' && '文字'}
												{inputType === 'textSelect' && '文字選擇'}
												{inputType === 'subject' && '科目'}
												{inputType === 'date' && '日期'}
												{inputType === 'score' && '分數'}
											</Label>
										)}
									</Table.Cell>
									<Table.Cell collapsing textAlign="center">
										{appraisalLock.contentLock ? (
											<Button
												onClick={this.modalToggle('isEditModalOpen', 'edit', id)}
												color="blue"
												icon="ellipsis horizontal"
												circular
											/>
										) : (
											<Fragment>
												<Button
													onClick={this.modalToggle('isEditModalOpen', 'edit', id)}
													color="blue"
													icon="edit"
													circular
												/>
												<Button
													onClick={this.modalToggle('isDeleteModalOpen', 'delete', id)}
													color="red"
													icon="delete"
													circular
												/>
											</Fragment>
										)}
									</Table.Cell>
								</Table.Row>
							</Fragment>
						);
					})}
			</Fragment>
		);
	};

	displayEditModal = () => {
		const { isEditModalOpen, appraisalContent, content, levelOption, textSelectOption } = this.state;
		let addUpRecord = find(appraisalContent, { addUp: 1 });
		return (
			<Modal
				open={isEditModalOpen}
				onClose={this.modalToggle('isEditModalOpen')}
				closeOnEscape={false}
				closeOnDimmerClick={false}
			>
				<Modal.Header>編輯項目</Modal.Header>
				<Modal.Content>
					<Segment basic>
						<Form>
							<Header color="blue" as="h2" content="基本資料" />
							<Form.Group className="form-group-margin" grouped>
								<Form.Input
									onChange={this.contentChange('text', 'description')}
									value={content.description}
									type="text"
									label="描述"
									placeholder="描述"
									error={this.validator.message('description', content.description, 'required')}
								/>
							</Form.Group>

							<Divider />

							<Form.Group className="form-group-margin" inline>
								<label>
									<Header color="blue" as="h2" content="需要輸入" />
								</label>
								<Form.Field
									onChange={this.contentChange('toggle', 'needInput')}
									checked={Boolean(content.needInput)}
									control={Checkbox}
									toggle
								/>
							</Form.Group>

							{Boolean(content.needInput) && (
								<Fragment>
									<Form.Group className="form-group-margin" grouped>
										<Form.Select
											onChange={this.contentChange('select', 'inputType')}
											value={content.inputType}
											options={inputTypeOptions}
											label="輸入類別"
											placeholder="輸入類別"
											error={this.validator.message('inputType', content.inputType, ['required'])}
										/>
									</Form.Group>

									{content.inputType === 'textSelect' && (
										<Fragment>
											<Form.Group className="form-group-margin">
												<Form.Field
													onChange={this.contentChange('toggle', 'multiple')}
													checked={Boolean(content.multiple)}
													control={Checkbox}
												/>
												<label>可多選</label>
											</Form.Group>
											{textSelectOption.length > 0 && (
												<Grid style={{ marginBottom: '5px' }}>
													<Grid.Row verticalAlign="bottom">
														{textSelectOption.map(({ displayName }, index) => {
															return (
																<Grid.Column key={index} computer={8} mobile={16}>
																	<Form.Group style={{ alignItems: 'flex-end' }} unstackable>
																		<Form.Input
																			value={displayName}
																			onChange={this.changeTextSelectOption(index)}
																			type="text"
																			label={index === 0 ? '文字選擇' : ''}
																			placeholder={'文字選擇' + (index + 1)}
																			width="13"
																			error={this.validator.message(
																				'textSelectOption_' + index,
																				textSelectOption[index].displayName,
																				'required'
																			)}
																		/>
																		<Form.Button
																			onClick={this.deleteTextSelectOption(index)}
																			circular
																			basic
																			color="red"
																			icon="delete"
																			width="3"
																		/>
																	</Form.Group>
																</Grid.Column>
															);
														})}
													</Grid.Row>
												</Grid>
											)}
											<Form.Group grouped className="text-center">
												<Button
													onClick={this.addTextSelectOption}
													color="green"
													type="button"
													icon="add"
													content="新增文字選擇"
													circular
												/>
												<div>
													{this.validator.message('textSelectOption', textSelectOption, 'required|min:1,array')}
												</div>
											</Form.Group>
										</Fragment>
									)}
								</Fragment>
							)}

							{(!Boolean(addUpRecord) || addUpRecord.id === content.id) &&
								Boolean(content.needInput) &&
								content.inputType === 'score' && (
									<Fragment>
										<Divider />
										<Form.Group className="form-group-margin" inline>
											<label>
												<Header color="blue" as="h2" content="以此項目計算等級" />
											</label>
											<Form.Field
												onChange={this.contentChange('toggle', 'addUp')}
												checked={Boolean(content.addUp)}
												control={Checkbox}
												toggle
											/>
										</Form.Group>

										{Boolean(content.addUp) && (
											<Fragment>
												{levelOption.length > 0 && (
													<Grid style={{ marginBottom: '5px' }}>
														<Grid.Row verticalAlign="bottom">
															{levelOption.map(({ description, mark, min }, index) => {
																return (
																	<Grid.Column key={index} mobile={16}>
																		<Form.Group style={{ alignItems: 'flex-end' }} unstackable>
																			<Form.Input
																				value={description}
																				onChange={this.levelChange('text', index, 'description')}
																				type="text"
																				label={index === 0 ? '等級描述' : ''}
																				placeholder="等級描述"
																				width="8"
																				error={this.validator.message('levelDescription', description, 'required')}
																			/>
																			<Form.Input
																				value={mark || 0}
																				onChange={this.levelChange('number', index, 'mark')}
																				type="number"
																				label={index === 0 ? '等級得分' : ''}
																				placeholder="等級得分"
																				width="3"
																				min="0"
																				error={this.validator.message('mark', mark, 'required')}
																			/>
																			<Form.Input
																				value={min || 0}
																				onChange={this.levelChange('number', index, 'min')}
																				type="number"
																				label={index === 0 ? '分數大過或等於' : ''}
																				placeholder="分數大過或等於"
																				width="3"
																				min="0"
																				error={this.validator.message('min', min, 'required')}
																			/>
																			<Form.Button
																				onClick={this.deleteLevel(index)}
																				circular
																				basic
																				color="red"
																				icon="delete"
																				width="2"
																			/>
																		</Form.Group>
																	</Grid.Column>
																);
															})}
														</Grid.Row>
													</Grid>
												)}
												<Form.Group grouped className="text-center">
													<Button
														onClick={this.addLevel}
														color="green"
														type="button"
														icon="add"
														content="新增等級設定"
														circular
													/>
													<div>{this.validator.message('levelOption', levelOption, 'required|min:1,array')}</div>
												</Form.Group>
											</Fragment>
										)}
									</Fragment>
								)}
						</Form>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button
						className="button-margin-bottom"
						color="red"
						content="取消"
						icon="cancel"
						onClick={this.modalToggle('isEditModalOpen')}
						circular
					/>
					<Button
						className="button-margin-bottom"
						color="green"
						content="儲存"
						icon="save"
						onClick={this.save}
						circular
					/>
				</Modal.Actions>
			</Modal>
		);
	};

	displayViewModal = () => {
		const { isEditModalOpen, content, textSelectOption, levelOption } = this.state;
		return (
			<Modal open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')}>
				<Modal.Header>項目詳情</Modal.Header>
				<Modal.Content>
					<Segment basic>
						<Form>
							<Header color="blue" as="h2" content="基本資料" />

							<Form.Group className="form-group-margin" grouped>
								<label>描述</label>
								<Message>{textareaText(content.description)}</Message>
							</Form.Group>

							<Divider />

							<Header color="blue" as="h2" content="需要輸入" />

							{Boolean(content.needInput) ? (
								<Fragment>
									{/* input type */}
									<Form.Group className="form-group-margin" grouped>
										<label>輸入類別</label>
										<Message>
											{content.inputType === 'text' && '文字'}
											{content.inputType === 'textSelect' && (
												<span>文字選擇 ({Boolean(content.multiple) ? '可多選' : '不可多選'})</span>
											)}
											{content.inputType === 'date' && '日期'}
											{content.inputType === 'score' && '數字（此項目將會計算等級）'}
											{content.inputType === 'subject' && '科目'}
										</Message>
									</Form.Group>

									{content.inputType === 'textSelect' && (
										<Fragment>
											<Form.Group className="form-group-margin" grouped>
												<label>選項</label>
											</Form.Group>
											{textSelectOption.length > 0 && (
												<Grid>
													<Grid.Row verticalAlign="bottom">
														{textSelectOption.map(({ displayName }, index) => {
															return (
																<Grid.Column key={index} computer={8} mobile={16}>
																	<Form.Group className="form-group-margin" grouped>
																		<Message>{displayName}</Message>
																	</Form.Group>
																</Grid.Column>
															);
														})}
													</Grid.Row>
												</Grid>
											)}
										</Fragment>
									)}
								</Fragment>
							) : (
								<Message>不需輸入</Message>
							)}

							<Divider />

							<Header color="blue" as="h2" content="以此項目計算等級" />

							{Boolean(content.addUp) ? (
								<Fragment>
									{levelOption.map(({ description, mark, min }, index) => {
										return (
											<Grid columns="equal" key={index}>
												<Grid.Column>
													<Form.Group grouped>
														{index === 0 && <label>等級描述</label>}
														<Message>{description}</Message>
													</Form.Group>
												</Grid.Column>
												<Grid.Column>
													<Form.Group grouped>
														{index === 0 && <label>等級得分</label>}
														<Message>{mark}</Message>
													</Form.Group>
												</Grid.Column>
												<Grid.Column>
													<Form.Group grouped>
														{index === 0 && <label>分數大過或等於</label>}
														<Message>{min}</Message>
													</Form.Group>
												</Grid.Column>
											</Grid>
										);
									})}
								</Fragment>
							) : (
								<Message>不作計算</Message>
							)}
						</Form>
					</Segment>
				</Modal.Content>
			</Modal>
		);
	};

	displayOrderModal = () => {
		const { isOrderModalOpen, sortContent } = this.state;
		return (
			<Modal open={isOrderModalOpen} onClose={this.orderModalToggle()} closeOnEscape={false} closeOnDimmerClick={false}>
				<Modal.Header>編輯子項目排序</Modal.Header>
				<Modal.Content>
					<Segment basic>
						<Table>
							<Table.Body>
								{sortContent.map(({ description }, index) => (
									<Table.Row key={index}>
										<Table.Cell>
											<b>{description}</b>
										</Table.Cell>
										<Table.Cell collapsing>
											<Button color="blue" icon="caret up" onClick={this.order(index, 'up')} circular />
											<Button color="brown" icon="caret down" onClick={this.order(index, 'down')} circular />
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" content="取消" icon="cancel" onClick={this.orderModalToggle()} circular />
					<Button color="green" content="儲存" icon="save" onClick={this.saveOrder()} circular />
				</Modal.Actions>
			</Modal>
		);
	};

	order = (index, action) => () => {
		let { sortContent } = this.state;
		let newIndex = action === 'up' ? index - 1 : index + 1;
		if (newIndex >= 0 && newIndex < sortContent.length) {
			let currentContent = sortContent[index];
			sortContent[index] = sortContent[newIndex];
			sortContent[newIndex] = currentContent;

			this.setState((prevState) => ({
				...prevState,
				sortContent,
			}));
		}
	};

	saveOrder = () => () => {
		const { sortContent } = this.state;
		let data = {
			appraisalContent: map(sortContent, ({ id }, index) => ({
				id,
				order: index + 1,
			})),
		};

		this.setState({ dimmerOpen: true });
		post('editAppraisalContentOrder', data).then((result) => {
			if (result.status) {
				this.fetch().then(() => {
					this.setState({
						isOrderModalOpen: false,
						dimmerOpen: false,
					});
				});
			}
		});
	};

	render() {
		this.validator.purgeFields();
		const { dimmerOpen, appraisalLock, appraisalItem, appraisalContentTree, isDeleteModalOpen } = this.state;
		return (
			<Fragment>
				{dimmerOpen ? (
					<FullscreenDimmer active={dimmerOpen} isLoading={true} />
				) : (
					<Fragment>
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<PageHeader title="編輯表格內容" prevPage={() => this.props.history.push('/appraisal/content')} />
								</Grid.Column>
							</Grid.Row>

							{appraisalItem && (
								<Fragment>
									<Grid.Row>
										<Grid.Column>
											<div>
												<Segment.Group>
													<Segment className="bold larger-font">
														<Grid columns={1} stackable>
															<Grid.Row verticalAlign="middle">
																<Grid.Column>
																	{appraisalItem.yearName} : {appraisalItem.displayName} (只支援1欄等級計算項目)
																</Grid.Column>
															</Grid.Row>
														</Grid>
													</Segment>
													<Segment padded="very" className="overflowX" secondary>
														{!appraisalLock.contentLock && (
															<div style={{ paddingRight: '0.9em', textAlign: 'right' }}>
																<Button
																	onClick={this.modalToggle('isEditModalOpen', 'new')}
																	color="green"
																	icon="add"
																	circular
																/>
																<Button onClick={this.orderModalToggle()} color="brown" icon="sort" circular />
															</div>
														)}

														{appraisalContentTree.length > 0 ? (
															<Table>
																<Table.Body>{this.displayContent(appraisalContentTree, 1)}</Table.Body>
															</Table>
														) : (
															<Segment textAlign="center" basic>
																沒有評估內容
															</Segment>
														)}
													</Segment>
												</Segment.Group>
											</div>
										</Grid.Column>
									</Grid.Row>
								</Fragment>
							)}
						</Grid>

						{appraisalLock.contentLock ? (
							this.displayViewModal()
						) : (
							<Fragment>
								{this.displayEditModal()}
								{this.displayOrderModal()}
							</Fragment>
						)}

						<ConfirmModal
							open={isDeleteModalOpen}
							description="確定刪除評估項目？所有子項目也會一併刪除"
							cancel={this.modalToggle('isDeleteModalOpen')}
							confirm={this.delete}
						/>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

export default withRouter(EditTable);
