import React from 'react';
import {Modal, Segment } from 'semantic-ui-react';
import Buttons from './Buttons';

const ConfirmModal = ({open, description, cancel, confirm, confirmText='刪除', confirmIcon='remove', modalname, closeOnEscape, closeOnDimmerClick}) => {
    return (
      <Modal
        open={open}
        onClose={cancel}
        closeOnEscape={closeOnEscape}
        closeOnDimmerClick={closeOnDimmerClick}
        data-modalname={modalname}
      >
        <Modal.Content>
          <Segment basic>
            {description}
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Buttons.CancelButton data-modalname={modalname} onClick={cancel} circular />
          <Buttons.SaveButton content={confirmText} title={confirmText} icon={confirmIcon} data-modalname={modalname} onClick={confirm}/>
        </Modal.Actions>
      </Modal>
    )
}

export default ConfirmModal;