/* import react */
import React, { useRef } from 'react';

/* import semantic-ui element */
import { Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const PageHeader = ({ title, subTitle = null, prevPage = null, returnUrl = null, size = 'h2' }) => {
	return (
		<Header>
			{!!returnUrl && (
				<Link to={returnUrl} style={{ display: 'table-cell', verticalAlign: 'middle' }}>
					<Icon link name="chevron circle left" className="iconStyle" />
				</Link>
			)}

			{!!prevPage && <Icon link onClick={prevPage} name="chevron circle left" />}
			<Header.Content as={size} style={{ display: 'table-cell', verticalAlign: 'middle' }}>
				{title}
				{subTitle && <Header.Subheader style={{ marginTop: '5px' }}>{subTitle}</Header.Subheader>}
			</Header.Content>
		</Header>
	);
};

export default React.memo(PageHeader);
