import OneYear from './1Year';
import ThreeYear from './3Year';
import Group from './Group';
import Report from './Report';
import ExportFile from './ExportFile';

export default {
  OneYear,
  ThreeYear,
  Group,
  Report,
  ExportFile,
}