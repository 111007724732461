/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	AggregateNumber,
	EmptyTableMsg,
} from '../../Components';

import { get } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import {
  selectOptions,
  defaultYear,
  numRound
} from '../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Segment, Table, Form, Select } from 'semantic-ui-react';

class Panel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,

      year: [],
      selectedYear: '',
      surveys: [],
    }
    this.tenRem = {width: '10rem'}
  }
  
  fetch = async () => {
    try{
      //get year info
      const rawYear = await get('getYear');
      let year = selectOptions(rawYear, 'displayName', 'id');
      const selectedYear = this.state.selectedYear ? this.state.selectedYear : defaultYear(rawYear);

      const surveys = await get(`getViewableYearSurvey/${+selectedYear}`);

      await this.setStateAsync({
        year,
        selectedYear,
        surveys
      });
      
    }catch(err){
      console.log('Error when fetching results');
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when fetching 3 year Review & SWOP');
      console.log(err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  yearChange = async (_,data) => {
    try{
      let value = inputHandler('select', data);
      await this.setStateAsync({
        selectedYear: value,
        finishedLoading: false,
      })
      await this.fetch();
    }catch(err){
      console.log('Error when changing selected OYP');
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
    });
  }

  navToSurveyResult = (id) => () => {
    this.props.history.push(`/survey/stats/${+id}`)
  }

  render() {
    const {finishedLoading, year, selectedYear, surveys} = this.state;
    return (
      <Grid centered doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='周年計劃問卷結果'/>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Field inline>
                  <label>周年</label>
                  <Select
                    disabled={!finishedLoading}
                    options={year}
                    value={selectedYear}
                    onChange={this.yearChange}
                  />
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Table fixed selectable celled unstackable>
              <colgroup>
                <col/>
                <col style={this.tenRem}/>
                <col style={this.tenRem}/>
              </colgroup>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>標題</Table.HeaderCell>
                  <Table.HeaderCell>達標度</Table.HeaderCell>
                  <Table.HeaderCell><abbr title="包括已儲存但未提交的問卷">回答進度</abbr></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finishedLoading ? (
                    surveys.length ? surveys.map(s=>(
                    <Table.Row key={s.id} onClick={this.navToSurveyResult(s.id)} className="cursor">
                      <Table.Cell><h4>{s.title}</h4></Table.Cell>
                      <Table.Cell><AggregateNumber value={numRound(s.x)} /></Table.Cell>
                      <Table.Cell>{numRound(100*s.rate)}%</Table.Cell>
                    </Table.Row>
                  )):<EmptyTableMsg colSpan={3} msg="沒有可閱覽的問卷結果"/>
                ):<EmptyTableMsg colSpan={3} msg="資料載入中"/>}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(Panel);