/* import react */
import React, { useEffect, useState } from 'react';
import { get } from '../../../Helper/ApiHelper';
import { FullscreenDimmer } from '../../../Components';
import { Button, Checkbox, Grid, GridColumn, Segment, SegmentGroup, Select, Table } from 'semantic-ui-react';
import PageHeader from '../../../Components/PageHeader';
import { defaultYear, selectOptions } from '../../../Helper/Helper';
import { filter, find, map, orderBy, uniq } from 'lodash';
import EmptyTableMsg from '../../../Components/EmptyTableMsg';
import { momentToDate } from '../../../Helper/TimeHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import { FinalReport } from './Template/FinalReport';
import { WorkingReport } from './Template/WorkingReport';
import { TeachingReport } from './Template/TeachingReport';
import { TrainingReport } from './Template/TrainingReport';
import { TrainingSchoolReport } from './Template/TrainingSchoolReport';

const filterTeacherNames = [
	'中文科',
	'英文科',
	'數學科',
	'常識科',
	'音樂科',
	'圖書科',
	'體育科',
	'視藝科',
	'宗教科',
	'電腦科',
	'普通話科',
	'訓輔組',
	'課程組',
	'活動組',
	'支援組',
	'公民科',
];

const tableHeaders = [
	{ key: 'index', displayName: '編號' },
	{ key: 'displayName', displayName: '職位' },
	{ key: 'name', displayName: '姓名' },
];

const Report = () => {
	const [loading, setLoading] = useState(false);
	const [teachers, setTeachers] = useState([]);
	const [years, setYears] = useState([]);
	const [yearSelectOptions, setYearSelectOptions] = useState([]);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedTeachers, setSelectedTeachers] = useState([]);
	const [checkAllTeacher, setCheckAllTeacher] = useState(false);
	const [showAllTeacher, setShowAllTeacher] = useState(false);

	const [appraisalLock, setAppraisalLock] = useState({});
	const [positionId, setPositionId] = useState(0);
	const [principalInfo, setPrincipalInfo] = useState({});

	const [sortColumns, setSortColumns] = useState([null, null, null]);

	const fetchYear = async () => {
		setLoading(true);
		const years = await get('getYear');
		const selectedYear = defaultYear(years);
		const yearSelectOptions = selectOptions(years, 'displayName', 'id');
		setYears(years);
		setYearSelectOptions(yearSelectOptions);
		setSelectedYear(selectedYear);
		setLoading(false);
	};

	const fetch = async () => {
		if (!!selectedYear) {
			setLoading(true);
			const appraisalLock = await get('getOneAppraisalLock/' + selectedYear);
			let teachers = await get('getAllTeacherWithAdminWithIndex/' + selectedYear);
			const oneTeacher = await get('getOneTeacher');

			teachers = filter(teachers, ({ name }) => filterTeacherNames.indexOf(name) < 0);

			let own = null;
			let positionId = 0;
			if (oneTeacher.length > 0) {
				positionId = oneTeacher[0].positionId;
				own = [find(teachers, { id: oneTeacher[0].id })];
			}

			setAppraisalLock(appraisalLock.length > 0 ? appraisalLock[0] : {});
			setTeachers(positionId === 1 || positionId === 2 ? teachers : own);
			setPrincipalInfo(teachers.length > 0 ? find(teachers, { positionId: 2 }) : {});
			setPositionId(positionId);
			setLoading(false);
		}
	};

	const fetchAppraisal = async () => {
		const appraisalItem = await get(`getAllAppraisalItem/${selectedYear}`);
		const appraisalContent = await get(`getAllAppraisalContent/${selectedYear}`);
		const appraisalLevelOption = await get(`getAllAppraisalLevelOption/${selectedYear}`);
		const appraisalNonApplicable = await get(`getAllAppraisalNonApplicable/${selectedYear}`);

		return {
			appraisalItem,
			appraisalItemTree: buildTree(appraisalItem),
			appraisalContent,
			appraisalContentTree: buildTree(appraisalContent),
			appraisalLevelOption,
			appraisalNonApplicable,
		};
	};

	const fetchTeacher = async () => {
		let reportTeachers = [];
		const fetchingPromise = selectedTeachers.map(async (teacherId) => {
			const tableRecord = await get('getAllAppraisalTableRecord/' + selectedYear + '/' + teacherId);
			const personalTarget = await get('getAllAppraisalFormTarget/' + selectedYear + '/' + teacherId);
			const personalFormRecord = await get('getAllAppraisalFormRecord/' + selectedYear + '/' + teacherId);
			const personalWriting = await get('getAllAppraisalWriting/' + selectedYear + '/' + teacherId);
			const personalAppraisalItem = await get('getPersonalAppraisalItemForReport/' + selectedYear + '/' + teacherId);
			const personalMarking = await get('getAppraisalMarkingByYearIdAndTeacherId/' + selectedYear + '/' + teacherId);

			let personalTableRecord = [];
			tableRecord.forEach(
				({ appraisalItemId, appraisalTableRecordId, appraisalContentId, text, date, score, submitDate }) => {
					if (!personalTableRecord[appraisalItemId]) {
						personalTableRecord[appraisalItemId] = [];
					}
					if (!personalTableRecord[appraisalItemId][appraisalTableRecordId]) {
						personalTableRecord[appraisalItemId][appraisalTableRecordId] = {};
					}
					if (submitDate) personalTableRecord[appraisalItemId][appraisalTableRecordId]['submitDate'] = submitDate;
					if (text) {
						return (personalTableRecord[appraisalItemId][appraisalTableRecordId][appraisalContentId] = text);
					}
					if (date) {
						return (personalTableRecord[appraisalItemId][appraisalTableRecordId][appraisalContentId] = momentToDate(
							date
						));
					}
					if (score !== null) {
						return (personalTableRecord[appraisalItemId][appraisalTableRecordId][appraisalContentId] = score);
					}
				}
			);

			const data = {
				teacherInfo: find(teachers, { id: teacherId }),
				personalTarget,
				personalTableRecord,
				personalFormRecord,
				personalWriting,
				personalAppraisalItem: uniq(
					personalAppraisalItem.map(({ appraisalItemId }) => {
						return appraisalItemId;
					})
				),
				personalMarking,
			};
			reportTeachers.push(data);
		});
		await Promise.all(fetchingPromise);
		return reportTeachers;
	};

	useEffect(() => {
		fetchYear();
	}, []);

	useEffect(() => {
		fetch();
	}, [selectedYear]);

	const changeSelectedYear = (selectedYear) => {
		setSelectedYear(selectedYear);
		setSelectedTeachers([]);
	};

	const toggleShowAllTeacher = (checked) => {
		setShowAllTeacher(checked);
		if (checked) {
			setCheckAllTeacher(false);
		} else {
			const updatedSelectedTeachers = selectedTeachers.filter((id) => {
				return find(teachers, { id }).status;
			});
			setSelectedTeachers(updatedSelectedTeachers);
		}
	};

	const toggleAllTeacher = (checked) => {
		if (checked) {
			if (showAllTeacher) {
				setSelectedTeachers(map(teachers, 'id'));
			} else {
				setSelectedTeachers(map(filter(teachers, { status: '1' }), 'id'));
			}
		} else {
			setSelectedTeachers([]);
		}
		setCheckAllTeacher(checked);
	};

	const toggleTeacher = (id, checked) => {
		if (checked) {
			setSelectedTeachers((selectedTeachers) => [...selectedTeachers, id]);
			const teacherTotal = showAllTeacher
				? map(teachers, 'id').length
				: map(filter(teachers, { status: '1' }), 'id').length;
			setCheckAllTeacher(selectedTeachers.length + 1 === teacherTotal);
		} else {
			let index = selectedTeachers.indexOf(id);
			if (index >= 0) {
				setSelectedTeachers((selectedTeachers) => [
					...selectedTeachers.slice(0, index),
					...selectedTeachers.slice(index + 1),
				]);
				setCheckAllTeacher(false);
			}
		}
	};

	const printPersonalReport = async (key) => {
		if (selectedTeachers.length > 0) {
			setLoading(true);
			const reportTeachers = await fetchTeacher();
			const reportAppraisal = await fetchAppraisal();
			const { appraisalItemTree, appraisalContentTree, appraisalLevelOption, appraisalNonApplicable } = reportAppraisal;

			const printData = {
				yearInfo: find(years, { id: selectedYear }),
				deadline: appraisalLock ? appraisalLock.deadline : null,
				appraisalItemTree,
				appraisalContentTree,
				appraisalLevelOption,
				appraisalNonApplicable,
				principalInfo,
				teacherData: reportTeachers,
			};

			if (key === 'final') {
				FinalReport(printData, () => {
					setLoading(false);
				});
			}

			if (key === 'working') {
				WorkingReport(printData, () => {
					setLoading(false);
				});
			}

			if (key === 'teaching') {
				TeachingReport(printData, () => {
					setLoading(false);
				});
			}

			if (key === 'training') {
				TrainingReport(printData, () => {
					setLoading(false);
				});
			}
		} else {
			alert('請先選擇老師');
		}
	};

	const printSchoolReports = async (key) => {
		if (key === 'training') {
			const tableRecords = await get('getAllAppraisalTableRecordByYear/' + selectedYear);
			const reportAppraisal = await fetchAppraisal();
			const { appraisalItemTree, appraisalLevelOption } = reportAppraisal;

			await TrainingSchoolReport({
				year: find(years, { id: selectedYear }),
				deadline: appraisalLock ? appraisalLock.deadline : null,
				teachers,
				appraisalItemTree,
				appraisalLevelOption,
				tableRecords,
			});
		}
	};

	const personalButtons = [
		{ key: 'final', content: '教師個人評鑑總表', display: positionId === 1 || positionId === 2 },
		{ key: 'working', content: '教師工作報告', display: positionId === 1 || positionId === 2 },
		{ key: 'teaching', content: '學與教報告' },
		{ key: 'training', content: '專業進修報告' },
	];

	const schoolButtons = [{ key: 'training', content: '專業進修總表', display: positionId === 1 || positionId === 2 }];

	const sort = (index) => {
		let value = sortColumns[index];
		if (value === 'ascending') value = 'descending';
		else if (value === 'descending') value = null;
		else value = 'ascending';
		setSortColumns((sortColumns) => [...sortColumns.slice(0, index), value, ...sortColumns.slice(index + 1)]);
	};

	useEffect(() => {
		let orderColumns = [];
		let orders = [];
		map(sortColumns, (sortColumn, index) => {
			if (!!sortColumn) {
				orderColumns.push(tableHeaders[index].key);
				if (sortColumn === 'ascending') orders.push('asc');
				if (sortColumn === 'descending') orders.push('desc');
			}
		});

		setTeachers(orderBy(teachers, orderColumns, orders));
	}, [sortColumns]);

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<PageHeader title="評估報告" subTitle="匯出老師評估報告" />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1}>
					<Grid.Column>
						<Select
							onChange={(event, { value }) => changeSelectedYear(value)}
							options={yearSelectOptions}
							value={selectedYear}
						/>
						{(positionId === 1 || positionId === 2) && (
							<Checkbox
								style={{ paddingTop: '11px', height: '36px', marginLeft: '15px' }}
								label="顯示已離校老師"
								checked={showAllTeacher}
								onChange={(event, { checked }) => toggleShowAllTeacher(checked)}
							/>
						)}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<GridColumn>
						<SegmentGroup>
							<Segment>
								<div className="bold" style={{ marginBottom: 10 }}>
									個人報告：
								</div>
								{personalButtons.map(({ key, content, display = true }) => {
									if (display)
										return (
											<Button
												key={key}
												color="green"
												content={content}
												icon="download"
												onClick={() => printPersonalReport(key)}
												circular
											/>
										);
								})}
							</Segment>
							<Segment>
								<div className="bold" style={{ marginBottom: 10 }}>
									總表：
								</div>
								{schoolButtons.map(({ key, content, display = true }) => {
									if (display)
										return (
											<Button
												key={key}
												color="green"
												content={content}
												icon="download"
												onClick={() => printSchoolReports(key)}
												circular
											/>
										);
								})}
							</Segment>
						</SegmentGroup>
					</GridColumn>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Table textAlign="center" sortable selectable celled unstackable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>
										<Checkbox checked={checkAllTeacher} onChange={(event, { checked }) => toggleAllTeacher(checked)} />
									</Table.HeaderCell>
									{tableHeaders.map(({ displayName }, index) => {
										return (
											<Table.HeaderCell key={index} sorted={sortColumns[index]} onClick={() => sort(index)}>
												{displayName}
											</Table.HeaderCell>
										);
									})}
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{teachers.length > 0 ? (
									teachers.map((teacher) => {
										const { id, status } = teacher;
										if (Boolean(Number(status)) || showAllTeacher) {
											return (
												<Table.Row key={id} className={Boolean(Number(status)) ? '' : 'left-school'}>
													<Table.Cell>
														<Checkbox
															checked={selectedTeachers.indexOf(id) > -1}
															onChange={(event, data) => toggleTeacher(id, data.checked)}
														/>
													</Table.Cell>
													{tableHeaders.map(({ key }) => (
														<Table.Cell key={key}>{teacher[key]}</Table.Cell>
													))}
												</Table.Row>
											);
										}
									})
								) : (
									<EmptyTableMsg colSpan="1" />
								)}
							</Table.Body>
						</Table>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</React.Fragment>
	);
};

export default Report;
