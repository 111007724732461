import React, { Fragment, useEffect, useState } from 'react';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import { Form, FormGroup, Grid, GridColumn, GridRow, Message, Segment, SegmentGroup, Table } from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import { get } from '../../../Helper/ApiHelper';
import { dateToMoment, momentToDate } from '../../../Helper/TimeHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import PageHeader from '../../../Components/PageHeader';
import DisplayBeforeComponent from './DisplayBeforeComponent';
import DisplayBeforeFile from './DisplayBeforeFile';
import { roundTwoDp, textareaText } from '../../../Helper/Helper';
import { base, formContentMarkForOne } from '../Report/Calculation';
import { forEach } from 'lodash';

const tableColor = ['#FFFFFF', '#eaeaea', '#cfcfcf'];

const ViewOther = (props) => {
	const history = useHistory();
	const { section, teacherId, appraisalItemId, appraisalTargetId, appraisalMarkingId } = useParams();
	const [loading, setLoading] = useState(false);
	const [teacherInfo, setTeacherInfo] = useState({});
	const [appraisalItem, setAppraisalItem] = useState({});
	const [appraisalTarget, setAppraisalTarget] = useState({});
	const [appraisalMarking, setAppraisalMarking] = useState({});
	const [appraisalContentBeforeTree, setAppraisalContentBeforeTree] = useState([]);
	const [appraisalBefore, setAppraisalBefore] = useState({});
	const [files, setFiles] = useState([]);
	const [appraisalContentOtherTree, setAppraisalContentOtherTree] = useState([]);
	const [appraisalOther, setAppraisalOther] = useState({});
	const isAppeal = section === 'appeal';

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const teacherInfo = await get(`getOneTeacher/${teacherId}`);
			const appraisalItem = await get(`getAppraisalItem/${appraisalItemId}`);
			const appraisalTarget = await get(`getOneAppraisalFormTarget/${appraisalTargetId}`);
			const appraisalMarking = await get(`getOneAppraisalMarkingById/${appraisalMarkingId}`);
			const appraisalContentBefore = await get(`getAppraisalContentBefore/${appraisalItemId}`);
			const appraisalFormBefore = await get(`getAppraisalFormBefore/${appraisalTargetId}`);
			const appraisalFiles = await get('getAppraisalFile/' + appraisalTargetId);
			const appraisalContentOther = await get(`getAppraisalContentOther/${appraisalItemId}`);
			const appraisalFormOther = await get(`getAppraisalFormOther/${appraisalTargetId}/${teacherId}`);

			let appraisalBefore = {};
			appraisalFormBefore.forEach(({ appraisalContentId, text, date, score }) => {
				let value = '不適用';
				if (!!score) value = score;
				if (!!text) value = text;
				if (!!date) value = dateToMoment(date);
				appraisalBefore[appraisalContentId] = value;
			});

			let appraisalOther = {};
			appraisalFormOther.forEach(({ appraisalContentId, text, date, score }) => {
				let value = '不適用';
				if (!!score) value = score;
				if (!!text) value = text;
				if (!!date) value = dateToMoment(date);
				appraisalOther[appraisalContentId] = value;
			});

			setTeacherInfo(teacherInfo.length > 0 ? teacherInfo[0] : {});
			setAppraisalItem(appraisalItem.length > 0 ? appraisalItem[0] : {});
			setAppraisalTarget(appraisalTarget.length > 0 ? appraisalTarget[0] : {});
			setAppraisalMarking(appraisalMarking.length > 0 ? appraisalMarking[0] : {});
			setAppraisalContentBeforeTree(buildTree(appraisalContentBefore));
			setAppraisalBefore(appraisalBefore);
			setFiles(appraisalFiles);
			setAppraisalContentOtherTree(buildTree(appraisalContentOther));
			setAppraisalOther(appraisalOther);
			setLoading(false);
		};

		fetch();
	}, []);

	const back = () => {
		let url = `/appraisal/item/form_list/${appraisalItemId}`;
		if (isAppeal) url = `/appraisal/appeal/form_list/${appraisalItemId}/${teacherId}`;
		history.push(url);
	};

	const displayOther = (appraisalContentOtherTree, level = 1) => {
		return (
			<Fragment>
				{appraisalContentOtherTree.map(
					(
						{
							id,
							description,
							notes,
							needInput,
							inputType,
							min,
							max,
							step,
							notApplicable,
							count,
							multiple,
							percentage,
							avgCount,
							children,
						},
						index
					) => {
						let value = appraisalOther[id];
						if (inputType === 'date') value = momentToDate(value);
						if (['text', 'subject', 'textSelect'].indexOf(inputType) >= 0) value = textareaText(value);

						let label = description;
						if (count) {
							if (avgCount) label += '（平均計算）';
							else if (!!percentage) label += '（' + percentage + '%）';
						}

						return (
							<Fragment key={index}>
								<GridColumn
									computer={!needInput || inputType === 'text' ? 16 : 8}
									largeScreen={!needInput || inputType === 'text' ? 16 : 8}
									mobile={16}
									style={{ backgroundColor: `${tableColor[level - 1] || '#FFFFFF'}` }}
								>
									<div style={{ display: 'flex', alignItems: 'flex-start' }}>
										<FormGroup className="no-margin" grouped style={{ width: '100%' }}>
											<Form.Field label={label} />
											{Boolean(needInput) && <Message>{value}</Message>}
										</FormGroup>
									</div>
								</GridColumn>
								{!!children && displayOther(children, level + 1)}
							</Fragment>
						);
					}
				)}
			</Fragment>
		);
	};

	const displayAvg = () => {
		let mark = 0;
		let storePercentage = 0;

		forEach(appraisalContentOtherTree, ({ id, percentage, max, count, children }) => {
			if (count) {
				let returnMark = formContentMarkForOne(id, max, count, appraisalOther, children) * percentage;
				if (!isNaN(returnMark)) {
					mark += returnMark;
					storePercentage += percentage;
				}
			}
		});

		if (storePercentage > 0) {
			return roundTwoDp(mark / storePercentage);
		} else {
			return '-';
		}
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<PageHeader
							title={`檢視他人評估${isAppeal && !!teacherInfo.name ? teacherInfo.name : ''}`}
							prevPage={back}
						/>
					</Grid.Column>
				</Grid.Row>
				<GridRow>
					<GridColumn>
						<SegmentGroup>
							<Segment className="bold">
								<Grid columns={3} stackable>
									<Grid.Row className="larger-font" verticalAlign="middle">
										<Grid.Column>
											{appraisalItem.yearName} : {appraisalItem.displayName}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>被評老師:{appraisalTarget.name}</Grid.Column>
										<Grid.Column>評估老師:{appraisalMarking.name}</Grid.Column>
										<Grid.Column>遞交限期:{momentToDate(appraisalTarget.deadline)}</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>評估科目:{appraisalTarget.subject}</Grid.Column>
										<Grid.Column>評估班別:{appraisalTarget.className}</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
							<Segment>
								<Table basic="very" padded celled>
									<Table.Body>
										{appraisalContentBeforeTree.length > 0 && appraisalBefore ? (
											<Fragment>
												<DisplayBeforeComponent
													appraisalContentBeforeTree={appraisalContentBeforeTree}
													appraisalBefore={appraisalBefore}
												/>
											</Fragment>
										) : (
											<Table.Row textAlign="center">
												<Table.Cell collapsing>等候被評老師進行設定</Table.Cell>
											</Table.Row>
										)}
									</Table.Body>
								</Table>
							</Segment>
							<DisplayBeforeFile files={files} teacherName={appraisalTarget?.name} />
						</SegmentGroup>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<SegmentGroup>
							<Segment className="bold larger-font">
								<Grid columns={2} stackable>
									<GridRow verticalAlign="middle">
										<GridColumn>評估內容</GridColumn>
									</GridRow>
								</Grid>
							</Segment>
							<Segment padded="very" style={{ marginBottom: 0 }}>
								<Form>
									<Grid>{displayOther(appraisalContentOtherTree)}</Grid>
									<div style={{ marginTop: 40, marginBottom: 30 }}>
										<Message>
											<Message.Header>{`平圴分數：${displayAvg()} / ${base}`}</Message.Header>
										</Message>
									</div>
								</Form>
							</Segment>
						</SegmentGroup>
					</GridColumn>
				</GridRow>
			</Grid>
		</React.Fragment>
	);
};

export default ViewOther;
