/* import react */
import React from 'react';

/* import semantic-ui element */
import { Label } from 'semantic-ui-react';

const ErrorLabel = ({message, pointing, className}) => {
    if (pointing === undefined)
        pointing = true;
    return (
        <Label color='red' basic pointing={pointing} className={className}>
            {message}
        </Label>
    )
}

export default ErrorLabel;