/* import react */
import React from 'react';

/* import semantic-ui element */
import {
    Segment,
    Header,
    Icon,
} from 'semantic-ui-react';

const empty = {padding: 0}

const EmptySegmentMsg = ({ icon = 'check', msg = '找不到', loading = false, as = 'h2', padded = true, attached, className }) => {
  return (
    <Segment basic textAlign='center' padded={padded} style={!padded ? empty: undefined} attached={attached} className={className}>
      <Header as={as} icon color={loading ? "black" : "blue"}>
        {!loading && <Icon name={icon} />}
        {msg}
      </Header>
    </Segment>
  )
}

export default EmptySegmentMsg;