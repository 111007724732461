import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { get } from '../../../Helper/ApiHelper';
import { filter, find, orderBy } from 'lodash';
import { buildTree } from '../../../Helper/TreeHelper';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import { Button, Grid, GridColumn, GridRow, Label, Menu, Segment, Tab, Table, TableCell } from 'semantic-ui-react';
import PageHeader from '../../../Components/PageHeader';
import FormOrderModal from './_partials/FormOrderModal';
import FormEditModal from './_partials/FormEditModal';
import FormDeleteModal from './_partials/FormDeleteModal';
import FormDisplayModal from './_partials/FormDisplayModal';

const tabSections = {
	before: '被評估前設定',
	self: '被評估後反思',
	other: '他人評估',
};

const tableColor = ['#FFFFFF', '#eaeaea', '#cfcfcf'];

const EditForm = (props) => {
	const history = useHistory();
	const orderModalRef = useRef();
	const editModalRef = useRef();
	const deleteModalRef = useRef();
	const displayModalRef = useRef();

	const { yearId, appraisalItemId } = useParams();
	const [loading, setLoading] = useState(false);
	const [appraisalLock, setAppraisalLock] = useState({});
	const [appraisalItem, setAppraisalItem] = useState({});
	const [appraisalContents, setAppraisalContents] = useState([]);
	const [appraisalContentTree, setAppraisalContentTree] = useState([]);
	const [appraisalTextOptions, setAppraisalTextOptions] = useState([]);
	const [panes, setPanes] = useState([]);

	const fetch = async () => {
		setLoading(true);
		const appraisalLock = await get(`getOneAppraisalLock/${yearId}`);
		const appraisalItem = await get(`getAppraisalItem/${appraisalItemId}`);
		let appraisalContents = await get(`getAppraisalContent/${appraisalItemId}`);
		const appraisalTextOptions = await get(`getAppraisalTextOption/${appraisalItemId}`);

		appraisalContents = orderBy(appraisalContents, ['order', 'id']);
		setAppraisalLock(appraisalLock.length > 0 ? appraisalLock[0] : {});
		setAppraisalItem(appraisalItem.length > 0 ? appraisalItem[0] : {});
		setAppraisalContents(appraisalContents);
		setAppraisalContentTree(buildTree(appraisalContents));
		setAppraisalTextOptions(appraisalTextOptions);
		setLoading(false);
	};

	useEffect(() => {
		fetch();
	}, []);

	useEffect(() => {
		let panes = [];
		if (!!appraisalItem && appraisalContentTree.length > 0) {
			const { displayName } = appraisalItem;
			if (['觀課', '科主/同儕觀課', '查簿', '下學期查簿'].indexOf(displayName) >= 0) {
				panes.push(displayPane('before'));
			}

			if (['觀課', '科主/同儕觀課'].indexOf(displayName) >= 0) {
				panes.push(displayPane('self'));
			}

			panes.push(displayPane('other'));
		}
		setPanes(panes);
	}, [appraisalItem, appraisalContentTree]);

	const back = () => {
		history.push('/appraisal/content');
	};

	const displayPane = (tabSection) => {
		return {
			menuItem: <Menu.Item key={tabSection}>{tabSections[tabSection]}</Menu.Item>,
			render: () => (
				<Tab.Pane>
					<Segment padded basic>
						{displayContent(tabSection)}
					</Segment>
				</Tab.Pane>
			),
		};
	};

	const displayContent = (tabSection) => {
		const sessionContent = filter(appraisalContentTree, { section: tabSection });
		return (
			<React.Fragment>
				{!appraisalLock.contentLock && (
					<div style={{ paddingRight: '0.9em', textAlign: 'right' }}>
						<Button onClick={() => openModal('new', null, tabSection)} color="green" icon="add" circular />
						<Button onClick={() => orderModalRef.current.open(sessionContent)} color="brown" icon="sort" circular />
					</div>
				)}

				{sessionContent.length > 0 ? (
					<Table>
						<Table.Body>{displayContentDetails(sessionContent)}</Table.Body>
					</Table>
				) : (
					<Segment textAlign="center" basic>
						沒有評估內容
					</Segment>
				)}
			</React.Fragment>
		);
	};

	const displayContentDetails = (content, level = 1) => {
		return (
			<React.Fragment>
				{!!content &&
					content.map(({ id, description, count, section, needInput, inputType, percentage, children, avgCount }) => (
						<React.Fragment key={id}>
							<Table.Row style={{ backgroundColor: `${tableColor[level - 1] || '#FFFFFF'}` }}>
								<TableCell style={{ paddingLeft: `${20 * level}px` }}>
									<b>{description}</b>
								</TableCell>
								<TableCell collapsing>
									{Boolean(count) && (
										<Label style={{ marginLeft: '5px', width: '80px' }} color="blue" horizontal basic>
											{Boolean(avgCount) ? '平圴計算' : `${percentage}%`}
										</Label>
									)}
								</TableCell>
								<TableCell collapsing>
									{Boolean(needInput) && (
										<Label style={{ marginLeft: '5px', width: '80px' }} color="orange" horizontal basic>
											{inputType === 'text' && '文字'}
											{inputType === 'textSelect' && '文字選擇'}
											{inputType === 'subject' && '科目'}
											{inputType === 'date' && '日期'}
											{inputType === 'score' && '分數'}
										</Label>
									)}
								</TableCell>
								<TableCell collapsing>
									{appraisalLock.contentLock ? (
										<Button onClick={() => openModal('display', id)} color="blue" icon="ellipsis horizontal" circular />
									) : (
										<React.Fragment>
											<Button onClick={() => openModal('edit', id)} color="blue" icon="edit" circular />
											<Button onClick={() => deleteModalRef.current.open(id)} color="red" icon="delete" circular />
											{!Boolean(needInput) && (
												<Button onClick={() => openModal('new', id, section)} color="green" icon="add" circular />
											)}
											{children && (
												<Button
													onClick={() => orderModalRef.current.open(children)}
													color="brown"
													icon="sort"
													circular
												/>
											)}
										</React.Fragment>
									)}
								</TableCell>
							</Table.Row>
							{children && <React.Fragment>{displayContentDetails(children, level + 1)}</React.Fragment>}
						</React.Fragment>
					))}
			</React.Fragment>
		);
	};

	const openModal = (action = 'new', id = null, section = null) => {
		let haveData = action === 'edit' || action === 'display';
		const contentRecord = find(appraisalContents, { id: id });
		const textSelectOptions = filter(appraisalTextOptions, { appraisalContentId: id }).map(({ displayName }) => {
			return { displayName };
		});

		let details = {
			id: haveData ? id : null,
			parentId: haveData ? contentRecord.parentId : id,
			description: haveData ? contentRecord.description : '',
			notes: haveData ? contentRecord.notes : '',
			needInput: haveData ? contentRecord.needInput : false,
			section: haveData ? contentRecord.section : section,
			inputType: haveData ? contentRecord.inputType : '',
			min: haveData ? contentRecord.min : 0,
			max: haveData ? contentRecord.max : 0,
			step: haveData ? contentRecord.step : 0,
			notApplicable: haveData ? contentRecord.notApplicable : false,
			multiple: haveData ? contentRecord.multiple : false,
			count: haveData ? contentRecord.count : false,
			avgCount: haveData && contentRecord.avgCount !== null ? contentRecord.avgCount : true,
			percentage: haveData ? contentRecord.percentage : 0,
			allowSetApplicable: haveData ? contentRecord.allowSetApplicable : false,
		};

		if (action === 'display') {
			displayModalRef.current.open(details, textSelectOptions);
		} else {
			editModalRef.current.open(details, textSelectOptions);
		}
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<FormOrderModal ref={orderModalRef} fetch={fetch} />
			<FormEditModal ref={editModalRef} fetch={fetch} />
			<FormDeleteModal ref={deleteModalRef} fetch={fetch} />
			<FormDisplayModal ref={displayModalRef} />
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<PageHeader title="編輯評估內容" prevPage={back} />
					</Grid.Column>
				</Grid.Row>
				<GridRow>
					<GridColumn>
						<Segment.Group>
							<Segment className="bold larger-font">
								<Grid columns={1} stackable>
									<Grid.Row verticalAlign="middle">
										<Grid.Column>
											{appraisalItem.yearName} : {appraisalItem.displayName}
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
							<Segment className="overflowX" secondary>
								<Tab panes={panes} renderActiveOnly />
							</Segment>
						</Segment.Group>
					</GridColumn>
				</GridRow>
			</Grid>
		</React.Fragment>
	);
};

export default EditForm;
