import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import { get, getAppraisalFile } from '../../../Helper/ApiHelper';
import { filter, find, map, orderBy } from 'lodash';
import {
	Button,
	Grid,
	GridColumn,
	GridRow,
	Label,
	Menu,
	Segment,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
} from 'semantic-ui-react';
import PageHeader from '../../../Components/PageHeader';
import { isDeadlineOver, momentToDate } from '../../../Helper/TimeHelper';
import EmptyTableMsg from '../../../Components/EmptyTableMsg';

const tabSections = {
	before: {
		title: '被評估前設定',
		headers: [
			{ key: 'subject', displayName: '評估科目' },
			{ key: 'className', displayName: '評估班別' },
			{ key: 'markingTeachers', displayName: '評估老師' },
			{ key: 'deadline', displayName: '遞交限期' },
			{ key: 'status', displayName: '狀態' },
		],
	},
	self: {
		title: '被評估後反思',
		headers: [
			{ key: 'subject', displayName: '評估科目' },
			{ key: 'className', displayName: '評估班別' },
			{ key: 'markingTeachers', displayName: '評估老師' },
			{ key: 'deadline', displayName: '遞交限期' },
			{ key: 'status', displayName: '狀態' },
		],
	},
	other: {
		title: '他人評估',
		headers: [
			{ key: 'subject', displayName: '評估科目' },
			{ key: 'className', displayName: '評估班別' },
			{ key: 'teacher', displayName: '被評老師' },
			{ key: 'deadline', displayName: '遞交限期' },
			{ key: 'status', displayName: '狀態' },
		],
	},
};

const FormList = () => {
	const history = useHistory();
	const { section, appraisalItemId, teacherId } = useParams();
	const [loading, setLoading] = useState(false);
	const [teacherInfo, setTeacherInfo] = useState(null);
	const [appraisalLock, setAppraisalLock] = useState(null);
	const [isLock, setIsLock] = useState(true);
	const [appraisalItem, setAppraisalItem] = useState(null);
	const [details, setDetails] = useState(null);
	const [panes, setPanes] = useState([]);

	const [sortColumns, setSortColumns] = useState({
		before: ['ascending', null, null, null, null],
		self: ['ascending', null, null, null, null],
		other: ['ascending', null, null, null, null],
	});
	const isAppeal = section === 'appeal';

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const appraisalItem = await get(`getAppraisalItem/${appraisalItemId}`);
			const teacherInfo = await get(`getOneTeacher/${teacherId}`);

			setAppraisalItem(appraisalItem.length > 0 ? appraisalItem[0] : {});
			setTeacherInfo(teacherInfo.length > 0 ? teacherInfo[0] : {});
			setLoading(false);
		};

		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const { yearId } = appraisalItem;
			const appraisalLock = await get(`getOneAppraisalLock/${yearId}`);
			const teachers = await get(`getAllTeacherWithAdminWithIndex/${yearId}`);
			const markingTeachers = await get(`getPersonalFormTargetMarkingTeacher/${appraisalItemId}/${teacherId}`);
			let before = await get(`getPersonalFormTargetBefore/${appraisalItemId}/${teacherId}`);
			let self = await get(`getPersonalFormTargetSelf/${appraisalItemId}/${teacherId}`);
			let other = await get(`getPersonalFormTargetOther/${appraisalItemId}/${teacherId}`);

			map(before, ({ id }, index) => {
				const markingTeacherRecord = filter(markingTeachers, { appraisalTargetId: id });
				map(markingTeacherRecord, ({ teacherId }, index) => {
					const teacherRecord = find(teachers, { id: teacherId });
					if (!!teacherRecord) {
						markingTeacherRecord[index]['index'] = teacherRecord.index;
						markingTeacherRecord[index]['name'] = teacherRecord.name;
					}
				});
				before[index]['markingTeachers'] = orderBy(markingTeacherRecord, 'index');
			});

			map(self, ({ id }, index) => {
				const markingTeacherRecord = filter(markingTeachers, { appraisalTargetId: id });
				map(markingTeacherRecord, ({ teacherId }, index) => {
					const teacherRecord = find(teachers, { id: teacherId });
					if (!!teacherRecord) {
						markingTeacherRecord[index]['index'] = teacherRecord.index;
						markingTeacherRecord[index]['name'] = teacherRecord.name;
					}
				});
				self[index]['markingTeachers'] = orderBy(markingTeacherRecord, 'index');
			});

			map(other, ({ teacherId }, index) => {
				const teacherRecord = find(teachers, { id: teacherId });
				if (!!teacherRecord) {
					other[index]['index'] = teacherRecord.index;
					other[index]['name'] = teacherRecord.name;
				}
			});

			setAppraisalLock(appraisalLock.length ? appraisalLock[0] : {});
			setIsLock(appraisalLock.length ? Boolean(appraisalLock[0].appraisalLock) : true);

			setDetails({
				before,
				self,
				other,
			});

			setLoading(false);
		};
		if (!!appraisalItem) {
			fetch();
		}
	}, [appraisalItem]);

	useEffect(() => {
		let panes = [];
		if (!!appraisalItem && !!appraisalLock && !!details) {
			const { displayName } = appraisalItem;
			if (['觀課', '科主/同儕觀課', '查簿', '下學期查簿'].indexOf(displayName) >= 0) {
				panes.push(displayPane('before'));
			}

			if (['觀課', '科主/同儕觀課'].indexOf(displayName) >= 0) {
				panes.push(displayPane('self'));
			}

			panes.push(displayPane('other'));
		}
		setPanes(panes);
	}, [appraisalItem, appraisalLock, details, sortColumns]);

	useEffect(() => {
		if (!!appraisalItem && !!appraisalLock && !!details) {
			order('before');
		}
	}, [sortColumns.before]);

	useEffect(() => {
		if (!!appraisalItem && !!appraisalLock && !!details) {
			order('self');
		}
	}, [sortColumns.self]);

	useEffect(() => {
		if (!!appraisalItem && !!appraisalLock && !!details) {
			order('other');
		}
	}, [sortColumns.other]);

	const order = (tabSection) => {
		let orderColumns = [];
		let orders = [];
		map(sortColumns[tabSection], (sortColumn, index) => {
			if (!!sortColumn) {
				orderColumns.push(tabSections[tabSection]['headers'][index].key);
				if (sortColumn === 'ascending') orders.push('asc');
				if (sortColumn === 'descending') orders.push('desc');
			}
		});

		setDetails((details) => ({
			...details,
			[tabSection]: orderBy(details[tabSection], orderColumns, orders),
		}));
	};

	const sort = (tabSection, index) => {
		let value = sortColumns[tabSection][index];
		if (value === 'ascending') value = 'descending';
		else if (value === 'descending') value = null;
		else value = 'ascending';

		setSortColumns((sortColumns) => ({
			...sortColumns,
			[tabSection]: [...sortColumns[tabSection].slice(0, index), value, ...sortColumns[tabSection].slice(index + 1)],
		}));
	};

	const displayPane = (tabSection) => {
		const { displayName: appraisalItemName } = appraisalItem;
		const { title, headers } = tabSections[tabSection];
		const isOther = tabSection === 'other';
		const records = details[tabSection];
		const statusName = `${tabSection}Status`;
		const submitDateName = tabSection === 'other' ? 'submitDate' : `${tabSection}SubmitDate`;
		const outstanding =
			filter(records, { [statusName]: 'process' }).length + filter(records, { [statusName]: 'save' }).length;

		return {
			menuItem: (
				<Menu.Item key={tabSection}>
					{title}
					{outstanding > 0 && <Label color="orange">{outstanding}</Label>}
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane>
					<Segment padded basic>
						{appraisalLock.deadline && (
							<div style={{ marginBottom: 10 }}>
								(總分只計算<b>{momentToDate(appraisalLock.deadline)}</b>或以前輸入的分數)
							</div>
						)}
						{['觀課', '科主/同儕觀課'].indexOf(appraisalItemName) >= 0 &&
							isAppeal &&
							tabSection === 'other' &&
							records.length > 0 && (
								<Button
									color="green"
									content="下載全部他評教案"
									icon="download"
									type="button"
									onClick={() => download()}
								/>
							)}
						<Table textAlign="center" columns={5} celled selectable sortable>
							<TableHeader>
								<TableRow>
									{headers.map(({ key, displayName }, index) => {
										return (
											<Table.HeaderCell
												key={key}
												collapsing
												sorted={sortColumns[tabSection][index]}
												onClick={() => sort(tabSection, index)}
											>
												{displayName}
											</Table.HeaderCell>
										);
									})}
									<Table.HeaderCell collapsing>行動</Table.HeaderCell>
								</TableRow>
							</TableHeader>
							<TableBody>
								{records.length > 0 ? (
									<React.Fragment>
										{records.map((record) => {
											const {
												id,
												index,
												name,
												deadline,
												appraisalMarkingId,
												markingTeachers = [],
												needBefore,
												beforeStatus,
											} = record;
											const submitDate = record[submitDateName];
											const status = record[statusName];
											const isDead = isDeadlineOver(deadline);
											const otherMarking = isOther ? `/${appraisalMarkingId}` : '';

											let to = null;
											let icon = null;
											let color = null;
											let content = '-';

											if (isOther && Boolean(needBefore) && (beforeStatus === 'process' || beforeStatus === 'save')) {
												content = isDead ? '已過期限，未有評估前設定' : '等候評估前設定';
											} else if (isAppeal) {
												if (status === 'complete') {
													if (isLock) {
														to = `/appraisal/appeal/view_${tabSection}/${appraisalItemId}/${id}${otherMarking}/${teacherId}`;
														icon = 'eye';
														color = 'blue';
														content = '檢視';
													} else {
														to = `/appraisal/appeal/edit_${tabSection}/${appraisalItemId}/${id}${otherMarking}/${teacherId}`;
														icon = 'edit';
														color = 'blue';
														content = '更改';
													}
												} else {
													content = isDead ? '已過期限，未有他人評估' : '等候他人評估';
												}
											} else {
												if (status === 'complete') {
													to = `/appraisal/item/view_${tabSection}/${appraisalItemId}/${id}${otherMarking}`;
													icon = 'eye';
													color = 'blue';
													content = '檢視';
												} else {
													if (!isDead) {
														to = `/appraisal/item/edit_${tabSection}/${appraisalItemId}/${id}${otherMarking}`;
														icon = 'calculator';
														if (status === 'process') {
															color = 'green';
															content = '開始';
														}
														if (status === 'save') {
															color = 'orange';
															content = '繼續';
														}
													} else {
														content = isOther ? '已過期限，未有他人評估' : '已過期限';
													}
												}
											}

											return (
												<Table.Row key={id}>
													{headers.map(({ key }) => (
														<TableCell key={key} collapsing>
															{key === 'deadline' && (
																<React.Fragment>{deadline ? momentToDate(deadline) : '-'}</React.Fragment>
															)}

															{key === 'teacher' && (
																<Label className="teacherLabel">
																	{index} {name}
																</Label>
															)}

															{key === 'markingTeachers' && (
																<React.Fragment>
																	{markingTeachers.map(({ teacherId, index, name }) => {
																		return (
																			<Label key={teacherId} className="teacherLabel">
																				{index} {name}
																			</Label>
																		);
																	})}
																</React.Fragment>
															)}

															{key === 'status' && (
																<React.Fragment>
																	{status === 'complete' ? (
																		<Label color="black" content={momentToDate(submitDate) + ' 已完成'} basic />
																	) : (
																		<React.Fragment>
																			{isDead ? (
																				<Label color="red" content="已過限期" basic />
																			) : (
																				<React.Fragment>
																					{status === 'process' && <Label color="green" content="進行中" basic />}
																					{status === 'save' && <Label color="orange" content="已儲存" basic />}
																				</React.Fragment>
																			)}
																		</React.Fragment>
																	)}
																</React.Fragment>
															)}

															{['deadline', 'teacher', 'markingTeachers', 'status'].indexOf(key) < 0 && (
																<React.Fragment>{record[key] || '-'}</React.Fragment>
															)}
														</TableCell>
													))}
													<TableCell collapsing>
														{!!to && !!icon && !!color ? (
															<Link to={to}>
																<Button icon={icon} color={color} content={content} circular />
															</Link>
														) : (
															content
														)}
													</TableCell>
												</Table.Row>
											);
										})}
									</React.Fragment>
								) : (
									<EmptyTableMsg colSpan={headers.length + 1} />
								)}
							</TableBody>
						</Table>
					</Segment>
				</Tab.Pane>
			),
		};
	};

	const download = async () => {
		setLoading(true);
		const records = details['other'];
		const appraisalTargetIds = map(records, 'id');
		await getAppraisalFile('downloadAppraisalFile', { appraisalTargetIds, name: '他評教案', isZip: true });
		setLoading(false);
	};

	const back = () => {
		let url = `/appraisal/item`;
		if (isAppeal) url = `/appraisal/appeal/item/${appraisalItem.yearId}/${teacherId}`;
		history.push(url);
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<PageHeader
							title={isAppeal && !!teacherInfo?.name ? `覆核評估分數: ${teacherInfo.name}` : '個人評估項目'}
							subtitle={isAppeal ? '選擇需要覆核之評估項目' : '每年度個人需要進行之評估'}
							prevPage={back}
						/>
					</Grid.Column>
				</Grid.Row>
				<GridRow>
					<GridColumn>
						<Segment.Group>
							<Segment className="bold larger-font">
								<Grid stackable>
									<Grid.Row verticalAlign="middle" columns={2}>
										<Grid.Column>
											{appraisalItem?.yearName} : {appraisalItem?.displayName}
										</Grid.Column>
										<Grid.Column textAlign="right">
											{Boolean(appraisalLock?.appraisalLock) && (
												<Label color="red" basic>
													已鎖定本年度分數
												</Label>
											)}
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
							<Segment className="overflowX" secondary>
								<Tab panes={panes} renderActiveOnly />
							</Segment>
						</Segment.Group>
					</GridColumn>
				</GridRow>
			</Grid>
		</React.Fragment>
	);
};

export default FormList;
