/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {keyBy, pick} from 'lodash';

/* import components */
import {
	PageHeader,
	FullscreenDimmer,
	ConfirmModal,
	BlockerPrompt,
	FlexibleTextField,
	ConfirmButtonAndModal,
	MySelect,
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import {
  selectOptions,
  defaultYearForPlan,
  whetherReadOnlyForYearPlan
} from '../../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Button, Form, Segment, Header } from 'semantic-ui-react';

class GroupSWOP extends Component {
  constructor(props) {
    super(props);

    this.default = {};

    this.swop = [
      ['s', '強項'],
      ['w', '弱項'],
      ['o', '契機'],
      ['p', '危機'],
    ]

    this.swop.forEach(data=>{
      this.default[data[0]] = '';
    });

    this.state = {
      finishedLoading: false,
      isSaveModalOpen: false,
      isBlocking: false,
      readOnly: true,
      dimmerOpen: false,

      year: [],
      yearInfo: {},
      yearId: '',
      ...this.default,

      groupOptions: [],
      editableGroups: new Set(),
      groupId: '',
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when fetching 3 year Review & SWOP');
      console.log(err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try{

      //get year info
      const [rawYear, groups] = await Promise.all([
        get('getYear'),
        get('getGroup'),
      ]);

      await this.setStateAsync({
        year: selectOptions(rawYear, 'displayName', 'id'),
        yearInfo: keyBy(rawYear, 'id'),
        yearId: this.state.yearId ? this.state.yearId : defaultYearForPlan(rawYear),

        groupOptions: selectOptions(groups, 'name', 'id'),
        groupId: this.state.groupId || '',
      });

      await this.fetchEditableGroups();
    }catch(err){
      if(err!=="unmounted"){console.log('Error when fetching year and group info', err);}
    }
  }

  fetchEditableGroups = async () => {
    try {
      const groupsCanEdit = await get('getGroupEditRights/' + this.state.yearId);
      const editableGroups = new Set(groupsCanEdit.map(x => x.id));

      await this.setStateAsync({
        editableGroups,
        readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId) || !editableGroups.has(this.state.groupId),
      });

      await this.fetchGroupData();

    } catch (err) {
      console.log("Error when fetching editable group data");
      console.log(err);
    }
  }

  fetchGroupData = async () => {
    try{
      let groupPlanData = [];
      if(this.state.groupId){
        groupPlanData = await get(`getGroupPlan/${+this.state.yearId}/${+this.state.groupId}`);
      }
      
      let stateData;
      if(groupPlanData.length){
        stateData = {...groupPlanData[0]};
      }else{
        stateData = {...this.default, id: null};
      }

      await this.setStateAsync(stateData);
    }catch(err){
      if(err!=="unmounted"){console.log("Error when fetching group data", err);}
    }
  }

  save = async () => {
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      })
      const data = pick(this.state, ['id', 'yearId', 'groupId'].concat('swop'.split('')));
      'swop'.split('').forEach(x=>{
        data[x] = data[x].replace(/^[\r\n\s\uFEFF\xA0]+|[\r\n\s\uFEFF\xA0]+$/g,'');
      })
      const result = await post('editGroupPlan/SWOP', data);
      if(result && result.status){
        await this.fetchGroupData();
      }else{
        throw(result);
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存科組強弱機危時發生錯誤");
        console.log('Error when saving group SWOT',err);
      }
    }
    this.saveModalToggle();
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
      isBlocking: false
    });
  }

  /* modal toggle */
  saveModalToggle = () => {
    this.setStateAsync({
      isSaveModalOpen: !this.state.isSaveModalOpen
    });
  }

  /* input update handler */
  inputChange = (event, data) => {
    const fieldName = event.target.dataset.fieldname;
    this.setStateAsync({
      [fieldName]: inputHandler('text', data),
      isBlocking: true
    })
  }

  yearChange = async (_,data) => {
    if(this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要轉換年份嗎？")){
        return;
      }
    }
    try{
      let value = inputHandler('select', data);
      await this.setStateAsync({
        yearId: value,
        finishedLoading: false,
      });
      
      await this.fetchEditableGroups();
    }catch(err){
      console.log('Error when changing selected OYP');
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
      isBlocking: false,
    });
  }

  groupChange = async(_,data) => {
    if(this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要轉換科組嗎？")){
        return;
      }
    }
    try{
      let value = inputHandler('select', data);
      console.log(value, this.state.editableGroups, this.state.yearInfo, this.state.yearId);
      await this.setStateAsync({
        groupId: value,
        finishedLoading: false,
        readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId) || !this.state.editableGroups.has(value),
      });

      await this.fetchGroupData();
    }catch(err){
      console.log('Error when changing selected group');
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
      isBlocking: false,
    });
  }

  fetchPrevData = async () => {
    return await post('copyGroupPlan', {yearId: this.state.yearId, groupId: this.state.groupId});
  }

  render() {
    const {
      isSaveModalOpen,
      finishedLoading,
      isBlocking,
      readOnly,
      dimmerOpen,

      year,
      yearId,

      groupOptions,
      groupId,
    } = this.state;

    return (<>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='科組計劃 - 強弱機危' subTitle='按科組情況輸入強弱機危' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Group>
                  <Form.Field inline>
                    <label>周年</label>
                    <MySelect
                      disabled={!finishedLoading}
                      options={year}
                      value={yearId}
                      onChange={this.yearChange}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <label>科組</label>
                    <MySelect
                      error={!groupId}
                      disabled={!finishedLoading}
                      options={groupOptions}
                      value={groupId}
                      onChange={this.groupChange}
                      placeholder="請選擇"
                    />
                  </Form.Field>
                  <ConfirmButtonAndModal
                    buttonProps={{
                      floated: 'right',
                      disabled: readOnly
                    }}
                    onConfirm={this.fetchPrevData}
                    onFinish={this.fetchGroupData}
                  />
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form>              
              <Header as='h3'>強弱機危<Header.Subheader>(按Enter會自動新增另一點)</Header.Subheader></Header>
              <Segment padded>
                <Form.Group>
                  {this.swop.slice(0,2).map(([fieldName, title], i) => (
                    <Form.Field width={9-(i%2)*2} key={fieldName}>
                      <label>{title}</label>
                      <FlexibleTextField
                        value={this.state[fieldName]}
                        data-fieldname={fieldName}
                        readOnly={readOnly}
                        onChange={this.inputChange}

                        disabled={!finishedLoading}
                        multiline={true}
                        ul={true}
                        rows={8}
                      />
                    </Form.Field>
                  ))}
                </Form.Group>
                <Form.Group>
                  {this.swop.slice(2).map(([fieldName, title], i) => (
                    <Form.Field width={9-(i%2)*2} key={fieldName}>
                      <label>{title}</label>
                      <FlexibleTextField
                        value={this.state[fieldName]}
                        data-fieldname={fieldName}
                        readOnly={readOnly}
                        onChange={this.inputChange}

                        disabled={!finishedLoading}
                        multiline={true}
                        ul={true}
                        rows={8}
                      />
                    </Form.Field>
                  ))}
                </Form.Group>
              </Segment>
              {!readOnly && (<Segment>
                <Form.Field
                  control={Button}
                  color="green"
                  onClick={this.saveModalToggle}
                  disabled={!finishedLoading||!yearId||!groupId}
                >儲存</Form.Field>
              </Segment>)}
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {dimmerOpen?(<FullscreenDimmer active={dimmerOpen} isLoading={true} />):<ConfirmModal
        open={isSaveModalOpen}
        description='確定儲存科組強弱機危資料？'
        cancel={this.saveModalToggle}
        confirm={this.save}
        confirmIcon='check'
        confirmText='儲存'
      />}
      <BlockerPrompt isBlocking={isBlocking}/>
    </>)
  }
}

export default withRouter(GroupSWOP);