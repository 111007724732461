import { fromBlankAsync } from 'xlsx-populate';
import { exportExcel } from '../../../../Helper/ExcelHelper';
import { filter, find, forEach, map } from 'lodash';
import moment from 'moment';

export const TrainingSchoolReport = async ({
	year,
	deadline,
	teachers,
	appraisalItemTree,
	appraisalLevelOption,
	tableRecords,
}) => {
	const yearName = year.displayName;
	const filename = `${yearName} 教師專業進修報告總表`;
	const workbook = await fromBlankAsync();
	const sheet = workbook.sheet(0);

	let content = [];
	content.push(['編號', '教師姓名', '代號', '進修時數', '分數']);

	const trainingItem = find(appraisalItemTree, { displayName: '教師專業進修' });
	const tableLevelOption = filter(appraisalLevelOption, { appraisalItemId: trainingItem.id });

	map(teachers, ({ id, index, name, shortName, status }) => {
		if (Boolean(Number(status))) {
			let total = 0;
			const records = filter(tableRecords, ({ teacherId, appraisalItemId, submitDate }) => {
				return (
					teacherId === id && appraisalItemId === trainingItem.id && moment(submitDate).isSameOrBefore(deadline, 'day')
				);
			});

			map(records, ({ score }) => {
				total += score;
			});
			content.push([index, name, shortName, total, result(tableLevelOption, total)]);
		}
	});

	sheet.cell('A1').value(`${yearName}馬鞍山聖若瑟小學\n教師專業進修報告總表`);
	sheet.cell('A2').value(content);
	sheet.range('A1:E1').merged(true);
	sheet.row(1).height(40);
	sheet.range('A1:E1').style({
		underline: true,
		bold: true,
		wrapText: true,
		horizontalAlignment: 'center',
		verticalAlignment: 'center',
	});

	const file = await workbook.outputAsync();
	exportExcel(file, filename);
};

const result = (tableLevelOption, total) => {
	let result = 0;
	forEach(tableLevelOption, ({ mark, min }) => {
		if (total >= min && mark > result) {
			result = mark;
			return;
		}
	});
	return result;
};
