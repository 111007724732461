import React, { useState, useEffect } from 'react';
import { Form, Search, Button, Label, Icon } from 'semantic-ui-react';
import { debounce, escapeRegExp } from 'lodash';

/**
 * Generate Multiple Label Edit Field for Report
 * @param {Object}  props
 * @param {string}  props.label label - label to be used
 * @param {boolean} props.readOnly field is disabled if readOnly
 * @param {string}  props.data the data array, not multiline data
 * @param {promise|function} props.setData change incoming data
 * @param {promise|function} props.setBlocking change isBlocking in parent
 * @param {Object[]} props.reference pretreated data array (see Helper)
 */

const ReportMultipleLabel = (props) => {
	const { label, readOnly, data, setData, setBlocking, reference } = props;

	const [searchObj, setSearch] = useState({ loading: false, results: reference });

	const [curString, setCurString] = useState('');

	const [inputProps, setInputProps] = useState({ fluid: true, placeholder: label });

	useEffect(() => {
		setCurString('');
		setSearch({
			loading: false,
			results: [],
		});
	}, [data]);

	useEffect(() => {
		setInputProps({ fluid: true, placeholder: label });
	}, [label]);

	const handleSearchChange = (source) =>
		debounce(
			(e, { value }) => {
				const search = { ...searchObj, loading: true };
				setSearch(search);
				setCurString(value);
				setBlocking();

				setTimeout(() => {
					if (value.length < 1) {
						setSearch({
							loading: false,
							results: [],
						});
						setCurString('');
						return;
					}

					const re = new RegExp(escapeRegExp(value), 'i');
					const isMatch = (result) => re.test(result.title);

					setSearch({
						loading: false,
						results: source.filter(isMatch),
					});
				}, 400);
			},
			500,
			{ leading: true }
		);

	const handleResultSelect = (e, { result }) => {
		setCurString(result.title);
	};

	const handleSearchAdd = () => {
		setData(null, data.concat(curString)); // event, data
	};

	const handleSearchDelete = (index) => (event) => {
		const newData = data.slice();
		newData.splice(index, 1);
		setData(null, newData); // event, data
	};

	return (
		<>
			<Form.Group className="input-with-button">
				<Form.Field width={15}>
					<label>{label}</label>
					<Search
						{...searchObj}
						onResultSelect={!readOnly && handleResultSelect}
						onSearchChange={!readOnly && handleSearchChange(reference)}
						value={curString}
						input={inputProps}
						noResultsMessage={'找不到' + label}
					/>
				</Form.Field>
				<Button
					onClick={handleSearchAdd}
					className="align-bottom-button"
					color="green"
					type="button"
					floated="right"
					icon="add"
					circular
				/>
			</Form.Group>
			<Form.Group className="form-group-margin">
				<Form.Field>
					<Label.Group>
						{data.map((entry, index) => (
							<Label key={index}>
								{entry}
								<Icon name="close" data-index={index} onClick={handleSearchDelete(index)} />
							</Label>
						))}
					</Label.Group>
				</Form.Field>
			</Form.Group>
		</>
	);
};
export default ReportMultipleLabel;
