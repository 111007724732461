import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Header, Icon, Button, Grid } from 'semantic-ui-react';

const NotFound = () => (
  <Grid columns={1}>
    <Grid.Row>
      <Grid.Column>
        <Segment textAlign='center' basic>
          <Header icon>
            <Icon name='pdf file outline' />
            沒有此頁面，請返回主頁
          </Header>
          <br />
          <Link to='/panel'>
            <Button content='主頁' primary circular />
          </Link>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)


export default NotFound;