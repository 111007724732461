import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  PageHeader,
  FullscreenDimmer,
  BlockerPrompt,
  MySortableTable,
  Buttons,
  FileInputInstantUpload
} from '../../../../Components';

import { get, post } from '../../../../Helper/ApiHelper';

import { findIndex } from 'lodash';

import { Grid, Segment, Form, Icon } from 'semantic-ui-react';

import { fromBlankAsync } from 'xlsx-populate';
import { exportExcel } from '../../../../Helper/ExcelHelper';
import { excelFormats } from '../../../../Const/Const';

import { tableColumnData } from './Core';

class FridayActivityStudentEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: false,
      year: {},
      finishedLoading: false,
      fridayActivities: [],
      students: [],
      normalGroups: [],
      isBlocking: false,
    }

    this.excelHeader = ['班別', '班號', '學生姓名', '性別', '組別']

    this.fileInputRef = React.createRef();
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchData = async () => {
    const { yearId } = this.props.match.params;
    try {
      let [year, students, fridayActivities, specialStudents, normalStudents, normalGroups] = await Promise.all(['getOneYear', 'getStudentEssentialInfo', 'getFridayActivity', 'getAllSpecialFridayActivityStudent', 'getAllNormalFridayActivityStudent', 'getAllFridayActivityNormalGroup'
      ].map(x => get(x, [yearId])));
      students=students.filter(x=>x.status);
      if (!students.length) {
        alert("請校方先在系統設定中上載今年度的學生資料");
      }

      fridayActivities = fridayActivities.filter(x=>x.type==='special');

      const jA_idToIndex = new Map(fridayActivities.map((x, i) => [x.id, i]));
      const specStudentMap = new Map(specialStudents.map(x => [x.studentId, {
        id: x.id,
        jAIndex: jA_idToIndex.get(x.fridayActivityId)
      }]));
      const normStudentMap = new Map(normalStudents.map((x, i) => [x.studentId, i]));

      for (let s of students) {
        Object.assign(s, {
          studentId: s.id,
          id: null,
        });
        if (specStudentMap.has(s.studentId)) {
          const { id, jAIndex } = specStudentMap.get(s.studentId);
          Object.assign(s, {
            id,
            group: fridayActivities[jAIndex].code,
            type: 'special',
            fridayActivityId: fridayActivities[jAIndex].id,
            fridayActivity: fridayActivities[jAIndex].name
          });
        } else {
          if (normStudentMap.has(s.studentId)) {
            const norm = normalStudents[normStudentMap.get(s.studentId)];
            Object.assign(s, {
              id: norm.id,
              group: norm.groupName,
              type: 'normal',
              fridayActivityId: null,
              fridayActivity: null
            });
          }
        }
      }

      await this.setStateAsync({
        year: year[0] || {},
        students,
        fridayActivities,
        normalGroups,
        finishedLoading: true
      });
    } catch (err) { if (err !== 'unmounted') console.log('Error when fetching friday activity', err) }
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  processFile = async (xlsFile) => {
    try {
      const reader = new FileReader();
      const { fromDataAsync, RichText } = (await import('xlsx-populate')).default;
      const workbook = await new Promise((res, rej) => {
        reader.onload = (e) => {
          fromDataAsync(e.target.result).then(res).catch(rej);
        }
        reader.onerror = (evt) => {
          console.log('err', evt);
          rej('讀取檔案時發生錯誤');
        }
        // more stable than binary strings
        reader.readAsArrayBuffer(xlsFile);
      });

      let worksheet = workbook.sheet(0).usedRange().value();

      if (JSON.stringify(worksheet[5].slice(0,this.excelHeader.length)) !== JSON.stringify(this.excelHeader)) {
        throw '內容格式錯誤，請下載名單再作修改'
      } else {
        const normalGroupMap = new Set(this.state.normalGroups.map(x => x.code));
        const fridayActivityMap = new Map(this.state.fridayActivities.map((x,i) => [+x.code, i]));

        let students = [...this.state.students];

        let range = findIndex(worksheet.slice(5), r=>!r[0]||!String(r[0]).trim());
        if(range>0) range-=1; else range = undefined;

        const newDataMap = new Map(worksheet.slice(6, range).map(r=>{
          const [classCode, classNo, code] = [0,1,4].map(i=>r[i]?String(r[i] instanceof RichText ? r[i].text(): r[i]).trim():'');
          console.log(r, classCode, classNo, code);
          return [`${classCode}\b${classNo}`, Number.isInteger(+code)?+code:code]
        }).filter(x=>(typeof x[1] ==='number'?fridayActivityMap:normalGroupMap).has(x[1])));
        
        for (let i=0;i<students.length;i++) {
          students[i] = {...students[i], group: null, fridayActivityId: null, fridayActivity: '', type: null}
          if(newDataMap.has(`${students[i].classCode.trim()}\b${students[i].classNo}`)){
            const data = newDataMap.get(`${students[i].classCode.trim()}\b${students[i].classNo}`);
            if(typeof data === 'number'){
              Object.assign(students[i], {
                group: data,
                type: 'special',
                fridayActivityId: this.state.fridayActivities[fridayActivityMap.get(data)].id,
                fridayActivity: this.state.fridayActivities[fridayActivityMap.get(data)].name
              })
            }else{
              Object.assign(students[i], {
                type: 'normal',
                group: data
              })
            }
          }
        }

        await this.setStateAsync({
          students,
          isBlocking: true,
        })
        return {status: true}
      }
    } catch (err) {
      console.log(err);
      return { status: false, err }
    }
  }

  downloadTemplate = async () => {
    let filename = this.state.year.displayName + '學生分組名單';
    let data = [this.excelHeader];
    let { students } = this.state;

    if (!students || !students.length) {
      students = [{
        classCode: '9X',
        classNo: '99',
        name: '陳大文',
        gender: 'M',
        group: 'C'
      }];
    }
    for (let { classCode, classNo, chiName, engName, gender, group } of students) {
      data.push([classCode, Number(classNo), chiName || engName, gender, group]);
    }

    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);

    sheet.cell('A6').value(data);
    sheet.usedRange().style({
      border: { left: 'medium', right: 'medium', top: 'medium', bottom: 'medium', },
    });

    sheet.range('A1:E1').merged(true).startCell().value(process.env.REACT_APP_SCHOOL_NAME);
    sheet.range('A2:E2').merged(true).startCell().value("周五興趣小組");
    sheet.range('A3:E3').merged(true).startCell().value("全校分組名單");

    sheet.range('A1:E3').style({ fontSize: 16 });

    sheet.column('C').width(24);

    sheet.column('H').width(22);

    // gradeJointActivities
    let legendInfo = [['編號', '專項組別名稱']].concat(this.state.fridayActivities.map(x=>[+x.code, x.name])).concat(this.state.normalGroups.map(x=>[x.code, null]));

    sheet.range('E7:E'+(5+data.length)).dataValidation({
      type: 'list',
      allowBlank: false,
      showErrorMessage: true,
      error: '請輸入在預設顯示在G欄的編號',
      errorTitle: '編號無效',
      formula1: '$G$7:$G$'+(5+legendInfo.length),
    });

    sheet.cell('G6').value(legendInfo);
    sheet.range('G6:H'+(5+legendInfo.length)).style({
      border: { left: 'medium', right: 'medium', top: 'medium', bottom: 'medium' },
    });

    sheet.usedRange().style({
      fontFamily: '標楷體',
      horizontalAlignment: 'center'
    });

    const file = await workbook.outputAsync();
    exportExcel(file, filename);
  }

  return = () => {
    this.props.history.push('/eca/fridayActivity/student');
  }

  save = async () => {
    let specialStudents = [];
    let normalStudents = [];
    const normalGroupMap = new Map(this.state.normalGroups.map(x=>[x.code, x.id]));

    try{
      for(let { id, studentId, group, type, fridayActivityId } of this.state.students){
        switch(type){
          case 'special':
            if(!fridayActivityId) throw "請確保每位學生都有關聯的聯課活動";
            specialStudents.push({
              id,
              yearId: this.props.match.params.yearId,
              fridayActivityId,
              studentId
            });
            break;
          case 'normal':
            if(!normalGroupMap.has(group)) throw "請確保每位學生都有關聯的聯課活動";
            normalStudents.push({
              id,
              yearId: this.props.match.params.yearId,
              normalFridayActivityGroupId: normalGroupMap.get(group),
              studentId
            });
            break;
        }
      }

      await this.setStateAsync({ dimmerOpen: true });
      const result = await post('editFridayActivityStudent', {
        yearId: this.props.match.params.yearId,
        specialStudents,
        normalStudents
      });
      if (result && result.status) {
        await this.setStateAsync({isBlocking: false});
        alert("成功儲存，將會帶你回到學生分組列表");
        this.return();
        return;
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        this.setStateAsync({ dimmerOpen: false })
        if(err==="Field 'normalfridayActivityGroupId' doesn't have a default value" || err === '請確保每位學生都有關聯的聯課活動'){
          alert("請確保每位學生都有關聯的聯課活動");
        }else{
          alert(`儲存${(this.state.year&&this.state.year.displayName)||''}學生分組名單時發生錯誤`);
          console.log('Error when saving joint activity student grouping', err);
        }
      }
    }
  }

  render() {
    const {
      dimmerOpen,
      year,
      students,
      isBlocking,
      finishedLoading
    } = this.state;

    const yearName = year.displayName;

    return (
      <>
        <FullscreenDimmer active={dimmerOpen} isLoading={true} />
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title={yearName !== null ? `編輯${yearName || '---'}年度學生分組名單` : '資料載入中'} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form>
                <Segment.Group className='form-group-margin'>
                  <Segment className='bold' size='big'>學生資料</Segment>
                  <Segment secondary>
                    <FileInputInstantUpload
                      disabled={!finishedLoading || !students.length}
                      noWrapper={true}
                      buttonColor='blue'
                      buttonText='上載'
                      formatErrorText='請上載正確格式(xlsx)'
                      successMsg='成功載入資料，請再檢查後儲存'
                      accept={excelFormats}
                      fileHandling={this.processFile}
                      className='button-margin-bottom'
                    />
                    <Buttons.DownloadButton disabled={!students.length} content='下載修改' onClick={this.downloadTemplate} />
                  </Segment>
                  <Segment secondary>
                    <div style={{ marginLeft: 5 }}><Icon name='user' />共{students.length}位學生</div>
                    <MySortableTable
                      keyHandle='studentId'
                      tableColumnData={tableColumnData}
                      data={students}
                      finishedLoading={finishedLoading}
                    />
                  </Segment>
                </Segment.Group>

                <div className='text-center'>
                  <Buttons.CancelButton onClick={this.return} />
                  <Buttons.SaveButton onClick={this.save} />
                </div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <BlockerPrompt isBlocking={isBlocking} />
      </>
    )
  }
}

export default withRouter(FridayActivityStudentEdit);