/* import lodash */
import {keyBy, flatten, pick, filter, find, groupBy} from 'lodash';

/* import react */
import React, { Component, Fragment, memo } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	EmptyTableMsg,
	FullscreenDimmer,
  MonthRadio,
  MySelect
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions,
  threeYearPlanAndWhichYearFromYear,
  defaultYearForPlan,
  getTeacherOptions,
  whetherReadOnlyForYearPlan
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, TextArea } from 'semantic-ui-react';

const RawStrategyTable = ({
  finishedLoading, aims, strategies, concernIndex, teachersGroupedbyAction, teacherLookup, readOnly, actionLookup, modalToggle, strategyLookup
}) => (
  <Table textAlign='center' celled unstackable fixed>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={7}>標題</Table.HeaderCell>
        <Table.HeaderCell width={2}>時間表</Table.HeaderCell>
        <Table.HeaderCell width={3}>負責人</Table.HeaderCell>
        <Table.HeaderCell width={2}>所需資源</Table.HeaderCell>
        <Table.HeaderCell width={2}>行動</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {finishedLoading ?
        (aims.length === 0 ? <EmptyTableMsg colSpan='5' msg='沒有策略目標' /> : (aims.map((aim, i) => {

            const aimStrategies = filter(strategies, { aimId: aim.id })

            return (
              <Fragment key={aim.id}>
                <EmptyTableMsg colSpan='5' msg={`${+concernIndex + 1}.${+i + 1}. ${aim.title}`} />
                {aimStrategies.length ? aimStrategies.map((s, j) => {
                  const action = actionLookup[s.id];
                  let teachers = [];
                  if (action) {
                    teachers = teachersGroupedbyAction[action.id] || [];
                  }
                  return (
                    <Table.Row key={s.id}>
                      <Table.Cell textAlign="left">{+concernIndex + 1}.{+i + 1}.{+strategyLookup[s.id] + 1}. {s.title}</Table.Cell>
                      <Table.Cell>{action ? `${action.beginMonth || '?'}月—${action.endMonth || '?'}月` : "-"}</Table.Cell>
                      <Table.Cell>
                        {
                          teachers.length ? teachers.map(x => ((x.id && teacherLookup[x.teacherId]) ? teacherLookup[x.teacherId].shortName || teacherLookup[x.teacherId].name : "?")).join("") : "-"
                        }
                      </Table.Cell>
                      <Table.Cell>{(action && action.resourceNeeded) ? action.resourceNeeded : "-"}</Table.Cell>
                      <Table.Cell key='actions'>
                        {(readOnly && !action) ? "-" : (
                          <Button
                            color={action ? 'blue' : 'green'}
                            icon={readOnly ? 'eye' : (action ? 'edit' : 'add')}
                            onClick={modalToggle}
                            data-modalname='isEditModalOpen'
                            data-selected-strategy={s.id}
                            circular
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <EmptyTableMsg colSpan='5' msg="未有策略大綱" />}
              </Fragment>
            )
          }))
        ): <EmptyTableMsg colSpan='5' msg='載入資料中' />
      }
    </Table.Body>
  </Table>
)

const StrategyTable = memo(RawStrategyTable);

class OneYearStrategy extends Component {
  constructor(props) {
    super(props);

    this.action = null;
    this.concernLookup = []; //index->id
    this.strategyLookup = {}; //id->index

    this.default = {
      resourceNeeded: '',
      beginMonth: 9,
      endMonth: 6,
      selectedTeachers: [],
    }
    
    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        required: '請輸入:attribute',
        min: '請最少輸入:min位:attribute',
      }
    })

    this.state = {

      year: [],
      yearInfo: [],
      yearId: '',

      teacherLookup: {},
      teacherOptions: [],

      finishedLoading: false,
      dimmerOpen: false,
      readOnly: true,

      threeYearPlans: [],
      selectedTYP: '',
      whichYear: 0,

      concerns: [],
      concernOptions: [],
      concernIndex: '',

      aims: [],

      strategies: [],
      selectedStrategy: null,

      actions: [],
      teachersGroupedbyAction: {},
      
      title: '',
      theAction: this.default,

      isEditModalOpen: false,
    }
    this.actionLookup = {};
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when mounting 1 year strategies');
      console.log(err);
    }
  }

  fetch = async () => {
    try{
      const [yearInfo, TYPs, teachers] = await Promise.all([
        get('getYear'),
        get('getTYP'),
        get('getAllTeacherWithAdminInSchool'),
      ]);

      const yearId = defaultYearForPlan(yearInfo);
      const [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs);

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId,
        threeYearPlans: TYPs,
        selectedTYP,
        whichYear,
        teacherLookup: keyBy(teachers, 'id'),
      });

      const teacherOptions = await getTeacherOptions(this.state.yearId);
      
      await this.setStateAsync({
        readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId),
        teacherOptions,
      });
      
      await this.fetch3YearPlanDetails(true);
    }catch(err){
      console.log('Error when fetching 1 year strategies');
      console.log(err);
    }
  }

  fetch3YearPlanDetails = async (resetConcern = false) => {
    try{
      const concerns = await get(`get3YearConcern/${+this.state.selectedTYP}`);

      this.concernLookup = concerns.map(x=>x.id);

      await this.setStateAsync({
        concerns,
        concernIndex: resetConcern ? (concerns && concerns.length? 0 : '') : this.state.concernIndex,
        concernOptions: selectOptions(concerns, (c,i)=>{
          const wYear = +(this.state.whichYear);
          const subTopic = c["subTopic"+(1+wYear)];
          return `${i+1}. `+(subTopic?`${c.title} (${subTopic})`:c.title)
        }, (c,i)=>i),
      });

      const aims = await get('get3YearAim/'+this.concernLookup[this.state.concernIndex]);
      await this.setStateAsync({
        aims
      });

      const rawStrategies = await Promise.all(aims.map(aim => get(`get3YearStrategy/${+aim.id}`)));

      this.strategyLookup = {};
      rawStrategies.forEach((strategies, i)=>{
        Object.assign(this.strategyLookup, strategies.reduce((prev, s, j)=>({
          ...prev,
          [s.id]: j,
        }), {}));
      })

      const strategies = flatten(rawStrategies);

      const wYear = this.state.whichYear;

      await this.setStateAsync({
        strategies: Number.isInteger(wYear) ? strategies.filter(s => +s[`year${1+wYear}`]) : [],
      });

      const rawActions = await Promise.all(this.state.strategies.map(s => get(`get1YearAction/${this.state.yearId}/${+s.id}`)));
      const actions = flatten(rawActions);

      this.actionLookup = keyBy(actions, "3yearstrategyId");

      await this.setStateAsync({
        actions,
      });

      const rawActionTeachers = await Promise.all(actions.map(action => get(`getActionTeacher/${action.id}`)));

      await this.setStateAsync({
        teachersGroupedbyAction: groupBy(flatten(rawActionTeachers), 'actionId'),
      })

    }catch(err){
      console.log('Error when fetching TYP detail for 1 year strategies');
      console.log(err);
    }
  };

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId, yearInfo } = this.state;
      if (yearId !== value) {
        const [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, this.state.threeYearPlans, value);
        const TYPchanged = selectedTYP != this.state.selectedTYP;
        await this.setStateAsync({
          yearId: value,
          selectedTYP,
          whichYear,
          finishedLoading: false,
        });

        const teacherOptions = await getTeacherOptions(this.state.yearId);
        
        await this.setStateAsync({
          teacherOptions,
          readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId),
        });

        await this.fetch3YearPlanDetails(TYPchanged);
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      console.log("Error when changing year for One Year Action");
      console.log(err);
    }
  }

  /* Concern Change */
  concernChange = async (event, {value}) => {
    try {
      const { concernIndex } = this.state;
      if (concernIndex !== value) {
        await this.setStateAsync({
          concernIndex: value,
          finishedLoading: false,
        });

        await this.fetch3YearPlanDetails();
        await this.setStateAsync({ finishedLoading: true });
      }
    } catch (err) {
      console.log("Error when changing corcern for One Year Action");
      console.log(err);
    }
  }
  
  inputChange = (event, value) => {
    let inputData;
    //special case for remove icon in semantic-ui-react select
    if(event.target.tagName==='I'){
      inputData = event.target.closest('.ui.dropdown, textarea, button').dataset;
    }else{
      inputData = event.target.closest('.ui, textarea, button').dataset;
    }
    const {inputType, stateName} = inputData;
    this.setStateAsync({
      theAction: {
        ...this.state.theAction,
        [stateName]: inputHandler(inputType, value)
      }
    });
  }

  /**
   * Toggle Modal
   * @param {object|string} eventOrStateName statename is used in calls in functions (turning off modals); event are used for elements
   * @param {number} selectedStrategy? strategyId
   */
  modalToggle = async (eventOrStateName) => {
    try{
      if(typeof eventOrStateName === 'object'){

        let modalData = {};
        if(eventOrStateName.target.classList.contains('modals')){
          modalData = eventOrStateName.target.firstElementChild.dataset;
        }else{
          modalData = eventOrStateName.target.closest('.ui, button').dataset;
        }
        const {modalname} = modalData;
        const selectedStrategy = +modalData.selectedStrategy

        const data = {
          [modalname]: !this.state[modalname],
          selectedStrategy,
          theAction: this.default,
        }

        this.action = this.actionLookup[selectedStrategy];
        
        if(this.action){
          data.theAction = pick(this.action, ['resourceNeeded', 'beginMonth', 'endMonth']);
          const selectedTeachers = this.state.teachersGroupedbyAction[this.action.id].map(x=>x.teacherId);
          Object.assign(data.theAction,{selectedTeachers});
        }
        this.setStateAsync(data);
      }else{
        this.setStateAsync({
          [eventOrStateName]: !this.state[eventOrStateName],
          selectedStrategy: null,
          theAction: this.default
        });
      }
    }catch(err){
      console.log("Error when modal-toggling for 1yearactions");
      console.log(err);
    }
  }

  /* save record */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }

    const data = {...this.state.theAction};

    if(this.action && this.action.hasOwnProperty("id")){
      data.id = this.action.id
    }else{
      data.yearId = this.state.yearId;
      data["3yearstrategyId"] = this.state.selectedStrategy;
    }

    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
  
      const actionResult = await post('edit1YearAction', data);

      if(actionResult&&actionResult.status){
        await this.modalToggle('isEditModalOpen');
        await this.fetch3YearPlanDetails();
      }else{
        throw actionResult;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存周年計劃時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  render() {
    const {
      finishedLoading,
      dimmerOpen,

      year,
      yearId,
      readOnly,

      concernOptions,
      concernIndex,

      aims,
      strategies,

      theAction,

      teachersGroupedbyAction,
      teacherLookup,
      teacherOptions,

      selectedStrategy,
      isEditModalOpen,
    } = this.state;

    const theStrategy = find(strategies, {id: selectedStrategy}) || {title: ''};

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='周年計劃 - 策略項目' subTitle='按照三年計劃，設定學校周年策略項目' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
            <Segment padded>
                <Form>
                  <Form.Group>
                    <Form.Field inline>
                      <label>周年</label>
                      <MySelect compact disabled={!finishedLoading} options={year} value={yearId} onChange={this.yearChange} />
                    </Form.Field>
                    <Form.Field inline>
                      <label>關注事項</label>
                      <MySelect
                        compact
                        disabled={!finishedLoading || !concernOptions.length}
                        options={concernOptions}
                        value={concernIndex}
                        onChange={this.concernChange}
                        placeholder={concernOptions.length?"請選擇":"沒有三年計劃關注事項"}
                        error={!concernOptions.length}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <StrategyTable
                finishedLoading={finishedLoading}
                aims={aims}
                actionLookup={this.actionLookup}
                strategies={strategies}
                concernIndex={concernIndex}
                teachersGroupedbyAction={teachersGroupedbyAction}
                teacherLookup={teacherLookup}
                readOnly={readOnly}
                modalToggle={this.modalToggle}
                strategyLookup={this.strategyLookup}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {dimmerOpen?<FullscreenDimmer active={dimmerOpen} isLoading={true} />:(
          <Modal
            open={isEditModalOpen}
            data-modalname='isEditModalOpen'
            onClose={this.modalToggle}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>設定學校周年策略項目</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <Form>
                  <Form.Group widths='equal' className='form-group-margin'>
                    <Form.Field>
                      <label>標題</label>
                      <TextArea disabled value={theStrategy.title}/>
                    </Form.Field>
                    <Form.Field>
                      <label>所需資源</label>
                      <TextArea
                        disabled={readOnly}
                        value={theAction.resourceNeeded}
                        data-input-type='text'
                        data-state-name='resourceNeeded'
                        onChange={this.inputChange}
                        placeholder='所需資源'
                      />
                    </Form.Field>
                  </Form.Group>
                  <MonthRadio
                    stateName="beginMonth"
                    disabled={readOnly}
                    label="開始月份"
                    value={theAction.beginMonth}
                    changeHandler={this.inputChange}
                    max={theAction.endMonth}
                  />
                  <MonthRadio
                    stateName="endMonth"
                    disabled={readOnly}
                    label="完結月份"
                    value={theAction.endMonth}
                    changeHandler={this.inputChange}
                    min={theAction.beginMonth}
                  />
                  <Form.Group grouped className='form-group-margin'>
                  </Form.Group>
                  <Form.Group grouped>
                    <Form.Field required>
                      <label>將老師加入負責人</label>
                      <MySelect
                        disabled={readOnly}
                        value={theAction.selectedTeachers}
                        options={teacherOptions}
                        data-input-type='select'
                        data-state-name='selectedTeachers'
                        onChange={this.inputChange}
                        fluid
                        multiple
                        search
                        noResultsMessage='找不到教師'
                      />
                      {this.validator.message('老師', theAction.selectedTeachers, 'required|min:1,array')}
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color='red'
                content='取消'
                icon='cancel'
                data-modalname='isEditModalOpen'
                onClick={this.modalToggle}
                circular
              />
              {!readOnly && <Button color='green' content='儲存' icon='save' onClick={this.save} circular />}
            </Modal.Actions>
          </Modal>
        )}
      </>
    )
  }
}

export default withRouter(OneYearStrategy);