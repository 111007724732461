import React, {useContext, useMemo, memo} from 'react';
import {Form} from 'semantic-ui-react'
import DynamicContext from './dynamicFieldContext';
import {genFieldClassName} from './Helper';

const DynamicSubField = (props) => {
  const {readOnly, dynamicInputChange} = useContext(DynamicContext);
  let subFields = false;
  const stack = useMemo(()=>{
    return (props.stack||[]).concat(props.displayName);
  }, [props.stack, props.displayName]);

  let thisField;

  switch(props.displayName){
    case '註明':
    case '其他請註明':
      thisField = (<Form.Input
        value={props.value||''}
        name={stack.join("|")}
        onChange={dynamicInputChange('text', stack)}
        placeholder={props.displayName}
        required
        disabled={readOnly}
      />)
      break;
    case '家長同意與否':
      thisField = (<Form.Group inline className={genFieldClassName(readOnly || props.value)}>
        <label>家長同意與否：</label>
        <Form.Radio
          checked={props.value === true}
          name={stack.join("|")}
          onChange={dynamicInputChange('radio', stack)}
          label='同意'
          value='true'
          readOnly={readOnly}
          disabled={readOnly}
        />
        <Form.Radio
          checked={props.value === false}
          name={stack.join("|")}
          onChange={dynamicInputChange('radio', stack)}
          label='不同意'
          value='false'
          readOnly={readOnly}
          disabled={readOnly}
        />
        <Form.Radio
          checked={props.value === null}
          name={stack.join("|")}
          onChange={dynamicInputChange('radio', stack)}
          label='未有資料'
          value='null'
          readOnly={readOnly}
          disabled={readOnly}
        />
      </Form.Group>)
      break;
    default:
      thisField = (<Form.Checkbox
        checked={!!props.value}
        name={stack.join("|")}
        onChange={dynamicInputChange('checkbox', stack)}
        label={props.displayName}
        readOnly={readOnly}
        disabled={readOnly}
      />);
      break;
  }

  let isInline = true;

  if(props.subFields && props.value){
    let hasParentConsent = props.subFields.hasOwnProperty('家長同意與否');
    subFields = Object.entries(props.subFields).map(([displayName, x],i)=>{
      if(x.hasOwnProperty('subFields')) isInline = false;
      return (<DynamicSubField
        displayName={displayName}
        {...x}
        key={i}
        stack={stack}
      />)
    });
    if(hasParentConsent){
      return (
        <Form.Group grouped>
          {thisField}
          <Form.Group className='next-layer' inline={isInline} grouped={!isInline}>
            {subFields.slice(0,subFields.length-1)}
          </Form.Group>
          <div className='field next-layer'>
            {subFields.slice(subFields.length-1)}
          </div>
      </Form.Group>
      )
    }
    return (
      <Form.Group grouped>
        {thisField}
        <Form.Group className='next-layer' inline={isInline} grouped={!isInline}>
          {subFields}
        </Form.Group>
      </Form.Group>
    )
  }

  return props.subFields?(
    <Form.Group grouped>
      {thisField}
    </Form.Group>
  ):thisField;
}

const DynamicField = (props) => {
  const {readOnly, dynamicInputChange} = useContext(DynamicContext);
  const {value, displayName, subFields, index} = props;
  let SubFields = false;

  const newStack = useMemo(()=>{
    return [displayName]
  }, [displayName]);

  let isInline = true;
  let hasParentConsent = false;

  if(subFields && value){
    SubFields = Object.entries(props.subFields).map(([displayName, x],i)=>{
      if(x.hasOwnProperty('subFields')) isInline = false;
      return (<DynamicSubField
        displayName={displayName}
        {...x}
        key={i}
        stack={newStack}
      />)
    });
    hasParentConsent = props.subFields.hasOwnProperty('家長同意與否');
  }
  
  const thisField = /註明$/.test(displayName) ? (<Form.Input
    value={value||''}
    onChange={dynamicInputChange('text', newStack)}
    placeholder={displayName}
    required
    disabled={readOnly}
  />):(<Form.Checkbox
    checked={!!value}
    onChange={dynamicInputChange('checkbox', newStack)}
    toggle
    readOnly={readOnly}
    disabled={readOnly}
  />);

  if(hasParentConsent){
    return (
      <Form.Group className='form-group-margin' grouped>
        <Form.Group inline>
          <label>{index}. {displayName}</label>
          {thisField}
        </Form.Group>
        <Form.Group inline={isInline} grouped={!isInline} className='next-layer'>
          {SubFields.slice(0,SubFields.length-1)}
        </Form.Group>
        <div className='field next-layer'>
          {SubFields.slice(SubFields.length-1)}
        </div>
      </Form.Group>
    )
  }

  return (
    <Form.Group className='form-group-margin' grouped>
      <Form.Group inline>
        <label>{index}. {displayName}</label>
        {thisField}
      </Form.Group>
      {(SubFields!==false) && <Form.Group inline={isInline} grouped={!isInline} className='next-layer'>
        {SubFields}
      </Form.Group>}
    </Form.Group>
  )
}

export default memo(DynamicField);