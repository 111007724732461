/* import react */
import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import ErrorLabel from '../../../Components/ErrorLabel';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import SignModal from '../../../Components/SignModal';
import DisplayBeforeComponent from '../Item/DisplayBeforeComponent';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import { markSelectOptions, selectOptions, roundTwoDp } from '../../../Helper/Helper';
import { momentToDate, dateToMoment, htmlInputDate } from '../../../Helper/TimeHelper';

/* import calculation function */
import { formContentMarkForOne, base } from '../Report/Calculation';

/* import lodash */
import { filter, find, forEach, map, orderBy, maxBy } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import {
	Grid,
	Form,
	Segment,
	Table,
	Button,
	TextArea,
	Message,
	GridRow,
	GridColumn,
	TableBody,
	SegmentGroup,
	FormGroup,
	FormInput,
	FormSelect,
} from 'semantic-ui-react';
import DisplayBeforeFile from '../Item/DisplayBeforeFile';

const tableColor = ['#FFFFFF', '#eaeaea', '#cfcfcf'];

const EditOther = (props) => {
	const history = useHistory();
	const [, forceUpdate] = useState();
	const validator = useRef(
		new SimpleReactValidator({
			autoForceUpdate: { forceUpdate: () => forceUpdate(1) },
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				default: '請輸入資料',
			},
		})
	);

	const { section, teacherId, appraisalItemId, appraisalTargetId, appraisalMarkingId } = useParams();
	const [loading, setLoading] = useState(false);
	const [maxScore, setMaxScore] = useState(0);
	const [teacherInfo, setTeacherInfo] = useState({});
	const [appraisalItem, setAppraisalItem] = useState({});
	const [appraisalTarget, setAppraisalTarget] = useState({});
	const [appraisalMarking, setAppraisalMarking] = useState({});
	const [appraisalContentBeforeTree, setAppraisalContentBeforeTree] = useState([]);
	const [appraisalBefore, setAppraisalBefore] = useState({});
	const [files, setFiles] = useState([]);
	const [appraisalContentOther, setAppraisalContentOther] = useState([]);
	const [appraisalContentOtherTree, setAppraisalContentOtherTree] = useState([]);
	const [appraisalOther, setAppraisalOther] = useState({});
	const [subjects, setSubjects] = useState([]);
	const [appraisalTextOptions, setAppraisalTextOptions] = useState([]);
	const [appraisalStatus, setAppraisalStatus] = useState(null);
	const [appraisalNonApplicable, setAppraisalNonApplicable] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [isSignModalOpen, setIsSignModalOpen] = useState(false);
	const [defaultMark, setDefaultMark] = useState(null);
	const isAppeal = section === 'appeal';

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const teacherInfo = await get('getOneTeacher/' + teacherId);
			const appraisalItem = await get('getAppraisalItem/' + appraisalItemId);
			const appraisalTarget = await get('getOneAppraisalFormTarget/' + appraisalTargetId);
			const appraisalMarking = await get('getOneAppraisalMarkingById/' + appraisalMarkingId);
			let appraisalContentBefore = await get('getAppraisalContentBefore/' + appraisalItemId);
			const appraisalFormBefore = await get('getAppraisalFormBefore/' + appraisalTargetId);
			const appraisalFiles = await get('getAppraisalFile/' + appraisalTargetId);
			let appraisalContentOther = await get('getAppraisalContentOther/' + appraisalItemId);
			const appraisalFormOther = await get('getAppraisalFormOther/' + appraisalTargetId + '/' + teacherId);
			const subjects = await get('getSubject');
			const appraisalTextOptions = await get('getAppraisalTextOption/' + appraisalItemId);

			let appraisalBefore = {};
			appraisalFormBefore.map(({ appraisalContentId, text, date, score }) => {
				let value = '不適用';
				if (!!score) value = score;
				else if (!!text) value = text;
				else if (!!date) value = dateToMoment(date);
				appraisalBefore[appraisalContentId] = value;
			});

			let appraisalOther = {};
			appraisalContentOther.map(({ id, inputType, multiple, notApplicable }) => {
				const record = find(appraisalFormOther, { appraisalContentId: id });
				let value = record && record.text ? record.text : '';
				if (inputType === 'date') value = record && record.date ? htmlInputDate(record.date) : htmlInputDate();
				else if (inputType === 'score') {
					if (notApplicable) value = record && record.score ? record.score : 'Not applicable';
					else value = record && record.score ? record.score : 0;
				} else if (inputType === 'textSelect' && multiple) value = record && record.text ? record.text.split(', ') : [];
				return (appraisalOther[id] = value);
			});

			appraisalContentBefore = orderBy(appraisalContentBefore, ['order', 'id']);
			appraisalContentOther = orderBy(appraisalContentOther, ['order', 'id']);

			const maxRecord = maxBy(appraisalContentOther, 'max');
			const maxScore = maxRecord?.max || 0;

			setMaxScore(maxScore);
			setTeacherInfo(teacherInfo.length > 0 ? teacherInfo[0] : {});
			setAppraisalItem(appraisalItem.length > 0 ? appraisalItem[0] : {});
			setAppraisalTarget(appraisalTarget.length > 0 ? appraisalTarget[0] : {});
			setAppraisalMarking(appraisalMarking.length > 0 ? appraisalMarking[0] : {});
			setAppraisalContentBeforeTree(buildTree(appraisalContentBefore));
			setAppraisalBefore(appraisalBefore);
			setFiles(appraisalFiles);
			setAppraisalContentOther(appraisalContentOther);
			setAppraisalContentOtherTree(buildTree(appraisalContentOther));
			setIsEdit(appraisalFormOther.length > 0);
			setAppraisalOther(appraisalOther);
			setSubjects(selectOptions(subjects, 'displayName', 'displayName'));
			setAppraisalTextOptions(appraisalTextOptions);
			setLoading(false);
		};

		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const appraisalNonApplicable = await get(`getAppraisalNonApplicable/${appraisalTargetId}`);
			setAppraisalNonApplicable(map(appraisalNonApplicable, 'appraisalContentId'));
			setLoading(false);
		};

		if (!!appraisalItem && ['觀課', '科主/同儕觀課'].indexOf(appraisalItem.displayName) >= 0) fetch();
	}, [appraisalItem]);

	const inputChange = (inputType, stateName) => (event, data) => {
		let value = inputHandler(inputType, data);
		setAppraisalOther((appraisalOther) => ({
			...appraisalOther,
			[stateName]: value,
		}));
	};

	const displayOther = (appraisalContentOtherTree, level = 1) => {
		return (
			<Fragment>
				{appraisalContentOtherTree.map((content, index) => {
					const {
						id,
						description,
						notes,
						needInput,
						inputType,
						min,
						max,
						step,
						notApplicable,
						count,
						multiple,
						percentage,
						avgCount,
						children,
					} = content;
					const value = appraisalOther[id];
					let label = description;
					if (count) {
						if (avgCount) label += '（平均計算）';
						else if (!!percentage) label += '（' + percentage + '%）';
					}

					const display = appraisalNonApplicable.indexOf(id) < 0;

					if (display)
						return (
							<Fragment key={index}>
								<GridColumn
									computer={!needInput || inputType === 'text' || level === 1 ? 16 : 8}
									largeScreen={!needInput || inputType === 'text' || level === 1 ? 16 : 8}
									mobile={16}
									style={{ backgroundColor: `${tableColor[level - 1] || '#FFFFFF'}` }}
								>
									<div style={{ display: 'flex', alignItems: 'flex-start' }}>
										<FormGroup className="no-margin" grouped style={{ width: '100%' }}>
											{needInput ? (
												<React.Fragment>
													{inputType === 'text' && (
														<FormInput
															control={TextArea}
															onChange={inputChange('text', id)}
															value={value || ''}
															type="text"
															label={label}
														/>
													)}

													{inputType === 'date' && (
														<FormInput
															type="date"
															onChange={inputChange('text', id)}
															value={value || htmlInputDate()}
															label={label}
														/>
													)}

													{['score', 'subject', 'textSelect'].indexOf(inputType) >= 0 && (
														<FormSelect
															label={label}
															clearable
															search={!!multiple}
															multiple={!!multiple}
															onChange={inputChange('select', id)}
															options={
																inputType === 'subject'
																	? subjects
																	: inputType === 'score'
																	? markSelectOptions(min, max, step, notApplicable)
																	: selectOptions(
																			filter(appraisalTextOptions, { appraisalContentId: id }),
																			'displayName',
																			'displayName'
																	  )
															}
															value={multiple ? (Array.isArray(value) ? value : []) : value || ''}
														/>
													)}
													{description !== '評語' && validator.current.message(description, value, 'required')}
												</React.Fragment>
											) : (
												<Form.Field label={label} />
											)}
										</FormGroup>
									</div>
									<div className="paragraph">{notes}</div>
								</GridColumn>
								{children && displayOther(children, level + 1)}
							</Fragment>
						);
				})}
			</Fragment>
		);
	};

	const back = () => {
		let url = `/appraisal/item/form_list/${appraisalItemId}`;
		if (isAppeal) url = `/appraisal/appeal/form_list/${appraisalItemId}/${teacherId}`;
		history.push(url);
	};

	const displayAvg = () => {
		let mark = 0;
		let storePercentage = 0;

		forEach(appraisalContentOtherTree, ({ id, percentage, max, count, children }) => {
			if (count) {
				let returnMark = formContentMarkForOne(id, max, count, appraisalOther, children) * percentage;
				if (!isNaN(returnMark)) {
					mark += returnMark;
					storePercentage += percentage;
				}
			}
		});

		if (storePercentage > 0) {
			return roundTwoDp(mark / storePercentage);
		} else {
			return '-';
		}
	};

	const defaultMarkChange = (event, data) => {
		let value = inputHandler('select', data);
		setDefaultMark(value);
	};

	const useDefaultMark = () => {
		if (!!defaultMark) {
			let newAppraisalOther = {};
			appraisalContentOther.map(({ id, inputType, max, notApplicable }) => {
				if (appraisalNonApplicable.indexOf(id) < 0 && inputType === 'score') {
					if (defaultMark === 'Not applicable' && notApplicable) {
						return (newAppraisalOther[id] = 'Not applicable');
					}
					if (defaultMark >= 0 && defaultMark <= max) {
						return (newAppraisalOther[id] = defaultMark);
					}
				}
			});
			setAppraisalOther((appraisalOther) => ({
				...appraisalOther,
				...newAppraisalOther,
			}));
			setDefaultMark(null);
		}
	};

	const openSign = (appraisalStatus = null) => {
		if (appraisalStatus !== 'save' && !validator.current.allValid()) {
			validator.current.showMessages();
			alert('資料不完整');
			return;
		}
		setAppraisalStatus(appraisalStatus);
		setIsSignModalOpen(true);
	};

	const save = () => {
		const appraisalOtherArray = Object.keys(appraisalOther).map((id) => {
			const contentRecord = find(appraisalContentOther, { id: Number(id) });
			if (contentRecord.inputType === 'date') {
				return { appraisalContentId: id, date: appraisalOther[id] };
			}

			if (contentRecord.inputType === 'score') {
				return {
					appraisalContentId: id,
					score: appraisalOther[id] === 'Not applicable' || appraisalOther[id] === '' ? null : appraisalOther[id],
				};
			}

			if (contentRecord.inputType === 'textSelect' && contentRecord.multiple) {
				return { appraisalContentId: id, text: appraisalOther[id].join(', ') };
			}

			return { appraisalContentId: id, text: appraisalOther[id] };
		});

		let data = {
			isEdit,
			appraisalTargetId,
			appraisalOtherArray,
			teacherId: isAppeal ? teacherId : null,
			appraisalStatus,
		};

		setLoading(true);
		post('editAppraisalFormOther', data).then((result) => {
			if (result) {
				validator.current.hideMessages();
				back();
			}
		});
	};

	const buttons = [
		{ key: 'cancel', color: 'red', content: '取消', action: back },
		{ key: 'save', color: 'orange', content: '儲存', action: () => openSign('save'), display: !isAppeal },
		{ key: 'check', color: 'green', content: '提交', action: () => openSign('complete') },
	];

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid>
				<GridRow>
					<GridColumn>
						{isAppeal ? (
							<PageHeader
								title={teacherInfo.name ? '編輯他人評估: ' + teacherInfo.name : '編輯他人評估'}
								prevPage={back}
							/>
						) : (
							<PageHeader title="進行他人評估" prevPage={back} />
						)}
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<Segment.Group>
							<Segment className="bold">
								<Grid columns={3} stackable>
									<GridRow className="larger-font" verticalAlign="middle">
										<GridColumn>
											{appraisalItem.yearName} : {appraisalItem.displayName}
										</GridColumn>
									</GridRow>
									<GridRow>
										<GridColumn>被評老師: {appraisalTarget.name}</GridColumn>
										<GridColumn>評估老師: {appraisalMarking.name}</GridColumn>
										<GridColumn>遞交限期: {momentToDate(appraisalTarget.deadline)}</GridColumn>
									</GridRow>
									<GridRow>
										<GridColumn>評估科目: {appraisalTarget.subject ? appraisalTarget.subject : ' - '}</GridColumn>
										<GridColumn>評估班別: {appraisalTarget.className ? appraisalTarget.className : ' - '}</GridColumn>
									</GridRow>
								</Grid>
							</Segment>
							{appraisalContentBeforeTree.length > 0 && (
								<Segment>
									<Table basic="very" padded celled>
										<TableBody>
											<DisplayBeforeComponent
												appraisalContentBeforeTree={appraisalContentBeforeTree}
												appraisalBefore={appraisalBefore}
											/>
										</TableBody>
									</Table>
								</Segment>
							)}
							<DisplayBeforeFile files={files} teacherName={appraisalTarget?.name} />
						</Segment.Group>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<SegmentGroup>
							<Segment className="bold larger-font">
								<Grid columns={2} stackable>
									<GridRow verticalAlign="middle">
										<GridColumn>評估內容</GridColumn>
									</GridRow>
								</Grid>
							</Segment>
							<Segment style={{ backgroundColor: '#ffd6d6' }}>
								<Form style={{ display: 'flex', alignItems: 'flex-end' }}>
									<FormGroup className="no-margin" grouped>
										<FormSelect
											label="選擇預設分數（確認後將覆蓋現有分數）"
											clearable
											onChange={defaultMarkChange}
											options={markSelectOptions(1, maxScore, maxScore > 4 ? 1 : 0.5, true)}
											value={defaultMark}
										/>
									</FormGroup>
									<Button
										color="green"
										content="確認"
										type="button"
										onClick={useDefaultMark}
										circular
										className="align-bottom-button"
									/>
								</Form>
							</Segment>
							<Segment padded="very" style={{ marginBottom: 0 }}>
								<Form>
									<Grid>{displayOther(appraisalContentOtherTree)}</Grid>
									<div style={{ marginTop: 40, marginBottom: 30 }}>
										<Message>
											<Message.Header>{`平圴分數：${displayAvg()} / ${base}`}</Message.Header>
										</Message>
									</div>
									<Grid>
										<GridRow>
											<GridColumn textAlign="center">
												{buttons.map(({ key, color, content, action, display = true }) => {
													if (display)
														return (
															<Button
																key={key}
																className="button-margin-bottom"
																color={color}
																content={content}
																icon={key}
																type="button"
																onClick={action}
																circular
															/>
														);
												})}
											</GridColumn>
										</GridRow>
									</Grid>
								</Form>
							</Segment>
						</SegmentGroup>
					</GridColumn>
				</GridRow>
			</Grid>
			<SignModal open={isSignModalOpen} close={() => setIsSignModalOpen(false)} save={save} />
		</React.Fragment>
	);
};

export default EditOther;
