/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {keyBy, pick} from 'lodash';

/* import components */
import {
	PageHeader,
	ConfirmModal,
	BlockerPrompt,
	FlexibleTextField,
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import {
  selectOptions,
  defaultYear,
  whetherReadOnlyForReport
} from '../../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Button, Form, Segment, Select } from 'semantic-ui-react';

class Student_Performance extends Component {
  constructor(props) {
    super(props);

    this.actualId = null;

    this.state = {
      finishedLoading: false,
      isSaveModalOpen: false,
      isBlocking: false,
      readOnly: true,

      year: [],
      yearInfo: {},
      selectedYear: '',
      student_perform: '',
      student_perform_fb_and_fu: '',
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when fetching 3 year Review & SWOP');
      console.log(err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try{
      //get year info
      const rawYear = await get('getYear');
      let year = selectOptions(rawYear, 'displayName', 'id');
      let yearInfo = keyBy(rawYear, 'id');
      const selectedYear = this.state.selectedYear ? this.state.selectedYear : defaultYear(rawYear);

      await this.setStateAsync({year, yearInfo, selectedYear});

      await this.setStateAsync({
        readOnly: whetherReadOnlyForReport(this.state.yearInfo, this.state.selectedYear)
      });

      await this.fetchYearReportData();
    }catch(err){
      console.log('Error when fetching 1 year Report background info');
      console.log(err);
    }
  }

  fetchYearReportData = async () => {
    try{
      const [oneYearReportData] = await get('get1YearReport/' + this.state.selectedYear);
      if(oneYearReportData){
        this.actualId = oneYearReportData.hasOwnProperty('id') ? oneYearReportData.id : null;
        await this.setStateAsync(pick(oneYearReportData, ['student_perform','student_perform_fb_and_fu',]));
      }else{
        this.actualId = null;
        await this.setStateAsync({
          student_perform: '',
          student_perform_fb_and_fu: '',
        });
      }
    }catch(err){
      console.log('Error when fetching 1 year Report');
      console.log(err);
    }
  }

  save = async () => {
    try{
      await this.setStateAsync({
        finishedLoading: false,
      })
      const data = pick(this.state, ['student_perform','student_perform_fb_and_fu']);
      if(this.state.selectedYear){
        data.yearId = this.state.selectedYear;
      }
      if(this.actualId){
        data.id = this.actualId;
      }
      const result = await post('edit1YearReport', data);
      if(result && result.status){
        this.setStateAsync({
          isBlocking: false,
        });
        await this.fetch();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert('儲存學生表現資料時發生錯誤');
        console.log('Error when saving 學生表現', err);
      }
    }
    await this.saveModalToggle();
    this.setStateAsync({
      finishedLoading: true,
    })
  }

  /* modal toggle */
  saveModalToggle = async () => {
    this.setStateAsync({
      isSaveModalOpen: !this.state.isSaveModalOpen
    });
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({
      [stateName]: value,
      isBlocking: true
    })
  }

  yearChange = async (_,data) => {
    if(this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要切換年度嗎？")){
        return;
      }
    }
    try{
      let value = inputHandler('select', data);
      await this.setStateAsync({
        selectedYear: value,
        finishedLoading: false,
      });

      await this.setStateAsync({
        readOnly: whetherReadOnlyForReport(this.state.yearInfo, this.state.selectedYear)
      });

      await this.fetchYearReportData();
    }catch(err){
      console.log('Error when changing selected OYP');
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
      isBlocking: false,
    });
  }

  render() {
    const {
      isSaveModalOpen,
      finishedLoading,
      readOnly,
      isBlocking,

      year,
      selectedYear,

      student_perform,
      student_perform_fb_and_fu
    } = this.state;

    return (<>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='周年報告 - 學生表現' subTitle='輸入不同方面的成就，回饋與跟進' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Field inline>
                  <label>周年</label>
                  <Select
                    disabled={!finishedLoading}
                    options={year}
                    value={selectedYear}
                    onChange={this.yearChange}
                  />
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form>
              <Segment.Group>
                <Segment>
                  <Form.Field>
                    <label>正文項目 (無需 1,2,3...)</label>
                    <FlexibleTextField
                      multiline={true}
                      readOnly={readOnly}
                      value={student_perform}
                      options={{
                        rows: 16,
                        disabled: !finishedLoading,
                        onChange: this.inputChange('text', 'student_perform')
                      }}
                    />
                  </Form.Field>
                </Segment>
                <Segment>
                  <Form.Field>
                    <label>回饋與跟進 (無需 1,2,3...)</label>
                    <FlexibleTextField
                      multiline={true}
                      readOnly={readOnly}
                      value={student_perform_fb_and_fu}
                      options={{
                        rows: 12,
                        disabled: !finishedLoading,
                        onChange: this.inputChange('text', 'student_perform_fb_and_fu')
                      }}
                    />
                  </Form.Field>
                </Segment>
                {!readOnly && (<Segment>
                  <Form.Field
                    control={Button}
                    color="green"
                    onClick={this.saveModalToggle}
                    disabled={!finishedLoading}
                  >儲存</Form.Field>
                </Segment>)}
              </Segment.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ConfirmModal
        open={isSaveModalOpen}
        description='確定儲存周年報告學生表現資料？'
        cancel={this.saveModalToggle}
        confirm={this.save}
        confirmIcon='check'
        confirmText='儲存'
      />
      <BlockerPrompt isBlocking={isBlocking}/>
    </>)
  }
}

export default withRouter(Student_Performance);