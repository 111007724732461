import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
	PageHeader,
  FullscreenDimmer,
  ConfirmModal,
  MySortableTable,
  Buttons,
  MySelect
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions, genGradeString } from '../../../Helper/Helper';
import { momentToHtmlInput } from '../../../Helper/TimeHelper';
import genVenueTable from '../../../Layout/ECA/venueTable';

import {weekdayLookup, gradeOptions} from '../../../Const/Const';

import { find, groupBy, keyBy } from 'lodash';

import { Grid } from 'semantic-ui-react';

import { genSimpleExcel } from '../../../Helper/ExcelHelper';

const weekDayRender = x=>x.weekday?String(x.weekday).split("").map(y=>weekdayLookup[y]).join("、"):"-";
const initSortStack = [{column: '星期', direction: 'ascending'}];

class ActivitySummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,
      yearOptions: [],
      selectedYear: '',
      isDeleteModalOpen: false,
      dimmerOpen: false,

      selectedId: null,
      activityName: '',
      activities: [],
      activityTeachers: [],
      locationLookup: null,
      tableColumnData: [
        { headerName: '星期', width: 1, allowSort: true, cellRender: weekDayRender, sortMethod: x=>x.weekday },
        { headerName: '活動名稱', width: 2, allowSort: true, cellRender: 'name' },
        { headerName: '負責老師', width: 2, allowSort: true, cellRender: (x)=>{
          if(!this.state.activityTeachers[String(x.id)])return '外聘導師';
          return this.state.activityTeachers[String(x.id)].map(x=>x.name||'外聘導師').join("、");
        } },
        { headerName: '活動地點', width: 1, allowSort: true, cellRender: x=>(x.locationName||'-') },
        {
          headerName: <abbr title='括號內是離組/離校的數字'>學生人數</abbr>, width: 1, cellRender: x=>`${x.studentCount[1]}${x.studentCount[0]?` (${x.studentCount[0]})`:''}`
        },
        { headerName: '詳情', width: 1, cellClassName: 'textlessCell', cellRender: x=>(
          <>
            <Buttons.ViewButton data-id={x.id} onClick={this.viewDetail} />
            <Buttons.DeleteButton data-selected-id={x.id} onClick={this.deleteModalToggle} />
          </>
        )}
      ]
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchYear = async () => {
    try{
      const result = await get('getYear');
      await this.setStateAsync({
        yearOptions: selectOptions(result, 'displayName', 'id'),
        selectedYear: defaultYear(result)
      })
    }catch(err){if(err!=='unmounted')console.log("Error when fetching year", err)}
  }

  fetchData = async () => {
    try{
      const {selectedYear} = this.state;
      const [activities, rawActivityTeachers, activityStudents, locations] = await Promise.all([
        get('getActivity/' + selectedYear),
        get('getAllActivityTeacher/' + selectedYear),
        get('getAllActivityStudent/' + selectedYear),
        get('getLocation')
      ]);

      const locationMap = new Map();

      for(let location of locations)
        locationMap.set(location.id, location);

      const aSGrouped = groupBy(activityStudents, 'activityId');

      for(let a of activities){
        a.studentCount = [0,0];
        if(aSGrouped[a.id]){
          for(let s of aSGrouped[a.id]){
            a.studentCount[+s.status]++;
          }
        }
      }

      await this.setStateAsync({
        activities,
        activityTeachers: groupBy(rawActivityTeachers, 'activityId'),
        activityStudents: aSGrouped,
        locationMap
      });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching activity data", err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchYear();
    await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  yearChange = async (event, { value }) => {
    try{
      await this.setStateAsync({ finishedLoading: false, selectedYear: value });
      await this.fetchData(this.state.selectedYear);
      this.setStateAsync({ finishedLoading: true });
    }catch(err){if(err!=='unmounted')console.log("Error when changing year", err)}
  }

  newActivity = () => {
    this.mounted = false;
    this.props.history.push('/eca/activity/summary/edit/' + this.state.selectedYear);
  }

  viewDetail = (event) => {
    this.mounted = false;
    this.props.history.push('/eca/activity/summary/detail/' + this.state.selectedYear + '/' + event.target.closest('button').dataset.id);
  }

  downloadLocationList = async () => {
    const year = find(this.state.yearOptions, { value: this.state.selectedYear });
    const {activities, locationMap, activityTeachers} = this.state;

    let filename = `${year.text}「課後活動場地使用」一覽表 (${momentToHtmlInput()}更新版)`;

    const aggregateData = new Map();

    locationMap.forEach(({level,name}) => {
      if(!aggregateData.has(level)){
        aggregateData.set(level, new Map());
      }
      if(!aggregateData.get(level).has(name)){
        aggregateData.get(level).set(name, Array.from({length: 5}).map(x=>([])));
      }
    })
    
    for(let activity of activities){
      const location = locationMap.get(activity.locationId);
      if(location){
        const wkArray = activity.weekday?String(activity.weekday).split(""):[];
        for(let wkday of wkArray){
          if(wkday>5)continue;
          let text = activity.name;
          if(activityTeachers[activity.id]){
            text+=` (${activityTeachers[activity.id].map(x=>x.shortName||x.name||"導師").join("、")})`
          }
          aggregateData.get(location.level).get(location.name)[wkday-1].push(text);
        }
      }
    }

    genVenueTable({
      filename,
      data: aggregateData
    })
  }
  
  downloadStudentList = async () => {
    const {selectedYear, activities, activityTeachers, activityStudents} = this.state;
    const year = find(this.state.yearOptions, { value: selectedYear });
    const activityMap = keyBy(activities, 'id');

    const students = await get('getStudent/'+selectedYear);
    const studentMap = keyBy(students, 'id');

    const studentsSorted = [];

    for(let activityId in activityStudents){
      for(let activityStudent of activityStudents[activityId]){
        if(!activityStudent.status) continue;
        const activity = activityMap[activityId] || {};
        const student = studentMap[activityStudent.studentId] || {};
        studentsSorted.push({
          ...activityStudent,
          ...student,
          ...activity
        });
      }
    }

    genSimpleExcel(studentsSorted, `課外活動總學生名單 - ${year?year.text:''}`, [
      {width: 13, headerTitle: "星期", render: weekDayRender},
      {width: 20, headerTitle: "活動名稱", render: 'name'},
      {width: 17, headerTitle: "負責老師", render: x=>activityTeachers[x.activityId].map(x=>x.shortName||"導師").join("、")},
      {width: 11, headerTitle: "地點", render: 'locationName'},
      {width: 16, headerTitle: "時間", render: x=>`${String(x.startTime||'?').replace(/:\d+$/,'')}-${String(x.endTime||'?').replace(/:\d+$/,'')}`},
      {width: 7, headerTitle: "班別", render: x=>x?x.classCode:'-'},
      {width: 7, headerTitle: "班號", render: x=>x?x.classNo:'-'},
      {width: 33, headerTitle: "英文姓名", render: x=>x?x.engName:'-'},
      {width: 15, headerTitle: "中文姓名", render: x=>x?x.chiName:'-'},
      {width: 7, headerTitle: "性別", render: x=>x?x.gender:'-'},
    ]);
  }

  downloadActivityByGrade = async () => {
    const {selectedYear, activities, activityTeachers} = this.state;
    const year = find(this.state.yearOptions, { value: selectedYear });

    genSimpleExcel(activities, `各級課後活動一覽表 - ${year?year.text:''}`, [
      {width: 13, headerTitle: '星期', render: weekDayRender},
      {width: 13, headerTitle: '科目', render: x=>x.subjectName||'跨學科'},
      {width: 22, headerTitle: '活動名稱', render: 'name'},
      {width: 16, headerTitle: '負責老師', render: x=>activityTeachers[x.id].map(x=>x.shortName||"外聘導師").join("、")},
      {width: 11, headerTitle: '地點', render: 'locationName'},
      {width: 16, headerTitle: '時間', render: x=>`${String(x.startTime||'?').replace(/:\d+$/,'')}-${String(x.endTime||'?').replace(/:\d+$/,'')}`},
      {width: 14, headerTitle: '級別', render: genGradeString},
    ].concat(gradeOptions.map(y=>({
      width: 6.1,
      headerTitle: y.text,
      render: x=>x['grade_'+y.value]?"\u2713":""
    }))));
  }

  deleteModalToggle = async (event) => {
    if(event){
      let activityName;
      let {selectedId} = event.target.closest('button').dataset;
      const activity = find(this.state.activities, {id: +selectedId});
      if(activity){
        activityName = activity.name;
      }
    
      this.setStateAsync({
        isDeleteModalOpen: !this.state.isDeleteModalOpen,
        selectedId: selectedId||null,
        activityName
      });
      return;
    }
    this.setStateAsync({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
      selectedId: null,
      activityName: ''
    });
  }

  deleteActivity = async () => {
    try{
      await this.setStateAsync({finishedLoading: false, dimmerOpen: true});
      const result = await post('deleteActivity', {id: this.state.selectedId});
      if(result && result.status){
        await this.fetchData();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert('刪除課外活動時發生錯誤');
        console.log('Error when deleting ASummary',err);
      }
    }
    await this.deleteModalToggle();
    await this.setStateAsync({finishedLoading: true, dimmerOpen: false});
  }
  
  render() {
    const {
      dimmerOpen,
      finishedLoading,
      isDeleteModalOpen,
      activityName,
      yearOptions,
      selectedYear,
      activities
    } = this.state;

    return (<>
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='課外活動列表' subTitle='管理每年度的課外活動' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <MySelect
              disabled={!finishedLoading}
              onChange={this.yearChange}
              options={yearOptions}
              value={selectedYear}
            />
            <Buttons.DownloadButton
              floated='right'
              className='button-margin-bottom'
              content='場地總表'
              disabled={!finishedLoading}
              onClick={this.downloadLocationList}
            />
            <Buttons.DownloadButton
              floated='right'
              className='button-margin-bottom'
              content='各級課後活動一覽表'
              disabled={!finishedLoading}
              onClick={this.downloadActivityByGrade}
            />
            <Buttons.DownloadButton
              floated='right'
              className='button-margin-bottom'
              content='總學生名單'
              disabled={!finishedLoading}
              onClick={this.downloadStudentList}
            />
            <Buttons.AddButton
              floated='right'
              className='button-margin-bottom'
              content='新增課外活動'
              disabled={!finishedLoading}
              onClick={this.newActivity}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <MySortableTable
              data={activities}
              finishedLoading={finishedLoading}
              tableColumnData={this.state.tableColumnData}
              sortStack={initSortStack}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {dimmerOpen ? ( <FullscreenDimmer active={dimmerOpen} isLoading={true} /> ) : (
        <ConfirmModal
          open={isDeleteModalOpen}
          description={<>確定刪除課外活動 <span className="bold">{activityName}</span>？<span className="red bold">注意：這會一併刪除課堂時間等資料</span></>}
          cancel={this.deleteModalToggle}
          confirm={this.deleteActivity}
        />
      )}
    </>)
  }
}

export default withRouter(ActivitySummary);