/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import react router */
import { Link } from 'react-router-dom';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import EmptyTableMsg from '../../../Components/EmptyTableMsg';
import ConfirmModal from '../../../Components/ConfirmModal';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { defaultYear, selectOptions } from '../../../Helper/Helper';

/* import lodash */
import { map, orderBy } from 'lodash';

/* import semantic-ui element */
import { Grid, Select, Table, Checkbox, Button } from 'semantic-ui-react';

class Appeal extends Component {
    constructor(props) {
        super(props);
        ping(this);        
    }

    state = {
        dimmerOpen: false,
        teacher: [],

        showHidden: false,

        year: [],
        selectedYear: '',

        appraisalLock: {},
        isLockModalOpen: false,
        isUnlockModalOpen: false,

        sortColumn: ['ascending', null, null],

        tableHeader: [
            {displayName: '編號', columnName: 'index'},
            {displayName: '職位', columnName: 'displayName'},
            {displayName: '姓名', columnName: 'name'},
        ]
    }

    fetchYear = async () => {
        return new Promise(async (resolve, reject) => {
            const result = await get('getYear');
            let selectedYear = defaultYear(result);
            let year = selectOptions(result, 'displayName', 'id');
            this.setState({ selectedYear, year })
            resolve(selectedYear);
        });
    }
   

    fetch = async (selectedYear) => {
        const [appraisalLock, teacher] = await Promise.all([
            get('getOneAppraisalLock/'+selectedYear),
            get('getAllTeacherWithAdminWithIndex/'+selectedYear)
        ])

        this.setState({
            appraisalLock: appraisalLock.length > 0? appraisalLock[0] : {},
            teacher
        });
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    componentDidMount = async () => {
        this.mounted = true;
        const selectedYear = await this.fetchYear();
        await this.fetch(selectedYear);        
        this.setState({ dimmerOpen: false });
    }

    /* modal toggle */
    modalToggle = (modalStateName) => () => {
        this.setState({ [modalStateName]: !this.state[modalStateName] })
    }

    /* toggle hidden teacher */
    toggleHiddenTeacher = (event, data) => {      
        this.setState({ 'showHidden': inputHandler('checkbox', data) })
    }

    /* table sorting */
    sorting = (index) => () => {
        let { teacher, sortColumn, tableHeader } = this.state;
        let column = [], order = [], newDirection = null;
        if(sortColumn[index] === null) newDirection = 'ascending';
        if(sortColumn[index] === 'ascending') newDirection = 'descending';
        sortColumn[index] = newDirection;
        map(sortColumn, (direction, index) => {
            if(direction) {
                column.push(tableHeader[index]['columnName']);
                order.push(direction === 'ascending'? 'asc' : 'desc');
            }
        })
        this.setState({ sortColumn, teacher: orderBy(teacher, column, order) });
    }

    /* year change */
    yearChange = async (event, { value }) => {
        const { selectedYear } = this.state;
        if(selectedYear !== value) {
            this.setState({dimmerOpen: true});
            await this.fetch(value);
            this.setState({
                selectedYear: value,
                dimmerOpen: false
            })
        }
    }

    /* lock */
    lock = async () => {
        const { selectedYear } = this.state;
        let data = { yearId: selectedYear };
        this.setState({dimmerOpen: true})
        let lock = await post('lockAppraisal', data);
        if(lock.status) {
            await this.fetch(selectedYear);
            this.setState({ 
                isLockModalOpen: false,
                dimmerOpen: false 
            });
        }
    }

    /* lock */
    unlock = async () => {
        const { selectedYear } = this.state;
        let data = { yearId: selectedYear };
        this.setState({dimmerOpen: true})
        let unlock = await post('unlockAppraisal', data);
        if(unlock.status) {
            await this.fetch(selectedYear);
            this.setState({ 
                isUnlockModalOpen: false,
                dimmerOpen: false 
            });
        }
    }
    
    render() {
        const { dimmerOpen, teacher, sortColumn, showHidden, appraisalLock, isLockModalOpen, isUnlockModalOpen, selectedYear, year, tableHeader } = this.state;

        return (
            <Fragment>
                {dimmerOpen? 
                    <FullscreenDimmer active={dimmerOpen} isLoading={true} />
                    :
                    <Fragment>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <PageHeader title='覆核評估分數' subTitle='選擇需要覆核之老師' />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <div>
                                        <Select className='button-margin-bottom' onChange={this.yearChange} options={year} value={selectedYear} />
                                        <Checkbox style={{marginLeft: '1rem'}} label='顯示已離校老師' checked={showHidden} onChange={this.toggleHiddenTeacher} />
                                    </div>
                                    <div>
                                        {Boolean(appraisalLock.appraisalLock)?
                                            <Button color='red' content='解鎖本年度評估分數' icon='unlock' onClick={this.modalToggle('isUnlockModalOpen')} circular />                                
                                            :
                                            <Button color='orange' content='鎖定本年度評估分數' icon='lock' onClick={this.modalToggle('isLockModalOpen')} circular />                                
                                        }
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Table textAlign='center' sortable selectable celled unstackable>
                                        <Table.Header>
                                            <Table.Row >
                                                {tableHeader.map(({displayName}, index) => {
                                                    return (
                                                        <Table.HeaderCell key={index} sorted={ sortColumn[index] } onClick={this.sorting(index)}>
                                                            {displayName}
                                                        </Table.HeaderCell>
                                                    )
                                                })}
                                                <Table.HeaderCell>行動</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {teacher.length > 0?
                                                <Fragment>
                                                    {teacher.map(({id, name, displayName, status, index }) => {
                                                        return (
                                                            <Fragment key={id}>
                                                                {(Boolean(Number(status)) || showHidden) &&
                                                                    <Table.Row className={Boolean(Number(status))? '': 'left-school'}>
                                                                        <Table.Cell>{index}</Table.Cell>
                                                                        <Table.Cell>{displayName}</Table.Cell>
                                                                        <Table.Cell>{name}</Table.Cell>
                                                                        <Table.Cell>
                                                                            <Link to={'/appraisal/appeal/item/'+selectedYear+'/'+id}>
                                                                                <Button color='blue' icon={Boolean(appraisalLock.appraisalLock)? 'eye' : 'edit'} circular />
                                                                            </Link>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                }
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Fragment>
                                                :
                                                <EmptyTableMsg colSpan='1' />
                                            }
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <ConfirmModal open={isLockModalOpen} description='確定鎖定今年評估分數？' cancel={this.modalToggle('isLockModalOpen')} confirm={this.lock} confirmText='鎖定' confirmIcon='lock' />
                        <ConfirmModal open={isUnlockModalOpen} description='確定解鎖今年評估分數？（不建議）' cancel={this.modalToggle('isUnlockModalOpen')} confirm={this.unlock} confirmText='鎖定' confirmIcon='lock' />
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default withRouter(Appeal);