import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import {findIndex} from 'lodash';
import {Form, Input, Grid} from 'semantic-ui-react';
import {useParams, useHistory,}  from 'react-router-dom';

import { get, post } from '../../../Helper/ApiHelper';
import { genStudentOptionName, parseJSONArray, selectOptions, useCancellablePromise } from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

import {
  PageHeader,
  FullscreenDimmer,
  BlockerPrompt,
  MyTable,
  MySelect,
  Buttons
} from '../../../Components';

const tableProps = { structured: true, compact: true, unstackable: true };

const OutsideActivityAwards = () => { 
  
  const [dimmerOpen, setDimmerOpen] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [isBlocking, setBlocking] = useState(false);
  
  const {outsideActivityId, section, yearId} = useParams();
  const history = useHistory();

  const [activity, setActivity] = useState(null);
  const [newId, decreaseId] = useReducer((state, action)=>state-1, -1);
  const [events, setEvents] = useState([]);
  
  const { cancellablePromise } = useCancellablePromise();
  useEffect(() => {
    const fetchData = async () => {
      try{
        const [activity, students, rawEvents, awards] = await cancellablePromise(Promise.all([
          get('getOneOutsideActivityWithDetails/' + outsideActivityId),
          get('getOutsideActivityStudent/' + outsideActivityId),
          get('getOutsideActivityEvents/' + outsideActivityId),
          get('getOutsideActivityAwards/' + outsideActivityId),
        ]));
        setActivity(activity[0]);
        const studentMap = new Map(students.map(x=>[x.studentId, genStudentOptionName(x)]))
        const events = rawEvents.map(x=>({
          ...x,
          awards: [],
          studentOptions: selectOptions(parseJSONArray(x.studentIds), x=>studentMap.get(x)||'-', x=>x)
        }))
        const eventMap = new Map(events.map((x,i)=>[x.id, x]));
        for(let a of awards){
          a.students = parseJSONArray(a.students);
          eventMap.get(a.eventId).awards.push(a);
        }
        setEvents(events);
        setFinishedLoading(true);
      }catch(err){
        console.log(err);
        if(!err.isCanceled){
          console.log('Error when fetching info');
        }
      }
    }
    fetchData();
  }, [outsideActivityId]);
  
  const canEdit = activity?moment().isBefore(moment(activity.date).add(1, 'y')):false;

  const changeAward = (event, data) => {
    const {id, eventId, stateName, inputType} = event.target.closest('.ui.selection, .ui.input').dataset;
    setBlocking(true);
    setEvents(source => {
      const eventIndex = findIndex(source, {id: +eventId});
      if(eventIndex<0) return source;
      const index = findIndex(source[eventIndex].awards, {id: +id});
      if(index<0) return source;
      const awards = [...source[eventIndex].awards];
      awards[index] = {
        ...awards[index],
        [stateName]: inputHandler(inputType, data)
      }
      const newSource = [...source];
      newSource[eventIndex] = {
        ...source[eventIndex],
        awards
      }
      return newSource;
    })
  }

  const deleteAward = (event) => {
    const {id, eventId} = event.target.closest('.ui').dataset;
    setBlocking(true);
    setEvents(source => {
      const index = findIndex(source, {id: +eventId});
      if(index<0) return source;
      const newSource = [...source];
      newSource[index] = {
        ...source[index],
        awards: source[index].awards.filter(x=>x.id!==+id)
      }
      return newSource;
    })
  }

  const addAward = (event) => {
    const id = +event.target.closest('.ui').dataset.eventId;
    setBlocking(true);
    setEvents(source => {
      decreaseId();
      const index = findIndex(source, {id});
      if(index<0) return source;
      const newSource = [...source];
      newSource[index] = {
        ...source[index],
        awards: source[index].awards.concat({
          id: newId,
          eventId: id,
          prizeName: '',
          groupAlias: '',
          students: []
        })
      }
      return newSource;
    })
  }

  const tableColumnData = [
    { headerName: '項目', width: 4, cellRender: 'name', show: events.filter(x=>x.name).length>0},
    { headerName: '獲獎學生及獎項名', cellClassName: 'table-cell-with-dropdown', width: 12, cellRender: (item)=>(
      <>
        {item.awards.map(x=>{
          return canEdit?(
            <Form.Group className='input-with-button form-group-margin-narrow' key={x.id}>
              <Form.Field width={5}>
                <Input
                  fluid
                  placeholder='獎項名'
                  data-event-id={item.id}
                  data-id={x.id}
                  value={x.prizeName||''}
                  data-input-type='text'
                  data-state-name='prizeName'
                  onChange={changeAward}
                />
              </Form.Field>
              <Form.Field width={5}>
                <MySelect
                  fluid
                  multiple
                  search
                  options={item.studentOptions}
                  data-event-id={item.id}
                  data-id={x.id}
                  placeholder='學生'
                  value={x.students||''}
                  data-input-type='select'
                  data-state-name='students'
                  onChange={changeAward}
                />
              </Form.Field>
              <Form.Field width={4}>
                <Input
                  fluid
                  placeholder='統稱'
                  data-event-id={item.id}
                  data-id={x.id}
                  value={x.groupAlias||''}
                  data-input-type='text'
                  data-state-name='groupAlias'
                  onChange={changeAward}
                />
              </Form.Field>
              <Buttons.DeleteButton 
                data-event-id={item.id}
                data-id={x.id}
                onClick={deleteAward}
              />
            </Form.Group>
          ):(
            <Form.Group className='input-with-button form-group-margin-narrow' key={x.id} widths={3}>
              <Form.Field>
                {x.prizeName||'-'}
              </Form.Field>
              <Form.Field>
                {x.students.length?'LOL':'全體學生獲獎'}
              </Form.Field>
              <Form.Field>
                {x.groupAlias||'-'}
              </Form.Field>
            </Form.Group>
          )
        })}
        {canEdit&&(<Form.Group grouped>
          <Buttons.AddButton
            data-event-id={item.id}
            onClick={addAward}
          />
        </Form.Group>)}
      </>
    )},
  ];
  
  const quit = () => {
    if (outsideActivityId) {
      history.push(`/eca/outsideActivity/${section}/detail/${yearId}/${outsideActivityId}`);
    } else {
      history.push(`/eca/outsideActivity/${section}`);
    }
  }

  const save = async () => {
    setDimmerOpen(true);
    setFinishedLoading(false);
    let done = false;
    try{
      const result = await post('setOutsideActivityAwards', {
        outsideActivityId,
        awards: events.reduce((prev,cur)=>prev.concat(
          cur.awards.map(x=>({...x, eventId: cur.id}))
        ), [])
      })
      if(result && result.status){
        setBlocking(false);
        done = true;
        quit();
      }
    }catch(err){
      console.log(err);
      if(!err.isCanceled){
        alert(`儲存${activity?activity.name:'比賽'}獎項資料時發生錯誤`);
        console.log('Error when fetching info');
      }
    }
    if(!done){
      setDimmerOpen(false);
      setFinishedLoading(true);
    }
  }

  return (
    <>
      {dimmerOpen && <FullscreenDimmer active={true} isLoading={true} />}
      {<Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader
              title={'編輯獎項'+(activity?` - ${activity.yearName} ${activity.name}`:'')}
              subTitle={<div className='red'>注意：
                <ul>
                  <li>如所有參與此項目的學生都獲獎，可留空 "學生" 的位置</li>
                  <li>是否團體賽由有無統稱 (如 "1A班"、"{process.env.REACT_APP_SCHOOL_NAME}"等) 決定</li>
                </ul>
              </div>}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form>
              <MyTable
                tableColumnData={tableColumnData}
                data={events}
                noDataMsg="找不到項目資料"
                tableProps={tableProps}
                finishedLoading={finishedLoading}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='center'>
            <Buttons.CancelButton disabled={!finishedLoading} onClick={quit} />
            <Buttons.SaveButton disabled={!finishedLoading} onClick={save} />
          </Grid.Column>
        </Grid.Row>
      </Grid>}
      <BlockerPrompt isBlocking={isBlocking}/>
    </>
  );
}
export default OutsideActivityAwards;