/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {EmptySegmentMsg} from '../../Components';

import {get} from '../../Helper/ApiHelper';
import {timeToDescription, dateToMoment} from '../../Helper/TimeHelper';

import {Grid, Segment, Header, Table} from 'semantic-ui-react';
import '../../Styles/SurveyDashboard.css';

class RecommendedPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {pendingSurveys: null}
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentDidMount = async () => {
    this.mounted = true;
    try{
      const pendingSurveys = await get('getPendingSurveys');
      await this.setStateAsync({pendingSurveys}, "mount");
    }catch(err){
      console.log(err);
      if(err !== 'unmounted' ){
        console.log("Error when loading surveys to be completed", err);
      }
    }
  }

  componentWillUnmount = () =>{
    this.mounted = false;
  }

  navToSurvey = (id) => () => {
    this.props.history.push(`/survey/complete/${+id}`)
  }

  getClassName = (date) => {
    if(dateToMoment(date).diff(dateToMoment(), "days")<7){
      return 'red cursor';
    }else{
      if(dateToMoment(date).diff(dateToMoment(), "month")<1){
        return 'orange cursor'
      }
    }
    return 'cursor';
  }


  render() {
    const {pendingSurveys} = this.state;
    return (
      <Grid padded='vertically' centered stretched>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment.Group>
              <Segment attached="bottom">
                <Header as='h2' textAlign='center'>
                  <Header.Content>待提交問卷</Header.Content>
                </Header>
              </Segment>
              {
                pendingSurveys===null?(
                  <EmptySegmentMsg msg="資料載入中" icon={false}/>
                ):(pendingSurveys.length?(
                  <Segment className='no-padding'>
                    <Table fixed selectable celled>
                      <colgroup>
                        <col/>
                        <col id="survey-data"/>
                      </colgroup>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>標題</Table.HeaderCell>
                          <Table.HeaderCell>截止日期</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                      {pendingSurveys.map(({id, title,endDate})=>{
                        let className = this.getClassName(endDate);
                        return (
                          <Table.Row
                            className={className}
                            onClick={this.navToSurvey(+id)}
                            key={id}
                          >
                            <Table.Cell><Header as="h4">{title}</Header></Table.Cell>
                            <Table.Cell>{timeToDescription(endDate)}</Table.Cell>
                          </Table.Row>
                        )
                      })}
                      </Table.Body>
                    </Table>
                  </Segment>
                ):(<EmptySegmentMsg msg="您沒有未完成的問卷"/>))
              }
              </Segment.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withRouter(RecommendedPanel);