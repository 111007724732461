import React from 'react';
import { Table } from 'semantic-ui-react';
import EmptyTableMsg from './EmptyTableMsg';

const RawMyTableRow = (props) => (
  <Table.Row className={props.rowClassName}>
    {props.tableColumnData.filter(x=>x.show!==false).map(colData=>{
      let cellProps = {};
      switch(typeof colData.cellProps){
        case 'object': cellProps = colData.cellProps; break;
        case 'function': cellProps = colData.cellProps(props.data, props.index); break;
        default: break;
      }
      return (<Table.Cell key={colData.headerName} colSpan={colData.colSpan} className={colData.cellClassName ? (typeof colData.cellClassName ==='string'?colData.cellClassName:colData.cellClassName(props.data)):undefined} {...cellProps}>
        {
          typeof colData.cellRender === "function" ? colData.cellRender(props.data, props.index): props.data[colData.cellRender]
        }
      </Table.Cell>)
    })}
  </Table.Row>
)

export const MyTableRow = React.memo(RawMyTableRow);

const MyTable = (props) => {
  const totalCols = props.tableColumnData.reduce((prev,cur)=>prev+(cur.show!==false?cur.colSpan?cur.colSpan:1:0),0);
  return (<Table fixed textAlign="center" selectable celled {...(props.tableProps||{})}>
    <Table.Header>
      {props.customHeader?props.customHeader:
        <Table.Row>
          {props.tableColumnData.filter(x=>x.show!==false).map(colData => colData.HeaderRender?<colData.HeaderRender key={colData.headerName} width={colData.width}/>:(
            <Table.HeaderCell key={colData.headerName} width={colData.width}>{colData.headerName}</Table.HeaderCell>
          ))}
        </Table.Row>
      }
    </Table.Header>
    <Table.Body>
      {Array.isArray(props.data) && props.finishedLoading !== false ? (
        props.data.length ? (
          props.data.map((item, i) => (
            <MyTableRow
              data={item}
              tableColumnData={props.tableColumnData}
              key={props.useIndex?i:(props.keyHandle?item[props.keyHandle]:item.id)}
              index={i}
            />
          ))
        ) : <EmptyTableMsg colSpan={totalCols} msg={props.noDataMsg || "找不到資料"} />
      ) : <EmptyTableMsg colSpan={totalCols} msg={props.loadingMsg || "載入資料中"} />}
    </Table.Body>
    {props.tableFooterRender && <Table.Footer fullWidth><Table.Row><Table.HeaderCell colSpan={totalCols}>{props.tableFooterRender(props.data)}</Table.HeaderCell></Table.Row></Table.Footer>}
  </Table>)
}

export default MyTable;