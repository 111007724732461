import React, { useState, useCallback } from 'react';
import Router from './Router/Router';
import { User, Media } from './Context';
import { Helmet } from 'react-helmet';
import {localhost} from './Helper/ApiHelper';
import {pick} from 'lodash';

const imgDir = process.env.PUBLIC_URL+(process.env.REACT_APP_DEMO?"/demo":"");

const App = () => {
  const [user, updateUser] = useState({
    userId: null,
    isAdmin: false,
    name: '',
    menus: [],
    hasMenuAccess: () => true
  });

  /**
   * Ping and Update User
   * @param {{
   *  path: string,
   *  abortSignal: boolean
   * }} object path and abortSignal
   */
  const pingUser = useCallback(({path, abortSignal})=>{
    return fetch(localhost+'/ping', {
      method: 'POST',
      body: JSON.stringify({pathname: path}),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      signal: abortSignal,
    }).then(response=>response.json()).then(result=>{
      if(path!=='/' && !result.userId){
        switch(result.err){
          case '請先登入':
          case '此Session已過期':
            alert("您未登入或登錄已逾時，請重新登入");
            break;
          default:
            alert(result.err);
            break;
        }
      }
      result.hasMenuAccess = (url) => Array.isArray(result.menus) && result.menus.some(x=>x.url===url);
      updateUser({
        ...pick(result, ['userId' ,'isAdmin' ,'name', 'hasMenuAccess']),
        menus: result.menus||[],
        privilege: result.privilege||{}
      })
      return result.status;
    })
  }, [updateUser]);

  return (
    <>
      <Helmet>
        <link rel="apple-touch-icon" sizes="57x57" href={imgDir+"/apple-icon-57x57.png"} />
        <link rel="apple-touch-icon" sizes="60x60" href={imgDir+"/apple-icon-60x60.png"} />
        <link rel="apple-touch-icon" sizes="72x72" href={imgDir+"/apple-icon-72x72.png"} />
        <link rel="apple-touch-icon" sizes="76x76" href={imgDir+"/apple-icon-76x76.png"} />
        <link rel="apple-touch-icon" sizes="114x114" href={imgDir+"/apple-icon-114x114.png"} />
        <link rel="apple-touch-icon" sizes="120x120" href={imgDir+"/apple-icon-120x120.png"} />
        <link rel="apple-touch-icon" sizes="144x144" href={imgDir+"/apple-icon-144x144.png"} />
        <link rel="apple-touch-icon" sizes="152x152" href={imgDir+"/apple-icon-152x152.png"} />
        <link rel="apple-touch-icon" sizes="180x180" href={imgDir+"/apple-icon-180x180.png"} />
        <link rel="icon" type="image/png" sizes="192x192" href={imgDir+"/android-icon-192x192.png"} />
        <link rel="shortcut icon" helmetKey="favicon" href={imgDir+"/favicon.ico"} />
        <link rel="icon" type="image/png" sizes="32x32" href={imgDir+"/favicon-32x32.png"} />
        <link rel="icon" type="image/png" sizes="96x96" href={imgDir+"/favicon-96x96.png"} />
        <link rel="icon" type="image/png" sizes="16x16" href={imgDir+"/favicon-16x16.png"} />
        <meta name="msapplication-TileImage" content={imgDir+"/ms-icon-144x144.png"} />
      </Helmet>
      <style>{Media.mediaStyles}</style>
      <Media.MediaContextProvider>
        <User.Provider value={{ ...user, pingUser }}>
          <Router />
        </User.Provider>
      </Media.MediaContextProvider>
    </>
  );
}
export default App;