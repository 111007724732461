import React, { useState, useEffect } from 'react';
import { Form, Search } from 'semantic-ui-react';
import { debounce, escapeRegExp } from 'lodash';

/**
 * Generate Multiple Label Edit Field for Report
 * @param {Object}  props
 * @param {string}  props.label label - label to be used
 * @param {boolean} props.readOnly field is disabled if readOnly
 * @param {string}  props.value the string value
 * @param {promise|function} props.setValue change incoming value
 * @param {promise|function} props.setBlocking change isBlocking in parent
 * @param {Object[]} props.reference pretreated value array (see Helper)
 */

const MySearch = (props) => {
  const {label, readOnly, value, setValue, setBlocking, reference} = props;

  const [searchObj, setSearch] = useState({loading: false, results: reference});

  const [inputProps, setInputProps] = useState({ fluid: true, placeholder: label });

  useEffect(()=>{
    setInputProps({ fluid: true, placeholder: label });
  }, [label])

  const handleSearchChange = (source) => debounce((e, { value }) => {
    const search = {...searchObj, loading: true,};
    setSearch(search);
    setValue(null, value);
    setBlocking();

    setTimeout(() => {

      if (value.length < 1){
        setSearch({
          loading: false,
          results: [],
        });
        setValue(null, '');
        return;
      }

      const re = new RegExp(escapeRegExp(value), 'i')
      const isMatch = result => re.test(result.title);

      setSearch({
        loading: false,
        results: source.filter(isMatch),
      });

    }, 300)
  }, 500, { leading: true })

  const handleResultSelect = (e, { result }) => {
    setValue(null, result.title);
  }

  return (
    <Form.Field className='form-group-margin'>
      <label>{label}</label>
      <Search
        {...searchObj}
        onResultSelect={!readOnly && handleResultSelect}
        onSearchChange={!readOnly && handleSearchChange(reference)}
        value={value}
        input={inputProps}
        noResultsMessage={"找不到"+label}
      />
    </Form.Field>
  )
}
export default MySearch;

// label: 評估結論, 評估後建議