/* import react */
import React, { Fragment, useEffect, useRef, useState } from 'react';

/* import components */
import PageHeader from '../../../Components/PageHeader';
import ErrorLabel from '../../../Components/ErrorLabel';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import SignModal from '../../../Components/SignModal';

/* import date input */
import { DateInput } from 'semantic-ui-calendar-react';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { selectOptions } from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import { momentToDate } from '../../../Helper/TimeHelper';

/* Appraisal css */
import '../../../Styles/Appraisal.css';

/* import lodash */
import { compact, filter, find, map, orderBy } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import {
	Grid,
	Button,
	Segment,
	SegmentGroup,
	Form,
	TextArea,
	Select,
	Label,
	Icon,
	GridRow,
	GridColumn,
	Header,
	LabelGroup,
} from 'semantic-ui-react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

const EditTable = () => {
	const location = useLocation();
	const history = useHistory();
	const { section, appraisalItemId, teacherId, appraisalTableRecordId = null } = useParams();
	const [, forceUpdate] = useState();
	const [dimmerOpen, setDimmerOpen] = useState(false);
	const [isSignModalOpen, setIsSignModalOpen] = useState(false);
	const [teacherInfo, setTeacherInfo] = useState({});
	const [appraisalTargetId, setAppraisalTargetId] = useState(null);
	const [appraisalTarget, setAppraisalTarget] = useState({});
	const [appraisalItem, setAppraisalItem] = useState({});
	const [appraisalContents, setAppraisalContents] = useState([]);
	const [appraisalContentTree, setAppraisalContentTree] = useState([]);
	const [appraisalTableDetails, setAppraisalTableDetails] = useState({});
	const [appraisalTextOptions, setAppraisalTextOptions] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [returnUrl, setReturnUrl] = useState('/');
	const appeal = section === 'appeal';

	const validator = useRef(
		new SimpleReactValidator({
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				default: '請輸入資料',
			},
		})
	);

	useEffect(() => {
		if (!!location) {
			ping({ props: { location } });
		}
	}, [location]);

	useEffect(() => {
		const fetch = async () => {
			setDimmerOpen(true);

			const teacherInfo = await get('getOneTeacher/' + teacherId);
			setTeacherInfo(teacherInfo.length > 0 ? teacherInfo[0] : {});

			const appraisalTarget = await get('getPersonalAppraisalTableTarget/' + appraisalItemId + '/' + teacherId);
			if (appraisalTarget.length > 0) {
				setAppraisalTargetId(appraisalTarget[0].id);
				setAppraisalTarget(appraisalTarget[0]);
			}

			const appraisalItem = await get('getAppraisalItem/' + appraisalItemId);
			setAppraisalItem(appraisalItem.length > 0 ? appraisalItem[0] : {});

			let appraisalContents = await get('getAppraisalContent/' + appraisalItemId);
			appraisalContents = orderBy(appraisalContents, ['order', 'id']);
			setAppraisalContents(appraisalContents);
			setAppraisalContentTree(buildTree(appraisalContents));

			const subjects = await get('getSubject');
			setSubjects(subjects);

			const appraisalTextOptions = await get('getAppraisalTextOption/' + appraisalItemId);
			setAppraisalTextOptions(appraisalTextOptions);

			const appraisalTableRecords = await get('getOneAppraisalTableRecord/' + appraisalTableRecordId);
			const appraisalTableDetails = [];
			map(appraisalContents, ({ id, inputType, multiple }) => {
				const record = find(appraisalTableRecords, { appraisalContentId: id });

				if (inputType === 'date') {
					return (appraisalTableDetails[id] =
						record && record.date
							? {
									temp: '',
									date: record.date.split(','),
							  }
							: {
									temp: '',
									date: [],
							  });
				}

				if (inputType === 'score') {
					return (appraisalTableDetails[id] = record && record.score ? record.score : 0);
				}

				if (inputType === 'textSelect' && multiple) {
					return (appraisalTableDetails[id] = record && record.text ? record.text.split(', ') : []);
				}

				return (appraisalTableDetails[id] = record && record.text ? record.text : '');
			});
			setAppraisalTableDetails(appraisalTableDetails);

			setDimmerOpen(false);
		};

		fetch();
	}, []);

	useEffect(() => {
		setReturnUrl(`/appraisal/${section}/table_list/${appraisalItemId}${appeal ? `/${teacherId}` : ''}`);
	}, [appeal, appraisalItemId, teacherId]);

	const inputChange = (inputType, id, data) => {
		const value = inputHandler(inputType, data);
		setAppraisalTableDetails((appraisalTableDetails) => [
			...appraisalTableDetails.slice(0, id),
			value,
			...appraisalTableDetails.slice(id + 1),
		]);
	};

	const dateChange = (id, data) => {
		const value = inputHandler('date', data);
		setAppraisalTableDetails((appraisalTableDetails) => [
			...appraisalTableDetails.slice(0, id),
			{
				...appraisalTableDetails[id],
				temp: momentToDate(value),
			},
			...appraisalTableDetails.slice(id + 1),
		]);
	};

	const addDate = (id) => {
		setAppraisalTableDetails((appraisalTableDetails) => [
			...appraisalTableDetails.slice(0, id),
			{
				date: [...appraisalTableDetails[id]['date'], appraisalTableDetails[id]['temp']],
				temp: '',
			},
			...appraisalTableDetails.slice(id + 1),
		]);
	};

	const removeDate = (id, index) => {
		setAppraisalTableDetails((appraisalTableDetails) => [
			...appraisalTableDetails.slice(0, id),
			{
				...appraisalTableDetails[id],
				date: [
					...appraisalTableDetails[id]['date'].slice(0, index),
					...appraisalTableDetails[id]['date'].slice(index + 1),
				],
			},
			...appraisalTableDetails.slice(id + 1),
		]);
	};

	const toggleSign = () => {
		if (!validator.current.allValid()) {
			validator.current.showMessages();
			forceUpdate(1);
			return;
		}
		setIsSignModalOpen((isSignModalOpen) => !isSignModalOpen);
	};

	const save = async () => {
		const appraisalTableDetailArray = map(Object.keys(appraisalTableDetails), (id) => {
			const contentRecord = find(appraisalContents, { id: Number(id) });
			if (!!contentRecord) {
				let { inputType, multiple } = contentRecord;
				let value = appraisalTableDetails[id];

				if (inputType === 'date') {
					const dateArray = appraisalTableDetails[id]['date'].map((date) => {
						return date.includes('-') ? momentToDate(date) : date;
					});

					value = dateArray.join();
				}

				if (inputType === 'textSelect') {
					inputType = 'text';
					if (!!multiple) value = appraisalTableDetails[id].join(', ');
				}

				return { appraisalContentId: id, [inputType]: value };
			}
		});

		const data = {
			appraisalTableRecordId,
			appraisalTargetId,
			appraisalTableDetailArray: compact(appraisalTableDetailArray),
		};

		setDimmerOpen(true);
		const result = await post('editAppraisalTableRecord', data);
		if (result) {
			setDimmerOpen(false);
			validator.current.hideMessages();
			forceUpdate(1);
			history.push(returnUrl);
		}
	};

	const displayForm = (appraisalContentTree, level = 1) => {
		return (
			<Grid padded>
				{appraisalContentTree.map(({ id, description, needInput, inputType, multiple, children }, index) => {
					const width = !!needInput ? 8 : 16;
					return (
						<GridColumn key={index} mobile={16} tablet={width} computer={width} largeScreen={width}>
							<Form.Group grouped>
								{!!needInput ? (
									<React.Fragment>
										{inputType === 'text' && (
											<Form.Input
												control={TextArea}
												onChange={(event, data) => {
													inputChange('text', id, data);
												}}
												value={appraisalTableDetails[id] || ''}
												type="text"
												label={description}
												placeholder={description}
											/>
										)}

										{inputType === 'date' && (
											<div>
												<Form.Group className="input-with-button">
													<Form.Field className="wide">
														<label>日期</label>
														<DateInput
															className="flex-1"
															placeholder="日期"
															value={appraisalTableDetails[id]['temp']}
															onChange={(event, data) => dateChange(id, data)}
														/>
													</Form.Field>
													<Button onClick={() => addDate(id)} color="green" type="button" icon="add" circular />
												</Form.Group>
												{appraisalTableDetails[id]['date'].length > 0 && (
													<LabelGroup size="large">
														{appraisalTableDetails[id]['date'].map((date, index) => (
															<Label key={index} color="teal">
																{date.includes('-') ? momentToDate(date) : date}
																<Icon onClick={() => removeDate(id, index)} name="delete" data-index={index} />
															</Label>
														))}
													</LabelGroup>
												)}
												{validator.current.message(description, appraisalTableDetails[id]['date'], 'required')}
											</div>
										)}

										{inputType === 'subject' && (
											<Form.Field
												control={Select}
												onChange={(event, data) => inputChange('select', id, data)}
												value={appraisalTableDetails[id] || ''}
												options={subjects}
												label={description}
												placeholder={description}
											/>
										)}

										{inputType === 'score' && (
											<Form.Input
												onChange={(event, data) => inputChange('number', id, data)}
												value={appraisalTableDetails[id] || 0}
												type="number"
												label={description}
												placeholder={description}
												min="0"
											/>
										)}

										{inputType === 'textSelect' &&
											(multiple ? (
												<Form.Select
													onChange={(event, data) => inputChange('select', id, data)}
													value={appraisalTableDetails[id] || []}
													options={selectOptions(
														filter(appraisalTextOptions, { appraisalContentId: id }),
														'displayName',
														'displayName'
													)}
													label={description}
													placeholder={description}
													search
													multiple
													clearable
												/>
											) : (
												<Form.Select
													onChange={(event, data) => inputChange('select', id, data)}
													value={appraisalTableDetails[id] || ''}
													options={selectOptions(
														filter(appraisalTextOptions, { appraisalContentId: id }),
														'displayName',
														'displayName'
													)}
													label={description}
													placeholder={description}
													clearable
												/>
											))}
										{inputType !== 'date' &&
											validator.current.message(description, appraisalTableDetails[id], 'required')}
									</React.Fragment>
								) : (
									<label>{description}</label>
								)}
								{children && this.displayForm(children, level + 1)}
							</Form.Group>
						</GridColumn>
					);
				})}
			</Grid>
		);
	};

	if (dimmerOpen) return <FullscreenDimmer active={dimmerOpen} isLoading={true} />;

	return (
		<React.Fragment>
			<Grid>
				<GridRow>
					<GridColumn>
						<PageHeader
							title={appeal ? `編輯評估記錄${!!teacherInfo.name ? `：${teacherInfo.name}` : ''}` : '編輯評估記錄'}
							returnUrl={returnUrl}
						/>
					</GridColumn>
				</GridRow>
				<GridRow>
					<GridColumn>
						<SegmentGroup>
							<Segment>
								<Grid stackable>
									<GridRow columns={3}>
										<GridColumn>
											<Header size="small">{`${appraisalItem.yearName} : ${appraisalItem.displayName}`}</Header>
										</GridColumn>
										<GridColumn>
											<Header size="small">{`老師：${appraisalTarget.teacherName}`}</Header>
										</GridColumn>
										<GridColumn>
											<Header size="small">{`遞交期限：${momentToDate(appraisalTarget.deadline)}`}</Header>
										</GridColumn>
									</GridRow>
								</Grid>
							</Segment>
							<Segment compact secondary>
								<Form>{displayForm(appraisalContentTree)}</Form>
								<Grid>
									<Grid.Row>
										<Grid.Column textAlign="center">
											<Link to={returnUrl}>
												<Button
													className="button-margin-bottom"
													color="red"
													content="取消"
													icon="cancel"
													type="button"
													circular
												/>
											</Link>
											<Button color="green" content="儲存" icon="save" onClick={toggleSign} circular />
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						</SegmentGroup>
					</GridColumn>
				</GridRow>
			</Grid>
			<SignModal open={isSignModalOpen} close={() => setIsSignModalOpen(false)} save={save} />
		</React.Fragment>
	);
};

export default EditTable;
