/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
/* import lodash */
import {keyBy, flatten, find, groupBy, orderBy, pick} from 'lodash';


/* import components */
import {
	PageHeader,
	EmptyTableMsg,
  FlexibleTextField,
	FullscreenDimmer,
	MySelect,
  Buttons
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions, 
  threeYearPlanAndWhichYearFromYear,
  defaultYearForPlan,
  whetherReadOnlyForMidReport,
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';


/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, TextArea } from 'semantic-ui-react';

const RawStrategyTable = ({
  finishedLoading, readOnly, strategies, 
  aimId, groupId, groupActions,
  actionLookup, modalToggle,
}) => {
  let simpleTablePart;
  const colSpan = groupId ? 6 - (+!!readOnly) - (aimId<0) : 4;

  if(!finishedLoading) simpleTablePart = <EmptyTableMsg colSpan={colSpan} msg='載入資料中' />;
  else if(!groupId) simpleTablePart =  <EmptyTableMsg colSpan={colSpan} msg='請先選擇組別' />;
  else if(!strategies.length) simpleTablePart =  <EmptyTableMsg colSpan={colSpan} msg='沒有任何策略目標' />; //no aim no strategy
  else if(!aimId) simpleTablePart =  <EmptyTableMsg colSpan={colSpan} msg='請先選擇目標' />;
  else if(aimId>0 && !strategies.length) simpleTablePart = <EmptyTableMsg colSpan={colSpan} msg="未有周年策略" />;
  else if(aimId<0 && !(groupActions[-aimId]||[]).length) simpleTablePart = <EmptyTableMsg colSpan={colSpan} msg="未有自訂科組策略" />;

  return (<Table textAlign='center' celled unstackable fixed structured>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={aimId>0?5:8}>標題</Table.HeaderCell>
        {aimId>0 && <Table.HeaderCell width={3}>標題</Table.HeaderCell>}
        <Table.HeaderCell width={2}>時間表</Table.HeaderCell>
        <Table.HeaderCell width={2}>成就與反思</Table.HeaderCell>
        <Table.HeaderCell width={2}>建議</Table.HeaderCell>
        {!readOnly && <Table.HeaderCell width={2}>行動</Table.HeaderCell>}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {simpleTablePart || (aimId>0 ? strategies.map(s => {
        const action = actionLookup[s.id];
        if (!action) {
          return (
            <Table.Row key={"s" + s.id}>
              <Table.Cell textAlign="left">{s.title}</Table.Cell>
              <Table.Cell colSpan={colSpan-1} textAlign="center"><strong>未有<span className='red'>周年</span>計劃項目</strong></Table.Cell>
            </Table.Row>
          );
        }
        const groupAction = groupActions[action.id];
        if (!groupAction || !groupAction.length) {
          return (
            <Table.Row key={"a" + s.id}>
              <Table.Cell textAlign="left">
                {s.title}
              </Table.Cell>
              <Table.Cell colSpan={colSpan-1} textAlign="center"><strong>未有<span className='red'>組別</span>計劃項目</strong></Table.Cell>
            </Table.Row>
          )
        }

        if(groupAction.length===1 && groupAction[0].beginMonth+groupAction[0].endMonth === 0){
          return (
            <Table.Row key={"a" + s.id}>
              <Table.Cell disabled textAlign="left" className='line-through'>
                {s.title}
              </Table.Cell>
              <Table.Cell colSpan={colSpan-1} textAlign="center">
                <span className='red'>{groupAction[0].title||'不適用'}</span>
              </Table.Cell>
            </Table.Row>
          )
        }

        return groupAction.map((gA, j) => {

          return (<Table.Row key={"gA" + gA.id}>
            {!j && (
              <Table.Cell textAlign="left" rowSpan={groupAction.length}>
                {s.title}
              </Table.Cell>
            )}
            <Table.Cell className="textarea-text">
              {aimId<0&&gA.prefix}{gA.title}
            </Table.Cell>
            <Table.Cell className="textarea-text">{process.env.REACT_APP_FREE_TEXT_TIME_SUPPORT ? (gA.time || '-') : `${gA.beginMonth || '?'}月—${gA.endMonth || '?'}月`}</Table.Cell>
            <Table.Cell>
              {gA.end_reflection|| "-"}
            </Table.Cell>
            <Table.Cell className="textarea-text">{gA.end_suggestion || "-"}</Table.Cell>
            {!readOnly && (<Table.Cell key='actions'>
              <Buttons.EditButton
                data-modalname='isEditModalOpen'
                data-selected-id={gA.id}
                onClick={modalToggle}
              />
            </Table.Cell>)}
          </Table.Row>)
        })
      }): groupActions[-aimId].map((gA, j) => {

        return (<Table.Row key={"-gA" + gA.id}>
          <Table.Cell textAlign="left" className="textarea-text">
            {aimId<0&&gA.prefix}{gA.title}
          </Table.Cell>
          <Table.Cell className="textarea-text">{process.env.REACT_APP_FREE_TEXT_TIME_SUPPORT ? (gA.time || '-') : `${gA.beginMonth || '?'}月—${gA.endMonth || '?'}月`}</Table.Cell>
          <Table.Cell>
            {gA.end_reflection|| "-"}
          </Table.Cell>
          <Table.Cell className="textarea-text">{gA.end_suggestion || "-"}</Table.Cell>
          {!readOnly && (<Table.Cell key='actions'>
            <Buttons.EditButton
              data-modalname='isEditModalOpen'
              data-selected-id={gA.id}
              onClick={modalToggle}
            />
          </Table.Cell>)}
        </Table.Row>)
      }))}
    </Table.Body>
  </Table>)
};

const StrategyTable = React.memo(RawStrategyTable);

const RawBudgetTable = ({
  info, readOnly, budgetChange, fields, total, firstTotal
}) => {
  const secondTotal = info.reduce((prev,cur)=>+cur['下學期']+prev, 0);
  return (<Table textAlign='center' celled unstackable fixed>
    <Table.Header>
      <Table.Row>
        {fields.map(x=>(<Table.HeaderCell width={6} key={x}>{x}</Table.HeaderCell>))}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {info.length?info.map((item, i) => (
        <Table.Row key={i} className='textarea-text'>
          {fields.map((x,j)=>(
            <Table.Cell textAlign={readOnly?(j?"left":"right"):undefined} key={x}>
              <FlexibleTextField
                fluid
                type={j?"number":"text"}
                readOnly={readOnly}
                disabled={j!==3}
                ul={true}
                value={item[x]||item[x]===0?item[x]:''}
                data-i={i}
                data-input-type='text'
                data-which-field={x}
                onChange={budgetChange}
                rows={2}
              />
            </Table.Cell>
          ))}
        </Table.Row>
      )):<EmptyTableMsg colSpan={fields.length}/>}
    </Table.Body>
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell textAlign='right'>共計</Table.HeaderCell>
        <Table.HeaderCell textAlign='left'>{total}</Table.HeaderCell>
        <Table.HeaderCell textAlign='left'>{firstTotal}</Table.HeaderCell>
        <Table.HeaderCell textAlign='left'>{secondTotal}</Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  </Table>);
};

const BudgetTable = React.memo(RawBudgetTable);

class GroupMidReport extends Component {
  constructor(props) {
    super(props);

    this.default = {
      resourceNeeded:null,
      selectedTeachers:[],
      selectedActionGroups: [],
      selectedGroups: [],
      remark: '',
    }
    if(process.env.REACT_APP_FREE_TEXT_TIME_SUPPORT){
      this.default.time = '';
    }else{
      this.default.beginMonth = this.default.endMonth = null;
    }

    this.state = {

      year: [],
      yearInfo: [],
      yearId: '',
      
      finishedLoading: false,
      dimmerOpen: false,
      readOnly: true,

      TYPs: [],
      whichYear: null,

      strategies: [],
      actions: [],
      actionToStratMap: new Map(),

      groupOptions: [],
      groupId: null,
      editableGroups: new Set(),
      groupTypeMap: new Map(),

      aimOptions: [],
      aimId: '',

      budgetId: '',
      info: [],
      total: "NaN",
      firstTotal: "NaN",

      groupActions: {},

      theAction: this.default,

      isEditModalOpen: false,
      isEditBudgetModalOpen: false,
      selectedId: null,
    }

    this.actionLookup = {};
    this.fields = ['項目','預算金額', '上學期', '下學期'];
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    try{
      this.mounted = true;
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      if(err!=='unmounted') console.log("Error when mounting Group Summary", err);
    }
  }

  fetch = async () => {
    try {
      //get year info
      let [yearInfo, TYPs, groups] = await Promise.all([
        get('getYear'),
        get('getTYP'),
        get('getGroup'),
      ]);
      
      const yearId = defaultYearForPlan(yearInfo);
      const whichYear = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs)[1];

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId,

        TYPs,
        whichYear,

        groupOptions: selectOptions(groups, 'name', 'id'),
        groupId: this.state.groupId || '',
        groupTypeMap: new Map(groups.map(x=>[x.id, x.type])),
      });
      
      await this.fetchEditableGroups();
      
    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching group actions',err);
    }
  }

  isReadOnly = (editableGroups) => whetherReadOnlyForMidReport(this.state.yearInfo, this.state.yearId) || !editableGroups.has(this.state.groupId);

  fetchEditableGroups = async () => {
    try {
      const groupsCanEdit = await get('getGroupEditRights/' + this.state.yearId);

      const editableGroups = new Set(groupsCanEdit.map(x => x.id));

      await this.setStateAsync({
        editableGroups,
        readOnly: this.isReadOnly(editableGroups)
      });

      await this.fetchAims();

    } catch (err) {
      if(err!=='unmounted') console.log("Error when fetching editable group data for group summary", err);
    }
  }

  fetchAims = async () => {
    try{
      const {aimId, groupId, yearId, whichYear} = this.state;
      if(whichYear===null || !groupId){
        await this.setStateAsync({
          aimId: null,
          aimOptions: [],
        });
      }else{
        let aims = await get(`getAimsForGroup/${groupId}/${yearId}`);
  
        aims = orderBy(aims, ['text'], ['asc']);
        const needToChangeAim = !find(aims, {value: aimId});
  
        await this.setStateAsync({
          aimId: needToChangeAim ? (aims && aims.length ? aims[0].value : null) : aimId,
          aimOptions: aims,
        });
      }

      await this.fetchStrategies();

    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching TYP detail for group summary', err);
    }
  };

  fetchStrategies = async () => {
    try{
      const {aimId, yearId, groupId} = this.state;
      if(aimId>0){
        const aimPrefix = find(this.state.aimOptions,{value:aimId}).text.match(/^(自訂 )?((\d+\.){2})/)[2];
        let [strategies, actions] = await Promise.all([
          get(`get3YearStrategyByYear/${yearId}/${aimId}`),
          get(`getActionByYearAndAim/${yearId}/${aimId}`)
        ]);

        for(let s of strategies){
          s.title = aimPrefix+s.title;
        }
        
        // const stratHasAction = new Set();
        // actions = actions.filter(a=>{
        //   const gp = new Set(a.groups ? a.groups.split("|"): []);
        //   if(gp.has(String(groupId)) || gp.has(groupId+'各級主任') || gp.has(this.state.groupTypeMap.get(groupId))){
        //     stratHasAction.add(a['3yearstrategyId']);
        //     return true;
        //   }
        //   return false;
        // });
        // strategies = strategies.filter(s => +s[`year${+this.state.whichYear+1}`] && stratHasAction.has(s.id));
        const stratMap = new Map(strategies.map(x=>[x.id, x.title]));
  
        this.actionLookup = keyBy(actions, "3yearstrategyId");
  
        await this.setStateAsync({
          strategies,
          actions,
          actionToStratMap: new Map(actions.map(x=>[x.id, stratMap.get(x['3yearstrategyId'])])),
        });
      }else{
        this.actionLookup = {};
        await this.setStateAsync({
          strategies: [],
          actions: [],
          actionToStratMap: new Map(),
        });
      }
      await this.fetchGroupInfo();
    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching TYP detail for group summary', err);
    }
  }

  fetchGroupInfo = async () => {
    try{
      let {groupId,yearId,aimId} = this.state;
      if(!groupId){
        await this.setStateAsync({
          groupActions: {},
          budgetId: null,
          info: [],
          total: "NaN"
        });
      }else{
        if(aimId>0){
          const [groupActions] = await Promise.all([
            get(`getGroupActionByYearAndAim/${groupId}/${yearId}/${aimId}`),
          ]);
      
          await this.setStateAsync({
            groupActions: groupBy(groupActions, '1yearactionId'),
          });
        }else{
          const aimPrefix = find(this.state.aimOptions,{value:aimId}).text.match(/^(自訂 )?((\d+\.){2})/)[2];
          const [groupActions] = await Promise.all([
            get(`getCustomGroupAction/${Math.abs(aimId)}`),
          ]);
          
          groupActions.forEach((gA,i)=>{
            gA.prefix = `${aimPrefix}${i+1}. `;
          });
          await this.setStateAsync({
            groupActions: groupBy(groupActions, 'groupaimId'),
          });
        }
        
        const _info = await get(`getGroupBudget/${+yearId}/${+groupId}`);
        let info;
        if (_info.length) {
          try {
            info = typeof _info[0].info === 'string' ? JSON.parse(_info[0].info) : _info[0].info;
          } catch (err) { }
        }
        if (!Array.isArray(info)) {
          info = [];
        }
        await this.setStateAsync({
          budgetId: _info.length ? _info[0].id : undefined,
          info,
          total: this.calcTotal(info),
          firstTotal: this.calcTotal(info, '上學期'),
        });
    }

    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching group actions for group summary', err);
    }
  }

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId, yearInfo } = this.state;
      if (yearId !== value) {
        const whichYear = threeYearPlanAndWhichYearFromYear(yearInfo, this.state.TYPs, value)[1];
        await this.setStateAsync({
          finishedLoading: false,
        });
        await this.setStateAsync({
          yearId: value,
          whichYear,
        });

        await this.fetchEditableGroups();
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      if(err!=='unmounted') console.log("Error when changing year for Group summary", err);
    }
  }

  /* Aim Change */
  aimChange = async (event, { value }) => {
    try{
      const { seletedAim } = this.state;
      if (seletedAim !== value) {
        await this.setStateAsync({
          aimId: value,
          finishedLoading: false,
        });
        
        await this.fetchStrategies();
        this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      if(err!=='unmounted') console.log("Error when changing aim for Group summary", err);
    }
  }

  groupChange = async (event, { value }) => {
    try {
      const { groupId } = this.state;
      if (groupId !== value) {
        await this.setStateAsync({
          finishedLoading: false,
          groupId: value,
        });
      
        await this.setStateAsync({
          readOnly: this.isReadOnly(this.state.editableGroups)
        });
        
        await this.fetchAims();
        await this.setStateAsync({ finishedLoading: true });
      }
    } catch (err) {
      console.log("Error when changing group for Group Action");
      console.log(err);
    }
  }

  inputChange = (event, value) => {
    let inputData;
    //special case for remove icon in semantic-ui-react select
    if(event.target.tagName==='I'){
      inputData = event.target.closest('.ui.dropdown, textarea, button').dataset;
    }else{
      inputData = event.target.closest('.ui, textarea, button').dataset;
    }
    const {inputType, stateName} = inputData;
    this.setStateAsync({
      theAction: {
        ...this.state.theAction,
        [stateName]: inputHandler(inputType, value)
      }
    });
  }

  calcTotal = (info, field = '預算金額') => {
    let total = "ERR";
    if(!info) info = this.state.info;
    if(Array.isArray(info)){
      total = info.reduce((prev,cur)=>+cur[field]+prev,0);
    }
    return Number.isNaN(total)?"ERROR":(Math.round(total * 1e12) / 1e12);
  }

  budgetChange = async (event, data) => {
    const {i} = event.target.closest('.ui, button, textarea').dataset;
    const info = [...this.state.info];
    info[+i] = {
      ...info[+i],
      '上學期': +inputHandler('number', data)
    };
    const d = {
      info,
      isBlocking: true
    };
    try{await this.setStateAsync(d);}catch(err){}
  }

  /**
   * Toggle Modal
   * @param {object|string} eventOrStateName statename is used in calls in functions (turning off modals); event are used for elements
   */
  modalToggle = (eventOrStateName) => {
    try{
      if(typeof eventOrStateName === 'object'){
        let modalData = {};
        if(eventOrStateName.target.classList.contains('modals')){
          modalData = eventOrStateName.target.firstElementChild.dataset;
        }else{
          modalData = eventOrStateName.target.closest('.ui, button').dataset;
        }
        const {modalname} = modalData;
        const selectedId = +modalData.selectedId;

        const data = {
          [modalname]: !this.state[modalname],
          selectedId,
          theAction: this.default,
        }

        const ref = find(flatten(Object.values(this.state.groupActions)), { id: selectedId });

        if(ref){
          data.theAction = pick(ref, ['title', 'end_suggestion', 'end_reflection'].concat(process.env.REACT_APP_FREE_TEXT_TIME_SUPPORT?['time']:['beginMonth', 'endMonth']));
          data.theAction.selectedAction = ref['1yearactionId'];
        }
         this.setStateAsync(data);
      }else{
        this.setStateAsync({
          [eventOrStateName]: !this.state[eventOrStateName],
          selectedId: null,
          theAction: this.default
        });
      }

    }catch(err){
      if(err!=='unmounted') console.log("Error when modal-toggling for Group summary", err);
    }
  }

  /* save record */
  save = async () => {
    try{
      const data = {
        ...this.state.theAction,
        groupId: this.state.groupId,
        skipGroups: true
      };

      const {selectedId, aimId} = this.state;

      if(!selectedId && !data.selectedAction && !data.groupaimId){
        alert("這不應該發生的");
        return;
      }

      if(selectedId){
        data.id = selectedId;
      }
      if(data.selectedAction){
        data["1yearactionId"] = data.selectedAction;
      }
      if(aimId<0){
        data.groupaimId = -aimId;
      }
      delete data.selectedAction;
      
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });

      const actionResult = await post('editGroupAction', data);
      
      if(actionResult&&actionResult.status){
        await this.modalToggle('isEditModalOpen');
        await this.fetchGroupInfo();
      }else{
        throw actionResult;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存組別中期報告時發生錯誤"+(typeof err==='string'?":"+err:''));
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  saveBudget = async () => {
    const data = pick(this.state, ['info', 'yearId', 'groupId']);
    data.id = this.state.budgetId;
    try{
      await this.setStateAsync({
        dimmerOpen: true,
        finishedLoading: false,
      });
      const result = await post('editGroupBudget', data);
      if(result && result.status){
        await this.setStateAsync({
          isBlocking: false,
        })
        await this.fetch();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存組別預算時發生錯誤");
        console.log(err);
      }
    }
    try{await this.modalToggle('isEditBudgetModalOpen');}catch(err){}
    this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true,
    });
  }


  render() {
    const {
      finishedLoading,
      dimmerOpen,

      year,
      yearId,
      readOnly,

      aimOptions,
      aimId,

      strategies,
      actionToStratMap,
      groupActions,

      groupOptions,
      groupId,

      editableGroups,
      info,
      total,
      firstTotal,

      isEditModalOpen,
      isEditBudgetModalOpen,
      theAction,
    } = this.state;

    return (<>
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='科組總結 - 成就、反思及建議' subTitle='為每個科組策略項目設定成就、反思及建議，總結關注事項' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Group>
                  <Form.Field inline className='vertical-center-field'>
                    <label>周年</label>
                    <MySelect
                      compact
                      disabled={!finishedLoading}
                      options={year}
                      value={yearId}
                      onChange={this.yearChange}
                    />
                  </Form.Field>
                  <Form.Field inline className='vertical-center-field'>
                    <label>組別</label>
                    <MySelect
                      compact
                      error={!groupId}
                      disabled={!finishedLoading}
                      options={groupOptions}
                      value={groupId}
                      search
                      onChange={this.groupChange}
                      placeholder="請先選擇組別"
                    />
                  </Form.Field>
                  <Form.Field inline className='vertical-center-field'>
                    <label>目標</label>
                    <MySelect
                      disabled={!finishedLoading||!aimOptions.length}
                      options={aimOptions}
                      value={aimId}
                      search
                      onChange={this.aimChange}
                      placeholder={aimOptions.length?"請選擇":"未有目標"}
                      error={!aimOptions.length}
                    />
                  </Form.Field>
                  {editableGroups.has(groupId) && (<Button
                    data-modalname='isEditBudgetModalOpen'
                    color='blue'
                    icon={readOnly?'eye':'edit'}
                    content={(readOnly?'閱覽':'編輯')+'財政預算'}
                    onClick={this.modalToggle}
                    circular
                    floated='right'
                  />)}
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StrategyTable
              actionLookup={this.actionLookup}
              modalToggle={this.modalToggle}
              finishedLoading={finishedLoading}
              readOnly={readOnly}
              aimId={aimId}
              groupId={groupId}
              strategies={strategies}
              groupActions={groupActions}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {dimmerOpen?<FullscreenDimmer active={true} isLoading={true}/>:(
        <>
          <Modal
            open={isEditModalOpen}
            data-modalname='isEditModalOpen'
            onClose={this.modalToggle}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>設定組別年度策略項目</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <Form>
                  <Form.Group className='form-group-margin'>
                    <Form.Field required width={16}>
                      <label>標題</label>
                      {theAction.title||'-'}
                      {aimId>0 && <p>(對應周年策略項目: {actionToStratMap.has(theAction.selectedAction)?actionToStratMap.get(theAction.selectedAction):'-'})</p>}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group className='form-group-margin'>
                    <Form.Field width={8}>
                      <label>成就與反思</label>
                      <TextArea
                        value={theAction.end_reflection || ''}
                        onChange={this.inputChange}
                        data-input-type='text'
                        data-state-name='end_reflection'
                        placeholder='成就與反思'
                        rows={3}
                      />
                    </Form.Field>
                    <Form.Field width={8}>
                      <label>建議</label>
                      <TextArea
                        value={theAction.end_suggestion||''}
                        data-input-type='text'
                        data-state-name='end_suggestion'
                        onChange={this.inputChange}
                        rows={3}
                        placeholder="建議"
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Buttons.CancelButton
                data-modalname='isEditModalOpen'
                onClick={this.modalToggle}
              />
              {!readOnly && <Buttons.SaveButton onClick={this.save} />}
            </Modal.Actions>
          </Modal>
          <Modal
            open={isEditBudgetModalOpen}
            data-modalname='isEditBudgetModalOpen'
            onClose={this.modalToggle}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>編輯財政預算</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <BudgetTable
                  info={info}
                  total={total}
                  firstTotal={firstTotal}
                  readOnly={readOnly}
                  budgetChange={this.budgetChange}
                  fields={this.fields}
                />
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Buttons.CancelButton
                data-modalname='isEditBudgetModalOpen'
                onClick={this.modalToggle}
              />
              {!readOnly && !!info.length && <Buttons.SaveButton onClick={this.saveBudget} />}
            </Modal.Actions>
          </Modal>
        </>
      )}
    </>)
  }
}

export default withRouter(GroupMidReport);