/* import react */
import React, { memo } from 'react';
import {Button} from 'semantic-ui-react';

const CancelButton = memo(props => <Button
  content={props.concise ? undefined : '取消'}
  color='red'
  icon='cancel'
  circular
  title={props.title ? props.title : "取消"}
  type='button'
  {...props}
/>);

const SaveButton = memo(props => <Button
  content={props.concise ? undefined : '儲存'}
  color='green'
  icon='save'
  circular
  title={props.title ? props.title : "儲存"}
  type='button'
  {...props}
/>);

const EditButton = memo(props => <Button
  content={props.long ? '編輯' : undefined}
  color='blue'
  icon='edit'
  circular
  title={props.title ? props.title : "編輯"}
  type='button'
  {...props}
/>);

const ViewButton = memo(props => <Button
  content={props.long ? '檢視' : undefined}
  color='blue'
  icon='eye'
  circular
  title={props.title ? props.title : "檢視"}
  type='button'
  {...props}
/>);

const DeleteButton = memo(props => <Button
  content={props.long ? '刪除' : undefined}
  color='red'
  icon='delete'
  circular
  title={props.title ? props.title : "刪除"}
  type='button'
  {...props}
/>);

const AddButton = memo(props => <Button
  content={props.long ? '新增' : undefined}
  color='green'
  icon='add'
  circular
  title={props.content ? props.content : "新增"}
  type='button'
  {...props}
/>);

const UploadButton = memo(props => <Button
  content={props.concise ? undefined : props.content || '上載'}
  color={props.color||'blue'}
  icon='upload'
  circular
  title={props.content ? props.content : "上載"}
  type='button'
  {...props}
/>);
const DownloadButton = memo(props => <Button
  content={props.concise ? undefined : props.content || '下載'}
  color={props.color||'blue'}
  icon='download'
  circular
  title={props.content ? props.content : "下載"}
  type='button'
  {...props}
/>);

export default {
  CancelButton,
  SaveButton,
  EditButton,
  ViewButton,
  DeleteButton,
  AddButton,
  UploadButton,
  DownloadButton
}