import React, { forwardRef, Fragment, useImperativeHandle, useRef, useState } from 'react';
import FullscreenDimmer from '../../../../Components/FullscreenDimmer';
import { Button, Checkbox, Divider, Form, Grid, Header, Modal, Segment, TextArea } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import ErrorLabel from '../../../../Components/ErrorLabel';
import { inputHandler } from '../../../../Helper/FormHelper';
import { useParams } from 'react-router-dom';
import { post } from '../../../../Helper/ApiHelper';

const sectionOptions = {
	before: '評估前設定',
	self: '被評估後反思',
	other: '他人評估',
};

const inputTypeOptions = [
	{ text: '文字', value: 'text' },
	{ text: '文字選擇', value: 'textSelect' },
	{ text: '日期', value: 'date' },
	{ text: '科目', value: 'subject' },
	{ text: '分數', value: 'score' },
];

const FormEditModal = forwardRef(({ fetch }, ref) => {
	const [, forceUpdate] = useState();
	const validator = useRef(
		new SimpleReactValidator({
			autoForceUpdate: { forceUpdate: () => forceUpdate(1) },
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				default: '請輸入資料',
			},
		})
	);

	const { appraisalItemId } = useParams();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [details, setDetails] = useState({});
	const [textSelectOptions, setTextSelectOptions] = useState([]);

	const {
		section,
		description,
		notes,
		needInput,
		inputType,
		multiple,
		min,
		max,
		step,
		notApplicable,
		count,
		avgCount,
		percentage,
		allowSetApplicable,
	} = details || {};

	useImperativeHandle(ref, () => ({
		open: (details, textSelectOptions) => {
			setDetails(details);
			setTextSelectOptions(textSelectOptions);
			setOpen(true);
		},
	}));

	const contentChange = (data, inputType, stateName) => {
		setDetails((details) => ({
			...details,
			[stateName]: inputHandler(inputType, data),
		}));
	};

	const save = async () => {
		if (!validator.current.allValid()) {
			validator.current.showMessages();
			return;
		}

		const {
			needInput,
			inputType,
			multiple,
			min,
			max,
			step,
			notApplicable,
			count,
			avgCount,
			percentage,
			allowSetApplicable,
		} = details;

		const isCount = !needInput || (needInput && inputType === 'score') ? count : false;
		const data = {
			...details,
			appraisalItemId,
			inputType: needInput ? inputType : null,
			textSelectOptions: needInput && inputType === 'textSelect' ? textSelectOptions : null,
			multiple: needInput && inputType === 'textSelect' ? multiple : false,
			min: needInput && inputType === 'score' ? Number(min) : 0,
			max: needInput && inputType === 'score' ? Number(max) : 0,
			step: needInput && inputType === 'score' ? Number(step) : 0,
			notApplicable: needInput && inputType === 'score' ? notApplicable : false,
			count: isCount,
			avgCount: isCount ? avgCount : null,
			percentage: count && !avgCount ? Number(percentage) : null,
			allowSetApplicable,
		};

		setLoading(true);
		const result = await post('editAppraisalContent', data);
		if (result.status) {
			validator.current.hideMessages();
			await fetch();
			setLoading(false);
			setOpen(false);
		}
	};

	const addTextSelectOption = () => {
		setTextSelectOptions((textSelectOptions) => [...textSelectOptions, { displayName: '' }]);
	};

	const changeTextSelectOption = (index, data) => {
		const value = inputHandler('text', data);
		setTextSelectOptions((textSelectOptions) => [
			...textSelectOptions.slice(0, index),
			{
				...textSelectOptions[index],
				displayName: value,
			},
			...textSelectOptions.slice(index + 1),
		]);
	};

	const deleteTextSelectOption = (index) => {
		setTextSelectOptions((textSelectOptions) => [
			...textSelectOptions.slice(0, index),
			...textSelectOptions.slice(index + 1),
		]);
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Modal open={open} onClose={() => setOpen(false)} closeOnEscape={false} closeOnDimmerClick={false}>
				<Modal.Header>編輯項目: {sectionOptions[section]}</Modal.Header>
				<Modal.Content>
					<Segment basic>
						<Form>
							<Header color="blue" as="h2" content="基本資料" />
							<Form.Group grouped>
								<Form.Input
									className="form-group-margin"
									onChange={(event, data) => contentChange(data, 'text', 'description')}
									value={description}
									type="text"
									label="描述"
									placeholder="描述"
									error={validator.current.message('description', description, 'required')}
								/>
							</Form.Group>

							<Form.Group grouped>
								<Form.Input
									className="form-group-margin"
									control={TextArea}
									onChange={(event, data) => contentChange(data, 'text', 'notes')}
									value={notes || ''}
									type="text"
									label="備註"
									placeholder="備註"
								/>
							</Form.Group>

							<Divider />

							<Form.Group className="form-group-margin" inline>
								<label>
									<Header color="blue" as="h2" content="需要輸入" />
								</label>
								<Form.Field
									onChange={(event, data) => contentChange(data, 'toggle', 'needInput')}
									checked={Boolean(needInput)}
									control={Checkbox}
									toggle
								/>
							</Form.Group>

							{Boolean(needInput) && (
								<React.Fragment>
									{/* input type */}
									<Form.Group className="form-group-margin" grouped>
										<Form.Select
											onChange={(event, data) => contentChange(data, 'select', 'inputType')}
											value={inputType}
											options={inputTypeOptions}
											label="輸入類別"
											placeholder="輸入類別"
											error={validator.current.message('inputType', inputType, ['required'])}
										/>
									</Form.Group>

									{inputType === 'textSelect' && (
										<React.Fragment>
											<Form.Group className="form-group-margin">
												<Form.Field
													onChange={(event, data) => contentChange(data, 'toggle', 'multiple')}
													checked={Boolean(multiple)}
													control={Checkbox}
												/>
												<label>可多選</label>
											</Form.Group>

											{textSelectOptions.length > 0 && (
												<Grid className="form-group-margin">
													<Grid.Row verticalAlign="bottom">
														{textSelectOptions.map(({ displayName }, index) => {
															return (
																<Grid.Column key={index} computer={8} mobile={16}>
																	<Form.Group style={{ alignItems: 'flex-end' }} unstackable>
																		<Form.Input
																			value={displayName}
																			onChange={(event, data) => changeTextSelectOption(index, data)}
																			type="text"
																			label={index === 0 ? '文字選擇' : ''}
																			placeholder={'文字選擇' + (index + 1)}
																			width="13"
																			error={validator.current.message(
																				'textSelectOption_' + index,
																				textSelectOptions[index].displayName,
																				'required'
																			)}
																		/>
																		<Form.Button
																			onClick={() => deleteTextSelectOption(index)}
																			circular
																			basic
																			color="red"
																			icon="delete"
																			width="3"
																		/>
																	</Form.Group>
																</Grid.Column>
															);
														})}
													</Grid.Row>
												</Grid>
											)}
											<Form.Group grouped className="text-center">
												<Button
													onClick={() => addTextSelectOption()}
													color="green"
													type="button"
													icon="add"
													content="新增文字選擇"
													circular
												/>
												<div>
													{validator.current.message('textSelectOptions', textSelectOptions, 'required|min:1,array')}
												</div>
											</Form.Group>
										</React.Fragment>
									)}

									{inputType === 'score' && (
										<React.Fragment>
											{/* mark type */}
											<Form.Group className="form-group-margin" widths="equal">
												{/* min */}
												<Form.Input
													onChange={(event, data) => contentChange(data, 'number', 'min')}
													value={min}
													type="number"
													step="0.5"
													label="最小"
													placeholder="最小"
												/>
												{validator.current.message('min', min, 'required')}

												{/* max */}
												<Form.Input
													onChange={(event, data) => contentChange(data, 'number', 'max')}
													value={max}
													type="number"
													step="0.5"
													label="最大"
													placeholder="最大"
												/>
												{validator.current.message('max', max, 'required')}

												{/* step */}
												<Form.Input
													onChange={(event, data) => contentChange(data, 'number', 'step')}
													value={step}
													type="number"
													step="0.5"
													label="每級相隔"
													placeholder="每級相隔"
												/>
												{validator.current.message('step', step, 'required')}
											</Form.Group>
											<Form.Group className="form-group-margin">
												{/* not applicable */}
												<Form.Field
													onChange={(event, data) => contentChange(data, 'toggle', 'notApplicable')}
													checked={Boolean(notApplicable)}
													control={Checkbox}
												/>
												<label>可以填寫「不適用」</label>
											</Form.Group>
										</React.Fragment>
									)}
								</React.Fragment>
							)}

							{(!Boolean(needInput) || (Boolean(needInput) && inputType === 'score')) && (
								<React.Fragment>
									<Divider />

									<Form.Group className="form-group-margin" inline>
										<label>
											<Header color="blue" as="h2" content="需計算比例" />
										</label>
										<Form.Field
											onChange={(event, data) => contentChange(data, 'toggle', 'count')}
											checked={Boolean(count)}
											control={Checkbox}
											toggle
										/>
									</Form.Group>

									{Boolean(count) && (
										<Fragment>
											{/* Average count */}
											<Form.Group className="form-group-margin" inline>
												<Form.Field
													onChange={(event, data) => contentChange(data, 'checkbox', 'avgCount')}
													checked={Boolean(avgCount)}
													control={Checkbox}
													label="平圴計算同層比例"
												/>
											</Form.Group>

											{!Boolean(avgCount) && (
												<Fragment>
													{/* percentage */}
													<Form.Group className="form-group-margin" grouped>
														<Form.Input
															onChange={(event, data) => contentChange(data, 'number', 'percentage')}
															value={percentage}
															type="number"
															label="佔同層比例(%)"
															placeholder="佔同層比例(%)"
														/>
														{validator.current.message('percentage', percentage, 'required')}
													</Form.Group>
												</Fragment>
											)}
										</Fragment>
									)}
								</React.Fragment>
							)}

							<Divider />
							<Form.Group className="form-group-margin" inline>
								<label>
									<Header color="blue" as="h2" content="可在設定前選擇是否適用" />
								</label>
								<Form.Field
									onChange={(event, data) => contentChange(data, 'toggle', 'allowSetApplicable')}
									checked={Boolean(allowSetApplicable)}
									control={Checkbox}
									toggle
								/>
							</Form.Group>
						</Form>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" content="取消" icon="cancel" onClick={() => setOpen(false)} circular />
					<Button color="green" content="儲存" icon="save" onClick={save} circular />
				</Modal.Actions>
			</Modal>
		</React.Fragment>
	);
});

export default FormEditModal;
