import AttachmentList from './AttachmentList';
import FinancialReport from './FinancialReport';
import L_and_T from './L_and_T';
import StudentPerformance from './StudentPerformance';
import StudentSupport from './StudentSupport';

export default {
  AttachmentList,
  FinancialReport,
  L_and_T,
  StudentPerformance,
  StudentSupport,
}