/* import lodash */
import {keyBy, flatten, groupBy, find, orderBy} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	EmptyTableMsg,
  MySelect,
  AggregateNumber,
} from '../../../Components';

/* import helper functions */
import { get } from '../../../Helper/ApiHelper';
import {
  selectOptions, 
  threeYearPlanAndWhichYearFromYear,
  defaultYear,
  numRound
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

/* import semantic-ui element */
import { Grid, Form, Table, Segment } from 'semantic-ui-react';

const RawStrategyTable = ({
  finishedLoading, strategies, 
  aimId, groupId, groupActions, actionTeachers, teacherMap,
  // actionGroupsInCharge, actionGroupMap,
  // groupMap,
  actionLookup,
  aggregateInfo
}) => {
  let simpleTablePart;
  const colSpan = 6;

  if(!finishedLoading) simpleTablePart = <EmptyTableMsg colSpan={colSpan} msg='載入資料中' />;
  else if(!groupId) simpleTablePart =  <EmptyTableMsg colSpan={colSpan} msg='請先選擇組別' />;
  else if(!strategies.length) simpleTablePart =  <EmptyTableMsg colSpan={colSpan} msg='沒有任何策略目標' />; //if no aim
  else if(!aimId) simpleTablePart =  <EmptyTableMsg colSpan={colSpan} msg='請先選擇目標' />;
  else if(aimId>0 && !strategies.length) simpleTablePart = <EmptyTableMsg colSpan={colSpan} msg="未有周年策略" />;

  return (<Table textAlign='center' celled unstackable fixed structured>
    <Table.Header>
      <Table.Row>
      <Table.HeaderCell width={aimId>0?5:8}>標題</Table.HeaderCell>
      {aimId>0 && <Table.HeaderCell width={3}>標題</Table.HeaderCell>}
        <Table.HeaderCell width={2}>時間表</Table.HeaderCell>
        <Table.HeaderCell width={2}>負責人</Table.HeaderCell>
        <Table.HeaderCell width={2}>所需資源</Table.HeaderCell>
        <Table.HeaderCell width={2}>目前結果</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {simpleTablePart || strategies.map(s => {
        const action = actionLookup[s.id];
        if (!action) {
          return (
            <Table.Row key={"s" + s.id}>
              <Table.Cell textAlign="left">{s.title}</Table.Cell>
              <Table.Cell colSpan={colSpan-1} textAlign="center"><strong>未有<span className='red'>周年</span>計劃項目</strong></Table.Cell>
            </Table.Row>
          );
        }
        const groupAction = groupActions[action.id];
        if (!groupAction || !groupAction.length) {
          return (
            <Table.Row key={"a" + s.id}>
              <Table.Cell textAlign="left">
                {s.title}
              </Table.Cell>
              <Table.Cell colSpan={colSpan-1} textAlign="center"><strong>未有<span className='red'>科組</span>計劃項目</strong></Table.Cell>
            </Table.Row>
          )
        }

        if(groupAction.length===1 && groupAction[0].beginMonth+groupAction[0].endMonth === 0){
          return (
            <Table.Row key={"a" + s.id}>
              <Table.Cell disabled textAlign="left" className='line-through'>
                {s.title}
              </Table.Cell>
              <Table.Cell colSpan={colSpan-1} textAlign="center">
                <span className='red'>{groupAction[0].title||'不適用'}</span>
              </Table.Cell>
            </Table.Row>
          )
        }

        return groupAction.map((gA, j) => {
          const aggregate = aggregateInfo[gA.id] ? numRound(aggregateInfo[gA.id].x, 2) : undefined;
          const inCharge = [];

          for (let teacher of actionTeachers[gA.id] || []) {
            inCharge.push(teacherMap.get(teacher.teacherId));
          }

          return (<Table.Row key={"gA" + gA.id}>
            {!j && (
              <Table.Cell textAlign="left" rowSpan={groupAction.length}>
                {s.title}
              </Table.Cell>)}
            <Table.Cell className="textarea-text">
              {gA.title}
            </Table.Cell>
            <Table.Cell className="textarea-text">{process.env.REACT_APP_FREE_TEXT_TIME_SUPPORT ? (gA.time || '-') : `${gA.beginMonth || '?'}月—${gA.endMonth || '?'}月`}</Table.Cell>
            <Table.Cell>
              {inCharge.length ? inCharge.join("、") : "-"}
            </Table.Cell>
            <Table.Cell className="textarea-text">{gA.resourceNeeded || "-"}</Table.Cell>
            <Table.Cell key='actions'>
              <AggregateNumber value={aggregate}/>
            </Table.Cell>
          </Table.Row>)
        })
      })}
    </Table.Body>
  </Table>)
}

const StrategyTable = React.memo(RawStrategyTable);

class GroupStrategy extends Component {
  constructor(props) {
    super(props);

    this.state = {

      year: [],
      yearInfo: [],
      yearId: '',
      
      finishedLoading: false,
      TYPs: [],
      whichYear: null,

      strategies: [],
      actions: [],
      groupOptions: [],
      groupId: null,
      editableGroups: new Set(),
      aimOptions: [],
      aimId: '',
      teacherMap: new Map(),

      groupActions: {},
      actionTeachers: {},
    }
    this.actionLookup = {};
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    try{
      this.mounted = true;
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      if(err!=='unmounted') console.log("Error when mounting Group Result", err);
    }
  }

  fetch = async () => {
    try {
      //get year info
      const [yearInfo, TYPs, groups, teachers] = await Promise.all([
        get('getYear'),
        get('getTYP'),
        get('getGroup'),
        get('getAllTeacherWithAdminInSchool'),
      ]);
      
      const yearId = this.state.yearId ||defaultYear(yearInfo);
      const whichYear = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs)[1];

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId,

        TYPs,
        whichYear,

        teacherMap: new Map(teachers.map(x=>[x.id, x.shortName || x.name || '?'])),

        groupOptions: selectOptions(groups, 'name', 'id'),
        groupId: this.state.groupId || '',
      });

      await this.fetchAims();
      
    } catch (err) {
      if(err!=='unmounted') console.log("Error when fetching Group Result", err);
    }
  }

  fetchAims = async () => {
    try{
      const {groupId, yearId, whichYear} = this.state;
      if(whichYear===null/* || !groupId*/){
        await this.setStateAsync({
          aimId: null,
          aimOptions: [],
          // actionGroupMap: new Map(),
          // actionGroups: [],
        });
      }else{
        let [ aims, /*actionGroups*/ ] = await Promise.all([
          get(`getAimsForGroup/${groupId||0}/${yearId}`),
          //get(`getActionGroupByYear/${yearId}`),
        ]);
  
        aims = orderBy(aims, ['text'], ['asc']);
        const needToChangeAim = !find(aims, {value: this.state.aimId});
  
        let aimId = needToChangeAim ? (aims && aims.length ? aims[0].value : null) : this.state.aimId;
  
        await this.setStateAsync({
          aimId,
          aimOptions: aims,
          
          // actionGroupMap: new Map(actionGroups.map(x=>[x.id, x.name])),
          // actionGroups: selectOptions(actionGroups, 'name', 'id'),
        });
      }
      await this.fetchStrategies();

    }catch(err){
      if(err!=='unmounted') console.log("Error when fetching TYP for Group Result", err);
    }
  };

  fetchStrategies = async () => {
    try{
      const {aimId, yearId, groupId} = this.state;

      if(!aimId){
        await this.setStateAsync({
          strategies: [],
          actions: [],
        });
        this.actionLookup = {};
      }else{
        const aimPrefix = find(this.state.aimOptions,{value:aimId}).text.match(/^(自訂 )?((\d+\.){2})/)[2];
        if(aimId>0){ // always
          let [strategies, actions] = await Promise.all([
            get(`get3YearStrategyByYear/${yearId}/${aimId}`),
            get(`getActionByYearAndAim/${yearId}/${aimId}`)
          ]);
    
          for(let s of strategies){
            s.title = aimPrefix+s.title;
          }
  
          // const stratHasAction = new Set();
          // actions = actions.filter(a=>{
          //   const gp = new Set(a.groups ? a.groups.split("|"): []);
          //   if(gp.has(String(groupId)) || gp.has(groupId+'各級主任') || gp.has(this.state.groupTypeMap.get(groupId))){
          //     stratHasAction.add(a['3yearstrategyId']);
          //     return true;
          //   }
          //   return false;
          // });
          // strategies = strategies.filter(s => +s[`year${+this.state.whichYear+1}`] && stratHasAction.has(s.id));
    
          this.actionLookup = keyBy(actions, "3yearstrategyId");
    
          await this.setStateAsync({
            strategies,
            actions,
          });
        }
      }
      await this.fetchGroupInfo();
    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching TYP detail for group strategies', err);
    }
  }

  fetchGroupInfo = async () => {
    try{
      if(this.state.yearId && this.state.groupId){
        const rawGroupActions = await Promise.all(this.state.actions.map(yA => get(`getGroupAction/${+this.state.groupId}/${+yA.id}`)));

        const groupActions = {};
  
        this.state.actions.forEach((a,i)=>{
          groupActions[a.id] = rawGroupActions[i];
        });
        
        this.groupActionFlattened = flatten(rawGroupActions);
    
        await this.setStateAsync({groupActions});
    
        const rawActionTeachers = await Promise.all(this.groupActionFlattened.map(gA => get(`getGroupActionTeacher/${+gA.id}`)));
    
        await this.setStateAsync({
          actionTeachers: groupBy(flatten(rawActionTeachers), 'groupActionId')
        });
        const aggregateInfo = await get(`getGroupSurveyResultAggregate/${this.state.yearId}/${this.state.groupId}`);
  
        await this.setStateAsync({ aggregateInfo: keyBy(aggregateInfo, 'Aid') });
      }else{
        await this.setStateAsync({
          groupActions: [],
          actionTeachers: [],
          aggregateInfo: {}
        })
      }

    }catch(err){
      if(err!=='unmounted') console.log("Error when fetching Group Info for Result", err);
    }
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value });
  }

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId } = this.state;
      if (yearId !== value) {
        await this.setStateAsync({
          yearId: value,
          finishedLoading: false,
        });

        await this.fetchAims();
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      if(err!=='unmounted') console.log("Error when changing year for Group Result", err);
    }
  }

  /* Aim Change */
  aimChange = async (event, { value }) => {
    try{
      const { seletedAim } = this.state;
      if (seletedAim !== value) {
        await this.setStateAsync({
          aimId: value,
          finishedLoading: false,
        });
        
        await this.fetchStrategies();
        this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      if(err!=='unmounted') console.log("Error when changing aim for Group Result", err);
    }
  }

  groupChange = async (event, { value }) => {
    try {
      const { groupId } = this.state;
      if (groupId !== value) {
        await this.setStateAsync({
          finishedLoading: false,
          groupId: value,
        });
        
        await this.fetchGroupInfo();
        await this.setStateAsync({ finishedLoading: true });
      }
    } catch (err) {
      if(err!=='unmounted') console.log("Error when changing group for Group Result", err);
    }
  }

  render() {
    const {
      finishedLoading,

      year,
      yearId,

      aimOptions,
      aimId,

      strategies,

      groupActions,

      actionTeachers,

      teacherMap,
      groupOptions,
      groupId,
      aggregateInfo
    } = this.state;

    return (
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='科組計劃 - 策略項目問卷結果' subTitle='查看每個科組策略項目的平均問卷結果' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Group>
                  <Form.Field inline className='vertical-center-field'>
                    <label>周年</label>
                    <MySelect
                      compact
                      disabled={!finishedLoading}
                      options={year}
                      value={yearId}
                      onChange={this.yearChange}
                    />
                  </Form.Field>
                  <Form.Field inline className='vertical-center-field'>
                    <label>目標</label>
                    <MySelect
                      compact
                      disabled={!finishedLoading||!aimOptions.length}
                      options={aimOptions}
                      value={aimId}
                      search
                      onChange={this.aimChange}
                      placeholder={aimOptions.length?"請選擇":"沒有三年計劃目標"}
                      error={!aimOptions.length}
                    />
                  </Form.Field>
                  <Form.Field inline className='vertical-center-field'>
                    <label>科組</label>
                    <MySelect
                      error={!groupId}
                      disabled={!finishedLoading}
                      options={groupOptions}
                      value={groupId}
                      search
                      onChange={this.groupChange}
                      placeholder="請先選擇科組"
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StrategyTable
              finishedLoading={finishedLoading}
              aimId={aimId}
              groupId={groupId}
              strategies={strategies}
              groupActions={groupActions}
              actionTeachers={actionTeachers}
              teacherMap={teacherMap}
              aggregateInfo={aggregateInfo}
              actionLookup={this.actionLookup}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(GroupStrategy);