//import Concerns from './Concerns';
import Strategies from './Strategies';
import Evaluations from './Evaluations';
import ReviewAndSWOP from './ReviewAndSWOP';

export default {
  //Concerns,
  Evaluations,
  ReviewAndSWOP,
  Strategies
}