import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { get as _get } from 'lodash';
import { get } from '../../Helper/ApiHelper';
import { momentToDate, dateToMoment } from '../../Helper/TimeHelper';
import { EmptySegmentMsg } from '../../Components';

import { Grid, Header, Table, Segment } from 'semantic-ui-react';
import { User, Media } from '../../Context';

const creator = { width: '6rem' }
const dueDate = { width: '6rem' }

const SplitChildrenToColumns = ({children, columns}) => {
  const chunkedTasks = Array.from({length: columns}, (v,k)=> children.slice(children.length/columns*k, children.length/columns*(k+1)));
  return <Grid columns={columns} padded='vertically' centered>
    {chunkedTasks.map((x,i)=><Grid.Column key={i}>
      {x}
    </Grid.Column>)}
  </Grid>
}

const ResponsiveGrid = ({children}) => (
  <>
    <Media.Media at="mobile">{(x, needRender) => needRender && <SplitChildrenToColumns columns={1} >{children}</SplitChildrenToColumns>}</Media.Media>
    <Media.Media at="tablet">{(x, needRender) => needRender && <SplitChildrenToColumns columns={2} >{children}</SplitChildrenToColumns>}</Media.Media>
    <Media.Media at="computer">{(x, needRender) => needRender && <SplitChildrenToColumns columns={2} >{children}</SplitChildrenToColumns>}</Media.Media>
    <Media.Media at="largeScreen">{(x, needRender) => needRender && <SplitChildrenToColumns columns={3} >{children}</SplitChildrenToColumns>}</Media.Media>
    <Media.Media at="widescreen">{(x, needRender) => needRender && <SplitChildrenToColumns columns={3} >{children}</SplitChildrenToColumns>}</Media.Media>
  </>
)

const getClassName = (date) => {
  const dMoment = dateToMoment(date), now = dateToMoment();
  if (dMoment.isBefore(now)) {
    return 'inverted red cursor';
  }
  if (dMoment.diff(now, "days") < 7) {
    return 'red cursor';
  }
  if (dMoment.diff(now, "month") < 1) {
    return 'orange cursor'
  }
  return 'cursor';
}

const Panel = () => {
  const [tasks, setTasks] = useState({});
  const history = useHistory();
  const { menus } = useContext(User);

  useEffect(() => {
    const abortController = new AbortController();
    if (menus.some(m => m.name === 'sen')) {
      get('dashboard/get/SEN', [], abortController).then(({ needFollow, hasActivity }) => {
        const tasks = [];
        if (needFollow) {
          tasks.push({ title: <>學校尚有<span className='red'>{needFollow}</span>個SEN學生的記錄需完成</>, creator: null, dueDate: null, link: '/sen/consent' })
        }
        if (hasActivity === false) {
          tasks.push({ title: <>學校需設置<span className='red'>SEN支援小組</span></>, creator: null, dueDate: null, link: '/sen/group' })
        }

        setTasks(t => ({ ...t, '特殊教育': tasks }))
      }).catch(err => {
        if (_get(err, 'name') !== 'AbortError') {
          console.log("error detail", err);
        }
      })
    }
    if (menus.some(m => m.name === 'task')) {
      get('dashboard/get/task', [], abortController).then(tasks => {
        setTasks(t => ({ ...t, '檔案提交': tasks.map(t => ({ ...t, link: `/task/submit/${t.id}` })) }))
      })
    }
    if (menus.some(m => m.name === 'talent')) {
      get('dashboard/get/talent', [], abortController).then(({ activityTalent, talentStudents }) => {
        const tasks = [];
        if (activityTalent) {
          tasks.push({ title: <>尚有<span className='red'>{activityTalent}</span>個課外活動需設定人才庫資料</>, creator: null, dueDate: null, link: '/talent/activity' })
        }
        if (!talentStudents) {
          tasks.push({ title: <>需要複製<span className='red'>去年人才資料</span></>, creator: null, dueDate: null, link: '/talent/list' })
        }
        setTasks(t => ({ ...t, '人才庫': tasks }))
      })
    }
    if (menus.some(m => m.name === 'setting')) {
      get('dashboard/get/setting', [], abortController).then(({ hasYear, hasStudents, hasGroupTeachers, hasTeacherIndices, hasTeacherDuties }) => {
        const tasks = [];
        if (hasYear === false) {
          tasks.push({ title: <>需要<span className='red'>建立新年度</span></>, creator: null, dueDate: null, link: '/setting/year' })
        } else {
          if (hasStudents === false) {
            tasks.push({ title: <>需要<span className='red'>匯入新年度學生</span></>, creator: null, dueDate: null, link: '/setting/student' })
          }
          if (hasGroupTeachers === false) {
            tasks.push({ title: <>需要<span className='red'>複製新年度科組人員</span></>, creator: null, dueDate: null, link: '/setting/grouppersonnel' })
          }
          if (hasTeacherIndices === false) {
            tasks.push({ title: <>需要設定<span className='red'>教師編號</span></>, creator: null, dueDate: null, link: '/setting/teacher' })
          }
          if (hasTeacherDuties === false) {
            tasks.push({ title: <>需要設定<span className='red'>教師職位</span></>, creator: null, dueDate: null, link: '/setting/teacher' })
          }
        }
        setTasks(t => ({ ...t, '系統設定': tasks }))
      })
    }
    if (menus.some(m => m.name === 'survey')) {
      get('dashboard/get/survey', [], abortController).then(tasks => {
        setTasks(t => ({ ...t, '校本計劃問卷': tasks.map(t => ({ ...t, link: `/survey/complete/${t.id}` })) }))
      })
    }

    const ECAtasks = [
      { title: '週五普通興趣小組', link: '/eca/fridayActivity/normalgroup' },
      { title: '週五興趣學生分組', link: '/eca/fridayActivity/student' },
      { title: '週五普通興趣小組上課日分配', link: '/eca/fridayActivity/lesson' },
    ]

    if (menus.some(m => m.name === 'eca')) {
      setTimeout(()=>{
        setTasks(t => ({
          ...t,
          '課外活動': ECAtasks.filter(x => menus.some(m => m.url === x.link)).map(x => (
            { title: <>設定今年<span className='red'>{x.title}</span></>, creator: null, dueDate: null, link: x.link }
          ))
        }))
      }, 400)
    }

    return () => {
      abortController.abort();
    }
  }, [menus]);

  const nav = useCallback((event) => {
    const { link } = event.target.closest('tr').dataset;
    history.push(link)
  }, [history]);
  
  const taskEntries = Object.entries(tasks);

  return (<ResponsiveGrid>
    {taskEntries.map(([topic, m]) => {
      const hasDueAndCreator = m.some(x => x.dueDate || x.creator);
      return (<Segment.Group key={topic}>
        <Segment className='no-margin-top' color='blue' inverted>
          <Header as='h2' textAlign='center'>
            <Header.Content>{topic}</Header.Content>
          </Header>
        </Segment>
        {m.length ? (<Table fixed selectable celled attached='bottom' unstackable>
          <colgroup>
            <col />
            {hasDueAndCreator && <><col style={creator} />
              <col style={dueDate} /></>}
          </colgroup>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>標題</Table.HeaderCell>
              {hasDueAndCreator && <><Table.HeaderCell>發起人</Table.HeaderCell>
                <Table.HeaderCell>截止日期</Table.HeaderCell></>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {m.map(({ title, creator, dueDate, link }, i) => {
              let className = getClassName(dueDate);
              return (
                <Table.Row className={className} key={i} data-link={link} onClick={nav}>
                  <Table.Cell><Header as="h4" inverted={/inverted/.test(className)}>{title}</Header></Table.Cell>
                  {hasDueAndCreator && <><Table.Cell>{creator}</Table.Cell>
                    <Table.Cell>{!!dueDate && momentToDate(dueDate)}</Table.Cell></>
                  }
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>) : <EmptySegmentMsg as='h3' msg="任務完成" padded={false} />}
      </Segment.Group>)
    })}
  </ResponsiveGrid>)
}

export default Panel;