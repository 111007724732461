import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
	PageHeader,
  MySortableTable,
  MySelect,
  Buttons
} from '../../../../Components';

/* import helper functions */
import { get } from '../../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../../Helper/Helper';
import {genLocationString } from './../FridayHelpers';

/* import lodash */
import { find, groupBy } from 'lodash';

/* import semantic-ui element */
import { Grid } from 'semantic-ui-react';

import { fromBlankAsync } from 'xlsx-populate';
import { exportExcel } from '../../../../Helper/ExcelHelper';
import {tableColumnData} from './Core';

class FridayActivityStudent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,
      yearOptions: [],
      yearId: '',
      students: [],
      fridayActivityMap: new Map(),
    }
    this.tableProps = {unstackable: true, compact: true,};
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchYear = async () => {
    try{
      const result = await get('getYear');
      await this.setStateAsync({
        yearOptions: selectOptions(result, 'displayName', 'id'),
        yearId: defaultYear(result),
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity year', err)}
  }

  fetchData = async () => {
    try{
      const {yearId} = this.state;
      let [students, fridayActivities, specialStudents, normalStudents] = await Promise.all(['getStudentEssentialInfo','getFridayActivity','getAllSpecialFridayActivityStudent','getAllNormalFridayActivityStudent'].map(x=>get(x, [yearId])));

      if(!students.length){
        alert("請校方先在系統設定中上載今年度的學生資料");
        return;
      }

      fridayActivities = fridayActivities.filter(x=>x.type==='special');

      const fridayActivityMap = new Map(fridayActivities.map((x, i)=>[x.id, i]));
      
      const specStudentMap = new Map(specialStudents.map(x=>[x.studentId, fridayActivityMap.get(x.fridayActivityId)]));
      const normStudentMap = new Map(normalStudents.map((x, i)=>[x.studentId, i]));
      for(let s of students){
        Object.assign(s, {
          studentId: s.id,
          id: undefined,
        });
        if(specStudentMap.has(s.studentId)){
          const spec = fridayActivities[specStudentMap.get(s.studentId)];
          if(spec){
            Object.assign(s, {
              group: spec.code,
              fridayActivity: spec.name,
              fridayActivityId: spec.id
            });
          }else{
            console.log("Special Group doesn't exist");
          }
        }else{
          if(normStudentMap.has(s.studentId)){
            const norm = normalStudents[normStudentMap.get(s.studentId)];
            Object.assign(s, {
              group: norm.groupName,
              locationName: norm.locationName,
              fridayActivity: null
            });
          }
        }
      }

      await this.setStateAsync({
        fridayActivityMap: new Map(fridayActivities.map(x=>[x.id, x])),
        students
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity data', err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchYear();
    await this.fetchData(this.state.yearId);
    this.setStateAsync({ finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  yearChange = async (event, { value }) => {
    try{
      await this.setStateAsync({ finishedLoading: false, yearId: value });
      await this.fetchData();
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity', err)}
    this.setStateAsync({ finishedLoading: true });
  }

  editFridayActivityStudent = () => {
    this.props.history.push('/eca/fridayActivity/studentEdit/' + this.state.yearId);
  }

  downloadFridayActivityStudentByClass = async () => {
    const year = find(this.state.yearOptions, {value: this.state.yearId})
    let filename = year?year.text + '年度周五興趣小組各班別分組名單':'周五興趣小組各班別分組名單';
    if(!this.state.students){ alert("找不到學生資料"); return;}
    const studentsByClass = groupBy(this.state.students, 'classCode');
    const classKeys = Object.keys(studentsByClass);
    if(!classKeys.length){ alert("找不到學生班別資料"); return;}

    const workbook = await fromBlankAsync();
    let sheet = workbook.sheet(0).name(classKeys[0]);
    console.log(this.state.fridayActivityMap);
    Object.entries(studentsByClass).forEach(([classCode,classStudents],i)=>{
      let data = [['班別', '班號', '學生姓名', '性別', '組別', '活動名稱', '上課地點']];
      for(let x of classStudents){
        console.log(x.group);
        data.push([x.classCode, Number(x.classNo), x.chiName || x.engName, x.gender, x.group, x.fridayActivity||'-', x.locationName||genLocationString(this.state.fridayActivityMap.get(x.fridayActivityId))]);
      }
      
      sheet.cell('A6').value(data);
      sheet.usedRange().style({
        border: { left: 'medium', right: 'medium', top: 'medium', bottom: 'medium', },
      });

      sheet.range('A1:G1').merged(true).startCell().value(process.env.REACT_APP_SCHOOL_NAME);
      sheet.range('A2:G2').merged(true).startCell().value("周五興趣小組");
      sheet.range('A3:G3').merged(true).startCell().value(`${classCode}班分組名單`);

      sheet.range('A1:G3').style({ fontSize: 16 });

      sheet.column('C').width(24);
      sheet.column('F').width(20);
      sheet.column('G').width(30);

      sheet.usedRange().style({
        fontFamily: '標楷體',
        horizontalAlignment: 'center'
      });
      if(i<classKeys.length-1){
        workbook.addSheet(classKeys[i+1]);
        sheet = workbook.sheet(classKeys[i+1]);
      }
    });

    const file = await workbook.outputAsync();
    exportExcel(file, filename);
  }

  render() {
    const {
      finishedLoading,
      yearOptions,
      yearId,
      students,
    } = this.state;

    return (
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='學生分組名單' subTitle='設定每年度的週五興趣小組學生分組名單' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <MySelect
              onChange={this.yearChange}
              options={yearOptions}
              value={yearId}
              disabled={!finishedLoading}
            />
            <Buttons.EditButton
              floated='right'
              className='button-margin-bottom'
              content='編輯學生分組名單'
              disabled={!finishedLoading}
              onClick={this.editFridayActivityStudent}
            />
            <Buttons.DownloadButton
              content='下載每班分組名單'
              disabled={!finishedLoading}
              onClick={this.downloadFridayActivityStudentByClass}
              floated='right'
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <MySortableTable
              data={students}
              tableColumnData={tableColumnData}
              finishedLoading={finishedLoading}
              tableProps={this.tableProps}
              keyHandle='studentId'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(FridayActivityStudent);