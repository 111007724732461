/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import PageHeader from '../../Components/PageHeader';
import ErrorLabel from '../../Components/ErrorLabel';
import FullscreenDimmer from '../../Components/FullscreenDimmer';

/* import helper */
import { post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Segment, Form, Button, Message, Icon } from 'semantic-ui-react';

class User_Setting extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        dimmerOpen: false,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        msg: null,
        editStatus: null
    }

    componentWillMount = () => {
        //form validator
        this.validator = new SimpleReactValidator({
            element: message =>  <ErrorLabel message={message} />,
            messages: {
                default: '新簽名密碼需要一樣（不能空白）'
            }
        });
    }

    /* input update handler */
    inputChange = (inputType, stateName) => (event, data) => {      
        let value = inputHandler(inputType, data);
        this.setState({ [stateName]: value })
    }

    /* save */
    save = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return;
        }
        
        const { oldPassword, newPassword } = this.state;
        let data = { 
            oldSign: oldPassword,
            sign: newPassword 
        };

        this.setState({dimmerOpen: true});
        post('editUserSetting', data).then(({status}) => {
            if(status) {
                this.validator.hideMessages();
                this.forceUpdate();
                this.setState({ 
                    dimmerOpen: false,
                    oldPassword: '',
                    newPassword: '',
                    confirmNewPassword: '',
                    msg: '已更改簽名密碼',
                    editStatus: 'approve',
                });
            }else{
                this.setState({ 
                    dimmerOpen: false,
                    msg: '舊簽名密碼不正確',
                    editStatus: 'error',
                });
            }
        });
    }
    
    render() {
        const { dimmerOpen, oldPassword, newPassword, confirmNewPassword, msg, editStatus } = this.state;
        return (
            <Fragment>
                {dimmerOpen? 
                    <FullscreenDimmer active={dimmerOpen} isLoading={true} />
                    :
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>
                                <PageHeader title='簽名密碼' subTitle='更改簽名密碼' /> 
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment padded='very' attached>
                                    <Form>
                                        <Form.Group className='form-group-margin' grouped>
                                            <Form.Input value={oldPassword} onChange={this.inputChange('text', 'oldPassword')} type='password' label='舊簽名密碼（若未有設定請留空）' placeholder='簽名密碼（若未有設定請留空）'  />
                                        </Form.Group>
                                        <Form.Group className='form-group-margin' grouped>
                                            <Form.Input value={newPassword} onChange={this.inputChange('text', 'newPassword')} type='password' label='新簽名密碼' placeholder='簽名密碼'  />
                                            {this.validator.message('newPassword', newPassword, 'required')}   
                                        </Form.Group>
                                        <Form.Group className='form-group-margin' grouped>
                                            <Form.Input value={confirmNewPassword} onChange={this.inputChange('text', 'confirmNewPassword')} type='password' label='確認新簽名密碼' placeholder='確認簽名密碼'  />
                                            {this.validator.message('confirmNewPassword', confirmNewPassword, 'required|in:'+newPassword)}   
                                        </Form.Group>
                                        <Grid>
                                            <Grid.Column>
                                                <Button floated='right' color='green' content='儲存' icon='save' onClick={this.save} circular />
                                            </Grid.Column>
                                        </Grid>
                                    </Form>
                                </Segment>
                                {editStatus === 'approve' &&
                                    <Message info attached='bottom'>
                                        <Icon name='check' />{msg}
                                    </Message>
                                }
                                {editStatus === 'error' &&
                                    <Message error attached='bottom'>
                                        <Icon name='warning' />{msg}
                                    </Message>
                                }
                                
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            </Fragment>
        )
    }
}

export default withRouter(User_Setting);