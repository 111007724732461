import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { find, get as _get, groupBy } from 'lodash';
import Nav from '../Components/Nav';
import { User } from '../Context';

import { Grid, Menu, Accordion} from 'semantic-ui-react';

const ModuleLayout = ({ children }) => {
  const { pathname } = useLocation();
  const {menus} = useContext(User);
  const theItem = find(menus, { url: pathname });

  const title = _get(theItem, 'displayName', '');
  const moduleName = _get(pathname.match(/^\/?([^\/]+)/), 1, '');
  const trueMenus = groupBy(menus, m=>String(m.index).indexOf(".")<0);
  if(trueMenus.false){
    trueMenus.false = groupBy(trueMenus.false, 'moduleId')
  }else{
    trueMenus.false = {};
  }
  if(!trueMenus.true){
    trueMenus.true = [];
  }
  
  const [activeModule, setActiveModule] = useState(0);
  useEffect(()=>{
    const theModule = find(trueMenus.true, {name: moduleName});
    setActiveModule(_get(theModule, 'moduleId', 0));
  }, [pathname, menus]);

  const handler = useCallback((e)=>{
    const {index} = e.target.closest('.title').dataset
    setActiveModule(e=>e===+index?0:+index);
  }, []);

  return (
    <>
      <Nav title={title} />
      <Grid id='main' celled={true}>
        <Grid.Row>
          <Grid.Column id='side-bar' stretched only='tablet computer'>
            <Accordion as={Menu} size='huge' secondary vertical color='blue'>
              {trueMenus.true.map(M=>(
                <Menu.Item
                  key={M.moduleId}
                  header={true}
                  active={pathname === M.url}
                  disabled={Boolean(M.lock)}
                >
                  <Accordion.Title
                    active={activeModule===M.moduleId}
                    index={M.moduleId}
                    data-index={M.moduleId}
                    onClick={handler}
                    content={<><span className='menu-index'>{M.index}</span>{M.displayName}</>}
                  />
                  <Accordion.Content
                    active={activeModule===M.moduleId}
                    content={
                    (trueMenus.false[M.moduleId]||[]).map(m=>{
                      return Boolean(m.header) ? (
                        <Menu.Item
                          key={m.id}
                          header={true}
                          active={pathname === m.url}
                          disabled={Boolean(m.lock||m.header)}
                        >
                          <span className='menu-index'>{m.index}</span>
                          {m.displayName}
                        </Menu.Item>
                      ) : (
                        <Link to={m.url} key={m.id}>
                          <Menu.Item className={String(m.index).split(".").length===3?"sub-sub-item":undefined} active={pathname === m.url} disabled={Boolean(m.lock)}>
                            <span className='menu-index'>{m.index}</span>
                            {m.displayName}
                          </Menu.Item>
                        </Link>
                      )
                    })
                  }/>
                </Menu.Item>
              ))}
              {/* {menus.map(({ displayName, index, url, lock, header, id }) => {
                return Boolean(header) ? (
                  <Menu.Item
                    key={id}
                    header={true}
                    active={pathname === url}
                    disabled={Boolean(lock)}
                  >
                    <span className='menu-index'>{index}</span>
                    {displayName}
                  </Menu.Item>
                ) : (
                  <Link to={url} key={id}>
                    <Menu.Item active={pathname === url} disabled={Boolean(lock)} data-index={index}>
                      <span className='menu-index'>{index}</span>
                      {displayName}
                    </Menu.Item>
                  </Link>
                )
              })} */}
            </Accordion>
          </Grid.Column>
          <Grid.Column id='content' stretched>
            {children}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default ModuleLayout;