/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import react router */
import { Link } from 'react-router-dom';

/* import components */
import ErrorLabel from '../../../Components/ErrorLabel';
import ConfirmModal from '../../../Components/ConfirmModal';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import SignModal from '../../../Components/SignModal';
import DisplayBeforeComponent from '../Item/DisplayBeforeComponent';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { buildTree } from '../../../Helper/TreeHelper';
import { markSelectOptions, selectOptions, roundTwoDp } from '../../../Helper/Helper';
import { momentToDate, dateToMoment, htmlInputDate } from '../../../Helper/TimeHelper';

/* import calculation function */
import { formContentMarkForOne, base } from '../Report/Calculation';

/* import lodash */
import { filter, find, forEach, orderBy } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Form, Segment, Table, Button, TextArea, Select, Message } from 'semantic-ui-react';

/* import atrament for writing comments */
var atrament = require('atrament');

class EditOtherWrite extends Component {
	constructor(props) {
		super(props);
		ping(this);

		//form validator
		this.validator = new SimpleReactValidator({
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				default: '請輸入資料',
			},
		});
	}

	state = {
		dimmerOpen: true,

		section: this.props.match.params.section,
		appeal: this.props.match.params.section === 'appeal' ? true : false,
		teacherId: this.props.match.params.teacherId,
		teacherInfo: {},

		appraisalItemId: this.props.match.params.appraisalItemId,
		appraisalTargetId: this.props.match.params.appraisalTargetId,
		appraisalMarkingId: this.props.match.params.appraisalMarkingId,

		appraisalItem: {},
		appraisalTarget: {},
		appraisalMarking: {},

		appraisalContentBefore: [],
		appraisalContentBeforeTree: [],
		appraisalBefore: {},

		appraisalContentOther: [],
		appraisalContentOtherTree: [],
		appraisalOther: {},

		subject: [],
		appraisalTextOption: [],

		comment: '',
		dataUrl: null,

		sketcher: null,
		sketcherMode: 'write',
		isDeleteModalOpen: false,
		isRemoveModalOpen: false,

		isEdit: false,
		appraisalStatus: null,
		isSignModalOpen: false,
	};

	fetch = async () => {
		const { appraisalItemId, appraisalTargetId, appraisalMarkingId, teacherId } = this.state;
		let [
			appraisalItem,
			appraisalTarget,
			appraisalMarking,
			appraisalContentBefore,
			appraisalFormBefore,
			appraisalContentOther,
			appraisalFormOther,
			appraisalWriting,
			subject,
			appraisalTextOption,
		] = await Promise.all([
			get('getAppraisalItem/' + appraisalItemId),
			get('getOneAppraisalFormTarget/' + appraisalTargetId),
			get('getOneAppraisalMarkingById/' + appraisalMarkingId),
			get('getAppraisalContentBefore/' + appraisalItemId),
			get('getAppraisalFormBefore/' + appraisalTargetId),
			get('getAppraisalContentOther/' + appraisalItemId),
			get('getAppraisalFormOther/' + appraisalTargetId + '/' + teacherId),
			get('getAppraisalWriting/' + appraisalTargetId + '/' + teacherId),
			get('getSubject'),
			get('getAppraisalTextOption/' + appraisalItemId),
		]);

		let appraisalBefore = {};
		forEach(appraisalFormBefore, ({ appraisalContentId, text, date, score }) => {
			if (score) {
				appraisalBefore[appraisalContentId] = score;
			} else {
				appraisalBefore[appraisalContentId] = '不適用';
			}
			if (text) {
				appraisalBefore[appraisalContentId] = text;
			}
			if (date) {
				appraisalBefore[appraisalContentId] = dateToMoment(date);
			}
		});

		let appraisalOther = {};

		forEach(appraisalContentOther, ({ id, inputType, multiple, notApplicable }) => {
			const record = find(appraisalFormOther, { appraisalContentId: id });
			if (inputType === 'date') {
				return (appraisalOther[id] = record && record.date ? htmlInputDate(record.date) : htmlInputDate());
			}

			if (inputType === 'score') {
				if (notApplicable) {
					return (appraisalOther[id] = record && record.score ? record.score : 'Not applicable');
				} else {
					return (appraisalOther[id] = record && record.score ? record.score : 0);
				}
			}

			if (inputType === 'textSelect' && multiple) {
				return (appraisalOther[id] = record && record.text ? record.text.split(', ') : []);
			}

			return (appraisalOther[id] = record && record.text ? record.text : '');
		});

		appraisalContentBefore = orderBy(appraisalContentBefore, ['order', 'id']);
		appraisalContentOther = orderBy(appraisalContentOther, ['order', 'id']);
		this.setState({
			appraisalItem: appraisalItem.length > 0 ? appraisalItem[0] : {},
			appraisalTarget: appraisalTarget.length > 0 ? appraisalTarget[0] : {},
			appraisalMarking: appraisalMarking.length > 0 ? appraisalMarking[0] : {},
			appraisalContentBefore,
			appraisalContentBeforeTree: buildTree(appraisalContentBefore),
			appraisalBefore,
			appraisalContentOther,
			appraisalContentOtherTree: buildTree(appraisalContentOther),
			isEdit: appraisalFormOther.length > 0 ? true : false,
			appraisalOther,
			comment: appraisalWriting.length > 0 ? appraisalWriting[0]['comment'] : '',
			dataUrl: appraisalWriting.length > 0 ? appraisalWriting[0]['dataUrl'] : null,
			subject: selectOptions(subject, 'displayName', 'displayName'),
			appraisalTextOption,
		});
	};

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidMount = async () => {
		this.mounted = true;
		await this.fetch();
		this.setState({ dimmerOpen: false });

		// set up sketcher
		let sketcher = atrament('#sketcher', '1000', '1000', '#000000');
		sketcher.weight = 0.1;
		sketcher.smoothing = false;
		if (this.mounted) {
			this.setState({ sketcher: sketcher });
		}
	};

	modalToggle = (modalStateName) => () => {
		this.setState({ [modalStateName]: !this.state[modalStateName] });
	};

	/* Input change */
	inputChange = (inputType, stateName) => (event, data) => {
		let value = inputHandler(inputType, data);
		const { appraisalOther } = this.state;
		appraisalOther[stateName] = value;
		const newAppraisalOther = appraisalOther;
		this.setState({ appraisalOther: newAppraisalOther });
	};

	/* change comment */
	commentChange = (event, data) => {
		let comment = inputHandler('text', data);
		this.setState({ comment });
	};

	/* use type in comment */
	typeComment = () => {
		document.getElementById('commentInput').focus();
	};

	write = () => {
		let { sketcher } = this.state;
		sketcher.mode = 'write';
		sketcher.weight = 0.1;
		this.setState({
			sketcher: sketcher,
			sketcherMode: 'write',
		});
	};

	erase = () => {
		let { sketcher } = this.state;
		sketcher.mode = 'erase';
		sketcher.weight = 5;
		this.setState({
			sketcher: sketcher,
			sketcherMode: 'erase',
		});
	};

	clear = () => {
		let { sketcher } = this.state;
		sketcher.clear();
		this.setState({
			isDeleteModalOpen: false,
			sketcher: sketcher,
		});
	};

	removeSavedWriting = () => {
		this.setState({
			isRemoveModalOpen: false,
			dataUrl: null,
		});
	};

	/* return url */
	returnUrl = () => {
		const { appeal, teacherId, appraisalItemId } = this.state;
		if (appeal) {
			return '/appraisal/appeal/form_list/' + appraisalItemId + '/' + teacherId;
		} else {
			return '/appraisal/item/form_list/' + appraisalItemId;
		}
	};

	cancel = () => {
		this.props.history.push(this.returnUrl());
	};

	displayOther = (appraisalContentOtherTree, level = 1) => {
		const { appraisalOther, subject, appraisalTextOption } = this.state;

		return (
			<Fragment>
				{appraisalContentOtherTree.map(
					(
						{
							id,
							description,
							needInput,
							inputType,
							min,
							max,
							step,
							count,
							avgCount,
							percentage,
							notApplicable,
							multiple,
							children,
						},
						index
					) => {
						let showlabel = description;
						if (count) {
							if (avgCount) {
								showlabel += ' (平均計算)';
							} else if (percentage) {
								showlabel += ' (' + percentage + '%)';
							}
						}

						return (
							<Fragment key={index}>
								<Form.Group className={level > 1 ? 'next-layer' : 'form-group-margin'} grouped>
									{needInput ? (
										<Fragment>
											{inputType === 'text' && (
												<Form.Input
													control={TextArea}
													onChange={this.inputChange('text', id)}
													value={appraisalOther[id] !== '' ? appraisalOther[id] : ''}
													type="text"
													label={showlabel}
													placeholder={description}
													width="16"
												/>
											)}

											{inputType === 'date' && (
												<Form.Input
													type="date"
													onChange={this.inputChange('text', id)}
													value={appraisalOther[id] !== '' ? appraisalOther[id] : htmlInputDate()}
													label={showlabel}
													placeholder={description}
													width="8"
												/>
											)}

											{inputType === 'subject' && (
												<Form.Field
													control={Select}
													onChange={this.inputChange('select', id)}
													value={appraisalOther[id] !== '' ? appraisalOther[id] : ''}
													options={subject}
													label={showlabel}
													placeholder={description}
													width="8"
												/>
											)}

											{inputType === 'score' && (
												<Form.Select
													onChange={this.inputChange('select', id)}
													value={appraisalOther[id] !== '' ? appraisalOther[id] : ''}
													options={markSelectOptions(min, max, step, notApplicable)}
													label={showlabel}
													placeholder={description}
													clearable
													width="8"
												/>
											)}

											{inputType === 'textSelect' &&
												(multiple ? (
													<Form.Select
														onChange={this.inputChange('select', id)}
														value={Array.isArray(appraisalOther[id]) ? appraisalOther[id] : []}
														options={selectOptions(
															filter(appraisalTextOption, { appraisalContentId: id }),
															'displayName',
															'displayName'
														)}
														label={showlabel}
														placeholder={description}
														search
														multiple
														clearable
														width="8"
													/>
												) : (
													<Form.Select
														onChange={this.inputChange('select', id)}
														value={appraisalOther[id] !== '' ? appraisalOther[id] : ''}
														options={selectOptions(
															filter(appraisalTextOption, { appraisalContentId: id }),
															'displayName',
															'displayName'
														)}
														label={showlabel}
														placeholder={description}
														clearable
														width="8"
													/>
												))}
											{this.validator.message(description, appraisalOther[id], 'required')}
										</Fragment>
									) : (
										<label>{showlabel}</label>
									)}
									{children && this.displayOther(children, level + 1)}
								</Form.Group>
							</Fragment>
						);
					}
				)}
			</Fragment>
		);
	};

	/* open sign modal */
	openSign = (appraisalStatus = null) => () => {
		if (appraisalStatus !== 'save' && !this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
		}

		this.setState({
			appraisalStatus,
			isSignModalOpen: true,
		});
	};

	/* close sign modal */
	closeSign = () => {
		this.setState({ isSignModalOpen: false });
	};

	/* display avg */
	displayAvg = () => {
		const { appraisalContentOtherTree, appraisalOther } = this.state;

		let mark = 0;
		let storePercentage = 0;

		forEach(appraisalContentOtherTree, ({ id, percentage, max, count, children }) => {
			if (count) {
				let returnMark = formContentMarkForOne(id, max, count, appraisalOther, children) * percentage;
				if (!isNaN(returnMark)) {
					mark += returnMark;
					storePercentage += percentage;
				}
			}
		});

		if (storePercentage > 0) {
			return roundTwoDp(mark / storePercentage);
		} else {
			return '-';
		}
	};

	save = () => {
		const {
			isEdit,
			appraisalTargetId,
			appraisalOther,
			appraisalContentOther,
			comment,
			sketcher,
			teacherId,
			appeal,
			appraisalStatus,
		} = this.state;
		let dataUrl = this.state.dataUrl;
		if (!dataUrl && sketcher.dirty) {
			dataUrl = sketcher.toImage();
		}

		let appraisalOtherArray = Object.keys(appraisalOther).map((id) => {
			const contentRecord = find(appraisalContentOther, { id: Number(id) });
			if (contentRecord.inputType === 'date') {
				return { appraisalContentId: id, date: appraisalOther[id] };
			}

			if (contentRecord.inputType === 'score') {
				return {
					appraisalContentId: id,
					score: appraisalOther[id] === 'Not applicable' || appraisalOther[id] === '' ? null : appraisalOther[id],
				};
			}

			if (contentRecord.inputType === 'textSelect' && contentRecord.multiple) {
				return { appraisalContentId: id, text: appraisalOther[id].join(', ') };
			}

			return { appraisalContentId: id, text: appraisalOther[id] };
		});

		let data = {
			isEdit,
			appraisalTargetId,
			appraisalOtherArray,
			comment,
			dataUrl,
			teacherId: appeal ? teacherId : null,
			appraisalStatus,
		};
		this.setState({ dimmerOpen: true });
		post('editAppraisalFormOtherWriting', data).then((result) => {
			if (result) {
				this.validator.hideMessages();
				this.forceUpdate();
				this.cancel();
			}
		});
	};

	render() {
		const {
			dimmerOpen,
			appeal,
			appraisalItem,
			appraisalTarget,
			appraisalMarking,
			appraisalContentBeforeTree,
			appraisalBefore,
			appraisalContentOtherTree,
			comment,
			dataUrl,
			sketcherMode,
			isDeleteModalOpen,
			isRemoveModalOpen,
			isSignModalOpen,
		} = this.state;
		return (
			<Fragment>
				{dimmerOpen ? (
					<FullscreenDimmer active={dimmerOpen} isLoading={true} />
				) : (
					<Fragment>
						<Grid celled="internally" doubling>
							<Grid.Row>
								<Grid.Column className="appraisal-form" computer={8} mobile={16}>
									<Grid>
										<Grid.Row>
											<Grid.Column>
												<Segment.Group>
													<Segment className="bold">
														<Grid columns={3} stackable>
															<Grid.Row className="larger-font" verticalAlign="middle">
																<Grid.Column>
																	{appraisalItem.yearName} : {appraisalItem.displayName}
																</Grid.Column>
															</Grid.Row>
															<Grid.Row>
																<Grid.Column>被評老師:{appraisalTarget.name}</Grid.Column>
																<Grid.Column>評估老師:{appraisalMarking.name}</Grid.Column>
																<Grid.Column>遞交限期:{momentToDate(appraisalTarget.deadline)}</Grid.Column>
															</Grid.Row>
															<Grid.Row>
																<Grid.Column>評估科目:{appraisalTarget.subject}</Grid.Column>
																<Grid.Column>評估班別:{appraisalTarget.className}</Grid.Column>
															</Grid.Row>
														</Grid>
													</Segment>
													<Segment secondary>
														<Table basic="very" padded celled>
															<Table.Body>
																<DisplayBeforeComponent
																	appraisalContentBeforeTree={appraisalContentBeforeTree}
																	appraisalBefore={appraisalBefore}
																/>
															</Table.Body>
														</Table>
													</Segment>
												</Segment.Group>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column>
												<Segment.Group>
													<Segment className="bold larger-font">
														<Grid columns={2} stackable>
															<Grid.Row verticalAlign="middle">
																<Grid.Column>評估內容</Grid.Column>
															</Grid.Row>
														</Grid>
													</Segment>
													<Segment padded="very" secondary>
														<Form>
															{this.displayOther(appraisalContentOtherTree)}

															<Form.Group className="form-group-margin" grouped>
																<Form.Input
																	id="commentInput"
																	control={TextArea}
																	onChange={this.commentChange}
																	value={comment}
																	type="text"
																	label="輸入評語"
																	placeholder="輸入評語"
																/>
															</Form.Group>

															<Message>
																<Message.Header>
																	平圴分數:
																	{this.displayAvg()} / {base}
																</Message.Header>
															</Message>
														</Form>
													</Segment>
												</Segment.Group>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Grid.Column>
								<Grid.Column className="appraisal-canvas" computer={8} mobile={16}>
									{dataUrl ? (
										<div>
											<Button onClick={this.modalToggle('isRemoveModalOpen')} content="清除全部" circular />
											<Button onClick={this.typeComment} floated="right" content="鍵盤輸入" circular />
										</div>
									) : (
										<div>
											<Button primary={sketcherMode === 'write'} onClick={this.write} content="手寫" circular />
											<Button primary={sketcherMode === 'erase'} onClick={this.erase} content="擦掉" circular />
											<Button onClick={this.modalToggle('isDeleteModalOpen')} content="清除全部" circular />
											<Button onClick={this.typeComment} floated="right" content="鍵盤輸入" circular />
										</div>
									)}

									{dataUrl && <img alt="手寫評語" src={dataUrl} width="100%" height="100%" />}
									<canvas id="sketcher" className="gridBg"></canvas>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column textAlign="right">
									<Link to={this.returnUrl()}>
										<Button color="red" type="button" content="取消" icon="cancel" circular />
									</Link>
									{!appeal && (
										<Button color="orange" content="儲存" icon="save" onClick={this.openSign('save')} circular />
									)}
									<Button color="green" content="提交" icon="check" onClick={this.openSign('complete')} circular />
								</Grid.Column>
							</Grid.Row>
						</Grid>

						<ConfirmModal
							open={isDeleteModalOpen}
							description="確定清除手寫內容？"
							cancel={this.modalToggle('isDeleteModalOpen')}
							confirm={this.clear}
						/>
						<ConfirmModal
							open={isRemoveModalOpen}
							description="確定清除手寫內容？一經儲存，原有手寫內容將會被刪除"
							cancel={this.modalToggle('isRemoveModalOpen')}
							confirm={this.removeSavedWriting}
						/>

						<SignModal open={isSignModalOpen} close={this.closeSign} save={this.save} />
					</Fragment>
				)}
			</Fragment>
		);
	}
}

export default withRouter(EditOtherWrite);
