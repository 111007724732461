/* import react */
import React, {useState, useEffect, memo} from 'react';

/* import semantic-ui element */
import { Button, Header, Icon } from 'semantic-ui-react';

import FullscreenDimmer from './FullscreenDimmer';
import ConfirmModal from './ConfirmModal';

const ConfirmButtonAndModal = (props = {}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [dimmerState, setDimmerState] = useState({active: false, content: '', isLoading: true});
  const [cancelled, setCancel] = useState(false);

  useEffect(() => {
    return ()=>{setCancel(true);}
  },[]);

  const send = () => {
    setDimmerState({active: true, isLoading: true, content: '操作進行中'});
    setModalOpen(false);
    props.onConfirm().then((res)=>{
      if(!cancelled){
        if(res && res.status){
          setDimmerState({
            active: true,
            isLoading: false,
            content: <Header size='large' icon inverted><Icon name='check'/>操作成功</Header>
          });
        }else{
          setDimmerState({
            active: true,
            isLoading: false,
            content: <Header size='large' icon inverted>
              <><Icon name='times'/>操作失敗{!!res && <>{res.err===undefined||res.err===null&&': '}{Number.isInteger(res.err)?`有${res.err}個項目未能更新`:res.err}</>}</>
            </Header>
          });
        }
      }
    }).catch((e)=>console.log("the end of universe", e)).finally(()=>{
      props.onFinish();
      setTimeout(()=>{
        if(!cancelled){
          setDimmerState({active: false, content: ''});
          setModalOpen(false);
        }
      }, 3000);
    });
  }

  const bProps = Object.assign({
    color: 'green',
    content: '參考以往項目資料',
    icon: 'paste',
    circular: true,
  }, props.buttonProps);

  delete bProps.onClick;

  const mProps = Object.assign({
    confirmIcon: 'paste',
    confirmText: '確認',
    description: <>確定參考以往項目資料？<span className='red bold'>注意：這會清除原有資料</span></>,
  }, props.modalProps);

  delete mProps.open;
  delete mProps.cancel;
  delete mProps.confirm;

  return (
    <>
      <Button
        {...bProps}
        onClick={()=>{setModalOpen(!modalOpen)}}
      />
      <FullscreenDimmer {...dimmerState}/>
      <ConfirmModal
        {...mProps}
        open={modalOpen}
        cancel={()=>{setModalOpen(false)}}
        confirm={send}
      />
    </>
  )
}

export default memo(ConfirmButtonAndModal);