import React, { Fragment, useEffect, useRef, useState } from 'react';
import { htmlInputDate, momentToDate } from '../../../Helper/TimeHelper';
import { get, post } from '../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../Helper/Helper';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';
import { Button, Form, Grid, GridColumn, Label, List, Modal, Segment, SegmentGroup, Select } from 'semantic-ui-react';
import PageHeader from '../../../Components/PageHeader';
import { buildTree } from '../../../Helper/TreeHelper';
import { Link, useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import ErrorLabel from '../../../Components/ErrorLabel';
import { inputHandler } from '../../../Helper/FormHelper';
import { find, map, filter } from 'lodash';
import { fromBlankAsync } from 'xlsx-populate';
import { exportExcel } from '../../../Helper/ExcelHelper';

const Target = (props) => {
	const history = useHistory();
	const [, forceUpdate] = useState();
	const validator = useRef(
		new SimpleReactValidator({
			autoForceUpdate: { forceUpdate: () => forceUpdate(1) },
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				default: '請輸入資料',
			},
		})
	);

	const [loading, setLoading] = useState(false);
	const [years, setYears] = useState([]);
	const [yearSelectOptions, setYearSelectOptions] = useState([]);
	const [selectedYear, setSelectedYear] = useState(null);
	const [appraisalItems, setAppraisalItems] = useState([]);
	const [appraisalItemTree, setAppraisalItemTree] = useState([]);
	const [appraisalLock, setAppraisalLock] = useState({});
	const [deadline, setDeadline] = useState(htmlInputDate());
	const [isModalOpen, setIsModalOpen] = useState(null);

	const fetchLock = async () => {
		setLoading(true);
		const appraisalLock = await get(`getOneAppraisalLock/${selectedYear}`);
		setAppraisalLock(appraisalLock.length > 0 ? appraisalLock[0] : {});
		setDeadline(appraisalLock.length > 0 ? htmlInputDate(appraisalLock[0].deadline) : htmlInputDate());
		setLoading(false);
	};

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const years = await get('getYear');
			const selectedYear = defaultYear(years);
			setYears(years);
			setYearSelectOptions(selectOptions(years, 'displayName', 'id'));
			setSelectedYear(selectedYear);
			setLoading(false);
		};

		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			const appraisalItems = await get(`getAllAppraisalItem/${selectedYear}`);
			setAppraisalItems(appraisalItems);
			setAppraisalItemTree(buildTree(appraisalItems));
			setLoading(false);
		};

		fetch();
	}, [selectedYear]);

	useEffect(() => {
		fetchLock();
	}, [selectedYear]);

	const editTarget = (id, tableFormat) => () => {
		if (tableFormat) {
			history.push(`/appraisal/target/edit_table/${selectedYear}/${id}`);
			return;
		}
		history.push(`/appraisal/target/edit_form/${selectedYear}/${id}`);
	};

	const print = async (key) => {
		const appraisalItem = find(appraisalItems, ({ displayName }) => displayName === key);
		if (!appraisalItem) {
			alert('沒有記錄');
			return;
		}

		const { id } = appraisalItem;
		const year = find(years, { id: selectedYear });
		const teachers = await get(`getAllTeacherWithAdminWithIndex/${selectedYear}`);
		const targets = await get(`getAppraisalFormTarget/${id}`);
		const marketingTeachers = await get(`getAppraisalFormTargetMarketingTeacher/${id}`);
		const before = await get(`getTargetBeforeInfo/${id}`);

		const yearName = year.displayName;
		const filename = `${yearName}${key}記錄表`.replace('/', '');
		const workbook = await fromBlankAsync();
		const sheet = workbook.sheet(0);

		let content = [];
		content.push(['編號', '授課者', '日期', '節次', '科目', '班別', '觀課者']);

		map(teachers, ({ id, index, name }) => {
			const records = filter(targets, ({ teacherId }) => teacherId === id);
			map(records, ({ id: targetId }) => {
				const marking = filter(marketingTeachers, ({ appraisalTargetId }) => appraisalTargetId === targetId);
				let markingNames = [];
				map(marking, ({ teacherId }) => {
					const teacher = find(teachers, ({ id }) => id === teacherId);
					if (!!teacher) {
						const { positionId, name, shortName } = teacher;
						let returnName = name;

						if (positionId === 2) returnName = '校長';
						else if (!!shortName && shortName !== '-') returnName = shortName;

						markingNames.push(returnName);
					}
				});

				const date = find(before, ({ teacherId, description }) => teacherId === id && description === '觀課日期');
				const className = find(before, ({ teacherId, description }) => teacherId === id && description === '班別');
				const subject = find(before, ({ teacherId, description }) => teacherId === id && description === '科目');
				const lesson = find(before, ({ teacherId, description }) => teacherId === id && description === '節數');

				content.push([
					index,
					name,
					date ? momentToDate(date.date) : '-',
					className ? className.text : '-',
					subject ? subject.text : '-',
					lesson ? lesson.text : '-',
					markingNames.join(' / '),
				]);
			});
		});

		sheet.cell('A1').value(content);
		sheet.range('A1:G1').style({
			bold: true,
		});
		const file = await workbook.outputAsync();
		exportExcel(file, filename);
	};

	const displayItem = (appraisalItems) => {
		return (
			<React.Fragment>
				{appraisalItems.map(({ id, displayName, percentage, tableFormat, children }, index) => {
					return (
						<List.Item key={index}>
							<Segment>
								<Grid columns={2} stackable>
									<Grid.Row verticalAlign="middle">
										<Grid.Column>
											{percentage !== null && (
												<Label style={{ marginLeft: '10px' }} color="blue" horizontal basic>
													{percentage}%
												</Label>
											)}
											<b style={{ marginLeft: '10px' }}>{displayName}</b>
										</Grid.Column>
										{!children && (
											<Grid.Column textAlign="right">
												<Button onClick={editTarget(id, tableFormat)} color="blue" icon="setting" circular />
											</Grid.Column>
										)}
									</Grid.Row>
								</Grid>
							</Segment>
							{children && (
								<List.Item>
									<List.List>{displayItem(children)}</List.List>
								</List.Item>
							)}
						</List.Item>
					);
				})}
			</React.Fragment>
		);
	};

	const displayDeadlineModal = () => {
		return (
			<Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} closeOnEscape={false} closeOnDimmerClick={false}>
				<Modal.Header>設定計分期限</Modal.Header>
				<Modal.Content>
					<Form>
						<Form.Group className="form-group-margin" grouped>
							<Form.Input
								className="form-group-margin"
								type="date"
								onChange={(event, data) => {
									setDeadline(inputHandler('text', data));
								}}
								value={deadline}
								label="計分期限*"
								placeholder="計分期限*"
								error={validator.current.message('deadline', deadline, 'required')}
							/>
						</Form.Group>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" content="取消" icon="cancel" onClick={() => setIsModalOpen(false)} circular />
					<Button color="green" content="儲存" icon="save" onClick={saveDeadline} circular />
				</Modal.Actions>
			</Modal>
		);
	};

	const saveDeadline = async (event) => {
		event.preventDefault();
		if (!validator.current.allValid()) {
			validator.current.showMessages();
			return;
		}

		setLoading(true);
		let edit = await post('editAppraisalDeadline', { yearId: selectedYear, deadline });
		if (edit.status) {
			await fetchLock();
			validator.current.hideMessages();
			setIsModalOpen(false);
			setLoading(false);
		} else {
			alert(edit.error);
			setLoading(false);
		}
	};

	const buttons = [
		{ key: '觀課', content: '教師觀課' },
		{ key: '科主/同儕觀課', content: '科主/同儕觀課' },
	];

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<PageHeader title="年度評估對象設定" subTitle="設定每年度需要評估之老師" />
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<Grid.Column>
						<Select
							onChange={(event, { value }) => setSelectedYear(value)}
							options={yearSelectOptions}
							value={selectedYear}
						/>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row>
					<GridColumn>
						{!Boolean(appraisalLock.contentLock) ? (
							<Link to={'/appraisal/content'}>
								<Button type="button" content="設定對像前請先鎖定本年度評估內容" icon="warning" color="red" circular />
							</Link>
						) : (
							<SegmentGroup>
								<Segment>
									<div className="bold" style={{ marginBottom: 10 }}>
										{appraisalLock.deadline ? (
											<span>總分只計算{momentToDate(appraisalLock.deadline)}或以前輸入的分數</span>
										) : (
											'未有設定計分期限'
										)}
									</div>
									<Button
										color="blue"
										content="更改計分期限"
										icon="calendar alternate outline"
										onClick={() => setIsModalOpen(true)}
										circular
									/>
								</Segment>
								<Segment>
									<div className="bold" style={{ marginBottom: 10 }}>
										總表：
									</div>
									{buttons.map(({ key, content }) => {
										return (
											<Button
												key={key}
												color="green"
												content={content}
												icon="download"
												onClick={() => print(key)}
												circular
											/>
										);
									})}
								</Segment>
							</SegmentGroup>
						)}
					</GridColumn>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Segment disabled={!Boolean(appraisalLock.contentLock)} padded="very" secondary>
							{appraisalItems.length > 0 ? (
								<List>{displayItem(appraisalItemTree)}</List>
							) : (
								<Fragment>
									<Segment textAlign="center" basic>
										沒有評估項目
									</Segment>
								</Fragment>
							)}
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{displayDeadlineModal()}
		</React.Fragment>
	);
};

export default Target;
