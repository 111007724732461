/* import lodash */
import {find, keyBy, groupBy, pick} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  FullscreenDimmer,
  ConfirmButtonAndModal,
  MySelect,
  MyTable
} from '../../Components';

import GroupTeacherModal from './GroupTeacherModal';
import {groupTypeOptions} from '../../Const/Const';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import {inputHandler} from '../../Helper/FormHelper';
import {
  selectOptions,
  defaultYear,
  getTeacherOptions,
  whetherReadOnlyForTeacher,
} from '../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Button, Segment, Form } from 'semantic-ui-react';


class GroupPersonnel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,
      dimmerOpen: false,
      buttonProps: {
        disabled: true
      },
  
      year: [],
      yearInfo: [],
      yearId: '',
      groupType: '科組',
      groupName: '',
  
      teacher: {},
      
      groups: [],

      teacherOptions: [],

      gts: [],
      isEditModalOpen: false,
      groupId: null,

      tableColumnData: [
        {
          headerName: '組別',
          cellRender: 'name',
          width: 3,
          show: true
        },
        {
          headerName: '組長',
          cellRender: x=>x.teachers[1]&&x.teachers[1].length?x.teachers[1].map(this.generateLabel).join("、"):"-",
          width: 3,
          show: true
        },
        {
          headerName: '副組長',
          cellRender: x=>x.teachers[2]&&x.teachers[2].length?x.teachers[2].map(this.generateLabel).join("、"):"-",
          width: 3,
          show: true
        },
        {
          headerName: '其他教師',
          cellRender: x=>x.teachers[0]&&x.teachers[0].length?x.teachers[0].map(this.generateLabel).join("、"):"-",
          width: 7,
          show: true
        },
        {
          headerName: '行動',
          cellRender: x=>(
            <Button
              color='blue'
              icon='edit'
              data-id={x.id}
              data-modal-name='isEditModalOpen'
              onClick={this.modalToggle}
              circular
            />
          ),
          width: 2,
          show: true
        },
      ]
    }

    this.noStack = {unstackable:true};
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      if(err!=='unmounted') console.log('Error when mounting group teachers', err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try{
      const {groupType, yearId} = this.state;
      let [yearInfo, groups, teachers] = await Promise.all([
        get('getYear'),
        get(`getGroupByType/${groupType}`),
        get('getAllTeacherWithAdminInSchool'),
      ]);

      const yId = yearId || defaultYear(yearInfo);
      const teacherOptions = await getTeacherOptions(yId);  
      const readOnly = whetherReadOnlyForTeacher(yearInfo, yId); 

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId: yId,

        teacherOptions,
        teacherLookup: keyBy(teachers, 'id'),
        buttonProps: {
          disabled: readOnly
        },
        tableColumnData: this.state.tableColumnData.slice(0,-1).concat({...this.state.tableColumnData.slice(-1)[0], show: !readOnly})
      });

      await this.fetchGroupTeachers(groups);

    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching info for group teachers', err);
    }
  }

  groupTypeChange = async (event, data) => {
    try{
      const value = inputHandler('select', data);
      const groups = await get('getGroupByType/'+value);
      
      await this.setStateAsync({
        finishedLoading: false,
        groupType: value,
      });

      await this.fetchGroupTeachers(groups);

      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      if(err!=='unmounted') console.log('Error when changing group type', err);
    }
  }

  /* year change */
  yearChange = async (event, data) => {
    try{
      const { yearId } = this.state;
      const value = inputHandler('select', data);
      if (yearId !== value) {

        const readOnly = whetherReadOnlyForTeacher(this.state.yearInfo, value);
        const teacherOptions = await getTeacherOptions(value);

        await this.setStateAsync({
          yearId: value,
          buttonProps: {disabled: readOnly},
          finishedLoading: false,
          teacherOptions,
          tableColumnData: this.state.tableColumnData.slice(0,-1).concat({...this.state.tableColumnData.slice(-1)[0], show: !readOnly})
        });

        await this.fetchGroupTeachers(this.state.groups);
        await this.setStateAsync({
          finishedLoading: true,
        });
      }
    }catch(err){
      if(err!=='unmounted') console.log("Error when changing year for Group Action", err);
    }
  }

  fetchGroupTeachers = async (groups) => {
    try{
      let groupTeachers = await get(`getGroupTeachersByType/${this.state.yearId}/${this.state.groupType}`);

      groupTeachers = groupBy(groupTeachers, 'groupId');
      for(let gpId in groupTeachers){
        groupTeachers[gpId] = groupBy(groupTeachers[gpId], x=>+x.isChief);
        for(let i=0;i<3;i++){
          if(!groupTeachers[gpId][i]){
            groupTeachers[gpId][i]=[];
          }
        }
      }

      await this.setStateAsync({
        groups: groups.map(gp=>({
          ...gp,
          teachers: groupTeachers.hasOwnProperty(gp.id) ? groupTeachers[gp.id] : {0:[],1:[],2:[]}
        }))
      });

    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching group teachers', err);
    }
  }
 
  /* modal toggle */
  modalToggle = (eventOrStateName) => {
    let modalName, id;
    if(typeof eventOrStateName === 'object'){
      let modalData = {};
      if(eventOrStateName.target.classList.contains('modals')){
        modalData = eventOrStateName.target.firstElementChild.dataset;
      }else{
        modalData = eventOrStateName.target.closest('.ui, button').dataset;
      }
      modalName = modalData.modalName;
      id = +modalData.id;
    }else{
      modalName = eventOrStateName;
    }
    if(id){
      const gp = find(this.state.groups, {id});
      this.setStateAsync({
        [modalName]: !this.state[modalName],
        groupId: id,
        groupName: gp?gp.name:'',
        gts:gp?gp.teachers[1].concat(gp.teachers[2],gp.teachers[0]):[]
      })
    }else{
      this.setStateAsync({
        [modalName]: !this.state[modalName],
        groupId: null,
        groupName: '',
        gts: []
      });
    }
  }

  save = async (teachers) => {
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      })
      const data = pick(this.state, ['groupId', 'yearId']);
      data.teachers = teachers.map(x=>({
        ...x,
        id: /^new/.test(x.id)?undefined:x.id
      }));
      const result = await post('batchEditGroupTeachers', data);
      if(!result || !result.status) throw result;
      await this.fetch();
      this.modalToggle('isEditModalOpen');
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存科組教師時發生錯誤");
        console.log('Error when saving Subject Group Teachers', err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  generateLabel = gT => {
    const t = this.state.teacherLookup[gT.teacherId];
    return (t?t.name:"無效教師")+(gT.title?` (${gT.title})`:"")
  }

  fetchPrevData = async () => {
    return await post('copyGroupTeacher', {yearId: this.state.yearId});
  }

  render() {
    const {
      finishedLoading,
      dimmerOpen,
      isEditModalOpen,
      buttonProps,
      
      year,
      yearId,
      groupType,
      groupName,

      groups,
      groupId,

      teacherOptions,
      teacherLookup,
      gts,
    } = this.state;

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='組別人員管理' subTitle='設定各組別的人員' />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Segment padded>
                <Form>
                  <Form.Group inline>
                    <Form.Field>
                      <label>年度</label>
                      <MySelect
                        options={year}
                        value={yearId}
                        onChange={this.yearChange}
                      />
                    </Form.Field>
                    <ConfirmButtonAndModal
                      buttonProps={buttonProps}
                      onConfirm={this.fetchPrevData}
                      onFinish={this.fetch}
                    />
                    <Form.Field className="right floated">
                      <label>組別類型</label>
                      <MySelect
                        options={groupTypeOptions}
                        value={groupType}
                        onChange={this.groupTypeChange}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <MyTable
                tableColumnData={this.state.tableColumnData}
                data={groups}
                finishedLoading={finishedLoading}
                tableProps={this.noStack}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <FullscreenDimmer active={dimmerOpen} isLoading={true} />

        {isEditModalOpen && (<GroupTeacherModal
          groupTeachers={gts}
          groupId={groupId}
          groupName={groupName}
          groupType={groupType}
          yearId={yearId}
          teacherLookup={teacherLookup}
          teacherOptions={teacherOptions}
          close={this.modalToggle}
          onConfirm={this.save}
        />)}
      </>
    )
  }
}

export default withRouter(GroupPersonnel);