import {
  escapeHtml,
  printLayout,
} from '../../Helper/PrintHelper';

const renderType = x => x==='special'?'專項':'普通';

const helperObj = [
  {
    "項目名稱":'name',
    "活動時間":()=>"<p>活動時間：<span class='others'>上午11:00&nbsp;至&nbsp;11:35</span></p>",
    "負責老師":x=>`<p>負責老師：<span class='others'>${x.teachers.map(y=>y.name?(escapeHtml(y.name)+"老師"):'外聘導師').join("、")}</span></p>`,
    "所需器材":'equipment',
    "學習材料":'material',
    "財政預算":'budget',
  },
  {
    "配合之關注事項": 'item',
  },
  {
    "教學目標": x=>{
      if(x.target)
        return `<p>教學目標：</p><ol class='no-list-item'>${x.target.split(/[\r\n]+/g).map(y=>`<li><span class='others'>${escapeHtml(y)}</span></li>\n`).join('\n')}</ol>`;
      return "<p>教學目標：<span class='others'>-</span></p>";
    },
  },
  {
    "教學計劃": x=>{
      if(x.plan)
        return `<p>教學計劃：</p><ol class='no-list-item'>${x.plan.split(/[\r\n]+/g).map(y=>`<li><span class='others'>${escapeHtml(y)}</span></li>\n`).join('\n')}</ol>`;
      return "<p>教學計劃：<span class='others'>-</span></p>";
    }
  },
  {
    "預計成效": x=>{
      if(x.outcome)
        return `<p>預計成效：</p><ol class='no-list-item'>${(x.outcome||'').split(/[\r\n]+/g).map(y=>`<li><span class='others'>${escapeHtml(y)}</span></li>\n`).join('\n')}</ol>`;
      return "<p>預計成效：<span class='others'>-</span></p>";
    }
  },
  {
    "評估方法": x=>{
      if(x.method)
        return `<p>評估方法：</p><ol class='no-list-item'>${(x.method||'').split(/[\r\n]+/g).map(y=>`<li><span class='others'>${escapeHtml(y)}</span></li>\n`).join('\n')}</ol>`;
      return "<p>評估方法：<span class='others'>-</span></p>";
    }
  },
];

const gen1RecordLayout = info => `
<div class='center section break-page'>
  <p>${process.env.REACT_APP_SCHOOL_NAME}</p>
  <p>${escapeHtml(info.yearName)} 周五興趣小組計劃表</p>
  <p>${renderType(info.type)}小組第&nbsp;<span class='others'>${escapeHtml(info.code)}</span>&nbsp;組</p>
</div>
${helperObj.map(section=>(
  `<div class='section'>${Object.entries(section).map(
    ([title,method])=>(typeof method === 'string'?`<p>${title}：<span class='others'>${escapeHtml(info[method]||'-')}</span></p>`:(method(info)||'-'))
  ).join('\n')}</div>`
)).join('\n')}
`;

/**
 * Print Friday Activity Plan Record(s)
 * @param {Object[]|Object} info Info for record printing (support 1 object or array of objects) 
 * @param {Function} callback 
 */
const printRecord = (info, callback = () => {}) =>{
  try{
    const HTMLLayout = Array.isArray(info)?info.map(gen1RecordLayout).join('\n'):gen1RecordLayout(info);

    printLayout(HTMLLayout,  Array.isArray(info)?`${(info[0]||{}).yearName} 周五興趣小組 - 計劃表`:`${info.yearName} 周五興趣小組 - ${renderType(info.type)}計劃表${+info.code} (${info.name})`, '',  callback);

  }catch(err){
      alert('ERROR');
      console.clear();
      console.log(err);
      console.log(info);
      callback();
  }
}

export default printRecord;
