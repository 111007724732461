/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	AggregateNumber,
	EmptyTableMsg,
  MySelect,
} from '../../Components';

import { get } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import {
  selectOptions,
  defaultYear,
  numRound
} from '../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Segment, Table, Form } from 'semantic-ui-react';

class Panel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,

      year: [],
      yearId: '',
      surveys: [],
      groupOptions: [],
      groupId: '',
    }

    this.colNum = process.env.REACT_APP_AGGREGATE_SUPPORT?3:2;
    this.tenRem = {width: '10rem'}
  }
  
  fetch = async () => {
    try{
      //get year info
      const rawYear = await get('getYear');
      let year = selectOptions(rawYear, 'displayName', 'id');
      const yearId = this.state.yearId ? this.state.yearId : defaultYear(rawYear);

      await this.setStateAsync({
        year,
        yearId,
      });

      await Promise.all([this.fetchViewableGroups(), this.fetchGroupData()]);
    }catch(err){
      console.log('Error when fetching results');
      console.log(err);
    }
  }

  fetchViewableGroups = async () => {
    try {
      const [groups, groupsCanEdit] = await Promise.all([
        get('getGroup'),
        get('getBelongingGroups/' + this.state.yearId),
      ]);

      const viewableGroups = new Set(groupsCanEdit.map(x => x.id));
      const groupOptions = groups.filter(x=>viewableGroups.has(x.id));

      const groupId = viewableGroups.has(this.state.groupId) ? this.state.groupId : '';

      await this.setStateAsync({
        groupOptions: selectOptions(groupOptions, 'name', 'id'),
        groupId,
      });

      await this.fetchGroupData();

    } catch (err) {
      console.log("Error when fetching editable group data");
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when fetching 3 year Review & SWOP');
      console.log(err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  yearChange = async (_,data) => {
    try{
      let value = inputHandler('select', data);
      await this.setStateAsync({
        yearId: value,
        finishedLoading: false,
      });
      await this.fetchViewableGroups();
    }catch(err){
      console.log('Error when changing selected OYP');
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
    });
  }
  
  groupChange = async (event, { value }) => {
    const { groupId } = this.state;
    if (groupId !== value) {
      await this.setStateAsync({
        finishedLoading: false,
        groupId: value,
      });

      await this.fetchGroupData();
      this.setStateAsync({
        finishedLoading: true
      });
    }
  }

  fetchGroupData = async () => {
    try{
      let surveys = [];
      if(this.state.groupId){
        surveys = await get(`getViewableGroupSurvey/${+this.state.yearId}/${+this.state.groupId}`);
      }
      await this.setStateAsync({surveys});
    }catch(err){
      console.log("Error when fetching group data");
      console.log(err);
    }
  }

  navToSurveyResult = (id) => () => {
    this.props.history.push(`/survey/stats/${+id}`)
  }

  render() {
    const {
      finishedLoading, 
      year, 
      yearId, 
      surveys,
      
      groupOptions,
      groupId,
    } = this.state;
    return (
      <Grid centered doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='科組計劃問卷結果'/>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Group>
                  <Form.Field inline>
                    <label>周年</label>
                    <MySelect
                      disabled={!finishedLoading}
                      options={year}
                      value={yearId}
                      onChange={this.yearChange}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <label>科組</label>
                    <MySelect
                      error={!groupId || groupOptions.length === 0}
                      disabled={!finishedLoading}
                      options={groupOptions}
                      value={groupId}
                      onChange={this.groupChange}
                      placeholder={groupOptions.length?"請先選擇科組":"沒有權限"}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Table fixed selectable celled unstackable>
              <colgroup>
                <col/>
                {!!process.env.REACT_APP_AGGREGATE_SUPPORT && <col style={this.tenRem}/>}
                <col style={this.tenRem}/>
              </colgroup>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>標題</Table.HeaderCell>
                  {!!process.env.REACT_APP_AGGREGATE_SUPPORT && <Table.HeaderCell>達標度</Table.HeaderCell>}
                  <Table.HeaderCell>回答進度</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finishedLoading ? (
                  groupId ? (
                    surveys.length ? surveys.map(s=>(
                      <Table.Row key={s.id} onClick={this.navToSurveyResult(s.id)} className="cursor">
                        <Table.Cell><h4>{s.title}</h4></Table.Cell>
                        {!!process.env.REACT_APP_AGGREGATE_SUPPORT && <Table.Cell><AggregateNumber value={numRound(s.x)} /></Table.Cell>}
                        <Table.Cell>{(!process.env.REACT_APP_NEED_APPROVAL || s.approved)?(s.started?`${numRound(100*s.rate)}%`:'未開始'):'未獲批核'}</Table.Cell>
                      </Table.Row>
                    )):<EmptyTableMsg colSpan={this.colNum} msg='沒有可閱覽的問卷結果' />
                  ):<EmptyTableMsg colSpan={this.colNum} msg='請先選擇科組' />
                ):<EmptyTableMsg colSpan={this.colNum} msg="資料載入中"/>}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(Panel);