import React, {memo, useContext} from 'react';
import {Segment, Form, Button, Grid, Input} from 'semantic-ui-react';

import {DateInput, MySelect} from '../../Components';
import {financialOptions, genFieldClassName} from './Helper';
import {momentToDate} from '../../Helper/TimeHelper';

import DynamicContext from './dynamicFieldContext';

const RecordPage1 = (props) => {
  const {student} = props;

  const {readOnly, inputChange, changeStep} = useContext(DynamicContext);

  return (
    <Segment padded attached>
      <Form.Group className='form-group-margin' widths='equal'>
        <Form.Field>
          <label>1. 學生姓名：</label>
          <Input
            placeholder='學生姓名'
            name="name"
            value={student.name}
            fluid
            disabled
          />
        </Form.Field>
        <Form.Field>
          <label>2. 班別（按年度）：</label>
          <Input
            placeholder='班別（按年度）'
            name="classCode"
            value={student.classCode}
            fluid
            disabled
          />
        </Form.Field>
        <Form.Field>
          <label>3. 學號（按年度）：</label>
          <Input
            placeholder='學號（按年度）'
            name="classNo"
            value={student.classNo}
            fluid
            disabled
          />
        </Form.Field>
        <Form.Field>
          <label>4. STRN:</label>
          <Input
            placeholder='STRN'
            name="STRN"
            value={student.strn}
            fluid
            disabled
          />
        </Form.Field>
      </Form.Group>

      <Form.Group className={genFieldClassName(readOnly || student.consent)} grouped>
        <label>5. SEMIS家長同意書：</label>
        <Form.Radio
          checked={student.consent === "1"}
          onChange={inputChange('radio', 'student', 'consent')}
          name="consent"
          label='同意'
          value='1'
          disabled={readOnly}
        />
        <Form.Radio
          checked={student.consent === "0"}
          onChange={inputChange('radio', 'student', 'consent')}
          name="consent"
          label='不同意'
          value='0'
          disabled={readOnly}
        />
        <Form.Radio
          checked={student.consent === null}
          onChange={inputChange('radio', 'student', 'consent')}
          name="consent"
          label='未有資料'
          value='null'
          disabled={readOnly}
        />
      </Form.Group>

      <Form.Group className={genFieldClassName(readOnly || student.signDate)} grouped>
        <label>簽署日期：</label>
        <DateInput
          value={momentToDate(student.signDate)}
          onChange={inputChange('date', 'student', 'signDate')}
          placeholder='簽署日期'
          name="consent_date"
          disabled={readOnly||student.consent===null}
        />
      </Form.Group>

      <Form.Group className={genFieldClassName(readOnly || student.financial)} grouped>
        <Form.Field>
          <label>家庭經濟狀況</label>
          <MySelect
            value={student.financial}
            onChange={inputChange('select', 'student', 'financial')}
            options={financialOptions}
            name="finance"
            placeholder='家庭經濟狀況'
            disabled={readOnly}
          />
        </Form.Field>
      </Form.Group>
      {/* section 6 per amendment requested on 27/2 */}
      <Form.Group className={genFieldClassName(true)} grouped>
        <label>6. 非華語學生：</label>
        <Form.Checkbox
          checked={!!student.nonChinese}
          name="nonChinese"
          onChange={inputChange('checkbox', 'student', 'nonChinese')}
          label='非華語學生'
          disabled={readOnly}
        />
      </Form.Group>

      <Grid>
        <Grid.Column>
          <Button
            color='green'
            content='下一歩'
            icon='arrow right'
            labelPosition='right'
            floated='right'
            type='button'
            data-step-name='needs'
            onClick={changeStep}
            circular
          />
        </Grid.Column>
      </Grid>
    </Segment>
  )
}

export default memo(RecordPage1);