/* import lodash */
import {pick} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	BlockerPrompt,
	ConfirmModal,
	EmptyTableMsg,
  FullscreenDimmer,
  MySelect,
  FlexibleTextField
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions,
  defaultYearForPlan,
  whetherReadOnlyForYearPlan
} from '../../../Helper/Helper';

import { inputHandler } from '../../../Helper/FormHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Form, Button, Table, Segment } from 'semantic-ui-react';

class Budget extends Component {
  constructor(props) {
    super(props);

    this.counter=0;
    this.state = {
      finishedLoading: false,
      dimmerOpen: false,
      readOnly: true,

      year: [],
      yearInfo: [],
      yearId: '',

      groupId: null,
      groupOptions: [],
      editableGroups: new Set(),

      info: [],
      total: 0,

      isSaveModalOpen: false,
      isBlocking: false,
    }
    this.fields = ['項目','預算金額'];
    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      messages: {
        required: '請輸入資料',
        numeric: '請輸入有效數字'
      },
      autoForceUpdate: this,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;

    try {
      await this.fetch();
      this.setStateAsync({
        finishedLoading: true,
      });
    } catch (err) {
      if(err!=='unmounted') console.log('Error when mounting budgets', err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try {
      //get year info
      const yearInfo = await get('getYear');
      const dYear = defaultYearForPlan(yearInfo);
      const { yearId } = this.state;

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId: yearId ? yearId : dYear,
      });

      await this.fetchEditableGroups();
    } catch (err) {
      if(err!=='unmounted') console.log('Error when fetching budget year info', err);
    }
  }

  fetchEditableGroups = async () => {
    try {
      const [groups, groupsCanEdit] = await Promise.all([
        get('getGroup'),
        get('getGroupEditRights/' + this.state.yearId),
      ]);

      const editableGroups = new Set(groupsCanEdit.map(x => x.id));
      const groupOptions = groups.filter(x=>editableGroups.has(x.id));

      const groupId = editableGroups.has(this.state.groupId) ? this.state.groupId : '';

      await this.setStateAsync({
        groupOptions: selectOptions(groupOptions, 'name', 'id'),
        editableGroups,
        groupId,
        readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId) || !editableGroups.has(groupId),
      });

      await this.fetchGroupData();

    } catch (err) {
      console.log("Error when fetching editable group data");
      console.log(err);
    }
  }

  fetchGroupData = async () => {
    try {
      const { yearId, groupId } = this.state;
      if (yearId && groupId) {
        const _info = await get(`getGroupBudget/${+yearId}/${+groupId}`);
        let info;
        if(_info.length){
          try{
            info = typeof _info[0].info === 'string' ? JSON.parse(_info[0].info): _info[0].info;
          }catch(err){}
        }
        if(!Array.isArray(info)){
          info = [];
        }
        await this.setStateAsync({
          id: _info.length?_info[0].id: undefined,
          info,
          total: this.calcTotal(info)
        });
      }else{
        await this.setStateAsync({
          id: undefined,
          info: [],
          total: 0
        });
      }
    } catch (err) {
      if(err!=='unmounted') console.log('Error when fetching group budget info', err);
    }
  }

  /* year change */
  yearChange = async (event, { value }) => {
    if(this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要切換年度嗎？")){
        return;
      }
    }
    try{
      if (this.state.yearId !== value) {
        await this.setStateAsync({
          yearId: value,
          finishedLoading: false,
        });

        await this.fetchEditableGroups();
        this.setStateAsync({
          finishedLoading: true,
          isBlocking: false,
        });
      }
    } catch (err) {
      if(err!=='unmounted') console.log('Error when changing year for group budget info', err);
    }
  }

  groupChange = async (event, { value }) => {
    if(this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要切換組別嗎？")){
        return;
      }
    }
    try{
      const { groupId } = this.state;
      if (groupId !== value && this.mounted) {
        await this.setStateAsync({
          finishedLoading: false,
          groupId: value,
          readOnly: whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId) || !this.state.editableGroups.has(value),
        });
        await this.fetchGroupData();
        this.setStateAsync({
          finishedLoading: true,
          isBlocking: false,
        });
      }
    } catch (err) {
      if(err!=='unmounted') console.log('Error when changing group for group budget info', err);
    }
  }

  /* save record */
  save = async () => {

    const data = pick(this.state, ['id', 'info', 'yearId', 'groupId']);
    if(!this.state.yearId && this.state.groupId){
      alert('未有提供年份或組別，無法儲存');
      return;
    }

    try{
      await this.setStateAsync({
        dimmerOpen: true,
        finishedLoading: false,
      });
      const result = await post('editGroupBudget', data);
      if(result && result.status){
        this.validator.hideMessages();
        await this.setStateAsync({
          isBlocking: false,
        })
        await this.fetch();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存組別預算時發生錯誤");
        console.log(err);
      }
    }
    try{await this.saveModalToggle();}catch(err){}
    this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true,
    });
  }

  calcTotal = (info) => {
    let total = "ERR";
    if(!info) info = this.state.info;
    if(Array.isArray(info)){
      total = info.reduce((prev,cur)=>+cur['預算金額']+prev,0);
    }
    return Number.isNaN(total)?"ERROR":(Math.round(total * 1e12) / 1e12);
  }

  /* input update handler */
  inputChange = async (event, data) => {
    this.validator.purgeFields();
    const {inputType, whichField, i} = event.target.closest('.ui, button, textarea').dataset;
    const info = [...this.state.info];
    info[+i] = {
      ...info[+i],
      [whichField]: inputHandler(inputType, data)
    };
    
    const d = {
      info,
      isBlocking: true
    };
    if(whichField!=='項目'){
      info[+i][whichField] = +info[+i][whichField];
      d.total = this.calcTotal(info);
    }
    try{await this.setStateAsync(d);}catch(err){}
  }

  addItem = () => {
    this.validator.purgeFields();
    this.setStateAsync({
      info: this.state.info.concat({
        '項目': '',
        '預算金額': '',
      }),
      isBlocking: true,
    });
  }

  removeItem = (event) => {
    this.validator.purgeFields();
    const {i} = event.target.closest('button').dataset;
    const info = [...this.state.info];
    info.splice(i,1);
    this.setStateAsync({
      info,
      total: this.calcTotal(info),
      isBlocking: true,
    });
  }

  /* modal toggle */
  saveModalToggle = () => {
    if(!this.state.isSaveModalOpen && !this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    this.setStateAsync({
      isSaveModalOpen: !this.state.isSaveModalOpen
    });
  }

  render() {
    const {
      finishedLoading,
      dimmerOpen,

      year,
      yearId,
      readOnly,

      groupOptions,
      groupId,

      info,
      total,

      isBlocking,
      isSaveModalOpen,
    } = this.state;

    let tableContent = false;
    if(!finishedLoading) tableContent = <EmptyTableMsg colSpan={readOnly?2:3} msg='載入資料中' />;
    else if(!info.length && readOnly) tableContent = <EmptyTableMsg colSpan={2} msg={groupId ? '沒有預算開支項目':'請先選擇組別'} />;

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='組別計劃 - 年度預算' subTitle='為每個組別設定年度預算開支項目' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Segment padded>
                <Form>
                  <Form.Group>
                  <Form.Field inline>
                    <label>周年</label>
                    <MySelect
                      compact
                      disabled={!finishedLoading}
                      options={year}
                      value={yearId}
                      onChange={this.yearChange}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <label>組別</label>
                    <MySelect
                      error={!groupId}
                      disabled={!finishedLoading}
                      options={groupOptions}
                      value={groupId}
                      search
                      onChange={this.groupChange}
                      placeholder={groupOptions.length?"請先選擇組別":"沒有權限"}
                    />
                  </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form>
                <Segment.Group>
                  <Segment>
                    <Table textAlign='center' celled unstackable fixed>
                      <Table.Header>
                        <Table.Row>
                          {this.fields.map(x=>(<Table.HeaderCell width={6} key={x}>{x}</Table.HeaderCell>))}
                          {!readOnly && <Table.HeaderCell width={2} textAlign="center">刪除</Table.HeaderCell>}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {tableContent || info.map((item, i) => (
                          <Table.Row key={i} className='textarea-text'>
                            {this.fields.map((x,j)=>(
                              <Table.Cell textAlign={readOnly?(j?"left":"right"):undefined} key={x}>
                                {readOnly ? item[x] : <FlexibleTextField
                                  fluid
                                  multiline={!j}
                                  type={j?"number":"text"}
                                  readOnly={false}
                                  disabled={false}
                                  ul={true}
                                  value={item[x]||item[x]===0?item[x]:''}
                                  data-i={i}
                                  data-input-type='text'
                                  data-which-field={x}
                                  onChange={this.inputChange}
                                  rows={2}
                                />}
                                {!readOnly && this.validator.message(`${x}${i}`, item, 'required')}
                              </Table.Cell>
                            ))}
                            {!readOnly && (<Table.Cell textAlign="center">
                              <Button
                                color='red'
                                type='button'
                                icon='delete'
                                data-i={i}
                                onClick={this.removeItem}
                                circular
                              />
                            </Table.Cell>)}
                          </Table.Row>
                        ))}
                        {!readOnly && <EmptyTableMsg colSpan={3} msg={<Button
                            color='green'
                            icon='add'
                            type='button'
                            disabled={!finishedLoading}
                            onClick={this.addItem}
                            circular
                          />}
                        />}
                      </Table.Body>
                      {!!groupId && !!finishedLoading && (<Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell textAlign='right'>共計</Table.HeaderCell>
                          <Table.HeaderCell colSpan={readOnly?1:2} textAlign='left'>{total}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>)}
                    </Table>
                  </Segment>
                  {!readOnly && (<Segment>
                    <Form.Field
                      control={Button}
                      color="green"
                      onClick={this.saveModalToggle}
                      disabled={!finishedLoading}
                    >儲存</Form.Field>
                  </Segment>)}
                </Segment.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {dimmerOpen ? (<FullscreenDimmer active={dimmerOpen} isLoading={true} />) : (
          <ConfirmModal
            open={isSaveModalOpen}
            description='確定儲存組別年度預算資料？'
            cancel={this.saveModalToggle}
            confirm={this.save}
            confirmIcon='check'
            confirmText='儲存'
          />
        )}
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(Budget);