/* import react */
import React, { useMemo, useState, useReducer, useEffect, useCallback } from 'react';
import { throttle, get as _get, find } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { useHistory } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  Buttons,
  FullscreenDimmer,
  MySelect,
  MySortableTable,
  ConfirmModal,
  BlockerPrompt,
  FileInputInstantUpload
} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { inputHandler } from '../../Helper/FormHelper';
import { excelFormats } from '../../Const/Const';
import { fileHandling } from '../../Helper/ExcelRead';
import { floatRight } from '../../Helper/StyleHelper';
import { printLayout } from '../../Helper/PrintHelper';
import { convertToColumnTitle } from '../../Helper/ExcelHelper';

import {
  genGradeTemplate,
  genTalentList,
  genTalentECA,
  genStudentList,
  genPercentage,
  genPercentageXLSX,
  checkHasSearchOn,
  renderTalentString,

  stdTests,
  subjects,
  characters,
  nonAcademics,
} from './helper';

import {
  defaultYear,
  selectOptions,
  genStudentOptionName,
  whetherReadOnlyForTeacher,
} from '../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Segment, Form, Accordion, Icon, Table, Button, Checkbox, Modal, Input } from 'semantic-ui-react';

const MyResponsiveBar = ({ data /* see data tab */ }) => (
  <div style={{height: '70vh'}}>
    <ResponsiveBar
      data={data}
      keys={['百分比']}
      indexBy="talentType"
      margin={{ top: 20, right: 0, bottom: 30, left: 45 }}
      minValue={0}
      maxValue={1}
      groupMode="grouped"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      valueFormat=' >-.1%'
      colors={{ scheme: 'category10' }}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      animate={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: v => `${v*100}%`,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [ [ 'brighter', 1.6 ] ] }}
      theme={{
        fontSize: 16,
      }}
      tooltip={({ indexValue, formattedValue, data }) => (
        <div
          style={{
            background: 'white',
            borderRadius: 2,
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
            padding: '5px 9px',
            textAlign: 'center'
          }}
        >
          <span>{indexValue}. {data.name}</span>
          <br />
          <strong>{formattedValue}</strong> ({data.count}人)
        </div>
      )}
    />
  </div>
)

const yearsReducer = (state, data) => {
  const d = Array.isArray(data) ? data : [];
  return {
    array: d,
    options: selectOptions(d, 'displayName', 'id')
  }
}

/**
 * 
 * @param {{stdTest: object, grade: object, char: object, non_academic: object, other_gifted: object, gradeClass: object}} state the unchanged previous state
 * @param {{category: string, field: string, checked: boolean}} data the data
 * @returns 
 */
const filterReducer = (state, data) => {
  const { category, checked } = data;
  switch (category) {
    case 'stdTest':
    case 'grade':
    case 'char':
    case 'non_academic':
    case 'other_gifted':
      return {
        ...state,
        [category]: {
          ...state[category],
          [data.field]: checked
        }
      }
    case 'parent_suggest':
    case 'remark':
      return {
        ...state,
        [category]: checked
      }
    case 'class':
      if (checked) {
        return {
          ...state,
          gradeClass: {
            ...state.gradeClass,
            [data.gradeId]: {
              ...state.gradeClass[data.gradeId],
              [data.classId]: true
            }
          }
        }
      } else {
        const gradeInfo = { ...state.gradeClass[data.gradeId] };
        gradeInfo[data.classId] = false;
        if (Object.values(gradeInfo).some(x => x)) {
          return {
            ...state,
            gradeClass: {
              ...state.gradeClass,
              [data.gradeId]: gradeInfo
            }
          }
        }
        return {
          ...state,
          gradeClass: {
            ...state.gradeClass,
            [data.gradeId]: undefined
          }
        }
      }
    case 'classGrade':
      if (checked) {
        return {
          ...state,
          gradeClass: {
            ...state.gradeClass,
            [data.gradeId]: JSON.parse(data.classes).reduce((prev, cur) => ({ ...prev, [cur]: true }), {})
          }
        }
      } else {
        return {
          ...state,
          gradeClass: {
            ...state.gradeClass,
            [data.gradeId]: undefined
          }
        }
      }
    default: return state;
  }
}

const TalentList = (props) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [dimmerOpen, setDimmer] = useState(false);
  const [isBlocking, setBlocking] = useState(false);
  const [data, setData] = useState([]);
  const [years, setYears] = useReducer(yearsReducer, { array: [], options: [] });
  const [readOnly, setReadOnly] = useState(true);
  const [yearId, setYear] = useState('');
  const [talentTypes, setTypes] = useState([]);
  const [reloadCount, setReload] = useState(0);
  const [grade, setGrade] = useState([]);
  const [classMap, setClassMap] = useState(new Map());
  const [searchFilter, setFilter] = useReducer(filterReducer, {
    stdTest: {},
    grade: {},
    char: {},
    non_academic: {},
    other_gifted: {},
    parent_suggest: false,
    remark: false,
    gradeClass: {},
  });
  const [students, setStudents] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const [andMode, setAndMode] = useState(true);

  const [modalOpen, toggleModal] = useState({ delete: false, edit: false, copy: false, id: null, export: false });
  const [editData, setEditData] = useState({});
  const [gradeToExport, setExportGrade] = useState(null);
  const [gradePercentage, setGradeForPercentage] = useState('全校');
  const [percentageInfo, setPercentageInfo] = useState({
    lengths: {},
    talentLengths: {},
  });

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        const years = await get('getYear', [], abortController);
        setYears(years);
        if (!yearId) {
          const talentTypes = await get('getTalentTypes', [], abortController);
          const grade = await get('getGrade', [], abortController);
          const classArray = await get('getClass', [], abortController);
          const classMap = new Map();
          for (let c of classArray) {
            if (!classMap.has(c.grade)) {
              classMap.set(c.grade, []);
            }
            classMap.get(c.grade).push(c);
          }
          setTypes(talentTypes);
          setGrade(grade);
          setClassMap(classMap);
          setYear(defaultYear(years));
        }
      } catch (err) {
        if (err.name !== 'AbortError')
          console.log(err);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    }
  }, [reloadCount, yearId]);

  useEffect(() => {
    setExportGrade(null);
  }, [grade]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        if (yearId) {
          const students = await get('getActiveStudent', [yearId], abortController);
          const data = await get('getTalentedStudents', [yearId], abortController);
          const isTalentPersonnel = await get('isTalentPersonnel', [], abortController);
          setReadOnly(!_get(isTalentPersonnel, [0, 'c'], false) || whetherReadOnlyForTeacher(years.array, yearId));
          setStudents(students);
          setData(data);
          setLoading(false);
        } else {
          setReadOnly(true);
          setData([]);
        }
        setDimmer(false);
      } catch (err) {
        if (err.name !== 'AbortError')
          console.log(err);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    }
  }, [years, yearId]);

  const studentOptions = useMemo(() => {
    return selectOptions(students, genStudentOptionName, 'id');
  }, [students]);

  const untalentedStudents = useMemo(() => {
    const talentSet = new Set(data.map(x => x.studentId));
    return selectOptions(students.filter(x => !talentSet.has(x.id)), genStudentOptionName, 'id');
  }, [students, data]);

  const filteredData = useMemo(() => {
    const hasSearchOn = checkHasSearchOn(searchFilter);
    if (!hasSearchOn) return data;
    const _gradeClass = Object.values(searchFilter.gradeClass).filter(x => x).reduce((prev, cur) => Object.assign({}, prev, cur), {});
    const correctedGradeClass = Object.keys(_gradeClass).filter(x => _gradeClass[x]).map(x => +x);
    return data.filter(x => {
      if (correctedGradeClass.length && correctedGradeClass.indexOf(x.classId) === -1) {
        return false;
      }
      const stdTestFilters = new Set(Object.keys(searchFilter.stdTest).filter(key => searchFilter.stdTest[key]));
      if (andMode) {
        if (stdTestFilters.size) {
          for (let test of stdTests) {
            if (stdTestFilters.has(test.fieldName) && +x[test.fieldName] < test.threshold) return false;
          }
        }
        for (let item of ['grade']) {
          const keysToCheck = Object.keys(searchFilter[item]).filter(key => searchFilter[item][key]);
          if (keysToCheck.length && keysToCheck.some(key => !x[key])) return false;
        }
        for (let item of ['char']) {
          const keysToCheck = Object.keys(searchFilter[item]).filter(key => searchFilter[item][key]);
          if (keysToCheck.length && keysToCheck.some(key => +x[key] < 4)) return false;
        }
        for (let item of ['non_academic']) {
          const keysToCheck = Object.keys(searchFilter[item]).filter(key => searchFilter[item][key]);
          if (keysToCheck.length && keysToCheck.some(key => !x[item] || x[item].indexOf(`\b${key}\b`) < 0)) return false;
        }
        for (let item of ['other_gifted']) {
          const keysToCheck = Object.keys(searchFilter[item]).filter(key => searchFilter[item][key]).map(x=>+x);
          if (keysToCheck.length && keysToCheck.some(key => !x[item] || x[item].indexOf(key) < 0)) return false;
        }
        if (searchFilter.parent_suggest && !x.parent_suggest) return false;
        if (searchFilter.remark && !x.remark) return false;
        return true;
      } else {
        if (stdTestFilters.size) {
          for (let test of stdTests) {
            if (stdTestFilters.has(test.fieldName) && +x[test.fieldName] >= test.threshold) return true;
          }
        }
        for (let item of ['grade']) {
          const keysToCheck = Object.keys(searchFilter[item]).filter(key => searchFilter[item][key]);
          if (keysToCheck.length && keysToCheck.some(key => x[key])) return true;
        }
        for (let item of ['char']) {
          const keysToCheck = Object.keys(searchFilter[item]).filter(key => searchFilter[item][key]);
          if (keysToCheck.length && keysToCheck.some(key => +x[key] >= 4)) return true;
        }
        for (let item of ['non_academic']) {
          const keysToCheck = Object.keys(searchFilter[item]).filter(key => searchFilter[item][key]);
          if (keysToCheck.length && keysToCheck.some(key => x[item] && x[item].indexOf(`\b${key}\b`) >= 0)) return true;
        }
        for (let item of ['other_gifted']) {
          const keysToCheck = Object.keys(searchFilter[item]).filter(key => searchFilter[item][key]).map(x=>+x);
          if (keysToCheck.length && keysToCheck.some(key => x[item] && x[item].indexOf(key) >= 0)) return true;
        }
        if (searchFilter.parent_suggest && x.parent_suggest) return true;
        if (searchFilter.remark && x.remark) return true;
        return false;
      }
    });
  }, [data, searchFilter, andMode]);

  const throttledInputHandler = throttle((event, data) => {
    switch (data['data-category']) {
      case 'class':
        setFilter({
          category: data['data-category'],
          checked: data.checked,
          gradeId: +data['data-grade-id'],
          classId: +data.name,
        });
        return;
      case 'classGrade':
        setFilter({
          category: data['data-category'],
          checked: data.checked,
          classes: data['data-classes'],
          gradeId: +data.name,
        });
        return;
      default:
        setFilter({
          category: data['data-category'],
          field: Number.isSafeInteger(+data.name) ? +data.name : data.name,
          checked: data.checked,
        });
        return;
    }
  }, 300);

  const tableInfo = useMemo(() => {
    const info = {
      data: [
        { allowSort: true, headerName: 'STRN', width: 2, cellRender: 'STRN' },
        { allowSort: true, sortMethod: 'classId', headerName: '班別', width: 1, cellRender: 'classCode' },
        { allowSort: true, sortMethod: x => +x.classNo, headerName: '學號', width: 1, cellRender: 'classNo' },
        { allowSort: true, sortMethod: x => x.chiName || x.engName, headerName: '姓名', width: 3, cellRender: x => x.chiName || x.engName },
        { headerName: '特質', width: 5, cellRender: x => renderTalentString(x, talentTypes) },
        { headerName: '備註', width: 3, cellRender: 'remark', cellClassName: 'textarea-text' },
      ],
    }
    if (readOnly)
      info.data.push({
        cellClassName: 'textlessCell', headerName: '行動', width: 1, cellRender: x => <Buttons.ViewButton onClick={() => {
          toggleModal(y => ({ ...y, edit: true, id: x.id }))
        }} />
      });
    else
      info.data.push({
        cellClassName: 'textlessCell', headerName: '行動', width: 1, cellRender: x => <>
          <Buttons.EditButton onClick={() => {
            toggleModal(y => ({ ...y, edit: true, id: x.id }))
          }} />
          <Buttons.DeleteButton onClick={() => {
            toggleModal(y => ({ ...y, delete: true, id: x.id }))
          }} />
        </>
      });
    return info;
  }, [isLoading, setData, readOnly, talentTypes, history]);

  const removeTalent = () => {
    const abortController = new AbortController();
    const operation = async () => {
      try {
        setDimmer(true);
        const result = await post('deleteTalentedStudents', { id: modalOpen.id }, 'JSON', abortController);
        if (!result || !result.status) {
          alert("刪除時發生錯誤");
        }
        return () => {
          abortController.abort();
        }
      } catch (err) {
        if (err.name !== 'AbortError')
          console.log(err);
      }
      setDimmer(false);
    };
    operation().then(() => {
      setReload(x => x + 1);
      toggleModal(x => ({ ...x, delete: false, id: null }));
    });
  }

  const copyTalent = useCallback(() => {
    const abortController = new AbortController();
    const operation = async () => {
      try {
        setDimmer(true);
        const result = await post('copyTalentData', { yearId }, 'JSON', abortController);
        if (!result || !result.status) return Promise.reject();
      } catch (err) {
        if (err.name !== 'AbortError')
          console.log(err);
      } finally {
        setDimmer(false);
        toggleModal(x => ({ ...x, copy: false, id: null }));
      }
    };
    operation().then(() => {
      setReload(x => x + 1);
      toggleModal(x => ({ ...x, copy: false, id: null }));
    }).catch(err=>{});
  }, [yearId]);

  const saveTalent = () => {
    const abortController = new AbortController();
    setDimmer(true);
    const operation = async () => {
      try {
        const result = await post('editTalentedStudents', {
          ...editData,
          non_academic: editData.non_academic.size ? `\b${Array.from(editData.non_academic).join("\b")}\b` : '',
          other_gifted: Array.from(editData.other_gifted),
        }, 'JSON', abortController);
        if (_get(result, 'status')) {
          setReload(x => x + 1);
          setBlocking(false);
          toggleModal(x => ({ ...x, edit: false, id: null }));
        }
      } catch (err) {
        if (err.name !== 'AbortError')
          console.log(err);
      }finally {
        setDimmer(false);
      }
    };
    operation();
    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    const _data = find(data, { id: modalOpen.id });
    const editData = _data ? Object.assign({}, _data) : {
      addNew: true,
      studentId: null,
      ...stdTests.reduce((prev, cur) => ({ ...prev, [cur.fieldName]: null }), {}),
      ...subjects.reduce((prev, cur) => ({ ...prev, [cur.fieldName]: null }), {}),
      ...characters.reduce((prev, cur) => ({ ...prev, [cur.fieldName]: null }), {}),
      non_academic: null,
      other_gifted: null,
      parent_suggest: '',
      outside_course: '',
      remark: '',
    };
    if (editData.studentId) {
      editData.originalStudentId = editData.studentId;
      editData.originalName = `${editData.classCode}${editData.classNo < 10 ? "0" : ""}${editData.classNo} ${editData.chiName || editData.engName}`
    }
    for (let prop of ['non_academic']) {
      if (editData[prop] && typeof editData[prop] === 'string') {
        editData[prop] = new Set(editData[prop].split("\b").filter(x => x));
      } else {
        editData[prop] = new Set();
      }
    }
    for (let prop of ['other_gifted']) {
      if (Array.isArray(editData[prop])) {
        try {
          editData[prop] = new Set(editData[prop].filter(x => x));
        } catch (err) {
          editData[prop] = new Set();
        }
      } else {
        editData[prop] = new Set();
      }
    }
    setEditData(editData);
  }, [modalOpen.id, data]);

  const editInputChange = useCallback((event, data) => {
    setBlocking(true);
    setEditData(x => {
      if (data.hasOwnProperty('data-category')) {
        const set = new Set(x[data['data-category']]);
        if (data.checked) {
          set.add(data['data-field-name']);
        } else {
          set.delete(data['data-field-name']);
        }
        return { ...x, [data['data-category']]: set };
      } else {
        return { ...x, [data['data-field-name']]: inputHandler(data.type, data) };
      }
    });
  }, []);

  const customHandling = useCallback((xlsFile) => {
    if (!subjects.length || !grade.length) {
      return { status: false, err: '未定義科目或級別' }
    }
    const studentsMap = new Map(students.map(x => [`${x.classCode}\b${x.classNo}`, x.id]));
    return fileHandling(xlsFile).then(data => {
      try {
        const lastRow = +data['!ref'].match(/\d+$/g);
        const candidates = [];
        const cached = new Map(Array.from({ length: grade.length * subjects.length }).map((x, i) => [i + 5, convertToColumnTitle(i + 5)]));
        for (let i = 3; i <= lastRow; i++) {
          if (!_get(data, ['A' + i, 'v'])) break;
          if (subjects.some((x, j) => {
            return _get(data, [cached.get(data['A' + i].v * subjects.length + j + 1) + i, 'v']);
          })) {
            const grade = data['A' + i].v;
            candidates.push({
              studentId: studentsMap.get(`${data['B' + i].v}\b${data['C' + i].v}`),
              // grade,
              ...subjects.reduce((prev, x, j) => {
                let c = _get(data, [cached.get(grade * subjects.length + j + 1) + i, 'v']);
                c = c ? +c : 0;
                if (c) {
                  return { ...prev, [x.fieldName]: c };
                }
                return { ...prev, [x.fieldName]: null };
              }, {})
            })
          }
        }
        return post('setTalentSubjectGrades', { candidates, yearId }).then((res) => {
          setReload(x => x + 1);
          return res;
        });
      } catch (err) {
        console.log(err);
        return { status: false, err }
      }
    })
  }, [students, grade, yearId]);

  const exportTalentReport = useCallback(() => {
    const abortController = new AbortController();
    const operation = async () => {
      try {
        setDimmer(true);
        const result = await post('exportTalentReport', {
          gradeToExport,
          yearId,
        }, 'TEXT', abortController);
        printLayout(result, '人才庫學生報告', '', () => {
          setReload(x => x + 1);
          toggleModal(x => ({ ...x, export: false, id: null }));
        });
      } catch (err) {
        if (err.name !== 'AbortError')
          console.log(err);
      }
    };
    operation();
    setDimmer(false);
    return () => {
      abortController.abort();
    }
  }, [gradeToExport, yearId])

  return (<>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <PageHeader title='學生人才名單' />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Segment padded>
            <Form disabled>
              <Form.Group className='button-margin-bottom'>
                <Form.Field inline disabled={isLoading}>
                  <label>周年</label>
                  <MySelect
                    options={years.options}
                    value={yearId}
                    onChange={(event, data) => {
                      setLoading(true);
                      setYear(inputHandler('select', data))
                    }}
                  />
                </Form.Field>
                <Button
                  color='purple'
                  disabled={readOnly}
                  content='複製上年的資料'
                  title='複製上年的資料'
                  icon='copy'
                  onClick={() => {
                    toggleModal(x => ({ ...x, copy: true }));
                  }}
                  circular
                />
                <FileInputInstantUpload
                  disabled={readOnly}
                  buttonColor='green'
                  buttonText='上載全級排名首數位'
                  formatErrorText='請上載正確格式(xlsx)'
                  accept={excelFormats}
                  wrapperStyle={floatRight}
                  fileHandling={customHandling}
                />
                <Buttons.DownloadButton
                  content="下載全級排名樣板"
                  color='orange'
                  disabled={isLoading || readOnly}
                  onClick={() => {
                    setDimmer(true);
                    genGradeTemplate({ data: students, years: years.array, yearId, grade }).then(() => {
                      setDimmer(false);
                    })
                  }}
                />
                <Buttons.AddButton
                  content="新增人才"
                  disabled={readOnly}
                  onClick={() => {
                    toggleModal(x => ({ ...x, edit: true }));
                  }}
                />
              </Form.Group>
              <Form.Group className='button-margin-bottom'>
                <Buttons.DownloadButton
                  content='匯出人才庫總表(不包括增潤課程及比賽部分)'
                  title='匯出人才庫總表(不包括增潤課程及比賽部分)'
                  disabled={isLoading || readOnly}
                  onClick={() => {
                    setDimmer(true);
                    genTalentList({ data, years: years.array, yearId, talentTypes }).then(() => {
                      setDimmer(false);
                    })
                  }}
                />
                <Buttons.DownloadButton
                  content='匯出人才庫增潤課程及比賽部分'
                  title='匯出人才庫增潤課程及比賽部分'
                  disabled={isLoading}
                  onClick={() => {
                    setDimmer(true);
                    genTalentECA({ data, years: years.array, yearId }).then(() => {
                      setDimmer(false);
                    })
                  }}
                />
                <Buttons.DownloadButton
                  content='匯出學生名單'
                  title='匯出學生名單'
                  disabled={isLoading}
                  onClick={() => {
                    setDimmer(true);
                    genStudentList({ filteredData, grade, classMap, years: years.array, yearId, talentTypes, searchFilter, andMode }).then(() => {
                      setDimmer(false);
                    })
                  }}
                />
                <Buttons.DownloadButton
                  content='匯出學生報告'
                  title='匯出學生報告'
                  disabled={isLoading || readOnly}
                  onClick={() => {
                    toggleModal(x => ({ ...x, export: true, id: null }))
                  }}
                />
                <Buttons.DownloadButton
                  icon='eye'
                  content='檢視百分比'
                  title='檢視百分比'
                  disabled={isLoading || readOnly}
                  onClick={() => {
                    setPercentageInfo(genPercentage(data, grade, talentTypes, students));
                    toggleModal(x => ({ ...x, percentage: true, id: null }))
                  }}
                />
              </Form.Group>
              <Accordion fluid>
                <Accordion.Title active={showFilter} onClick={() => { setShowFilter(x => !x) }} className='ui talent_accordion'><strong>{showFilter ? '隱藏' : '展開'}篩選器 (預設顯示所有人才)</strong><Icon name='dropdown' /></Accordion.Title>
                <Accordion.Content active={showFilter}>
                  <Form.Group inline className='talent_search_field'>
                    <Table celled fixed>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>級別</Table.HeaderCell>
                          {grade.map(x => <Table.HeaderCell key={x.id}><Form.Checkbox
                            disabled={isLoading}
                            data-category='classGrade'
                            data-classes={JSON.stringify(classMap.has(x.id) ? classMap.get(x.id).map(x => x.id) : [])}
                            name={"" + x.id}
                            label={x.chiName || x.engName}
                            checked={!!searchFilter.gradeClass[x.id]}
                            onChange={throttledInputHandler}
                          /></Table.HeaderCell>)}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>班別</Table.Cell>
                          {grade.map(x => <Table.Cell key={x.id}>{classMap.has(x.id) && searchFilter.gradeClass[x.id] ? classMap.get(x.id).map(x => <Form.Checkbox
                            key={x.id}
                            disabled={isLoading}
                            data-category='class'
                            data-grade-id={x.grade}
                            name={"" + x.id}
                            label={x.name}
                            checked={!!_get(searchFilter, ['gradeClass', x.grade, x.id], false)}
                            onChange={throttledInputHandler}
                          />) : '-'}</Table.Cell>)}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Form.Group>
                  <Form.Group inline className='talent_search_field'>
                    <Form.Radio
                      label='符合下列所有標準'
                      name='andMode'
                      value='1'
                      disabled={isLoading}
                      checked={andMode}
                      onChange={(event, data) => {
                        setAndMode(true);
                      }}
                    />
                    <Form.Radio
                      label='符合下列任一標準'
                      name='andMode'
                      value='0'
                      disabled={isLoading}
                      checked={!andMode}
                      onChange={(event, data) => {
                        setAndMode(false);
                      }}
                    />
                  </Form.Group>
                  <Form.Group inline className='talent_search_field'>
                    <Form.Field>
                      <label>1. 標準化測驗</label>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline className='margin-left-1rem talent_search_field'>
                    <Form.Field>
                      <Checkbox
                        disabled={isLoading}
                        data-category='stdTest'
                        name='iq'
                        checked={!!searchFilter.stdTest.iq}
                        onChange={throttledInputHandler}
                      />
                      <label>1.1 智能 (特優<Icon name='check' />：130或以上；優異<Icon name='check' />：120-129；中上<Icon name='check' />：110-119)</label>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline className='margin-left-1rem talent_search_field'>
                    <Form.Field>
                      <Checkbox
                        disabled={isLoading}
                        data-category='stdTest'
                        name='creativity'
                        checked={!!searchFilter.stdTest.creativity}
                        onChange={throttledInputHandler}
                      />
                      <label>1.2 創造力 (強<Icon name='check' />：11分或以上；一般優異：4-10；弱：1-3)</label>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline className='talent_search_field'>
                    <Form.Field>
                      <label>2. (上年) 校內成績表現</label>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline className='margin-left-1rem talent_search_field'>
                    <Form.Field>
                      <label>2.1 校內成績表現</label>
                    </Form.Field>
                    {subjects.map((subject, i) => (
                      <Form.Checkbox
                        disabled={isLoading}
                        key={subject.fieldName}
                        data-category='grade'
                        name={subject.fieldName}
                        label={`2.1.${i + 1} ${subject.displayName}`}
                        checked={!!searchFilter.grade[subject.fieldName]}
                        onChange={throttledInputHandler}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group inline className='talent_search_field'>
                    <Form.Field>
                      <label>3. 教師推薦</label>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline className='margin-left-1rem talent_search_field'>
                    <Form.Field>
                      <label>3.1 資優生行為特質問卷</label>
                    </Form.Field>
                    {characters.map((character, i) => (
                      <Form.Checkbox
                        disabled={isLoading}
                        key={character.fieldName}
                        data-category='char'
                        name={character.fieldName}
                        label={`3.1.${i + 1} ${character.displayName}`}
                        checked={!!searchFilter.char[character.fieldName]}
                        onChange={throttledInputHandler}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group inline className='margin-left-1rem talent_search_field'>
                    <Form.Field>
                      <label>3.2 非學科</label>
                    </Form.Field>
                    {nonAcademics.map((nonAcad, i) => (
                      <Form.Checkbox
                        disabled={isLoading}
                        key={nonAcad}
                        data-category='non_academic'
                        name={nonAcad}
                        checked={!!searchFilter.non_academic[nonAcad]}
                        onChange={throttledInputHandler}
                        label={`3.2.${i + 1} ${nonAcad}`}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group inline className='margin-left-1rem talent_search_field'>
                    <Form.Field>
                      <label>3.3 資優特質</label>
                    </Form.Field>
                    {talentTypes.map((type, i) => (
                      <Form.Checkbox
                        disabled={isLoading}
                        key={type.id}
                        data-category='other_gifted'
                        name={"" + type.id}
                        checked={!!searchFilter.other_gifted[type.id]}
                        onChange={throttledInputHandler}
                        label={`3.3.${i + 1} ${type.name}`}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group inline className='margin-left-1rem talent_search_field'>
                    <Form.Field>
                      <label>3.4 家長推薦</label>
                    </Form.Field>
                    <Form.Checkbox
                      disabled={isLoading}
                      data-category='parent_suggest'
                      name='parent_suggest'
                      checked={!!searchFilter.parent_suggest}
                      onChange={throttledInputHandler}
                      label='有'
                    />
                  </Form.Group>
                  <Form.Group inline className='margin-left-1rem talent_search_field'>
                    <Form.Field>
                      <label>3.5 備註</label>
                    </Form.Field>
                    <Form.Checkbox
                      disabled={isLoading}
                      data-category='remark'
                      name='remark'
                      checked={!!searchFilter.remark}
                      onChange={throttledInputHandler}
                      label='有'
                    />
                  </Form.Group>
                </Accordion.Content>
              </Accordion>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <MySortableTable
            tableColumnData={tableInfo.data}
            data={filteredData}
            finishedLoading={!isLoading}
            useIndex
            footer
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    {dimmerOpen?<FullscreenDimmer active={dimmerOpen} isLoading={true} /> : (<><Modal
      size='large'
      open={modalOpen.edit}
      onClose={() => { toggleModal(x => ({ ...x, edit: false, id: null })) }}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>編輯人才資料</Modal.Header>
      <Modal.Content>
        <Segment basic>
          <Form>
            <Form.Group className='form-group-margin'>
              <Form.Field required inline>
                <label>學生</label>
                <MySelect
                  search
                  disabled={readOnly}
                  placeholder='搜尋學生...'
                  value={_get(editData, ['studentId']) || ''}
                  options={_get(editData, 'addNew') ? untalentedStudents : studentOptions}
                  data-field-name='studentId'
                  type='select'
                  onChange={editInputChange}
                />
                {editData.originalStudentId && editData.originalStudentId !== editData.studentId && <span className='red margin-left-1rem'>已改變學生 (本來是 {editData.originalName})</span>}
              </Form.Field>
            </Form.Group>
            <Form.Group><Form.Field><label>1. 標準化測驗</label></Form.Field></Form.Group>
            <Form.Group className='form-group-margin'>{stdTests.map(({ fieldName, displayName }, i) => (
              <Form.Input
                key={fieldName}
                disabled={readOnly}
                inline
                type='number'
                data-field-name={fieldName}
                value={_get(editData, [fieldName]) || ''}
                onChange={editInputChange}
                label={`1.${i + 1} ${displayName}`}
              />
            ))}</Form.Group>
            <Form.Group><Form.Field><label>2. 校內成績表現</label></Form.Field></Form.Group>
            <Form.Group className='form-group-margin' widths='equal'>{subjects.map(({ fieldName, displayName }, i) => (
              <Form.Field key={fieldName} inline>
                <label>2.{i + 1} {displayName}</label>
                <Input
                  disabled={readOnly}
                  className='number-field'
                  type='number'
                  data-field-name={fieldName}
                  value={_get(editData, [fieldName]) || ''}
                  onChange={editInputChange}
                />
              </Form.Field>
            ))}</Form.Group>
            <Form.Group><Form.Field><label>3. 教師推薦</label></Form.Field></Form.Group>
            <Form.Group inline className='margin-left-1rem talent_search_field'><Form.Field><label>3.1 資優生行為特質問卷 (用戶可以儲存所有值，惟4或以上才會計算)</label></Form.Field></Form.Group>
            <div className='margin-left-1rem'>
              <Form.Group inline className='margin-left-1rem talent_search_field'>
                {characters.map(({ fieldName, displayName }, i) => (
                  <Form.Field key={fieldName} inline>
                    <label>3.1.{i + 1} {displayName}</label>
                    <Input
                      disabled={readOnly}
                      className='number-field'
                      type='number'
                      data-field-name={fieldName}
                      value={_get(editData, [fieldName]) || ''}
                      onChange={editInputChange}
                    />
                  </Form.Field>
                ))}
              </Form.Group>
            </div>
            <Form.Group inline className='margin-left-1rem talent_search_field'>
              <Form.Field>
                <label>3.2 非學科</label>
              </Form.Field>
              {nonAcademics.map((name, i) => (
                <Form.Checkbox
                  disabled={readOnly}
                  key={name}
                  inline
                  type='checkbox'
                  data-category='non_academic'
                  data-field-name={name}
                  checked={(_get(editData, ['non_academic']) || new Set()).has(name)}
                  onChange={editInputChange}
                  label={`3.2.${i + 1} ${name}`}
                />
              ))}
            </Form.Group>
            <Form.Group inline className='margin-left-1rem talent_search_field'>
              <Form.Field>
                <label>3.3 資優特質</label>
              </Form.Field>
              {talentTypes.map((type, i) => (
                <Form.Checkbox
                  key={type.id}
                  disabled={readOnly}
                  name='other_gifted'
                  data-category='other_gifted'
                  data-field-name={type.id}
                  checked={(_get(editData, ['other_gifted']) || new Set()).has(type.id)}
                  onChange={editInputChange}
                  label={`3.3.${i + 1} ${type.name}`}
                />
              ))}
            </Form.Group>
            <Form.Group inline className='margin-left-1rem form-group-margin'>
              <Form.TextArea
                width={16}
                label='3.4 家長推薦'
                name='parent_suggest'
                disabled={readOnly}
                data-field-name='parent_suggest'
                value={_get(editData, ['parent_suggest']) || ''}
                onChange={editInputChange}
                type='text'
              />
            </Form.Group>
            <Form.Group className='talent_search_field'>
              <Form.TextArea
                width={16}
                name='outside_course'
                data-field-name='outside_course'
                disabled={readOnly}
                value={_get(editData, ['outside_course']) || ''}
                onChange={editInputChange}
                label='5B. 校外增潤課程 (每行一個課程、無需加B字)'
                type='text'
              />
            </Form.Group>
            <Form.Group className='talent_search_field'>
              <Form.TextArea
                width={16}
                name='remark'
                disabled={readOnly}
                data-field-name='remark'
                value={_get(editData, ['remark']) || ''}
                onChange={editInputChange}
                label='備註'
                type='text'
              />
            </Form.Group>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Buttons.CancelButton onClick={() => { toggleModal(x => ({ ...x, edit: false, id: null })) }} />
        <Buttons.SaveButton disabled={!editData.studentId||readOnly} onClick={saveTalent} />
      </Modal.Actions>
    </Modal>
    <Modal
      open={modalOpen.export}
      onClose={() => { toggleModal(x => ({ ...x, export: false, id: null })) }}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>匯出學生報告 - 選擇級別</Modal.Header>
      <Modal.Content>
        <Segment basic>
          <Form>
            <Form.Group inline className='margin-left-1rem talent_search_field'>
              {grade.map(g => (
                <Form.Radio
                  key={g.id}
                  name='grade_export'
                  value={g.id}
                  checked={gradeToExport === g.id}
                  onChange={(event, data) => {
                    setExportGrade(data.value)
                  }}
                  label={g.chiName}
                />
              ))}
            </Form.Group>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Buttons.CancelButton onClick={() => { toggleModal(x => ({ ...x, export: false, id: null })) }} />
        <Buttons.SaveButton onClick={exportTalentReport} />
      </Modal.Actions>
    </Modal>
    <Modal
      closeIcon={true}
      open={modalOpen.percentage}
      onClose={() => { toggleModal(x => ({ ...x, percentage: false })) }}
      closeOnEscape={true}
      closeOnDimmerClick={true}
    >
      <Modal.Header>
        {"人才百分比 - "}
        <MySelect
          placeholder='年級'
          value={gradePercentage}
          options={[{value: '全校', text: '全校'}].concat(grade.map(x=>({
            text: x.chiName,
            value: x.id,
          })))}
          onChange={(event, data) => {
            setGradeForPercentage(inputHandler('select', data))
          }}
        />
        <Buttons.DownloadButton
          content='下載 XLSX 總表'
          onClick={() => {
            setDimmer(true);
            genPercentageXLSX({ data, grade, years, yearId, talentTypes, students }).then(() => {
              setDimmer(false);
            })
          }}
        />

      </Modal.Header>
      <Modal.Content>
        <Segment basic>
          <MyResponsiveBar data={
            talentTypes.map((x,i)=>({
              talentType: '3.3.'+(i+1),
              name: x.name,
              count: _get(percentageInfo, ['talentLengths', gradePercentage, x.id], 0),
              "百分比": Object.keys(percentageInfo.lengths).length ? _get(percentageInfo, ['talentLengths', gradePercentage, x.id], 0) / _get(percentageInfo, ['lengths', gradePercentage], 0) : 0,
            }))
          } />
        </Segment>
      </Modal.Content>
    </Modal>
    <ConfirmModal
      open={modalOpen.delete}
      description={<>確定刪除<span className="red">{editData && (editData.chiName || editData.engName)}</span>的人才資料？本操作不可逆轉！</>}
      cancel={() => { toggleModal(x => ({ ...x, delete: false, id: null })) }}
      confirm={removeTalent}
    />
    <ConfirmModal
      open={modalOpen.copy}
      description='確定將本年度的人才資料以去年資料覆蓋？本操作不可逆轉！'
      confirmIcon='copy'
      confirmText='確定'
      cancel={() => { toggleModal(x => ({ ...x, copy: false, id: null })) }}
      confirm={copyTalent}
    /></>)}
    <BlockerPrompt isBlocking={isBlocking} />
  </>)
}

export default TalentList;