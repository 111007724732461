/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	FullscreenDimmer,
	BlockerPrompt,
	MySelect,
  Buttons
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { selectOptions, getTeacherOptions } from '../../../Helper/Helper';

/* import lodash */
import { map, pick } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Segment, Form, TextArea, Checkbox } from 'semantic-ui-react';

/* import const */
import { fridayActivityTypeOptions, gradeOptions } from '../../../Const/Const';

class FridayActivityEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: true,
      isBlocking: false,
      fridayActivityTypeOptions,
      teacherOptions: [],
      locationOptions: [],

      fridayActivityId: this.props.match.params.fridayActivityId || null,
      fridayActivity: {
        id: this.props.match.params.fridayActivityId || null,
        yearId: this.props.match.params.yearId,
        type: 'special',
        code: null,
        name: null,
        locationId: null,
        secondaryLocationId: null,
        remark: null,
        equipment: null,
        material: null,
        budget: null,
        item: null,
        target: null,
        plan: null,
        outcome: null,
        method: null,
      },
      fridayActivityTeachers: [],
    }

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => <ErrorLabel message={message} />,
      messages: {
        required: '請輸入資料',
        min: '請輸入最少1位教師',
        accepted: '專項小組時編號是數字，普通小組時編號是字母'
      }
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchFridayActivityData = async () => {
    const {fridayActivityId} = this.props.match.params;
    try{
      const [fridayActivity, fridayActivityTeachers] = await Promise.all([
        get('getOneFridayActivity/' + fridayActivityId),
        get('getFridayActivityTeacher/' + fridayActivityId)
      ]);
      await this.setStateAsync({
        fridayActivity: fridayActivity[0] || {},
        fridayActivityTeachers: map(fridayActivityTeachers, 'teacherId'),
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity', err)}
  }

  fetchOptions = async () => {
    try{
      const [teacherOptions, locations] = await Promise.all([
        getTeacherOptions(this.props.match.params.yearId),
        get('getLocation'),
      ]);

      teacherOptions.push({text: "外聘導師", value: null});

      await this.setStateAsync({
        teacherOptions,
        locationOptions: selectOptions(locations, 'name', 'id')
      });
    }catch(err){if(err!=='unmounted')console.log('Error when fetching friday activity', err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    if (this.props.match.params.fridayActivityId) {
      await this.fetchFridayActivityData();
    }
    await this.fetchOptions();
    this.setStateAsync({ dimmerOpen: false });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  fridayActivityChange = (event, data) => {
    const {inputType, stateName} = event.target.closest('.ui,textarea').dataset;
    let value = inputHandler(inputType, data);
    if( value==="" && inputType==="select" ){
      value = null;
    }
    if( value === 'special' && this.state.fridayActivity.type !== 'special'){
      this.setStateAsync({
        isBlocking: true,
        fridayActivity: {
          ...this.state.fridayActivity,
          [stateName]: value,
          code: null,
        }
      });
      return;
    }
    this.setStateAsync({
      isBlocking: true,
      fridayActivity: {
        ...this.state.fridayActivity,
        [stateName]: value
      }
    });
  }

  fridayActivityTeacherChange = (event, data) => {
    let fridayActivityTeachers = inputHandler('select', data);
    this.setStateAsync({ fridayActivityTeachers, isBlocking: true });
  }

  return = () => {
    if (this.state.fridayActivityId) {
      this.props.history.push('/eca/fridayActivity/' + this.props.match.params.section + '/detail/' + this.state.fridayActivity.type + '/' + this.props.match.params.yearId + '/' + this.state.fridayActivityId);
    } else {
      this.props.history.push('/eca/fridayActivity/' + this.props.match.params.section);
    }
  }

  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    try{
      const data = pick(this.state, ['fridayActivityId', 'fridayActivity', 'fridayActivityTeachers']);

      await this.setStateAsync({ dimmerOpen: true, isBlocking: false });
      const result = await post('editFridayActivity', data);
      if (result && result.status) {
        await this.setStateAsync({ fridayActivityId: result.fridayActivityId });
        this.return();
        return;
      }
      throw result;
    }catch(err){
      if(err!=='unmounted'){
        alert('儲存週五興趣小組時發生錯誤');
        this.setStateAsync({ dimmerOpen: false });
        console.log('Error when saving Friday Activity', err);
      }
    }
  }

  render() {
    const {
      dimmerOpen,
      fridayActivityTypeOptions,
      teacherOptions,
      locationOptions,
      fridayActivity,
      fridayActivityTeachers,
      isBlocking,
    } = this.state;

    this.validator.purgeFields();

    return (
      <>
        {dimmerOpen && <FullscreenDimmer active={dimmerOpen} isLoading={true} /> }
          <Grid stackable doubling>
            <Grid.Row>
              <Grid.Column>
                <PageHeader title='編輯週五興趣小組' />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Form>
                  <Segment.Group className='form-group-margin'>
                    <Segment className='bold' size='big'>基本資料</Segment>
                    <Segment secondary className='form-padding'>
                      <Form.Group widths='equal' className='form-group-margin'>
                        <Form.Field
                          control={MySelect}
                          required
                          onChange={this.fridayActivityChange}
                          data-input-type='select'
                          data-state-name='type'
                          value={fridayActivity.type || ''}
                          options={fridayActivityTypeOptions}
                          disabled={this.props.match.params.section==='personal'}
                          label='類型'
                          placeholder='類型'
                          error={this.validator.message('type', fridayActivity.type, 'required')}
                          noResultsMessage="找不到類型"
                        />
                        <Form.Input
                          type='number'
                          required
                          onChange={this.fridayActivityChange}
                          data-input-type='number'
                          data-state-name='code'
                          value={fridayActivity.code || ''}
                          disabled={this.props.match.params.section==='personal'}
                          label='編號'
                          placeholder='編號'
                          error={this.validator.message('code', fridayActivity.code, 'required')}
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          type='text'
                          required
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='name'
                          value={fridayActivity.name || ''}
                          disabled={this.props.match.params.section==='personal'}
                          label='小組名稱'
                          placeholder='小組名稱'
                          error={this.validator.message('name', fridayActivity.name, 'required')}
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={MySelect}
                          fluid
                          multiple
                          search
                          required
                          disabled={this.props.match.params.section==='personal'}
                          onChange={this.fridayActivityTeacherChange}
                          value={fridayActivityTeachers}
                          options={teacherOptions}
                          noResultsMessage="找不到老師"
                          label='負責老師'
                          placeholder='負責老師'
                          error={this.validator.message('fridayActivityTeachers', fridayActivityTeachers.length, 'required|min:1,num')}
                        />
                      </Form.Group>
                      <Form.Group widths='equal' className='form-group-margin'>
                        <Form.Field required>
                          <label>年級</label>
                        </Form.Field>
                        {gradeOptions.map(({text, value})=>(
                          <Form.Field key={value}>
                            <label data-i={value}>
                              <Checkbox
                                checked={!!fridayActivity['grade_'+value]}
                                data-input-type='checkbox'
                                data-state-name={'grade_'+value}
                                onChange={this.fridayActivityChange}
                                className='narrow'
                              />
                              {text}
                            </label>
                          </Form.Field>
                        ))}
                      </Form.Group>
                    </Segment>
                    <Segment secondary className='form-padding'>
                      {/* <Form.Group widths='equal'>
                        <Form.Field
                          className='form-group-margin'
                          required
                          control={MySelect}
                          clearable
                          disabled={this.props.match.params.section==='personal'}
                          onChange={this.fridayActivityChange}
                          data-input-type='select'
                          data-state-name='minGrade'
                          value={fridayActivity.minGrade || ''}
                          options={gradeOptions}
                          label='適合最小年級'
                          placeholder='最小'
                          error={this.validator.message('fridayActivityMinGrade', fridayActivity.minGrade, 'required')}
                        />
                        <Form.Field
                          className='form-group-margin'
                          required
                          control={MySelect}
                          clearable
                          disabled={this.props.match.params.section==='personal'}
                          onChange={this.fridayActivityChange}
                          data-input-type='select'
                          data-state-name='maxGrade'
                          value={fridayActivity.maxGrade || ''}
                          options={gradeOptions}
                          label='適合最大年級'
                          placeholder='最大'
                          error={this.validator.message('fridayActivityMaxGrade', fridayActivity.maxGrade, 'required')}
                        />
                      </Form.Group> */}
                      {fridayActivity.type === 'special' && (
                        <Form.Group widths='equal'>
                          <Form.Field
                            className='form-group-margin'
                            control={MySelect}
                            required
                            clearable
                            disabled={this.props.match.params.section === 'personal'}
                            onChange={this.fridayActivityChange}
                            data-input-type='select'
                            data-state-name='locationId'
                            value={fridayActivity.locationId || ''}
                            options={locationOptions}
                            search
                            label='地點'
                            placeholder='地點'
                            noResultsMessage="找不到地點"
                            error={this.validator.message('fridayActivityLocation', fridayActivity.locationId, 'required')}
                          />
                          <Form.Field
                            className='form-group-margin'
                            control={MySelect}
                            clearable
                            disabled={this.props.match.params.section === 'personal'}
                            onChange={this.fridayActivityChange}
                            data-input-type='select'
                            data-state-name='secondaryLocationId'
                            value={fridayActivity.secondaryLocationId || ''}
                            options={locationOptions}
                            search
                            label='第二地點'
                            placeholder='第二地點'
                            noResultsMessage="找不到地點"
                          />
                        </Form.Group>
                      )}
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={TextArea}
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='remark'
                          value={fridayActivity.remark || ''}
                          label='備註欄'
                          placeholder='備註欄'
                        />
                      </Form.Group>
                    </Segment>
                  </Segment.Group>

                  <Segment.Group className='form-group-margin'>
                    <Segment className='bold' size='big'>計劃表</Segment>
                    <Segment secondary className='form-padding'>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={TextArea}
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='equipment'
                          value={fridayActivity.equipment || ''}
                          label='所需器材'
                          placeholder='所需器材'
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={TextArea}
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='material'
                          value={fridayActivity.material || ''}
                          label='學習材料'
                          placeholder='學習材料'
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={TextArea}
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='budget'
                          value={fridayActivity.budget || ''}
                          label='財政預算'
                          placeholder='財政預算'
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={TextArea}
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='item'
                          value={fridayActivity.item || ''}
                          label='配合之關注事項'
                          placeholder='配合之關注事項'
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={TextArea}
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='target'
                          value={fridayActivity.target || ''}
                          label='教學目標'
                          placeholder='教學目標'
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={TextArea}
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='plan'
                          value={fridayActivity.plan || ''}
                          label='教學計劃'
                          placeholder='教學計劃'
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={TextArea}
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='outcome'
                          value={fridayActivity.outcome || ''}
                          label='預計成效'
                          placeholder='預計成效'
                        />
                      </Form.Group>
                      <Form.Group widths='equal'>
                        <Form.Input
                          className='form-group-margin'
                          control={TextArea}
                          onChange={this.fridayActivityChange}
                          data-input-type='text'
                          data-state-name='method'
                          value={fridayActivity.method || ''}
                          label='評估方法'
                          placeholder='評估方法'
                        />
                      </Form.Group>
                    </Segment>
                  </Segment.Group>

                  <div className='text-center'>
                    <Buttons.CancelButton onClick={this.return} />
                    <Buttons.SaveButton onClick={this.save} />
                  </div>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(FridayActivityEdit);