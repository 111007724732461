/* import lodash */
import {find} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	FullscreenDimmer,
} from '../../Components';

/* import helper functions */
import { get, doc } from '../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../Helper/Helper';
import { inputHandler } from '../../Helper/FormHelper';


/* import semantic-ui element */
import { Grid, Form, Button, Segment, Select, Icon } from 'semantic-ui-react';

class SurveyDesign extends Component {
  constructor(props) {
    super(props);

    this.state = {

      finishedLoading: false,
      dimmerOpen: false,

      yearInfo: [],
      year: [],
      yearId: '',

      scope: '1year',
      
      teacherGroupOptions: [],
      selectedTeacherGroup: '',
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
    
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log("Error when mounting ExportFile");
      console.log(err);
    }
  }

  fetch = async () => {
    try{
      const [yearInfo, teacherGroups] = await Promise.all([
        get('getYear'),
        get('getGroup')
      ]);
      
      const yearId = defaultYear(yearInfo);

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId,

        teacherGroupOptions: selectOptions(teacherGroups, 'name', 'id'),
        selectedTeacherGroup: teacherGroups.length? teacherGroups[0].id : '',
      });

    }catch(err){
      console.log("Error when fetching ExportFile");
      console.log(err);
    }
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({
      [stateName]: value,
    })
  }

  exportReport = async () => {
    const {yearInfo, yearId} = this.state;
    const theYear = find(yearInfo, {id: +yearId});
    if(theYear){
      await doc('generate/1YearReport', {yearId}, `學校周年報告 (${theYear.displayName})`);
    }
  }

  exportSchoolMidReport = async () => {
    const {yearInfo, yearId} = this.state;
    const theYear = find(yearInfo, {id: +yearId});
    if(theYear){
      await doc('generate/SchoolMidReport', {
        yearId,
      }, `學校周年中期報告 (${theYear.displayName})`);
    }
  }

  exportGroupMidReport = async () => {
    const {yearInfo, yearId, teacherGroupOptions, selectedTeacherGroup} = this.state;
    const theYear = find(yearInfo, {id: +yearId});
    const theGroup = find(teacherGroupOptions, {value: +selectedTeacherGroup});
    if(theYear && theGroup){
      await doc('generate/GroupMidReport', {
        yearId,
        groupId: selectedTeacherGroup,
      }, `${theGroup.text}中期報告 (${theYear.displayName})`);
    }
  }

  exportSchoolSummary = async () => {
    const {yearInfo, yearId} = this.state;
    const theYear = find(yearInfo, {id: +yearId});
    if(theYear){
      await doc('generate/SchoolSummary', {
        yearId,
      }, `學校周年總結檢討 (${theYear.displayName})`);
    }
  }

  exportGroupSummary = async () => {
    const {yearInfo, yearId, teacherGroupOptions, selectedTeacherGroup} = this.state;
    const theYear = find(yearInfo, {id: +yearId});
    const theGroup = find(teacherGroupOptions, {value: +selectedTeacherGroup});
    if(theYear && theGroup){
      await doc('generate/GroupSummary', {
        yearId,
        groupId: selectedTeacherGroup,
      }, `${theGroup.text}總結檢討 (${theYear.displayName})`);
    }
  }

  theAction = () => {
    const {scope} = this.state;
    switch(scope){
      case 'groupmid': this.exportGroupMidReport(); return;
      case 'schoolmid': this.exportSchoolMidReport(); return;
      case 'groupend': this.exportGroupSummary(); return;
      case 'schoolend': this.exportSchoolSummary(); return;
      default: this.exportReport(); return;
    }
  }

  render() {
    const {
      year,
      yearId,

      scope,
      teacherGroupOptions,
      selectedTeacherGroup,

      finishedLoading,
      dimmerOpen
    } = this.state;

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='匯出周年或中期報告' />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment padded='very'>
                <Form>
                  <Form.Group className='form-group-margin' inline>
                    <Form.Field disabled={!finishedLoading}>
                      <Form.Checkbox
                        radio
                        label='周年報告'
                        name='scope'
                        value='1year'
                        checked={scope === '1year'}
                        onChange={this.inputChange('select', 'scope')}
                      />
                    </Form.Field>
                    <Form.Field disabled={!finishedLoading}>
                      <Form.Checkbox
                        radio
                        label='學校中期報告'
                        name='scope'
                        value='schoolmid'
                        checked={scope === 'schoolmid'}
                        onChange={this.inputChange('select', 'scope')}
                      />
                    </Form.Field>
                    <Form.Field disabled={!finishedLoading}>
                      <Form.Checkbox
                        radio
                        label='科組中期報告'
                        name='scope'
                        value='groupmid'
                        checked={scope === 'groupmid'}
                        onChange={this.inputChange('select', 'scope')}
                      />
                    </Form.Field>
                    <Form.Field disabled={!finishedLoading}>
                      <Form.Checkbox
                        radio
                        label='學校總結報告'
                        name='scope'
                        value='schoolend'
                        checked={scope === 'schoolend'}
                        onChange={this.inputChange('select', 'scope')}
                      />
                    </Form.Field>
                    <Form.Field disabled={!finishedLoading}>
                      <Form.Checkbox
                        radio
                        label='科組總結報告'
                        name='scope'
                        value='groupend'
                        checked={scope === 'groupend'}
                        onChange={this.inputChange('select', 'scope')}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field inline>
                      <label>年份</label>
                      <Select
                        disabled={!finishedLoading}
                        options={year}
                        value={yearId}
                        onChange={this.inputChange('select', 'yearId')}
                      />
                    </Form.Field>
                    {scope.slice(0,5)==='group' && (
                      <Form.Field inline disabled={!finishedLoading}>
                        <label>科組</label>
                        <Select
                          disabled={!finishedLoading}
                          options={teacherGroupOptions}
                          value={selectedTeacherGroup}
                          float="right"
                          onChange={this.inputChange('select', 'selectedTeacherGroup')}
                        />
                      </Form.Field>
                    )}
                    <Form.Field disabled={!finishedLoading}>
                      <Button
                        color='green'
                        circular
                        onClick={this.theAction}
                      >
                        <Icon name='file alternate'/>
                        匯出<span style={{color:"#ff0"}}>{scope.indexOf('group')>-1&&'科組'}{scope.indexOf('school')>-1&&'學校'}{scope.indexOf('mid')>-1 && '中期'}{scope.indexOf('end')>-1 && '總結'}{scope==='1year'&&'周年'}</span>報告
                      </Button>
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {(!!dimmerOpen)&&(<FullscreenDimmer active={true} isLoading={true} content="系統正在生成DOCX檔。請稍候。" />)}
      </>
    )
  }
}

export default withRouter(SurveyDesign);