import Summary from './Summary';
import Personal from './Personal';
import Edit from './Edit';
import Detail from './Detail';
import Attendance from './Attendance';
import Report from './Report';

import NormalGroup from './NormalGroup';
import Lesson from './Lesson';
import Student from './Student';

export default {
  Summary,
  Personal,
  Edit,
  Detail,
  Attendance,
  Report,
  NormalGroup,
  Lesson,
  Student,
}