/* import lodash */
import {keyBy, flatten, find, groupBy, orderBy, pick, get as _get} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	ConfirmModal,
	EmptyTableMsg,
	FullscreenDimmer,
	MonthRadio,
	MySelect,
  Buttons
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions, 
  threeYearPlanAndWhichYearFromYear,
  defaultYearForPlan,
  getTeacherOptions,
  whetherReadOnlyForYearPlan
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Table, Segment, TextArea, Checkbox, Input } from 'semantic-ui-react';

const RawStrategyTable = ({
  finishedLoading, readOnly, strategies, 
  aimId, groupId, groupActions, actionTeachers, teacherMap,
  // actionGroupsInCharge, actionGroupMap,
  // groupMap,
  actionLookup, addModalToggle, modalToggle,
}) => {
  let simpleTablePart;
  const colSpan = 6;

  if(!finishedLoading) simpleTablePart = <EmptyTableMsg colSpan={colSpan} msg='載入資料中' />;
  else if(!groupId) simpleTablePart =  <EmptyTableMsg colSpan={colSpan} msg='請先選擇組別' />;
  else if(!strategies.length) simpleTablePart = <EmptyTableMsg colSpan={colSpan} msg='沒有任何策略目標' />; //if no aim
  else if(!aimId) simpleTablePart =  <EmptyTableMsg colSpan={colSpan} msg='請先選擇目標' />;
  else if(aimId>0 && !strategies.length) simpleTablePart = <EmptyTableMsg colSpan={colSpan} msg="未有周年策略" />;

  return (<Table textAlign='center' celled unstackable fixed structured>
    <Table.Header>
      <Table.Row>
      <Table.HeaderCell width={aimId>0?5:8}>標題</Table.HeaderCell>
      {aimId>0 && <Table.HeaderCell width={3}>標題</Table.HeaderCell>}
        <Table.HeaderCell width={2}>時間表</Table.HeaderCell>
        <Table.HeaderCell width={2}>負責人</Table.HeaderCell>
        <Table.HeaderCell width={2}>所需資源</Table.HeaderCell>
        <Table.HeaderCell width={2}>行動</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {simpleTablePart || strategies.map(s => {
        const action = actionLookup[s.id];
        if (!action) {
          return (
            <Table.Row key={"s" + s.id}>
              <Table.Cell textAlign="left">{s.title}</Table.Cell>
              <Table.Cell colSpan={colSpan-1} textAlign="center"><strong>未有<span className='red'>周年</span>計劃項目</strong></Table.Cell>
            </Table.Row>
          );
        }
        const groupAction = groupActions[action.id];
        if (!_get(groupAction, 'length')) {
          return (
            <Table.Row key={"a" + s.id}>
              <Table.Cell textAlign="left">
                {s.title}
                {!readOnly && <p className="addEvaluationButton">
                  <Buttons.AddButton
                    data-selected-action={action.id}
                    onClick={addModalToggle}
                  />
                  <Buttons.CancelButton
                    icon='eye slash outline'
                    concise={true}
                    title='標示為不適用'
                    data-selected-action={action.id}
                    data-modalname='cancel'
                    onClick={addModalToggle}
                  />
                </p>}
              </Table.Cell>
              <Table.Cell colSpan={colSpan-1} textAlign="center"><strong>未有<span className='red'>科組</span>計劃項目</strong></Table.Cell>
            </Table.Row>
          )
        }

        if(groupAction.length===1 && groupAction[0].beginMonth+groupAction[0].endMonth === 0){
          return (
            <Table.Row key={"a" + s.id}>
              <Table.Cell disabled textAlign="left" className='line-through'>
                {s.title}
              </Table.Cell>
              <Table.Cell colSpan={colSpan-2} textAlign="center">
                <span className='red'>{groupAction[0].title||'不適用'}</span>
              </Table.Cell>
              <Table.Cell>
                <Buttons.EditButton
                  data-modalname='isCancelModalOpen'
                  data-selected-id={groupAction[0].id}
                  onClick={modalToggle}
                />
                <Buttons.DeleteButton
                  data-modalname='isDeleteModalOpen'
                  data-selected-id={groupAction[0].id}
                  onClick={modalToggle}
                />
              </Table.Cell>
            </Table.Row>
          )
        }

        return groupAction.map((gA, j) => {

          const inCharge = [];

          for (let teacher of actionTeachers[gA.id] || []) {
            inCharge.push(teacherMap.get(teacher.teacherId));
          }

          return (<Table.Row key={"gA" + gA.id}>
            {!j && (
              <Table.Cell textAlign="left" rowSpan={groupAction.length}>
                {s.title}
                {!readOnly && (<p className="addEvaluationButton">
                  <Buttons.AddButton
                    data-selected-action={action.id}
                    onClick={addModalToggle}
                  />
                </p>)}
              </Table.Cell>)}
            <Table.Cell className="textarea-text">
              {gA.title}
            </Table.Cell>
            <Table.Cell className="textarea-text">{process.env.REACT_APP_FREE_TEXT_TIME_SUPPORT ? (gA.time || '-') : `${gA.beginMonth || '?'}月—${gA.endMonth || '?'}月`}</Table.Cell>
            <Table.Cell>
              {inCharge.length ? inCharge.join("、") : "-"}
            </Table.Cell>
            <Table.Cell className="textarea-text">{gA.resourceNeeded || "-"}</Table.Cell>
            <Table.Cell key='actions'>
              {readOnly ? (<>
                <Buttons.ViewButton
                  data-modalname='isEditModalOpen'
                  data-selected-id={gA.id}
                  onClick={modalToggle}
                />
              </>) : (<>
                <Buttons.EditButton
                  data-modalname='isEditModalOpen'
                  data-selected-id={gA.id}
                  onClick={modalToggle}
                />
                <Buttons.DeleteButton
                  data-modalname='isDeleteModalOpen'
                  data-selected-id={gA.id}
                  onClick={modalToggle}
                />
              </>)}
            </Table.Cell>
          </Table.Row>)
        })
      })}
    </Table.Body>
  </Table>)
}

const StrategyTable = React.memo(RawStrategyTable);

class GroupStrategy extends Component {
  constructor(props) {
    super(props);

    this.stFrancis = [
      ['1.1','與受造界的關係'],
      ['1.2','與他人的關係'],
      ['1.3','與三位一體天主的關係'],
      ['1.4','與自身的關係'],
      ['2.1','透過發展自尊心，發展小組中的合作能力及創意思考。'],
      ['2.2','推動明白及肯定每一個人及文化的獨特性及價值'],
      ['3.1','推動教育，為珍惜及接納學生在生活不同幅度中的經驗及知識。'],
      ['4.1','鼓勵以認真的跨學科研究方法去學習社會的真相。'],
      ['4.2','與文化、藝術、人文發展中心維繫關係，以完成所教科目的視野。'],
    ];

    this.default = {
      title: '',
      resourceNeeded: '',
      selectedTeachers:[],
      stFrancis: this.stFrancis.map(x=>0).join(""),
    }
    if(process.env.REACT_APP_FREE_TEXT_TIME_SUPPORT){
      this.default.time = '';
    }else{
      this.default.beginMonth = 9;
      this.default.endMonth = 6;
    }

    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        accepted: '請輸入:attribute',
        required: '請輸入:attribute',
        min: '請最少輸入:min位:attribute',
      }
    });

    this.state = {

      year: [],
      yearInfo: [],
      yearId: '',

      finishedLoading: false,
      dimmerOpen: false,
      readOnly: true,

      TYPs: [],
      whichYear: null,

      strategies: [],
      actions: [],
      actionOptions: [],

      groupOptions: [],
      groupId: null,
      editableGroups: new Set(),

      aimOptions: [],
      aimId: '',

      teacherMap: new Map(),
      teacherOptions: [],

      groupActions: {},
      actionTeachers: {},

      theAction: this.default,

      isCancelModalOpen: false,
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      selectedId: null,
    }
    this.actionLookup = {};
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    try{
      this.mounted = true;
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      if(err!=='unmounted') console.log("Error when mounting Group Actions", err);
    }
  }

  fetch = async () => {
    try {
      //get year info
      const [yearInfo, TYPs, groups, teachers] = await Promise.all([
        get('getYear'),
        get('getTYP'),
        get('getGroup'),
        get('getAllTeacherWithAdminInSchool'),
      ]);

      const yearId = this.state.yearId ||defaultYearForPlan(yearInfo);
      const teacherOptions = await getTeacherOptions(yearId);
      const whichYear = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs)[1];

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId,

        TYPs,
        whichYear,

        teacherMap: new Map(teachers.map(x=>[x.id, x.shortName || x.name || '?'])),
        teacherOptions,

        groupOptions: selectOptions(groups, 'name', 'id'),
        groupId: this.state.groupId || '',
      });
      
      await this.fetchEditableGroups();
      
    } catch (err) {
      if(err!=='unmounted') console.log('Error when fetching group actions',err);
    }
  }

  isReadOnly = (editableGroups) => whetherReadOnlyForYearPlan(this.state.yearInfo, this.state.yearId) || !editableGroups.has(this.state.groupId);

  fetchEditableGroups = async () => {
    try {
      const groupsCanEdit = await get('getGroupEditRights/' + this.state.yearId);

      const editableGroups = new Set(groupsCanEdit.map(x => x.id));

      await this.setStateAsync({
        editableGroups,
        readOnly: this.isReadOnly(editableGroups)
      });

      await this.fetchAims();

    } catch (err) {
      if(err!=='unmounted') console.log("Error when fetching editable group data for group strat", err);
    }
  }

  fetchAims = async () => {
    try{
      const {groupId, yearId, whichYear} = this.state;
      if(whichYear===null/* || !groupId*/){
        await this.setStateAsync({
          aimId: null,
          aimOptions: [],
          actionGroupMap: new Map(),
          actionGroups: [],
        });
      }else{
        let [ aims, /*actionGroups*/ ] = await Promise.all([
          get(`getAimsForGroup/${groupId||0}/${yearId}`),
          //get(`getActionGroupByYear/${yearId}`),
        ]);
  
        aims = orderBy(aims, ['text'], ['asc']);
        const needToChangeAim = !find(aims, {value: this.state.aimId});
  
        let aimId = needToChangeAim ? (aims && aims.length ? aims[0].value : null) : this.state.aimId;
  
        await this.setStateAsync({
          aimId,
          aimOptions: aims,
          
          // actionGroupMap: new Map(actionGroups.map(x=>[x.id, x.name])),
          // actionGroups: selectOptions(actionGroups, 'name', 'id'),
        });
      }
      await this.fetchStrategies();

    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching TYP detail for group strategies', err);
    }
  };

  fetchStrategies = async () => {
    try{
      const {aimId, yearId, groupId} = this.state;

      if(!aimId){
        await this.setStateAsync({
          strategies: [],
          actions: [],
          actionOptions: []
        });
        this.actionLookup = {};
      }else{
        const aimPrefix = find(this.state.aimOptions,{value:aimId}).text.match(/^(自訂 )?((\d+\.){2})/)[2];
        if(aimId>0){ // always
          let [strategies, actions] = await Promise.all([
            get(`get3YearStrategyByYear/${yearId}/${aimId}`),
            get(`getActionByYearAndAim/${yearId}/${aimId}`)
          ]);

          for(let s of strategies){
            s.title = aimPrefix+s.title;
          }
          
          // const stratHasAction = new Set();
          // actions = actions.filter(a=>{
          //   const gp = new Set(a.groups ? a.groups.split("|"): []);
          //   if(gp.has(String(groupId)) || gp.has(groupId+'各級主任') || gp.has(this.state.groupTypeMap.get(groupId))){
          //     stratHasAction.add(a['3yearstrategyId']);
          //     return true;
          //   }
          //   return false;
          // });
          // strategies = strategies.filter(s => +s[`year${+this.state.whichYear+1}`] && stratHasAction.has(s.id));
          const stratMap = new Map(strategies.map(x=>[x.id, x.title]));

          this.actionLookup = keyBy(actions, "3yearstrategyId");
    
          await this.setStateAsync({
            strategies,
            actions,
            actionOptions: selectOptions(actions, a=>stratMap.get(a["3yearstrategyId"]), "id")
          });
        }
      }
      await this.fetchGroupActions();
    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching TYP detail for group strategies', err);
    }
  }

  fetchGroupActions = async () => {
    try{
      let {groupId,yearId,aimId} = this.state;
      if(!groupId||!aimId){
        await this.setStateAsync({
          groupActions: [],
          actionTeachers: [],
          // actionGroupsInCharge: [],
        });
        return;
      }
      if(aimId>0){ //always
        const [groupActions, groupActionTeachers, actionGroupsInCharge] = await Promise.all([
          get(`getGroupActionByYearAndAim/${groupId}/${yearId}/${aimId}`),
          get(`getGroupActionTeacherForAim/${groupId}/${yearId}/${aimId}`),
          Promise.resolve({status: false})// get(`getGroupActionGroupInCharge/${groupId}/${yearId}/${aimId}`),
        ]);
    
        await this.setStateAsync({
          groupActions: groupBy(groupActions, '1yearactionId'),
          actionTeachers: groupBy(groupActionTeachers, 'groupActionId'),
          // actionGroupsInCharge: groupBy(actionGroupsInCharge, 'groupActionId'),
        });
      }
      else{
        const aimPrefix = find(this.state.aimOptions,{value:aimId}).text.match(/^(自訂 )?((\d+\.){2})/)[2];
        const [groupActions, groupActionTeachers, actionGroupsInCharge] = await Promise.all([
          get(`getCustomGroupAction/${Math.abs(aimId)}`),
          get(`getCustomGroupActionTeacher/${Math.abs(aimId)}`),
          Promise.resolve({status: false})// get(`getGroupActionGroupInCharge/${Math.abs(aimId)}`),
        ]);
        
        groupActions.forEach((gA,i)=>{
          gA.prefix = `${aimPrefix}${i+1}. `;
        });
        await this.setStateAsync({
          groupActions: groupBy(groupActions, 'groupaimId'),
          actionTeachers: groupBy(groupActionTeachers, 'groupActionId'),
          // actionGroupsInCharge: groupBy(actionGroupsInCharge, 'groupActionId'),
        });
      }

    }catch(err){
      if(err!=='unmounted') console.log('Error when fetching group actions for group strategies', err);
    }
  }

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId, yearInfo } = this.state;
      if (yearId !== value) {
        const whichYear = threeYearPlanAndWhichYearFromYear(yearInfo, this.state.TYPs, value)[1];
        await this.setStateAsync({
          finishedLoading: false,
        });
        const teacherOptions = await getTeacherOptions(value);
        await this.setStateAsync({
          yearId: value,
          whichYear,
          teacherOptions,
        });

        await this.fetchEditableGroups();
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      if(err!=='unmounted') console.log("Error when changing year for Group Action", err);
    }
  }

  /* Aim Change */
  aimChange = async (event, { value }) => {
    try{
      const { seletedAim } = this.state;
      if (seletedAim !== value) {
        await this.setStateAsync({
          aimId: value,
          finishedLoading: false,
        });
        
        await this.fetchStrategies();
        this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      if(err!=='unmounted') console.log("Error when changing aim for Group Action", err);
    }
  }

  groupChange = async (event, { value }) => {
    try {
      const { groupId } = this.state;
      if (groupId !== value) {
        await this.setStateAsync({
          finishedLoading: false,
          groupId: value,
        });
      
        await this.fetchGroupActions();
        await this.setStateAsync({
          readOnly: this.isReadOnly(this.state.editableGroups),
          finishedLoading: true
        });
      }
    } catch (err) {
      if(err!=='unmounted') console.log("Error when changing group for Group Action", err);
    }
  }

  inputChange = (event, value) => {
    let inputData;
    //special case for remove icon in semantic-ui-react select
    if(event.target.tagName==='I'){
      inputData = event.target.closest('.ui.dropdown, textarea, button').dataset;
    }else{
      inputData = event.target.closest('.ui, textarea, button').dataset;
    }
    const {inputType, stateName} = inputData;
    this.setStateAsync({
      theAction: {
        ...this.state.theAction,
        [stateName]: inputHandler(inputType, value)
      }
    });
  }

  /**
   * Toggle Modal
   * @param {object|string} eventOrStateName statename is used in calls in functions (turning off modals); event are used for elements
   */
  modalToggle = (eventOrStateName) => {
    try{
      this.validator.purgeFields();
      if(typeof eventOrStateName === 'object'){
        let modalData = {};
        if(eventOrStateName.target.classList.contains('modals')){
          modalData = eventOrStateName.target.firstElementChild.dataset;
        }else{
          modalData = eventOrStateName.target.closest('.ui, button').dataset;
        }
        const {modalname} = modalData;
        const selectedId = +modalData.selectedId;

        const data = {
          [modalname]: !this.state[modalname],
          selectedId,
          theAction: this.default,
        }

        const ref = find(flatten(Object.values(this.state.groupActions)), { id: selectedId });

        if(ref){
          data.theAction = pick(ref, ['title', 'resourceNeeded', 'remark', 'stFrancis'].concat(process.env.REACT_APP_FREE_TEXT_TIME_SUPPORT?['time']:['beginMonth', 'endMonth']));
          data.theAction.selectedAction = ref['1yearactionId'];
          const selectedTeachers = Array.from(new Set((this.state.actionTeachers[ref.id]||[]).map(x=>x.teacherId)));

          Object.assign(data.theAction,{
            selectedTeachers,


          });
        }
         this.setStateAsync(data);
      }else{
        this.setStateAsync({
          [eventOrStateName]: !this.state[eventOrStateName],
          selectedId: null,
          theAction: this.default
        });
      }

    }catch(err){
      if(err!=='unmounted') console.log("Error when modal-toggling for Group Actions", err);
    }
  }

  /* modal toggle */
  addModalToggle = (eventOrStateName) => {
    try{

      this.validator.purgeFields();
      let modalData = {};
      let selectedAction = null, groupaimId = null;
      if(typeof eventOrStateName === 'object'){

        if(eventOrStateName.target.classList.contains('modals')){
          modalData = eventOrStateName.target.firstElementChild.dataset;
        }else{
          modalData = eventOrStateName.target.closest('.ui, button').dataset;
        }
        selectedAction = +modalData.selectedAction||null;
        if(this.state.aimId<0) groupaimId = 0-this.state.aimId;
      }
      this.setStateAsync({
        [modalData.modalname? 'isCancelModalOpen':'isEditModalOpen']: true,
        theAction: Object.assign({
          ...this.default,
          selectedAction,
          groupaimId
        }, modalData.modalname ? { beginMonth: null, endMonth: null } : {})
      });
    }catch(err){
      console.log("Error when modal-toggling for Group Actions", err);
    }
  }

  /* save record */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    try{
      const data = {
        ...this.state.theAction,
        groupId: this.state.groupId,
        yearId: this.state.yearId
      };

      const {selectedId} = this.state;

      if(!selectedId && (!data.selectedAction || !data.yearId)){
        alert("這不應該發生的");
        return;
      }

      if(selectedId){
        data.id = selectedId;
      }
      if(data.selectedAction){
        data["1yearactionId"] = data.selectedAction;
      }
      delete data.selectedAction;
      
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });

      const actionResult = await post('editGroupAction', data);

      if(actionResult&&actionResult.status){
        await this.modalToggle(this.state.isEditModalOpen?'isEditModalOpen':'isCancelModalOpen');
        await this.fetchGroupActions();
      }else{
        throw actionResult;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存組別計劃時發生錯誤"+(typeof err==='string'?":"+err:''));
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  /* delete group strategical action */
  delete = async () => {
    try{
      await this.setStateAsync({
        dimmerOpen: true,
        finishedLoading: false,
      });
      const result = await post('deleteGroupAction', { id: this.state.selectedId });
      if(result && result.status){
        await this.fetchGroupActions();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("刪除科組計劃時發生錯誤");
        console.log("THROW DELETE ERROR for group action", err);
      }
    }
    this.modalToggle('isDeleteModalOpen');
    this.setStateAsync({
      dimmerOpen: false,
      finishedLoading: true,
    });
  }

  switchFrancis = (event, data) => {
    const {i} = event.target.closest('label[data-i]').dataset;
    const {stFrancis} = this.state.theAction;
    
    this.setStateAsync({
      theAction: {
        ...this.state.theAction,
        stFrancis: stFrancis.slice(0, i)+(inputHandler('checkbox', data)?"1":"0")+stFrancis.slice(+i+1)
      }
    })
  }

  render() {
    const {
      finishedLoading,
      dimmerOpen,

      year,
      yearId,
      readOnly,

      aimOptions,
      aimId,

      strategies,

      actionOptions,
      groupActions,

      actionTeachers,

      teacherMap,
      teacherOptions,

      groupOptions,
      groupId,

      isEditModalOpen,
      isDeleteModalOpen,
      isCancelModalOpen,
      theAction,
    } = this.state;

    this.validator.purgeFields();

    return (<>
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='科組計劃 - 策略項目' subTitle='按照周年計劃，為每個科組設定策略項目' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Group>
                  <Form.Field inline className='vertical-center-field'>
                    <label>周年</label>
                    <MySelect
                      compact
                      disabled={!finishedLoading}
                      options={year}
                      value={yearId}
                      onChange={this.yearChange}
                    />
                  </Form.Field>
                  <Form.Field inline className='vertical-center-field'>
                    <label>目標</label>
                    <MySelect
                      compact
                      disabled={!finishedLoading||!aimOptions.length}
                      options={aimOptions}
                      value={aimId}
                      search
                      onChange={this.aimChange}
                      placeholder={aimOptions.length?"請選擇":"沒有三年計劃目標"}
                      error={!aimOptions.length}
                    />
                  </Form.Field>
                  <Form.Field inline className='vertical-center-field'>
                    <label>科組</label>
                    <MySelect
                      error={!groupId}
                      disabled={!finishedLoading}
                      options={groupOptions}
                      value={groupId}
                      search
                      onChange={this.groupChange}
                      placeholder="請先選擇科組"
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <StrategyTable
              actionLookup={this.actionLookup}
              addModalToggle={this.addModalToggle}
              modalToggle={this.modalToggle}
              finishedLoading={finishedLoading}
              readOnly={readOnly}
              aimId={aimId}
              groupId={groupId}
              strategies={strategies}
              groupActions={groupActions}
              actionTeachers={actionTeachers}
              teacherMap={teacherMap}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {dimmerOpen ? <FullscreenDimmer active={true} isLoading={true} /> : (
        <>
          {isEditModalOpen && (<Modal
            data-modalname='isEditModalOpen'
            open={true}
            onClose={this.modalToggle}
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>設定科組年度策略項目</Modal.Header>
            <Modal.Content>
              <Segment basic>
                <Form>
                  <Form.Group className='form-group-margin'>
                    <Form.Field>
                      <label>周年策略項目</label>
                      <MySelect
                        value={theAction.selectedAction||''}
                        options={actionOptions}
                        data-input-type='select'
                        data-state-name='selectedAction'
                        onChange={this.inputChange}
                        placeholder='請選擇'
                        disabled={readOnly}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group className='form-group-margin' widths={2}>
                    <Form.Field required>
                      <label>標題</label>
                      <TextArea
                        value={theAction.title}
                        onChange={this.inputChange}
                        data-input-type='text'
                        data-state-name='title'
                        placeholder='標題'
                        disabled={readOnly}
                      />
                      {this.validator.message('標題', !!theAction.title, 'required|accepted')}
                    </Form.Field>
                    <Form.Field>
                      <label>所需資源</label>
                      <TextArea
                        value={theAction.resourceNeeded || ''}
                        onChange={this.inputChange}
                        data-input-type='text'
                        data-state-name='resourceNeeded'
                        placeholder='所需資源'
                        disabled={readOnly}
                      />
                    </Form.Field>
                  </Form.Group>

                  {finishedLoading && (
                    <Form.Group className='form-group-margin'>
                      <Form.Field>
                        <label className='bold'>配合方濟會的人類觀和教學理念</label>
                      </Form.Field>
                      {this.stFrancis.map(([index, desc], i) => (
                        <Form.Field key={index}>
                          <label data-i={i}>
                            <Checkbox
                              disabled={readOnly}
                              checked={theAction.stFrancis.slice(i,i+1)==="1"}
                              onClick={this.switchFrancis}
                              className='narrow'
                            />
                            <abbr title={desc}>{index}</abbr>
                          </label>
                        </Form.Field>
                      ))}
                    </Form.Group>
                  )}

                  {!process.env.REACT_APP_FREE_TEXT_TIME_SUPPORT && (
                    <>
                      <MonthRadio
                        stateName='beginMonth'
                        disabled={readOnly}
                        label="開始月份"
                        value={theAction.beginMonth}
                        changeHandler={this.inputChange}
                        max={theAction.endMonth}
                      />
                      <MonthRadio
                        stateName='endMonth'
                        disabled={readOnly}
                        label="完結月份"
                        value={theAction.endMonth}
                        bottomMargin={true}
                        changeHandler={this.inputChange}
                        min={theAction.beginMonth}
                      />
                    </>
                  )}
                  <Form.Group widths='equal' className='form-group-margin'>
                    <Form.Field required>
                      <label>將老師加入負責人</label>
                      <MySelect
                        disabled={readOnly}
                        value={theAction.selectedTeachers}
                        options={teacherOptions}
                        data-input-type='select'
                        data-state-name='selectedTeachers'
                        onChange={this.inputChange}
                        fluid
                        multiple
                        search
                        noResultsMessage='找不到教師'
                      />
                    </Form.Field>
                  </Form.Group>
                  {this.validator.message('負責人', theAction.selectedTeachers.length, 'required|min:1,num')}
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Buttons.CancelButton
                data-modalname='isEditModalOpen'
                onClick={this.modalToggle}
              />
              {!readOnly && <Buttons.SaveButton onClick={this.save} />}
            </Modal.Actions>
          </Modal>)}
          
          {isCancelModalOpen && (<ConfirmModal
            modalname='isCancelModalOpen'
            open={true}
            description={<Form>
              <Form.Field>
                <label>確定要將周年策略標為不適用？</label>
                <Input
                  value={theAction.title}
                  onChange={this.inputChange}
                  data-input-type='text'
                  data-state-name='title'
                  placeholder='要取代「未設定科組策略資料」的文字'
                />
              </Form.Field>
            </Form>}

            confirmText='確認'
            confirmIcon='check'
            cancel={this.modalToggle}
            confirm={this.save}
          />)}

          <ConfirmModal
            modalname='isDeleteModalOpen'
            open={isDeleteModalOpen}
            description={<>確定<span className="red">刪除</span>周年策略？</>}
            cancel={this.modalToggle}
            confirm={this.delete}
          />
        </>
      )}
    </>)
  }
}

export default withRouter(GroupStrategy);