/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import { PageHeader, ErrorLabel, ConfirmModal, FullscreenDimmer, EmptyTableMsg } from '../../../Components';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { defaultYear, selectOptions, selectOptionsWithCombineName } from '../../../Helper/Helper';

/* Appraisal css */
import '../../../Styles/Appraisal.css';

/* import lodash */
import { orderBy, map, filter, find } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Segment, Button, Form, Table, Label, Select } from 'semantic-ui-react';

class GroupList extends Component {
    constructor(props) {
        super(props);
        ping(this);

        this.state = {
            dimmerOpen: true,

            year: [],
            selectedYear: '',
    
            teacherOptions: [],

            appraisalGroup: [],
            group: {},
            
            isEditModalOpen: false,
            isDeleteModalOpen: false,

            sortColumn: ['ascending', null],

            tableHeader: [
                {displayName: '評分小組名稱', columnName: 'name'},
                {displayName: '評估老師', columnName: 'teachers'},
            ]
        }

        //form validator
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            element: message =>  <ErrorLabel message={message} />,
            messages: { default: '請輸入資料' }
        });
    }

    componentDidMount = async () => {
        this.mounted = true;
        let selectedYear = await this.fetchYear();
        await this.fetch(selectedYear);
        this.setState({ dimmerOpen: false });
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    fetchYear = async () => {
        return new Promise(async (resolve, reject) => {
            const result = await get('getYear');
            let selectedYear = defaultYear(result);
            let year = selectOptions(result, 'displayName', 'id');
            this.setState({ selectedYear, year })
            resolve(selectedYear);
        });
    }

    fetch = async (yearId) => {
        const [teacher, appraisalGroup, appraisalGroupTeacher] = await Promise.all([
            get('getAllTeacherWithAdminWithIndex/'+yearId),
            get('getAllAppraisalGroup/'+yearId),
            get('getAllAppraisalGroupTeacher/'+yearId)
        ]);

        map(appraisalGroup, ({id}, index) => {
            let teachers = filter(appraisalGroupTeacher, {appraisalGroupId: id});
            map(teachers, ({teacherId}, index) => {
                const teacherRecord = find(teacher, {id: teacherId});
                teachers[index]['index'] = teacherRecord? teacherRecord.index : null;
                teachers[index]['name'] = teacherRecord? teacherRecord.name : null;
            })
            appraisalGroup[index]['teachers'] = orderBy(teachers, 'index');
        });

        this.setState({
            teacher,
            teacherOptions: selectOptionsWithCombineName(teacher, ['index', 'name'], 'id'),
            appraisalGroup: orderBy(appraisalGroup, 'name')
        });
    }

    /* year change */
    yearChange = async (event, { value }) => {
        const { selectedYear } = this.state;
        if(selectedYear !== value) {
            this.setState({dimmerOpen: true});
            await this.fetch(value);
            this.setState({
                selectedYear: value,
                dimmerOpen: false
            })
        }
    }

    sorting = (index) => () => {
        let { appraisalGroup, sortColumn, tableHeader } = this.state;
        let column = [], order = [], newDirection = null;
        if(sortColumn[index] === null) newDirection = 'ascending';
        if(sortColumn[index] === 'ascending') newDirection = 'descending';
        sortColumn[index] = newDirection;
        map(sortColumn, (direction, index) => {
            if(direction) {
                column.push(tableHeader[index]['columnName']);
                order.push(direction === 'ascending'? 'asc' : 'desc');
            }
        })
        this.setState({ sortColumn, appraisalGroup: orderBy(appraisalGroup, column, order) });
    }
 
    modalToggle = (modalStateName, id = null) => () => {
        const record = find(this.state.appraisalGroup, {id});
        let group = {
            appraisalGroupId: id,
            yearId: id? record.yearId : this.state.selectedYear,
            name: id? record.name : '',
            teachers: id? map(record.teachers, 'teacherId') : []
        };

        this.setState({ 
            group,
            [modalStateName]:  !this.state[modalStateName]
        })
    }

    groupChange = (inputType, stateName) => (event, data) => {
        this.setState(prevState => ({
            group: {
                ...prevState.group,
                [stateName]: inputHandler(inputType, data)
            }
        }));
    }

    save = async () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        const { selectedYear, group } = this.state;
        this.setState({dimmerOpen: true});
        let edit = await post('editAppraisalGroup', { group });
        if(edit.status) {
            await this.fetch(selectedYear);
            this.validator.hideMessages();
            this.setState({
                isEditModalOpen: false,
                dimmerOpen: false
            });
        }else{
            alert(edit.error);
            this.setState({ dimmerOpen: false });
        }
    }

    delete = async () => {
        this.setState({dimmerOpen: true});
        let remove = await post('deleteAppraisalGroup', {id: this.state.group.appraisalGroupId});
        if(remove.status) {
            await this.fetch();
            this.setState({
                isDeleteModalOpen: false,
                dimmerOpen: false
            });
        }
    }

    displayEditModal = () => {
        const { isEditModalOpen, teacherOptions, group } = this.state;
        return (
            <Modal open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false}>
                <Modal.Header>編輯評分小組</Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    className='form-group-margin'
                                    fluid
                                    type='text'                                    
                                    onChange={this.groupChange('text', 'name')}
                                    value={group.name || ''}
                                    label='評分小組名稱'
                                    placeholder='評分小組名稱'
                                    error={this.validator.message('name', group.name, 'required')}
                                />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Select
                                    className='form-group-margin'
                                    fluid
                                    multiple
                                    search
                                    onChange={this.groupChange('select', 'teachers')}
                                    value={group.teachers}
                                    options={teacherOptions}
                                    noResultsMessage="找不到老師"
                                    label='評估老師(可多選)'
                                    placeholder='評估老師(可多選)'
                                    error={this.validator.message('teachers', group.teachers, 'required')}
                                />
                            </Form.Group>
                        </Form>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
                    <Button color='green' content='儲存' icon='save' onClick={this.save} circular />
                </Modal.Actions>  
            </Modal>
        )
    }

    render() {
        const { dimmerOpen, year, selectedYear, appraisalGroup, isDeleteModalOpen, sortColumn, tableHeader } = this.state;
        
        return (
            <Fragment>
                {dimmerOpen? 
                    <FullscreenDimmer active={dimmerOpen} isLoading={true} />
                    :
                    <Fragment>                
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <PageHeader title='評分小組管理' subTitle='設定每年度之評分老師小組及百分比' />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Select className='button-margin-bottom' onChange={this.yearChange} options={year} value={selectedYear} />
                                    <div>
                                        <Button onClick={this.modalToggle('isEditModalOpen')} color='green' icon='add' content='新增' circular />  
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Table textAlign='center' sortable selectable celled unstackable>
                                        <Table.Header>
                                            <Table.Row >
                                                {tableHeader.map(({displayName}, index) => {
                                                    return (
                                                        <Table.HeaderCell key={index} sorted={ sortColumn[index] } onClick={this.sorting(index)}>
                                                            {displayName}
                                                        </Table.HeaderCell>
                                                    )
                                                })}
                                                <Table.HeaderCell>行動</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {appraisalGroup.length > 0?
                                                <Fragment>
                                                    {appraisalGroup.map(({id, name, teachers }) => {
                                                        return (
                                                            <Fragment key={id}>
                                                                <Table.Row>
                                                                    <Table.Cell>{name}</Table.Cell>
                                                                    <Table.Cell>
                                                                        {teachers.map(({teacherId, index, name}) => {
                                                                            return (
                                                                                <Label key={teacherId}>{index} {name}</Label>
                                                                            )
                                                                        })}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Button color='blue' icon='edit' onClick={this.modalToggle('isEditModalOpen', id)} circular />
                                                                        <Button color='red' icon='delete' onClick={this.modalToggle('isDeleteModalOpen', id)} circular />
                                                                    </Table.Cell>   
                                                                </Table.Row>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Fragment>
                                                :
                                                <EmptyTableMsg colSpan='3' />
                                            }
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        {this.displayEditModal()}

                        <ConfirmModal open={isDeleteModalOpen} description='確定刪除評估小組？' cancel={this.modalToggle('isDeleteModalOpen')} confirm={this.delete} />
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default withRouter(GroupList);