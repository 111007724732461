import { find, pick } from 'lodash';
import moment from 'moment';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
	PageHeader,
	ErrorLabel,
	ConfirmModal,
  MyTable,
  MySelect,
  FullscreenDimmer
} from '../../Components';

import { get, post } from '../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../Helper/Helper';
import { inputHandler } from '../../Helper/FormHelper';

import SimpleReactValidator from 'simple-react-validator';

import { Grid, Modal, Button, TextArea, Form, Input, Label } from 'semantic-ui-react';

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
  
      year: [],
      yearInfo: [],
      yearId: '',
      isPast: true,
  
      finishedLoading: false,
      dimmerOpen: false,
      senGroupInfo: null,
  
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      selectedId: null,
  
      tableColumnData: [
        { headerName: '名稱', width: 3, cellRender: 'name' },
        { headerName: '支援/訓練重點', width: 6, cellClassName: 'textarea-text text-left', cellRender: 'mainFocus' },
        { headerName: '詳情', width: 5, cellRender: x=>`${x.startMonth||'-'}月起每週${x.sessionPerWeek||'-'}節，每節${x.sessionDuration||'-'}分鐘，共${x.totalSessions||'-'}節` },
        { headerName: '行動', width: 2, cellClassName: 'textlessCell', cellRender: x=>(<>
          <Button
            color='blue'
            icon='edit'
            data-modalname='isEditModalOpen'
            data-selected-id={x.id}
            onClick={this.modalToggle}
            circular
          />
          <Button
            color='red'
            icon='delete'
            data-modalname='isDeleteModalOpen'
            data-selected-id={x.id}
            onClick={this.modalToggle}
            circular
          />
        </>), show: true }
      ]
    }

    
    this.fields = ['name','mainFocus','startMonth','sessionPerWeek','sessionDuration','totalSessions'];

    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        default: '請輸入有效資料'
      }
    });
    this.validator2 = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} pointing='left' />,
      autoForceUpdate: this,
      messages: {
        default: '請輸入有效資料'
      }
    });

    this.tableProps = { unstackable: true};
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;

    try{
      const yearInfo = await get('getYear');
      let yearId = defaultYear(yearInfo);
      let year = selectOptions(yearInfo, 'displayName', 'id');
      const curYear = find(yearInfo, { id: yearId });
      let isPast = true;
      
      if(curYear) isPast = moment(curYear.endDate).isBefore(moment());

      await this.setStateAsync({
        year, yearId, yearInfo, isPast, tableColumnData: this.genNewTableColumnData(isPast),
      });
      
      await this.fetchSENGroup();

      this.setStateAsync({ finishedLoading: true });

    }catch(err){if(err!=='unmounted')console.log("Error when mounting SEN Group");}
  }

  fetchSENGroup = async () => {
    try{
      const senGroupInfo = await get('getYearSenGroup/' + this.state.yearId);
      await this.setStateAsync({senGroupInfo});
    }catch(err){if(err!=='unmounted')console.log("Error when updating SEN for SEN Group", err)}
  }

  /* input update handler */
  inputChange = (event, data) => {
    const {inputType, stateName} = event.target.closest('.ui, button, textarea').dataset;
    let value = inputHandler(inputType, data);
    const dataObj = {
      [stateName]: value||null,
    }

    this.setStateAsync(dataObj);
  }

  genNewTableColumnData = (isPast) => {

    const tableColumnData = [...this.state.tableColumnData];

    tableColumnData[tableColumnData.length-1] = {
      ...tableColumnData[tableColumnData.length-1],
      show: !isPast
    }
    return tableColumnData;
  }

  /* year change */
  yearChange = async (event, { value }) => {
    const { yearId, yearInfo } = this.state;
    if (yearId !== value) {
      const data = {
        yearId: value,
      }
      const curYear = find(yearInfo, { id: value });
      if(curYear){
        data.isPast = moment(curYear.endDate).isBefore(moment());
      }else{
        data.isPast = true;
      }
      data.tableColumnData = this.genNewTableColumnData(data.isPast);
      
      try{
        await this.setStateAsync({ finishedLoading: false });
        await this.setStateAsync(data);
        await this.fetchSENGroup();
        this.setStateAsync({ finishedLoading: true });
      }catch(err){if(err!=='unmounted')console.log("Error when changing year for SEN Group", err)}
    }
  }

  /* modal toggle */
  modalToggle = async (eventOrStateName) => {
    this.validator.hideMessages();
    this.validator2.hideMessages();
    const template = this.fields.reduce((prev,cur)=>({...prev,[cur]:null}),{});
    let modalData;
    if(typeof eventOrStateName === 'object'){
      if(eventOrStateName.target.classList.contains('modals')){
        modalData = eventOrStateName.target.firstElementChild.dataset;
      }else{
        modalData = eventOrStateName.target.closest('.ui, button').dataset;
      }
      const {modalname, selectedId} = modalData;

      const data = find(this.state.senGroupInfo, {id: +selectedId})||template;

      this.setStateAsync({
        [modalname]: !this.state[modalname],
        selectedId: selectedId||null,
        ...pick(data, this.fields)
      });
    }else{
      this.setStateAsync({
        [eventOrStateName]: !this.state[eventOrStateName],
        selectedId: null,
        ...template
      });
    }
  }

  /* add record */
  save = async () => {
    if (!this.validator.allValid() || !this.validator2.allValid()) {
      this.validator.showMessages();
      this.validator2.showMessages();
      return;
    }
    let data = pick(this.state, this.fields.concat('yearId'));
    if(this.state.selectedId)
      data.id = this.state.selectedId;
    try{
      await this.setStateAsync({dimmerOpen: true, finishedLoading: false});

      const result = await post('editSenGroup', data);
      if (result && result.status) {
        await this.fetchSENGroup();
        await this.modalToggle('isEditModalOpen');
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert('儲存SEN支援小組時發生錯誤');
        console.log("Error when saving for SEN Group", err);
      }
    }
    this.setStateAsync({dimmerOpen: false, finishedLoading: true});
  }

  /* delete record */
  delete = async () => {
    let data = { id: this.state.selectedId };
    try{
      await this.setStateAsync({dimmerOpen: true, finishedLoading: false});

      const result = await post('deleteSenGroup', data);
      if (result && result.status) {
        await this.fetchSENGroup();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert('刪除SEN支援小組時發生錯誤');
        console.log("Error when deleting for SEN Group", err);
      }
    }
    await this.modalToggle('isDeleteModalOpen');
    this.setStateAsync({dimmerOpen: false, finishedLoading: true});
  }

  render() {
    const {
      finishedLoading,
      dimmerOpen,
      year,
      yearId,
      senGroupInfo,
      isEditModalOpen,
      isDeleteModalOpen,
      isPast,
      tableColumnData,

      name,
      mainFocus,
      startMonth,
      sessionPerWeek,
      sessionDuration,
      totalSessions,
    } = this.state;

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='SEN 支援小組' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MySelect
                onChange={this.yearChange}
                options={year}
                value={yearId}
                placeholder='年度'
                disabled={!finishedLoading}
              />
              {!isPast && (
              <Button
                color='green'
                content='新增 SEN 支援小組'
                icon='add'
                floated='right'
                data-modalname='isEditModalOpen'
                onClick={this.modalToggle}
                circular
                disabled={!finishedLoading}
              />)}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MyTable
                data={senGroupInfo}
                tableColumnData={tableColumnData}
                tableProps={this.tableProps}
                finishedLoading={finishedLoading}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen ? <FullscreenDimmer active={true} isLoading={true} /> : (
          <>
            <Modal open={isEditModalOpen} closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>請輸入SEN 支援小組資料</Modal.Header>
              <Modal.Content>
                <Form>
                  <Form.Group className='form-group-margin'>
                    <Form.Field inline required>
                      <label>名稱</label>
                      <Input
                        value={name || ''}
                        onChange={this.inputChange}
                        data-input-type='text'
                        data-state-name='name'
                        placeholder='名稱'
                      />
                      {this.validator2.message('名稱', name, 'required')}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group className='form-group-margin'>
                    <Form.Field width={16}>
                      <label>支援/訓練重點</label>
                      <TextArea
                        value={mainFocus || ''}
                        onChange={this.inputChange}
                        data-input-type='text'
                        data-state-name='mainFocus'
                        placeholder='支援/訓練重點'
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field className='form-group-margin-narrow'><label>詳情</label></Form.Field>
                  <Form.Group unstackable>
                    <Form.Field width={4}>
                      <Input
                        type='number'
                        value={startMonth || ''}
                        onChange={this.inputChange}
                        data-input-type='text'
                        data-state-name='startMonth'
                        label='月起'
                        labelPosition='right'
                      />
                      {false && this.validator.message('開始月份', startMonth, 'required|integer|min:1,num|max:12,num')}
                    </Form.Field>
                    <Form.Field width={4}>
                      <Input
                        type='number'
                        value={sessionPerWeek || ''}
                        onChange={this.inputChange}
                        data-input-type='text'
                        data-state-name='sessionPerWeek'
                        labelPosition='right'
                      >
                        <Label>每週</Label>
                          <input />
                        <Label>節</Label>
                      </Input>
                      {false && this.validator.message('每週節數', sessionPerWeek, 'required|integer|min:0,num')}
                    </Form.Field>
                    <Form.Field width={4}>
                      <Input
                        type='number'
                        value={sessionDuration || ''}
                        onChange={this.inputChange}
                        data-input-type='text'
                        data-state-name='sessionDuration'
                        labelPosition='right'
                      >
                        <Label>每節</Label>
                          <input />
                        <Label>分鐘</Label>
                      </Input>
                      {false && this.validator.message('每週長度 (分鐘)', sessionDuration, 'required|integer|min:0,num')}
                    </Form.Field>
                    <Form.Field width={4}>
                      <Input
                        value={totalSessions || ''}
                        onChange={this.inputChange}
                        data-input-type='text'
                        data-state-name='totalSessions'
                        labelPosition='right'
                      >
                        <Label>共</Label>
                          <input />
                        <Label>節</Label>
                      </Input>
                      {false && this.validator.message('總節數', totalSessions, 'required')}
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color='red'
                  content='取消'
                  icon='cancel'
                  data-modalname='isEditModalOpen'
                  onClick={this.modalToggle}
                  circular
                />
                <Button color='green' content='儲存' icon='save' onClick={this.save} circular />
              </Modal.Actions>
            </Modal>

            <ConfirmModal
              open={isDeleteModalOpen}
              description={<>確定刪除SEN 支援小組 <span className='red'>{name}</span>？</>}
              modalname='isDeleteModalOpen'
              cancel={this.modalToggle}
              confirm={this.delete}
            />
          </>
        )}
      </>
    )
  }
}

export default withRouter(Group);