/* import lodash */
import { map, find, pick, findIndex } from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  FullscreenDimmer,
  MySelect,
  MySortableTable
} from '../../Components';

/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../Helper/Helper';
import { momentToDate } from '../../Helper/TimeHelper'; //consent time display
import { inputHandler } from '../../Helper/FormHelper';

/* import semantic-ui element */
import { Grid, Button, Table, Checkbox, Icon, Header } from 'semantic-ui-react';

import genBatchSchoolReport from '../../Layout/batchSchoolReport';
import genBatchStudentReport from '../../Layout/batchStudentReport';

import { fromBlankAsync } from 'xlsx-populate';
import { exportExcel } from '../../Helper/ExcelHelper';

class Export extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: [],
      selectedYear: '',
      finishedLoading: false,
      isLoading: false,
  
      studentsAllChecked: false,
      senStudentInfo: [],
  
      showHidden: false,
      
      genRowClassName: (x => x.status?"":"hidden-row")
    }

    this.yellowText = { color: '#ff3' };
    this.tableProps = {unstackable: true, compact: true};

    this.tableColumnData = [
      {
        width: 1,
        allowSort: false,
        headerName: 'checkbox',
        cellRender: (x)=>(<Checkbox
          checked={!!x.checked}
          value={x.id}
          onChange={this.selectStudentChange}
        />),
        cellClassName: 'textlessCell',
        HeaderRender: this.checkAllHeaderRender
      },
      { width: 3, allowSort:  true, headerName: 'STRN', cellRender: 'strn' },
      { width: 3, allowSort:  true, headerName: '中文姓名', cellRender: 'chiName' },
      { width: 4, allowSort:  true, headerName: '英文姓名', cellRender: 'engName' },
      { width: 2, allowSort:  true, headerName: '班別', cellRender: 'classCode', sortMethod: 'classId' },
      { width: 2, allowSort:  true, headerName: '性別', cellRender: 'gender' },
      { width: 3, allowSort:  true, headerName: '出生日期', cellRender: 'dob' },
      { width: 3, allowSort:  true, headerName: '家長同意', cellRender: x=>x.consent == null ? '未有' : x.consent ? '同意' : '不同意' },
      { width: 3, allowSort:  true, headerName: '簽署日期', cellRender: x=>x.consent == null ? '未有' : x.signDate ? momentToDate(x.signDate) : '未有' },
    ];
  }
  
  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  checkAllHeaderRender = (props) => (
    <Table.HeaderCell width={props.width} onClick={this.selectAllStudentsChange} className='textlessCell'>
      <Checkbox checked={this.state.studentsAllChecked} onChange={this.selectAllStudentsChange} />
    </Table.HeaderCell>
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      const result = await get('getYear');
      let selectedYear = defaultYear(result);
      let year = selectOptions(result, 'displayName', 'id');
      await this.setStateAsync({ year, selectedYear });
      await this.fetchSEN();
      await this.setStateAsync({finishedLoading: true});
    }catch(err){if(err!=='unmounted')console.log("Error when mounting SEN Export", err)}
  }

  fetchSEN = async () => {
    try{
      const senStudentInfo = await get('getSenStudentByYear/' + this.state.selectedYear)
      await this.setStateAsync({ senStudentInfo });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching SEN for Export", err)}
  }

  /* year change */
  yearChange = async (event, { value }) => {
    const { selectedYear } = this.state;
    if (selectedYear !== value) {
      try{
        await this.setStateAsync({finishedLoading: false, selectedYear: value});
        await this.fetchSEN();
        await this.setStateAsync({finishedLoading: true});
      }catch(err){if(err!=='unmounted')console.log("Error when changing year for SEN Export", err)}
    }
  }

  handlePrinting = (genReport) => async () => {
    const { showHidden } = this.state;
    let selectedYear = find(this.state.year, { value: this.state.selectedYear });
    if (selectedYear) {
      selectedYear = selectedYear.text;
    } else {
      alert('年度無效');
      return;
    }

    const trueData = this.state.senStudentInfo.filter(
      x => x.hasOwnProperty('checked') && x.checked && (x.status || showHidden)
    ).map(x => pick(x, ['id', 'strn', 'chiName', 'engName', 'classCode', 'classNo', 'consent', 'signDate']));

    if (trueData && trueData.length) {
      try{
        await this.setStateAsync({
          dimmerOpen: true,
          isLoading: true,
          loaderText: <Header size='large' inverted>從資料庫中獲取資料中</Header>
        });
  
        const value = await post('exportSenPdf', {senStudents: trueData});
        if(value && value.status){
          await this.setStateAsync( { loaderText: <Header size='large' inverted>將資料轉換成PDF中</Header> } );
          genReport(value.result.data, selectedYear, () => {
            this.setStateAsync({ dimmerOpen: false, })
          })
        }else{
          throw new Error('無法讀取資料，請稍後再試');
        }
      }catch(err){
        if(err!=='unmounted'){
          if(this.state.dimmerOpen){
            this.setStateAsync({
              isLoading: false,
              loaderText: <Header size='large' icon inverted><Icon name='times'/>發生錯誤</Header>,
            });
            setTimeout(
              () => { this.setStateAsync({ dimmerOpen: false, }) }
            , 2000)
          }
          console.log('Error when printing', err);
        }
      }
    } else {
      alert('請選擇要匯出的學生');
    }
  }

  printBatchSchoolReport = this.handlePrinting(genBatchSchoolReport)
  printBatchStudentReport = this.handlePrinting(genBatchStudentReport)

  /* export */
  export = async () => {
    let selectedYear = find(this.state.year, {value: this.state.selectedYear})
    if (selectedYear) {
      selectedYear = selectedYear.text.replace(/\s/g, '');
    } else {
      alert('Invalid Year');
      return;
    }
    const { showHidden } = this.state;

    const widths = [4.4,10,11,20,5.5,5.5,11.5,10,11.5,10];

    const data = [['#','STRN','中文姓名','英文姓名','班別','性別','出生日期','同意與否','簽署日期','已完成',]].concat()

    const trueData = this.state.senStudentInfo.filter(
      x => x.hasOwnProperty('checked') && x.checked && (x.status || showHidden)
    ).map((x, index) => [
      index + 1,
      x.strn,
      x.chiName,
      x.engName,
      x.classCode,
      x.gender,
      x.dob,
      x.consent == null ? '未有' : x.consent ? '同意' : '不同意',
      x.consent == null ? '未有' : x.signDate ? momentToDate(x.signDate) : '未有',
      x.complete ? '是' : ''
    ]);

    if (trueData && trueData.length) {

      const workbook = await fromBlankAsync();
      const sheet = workbook.sheet(0).name(`${selectedYear.length < 30 ? selectedYear : selectedYear.slice(0, 28) + "…"}年度特殊教育需要學生名單`);
      sheet.cell('A1').value(data);
      sheet.cell('A2').value(trueData);
      sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true }, horizontalAlignment: 'left' }).autoFilter();
      sheet.range('A1:J1').style({ bold: true }).style('fill', 'dddddd');

      widths.forEach((width,i)=>{
        sheet.column(String.fromCharCode(65+i)).width(width);
      });

      const file = await workbook.outputAsync();
      exportExcel(file, `${selectedYear}年度特殊教育需要學生名單`);

    } else {
      alert('請選擇要匯出的學生');
    }
  }

  /* input update handler */
  inputChange = (event, data) => {
    const {inputType, stateName} = event.target.closest('.ui, button, textarea').dataset;
    let value = inputHandler(inputType, data);
    const dataObj = {
      [stateName]: value,
    }
    
    if(stateName==='showHidden')
      if(value)
        dataObj.genRowClassName = x => x.status?"":"left-school"
      else
        dataObj.genRowClassName = x => x.status?"":"hidden-row";
    this.setStateAsync(dataObj)
  }

  /* select all senStudentInfo checkbox change */
  selectAllStudentsChange = (event) => {
    let { studentsAllChecked, senStudentInfo } = this.state;
    this.setStateAsync({
      studentsAllChecked: !studentsAllChecked,
      senStudentInfo: map(senStudentInfo, (student) => {
        if(student.checked === !studentsAllChecked) return student;
        return {...student, checked: !studentsAllChecked};
      })
    })
  }

  /* select student checkbox change */
  selectStudentChange = (event, { value, checked }) => {
    let index = findIndex(this.state.senStudentInfo, {id: value});

    let senStudentInfo = [...this.state.senStudentInfo];
    senStudentInfo[index] = {...senStudentInfo[index], checked};

    this.setStateAsync({
      senStudentInfo,
      studentsAllChecked: senStudentInfo.every(x=>x.checked)
    })
  }

  render() {
    const {
      year,
      selectedYear,
      genRowClassName,
      senStudentInfo,
      isLoading,

      finishedLoading,
      dimmerOpen,
      loaderText,
      showHidden,
    } = this.state;

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column><PageHeader title='匯出SEMIS家長同意記錄' /></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MySelect
                options={year}
                value={selectedYear}
                onChange={this.yearChange}
              />
              <Checkbox
                label='顯示中途離校學生'
                checked={showHidden}
                onChange={this.inputChange}
                data-input-type='checkbox'
                data-state-name='showHidden'
                className="margin-left-1rem"
              />
              <Button
                color='green'
                floated='right'
                content='匯出家長同意記錄'
                icon='file alternate'
                onClick={this.export}
                circular
              />
              <Button
                type='button'
                color='orange'
                floated='right'
                onClick={this.printBatchSchoolReport}
                circular
              >
                <Icon name='download'/>匯出為<span style={this.yellowText}>校方</span>報告
              </Button>
              <Button
                type='button'
                color='orange'
                floated='right'
                onClick={this.printBatchStudentReport}
                circular
              >
                <Icon name='download'/>匯出為<span style={this.yellowText}>學生</span>報告
              </Button>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MySortableTable
                data={senStudentInfo}
                tableColumnData={this.tableColumnData}
                genRowClassName={genRowClassName}
                finishedLoading={finishedLoading}
                tableProps={this.tableProps}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <FullscreenDimmer
          active={dimmerOpen}
          isLoading={isLoading}
          content={loaderText}
        />
      </>
    )
  }
}

export default withRouter(Export);