/* import lodash */
import {groupBy, pick} from 'lodash';

/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {PieChart} from 'react-minimal-pie-chart';

/* import components */
import { PageHeader, EmptyTableMsg } from '../../Components';

/* import helper functions */
import { get } from '../../Helper/ApiHelper';
import { numRound, getQuestionAverage, genEvaluationTitle } from '../../Helper/Helper';
import {momentToDate} from '../../Helper/TimeHelper';

/* import semantic-ui element */
import { Grid, Header, Table, Icon } from 'semantic-ui-react';


class SurveyDesign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      column: 5,
      finishedLoading: false,

      count: null,
      description: "",
      endDate: null,
      evaluation: null,
      questions: [],
      replies: null,
      title: "",
      teacherName: "",
      incompleteTarget: [],
    }
    this.titles = ['不適用','極度不同意','不同意','同意','極度同意'];
    this.colors = ['#dddddd','#ff4444','#ffc144','#c1ff44','#44ff44'];
    this.labelStyle = {
      fontSize: '10',
      fill: '#333',
      fontWeight: '700',
      textShadow: '0px 0px 3px rgb(255,255,255,0.5)'
    };
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      })
    }catch(err){
      console.log("Error when mounting");
      console.log(err);
    }
  }
  
  fetch = async () => {
    try{
      const {surveyId} = this.props.match.params;
      const [questions, lol] = await Promise.all([
        get(`getSurveyQuestion/${surveyId}`),
        get(`getSurveyResult/${surveyId}`)
      ]);
      const replies = groupBy(lol.questions, 'surveyQuestionId');
      Object.keys(replies).forEach(x=>{
        replies[x] = replies[x].map(x=>x.score);
      })
      
      await this.setStateAsync({
        replies,
        questions,
        ...pick(lol,['count','description','title','evaluation','endDate','incompleteTarget'])
      });
      
      if(this.state.evaluation){
        const teacher = await get(`getOneTeacher/${this.state.evaluation.teacherId}`);
        await this.setStateAsync({
          teacherName: teacher[0].name
        })
      }
    }catch(err){
      console.log("Error when fetching survey stats");
      console.log(err);
    }
  }

  /* save record */
  toDashboard = () => {
    this.props.history.push('/survey/')
  }

  genLabel = (qId) => (info) => {
    const {dataEntry} = info;
    const {value} = dataEntry;
    
    if(!value) return false;
    return numRound(value/(this.state.replies[qId]||[0]).length*100,1)+"%"
  }

  viewBoxSize = [100,100]

  render() {
    const {
      count,
      description,
      endDate,
      evaluation,
      questions,
      replies,
      title,
      teacherName,
      incompleteTarget,

      finishedLoading,
    } = this.state;

    let answered = null;
    const aggregate = {};

    questions.forEach(q=>{
      const r = replies[q.id] || [];
      answered = r.length;
      aggregate[q.id] = [0,0,0,0,0];
      r.forEach(x=>{
        aggregate[q.id][x]++;
      });
    });

    const avgs = Object.values(aggregate).map(getQuestionAverage);

    const avg = avgs.reduce((prev,cur)=>prev+cur,0)/avgs.length;

    return (
      <Grid divided='vertically'>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title={title} subTitle={description}/>
          </Grid.Column>
        </Grid.Row>
        {finishedLoading && (
          <Grid.Row columns="1">
            <Grid.Column>
              <Header floated="right" color="red">截止日期：{momentToDate(endDate)}<br/>負責老師：{teacherName}</Header>
              <Header as="h3" className="no-margin-top">成功準則：{genEvaluationTitle(evaluation)}</Header>
              <p>
                <strong>{evaluation.concernTitle}</strong>
                  <Icon name="angle right"/>
                  <strong>{evaluation.aimTitle}</strong>
                  <Icon name="angle right"/>
                  <strong>{evaluation.strategyTitle}</strong>
                {!!evaluation.groupActionTitle && (<><Icon name="angle right"/><strong>{evaluation.groupActionTitle}</strong></>)}
              </p>
            </Grid.Column>
          </Grid.Row>
        )}
        
        <Grid.Row>
          <Grid.Column width="5">
            <h3><abbr title="包括已儲存但未提交的問卷">目前回答率</abbr>: {finishedLoading?numRound(answered/count*100,2)+"%":"載入中"}</h3>
          </Grid.Column>
          <Grid.Column width="5">
            <h3><abbr title="全部題目的平均">目前平均分</abbr>: {finishedLoading?`${numRound(avg,2)} (${numRound((avg-1)/3*100,2)}%)`:"載入中"}</h3>
          </Grid.Column>
          <Grid.Column width="6">

          </Grid.Column>
          <Grid.Column width="16">
            <Header as='h3'>未提交回答的對象: <Header.Subheader as='span'>{incompleteTarget.map(x=>(x.name?x.name:`${x.chiName||x.engName}(${x.classCode} ${x.classNo})`)||'').join("、")}</Header.Subheader></Header>
          </Grid.Column>
          <Grid.Column width="16">
            <Table celled fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={8}>標題</Table.HeaderCell>
                  <Table.HeaderCell width={1}><abbr title="不計填不適用者">平均分</abbr></Table.HeaderCell>
                  <Table.HeaderCell width={7}>{[1,2,3,4,0].map(x=>(
                    <Fragment key={x}>
                      <label className="survey-legend" style={{backgroundColor: this.colors[x]}}></label>
                      <span className="legend-label">{this.titles[x]}</span>
                    </Fragment>
                  ))}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finishedLoading ? questions.map(q=>{
                  return (
                    <Table.Row key={q.id}>
                      <Table.Cell><Header as="h4">{q.title}</Header></Table.Cell>
                      <Table.Cell><Header as="h3" textAlign="center">{getQuestionAverage(aggregate[q.id])}</Header></Table.Cell>
                      <Table.Cell>
                        <PieChart
                          className="piechart"
                          data={
                            aggregate[q.id].map((value, i)=>({
                              title: this.titles[i]+(i?` (${i})`:""),
                              color: this.colors[i],
                              value,
                            }))
                          }
                          labelPosition={70}
                          viewBoxSize={this.viewBoxSize}
                          label={this.genLabel(q.id)}
                          labelStyle={this.labelStyle}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                }): <EmptyTableMsg msg='資料載入中' />}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(SurveyDesign);