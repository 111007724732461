/* import react */
import React, {memo} from 'react';

/* import semantic-ui element */
import { TextArea, Input, Icon } from 'semantic-ui-react';

const centerText = {textAlign: "center"};

/**
 * Shows plain text or an ordered list if a field is read only, cannot be used in Tabs or other methods requiring class method for renders
 * @param {Object} props 
 */
const FlexibleTextField = (props) => {

  const {multiline, fluid, name, readOnly, type, value, options, rows, disabled, onChange, ul, maxRows} = props;

  const data_fields = {};
  for(let x in props){
    if(x.slice(0,5)==='data-'&&props.hasOwnProperty(x)){
      data_fields[x]=props[x];
    }
  }
  
  if(readOnly){
    if(value!==null&&value!==''){
      const raw_rows = String(value).split('\n');
      const moreThanMax = raw_rows.length>maxRows;
      const rows = raw_rows.slice(0,maxRows).map((line,i)=><li key={i}>{line}</li>);
      return multiline?(<>
        {ul?(
          <ul className="list-less-padding text-left">{rows}</ul>
        ):(<ol className="list-less-padding text-left">{rows}</ol>)}
        {moreThanMax && <Icon name="ellipsis vertical" size="small" />}
      </>):<p>{value}</p>;
    }
    return <p style={centerText}>-</p>;
  }
  if(multiline){
    return (
      <TextArea
        name={name}
        rows={rows}
        disabled={disabled}
        {...data_fields}
        value={value===null?'':value}
        onChange={onChange}
        {...options}
      />
    )
  }
  return (
    <Input
      name={name}
      fluid={fluid}
      disabled={disabled}
      type={type}
      {...data_fields}
      value={value===null?'':value}
      onChange={onChange}
      {...options}
    />
  )
}

FlexibleTextField.defaultProps = {
  multiline: false,
  readOnly: true,
  value: "",
  type: "text",
  options: {},
  ul: false,
  rows: 8,
  disabled: true,
  onChange: ()=>{},
}

export default memo(FlexibleTextField);