/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	FullscreenDimmer,
	ConfirmModal,
	EmptyTableMsg,
} from '../../Components';

/* import helper functions */
import { get, post, getFile } from '../../Helper/ApiHelper';
import {
  tableSorting,
  sortData
} from '../../Helper/Helper';
import { inputHandler } from '../../Helper/FormHelper';
import { displayDateTime } from '../../Helper/TimeHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, Header, Icon } from 'semantic-ui-react';

class FileManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
      files: [],
      versions: [],
      finishedLoading: false,
      dimmerOpen: false,
      canEdit: false,

      action: {},

      mainTableSort: ['fileName', 'ascending'],
      isError: false,
      file: null,

      isEditModalOpen: false,
      isDeleteModalOpen: false,
      fileName: '',
      seletedId: null,
    }
    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      messages: {
        default: '請輸入資料'
      },
      autoForceUpdate: this,
    });
    this.tableHeader = [
      {
        displayName: '檔案名稱',
        columnName: 'fileName',
        //x is Object.entries
        sortable: true,
        isIntegerSort: false,
        width: 4,
      },
      {
        displayName: '備註 (最新)',
        columnName: 'remark',
        sortable: false,
        isIntegerSort: false,
        width: 5,
      },
      {
        displayName: '教師',
        columnName: 'teacher',
        sortable: true,
        isIntegerSort: false,
        width: 2,
      },
      {
        displayName: '最後上載時間',
        columnName: 'uploaded',
        sortable: true,
        isIntegerSort: false,
        width: 3,
      },
    ];
    this.fileInput = React.createRef();
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when mounting file management');
      console.log(err);
    }
  }
  
  fetch = async () => {
    try{
      const isGroup = this.isGroup();
      let action, files, canEdit, myInfo;
      if(isGroup){
        const id = +this.props.match.params.groupActionId;
        [action, files, canEdit, myInfo] = await Promise.all([
          get(`getGroupActionById/${id}`),
          get(`getLatestGroupActionFile/${id}`),
          get(`getCanEditFiles/group/${id}`),
          get('getPersonalInfo'),
        ]);
      }else{
        const id = +this.props.match.params.actionId;
        [action, files, canEdit, myInfo] = await Promise.all([
          get(`getActionById/${id}`),
          get(`getLatestActionFile/${id}`),
          get(`getCanEditFiles/school/${id}`),
          get('getPersonalInfo'),
        ]);
      }
      if(action.length){
        await this.setStateAsync({
          action: action[0],
          files: sortData(files),
          canEdit,
          myId: (myInfo[0]||{}).id
        });
      }else{
        await this.setStateAsync({action: undefined, files: []});
      }
    }catch(err){
      console.log("Error when fetching file info");
      console.log(err);
    }
  }

  /* table sorting */
  sorting = (clickedColumn) => () => {
    const { files, mainTableSort } = this.state;
    const sortingResult = tableSorting(clickedColumn, files, mainTableSort[0], mainTableSort[1]);
    this.setStateAsync({
      mainTableSort: [clickedColumn, sortingResult.sortDirection],
      files: sortingResult.data
    });
  }

  /* input update handler */
  inputChange = (inputType, stateName, callback = () => { }) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value }, callback)
  }

  /* modal toggle */
  modalToggle = (modalStateName, fileName = '') => async () => {
    await this.setStateAsync({
      [modalStateName]: !this.state[modalStateName],
      remark: '',
      fileName,
      file: null
    });
  }

  /* modal toggle */
  deleteModalToggle = async (event) => {
    let dataset;
    if(event){
      if(event.target.classList.contains('modals')){
        dataset = event.target.firstElementChild.dataset;
      }else{
        dataset = event.target.closest('button').dataset;
      }
      const {selectedId} = dataset;
      this.setStateAsync({
        isDeleteModalOpen: !this.state.isDeleteModalOpen,
        selectedId: Number.isInteger(+selectedId)?+selectedId:null
      });
    }else{
      this.setStateAsync({
        isDeleteModalOpen: !this.state.isDeleteModalOpen,
        selectedId: null
      });
    }
  }
  
  edit = (fileName) => async () => {
    await this.setStateAsync({
      isError: false,
    });

    if(!fileName){
      this.setStateAsync({
        versions: [],
        dimmerOpen: false,
      });
    }else{
      try{
        const id = this.props.match.params.groupActionId || this.props.match.params.actionId;
        await this.setStateAsync({dimmerOpen: true, finishedLoading:false});
        const versions = await get(`get${this.isGroup()?'Group':''}ActionFileByName/${id}/${fileName}`);
        await this.setStateAsync({
          versions,
          dimmerOpen: false, finishedLoading: true
        });
      }catch(err){
        console.log("Error when retrieving file version data");
        console.log(err);
      }
    }
    await this.modalToggle('isEditModalOpen', fileName)();
  }

  download = (id, fileName) => async () => {
    try{
      await getFile({id}, fileName);
    }catch(err){
      console.log("Error when retrieving file");
      console.log(err);
    }
  }

  /* save record */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('saveNewFile', {
        type: this.isGroup()?"group":"school",
        id: this.pickId(),
        fileName: this.state.fileName,
        file: this.state.file,
        remark: this.state.remark
      }, 'FORMDATA');
      if(result && result.status){
        await this.fetch();
        await this.modalToggle('isEditModalOpen')();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存檔案時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  /* delete record */
  delete = async () => {
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('removeFileVersion', { id: this.state.selectedId });
      if(result && result.status){
        await this.fetch();
        await this.edit(this.state.fileName)();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("刪除檔案版本時發生錯誤");
        console.log(err);
      }
    }
    await this.deleteModalToggle();
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  parseFile = async (event) => {
    event.preventDefault();
    if (this.fileInput.current.files.length) {
      const file = this.fileInput.current.files[0];
      const {fileName} = this.state;
      if(fileName && this.getExtension(file.name)!==this.getExtension(fileName)){
        await this.setStateAsync({
          file: null,
          isError: "請確認檔案副檔名"
        });
      }else{
        await this.setStateAsync({
          file,
          isError: false
        });
      }
    }
  }

  isGroup = () => this.props.match.params.hasOwnProperty('groupActionId')
  pickId = () => this.props.match.params.groupActionId || this.props.match.params.actionId
  getExtension = (fileName) => {
    const m = fileName.match(/\.[^.]+$/);
    return m?m[0]:""
  }

  render() {
    const {
      files,
      versions,
      action,
      canEdit,
      myId,

      finishedLoading,
      dimmerOpen,

      isEditModalOpen,
      isDeleteModalOpen,

      remark,
      fileName,
      isError,
      file,

      mainTableSort,
    } = this.state;
    
    switch(action){
      case null:
        return (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header textAlign="center" as="h1">{this.isGroup()?'科組':'學校'}周年計劃資料載入中</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      case undefined:
        return (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header textAlign="center" as="h1">沒有{this.isGroup()?'科組':'學校'}周年計劃資料</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      default:break;
    }

    this.validator.purgeFields();

    let prefix = '';

    if(finishedLoading){
      prefix = action.displayName+" 年度";
      if(action.groupName)
        prefix+=` ${action.groupName}`;
      prefix += ' - ';
    }

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title={`${prefix}策略項目檔案管理`} subTitle='為策略項目上載檔案' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={canEdit?13:16}>
              <p>
                <strong>{action.concernTitle}</strong>
                  <Icon name="angle right"/>
                  <strong>{action.aimTitle}</strong>
                  <Icon name="angle right"/>
                  <strong>{action.strategyTitle}</strong>
                {!!action.groupActionTitle && (<><Icon name="angle right"/><strong>{action.groupActionTitle}</strong></>)}
              </p>
            </Grid.Column>
            {canEdit && (<Grid.Column width={3}>
              <Button disabled={!finishedLoading} color='green' content='上載檔案' icon='upload' floated='right' onClick={this.edit()} circular />
            </Grid.Column>)}
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Table sortable unstackable fixed>
                <Table.Header>
                  <Table.Row>
                    {this.tableHeader.map(th => {
                      return (
                        <Table.HeaderCell
                          key={th.columnName}
                          sorted={mainTableSort[0] === th.columnName ? mainTableSort[1] : null}
                          onClick={th.sortable?this.sorting(th.columnName):()=>undefined}
                          width={th.width}
                        >
                          {th.displayName}
                        </Table.HeaderCell>
                      )
                    })}
                    <Table.HeaderCell width={2}>行動</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                {finishedLoading ?
                  (files.length ? files.map(({id,fileName,remark,uploaded,teacherName}) => {
                    return (
                      <Table.Row key={fileName}>
                        <Table.Cell>{fileName}</Table.Cell>
                        <Table.Cell>{remark}</Table.Cell>
                        <Table.Cell>{teacherName}</Table.Cell>
                        <Table.Cell>{displayDateTime(uploaded)}</Table.Cell>
                        <Table.Cell key='actions'>
                          <Button color='green' icon='download' onClick={this.download(id, fileName)} circular/>
                          <Button color='blue' icon={canEdit?'edit':'eye'} onClick={this.edit(fileName)} circular />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }): <EmptyTableMsg colSpan='5' msg='未有檔案'/>)
                  :<EmptyTableMsg colSpan='5' msg='載入資料中'/>}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {dimmerOpen?(<FullscreenDimmer active={dimmerOpen} isLoading={true} />):(
          <>
            <Modal open={isEditModalOpen} onClose={this.edit()} closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>策略項目檔案管理 - {fileName||'新檔案'}</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    {!!fileName && (
                      <Table celled unstackable fixed>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={8}>備註</Table.HeaderCell>
                            <Table.HeaderCell width={3}>最後上載時間</Table.HeaderCell>
                            <Table.HeaderCell width={2}>上載者</Table.HeaderCell>
                            <Table.HeaderCell width={3} textAlign="center">行動</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {finishedLoading ?
                          (versions.map(({id,remark,uploaded,fileName,teacherId,teacherName}) => {
                            const canEditVersion = canEdit && (teacherId === myId);
                            return (
                              <Table.Row key={id}>
                                <Table.Cell>{remark}</Table.Cell>
                                <Table.Cell>{displayDateTime(uploaded)}</Table.Cell>
                                <Table.Cell>{teacherName}</Table.Cell>
                                <Table.Cell key='actions' textAlign="center">
                                  <Button
                                    color='green'
                                    icon='download'
                                    circular
                                    onClick={this.download(id, fileName)}
                                  />
                                  {canEditVersion && (<Button
                                    color='red'
                                    icon='delete'
                                    data-selected-id={id}
                                    onClick={this.deleteModalToggle}
                                    circular
                                  />)}
                                </Table.Cell>
                              </Table.Row>
                            )
                          }))
                          :<EmptyTableMsg colSpan='4' msg='載入資料中'/>}
                        </Table.Body>
                      </Table>
                    )}

                    {canEdit && (<Form.Group>
                      <Form.Field width={4}>
                        <label>{fileName ? "上載檔案新版本" : "上載檔案"}</label>
                        <input
                          id="newFile"
                          type="file"
                          ref={this.fileInput}
                          onChange={this.parseFile}
                        />
                        {isError&&<ErrorLabel message={isError} pointing={false}/>}
                      </Form.Field>
                      <Form.TextArea width={12} value={remark} onChange={this.inputChange('text', 'remark')} label='備註' placeholder='備註' />
                    </Form.Group>)}
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
                <Button color='green' content='儲存' disabled={!canEdit||!file||isError} icon='save' onClick={this.save} circular />
              </Modal.Actions>
            </Modal>

            <ConfirmModal open={isDeleteModalOpen} description='確定刪除此檔案版本？' cancel={this.deleteModalToggle} confirm={this.delete} />
          </>
        )}
      </>
    )
  }
}

export default withRouter(FileManagement);