/* import lodash */
import {keyBy, find, filter, pick} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  ErrorLabel,
  ConfirmModal,
  EmptyTableMsg,
  FullscreenDimmer,
  SubTopicText,
  AggregateNumber,
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions,
  extractStartYear,
  toConcernAggregate,
  whetherReadOnlyForTYPResult,
  numRound
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

import '../../../Styles/Concerns.css';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, Header } from 'semantic-ui-react';

class ThreeYearPlan extends Component {
  constructor(props) {
    super(props);

    this.meetTargetLookup = {
      all: "完全達標 (4)",
      most: "大部分達標 (3)",
      part: "部分達標 (2)",
      no: "未達標 (1)",
    }

    this.meetTargetOptions = Object.entries(this.meetTargetLookup).map(([value, text])=>({value,text}));

    this.state = {

      year: [], // for select fields
      yearInfo: {}, //dictionary for info search
      hasGotYear: false,

      TYPs: [],
      concerns: [],

      beginYear: '',
      tempConcerns: [],
      
      aggregates: [{},{},{}],

      isEditModalOpen: false,
      isSaveModalOpen: false,
      selectedId: null,
    }

    //form validator
    this.validator = new SimpleReactValidator({
      element: (message) => (<ErrorLabel message={message} />),
      messages: {
        required: '請輸入有效資料',
      },
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
    }catch(err){
      console.log('did mount');
      console.log(err);
    }
  }

  fetch = async () => {
    //get year info
    const rawYear = await get('getYear');
    let year = selectOptions(rawYear, extractStartYear, 'id');
    let yearInfo = keyBy(rawYear, 'id');

    await this.setStateAsync({year, yearInfo, hasGotYear: true});

    const [TYPs, concerns] = await Promise.all([
      get('getTYP'),
      get('get3YearConcern'),
    ]);

    await this.setStateAsync({TYPs, concerns});
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value })
  }

  /* modal toggle */
  modalToggle = (modalStateName, selectedId = null) => async () => {
    const data = {
      [modalStateName]: !this.state[modalStateName],
    };

    if(modalStateName === 'isSaveModalOpen'){  
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return;
      }
      this.setStateAsync(data);
      return;
    }
    Object.assign(data, {selectedId});

    if (selectedId) {
      const typRef = find(this.state.TYPs, { id: selectedId });
      try{
        await this.setStateAsync({dimmerOpen: true});
        const beginYear = extractStartYear(this.state.yearInfo[typRef.beginYear]);
        const years = [
          typRef.beginYear,
          undefined,
          undefined
        ];
        if(years[0]){
          [1,2].forEach(i=>{
            years[i] = (find(this.state.year, {text: beginYear+i})||{value:undefined}).value
          });
        }
        const aggregates = (await Promise.all(years.map(x=>get(`get1yearSurveyResultAggregate/${x}`)))).map(toConcernAggregate);

        await this.setStateAsync({
          aggregates,
          dimmerOpen: false
        });
      }catch(err){
        console.log("Error when loading aggregates");
        console.log(err);
      }

      data.beginYear = typRef.beginYear;
      const concernRef = filter(this.state.concerns, { typId: selectedId });
      data.tempConcerns = concernRef ? [...concernRef] : [];
    } else {
      Object.assign(data, {
        beginYear:'',
        tempConcerns:[],
        aggregates: [],
      });
    }
    this.setStateAsync(data);
  }

  /* save record */
  save = async () => {
    const data = pick(this.state, ['beginYear']);
    data.id = this.state.selectedId;

    try{
      await this.setStateAsync({
        dimmerOpen: true,
      });
      let result;
      try{
        result = await post('editTYP', data);
      }catch(err){
        console.log("error when editing TYP data:");
        console.log(err);
      }

      if(result && result.insertId){
        const concerns = this.state.tempConcerns;
        await post('batchEdit3YearConcern', {typId: result.insertId, concerns});
      }else{
        await post('batchEdit3YearConcern', {typId: data.id, concerns: this.state.tempConcerns});
      }
      this.validator.hideMessages();
      await this.fetch();
    }catch(err){
      console.log("error when editing concern data:");
      console.log(err);
    }
    this.setStateAsync({
      isEditModalOpen: false,
      isSaveModalOpen: false,
      dimmerOpen: false,
    });
  }

  inputChangeForConcern = (i, inputType, propName) => (event, data) => {
    const tempConcerns = [...this.state.tempConcerns];
    const concern = { ...tempConcerns[i] };
    concern[propName] = inputHandler(inputType, data);
    if(concern[propName]==="" && inputType === "select"){
      concern[propName]=null;
    }
    tempConcerns[i] = concern;
    this.setStateAsync({ tempConcerns });
  }

  render() {
    const {
      dimmerOpen,

      yearInfo, hasGotYear,
      TYPs, concerns,

      isEditModalOpen,
      isSaveModalOpen,

      beginYear,
      selectedId,

      tempConcerns,
      aggregates
    } = this.state;

    const trueBeginYear = beginYear ? extractStartYear(yearInfo[beginYear]) : '';

    const isPast = whetherReadOnlyForTYPResult(yearInfo, TYPs, selectedId);

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='三年計劃 - 關注事項及跟進方法' subTitle='新增三年計劃、設定關注事項、目標及跟進方法' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Table textAlign='center' celled unstackable fixed structured>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>年度</Table.HeaderCell>
                    <Table.HeaderCell width={5}>關注事項</Table.HeaderCell>
                    <Table.HeaderCell width={2}>達標程度</Table.HeaderCell>
                    <Table.HeaderCell width={6}>跟進方法</Table.HeaderCell>
                    <Table.HeaderCell width={2}>行動</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {hasGotYear && (TYPs.length > 0) ?
                    <>
                      {TYPs.map(({ id, beginYear }) => {
                        const startYear = extractStartYear(yearInfo[beginYear]);
                        const isPast = whetherReadOnlyForTYPResult(yearInfo, TYPs, id);

                        return (
                          concerns.filter(x=>x.typId===id).map(({ title, subTopic1, subTopic2, subTopic3, meetTarget, followUp }, i, a) => {
                            return (
                              <Table.Row key={id + ' ' + i}>
                                {!i && <Table.Cell rowSpan={a.length} className="break-word">{startYear}<br />|<br />{startYear + 3}</Table.Cell>}
                                <Table.Cell>
                                  {title}
                                  <SubTopicText sT1={subTopic1} sT2={subTopic2} sT3={subTopic3} sYear={startYear}/>
                                </Table.Cell>
                                <Table.Cell>{meetTarget ? this.meetTargetLookup[meetTarget] : '-'}</Table.Cell>
                                <Table.Cell>{followUp || '-'}</Table.Cell>
                                {!i && (<Table.Cell rowSpan={a.length} className="textlessCell">
                                  <Button color='blue' icon={isPast ? 'eye' : 'edit'} onClick={this.modalToggle('isEditModalOpen', id)} circular />
                                </Table.Cell>)}
                              </Table.Row>
                            )
                          })
                        )
                      })}
                    </>
                    :
                    <EmptyTableMsg colSpan='5' />
                  }
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        
        {dimmerOpen?(<FullscreenDimmer active={dimmerOpen} isLoading={true} />):(
          <>
            <Modal id="editTYPModal" open={isEditModalOpen} onClose={this.modalToggle('isEditModalOpen')} closeOnEscape={false} closeOnDimmerClick={false} size="fullscreen">
              <Modal.Header>設定三年計劃達標程度及跟進方法 ({trueBeginYear}-{trueBeginYear + 3})</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    <Table fixed celled structured>
                      <Table.Header>
                        <Table.Row textAlign='center'>
                          <Table.HeaderCell width={4} rowSpan={2}>關注項目</Table.HeaderCell>
                          <Table.HeaderCell width={6} colSpan={3}>每年結果</Table.HeaderCell>
                          <Table.HeaderCell width={2} rowSpan={2}>達標程度</Table.HeaderCell>
                          <Table.HeaderCell width={4} rowSpan={2}>跟進方法</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row textAlign='center'>
                          <Table.HeaderCell width={2}>第一年</Table.HeaderCell>
                          <Table.HeaderCell width={2}>第二年</Table.HeaderCell>
                          <Table.HeaderCell width={2}>第三年</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {tempConcerns.map((x, i) => {
                          let hasAllAggregate = true;
                          let avg = 0;
                          const yearResultFields = [1,2,3].map((y,i)=>{
                            const sub = x["subTopic"+y];
                            const agg = numRound(aggregates[i][x.id],2);
                            if(!agg) hasAllAggregate = false; else avg += agg;
                            return ({sub,agg});
                          });

                          if(hasAllAggregate){
                            this.inputChangeForConcern(i, 'select', 'meetTarget')({value: ['dafuq','no','part','most','all'][numRound(avg/3,0)]})
                          }

                          return (<Table.Row key={i}>
                            <Table.Cell>
                              <Header as="h4">{x.title}</Header>
                            </Table.Cell>
                            <Table.Cell colSpan={3} textAlign='center' style={{ marginBottom: 0 }}>
                              <Form.Group widths='equal'>
                                {yearResultFields.map(({sub,agg},i)=><Form.Field key={i}>{!!sub&&(<>{sub}<br/></>)}{agg?<AggregateNumber value={agg}/>:"-"}</Form.Field>)}
                              </Form.Group>
                            </Table.Cell>
                            <Table.Cell className='table-cell-with-dropdown'>
                              <Form.Select
                                disabled={isPast}
                                clearable
                                options={this.meetTargetOptions}
                                value={x.meetTarget||''}
                                onChange={this.inputChangeForConcern(i, 'select', 'meetTarget')}
                                style={{ minWidth: 'unset' }}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Form.TextArea disabled={isPast} value={x.followUp || ''} onChange={this.inputChangeForConcern(i, 'text', 'followUp')} />
                            </Table.Cell>
                          </Table.Row>);
                        })}
                      </Table.Body>
                    </Table>
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
                <Button disabled={isPast} color='green' content='儲存' icon='save' onClick={this.modalToggle('isSaveModalOpen')} circular />
              </Modal.Actions>
            </Modal>
            <ConfirmModal
              open={isSaveModalOpen}
              description='確定儲存三年計劃的所有變更？'
              cancel={this.modalToggle('isSaveModalOpen', false)}
              confirm={this.save}
              confirmText='確定並儲存'
              confirmIcon='save'
            />
          </>
        )}
      </>
    )
  }
}

export default withRouter(ThreeYearPlan);