import {
  escapeHtml,
  CheckBoxWithLabel,
  BooleanCheckBoxSection,
  FlexListSection,
  TableWithHeader,
  GridListSection,
  printLayout,
  SENNAME,
  SENREPORTFIELDS,
  treatBoolean,
  treatedDataToAggregateData,
  AdjustmentRecursiveFields
} from './../Helper/PrintHelper';

import { fileTimeStamp } from './../Helper/TimeHelper'; //filename date stamp

const printRecord = (excelFields, yearName, callback = () => { }) => {
  try {
    const layout = excelFields.map(data => {

      const chiName = data["學生姓名|中文姓名"] || data["學生姓名|英文姓名"];
      const classCode = data["班別（按年度）"];
      const classNo = data["學號（按年度）"];
      const strn = data.STRN;
      const consent = treatBoolean(data["SEMIS家長同意書|家長同意"], "同意", "不同意");
      const signDate = data["SEMIS家長同意書|簽署日期"];

      // convert type of SEN
      const SEN = SENNAME.map(x => treatBoolean(data['特殊教育需要類別|' + x.replace(/\n/g, '')], "是", ""));
      // treated in main
      SEN[SEN.length - 1] = data['特殊教育需要類別|其他'];

      // convert SEN reports
      const tempSenReport = {};
      let senReportsCount = Infinity;
      if(data['評估報告|上報']){
        for(let item of SENREPORTFIELDS){
          senReportsCount = Math.min(senReportsCount, (data["評估報告|" + item].match(/[\b]/g) || []).length + 1); // \b has different meaning in RegEx and JavaScript string LOL
        };
        if(senReportsCount === Infinity) senReportsCount = undefined;

        for(let item of SENREPORTFIELDS){
          tempSenReport[item] = data["評估報告|" + item].split('\b', senReportsCount);
        }
      }

      if(senReportsCount === Infinity) senReportsCount = undefined;

      const senReports = [];
      for (let i = 0; i < senReportsCount; i++) {
        senReports.push(
          ["評估機構", "評估日期", "評估結論", "評估後建議", "上報"].reduce((prev,cur)=>{
            return ({
              ...prev,
              [cur==='上報'?'上報與否':cur]: tempSenReport[cur][i].replace(/^\d+\.\s*/, '')
            })
          }, {})
        );
      }

      // convert SEN results
      const senResults = [];
      while (data.hasOwnProperty(`落後年級|年度#${senResults.length}`)) {
        senResults.push(["年度", "中文", "英文", "數學"].reduce((prev,cur)=>({
          ...prev,
          [cur]: data[`落後年級|${cur}#${senResults.length}`]
        }), {}));
      }

      //p1 handling
      const joinedP1SenAssessment = treatBoolean(data['及早識別和輔導有學習困難的小一學生'], "有", "沒有");

      const aggregateAdjustmentData = treatedDataToAggregateData(data);
      console.log(aggregateAdjustmentData);

      return `<div id="report-type">校方報告</div>
<h1>${process.env.REACT_APP_SCHOOL_NAME}</h1>
<h2>學生支援摘要 (${escapeHtml(yearName)}學年)</h2>
${
  FlexListSection({
    "姓名": chiName,
    "班別": classCode,
    "學號": classNo,
    "STRN": strn,
  })
}
${
  BooleanCheckBoxSection("SEMIS 家長同意書", consent, "同意", "不同意", consent != null ? `<div><strong>簽署日期: </strong>${escapeHtml(signDate)}</div>` : '')
}
${
  GridListSection(
    '特殊教育需要類別',
    SENNAME.map(
      (type, z) => type.indexOf('其他') !== -1 && SEN[z] ?
        CheckBoxWithLabel(type, SEN[z], { inline: false, others: [] }) :
        CheckBoxWithLabel(type, SEN[z], { inline: false })
    ),
    3
  )
}
${
  BooleanCheckBoxSection("學前兒童發展進度綜合報告", treatBoolean(data["學前兒童發展進度綜合報告"], "有", "沒有"), "有", "沒有")
}
${
  TableWithHeader("評估報告", senReports, "sen-report")
}
<div class="section">
  <h3>及早識別和輔導有學習困難的小一學生</h3>
  ${CheckBoxWithLabel("曾參加此計劃", joinedP1SenAssessment && data['及早識別和輔導有學習困難的小一學生|年度'], { inline: false, others: "年度" })}
  <div class="flex subsection">
    <div style="width: 4rem;">結果：</div>
    <div>
      ${CheckBoxWithLabel("有顯著的學習困難", joinedP1SenAssessment && data['及早識別和輔導有學習困難的小一學生|結果'] === "有顯著的學習困難", { inline: false })}
      ${CheckBoxWithLabel("有輕微的學習困難", joinedP1SenAssessment && data['及早識別和輔導有學習困難的小一學生|結果'] === "有輕微的學習困難", { inline: false })}
      ${CheckBoxWithLabel("沒有學習困難", joinedP1SenAssessment && data['及早識別和輔導有學習困難的小一學生|結果'] === "沒有學習困難", { inline: false })}
    </div>
  </div>
  ${CheckBoxWithLabel("沒有參加此計劃", joinedP1SenAssessment === false, { inline: false })}
</div>
${
  TableWithHeader("學習程度測量卷3.0成績（落後年級）", senResults, "sen-result")
}
${
  AdjustmentRecursiveFields('課堂上的支援', aggregateAdjustmentData['課堂上的支援'], true, true)
}
${yearName==='2019-2020' ? AdjustmentRecursiveFields('課堂外的支援', aggregateAdjustmentData['課堂外的支援'], true) : `<div class="section">
  ${CheckBoxWithLabel("課堂外的支援", data['課堂外的支援'] && data.groups.length, { inline: false, labelTag: 'h3' })}
  ${data.groups.map(x=>`<p>小組名稱：<span class='others'>&nbsp;${x.name}&nbsp;</span><br/>支援/訓練重點：<span class='others'>&nbsp;${x.mainFocus||'-'}&nbsp;</span><br/>詳情：<span class='others'>&nbsp;${x.startMonth||'-'}&nbsp;</span>月起每周<span class='others'>&nbsp;${x.sessionPerWeek||'-'}&nbsp;</span>節，每節<span class='others'>&nbsp;${x.sessionDuration||'-'}&nbsp;</span>分鐘，共<span class='others'>&nbsp;${x.totalSessions||'-'}&nbsp;</span>節<br/>家長同意：<span class='others'>&nbsp;${treatBoolean(x.parentConsent, "是", "否")}&nbsp;</span></p>`).join('\n')}
</div>`}
${
  AdjustmentRecursiveFields('家課調適', aggregateAdjustmentData['家課調適'], true)
}
${
  AdjustmentRecursiveFields('評估調適', aggregateAdjustmentData['評估調適'], true)
}
${
  AdjustmentRecursiveFields('家長配合', aggregateAdjustmentData['家長配合'], true)
}
${
  AdjustmentRecursiveFields('課前輔導班', aggregateAdjustmentData['課前輔導班'], true)
}
${
  AdjustmentRecursiveFields('加強輔導班', aggregateAdjustmentData['加強輔導班'], true)
}`;
    }).join('\n');
    printLayout(layout, `校方SEN報告 ${fileTimeStamp()}`, 'school-report', callback);
  } catch (err) {
    alert('ERROR');
    //console.clear();
    console.log(err);
    console.log(excelFields);
    callback();
  }
}

export default printRecord;