import {
  escapeHtml,
  printLayout,
} from '../../Helper/PrintHelper';

const getLayout = info => `
<div class='center section break-page'>
  <p>${process.env.REACT_APP_SCHOOL_NAME}</p>
  <p>${escapeHtml(info.yearName||"-")}年度&emsp;周五興趣小組專項小組評估表</p>
</div>
<div class="section">
<div class="inline-block">專項小組：<span class='others'>&nbsp;${escapeHtml(info.fridayActivityTitle)}&nbsp;</span>&nbsp;(&nbsp;${escapeHtml(info.fridayActivityCode)}&nbsp;)</div>
<div class="inline-block" style="float: right;">負責導師：<span class='others'>&nbsp;${escapeHtml(info.fridayActivityTeachers.join("、"))}&nbsp;</span></div>
</div>
<table class="special-report-table section" fixed>
  <colgroup>
    <col class="date"/>
    <col class="content"/>
    <col class="excellent"/>
    <col class="okay"/>
    <col class="normal"/>
    <col class="bad"/>
    <col class="other"/>
  </colgroup>
  <thead>
    <tr>
      <th rowSpan="2">日期</th>
      <th rowSpan="2">學習內容&nbsp;及<br/>學習活動</th>
      <th colSpan="5">學生學習興趣評估</th>
    </tr>
    <tr>
      <th>優</th>
      <th>良</th>
      <th>常</th>
      <th>可</th>
      <th>其他</th>
    </tr>
  </thead>
  <tbody>
    ${info.fridayActivityClasses.map(x=>`<tr><th scope="row">${escapeHtml(x.date)}</th><td>${escapeHtml(x.content||"-")}</td>${Array.from({length: 4}).map((y,i)=>`<td>${4-i===x.mark?"&#10004;":""}</td>`).join("\n")}<td>${x.mark?"":escapeHtml(x.remark||'')}</td></tr>`).join("\n")}
  </tbody>
</table>
<div>建議：<span class="others">${escapeHtml(info.suggestion)}</span></div>
`;

/**
 * Print Friday Activity Plan Record(s)
 * @param {Object[]|Object} info Info for record printing (support 1 object or array of objects) 
 * @param {Function} callback 
 */
const printRecord = (info, callback = () => {}) =>{
  try{
      const HTMLLayout = getLayout(info);

    printLayout(HTMLLayout, `${info.yearName} 專項小組評估表${info.fridayActivityTitle} (${+info.fridayActivityCode})`, 'special-report',  callback);

  }catch(err){
      alert('ERROR');
      console.clear();
      console.log(err);
      console.log(info);
      callback();
  }
}

export default printRecord;
