import {
  escapeHtml,
  CheckBoxWithLabel,
  FlexListSection,
  GridListSection,
  printLayout,
  SENNAME,
  treatBoolean,
  StudentFillForm,
  treatedDataToAggregateData,
  AdjustmentRecursiveFields,
} from './../Helper/PrintHelper';

import { fileTimeStamp } from './../Helper/TimeHelper'; //filename date stamp

const printRecord = (excelFields, yearName, callback) =>{
  try{
    const layout = excelFields.map(data => {
      const chiName = data["學生姓名|中文姓名"]||data["學生姓名|英文姓名"];
      const classCode = data["班別（按年度）"];
      const classNo = data["學號（按年度）"];
      const strn = data.STRN;

      // convert type of SEN
      const SEN = SENNAME.map(x => treatBoolean(data['特殊教育需要類別|'+x.replace(/\n/g,'')],"是","") );
      // treated in main
      SEN[SEN.length-1] = data['特殊教育需要類別|其他'];

      const aggregateAdjustmentData = treatedDataToAggregateData(data);

return `<div id="report-type">學生報告</div>
<h1>${process.env.REACT_APP_SCHOOL_NAME}</h1>
<h2>學生支援摘要 (${escapeHtml(yearName)})</h2>
${
  FlexListSection({
    "姓名": chiName,
    "班別": classCode,
    "學號": classNo,
    "STRN": strn,
  })
}
${
  GridListSection(
    '特殊教育需要類別',
    SENNAME.map(
      (type, i)=>type.indexOf('其他')!==-1&&SEN[i]?
        CheckBoxWithLabel(type,SEN[i],{inline: false, others: []}):
        CheckBoxWithLabel(type,SEN[i],{inline: false})
    ).filter((x,i)=>SEN[i]),
    3,{borderless:true}
  )
}
${
  AdjustmentRecursiveFields('課堂上的支援', aggregateAdjustmentData['課堂上的支援'], false, true)
}
${
  yearName==='2019-2020' ? AdjustmentRecursiveFields('課堂外的支援', aggregateAdjustmentData['課堂外的支援'], false) : (
    data['課堂外的支援'] && data.groups.length ? `<div class="section">
    ${CheckBoxWithLabel("課堂外的支援", true, { inline: false, labelTag: 'h3' })}
    ${data.groups.map(x=>`<p>小組名稱：<strong class='others'>&nbsp;${x.name}&nbsp;</strong><br/>支援/訓練重點：<span class='others'>&nbsp;${x.mainFocus||'-'}&nbsp;</span><br/>詳情：<span class='others'>&nbsp;${x.startMonth||'-'}&nbsp;</span>月起每周<span class='others'>&nbsp;${x.sessionPerWeek||'-'}&nbsp;</span>節，每節<span class='others'>&nbsp;${x.sessionDuration||'-'}&nbsp;</span>分鐘，共<span class='others'>&nbsp;${x.totalSessions||'-'}&nbsp;</span>節<br/>家長同意：<strong class='others'>&nbsp;${x.parentConsent ? "是" : "否"}&nbsp;</strong></p>`).join('\n')}
  </div>`:''
  )
}
${
  AdjustmentRecursiveFields('家課調適', aggregateAdjustmentData['家課調適'], false)
}
${
  AdjustmentRecursiveFields('評估調適', aggregateAdjustmentData['評估調適'], false)
}
${
  AdjustmentRecursiveFields('家長配合', aggregateAdjustmentData['家長配合'], false)
}
${
  AdjustmentRecursiveFields('課前輔導班', aggregateAdjustmentData['課前輔導班'], false)
}
${
  AdjustmentRecursiveFields('加強輔導班', aggregateAdjustmentData['加強輔導班'], false)
}
${StudentFillForm}`;
    }).join('\n');
    printLayout(layout, `學生SEN報告 ${fileTimeStamp()}`, 'student-report', callback);
  }catch(err){
    alert('ERROR');
    //console.clear();
    console.log(err);
    console.log(excelFields);
    callback();
  }
}

export default printRecord;