import moment from 'moment';
import { forEach, filter, find, sumBy, compact } from 'lodash';

export const TrainingReport = (printData, callback) => {
	try {
		const {
			deadline,
			yearInfo,
			appraisalItemTree,
			appraisalContentTree,
			appraisalLevelOption,
			teacherData,
		} = printData;
		let content = '';

		const trainingItem = find(appraisalItemTree, { displayName: '教師專業進修' });
		if (!trainingItem) throw new Error();
		let trainingContent = filter(appraisalContentTree, { appraisalItemId: trainingItem.id });

		forEach(teacherData, (data) => {
			const { teacherInfo, personalTableRecord } = data;
			const tableRecords = compact(personalTableRecord[trainingItem.id]);
			const tableLevelOption = filter(appraisalLevelOption, { appraisalItemId: trainingItem.id });

			/* report header */
			if (yearInfo) {
				content += `
                    <div class="header break-page">
                        <div class="title">馬鞍山聖若瑟小學</div>
                        <div class="subtitle">教師專業進修報告</div>
                        <div class="subtitle">${yearInfo.displayName}年度</div>
                    </div>
                `;
			}

			/* year and teacher info */
			if (yearInfo && teacherInfo) {
				content += `
                    <div class="teacher-info">
                        <div>年分: ${yearInfo.displayName}</div>
                        <div>老師名稱: ${teacherInfo.name}</div>
                        <div>職位: ${teacherInfo.displayName}</div>
                    </div>
                `;
			}

			if (trainingContent.length > 0) {
				let countTips = deadline ? '(總分只計算' + moment(deadline).format('DD/MM/YYYY') + '或以前輸入的分數)' : '';
				content += `
                    <div class="section">
                        <div class="text-bold">教師專業進修${countTips}</div>
                        <table class="table" border="1">
                            <thead>
                                <tr>
                                    ${DisplayContent(trainingContent)}
                                </tr>
                            </thead>
                            ${DisplayRecord(trainingContent, tableRecords)}
                            ${DisplayMark(
															deadline,
															appraisalContentTree,
															tableLevelOption,
															trainingItem,
															trainingContent,
															tableRecords
														)}
                        </table>
                    </div>
                `;
			}

			if (tableLevelOption.length > 0) {
				content += `
                    <div class="section">
                        <div class="text-bold">分數備註</div>
                        ${DisplayLevelOption(tableLevelOption)}
                    </div>
                `;
			}
		});

		/* print */
		let printWindow = window.open('', '_blank', '');
		printWindow.document.open();
		printWindow.document.write(`
            <html>
                <head>
                    <title>馬鞍山聖若瑟小學教師專業進修報告</title>
                    <link rel="stylesheet" type="text/css" href="/css/appraisalReportLayout.css">
                </head>
                <body>
                    <div class="appraisal-report">
                        ${content}
                    </div>
                </body>
            </html>
        `);
		printWindow.document.close();
		printWindow.focus();
		printWindow.onload = () => {
			printWindow.print();
			callback();
		};
	} catch (err) {
		alert('發生錯誤，請再嘗試');
		callback();
	}
};

const DisplayContent = (trainingContent) => {
	let content = '';
	forEach(trainingContent, ({ description, inputType }) => {
		let width = '10%';
		if (description === '日期 (日/月/20XX)') {
			description = '日期';
			width = '5%';
		}
		if (inputType === 'score') width = '5%';
		if (description === '提供機構/主辦機構(e.g.教育局、本校)') {
			description = '機構';
		}
		if (description === '事項/活動名稱') width = '20%';
		if (description === '內容(簡述目標)') width = '25%';
		content += `
            <th class='text-center' width='${width}'>${description}</th>
        `;
	});
	return content;
};

const DisplayRecord = (trainingContent, tableRecord) => {
	let content = '';
	forEach(tableRecord, (record) => {
		content += `<tr>`;
		forEach(trainingContent, ({ id, inputType }) => {
			content += `<td class="${inputType !== 'text' ? 'text-center' : ''}">${record[id]}</td>`;
		});
		content += `</tr>`;
	});
	return content;
};

const DisplayMark = (deadline, appraisalContentTree, tableLevelOption, trainingItem, trainingContent, tableRecords) => {
	let countTableContent = find(appraisalContentTree, { appraisalItemId: trainingItem.id, addUp: 1 });
	if (deadline) {
		tableRecords = filter(tableRecords, ({ submitDate }) => {
			return moment(submitDate).isSameOrBefore(deadline, 'day');
		});
	}

	let total = sumBy(tableRecords, countTableContent.id);
	let tableMark = 0;

	forEach(tableLevelOption, ({ mark, min }) => {
		if (total >= min && mark > tableMark) {
			return (tableMark = mark);
		}
	});

	let content = `
        <tr>
            <td class="text-center" colspan="${trainingContent.length}">
                <span>${countTableContent.description}: ${total}</span>  <span style="margin-left: 20px">分數: ${tableMark}</span>
            </td>
        </tr>
    `;

	return content;
};

const DisplayLevelOption = (tableLevelOption) => {
	let content = '';
	forEach(tableLevelOption, ({ description, mark }, index) => {
		content += `<span style="margin-right: 10px">${mark}分: ${description}</span>`;
	});
	return content;
};
