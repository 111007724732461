import Complete from './CompleteSurvey';
import Dashboard from './Dashboard';
import Group from './Group';
import School from './School';
import Stats from './Stats';

export default {
  Complete,
  Dashboard,
  Group,
  School,
  Stats,
}