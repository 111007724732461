/* import panel componet */
import Panel from '../Modules/Panel/Panel';

/* import user setting */
import User_Setting from '../Modules/User/UserSetting';

/* import setting components */
import Setting from '../Modules/Setting';

/* import sen components */
import Sen from '../Modules/Sen';

/* import appraisal components */
import Appraisal_Content from '../Modules/Appraisal/Content/Content';

import Appraisal_Content_Form from '../Modules/Appraisal/Content/EditForm';
import Appraisal_Content_Table from '../Modules/Appraisal/Content/EditTable';

import Appraisal_Target from '../Modules/Appraisal/Target/Target';
import Appraisal_Target_Form from '../Modules/Appraisal/Target/EditForm';
import Appraisal_Target_Table from '../Modules/Appraisal/Target/EditTable';

import Appraisal_Item from '../Modules/Appraisal/Item/Item';
import Appraisal_Item_View_Before from '../Modules/Appraisal/Item/ViewBefore';
import Appraisal_Item_View_Self from '../Modules/Appraisal/Item/ViewSelf';
import Appraisal_Item_View_Other_Write from '../Modules/Appraisal/Item/ViewOtherWrite';
import Appraisal_Item_View_Other from '../Modules/Appraisal/Item/ViewOther';

import Appraisal_List_Table_List from '../Modules/Appraisal/List/TableList';
import Appraisal_List_Form_List from '../Modules/Appraisal/List/FormList';

import Appraisal_Form_Edit_Table from '../Modules/Appraisal/Form/EditTable';
import Appraisal_Form_Edit_Before from '../Modules/Appraisal/Form/EditBefore';
import Appraisal_Form_Edit_Self from '../Modules/Appraisal/Form/EditSelf';
import Appraisal_Form_Edit_Other_Write from '../Modules/Appraisal/Form/EditOtherWrite';
import Appraisal_Form_Edit_Other from '../Modules/Appraisal/Form/EditOther';

import Appraisal_Appeal from '../Modules/Appraisal/Appeal/Appeal';
import Appraisal_Appeal_Item from '../Modules/Appraisal/Appeal/Item';

import Appraisal_Report from '../Modules/Appraisal/Report/Report';

import Appraisal_Group from '../Modules/Appraisal/Group/Group';

import Appraisal_Notification from '../Modules/Appraisal/Notification/Notification';

import Plan from '../Modules/Plan';

import Report from '../Modules/Report';

import Survey from '../Modules/Survey';

import Task_Dashboard from '../Modules/Task/Dashboard';
import Task_Submit from '../Modules/Task/Submit';

import ECA from '../Modules/ECA';

import Talent from '../Modules/Talent';

/* Not Found */
import Not_Found from '../Modules/NotFound/NotFound';

const RouterConfig = [
	// Panel
	{
		path: '/panel',
		exact: true,
		Content: Panel,
		wide: true,
	},

	// User Setting
	{
		path: '/user/setting',
		exact: true,
		Content: User_Setting,
	},

	// Sen
	{
		path: '/sen/consent',
		exact: true,
		Content: Sen.Consent,
	},
	{
		path: '/sen/record/:studentId',
		exact: true,
		Content: Sen.Record,
	},
	{
		path: '/sen/export',
		exact: true,
		Content: Sen.Export,
	},
	{
		path: '/sen/report',
		exact: true,
		Content: Sen.Report,
	},
	{
		path: '/sen/group',
		exact: true,
		Content: Sen.Group,
	},

	// Appraisal Content
	{
		path: '/appraisal/content',
		exact: true,
		Content: Appraisal_Content,
	},
	{
		path: '/appraisal/content/form/:yearId/:appraisalItemId',
		exact: true,
		Content: Appraisal_Content_Form,
	},
	{
		path: '/appraisal/content/table/:yearId/:appraisalItemId',
		exact: true,
		Content: Appraisal_Content_Table,
	},

	// Appriasal Target
	{
		path: '/appraisal/target',
		exact: true,
		Content: Appraisal_Target,
	},
	{
		path: '/appraisal/target/edit_form/:yearId/:appraisalItemId',
		exact: true,
		Content: Appraisal_Target_Form,
	},
	{
		path: '/appraisal/target/edit_table/:yearId/:appraisalItemId',
		exact: true,
		Content: Appraisal_Target_Table,
	},

	// Appraisal Item
	{
		path: '/appraisal/item',
		exact: true,
		Content: Appraisal_Item,
	},
	{
		path: '/appraisal/:section/view_before/:appraisalItemId/:appraisalTargetId/:teacherId?',
		exact: true,
		Content: Appraisal_Item_View_Before,
	},
	{
		path: '/appraisal/:section/view_self/:appraisalItemId/:appraisalTargetId/:teacherId?',
		exact: true,
		Content: Appraisal_Item_View_Self,
	},
	{
		path: '/appraisal/:section/view_other_write/:appraisalItemId/:appraisalTargetId/:appraisalMarkingId/:teacherId?',
		exact: true,
		Content: Appraisal_Item_View_Other_Write,
	},
	{
		path: '/appraisal/:section/view_other/:appraisalItemId/:appraisalTargetId/:appraisalMarkingId/:teacherId?',
		exact: true,
		Content: Appraisal_Item_View_Other,
	},

	// Appraisal List
	{
		path: '/appraisal/:section?/table_list/:appraisalItemId/:teacherId?',
		exact: true,
		Content: Appraisal_List_Table_List,
	},
	{
		path: '/appraisal/:section?/form_list/:appraisalItemId/:teacherId?',
		exact: true,
		Content: Appraisal_List_Form_List,
	},

	// Apprisal Form
	{
		path: '/appraisal/:section/edit_table/:appraisalItemId/:appraisalTableRecordId?/:teacherId?',
		exact: true,
		Content: Appraisal_Form_Edit_Table,
	},
	{
		path: '/appraisal/:section/edit_before/:appraisalItemId/:appraisalTargetId/:teacherId?',
		exact: true,
		Content: Appraisal_Form_Edit_Before,
	},
	{
		path: '/appraisal/:section/edit_self/:appraisalItemId/:appraisalTargetId/:teacherId?',
		exact: true,
		Content: Appraisal_Form_Edit_Self,
	},
	{
		path: '/appraisal/:section/edit_other_write/:appraisalItemId/:appraisalTargetId/:appraisalMarkingId/:teacherId?',
		exact: true,
		Content: Appraisal_Form_Edit_Other_Write,
	},
	{
		path: '/appraisal/:section/edit_other/:appraisalItemId/:appraisalTargetId/:appraisalMarkingId/:teacherId?',
		exact: true,
		Content: Appraisal_Form_Edit_Other,
	},

	// Apprasial Appeal
	{
		path: '/appraisal/appeal',
		exact: true,
		Content: Appraisal_Appeal,
	},
	{
		path: '/appraisal/appeal/item/:yearId/:teacherId',
		exact: true,
		Content: Appraisal_Appeal_Item,
	},

	// Appraisal Report
	{
		path: '/appraisal/report',
		exact: true,
		Content: Appraisal_Report,
	},

	// Appraisal Group
	{
		path: '/appraisal/group',
		exact: true,
		Content: Appraisal_Group,
	},

	// Appraisal Notification
	{
		path: '/appraisal/notification',
		exact: true,
		Content: Appraisal_Notification,
	},

	//Setting
	{
		path: '/setting/permission',
		exact: true,
		Content: Setting.Permission,
	},
	{
		path: '/setting/year',
		exact: true,
		Content: Setting.Year,
	},
	{
		path: '/setting/student',
		exact: true,
		Content: Setting.Student,
	},
	{
		path: '/setting/teacher',
		exact: true,
		Content: Setting.Teacher,
	},
	{
		path: '/setting/schoolinfo',
		exact: true,
		Content: Setting.SchoolInfo,
	},
	{
		path: '/setting/group',
		exact: true,
		Content: Setting.Group,
	},
	{
		path: '/setting/grouppersonnel',
		exact: true,
		Content: Setting.GroupPersonnel,
	},
	{
		path: '/setting/location',
		exact: true,
		Content: Setting.Location,
	},

	// School Plans

	// School 3 Year Plan
	{
		path: '/plan/3year',
		exact: true,
		Content: Plan.ThreeYear.Concerns,
	},
	{
		path: '/plan/3year/swop',
		exact: true,
		Content: Plan.ThreeYear.ReviewAndSWOP,
	},
	{
		path: '/plan/3year/strategy',
		exact: true,
		Content: Plan.ThreeYear.AimsAndStrategies,
	},
	// School 1 Year Plan
	{
		path: '/plan/1year/swop',
		exact: true,
		Content: Plan.OneYear.ReviewAndSWOP,
	},
	{
		path: '/plan/1year/strategy',
		exact: true,
		Content: Plan.OneYear.Strategies,
	},
	{
		path: '/plan/1year/evaluation',
		exact: true,
		Content: Plan.OneYear.Evaluations,
	},
	// Group 1 Year Plan
	{
		path: '/plan/group/swop',
		exact: true,
		Content: Plan.Group.SWOP,
	},
	{
		path: '/plan/group/strategy',
		exact: true,
		Content: Plan.Group.Strategies,
	},
	{
		path: '/plan/group/timetable',
		exact: true,
		Content: Plan.Group.Timetable,
	},
	{
		path: '/plan/group/budget',
		exact: true,
		Content: Plan.Group.Budget,
	},
	{
		path: '/plan/group/evaluation',
		exact: true,
		Content: Plan.Group.Evaluations,
	},
	{
		path: '/report/file/school_action/:actionId',
		exact: true,
		Content: Plan.FileManagement,
	},
	{
		path: '/report/file/group_action/:groupActionId',
		exact: true,
		Content: Plan.FileManagement,
	},
	{
		path: '/plan/survey/:evaluationId',
		exact: true,
		Content: Plan.SurveyDesign,
	},
	{
		path: '/plan/group/survey/:groupEvaluationId',
		exact: true,
		Content: Plan.SurveyDesign,
	},
	{
		path: '/plan/export',
		exact: true,
		Content: Plan.ExportFile,
	},

	// School Reports
	{
		path: '/report/3yearconcern',
		exact: true,
		Content: Report.ThreeYear.Concerns,
	},
	{
		path: '/report/1yearconcern',
		exact: true,
		Content: Report.OneYear.Concerns,
	},
	{
		path: '/report/1yearstrategy',
		exact: true,
		Content: Report.OneYear.Strategies,
	},
	{
		path: '/report/1yearfile',
		exact: true,
		Content: Report.OneYear.File,
	},
	{
		path: '/report/1yearmidtermreport',
		exact: true,
		Content: Report.OneYear.MidTermReport,
	},
	{
		path: '/report/1yearsummary',
		exact: true,
		Content: Report.OneYear.Summary,
	},
	{
		path: '/report/groupstrategy',
		exact: true,
		Content: Report.Group.Strategies,
	},
	{
		path: '/report/groupfile',
		exact: true,
		Content: Report.Group.File,
	},
	{
		path: '/report/groupmidtermreport',
		exact: true,
		Content: Report.Group.MidTermReport,
	},
	{
		path: '/report/groupsummary',
		exact: true,
		Content: Report.Group.Summary,
	},
	{
		path: '/report/l_and_t',
		exact: true,
		Content: Report.Report.L_and_T,
	},
	{
		path: '/report/student_support',
		exact: true,
		Content: Report.Report.StudentSupport,
	},
	{
		path: '/report/student_performance',
		exact: true,
		Content: Report.Report.StudentPerformance,
	},
	{
		path: '/report/attachment_list',
		exact: true,
		Content: Report.Report.AttachmentList,
	},
	{
		path: '/report/financial_report',
		exact: true,
		Content: Report.Report.FinancialReport,
	},
	{
		path: '/report/export',
		exact: true,
		Content: Report.ExportFile,
	},
	{
		path: '/survey/school',
		exact: true,
		Content: Survey.School,
	},
	{
		path: '/survey/group',
		exact: true,
		Content: Survey.Group,
	},
	{
		path: '/survey/stats/:surveyId',
		exact: true,
		Content: Survey.Stats,
	},
	{
		path: '/survey/complete/:surveyId',
		exact: true,
		Content: Survey.Complete,
	},
	{
		path: '/task/dashboard',
		exact: true,
		Content: Task_Dashboard,
	},
	{
		path: '/task/submit/:taskId',
		exact: true,
		Content: Task_Submit,
	},

	// ECA
	{
		path: '/eca/activity/summary',
		exact: true,
		Content: ECA.Activity.Summary,
	},
	{
		path: '/eca/activity/personal',
		exact: true,
		Content: ECA.Activity.Personal,
	},
	{
		path: '/eca/activity/conflict',
		exact: true,
		Content: ECA.Activity.Conflict,
	},
	{
		path: '/eca/activity/:section/edit/:yearId/:activityId?',
		exact: true,
		Content: ECA.Activity.Edit,
	},
	{
		path: '/eca/activity/:section/detail/:yearId/:activityId',
		exact: true,
		Content: ECA.Activity.Detail,
	},
	{
		path: '/eca/activity/:section/attendance/:yearId/:activityId/:activityClassId?',
		exact: true,
		Content: ECA.Activity.Attendance,
	},
	{
		path: '/eca/outsideActivity/summary',
		exact: true,
		Content: ECA.OutsideActivity.Summary,
	},
	{
		path: '/eca/outsideActivity/personal',
		exact: true,
		Content: ECA.OutsideActivity.Personal,
	},
	{
		path: '/eca/outsideActivity/:section/edit/:yearId/:outsideActivityId?',
		exact: true,
		Content: ECA.OutsideActivity.Edit,
	},
	{
		path: '/eca/outsideActivity/:section/detail/:yearId/:outsideActivityId',
		exact: true,
		Content: ECA.OutsideActivity.Detail,
	},
	{
		path: '/eca/outsideActivity/:section/students/:yearId/:outsideActivityId',
		exact: true,
		Content: ECA.OutsideActivity.Attendance,
	},
	{
		path: '/eca/outsideActivity/:section/awards/:yearId/:outsideActivityId',
		exact: true,
		Content: ECA.OutsideActivity.Awards,
	},
	{
		path: '/eca/fridayActivity/summary',
		exact: true,
		Content: ECA.FridayActivity.Summary,
	},
	{
		path: '/eca/fridayActivity/personal',
		exact: true,
		Content: ECA.FridayActivity.Personal,
	},
	{
		path: '/eca/fridayActivity/:section/edit/:yearId/:fridayActivityId?',
		exact: true,
		Content: ECA.FridayActivity.Edit,
	},
	{
		path: '/eca/fridayActivity/:section/detail/:type/:yearId/:fridayActivityId',
		exact: true,
		Content: ECA.FridayActivity.Detail,
	},
	{
		path: '/eca/fridayActivity/student',
		exact: true,
		Content: ECA.FridayActivity.Student.Core,
	},
	{
		path: '/eca/fridayActivity/studentEdit/:yearId',
		exact: true,
		Content: ECA.FridayActivity.Student.Edit,
	},
	{
		path: '/eca/fridayActivity/lesson',
		exact: true,
		Content: ECA.FridayActivity.Lesson.Core,
	},
	{
		path: '/eca/fridayActivity/lessonEdit/:yearId',
		exact: true,
		Content: ECA.FridayActivity.Lesson.Edit,
	},
	{
		path: '/eca/fridayActivity/:section/attendance/:type/:yearId/:fridayActivityId/:fridayActivityClassId',
		exact: true,
		Content: ECA.FridayActivity.Attendance,
	},
	{
		path: '/eca/fridayActivity/:section/report/:type/:yearId/:fridayActivityId/:fridayActivityClassId',
		exact: true,
		Content: ECA.FridayActivity.Report,
	},
	{
		path: '/eca/fridayActivity/normalgroup',
		exact: true,
		Content: ECA.FridayActivity.NormalGroup.Core,
	},
	{
		path: '/eca/fridayActivity/normalgroupedit/:yearId',
		exact: true,
		Content: ECA.FridayActivity.NormalGroup.Edit,
	},
	{
		path: '/eca/fridayActivity/normalgroup/detail/:normalGroupId',
		exact: true,
		Content: ECA.FridayActivity.NormalGroup.Detail,
	},
	{
		path: '/talent/list',
		exact: true,
		Content: Talent.List,
		wide: true,
	},
	{
		path: '/talent/type',
		exact: true,
		Content: Talent.Type,
	},
	{
		path: '/talent/activity',
		exact: true,
		Content: Talent.Activity,
		wide: true,
	},

	// Not found
	{
		exact: true,
		Content: Not_Found,
	},
];

export default RouterConfig;
