/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get, post } from '../../Helper/ApiHelper';
import { pick } from 'lodash';

/* import components */
import { BlockerPrompt, PageHeader, FullscreenDimmer, ConfirmModal } from '../../Components';

/* import semantic-ui element */
import { Grid, Form, Button, Segment, Checkbox, Header, Icon } from 'semantic-ui-react';

/* import components */
import { inputHandler } from '../../Helper/FormHelper';

class CompleteSurvey extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dimmerOpen: false,
			isSaveModalOpen: false,
			dimmerMsg: '',
			id: null,
			endDate: null,
			isBlocking: false,
			errorActive: false,
			title: '問卷載入中',
			desc: '',
			questionSubmission: null,
		};
	}

	setStateAsync = (state) =>
		new Promise((res, rej) => {
			if (this.mounted) this.setState(state, res);
			else rej('unmounted');
		});

	componentDidMount = async () => {
		this.mounted = true;
		try {
			await this.getAllInfo();
		} catch (err) {
			console.log('Error when mounting');
			console.log(err);
		}
	};

	componentDidUpdate(prevProps) {
		const newId = this.props.match.params.surveyId;
		const oldId = prevProps.match.params.surveyId;
		if (newId !== oldId) {
			this.getAllInfo();
		}
	}

	componentWillUnmount = () => {
		this.mounted = false;
	};

	getAllInfo = async () => {
		try {
			const { surveyId } = this.props.match.params;
			const result = await get(`getOneSurvey/${+surveyId}`);
			if (result.length === 0) {
				alert('問卷不存在或您沒有相關權限，現在會將您帶回主目錄');
				this.props.history.replace('/survey/dashboard');
			}
			await this.setStateAsync(result[0]);
		} catch (err) {
			console.log('Error when getting survey info');
		}
		if (this.state.id) {
			try {
				const questionSubmission = await get(`getSurveyQuestionForSubmission/${+this.state.id}`);
				await this.setStateAsync({ questionSubmission });
			} catch (err) {
				console.log('Error when getting survey info');
			}
		}
	};

	/* input update handler */
	inputChange = (event, data) => {
		const { inputType, index, stateName } = event.target.closest('.ui, textarea, button').dataset;
		const questionSubmission = [...this.state.questionSubmission];
		const question = { ...questionSubmission[index] };
		question[stateName] = inputHandler(inputType, data);

		questionSubmission[index] = question;
		this.setStateAsync({
			questionSubmission,
			isBlocking: true,
		});
	};

	saveModalToggle = () => {
		this.setStateAsync({
			isSaveModalOpen: !this.state.isSaveModalOpen,
		});
	};

	save = (confirm) => async (event) => {
		const { id, questionSubmission } = this.state;
		try {
			if (confirm && questionSubmission.filter((x) => x.score === undefined && x.type === 'mcq').length) {
				this.setStateAsync({ errorActive: true });
				alert('請檢查是否已完成所有問題');
				return;
			}
			await this.setStateAsync({ dimmerOpen: true, errorActive: false, dimmerMsg: '' });

			const res = await post('saveSurvey', {
				id,
				confirm,
				questions: questionSubmission.map((x) => pick(x, ['id', 'score', 'text'])),
			});

			if (!res || !res.status) {
				await this.dimmerDisplayMessage(
					<Header as="h2" inverted icon>
						<Icon name="warning" />
						儲存資料發生錯誤
						<br />
						{typeof res.err === 'string' ? res.err : res.message}
					</Header>
				);
				setTimeout(() => {
					this.setStateAsync({ dimmerOpen: false });
				}, 3000);
			} else {
				await this.dimmerDisplayMessage(
					<Header as="h2" inverted icon>
						<Icon name="check" />
						{confirm ? '成功提交資料。現在會將您帶回主頁' : '成功儲存資料。'}
					</Header>
				);
				await this.setStateAsync({ isBlocking: false });
				setTimeout(() => {
					if (confirm) this.props.history.replace('/panel');
					else this.setStateAsync({ dimmerOpen: false });
				}, 3000);
			}
		} catch (err) {
			if (err !== 'unmounted') console.log('Error when saving', err);
			this.setStateAsync({ dimmerOpen: false });
		}
		try {
			await this.getAllInfo();
		} catch (err) {
			if (err !== 'unmounted') console.log('Error when refetching', err);
		}
	};

	dimmerDisplayMessage = async (dimmerMsg) => {
		try {
			this.setStateAsync({ dimmerMsg });
		} catch (err) {
			if (err !== 'unmounted') console.log('Error when displaying error message', err);
		}
	};

	render() {
		const { title, description, questionSubmission, isBlocking, errorActive, dimmerOpen, dimmerMsg, isSaveModalOpen } =
			this.state;
		if (questionSubmission === null) {
			return (
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<PageHeader title={title} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			);
		}
		return (
			<>
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<PageHeader title={title} subTitle={description} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Segment padded="very">
								<Form onSubmit={this.submit}>
									<Form.Group unstackable>
										<Form.Field width={9}>
											<h4>問題</h4>
										</Form.Field>
										<Form.Field width={7}>
											<Form.Group widths={5} unstackable>
												<Form.Field>極度不同意</Form.Field>
												<Form.Field>不同意</Form.Field>
												<Form.Field>同意</Form.Field>
												<Form.Field>極度同意</Form.Field>
												<Form.Field>不適用</Form.Field>
											</Form.Group>
										</Form.Field>
									</Form.Group>
									{questionSubmission.map((question, i) => (
										<Form.Group key={question.id} unstackable>
											<Form.Field width={9}>
												<h4>{question.title}</h4>
											</Form.Field>
											<Form.Field
												width={7}
												className="center align"
												error={errorActive && (question.score === undefined || question.score === null)}
											>
												<Form.Group widths={5} unstackable>
													{['極度不同意', '不同意', '同意', '極度同意', '不適用'].map((x, j) => (
														<Form.Field key={j}>
															<Checkbox
																type="checkbox"
																radio
																name={'' + question.id}
																value={(j + 1) % 5}
																checked={question.score === (j + 1) % 5}
																data-state-name="score"
																data-input-type="select"
																data-index={i}
																onChange={this.inputChange}
															/>
														</Form.Field>
													))}
												</Form.Group>
											</Form.Field>
										</Form.Group>
									))}
									<Button
										color="green"
										content="提交"
										icon="pencil alternate"
										floated="right"
										onClick={this.saveModalToggle}
										type="submit"
									/>
									<Button
										color="blue"
										content="儲存"
										icon="save"
										floated="right"
										onClick={this.save(false)}
										type="button"
									/>
								</Form>
							</Segment>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				{dimmerOpen ? (
					<FullscreenDimmer active={true} content={dimmerMsg} isLoading={!dimmerMsg} />
				) : (
					<ConfirmModal
						open={isSaveModalOpen}
						description="問卷提交後就無法修改，您確定要提交問卷？"
						cancel={this.saveModalToggle}
						confirm={this.save(true)}
						confirmIcon="check"
						confirmText="儲存"
					/>
				)}
				<BlockerPrompt isBlocking={isBlocking} />
			</>
		);
	}
}

export default withRouter(CompleteSurvey);
