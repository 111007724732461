/* import lodash */
import {keyBy, chain, pick, find, findIndex} from 'lodash';

/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	FullscreenDimmer,
	ConfirmModal,
	EmptyTableMsg,
  DateInput,
  MySelect,
} from '../../Components';

/* import helper functions */
import { get, post, getTaskFile } from '../../Helper/ApiHelper';
import {
  tableSorting,
  getTeacherOptions
} from '../../Helper/Helper';
import { inputHandler, extOptions } from '../../Helper/FormHelper';
import { displayDateTime, momentToDate, isValid, dateToMoment, momentToDbFormat } from '../../Helper/TimeHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, Header, Input, Select } from 'semantic-ui-react';

class SubmitTaskFile extends Component {
  constructor(props) {
    super(props);

    this.handle=Array.from({length: 5}).map(x=>"QWERTYUIOPASDFGHJKLZXCVBNM"[Math.floor(Math.random()*26)]).join("");

    this.state = {

      files: [],
      versions: [],
      finishedLoading: false,
      dimmerOpen: false,

      task: null,

      mainTableSort: ['fileName', 'ascending'],
      isError: false,
      file: null,

      dueDate: dateToMoment(),
      title: '',
      extension: '',

      teacherOptions: [],
      teacher_target: [],

      isEditModalOpen: false,
      isDeleteAllModalOpen: false,
      isDeleteModalOpen: false,
      isEditTaskModalOpen: false,
      fileName: '',
      seletedId: null,
    }

    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      messages: {
        default: '請輸入資料'
      },
      autoForceUpdate: this,
    });

    this.taskValidator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} />,
      messages: {
        required: '請提供:attribute',
        accepted: '請最少選擇:attribute',
      },
      autoForceUpdate: this
    });

    this.tableHeader = [
      {
        displayName: '檔案名稱',
        columnName: 'fileName',
        //x is Object.entries
        sortable: true,
        isIntegerSort: false,
        width: 4,
      },
      {
        displayName: '備註 (最新)',
        columnName: 'remark',
        sortable: false,
        isIntegerSort: false,
        width: 6,
      },
      {
        displayName: '上載者',
        columnName: 'teacherName',
        sortable: false,
        isIntegerSort: false,
        width: 2,
      },
      {
        displayName: '最後上載時間',
        columnName: 'uploaded',
        sortable: true,
        isIntegerSort: false,
        width: 3,
      },
    ];
    this.fileInput = React.createRef();
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try {
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    } catch (err) {
      console.log('Error when mounting file management');
      console.log(err);
    }
  }

  fetch = async () => {
    try {
      const taskId = +this.props.match.params.taskId;

      const [task, taskTargets, files, teachers, teacherOptions] = await Promise.all([
        get(`getTask/${taskId}`),
        get(`getTaskTarget/${taskId}`),
        get(`getTaskFileList/${taskId}`),
        get('getAllTeacherWithAdminInSchool'),
        getTeacherOptions(),
      ]);

      if (task.length) {
        await this.setStateAsync({
          task: task[0],
          taskTargets,
          isOwner: files.isOwner,
          [this.handle]: files.teacherId,
          files: files.status ? files.result : [],
          teachers: keyBy(teachers, 'id'),
          teacherOptions,
        });
      } else {
        await this.setStateAsync({
          task: undefined,
          taskTargets: [],
          files: [],
          teachers: keyBy(teachers, 'id'),
          teacherOptions,
        });
      }
    } catch (err) {
      console.log("Error when fetching file info");
      console.log(err);
    }
  }

  /* table sorting */
  sorting = (clickedColumn) => () => {
    const { files, mainTableSort } = this.state;
    const sortingResult = tableSorting(clickedColumn, files, mainTableSort[0], mainTableSort[1]);
    this.setStateAsync({
      mainTableSort: [clickedColumn, sortingResult.sortDirection],
      files: sortingResult.data
    });
  }

  /* input update handler */
  inputChange = (inputType, stateName, callback = () => { }) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value }, callback)
  }

  /* modal toggle */
  modalToggle = (modalStateName, fileName = '') => () => {
    this.setStateAsync({
      [modalStateName]: !this.state[modalStateName],
      remark: '',
      fileName
    });
  }

  /* modal toggle */
  modalToggle2 = (modalStateName, selectedId = null) => () => {
    this.setStateAsync({
      [modalStateName]: !this.state[modalStateName],
      selectedId
    });
  }
  
  taskModalToggle = () => {
    const isOpen = this.state.isEditTaskModalOpen;
    if(isOpen){
      this.setStateAsync({
        isEditTaskModalOpen: false,
        title: '', dueDate: null,
        teacher_target: [],
      });
      
    }else{
      const task = this.state.task||{}

      this.setStateAsync({
        isEditTaskModalOpen: true,
        title: task.title,
        dueDate: dateToMoment(task.dueDate),
        teacher_target: this.state.taskTargets.map(x=>x.teacherId)
      });
    }
  }

  edit = (fileName, teacherId) => async () => {
    try {
      await this.setStateAsync({ isError: false });
    } catch (err) { console.log("Error when editing"); }

    if (!fileName) {
      try {
        this.setStateAsync({
          versions: [],
          fileName: '',
          remark: '',
          isEditModalOpen: false,
          isError: false
        });
      } catch (err) { console.log("Error when editing"); }
      return;
    }

    try {
      const versions = chain(this.state.files).filter({fileName, teacherId}).orderBy(['uploaded'], ['desc']).value();

      await this.setStateAsync({
        versions,
        fileName,
        remark: '',
        isEditModalOpen: true
      });
    } catch (err) {
      console.log("Error when retrieving file version data");
      console.log(err);
    }
  }

  download = (id, fileName) => async () => {
    try {
      await getTaskFile({ id }, fileName);
    } catch (err) {
      console.log("Error when retrieving file");
      console.log(err);
    }
  }

  /* save record */
  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    try {
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });

      const data = pick(this.state, ['fileName', 'file', 'remark']);
      await post('saveNewTaskFile', {
        ...data,
        taskId: +this.props.match.params.taskId,
      }, 'FORMDATA');
    } catch (err) {
      console.log("Error when saving file");
      console.log(err);
    }
    await this.fetch();
    this.setStateAsync({
      finishedLoading: true,
      isEditModalOpen: false,
      dimmerOpen: false,
    })
  }

  /* delete record */
  delete = async () => {
    try {
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      await post('removeTaskFileVersion', { id: this.state.selectedId });
    } catch (err) {
      console.log("Error when removing file");
      console.log(err);
    }
    await this.fetch();
    await this.edit(this.state.fileName)();
    this.setStateAsync({
      finishedLoading: true,
      isDeleteModalOpen: false,
      dimmerOpen: false,
    })
  }

  deleteAll = async () => {
    try {
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      await post('removeTaskFile', {
        fileName: this.state.fileName,
        taskId: +this.props.match.params.taskId
      });
    } catch (err) {
      console.log("Error when removing file");
      console.log(err);
    }
    await this.fetch();
    this.setStateAsync({
      finishedLoading: true,
      isDeleteAllModalOpen: false,
      isEditModalOpen: false,
      dimmerOpen: false,
    })
  }

  editTask = async () => {
    if (!this.taskValidator.allValid()) {
      this.taskValidator.showMessages();
      return;
    }

    const id = this.props.match.params.taskId;

    const data = {
      title: this.state.title,
      id,
      dueDate: momentToDbFormat(this.state.dueDate),
    }
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true
      });
      const result = await post('editTask', data);
      if(!result.status){
        alert("編輯時發生錯誤");
      }else{
        await post('batchEditTaskTarget', {
          taskId: id,
          teacher: this.state.teacher_target
        });
      }
    }catch(err){
      console.log("Error when editing task");
      console.log(err);
    }
    
    await this.fetch();
    try{
      await this.setStateAsync({
        finishedLoading: true,
        isEditTaskModalOpen: false,
        dimmerOpen: false
      });
    }catch(err){}
  }

  getExtensionList = (value) => {
    const extensionString = find(extOptions, {value}).text.split(/[()]/)[1];
    const ans =  extensionString.split(/,\s*/);
    return ans;
  }

  parseFile = async (event) => {
    event.preventDefault();
    if (this.fileInput.current.files.length) {
      const file = this.fileInput.current.files[0];
      const { fileName } = this.state;
      if (
        (fileName && this.getExtension(file.name) !== this.getExtension(fileName)) ||
        (!fileName && this.getExtensionList(this.state.task.extension).indexOf(this.getExtension(file.name))===-1)
      ) {
        await this.setStateAsync({
          file: null,
          isError: "請確認檔案副檔名"
        });
      } else {
        await this.setStateAsync({
          file,
          isError: false
        });
      }
    }
  }

  changeSelected = (target) => {
    this.setStateAsync({
      teacher_target: target||[]
    });
  }

  getExtension = (fileName) => {
    const m = fileName.match(/\.[^.]+$/);
    return m ? m[0] : ""
  }

  render() {
    const {
      task,
      isOwner,
      files,
      versions,

      taskTargets,

      finishedLoading,
      dimmerOpen,

      isEditModalOpen,
      isEditTaskModalOpen,
      isDeleteAllModalOpen,
      isDeleteModalOpen,

      remark,
      fileName,
      isError,
      file,

      dueDate,
      title,

      teachers,

      teacherOptions,
      teacher_target,

      mainTableSort,
    } = this.state;

    this.validator.purgeFields();
    this.taskValidator.purgeFields();

    const canUploadVersion = !fileName || (!!versions && versions[0] && versions[0].teacherId === this.state[this.handle]);
    let pageContent = '';

    if (!finishedLoading) {
      pageContent = (<Grid>
        <Grid.Row>
          <Grid.Column>
            <Header textAlign="center" as="h1">資料載入中</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>);
    }else{
      if (task === undefined) {
        pageContent = (<Grid>
          <Grid.Row>
            <Grid.Column>
              <Header textAlign="center" as="h1">找不到檔案提交任務資料</Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>);
      }else{
        const canUpload = findIndex(taskTargets, {teacherId: this.state[this.handle]}) > -1;

        const latestFiles = chain(files).orderBy(['uploaded'], ['desc']).uniqWith((x,y)=>x.fileName===y.fileName && x.teacherId === y.teacherId).value();
        pageContent = (<Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title={'檔案提交任務'+(task.title?' - ' + task.title:'')} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {canUpload && <Button disabled={!finishedLoading} color='green' content='上載檔案' icon='upload' floated='right' onClick={this.modalToggle('isEditModalOpen')} circular />}
              
              {isOwner && <Button disabled={!finishedLoading} color='orange' content='編輯檔案提交任務' icon='edit' floated='right' onClick={this.taskModalToggle} circular />}

              <Header color="red" style={{marginTop: 0}}>
                截止日期：{momentToDate(task.dueDate)}<br/>
                {isOwner ?
                  (<>負責人：{taskTargets.map(x=>teachers[x.teacherId]?teachers[x.teacherId].name:`無效　(#${x.teacherId})`).join("、")}</>):
                  (<>發起人：{teachers[task.teacherId]?teachers[task.teacherId].name:`無效　(#${task.teacherId})`}</>)
                }
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Table celled sortable unstackable fixed>
                <Table.Header>
                  <Table.Row>
                    {this.tableHeader.filter(th => isOwner || th.columnName !== 'teacherName').map(th => {
                      return (
                        <Table.HeaderCell
                          key={th.columnName}
                          sorted={mainTableSort[0] === th.columnName ? mainTableSort[1] : null}
                          onClick={this.sorting(th.columnName)}
                          width={th.width}
                        >
                          {th.displayName}
                        </Table.HeaderCell>
                      )
                    })}
                    <Table.HeaderCell width={3}>行動</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {finishedLoading ?
                    (latestFiles.length ? latestFiles.map(({ id, fileName, remark, teacherId, uploaded }) => {
                      const canUpload = this.state[this.handle] === teacherId;
                      return (<Table.Row key={id}>
                        <Table.Cell>{fileName}</Table.Cell>
                        <Table.Cell>{remark}</Table.Cell>
                        {isOwner && <Table.Cell>{teachers[teacherId].name || `無效教師 #${teacherId}`}</Table.Cell>}
                        <Table.Cell>{displayDateTime(uploaded)}</Table.Cell>
                        <Table.Cell key='tasks'>
                          <Button color='green' icon='download' onClick={this.download(id, fileName)} circular />
                          <Button color='blue' icon={canUpload ? 'edit' : 'eye'} onClick={this.edit(fileName, teacherId)} circular />
                          {canUpload && <Button color='red' icon='delete' onClick={this.modalToggle('isDeleteAllModalOpen', fileName)} circular />}
                        </Table.Cell>
                      </Table.Row>
                      )
                    }) : <EmptyTableMsg colSpan='5' msg='未有檔案' />)
                    : <EmptyTableMsg colSpan='5' msg='載入資料中' />}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>)
      }
    }

    return (
      <>
        {pageContent}
        {dimmerOpen ? (<FullscreenDimmer active={dimmerOpen} isLoading={true} />) : finishedLoading && (
          <>
            <Modal open={isEditModalOpen} onClose={this.edit()} closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>{'檔案提交任務'+(task && task.title?' - ' + task.title:'')+(fileName?' - ' + fileName:'')}</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Header color="red">
                    檔案格式：{task ? find(extOptions, {value: task.extension}).text : ""}
                  </Header>
                  <Form>
                    {!!fileName && (
                      <Table celled unstackable fixed>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={9}>備註</Table.HeaderCell>
                            <Table.HeaderCell width={3}>最後上載時間</Table.HeaderCell>
                            <Table.HeaderCell width={3} textAlign="center">行動</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {finishedLoading ?
                            (versions.map(({ id, remark, uploaded }) => (
                              <Table.Row key={id}>
                                <Table.Cell>{remark}</Table.Cell>
                                <Table.Cell>{displayDateTime(uploaded)}</Table.Cell>
                                <Table.Cell key='tasks' textAlign="center">
                                  <Button
                                    color='green'
                                    icon='download'
                                    circular
                                    onClick={this.download(id, fileName)}
                                  />
                                  {canUploadVersion && (<Button
                                    color='red'
                                    icon='delete'
                                    onClick={this.modalToggle2('isDeleteModalOpen', id)}
                                    circular
                                  />)}
                                </Table.Cell>
                              </Table.Row>
                            )))
                            : <EmptyTableMsg colSpan='5' msg='載入資料中' />}
                        </Table.Body>
                      </Table>
                    )}

                    {canUploadVersion && (<Form.Group>
                      <Form.Field width={4}>
                        <label>{fileName ? "上載檔案新版本" : "上載檔案"}</label>
                        <input
                          id="newFile"
                          type="file"
                          ref={this.fileInput}
                          onChange={this.parseFile}
                        />
                        {isError && <ErrorLabel message={isError} pointing={false} />}
                      </Form.Field>
                      <Form.TextArea width={12} value={remark} onChange={this.inputChange('text', 'remark')} label='備註' placeholder='備註' />
                    </Form.Group>)}
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditModalOpen')} circular />
                {canUploadVersion && <Button color='green' content='儲存' disabled={!file || isError} icon='save' onClick={this.save} circular />}
              </Modal.Actions>
            </Modal>

            <Modal open={isOwner && isEditTaskModalOpen} onClose={this.taskModalToggle} closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>設定檔案提交任務</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    <Form.Field required>
                      <label>標題</label>
                      <Input
                        value={title || ''}
                        placeholder='標題'
                        onChange={this.inputChange('text', 'title')}
                      />
                      {this.taskValidator.message('標題', title, 'required')}
                    </Form.Field>
                    <Form.Group className='form-group-margin' widths={2}>
                      <Form.Field required>
                        <label>檔案格式</label>
                        <Select
                          options={extOptions}
                          disabled={true}
                          value={task.extension || ''}
                        />
                      </Form.Field>
                      <Form.Field required>
                        <label>截止日期</label>
                        <DateInput
                          popupPosition='bottom center'
                          value={momentToDate(dueDate)}
                          onChange={this.inputChange('date', 'dueDate')}
                          minDate={momentToDate()}
                          placeholder='開始日期'
                        />
                        {this.taskValidator.message('dueDate', isValid(dueDate), 'accepted')}
                      </Form.Field>
                    </Form.Group>
                    <Form.Field required>
                      <label>要完成任務的教師</label>
                      <MySelect
                        fluid
                        multiple
                        search
                        required
                        onChange={this.inputChange('select', 'teacher_target')}
                        value={teacher_target}
                        options={teacherOptions}
                        noResultsMessage="找不到教師"
                        placeholder='負責老師'
                      />
                      {this.taskValidator.message(`一位教師`, !!teacher_target.length, 'accepted')}
                    </Form.Field>
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' content='取消' icon='cancel' onClick={this.modalToggle('isEditTaskModalOpen')} circular />
                <Button color='green' content='儲存' icon='save' onClick={this.editTask} circular />
              </Modal.Actions>
            </Modal>

            <ConfirmModal open={isDeleteAllModalOpen} description='確定刪除檔案 (包括所有版本)？' cancel={this.modalToggle('isDeleteAllModalOpen')} confirm={this.deleteAll} />
            <ConfirmModal open={isDeleteModalOpen} description='確定刪除此檔案版本？' cancel={this.modalToggle2('isDeleteModalOpen')} confirm={this.delete} />
          </>
        )}
      </>
    )
  }
}

export default withRouter(SubmitTaskFile);