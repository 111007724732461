/* import react */
import React from 'react';

/* import semantic-ui element */
import { Form } from 'semantic-ui-react';

const monthRadioStyle = { margin: '0 .5em' };

const RawMonthRadio = ({value, label, stateName, disabled, bottomMargin=false, changeHandler, min=9, max=8 }) => (
  <Form.Group className={bottomMargin ? 'form-group-margin' : ''}>
    <Form.Field><label>{label}</label></Form.Field>
    {Array.from({ length: 12 }).map((_, i) => {
      const v = (i + 8) % 12 + 1;
      return (
        <Form.Radio
          disabled={disabled || (min + 3) % 12 > (v + 3) % 12 || (max + 3) % 12 < (v + 3) % 12}
          data-input-type='select'
          data-state-name={stateName}
          name={label}
          key={v}
          label={v}
          value={v}
          checked={v === value}
          onChange={changeHandler}
          style={monthRadioStyle}
        />
      )
    })}
  </Form.Group>
)

export default React.memo(RawMonthRadio);