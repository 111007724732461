/* import helper */
import moment from 'moment';
import {selectOptions} from './Helper';

/**
 * Handle various inputs
 * @param {string} inputType 
 * @param {any} data the second argument to onChange
 * @returns 
 */
export const inputHandler = (inputType, data) => {
  // date
  if (inputType === 'date') {
    let date = moment(data.value, 'DD-MM-YYYY');
    return date.isValid() ? date : moment();
  }

  // text
  if (inputType === 'text' || inputType === 'time' || inputType === 'select') {
    return data.value;
  }

  // number
  if (inputType === 'number') {
    if (data.value) {
      if (/^0+$/.test(data.value))
        return 0;
      let temp = data.value;
      if(temp[0]==='-') temp = '-'+temp.replace(/-/g,''); else temp = temp.replace(/-/g,'');
      temp.replace(/^0+/, '').replace(/[^0-9.-]/g, '');
      if(/^-?\./.test(temp)) return (temp[0]==='-'?'-':'')+"0."+temp.replace(/\./g,'');
      return temp;
    } else {
      return null;
    }
  }

  // toggle
  if (inputType === 'toggle' || inputType === 'checkbox') {
    return data.checked;
  }

  // raido button
  if (inputType === 'radio') {
    let value = data.value;
    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }
    if (value === 'null') {
      value = null;
    }
    return value
  }

  // file
  if (inputType === 'file') {
    return data.target.files[0];
  }

  // semantic-ui-react Search component in SEN
  if (inputType === 'SENsearch'){
    return data;
  }
}

export const extOptions = [
  { text: '文字檔 (.doc, .docx, .pdf)', value: 'word' },
  { text: '圖片 (.jpg, .jpeg, .png)', value: 'image' },
  { text: '影片 (.mp4, .avi)', value: 'video' },
  { text: 'Powerpoint簡報 (.ppt, .pptx)', value: 'powerpoint' },
  { text: 'Excel試算表 (.xls, .xlsx)', value: 'excel' },
];

// ECA
export const genGradeSet = (activity) => {
  return new Set(Array.from({length: 6}).map((x,i)=>i+1).filter(x=>{
    return !!activity['grade_'+x];
  }));
}

export const genStudentOptions = (activity, students) => {
  const gradeSet = genGradeSet(activity);
  const filtered_students = students.filter(x=>{
    return gradeSet.has(x.gradeId);
  });
  return selectOptions(filtered_students, x=>`${['chiName','engName'].map(y=>x[y]).filter(x=>x).join(", ")} (${x.classCode} ${x.classNo})`, 'id', true, x=>!x.status);
}