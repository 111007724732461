/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {find, keyBy, pick} from 'lodash';

/* import components */
import {
	PageHeader,
	ConfirmModal,
	ErrorLabel,
	BlockerPrompt,
  FlexibleTextField,
  EmptyTableMsg,
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import {
  selectOptions,
  defaultYear,
  whetherReadOnlyForReport
} from '../../../Helper/Helper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Button, Form, Segment, Select, Table, Header, Input } from 'semantic-ui-react';


class FinancialReport extends Component {
  constructor(props) {
    super(props);

    this.counter = 0;
    this.actualId = null;
    this.lastYearActualId = null;

    this.state = {
      finishedLoading: false,
      isSaveModalOpen: false,
      isBlocking: false,
      readOnly: true,

      year: [],
      yearInfo: [],

      yearId: null,
      
      finItems: {},
      balance: null,
      lastYearBalance: null,
    }

    this.validator = new SimpleReactValidator({
      element: message =>  <ErrorLabel message={message} />,
      messages: {
        required: '請輸入資料',
        numeric: '請輸入有效數字'
      },
      autoForceUpdate: this,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when fetching 3 year Review & SWOP');
      console.log(err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try{
      //get year info
      const yearInfo = await get('getYear');
      let year = selectOptions(yearInfo, 'displayName', 'id');
      const yearId = this.state.yearId ? this.state.yearId : defaultYear(yearInfo);

      await this.setStateAsync({year, yearInfo, yearId});

      await this.setStateAsync({
        readOnly: whetherReadOnlyForReport(this.state.yearInfo, this.state.yearId)
      });

      await this.fetchYearReportData();
    }catch(err){
      console.log('Error when fetching Annual Report background info');
      console.log(err);
    }
  }

  fetchYearReportData = async () => {
    try{
      const {yearInfo, yearId} = this.state;

      const curYear = find(yearInfo, {id: yearId});
      let lastYear = {startDate: new Date(0).toISOString()}, lastYearId;
      yearInfo.forEach(x=>{
        if(x.startDate<curYear.startDate){
          if(x.startDate>lastYear.startDate){
            lastYear = x;
          }
        }
      });

      if(lastYear.startDate){
        lastYearId = lastYear.id;
        const lastYearReport = await get(`get1YearReport/${+lastYearId}`);
        if(lastYearReport.length){
          this.lastYearActualId = lastYearReport[0].id;
          await this.setStateAsync({lastYearBalance: lastYearReport[0].balance})
        }
      }

      const oneYearReportData = await get(`get1YearReport/${+this.state.yearId}`);
      if(oneYearReportData.length){
        this.actualId = oneYearReportData[0].hasOwnProperty('id') ? oneYearReportData[0].id : null;
        await this.setStateAsync({balance: oneYearReportData[0].balance});
      }else{
        this.actualId = null;
        await this.setStateAsync({balance: null});
      }

      const finItems = await get(`getFinReport/${+this.state.yearId}`);
      
      await this.setStateAsync({finItems: keyBy(finItems, 'id')});

      if(this.state.balance === null){
        await this.setStateAsync({balance: this.calcThisYearBalance()});
      }

    }catch(err){
      console.log('Error when fetching Annual Financial Report');
      console.log(err);
    }
  }

  save = async () => {
    let errMessage = "";
    let result;
    try{
      const data = {yearId: this.state.yearId};
      await this.setStateAsync({
        finishedLoading: false,
      })
      const items = Object.values(this.state.finItems);
      items.forEach((x,i)=>items[i]={...x, id: /^new/.test(x.id)?undefined:x.id});

      data.items = items;

      if(this.actualId){
        data['1yearreportId'] = this.actualId;
      }

      result = await post('batchEditFinReport', data);
      if(result.insertErr.length){
        errMessage+="插入時發生錯誤\n";
      }
      if(result.editErr.length){
        errMessage+="編輯時發生錯誤\n";
      }
      if(result.deleteErr.length){
        errMessage+="刪除時發生錯誤";
      }
      if(errMessage){
        throw errMessage;
      }
    }catch(err){
      if(err!=='unmounted'){
        console.log('Error when saving 財務報告#1', err);
      }
    }
    try{
      const data = pick(this.state, ['yearId', 'balance']);
      if(result.yearreportId){
        data.id = result.yearreportId;
      }
      result = await post('edit1YearReport', data);
      if(result && result.status && !errMessage){
        this.setStateAsync({
          isBlocking: false,
        });
        await this.fetch();
      }else{
        throw [errMessage, result];
      }
    }catch(err){
      if(err!=='unmounted'){
        alert(['儲存財務報告資料時發生錯誤',errMessage].join('\n'));
        console.log('Error when saving 財務報告#2', err);
      }
    }
    await this.saveModalToggle();
    this.setStateAsync({
      finishedLoading: true,
    })
  }

  /* modal toggle */
  saveModalToggle = async () => {
    this.setStateAsync({
      isSaveModalOpen: !this.state.isSaveModalOpen
    });
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({
      [stateName]: value,
      isBlocking: true
    })
  }

  yearChange = async (_,data) => {
    if(this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要切換年度嗎？")){
        return;
      }
    }
    try{
      let value = inputHandler('select', data);
      await this.setStateAsync({
        yearId: value,
        finishedLoading: false,
      });

      await this.setStateAsync({
        readOnly: whetherReadOnlyForReport(this.state.yearInfo, this.state.yearId)
      });

      await this.fetchYearReportData();
    }catch(err){
      console.log('Error when changing selected OYP');
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
      isBlocking: false,
    });
  }
  
  finInfoChange = (inputType, id, field) => (event, data) => {
    const info = this.state.finItems;
    const item = {...info[id]}
    item[field] = inputHandler(inputType, data);
    info[id] = item;

    const obj = {
      finItems: info,
      isBlocking: true,
    };
    if(field==='expense'||field==='income'){
      obj.balance = this.calcThisYearBalance();
    }
    this.setStateAsync(obj);
  }

  addItem = (isSpecial) => () => {
    const finItems  = {...this.state.finItems};
    finItems[`new${this.counter}`] = ({
      id: `new${this.counter++}`,
      title: '',
      income: null,
      expense: null,
      isSpecial
    });
    this.validator.purgeFields();
    this.setStateAsync({
      finItems,
      isBlocking: true,
    });
  }

  removeItem = (id) => async () => {
    const finItems  = {...this.state.finItems};
    delete finItems[id];
    this.validator.purgeFields();
    await this.setStateAsync({
      finItems,
      isBlocking: true,
    });
    await this.setStateAsync({balance: this.calcThisYearBalance()});
  }

  tryOpenSaveModal = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    this.saveModalToggle();
  }

  calcThisYearBalance = () => {
    if(!this.state.lastYearBalance) return null;
    return Object.values(this.state.finItems).reduce((prev,item)=>(
      prev+(+item.income)-(+item.expense)
    ), this.state.lastYearBalance);
  }

  render() {
    const {
      isSaveModalOpen,
      finishedLoading,
      readOnly,
      isBlocking,

      year,
      yearId,

      balance,
      lastYearBalance,
      finItems
    } = this.state;

    const items=[], specialItems=[];

    Object.entries(finItems).forEach(([k,x])=>{
      if(x.isSpecial){
        specialItems.push(x);
      }else{
        items.push(x);
      }
    });
    
    this.validator.purgeFields();

    return (<>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='周年財務報告' subTitle=' ' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Field inline>
                  <label>周年</label>
                  <Select
                    disabled={!finishedLoading}
                    options={year}
                    value={yearId}
                    onChange={this.yearChange}
                  />
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form>
              <Segment.Group>
                <Segment>
                  <Form.Field inline>
                    <label>上年結餘</label>
                    <Input
                      value={lastYearBalance||'---'}
                      label="HK$"
                      style={{pointerEvents: 'none'}}
                    />
                  </Form.Field>
                </Segment>
                <Segment>
                  <Header size="medium">一般範疇</Header>
                    <Table celled unstackable fixed>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={5}>津貼名稱</Table.HeaderCell>
                          <Table.HeaderCell width={4}>政府撥款 / 收入</Table.HeaderCell>
                          <Table.HeaderCell width={4}>實際開支</Table.HeaderCell>
                          <Table.HeaderCell width={3} textAlign="center">{readOnly?'盈餘':'刪除'}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {items.length?items.map((item)=>(
                          <Table.Row key={item.id}>
                            <Table.Cell verticalAlign="top">
                              <FlexibleTextField
                                multiline={false}
                                readOnly={readOnly}
                                value={item.title}
                                options={{
                                  fluid: true,
                                  disabled: !finishedLoading,
                                  onChange: this.finInfoChange('text', item.id, 'title')
                                }}
                              />
                              {this.validator.message(`title${item.id}`, item.title, 'required')}
                            </Table.Cell>
                            <Table.Cell verticalAlign="top">
                              <FlexibleTextField
                                multiline={false}
                                readOnly={readOnly}
                                value={item.income}
                                options={{
                                  fluid: true,
                                  disabled: !finishedLoading,
                                  onChange: this.finInfoChange('number', item.id, 'income'),
                                }}
                              />
                              {this.validator.message(`income${item.id}`, item.income, 'required|numeric')}
                            </Table.Cell>
                            <Table.Cell verticalAlign="top">
                              <FlexibleTextField
                                multiline={false}
                                readOnly={readOnly}
                                value={item.expense}
                                options={{
                                  fluid: true,
                                  disabled: !finishedLoading,
                                  onChange: this.finInfoChange('number', item.id, 'expense'),
                                }}
                              />
                              {this.validator.message(`expense${item.id}`, item.expense, 'required|numeric')}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {readOnly? (+item.income) - (+item.expense): (<Button
                                disabled={!finishedLoading}
                                color='red'
                                type='button'
                                icon='delete'
                                onClick={this.removeItem(item.id)}
                                circular
                              />)}
                            </Table.Cell>
                          </Table.Row>)
                        ):<EmptyTableMsg msg="未有一般範疇財政報告項目" colSpan={4}/>}
                      </Table.Body>
                      {!readOnly && (<Table.Footer fullWidth>
                        <Table.Row>
                          <Table.HeaderCell colSpan={4} textAlign='center'>
                            <Button
                              color='green'
                              content='新增一般範疇財政報告項目'
                              icon='add'
                              type='button'
                              onClick={this.addItem(false)}
                              circular
                              disabled={!finishedLoading}
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>)}
                    </Table>
                </Segment>
                <Segment>
                  <Header size="medium">特殊範疇</Header>
                    <Table celled unstackable fixed>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={5}>津貼名稱</Table.HeaderCell>
                          <Table.HeaderCell width={4}>政府撥款 / 收入</Table.HeaderCell>
                          <Table.HeaderCell width={4}>實際開支</Table.HeaderCell>
                          <Table.HeaderCell width={3} textAlign="center">{readOnly?'':'刪除'}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {specialItems.length?specialItems.map((item)=>(
                          <Table.Row key={item.id}>
                            <Table.Cell verticalAlign="top">
                              <FlexibleTextField
                                multiline={false}
                                readOnly={readOnly}
                                value={item.title}
                                options={{
                                  fluid: true,
                                  disabled: !finishedLoading,
                                  onChange: this.finInfoChange('text', item.id, 'title')
                                }}
                              />
                              {this.validator.message(`title${item.id}`, item.title, 'required')}
                            </Table.Cell>
                            <Table.Cell verticalAlign="top">
                              <FlexibleTextField
                                multiline={false}
                                readOnly={readOnly}
                                value={item.income}
                                options={{
                                  fluid: true,
                                  disabled: !finishedLoading,
                                  onChange: this.finInfoChange('number', item.id, 'income'),
                                }}
                              />
                              {this.validator.message(`income${item.id}`, item.income, 'required|numeric')}
                            </Table.Cell>
                            <Table.Cell verticalAlign="top">
                              <FlexibleTextField
                                multiline={false}
                                readOnly={readOnly}
                                value={item.expense}
                                options={{
                                  fluid: true,
                                  disabled: !finishedLoading,
                                  onChange: this.finInfoChange('number', item.id, 'expense'),
                                }}
                              />
                              {this.validator.message(`expense${item.id}`, item.expense, 'required|numeric')}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {!readOnly && (<Button
                                disabled={!finishedLoading}
                                color='red'
                                type='button'
                                icon='delete'
                                onClick={this.removeItem(item.id)}
                                circular
                              />)}
                            </Table.Cell>
                          </Table.Row>)
                        ):<EmptyTableMsg msg="未有財政報告項目" colSpan={4}/>}
                      </Table.Body>
                      {!readOnly && (<Table.Footer fullWidth>
                        <Table.Row>
                          <Table.HeaderCell colSpan={4} textAlign='center'>
                            <Button
                              color='green'
                              content='新增特殊範疇財政報告項目'
                              icon='add'
                              type='button'
                              onClick={this.addItem(true)}
                              circular
                              disabled={!finishedLoading}
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>)}
                    </Table>
                </Segment>
                <Segment>
                  <Form.Field inline>
                    <label>今年結餘</label>
                    <FlexibleTextField
                      multiline={false}
                      readOnly={readOnly}
                      value={readOnly?`HK$ ${balance}`:balance}
                      options={{
                        disabled: !finishedLoading,
                        label: 'HK$',
                        onChange: this.inputChange('number', 'balance')
                      }}
                    />
                  {this.validator.message('balance', balance, 'required|numeric')}
                  </Form.Field>
                </Segment>
                {!readOnly && (<Segment>
                  <Form.Field
                    control={Button}
                    disabled={!finishedLoading}
                    color="green"
                    onClick={this.tryOpenSaveModal}
                  >儲存</Form.Field>
                </Segment>)}
              </Segment.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ConfirmModal
        open={isSaveModalOpen}
        description='確定儲存周年財政報告資料？'
        cancel={this.saveModalToggle}
        confirm={this.save}
        confirmIcon='check'
        confirmText='儲存'
      />
      <BlockerPrompt isBlocking={isBlocking}/>
    </>)
  }
}

export default withRouter(FinancialReport);