import Permission from './Permission';
import Year from './Year';
import Teacher from './Teacher';
import Student from './Student';
import SchoolInfo from './SchoolInfo';
import Group from './Group';
import GroupPersonnel from './GroupPersonnel';
import Location from './Location';

export default {
  Permission,
  Year,
  Teacher,
  Student,
  SchoolInfo,
  Group,
  GroupPersonnel,
  Location,
}