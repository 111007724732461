/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	ErrorLabel,
	FullscreenDimmer,
	BlockerPrompt,
  FileInputInstantUpload,
  MySelect,
  Buttons
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler, genGradeSet, genStudentOptions } from '../../../Helper/FormHelper';
import { selectOptions, getTeacherOptions, parseJSONArray } from '../../../Helper/Helper';
import { now, momentToDate, timeToStringFormat, momentToDbFormat, dateToMoment } from '../../../Helper/TimeHelper';
import { floatRight } from '../../../Helper/StyleHelper';

/* import lodash */
import { map, pick, keyBy } from 'lodash';

/* import date input */
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

import {fromBlankAsync} from 'xlsx-populate';
import { exportExcel } from '../../../Helper/ExcelHelper';

/* import semantic-ui element */
import { Grid, Segment, Form, Input, TextArea, Label, Checkbox } from 'semantic-ui-react';
import { excelFormats, gradeOptions  } from '../../../Const/Const';
import { User } from '../../../Context';

class OutsideActivityEdit extends Component {
  constructor(props) {
    super(props);
    this.newId = -1;

    this.state = {
      dimmerOpen: true,
      isBlocking: false,
      outsideActivityTypeOptions: [],
      teacherOptions: [],
      studentOptions: [],
      students: [],

      outsideActivityId: this.props.match.params.outsideActivityId || null,
      outsideActivity: {
        id: this.props.match.params.outsideActivityId || null,
        yearId: this.props.match.params.yearId,
        name: null,
        date: now(),
        subjectName: null,
        outsideActivityType: '',
        target: null,
        concern: null,
        outcome: null,
        location: null,
        meetLocation: null,
        leaveLocation: null,
        meetTime: null,
        leaveTime: null,
        grade_1: true, grade_2: true, grade_3: true, grade_4: true, grade_5: true, grade_6: true, 
        parents: []
      },
      outsideActivityTeachers: [],
      outsideActivityStudents: [],
      curParentName: '',
      curEventName: '',
      events: [],
      studentHasEvents: new Map(),
    }

    this.fileInputRef = React.createRef();

    this.outsideActivityStudentHeader = ['班別', '班號', '學生姓名', '性別'];

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => <ErrorLabel message={message} />,
      messages: {
        default: '請輸入資料'
      }
    });
  }

  setStateAsync = (state) => {
    const x = new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    });
    return x;
  }

  fetchOutsideActivityData = async () => {
    const {outsideActivityId, yearId} = this.props.match.params;
    try{
      let [
        outsideActivity,
        outsideActivityTeachers,
        outsideActivityStudents,
        events,
      ] = outsideActivityId ? await Promise.all([
        'getOneOutsideActivity','getOutsideActivityTeacher','getOutsideActivityStudent','getOutsideActivityEvents'].map(x=>get(x, [outsideActivityId]))
      ): [[{parents: [], yearId: +yearId}],[],[],[]];
      outsideActivityTeachers = map(outsideActivityTeachers, 'teacherId');
      if(!outsideActivityTeachers.length) outsideActivityTeachers.push(this.context.userId)
      if(outsideActivityTeachers.indexOf(this.context.userId)===-1 && !this.context.hasMenuAccess('/eca/outsideActivity/summary')){
        alert("沒有權限!");
        this.props.history.push(`/outsideActivity/personal`);
      }

      for(let e of events){e.studentIds = parseJSONArray(e.studentIds);}

      await this.setStateAsync({
        outsideActivity: {
          ...outsideActivity[0],
          date: dateToMoment(outsideActivity[0]['date'])
        },
        outsideActivityTeachers,
        outsideActivityStudents: map(outsideActivityStudents, 'studentId'),
        events: events.filter(x=>x.name), // remove dummy
        studentHasEvents: this.calStudentHasEvents(events)
      });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching OA data", err)}
  }

  fetchOptions = async () => {
    const {yearId} = this.props.match.params;
    try{
      const [teacherOptions, outsideActivityTypes, students, subjects] = await Promise.all([
        getTeacherOptions(yearId),
        get('getOutsideActivityType'),
        get('getStudentEssentialInfo/' + yearId),
        get('getSubject')
      ]);

      await this.setStateAsync({
        teacherOptions,
        outsideActivityTypeOptions: selectOptions(outsideActivityTypes, 'name', 'name'),
        students,
        subjectOptions: selectOptions(subjects, 'displayName', 'displayName').concat(['跨學科', '其他'].map(x=>({key: x, value: x, text: x})))
      });

      await this.setStateAsync({
        studentOptions: genStudentOptions(this.state.outsideActivity, this.state.students)
      });

    }catch(err){if(err!=='unmounted')console.log("Error when fetching OA data", err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchOutsideActivityData();
    await this.fetchOptions();
    this.setStateAsync({ dimmerOpen: false });
  }
  
  componentWillUnmount = () => {
    this.mounted = false;
  }

  calStudentHasEvents = (events) => {
    const studentHasEvents = new Set();
    for(let e of events){
      for(let s of e.studentIds){
        studentHasEvents.add(s);
      }
    }
    return studentHasEvents;
  }

  addItem = (event, data) => {
    switch(data['data-state-name']){
      case 'parents':
        this.setStateAsync({
          isBlocking: true,
          outsideActivity: {
            ...this.state.outsideActivity,
            parents: this.state.outsideActivity.parents.concat(this.state.curParentName.split(',').map(x=>x.trim()))
          },
          curParentName: ''
        });
        break;
      case 'events':
        const newEvents = this.state.curEventName.split(',').map(x=>({
          id: this.newId--,
          name: x.trim(),
          studentIds: []
        }));
        const events = this.state.events.concat(newEvents);
        this.setStateAsync({
          events,
          studentHasEvents: this.calStudentHasEvents(events),
          isBlocking: true,
          curEventName: ''
        });
        break;
    }
  }

  removeItem = (events, data) => {
    switch(data['data-state-name']){
      case 'events':
        const events = this.state.events.filter(x=>data['data-id']!==x.id);
        this.setStateAsync({
          isBlocking: true,
          events,
          studentHasEvents: this.calStudentHasEvents(events),
        })
        break;
      case 'parents':
        this.setStateAsync({
          isBlocking: true,
          outsideActivity: {
            ...this.state.outsideActivity,
            parents: this.state.outsideActivity.parents.filter((x,j)=>data['data-id']!==j)
          }
        })
        break;
    }
  }

  outsideActivityChange = (event, d) => {
    let inputData;
    //special case for remove icon in semantic-ui-react select
    if(event.target.tagName==='I'){
      inputData = event.target.closest('.ui.dropdown, textarea, button').dataset;
    }else{
      inputData = event.target.closest('.ui, textarea, button').dataset;
    }
    const {inputType, stateName} = inputData;
    let result = inputHandler(inputType, d);
    if(result==='' && inputType==='select'){
      result = null;
    }
    const data = {
      isBlocking: true,
      outsideActivity: {
        ...this.state.outsideActivity,
        [stateName]: result
      }
    };

    switch(stateName){
      case 'outsideActivityType':
        if(data.outsideActivity[stateName]!=='比賽')
          data.events = [];
        break;
      default:
        if(stateName.indexOf('grade_')>-1){
          data.studentOptions = genStudentOptions(data.outsideActivity, this.state.students);
          if(!result){
            const gradeSet = genGradeSet(data.outsideActivity);
            const studentLookup = new Map(this.state.students.map(x=>([x.id, x.gradeId])));
            data.outsideActivityStudents = this.state.outsideActivityStudents.filter(x=>{
              return studentLookup.has(x) && gradeSet.has(studentLookup.get(x));
            });
          }
        }
    }

    this.setStateAsync(data);
  }

  outsideActivityDateTimeChange = (event, data) => {
    this.setStateAsync({
      isBlocking: true,
      outsideActivity: {
        ...this.state.outsideActivity,
        [data['data-state-name']]: inputHandler(data['data-type'], data)
      }
    });
  }

  outsideActivityMultiSelectChange = (event, data) => {
    this.setStateAsync({
      [data['data-state-name']]: inputHandler('select', data),
      isBlocking: true
    });
  }

  return = () => {
    const {section, yearId} = this.props.match.params;
    this.mounted = false;
    if (this.state.outsideActivityId) {
      this.props.history.push(`/eca/outsideActivity/${section}/detail/${yearId}/${this.state.outsideActivityId}`);
    } else {
      this.props.history.push(`/eca/outsideActivity/${section}`);
    }
  }

  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }

    let data = {
      outsideActivity: {
        ...this.state.outsideActivity,
        date: momentToDbFormat(this.state.outsideActivity.date)
      },
      ...pick(this.state, ['outsideActivityId','outsideActivityTeachers','outsideActivityStudents','events']),
    };

    try{
      await this.setStateAsync({ dimmerOpen: true, isBlocking: false });
      const result = await post('editOutsideActivity', data);
      if (result.status) {
        const {section, yearId} = this.props.match.params;
        this.props.history.push(`/eca/outsideActivity/${section}/detail/${yearId}/${result.outsideActivityId}`);
      }else{
        alert("發生錯誤");
        console.log("Error when saving OA", result);
        this.setStateAsync({dimmerOpen: false});
      }
    }catch(err){
      if(err!=='unmounted'){
        console.log("Error when saving OA", err)
        this.setStateAsync({dimmerOpen: false});
      }
    }
  }

  downloadStudentList = async () => {
    const activityName = (this.state.outsideActivity && this.state.outsideActivity.name) || "-";
    const filename = activityName + (this.state.outsideActivity.type==='比賽' ? '學生參賽名單' : '學生名單');
    let data = [this.outsideActivityStudentHeader];
    let oaStudents = this.state.outsideActivityStudents;
    const studentLookup = keyBy(this.state.students, 'id');
    
    if(!oaStudents || !oaStudents.length){
      oaStudents = [{
        classCode: '9X',
        classNo: '99',
        chiName: '陳大文',
        gender: 'M',
      }];
    }else{
      oaStudents = oaStudents.map(x=>studentLookup[x])
    }

    for(let { classCode, classNo, chiName, engName, gender } of oaStudents){
      data.push([classCode, Number(classNo), chiName || engName, gender]);
    }

    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);

    sheet.cell('A5').value(data);

    let events = this.state.events.length ? this.state.events : [
      {name: 'TEST1', studentIds: []},
      {name: 'TEST2', studentIds: []},
      {name: 'TEST3', studentIds: []},
      {name: 'TEST4', studentIds: []},
    ]
    sheet.cell('A4').value([[1,1,1,1]]);

    if(this.state.outsideActivity.outsideActivityType==='比賽'){
      events.map((e,i)=>{
        const students = new Set(e.studentIds);
        const dataArray = [e.id, e.name].concat(oaStudents.map(x=>students.has(x.id)?'✓':null));
        sheet.cell(4, 5+i).value(dataArray.map(x=>[x]));
      });
    }
    
    sheet.usedRange().style({
      border: { left: 'medium', right: 'medium', top: 'medium', bottom: 'medium',  },
    });

    if(this.state.outsideActivity.outsideActivityType==='比賽'){
      sheet.range(3,5,3,Math.max(1,events.length)+4).merged(true).startCell().value("參賽項目 (由校方填寫)").style({
        fill: 'ffff00',
      });
    }
    sheet.range('E1:Q1').merged(true).startCell().value("本系統不支援分層的活動項目。每個活動項目都需填在行 5。有需要的老師可以善用 Excel 等工具");
    sheet.range('E2:Q2').merged(true).startCell().value("注意：校方在儲存之後，每次更新都務必重新下載表格，不能重用過去的表格").style({fontColor: 'ff0000'});

    sheet.row(4).hidden(true);

    sheet.range('A1:D1').merged(true).startCell().value(process.env.REACT_APP_SCHOOL_NAME);
    sheet.range('A2:D2').merged(true).startCell().value(filename);

    sheet.range('A1:D3').style({fontSize: 16});

    sheet.usedRange().style({
      fontFamily: '標楷體',
      horizontalAlignment: 'center',
      verticalAlignment: 'center'
    });

    sheet.range('E1:E2').style({fontFamily: "Microsoft JHengHei"});

    const file = await workbook.outputAsync();
    exportExcel(file, filename);
  }

  fileHandling = async (xlsFile) => {
    //file parse
    const reader = new FileReader();
    try{
      const XLSX = await import('xlsx');
      const result = await new Promise((res,rej)=>{
        reader.onload = (evt) => { //evt = on_file_select event
          /* Parse data */
          const workbook = XLSX.read(evt.target.result, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const excel_data = XLSX.utils.sheet_to_json(worksheet, { range: 3, header: 1, blankrows: false });
          const ids = excel_data[0].slice(4).filter(x=>x);
          if (
            JSON.stringify(excel_data[1].slice(0,4)) !== JSON.stringify(this.outsideActivityStudentHeader) ||
            ids.length !== new Set(ids).size
          ) {
            console.log(excel_data[1].slice(0,4));
            console.log(ids);
            rej('內容格式錯誤，請下載名單再作修改');
            return;
          }
          
          const events = this.state.outsideActivity.outsideActivityType === '比賽' ? excel_data[1].slice(4).map((x, i) => ({
            id: excel_data[0][i + 4] || this.newId--,
            name: x,
            studentIds: [],
          })) : [];

          for(let e of events){
            if(!e.name){
              rej('比賽項目名不能為空');
              return;
            }
          }

          const gradeSet = genGradeSet(this.state.outsideActivity);

          const studentLookup = new Map(this.state.students.filter(student=>gradeSet.has(student.gradeId)).map(x=>([`${x.classCode}\r${x.classNo}`, x.id])));

          const outsideActivityStudents = [];
          for(let row of excel_data.slice(1)){
            if(studentLookup.has(`${row[0]}\r${row[1]}`)){
              outsideActivityStudents.push(studentLookup.get(`${row[0]}\r${row[1]}`));
              events.forEach((e,i)=>{
                if(row[4+i]){
                  e.studentIds.push(studentLookup.get(`${row[0]}\r${row[1]}`))
                }
              })
            }
          }
          this.setStateAsync({
            outsideActivityStudents,
            events,
            studentHasEvents: this.calStudentHasEvents(events),
            isBlocking: true
          }).then(()=>{
            res({status: true});
          }).catch((err)=>{
            rej({status: false})
          });
        };
        reader.onerror = (evt) => {
          console.log('err', evt);
          rej('讀取檔案內容時發生錯誤');
        }
        reader.readAsArrayBuffer(xlsFile);
      });
      return result;
    }catch(err){
      console.log(err);
      return {status: false, err}
    }
  }

  changeItem = (event, data)=>{
    const type = data['data-state-name'];
    this.setStateAsync({
      [type==='parents'?'curParentName':'curEventName']: inputHandler('text', data),
      isBlocking: true
    })
  }

  renderLabel = (item) => {
    return {
      content: item.text,
      key: item.key,
      value: item.value,
      color: this.state.studentHasEvents.has(item.value)||!this.state.events.length?undefined:'red',
    }
  }

  render() {
    const {
      dimmerOpen,
      isBlocking,
      outsideActivityTypeOptions,
      teacherOptions,
      subjectOptions,
      outsideActivity,
      outsideActivityTeachers,
      curParentName,
      curEventName,
      outsideActivityStudents,
      studentOptions,
      events,
      studentHasEvents
    } = this.state;

    return (
      <>
        {dimmerOpen ?
          <FullscreenDimmer active={dimmerOpen} isLoading={true} />
          :
          <>
            <Grid stackable doubling>
              <Grid.Row>
                <Grid.Column>
                  <PageHeader title='編輯外出帶隊' />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Segment.Group className='form-group-margin'>
                      <Segment className='bold' size='big'>基本資料</Segment>
                      <Segment secondary>
                        <Form.Group>
                          <Form.Field required width={6}>
                            <label>活動名稱</label>
                            <Input
                              type='text'
                              fluid
                              data-input-type='text'
                              data-state-name='name'
                              onChange={this.outsideActivityChange}
                              value={outsideActivity.name || ''}
                              placeholder='活動名稱'
                            />
                            {this.validator.message('name', outsideActivity.name, 'required')}
                          </Form.Field>
                          <Form.Field required width={3}>
                            <label>類型</label>
                            <MySelect
                              fluid
                              data-input-type='select'
                              data-state-name='outsideActivityType'
                              onChange={this.outsideActivityChange}
                              value={outsideActivity.outsideActivityType || ''}
                              options={outsideActivityTypeOptions}
                              placeholder='類型'
                            />
                            {this.validator.message('outsideActivityType', outsideActivity.outsideActivityType, 'required')}
                          </Form.Field>
                          <Form.Field required width={3}>
                            <label>科目</label>
                            <MySelect
                              fluid
                              data-input-type='select'
                              data-state-name='subjectName'
                              onChange={this.outsideActivityChange}
                              value={outsideActivity.subjectName || ''}
                              options={subjectOptions}
                              placeholder='科目'
                            />
                            {this.validator.message('subjectName', outsideActivity.subjectName, 'required')}
                          </Form.Field>
                          <Form.Field width={4}>
                            <label>日期</label>
                            <DateInput
                              data-state-name='date'
                              data-type='date'
                              onChange={this.outsideActivityDateTimeChange}
                              value={momentToDate(outsideActivity.date)}
                              placeholder='日期'
                            />
                          </Form.Field>
                        </Form.Group>
                      </Segment>

                      <Segment secondary>
                        <Form.Group widths='equal' className='form-group-margin'>
                          <Form.Field>
                            <label>負責老師 (留空會自動將你設為負責老師)</label>
                            <MySelect
                              fluid
                              multiple
                              search
                              // disabled={!this.context.hasMenuAccess('/eca/outsideActivity/summary')}
                              data-state-name='outsideActivityTeachers'
                              onChange={this.outsideActivityMultiSelectChange}
                              value={outsideActivityTeachers}
                              options={teacherOptions}
                              noResultsMessage="找不到老師"
                              placeholder='負責老師'
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width='16' label="帶隊家長"/>
                        </Form.Group>
                        <Form.Group className='input-with-button'>
                          <Form.Field width='15'>
                            <Input
                              data-state-name='parents'
                              placeholder='請先輸入家長名稱，然後按＋號加入'
                              value={curParentName||''}
                              onChange={this.changeItem}
                            />
                          </Form.Field>
                          <Buttons.AddButton
                            data-state-name='parents'
                            onClick={this.addItem}
                            disabled={!curParentName}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width='16' className='multiselect-style-label'>
                            {outsideActivity.parents.map((x,i)=><Label
                              active={false}
                              key={i}
                              content={x}
                              data-state-name='parents'
                              data-id={i}
                              onRemove={this.removeItem}
                            />)}
                          </Form.Field>
                        </Form.Group>
                      </Segment>

                      <Segment secondary>
                        <Form.Group widths='equal' className='form-group-margin'>
                          <Form.Field>
                            <label>活動目標</label>
                            <TextArea
                              data-input-type='text'
                              data-state-name='target'
                              onChange={this.outsideActivityChange}
                              value={outsideActivity.target || ''}
                              placeholder='活動目標'
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal' className='form-group-margin'>
                          <Form.Field>
                            <label>關注事項</label>
                            <TextArea
                              data-input-type='text'
                              data-state-name='concern'
                              onChange={this.outsideActivityChange}
                              value={outsideActivity.concern || ''}
                              placeholder='關注事項'
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                          <Form.Field>
                            <label>成效評估</label>
                            <TextArea
                              data-input-type='text'
                              data-state-name='outcome'
                              onChange={this.outsideActivityChange}
                              value={outsideActivity.outcome || ''}
                              placeholder='成效評估'
                            />
                          </Form.Field>
                        </Form.Group>
                      </Segment>
                    </Segment.Group>

                    <Segment.Group className='form-group-margin'>
                      <Segment className='bold' size='big'>當日資料</Segment>
                      <Segment secondary>
                        <Form.Group widths='equal' className='form-group-margin'>
                          <Form.Field>
                            <label>活動地點</label>
                            <Input
                              type='text'
                              data-input-type='text'
                              data-state-name='location'
                              onChange={this.outsideActivityChange}
                              value={outsideActivity.location || ''}
                              placeholder='活動地點'
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal' className='form-group-margin'>
                          <Form.Field>
                            <label>集合時間</label>
                            <TimeInput
                              onChange={this.outsideActivityDateTimeChange}
                              data-state-name='meetTime'
                              data-type='time'
                              value={outsideActivity.meetTime ? timeToStringFormat(outsideActivity.meetTime) : ''}
                              placeholder='集合時間'
                              popupPosition='right center'
                              closable
                              clearable
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>集合地點</label>
                            <Input
                              type='text'
                              data-input-type='text'
                              data-state-name='meetLocation'
                              onChange={this.outsideActivityChange}
                              value={outsideActivity.meetLocation || ''}
                              placeholder='集合地點'
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                          <Form.Field>
                            <label>解散時間</label>
                            <TimeInput
                              onChange={this.outsideActivityDateTimeChange}
                              data-state-name='leaveTime'
                              data-type='time'
                              value={outsideActivity.leaveTime ? timeToStringFormat(outsideActivity.leaveTime) : ''}
                              placeholder='解散時間'
                              popupPosition='right center'
                              closable
                              clearable
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>解散地點</label>
                            <Input
                              type='text'
                              data-input-type='text'
                              data-state-name='leaveLocation'
                              onChange={this.outsideActivityChange}
                              value={outsideActivity.leaveLocation || ''}
                              placeholder='解散地點'
                            />
                          </Form.Field>
                        </Form.Group>
                      </Segment>
                    </Segment.Group>

                    <Segment.Group className='form-group-margin'>
                      <Segment className='bold' size='big'>學生及參賽項目(如適用)資料</Segment>
                      <Segment secondary>
                        <Form.Group widths='equal'>
                          <Form.Field required>
                            <label>年級</label>
                          </Form.Field>
                          {gradeOptions.map(({text, value})=>(
                            <Form.Field key={value}>
                              <label data-i={value}>
                                <Checkbox
                                  checked={!!outsideActivity['grade_'+value]}
                                  data-input-type='checkbox'
                                  data-state-name={'grade_'+value}
                                  onChange={this.outsideActivityChange}
                                  className='narrow'
                                />
                                {text}
                              </label>
                            </Form.Field>
                          ))}
                        </Form.Group>
                      </Segment>
                      <Segment secondary>
                        <Form.Group>
                          <Form.Field width={16} inline>
                            <label>學生 <span className='red'>(紅色標籤表示該學生未有項目, 如適用)</span></label>
                            <Buttons.DownloadButton content="下載學生及參賽項目(如適用)列表" floated="right" onClick={this.downloadStudentList}/>
                            <FileInputInstantUpload
                              buttonColor='blue'
                              buttonText='批次上載學生及參賽項目(如適用)'
                              formatErrorText='請上載正確格式(xlsx)'
                              accept={excelFormats}
                              wrapperStyle={floatRight}
                              fileHandling={this.fileHandling}
                              successMsg="更新了學生及參加項目(如適用)列表。請記得最後儲存"
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width={16}>
                            <MySelect
                              fluid
                              multiple
                              search
                              noResultsMessage="找不到學生"
                              placeholder='參加學生'
                              lol={studentHasEvents} //pass props for updating
                              lol2={events.length>0}
                              value={outsideActivityStudents}
                              options={studentOptions}
                              data-state-name='outsideActivityStudents'
                              onChange={this.outsideActivityMultiSelectChange}
                              renderLabel={this.renderLabel}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Segment>
                      {outsideActivity.outsideActivityType==='比賽' && (<Segment secondary>
                        <Form.Group>
                          <Form.Field width='16'>
                            <label>參賽項目 <span className='red'>(紅色標籤表示該項目未有學生)</span></label>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group className='input-with-button'>
                          <Form.Field width='15'>
                            <Input
                              placeholder='請先輸入參賽項目，然後按＋號加入'
                              value={curEventName||''}
                              data-state-name='events'
                              onChange={this.changeItem}
                            />
                          </Form.Field>
                          <Buttons.AddButton
                            data-state-name='events'
                            disabled={!curEventName}
                            onClick={this.addItem}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width='16' className='multiselect-style-label'>
                            {events.map(x=><Label
                              active={false}
                              key={x.id}
                              content={x.name}
                              data-state-name='events'
                              data-id={x.id}
                              onRemove={this.removeItem}
                              color={x.studentIds.length?undefined:'red'}
                            />)}
                          </Form.Field>
                        </Form.Group>
                      </Segment>)}
                    </Segment.Group>

                    <div className='text-center'>
                      <Buttons.CancelButton onClick={this.return} />
                      <Buttons.SaveButton onClick={this.save} />
                    </div>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <BlockerPrompt isBlocking={isBlocking}/>
          </>
        }
      </>
    )
  }
}

OutsideActivityEdit.contextType = User;

export default withRouter(OutsideActivityEdit)