import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import {
	FullscreenDimmer,
	PageHeader,
	EmptyTableMsg,
  MyTable,
  Buttons
} from '../../../Components';

import {weekdayLookup} from '../../../Const/Const';

/* import helper functions */
import { get } from '../../../Helper/ApiHelper';
import { timeToStringFormat, displayDate } from '../../../Helper/TimeHelper';
import { exportExcel } from '../../../Helper/ExcelHelper';
import { genAttendanceString } from '../../../Helper/Helper';

import {fromBlankAsync} from 'xlsx-populate';

import { Grid, Tab, Header, Segment, Table, Label, Icon } from 'semantic-ui-react';
import { get as _get, groupBy, mapValues } from 'lodash';

class ActivityDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: true,
      activeIndex: 0,
      activity: {},
      activityTeachers: [],
      activityStudents: [],
      activityClasses: [],
      activityAttendances: {},

      yearName: '-',
      attendanceStatusMap: {},
    }

    this.studentTableHeader = [
      {headerName: '班別', width: 2, cellRender: 'classCode', cellClassName: x=>x.status?'':'line-through'}, 
      {headerName: '姓名', width: 4, cellRender: x=>x.chiName || x.engName, cellClassName: x=>x.status?'':'line-through'},
      {headerName: '學號', width: 2, cellRender: 'classNo', cellClassName: x=>x.status?'':'line-through'},
      {headerName: '放學方式', width: 5, cellRender: x=>`${x.leaveMethodName}${(x.leaveMethodId === 4 && x.remark) ? ' - ' + x.remark : ''}`, cellClassName: x=>x.status?'':'line-through'},
    ];

    this.attendanceTableHeader = [
      { headerName: '日期', width: 2},
      { headerName: '出席', width: 2},
      { headerName: '缺席或其他', width: 2},
      { headerName: '備註', width: 8},
      { headerName: '點名', width: 2},
    ];
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchData = async () => {
    const {activityId} = this.props.match.params;

    try{
      const [activity, activityTeachers, activityStudents, activityClasses, activityAttendances, rawAttendanceStatus] = await Promise.all([
        get('getOneActivityWithDetails/' + activityId),
        get('getActivityTeacher/' + activityId),
        get('getActivityStudent/' + activityId),
        get('getActivityClass/' + activityId),
        get('getActivityAttendance/' + activityId),
        get('getAttendanceStatus')
      ]);

      if(!activity.length){
        alert("活動無效，現在會帶你回到首首");
        this.props.history.push('/eca/activity/personal')
        return;
      }

      const year = await get('getOneYear', [activity[0].yearId]);

      await this.setStateAsync({
        activity: activity[0] || {},
        activityTeachers,
        activityStudents,
        activityClasses,
        activityAttendances: groupBy(activityAttendances, 'activityClassId'),
        yearName: year[0]?year[0].displayName:"-",
        attendanceStatusMap: rawAttendanceStatus.reduce((prev,cur)=>({...prev,[cur.id]: cur.name}),{})
      });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching activity data", err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
    this.setStateAsync({ dimmerOpen: false });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  handleTabChange = (e, { activeIndex }) => this.setStateAsync({ activeIndex })

  panes = () => {
    return [
      { menuItem: '活動詳情', render: this.displayInfo },
      { menuItem: '點名', render: this.displayAttendance },
    ]
  }

  displayInfo = () => {
    const {
      activity,
      activityTeachers,
      activityStudents,
      activityClasses,
    } = this.state;

    const {section, activityId} = this.props.match.params;
    const yearId = _get(this.state.activity, 'yearId', null);

    const weekday = activity&&activity.weekday?"星期"+String(activity.weekday).split("").map(x=>weekdayLookup[x]).join("、"):""

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top'>
          <Link to={'/eca/activity/' + section + '/edit/' + yearId + '/' + activityId}><Buttons.EditButton content='編輯課外活動' /></Link>
        </Segment>
        <Segment vertical className='segment-padding'>
          <Header as='h3'><u>基本資料</u></Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={4}><b>活動名稱:</b></Grid.Column>
              <Grid.Column width={12}>{activity.name || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>負責老師:</b></Grid.Column>
              <Grid.Column width={12}>
                {activityTeachers.length > 0 ?
                  <Label.Group size='large'>
                    {activityTeachers.map(({ teacherId, name }) => (
                      <Label key={teacherId}>{name || '外聘導師'}</Label>
                    ))}
                  </Label.Group>
                  :
                  '-'
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>活動所屬科目:</b></Grid.Column>
              <Grid.Column width={12}>{activity.subjectName || '跨學科'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>備註欄:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{activity.remark || '-'}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment vertical className='segment-padding'>
          <Header as='h3'><u>上課資料</u></Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={4}><b>地點:</b></Grid.Column>
              <Grid.Column width={12}>{activity.locationName || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>時間:</b></Grid.Column>
              <Grid.Column width={12}>
                {weekday ? (
                  <>
                    {weekday}&nbsp;{!!(activity.startTime && activity.endTime) &&
                    <span>{timeToStringFormat(activity.startTime)} - {timeToStringFormat(activity.endTime)}</span>}
                  </>):'-'}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>日期:</b></Grid.Column>
              <Grid.Column width={12}>
                {activityClasses.length > 0 ?
                  <Label.Group size='large'>
                    {activityClasses.map(({ id, date }) => (
                      <Label href={'/eca/activity/' + section + '/attendance/' + yearId + '/' + activityId + '/' + id} key={id} className='cursor' color='teal' onClick={this.navigate}>
                        <Icon name='edit' />{displayDate(date)}
                      </Label>
                    ))}
                  </Label.Group>
                  :
                  '-'
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment vertical className='segment-padding'>
          <Header as='h3'><u>學生資料</u></Header>
          <MyTable
            tableColumnData={this.studentTableHeader}
            data={activityStudents}            
          />
        </Segment>
      </Tab.Pane>
    )
  }

  downloadAttendance = async () => {
    const students = this.state.activityStudents;
    if(!students.length){
      alert("未有學生資料");
      return;
    }
    const classes = this.state.activityClasses;
    if(!classes.length){
      alert("未有活動日期資料");
      return;
    }

    const {activity, attendanceStatusMap} = this.state;
    
    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);

    const dates = classes.map(x=>displayDate(x.date));

    const attendanceLookup = mapValues(this.state.activityAttendances, x=>new Map(x.map(x=>[x.activityStudentId, genAttendanceString(x, attendanceStatusMap)])))

    sheet.cell('A3').value("學生");
    sheet.cell('B3').value([dates.concat(Array.from({length: 25 - dates.length}))]);
    students.forEach((x,i)=>{
      sheet.cell(4+i, 1).value(`${x.classCode} ${x.classNo>9?x.classNo:" "+x.classNo} ${x.chiName||x.engName}`).style({
        strikethrough: !x.status
      })
    })

    classes.forEach((classObj, j)=>{
      students.forEach((student,i)=>{
        if(attendanceLookup[classObj.id] && attendanceLookup[classObj.id].has(student.id)){
          sheet.cell(i+4, j+2).value(attendanceLookup[classObj.id].get(student.id));
        }
      });
      sheet.cell(students.length+4, 2).value(classObj.remark||'');
    });

    sheet.cell(`A${students.length+4}`).value('備註');

    sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true }, fontSize: 12 });

    sheet.range(1,1,2,sheet.usedRange().endCell().columnNumber()).merged(true).startCell().value(`${process.env.REACT_APP_SCHOOL_NAME} ${this.state.yearName}年度課外活動點名表 - ${activity?activity.name:"-"}`);
    sheet.range('A1:A2').style({bold: true, fontSize: 16});
    sheet.row(1).height(21);
    sheet.row(2).height(21);

    sheet.usedRange().style({ fontFamily: "標楷體", horizontalAlignment: 'center', verticalAlignment: 'center', wrapText: true});
    for(let i=2;i<=sheet.usedRange().endCell().columnNumber();i++){
      sheet.column(i).width(11);
    }
    sheet.column("A").width(21).style({horizontalAlignment: 'left'});
    
    sheet.range('A1:B1').style({ horizontalAlignment: 'center' });
    
    const file = await workbook.outputAsync();
    exportExcel(file, `課外活動點名表 - ${activity.name}`);
  }

  displayAttendance = () => {
    const {
      activityClasses,
      activityAttendances
    } = this.state;

    const {section, activityId} = this.props.match.params;
    const yearId = _get(this.state.activity, 'yearId', null);

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top'>
          <Link to={'/eca/activity/' + section + '/attendance/' + yearId + '/' + activityId}><Buttons.EditButton content='新增課堂及點名' /></Link>
          <Buttons.DownloadButton content='匯出點名表' onClick={this.downloadAttendance} />
        </Segment>
        <Segment vertical className='segment-padding'>
          <Table textAlign='center' padded selectable celled stackable>
            <Table.Header>
              <Table.Row>
                {this.attendanceTableHeader.map(({ headerName, width }) => (
                  <Table.HeaderCell key={headerName} width={width}>{headerName}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {activityClasses.length > 0 ?activityClasses.map(({ id, date, remark }) => {
                const filteredAttendances = activityAttendances[id]||[];
                let present = 0, absent = 0;
                filteredAttendances.forEach(x=>{
                  if(x.attendanceStatusId === 1) present++;
                  else absent++;
                });
                return (
                  <Table.Row key={id}>
                    <Table.Cell>{displayDate(date)}</Table.Cell>
                    <Table.Cell>{filteredAttendances.length ? present : '-'}</Table.Cell>
                    <Table.Cell>{filteredAttendances.length ? absent : '-'}</Table.Cell>
                    <Table.Cell>{remark}</Table.Cell>
                    <Table.Cell><Link to={'/eca/activity/' + section + '/attendance/' + yearId + '/' + activityId + '/' + id}><Buttons.EditButton /></Link></Table.Cell>
                  </Table.Row>
                )
              }): <EmptyTableMsg colSpan={this.attendanceTableHeader.length} />}
            </Table.Body>
          </Table>
        </Segment>
      </Tab.Pane>
    )
  }

  navigate = (eventOrRoute) => {
    if(typeof eventOrRoute === 'string'){
      this.props.history.push(eventOrRoute);
      return;
    }
    const {href} = eventOrRoute.target.closest('a');
    this.props.history.push('/'+href.split('/').slice(3).join('/'));
    eventOrRoute.preventDefault();
  }

  render() {
    const {activeIndex,dimmerOpen} = this.state;
    return (
      <>
        {dimmerOpen ? <FullscreenDimmer active={true} isLoading={true} /> : (
          <Grid stackable doubling>
            <Grid.Row>
              <Grid.Column>
                <PageHeader title='課外活動詳情' />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Tab
                  panes={this.panes()}
                  activeIndex={activeIndex}
                  onTabChange={this.handleTabChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </>
    )
  }
}

export default withRouter(ActivityDetail);