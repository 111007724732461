import { exportExcel, convertToColumnTitle } from '../../Helper/ExcelHelper';
import {fromBlankAsync} from 'xlsx-populate';
import {get as _get, find, groupBy} from 'lodash';
import {
  escapeHtml,
  printLayout,
  CheckBoxWithLabel
} from '../../Helper/PrintHelper';

import {handleExport} from '../Sen/printHelper';

export const checkHasSearchOn = searchFilter => Object.values(searchFilter).some(x=>{
  if(Array.isArray(x)) return x.some(y=>Object.values(y).some(z=>z));
  return typeof x === 'object' ? Object.values(x).some(z=>z) : x;
});

export const stdTests = process.env.REACT_APP_TALENT_STANDARD_TESTS?JSON.parse(process.env.REACT_APP_TALENT_STANDARD_TESTS):[];
export const subjects = process.env.REACT_APP_TALENT_SUBJECTS?JSON.parse(process.env.REACT_APP_TALENT_SUBJECTS):[];
export const characters = process.env.REACT_APP_TALENT_CHARACTER?JSON.parse(process.env.REACT_APP_TALENT_CHARACTER):[];
export const nonAcademics = process.env.REACT_APP_TALENT_NON_ACADEMIC?process.env.REACT_APP_TALENT_NON_ACADEMIC.split("|"):[];


export const renderTalentString = (x, talentTypes) => {
  const data = [];
  stdTests.forEach((test,i)=>{
    if(+_get(x, [test.fieldName], 0) >= test.threshold) data.push(`1.${i+1} ${test.displayName}`);
  });
  subjects.forEach((subject,i)=>{
    if(x[subject.fieldName]) data.push(`2.1.${i+1} ${subject.displayName}`);
  });
  characters.forEach((character,i)=>{
    if(+x[character.fieldName]>=4) data.push(`3.1.${i+1} ${character.displayName}`);
  });
  nonAcademics.forEach((nonAcad,i)=>{
    if(x.non_academic && x.non_academic.indexOf(`\b${nonAcad}\b`)>-1) data.push(`3.2.${i+1} ${nonAcad}`);
  });
  talentTypes.forEach((types,i)=>{
    if(x.other_gifted && x.other_gifted.indexOf(types.id)>-1){
      data.push(`3.3.${i+1} ${types.name}`);
    }
  })
  return escapeHtml(data.join(", "));
}
export const genGradeTemplate = async ({data, years, yearId, grade}) => {
  try{
    const SECTIONDATA = [
      {
        displayName: '標準', noHeader: true, noSubHeader: true, subFields: [
          {displayName: '年級', fieldName: 'grade'},
          {displayName: '班別', fieldName: 'classCode'},
          {displayName: '學號', fieldName: 'classNo'},
          {displayName: '姓名', fieldName: x=>x.chiName||x.engName||''},
        ]
      },
      ...grade.map(g=>({
        displayName: g.chiName,
        noHeader: true,
        subFields: subjects
      }))
    ];

    await handleExport(
      {
        section: ()=>true,
        displayName: '全級排名樣板'
      },
      [],
      _get(find(years, {id: yearId}), ['displayName'], '20__-20__'),
      {data},
      SECTIONDATA
    )
  }catch(err){
    alert("Error!");
    console.log(err);
  }
}

export const genTalentList = async ({data, years, yearId, talentTypes}) => {
  try{
    const SECTIONDATA = [
      {
        displayName: '標準', noHeader: true, noSubHeader: true, subFields: [
          {displayName: '班別', fieldName: 'classCode'},
          {displayName: '學生姓名', fieldName: x=>x.chiName||x.engName||''},
        ]
      },
      { displayName: '1. 標準化測驗', noHeader: true, noSubHeader: true, subFields: stdTests.map((x,i)=>({...x, displayName: `1.${i+1} ${x.displayName}`}))},
      { displayName: '2. (去年) 校內成績表現', noHeader: true, noSubHeader: true, subFields: ['2.1 學科全級排名'].concat(subjects.map((x,i)=>({...x, displayName: `2.1 學科全級排名|2.1.${i+1} ${x.displayName}`})))},
      { displayName: '3. 教師推薦', noHeader: true, noSubHeader: true, subFields: ['3.1 資優生行為特質問卷'].concat(
          characters.map((x,i)=>({...x, displayName: `3.1 資優生行為特質問卷|3.1.${i+1} ${x.displayName}`}))
        ).concat(
          '3.2 非學科'
        ).concat(
          nonAcademics.map((x,i)=>({
            displayName: `3.2 非學科|3.2.${i+1} ${x}`,
            fieldName: y=>(_get(y, ['non_academic'])||'').indexOf(`\b${x}\b`)>-1?'✔':''
          }))
        ).concat(
          '3.3 資優特質'
        ).concat(
          talentTypes.map((x,i)=>({
            displayName: `3.3 資優特質|3.3.${i+1} ${x.name}`,
            fieldName: y=>{ let z = _get(y, ['other_gifted']); if(!Array.isArray(z))z=[];return z.indexOf(x.id)>-1?'✔':'';}
          }))
        ).concat(
          {displayName: '3.4 家長推薦', fieldName: 'parent_suggest'}
        ).concat(
          { displayName: '3.5 備註', fieldName: 'remark'}
        )
      },
      { displayName: '7. 學生支援', fieldName: 'sen'},
    ];

    const [workBook, workSheet, filename] = await handleExport(
      {
        section: ()=>true,
        displayName: '人才庫名單 (不包括增潤課程及比賽部分)'
      },
      [],
      _get(find(years, {id: yearId}), ['displayName'], '20__-20__'),
      {data},
      SECTIONDATA,
      true
    );

    for(
      let i=workSheet.usedRange().startCell().column().columnNumber();
      i<=workSheet.usedRange().endCell().column().columnNumber();
      i++
    ){
      workSheet.column(i).width(6);
    }

    return workBook.outputAsync().then(file => {
      exportExcel(file, filename);
      return true;
    });

  }catch(err){
    alert("Error!");
    console.log(err);
  }
}

export const genTalentECA = async ({data, years, yearId}) => {
  try{
    const SECTIONDATA = [
      {
        displayName: '標準', noHeader: true, noSubHeader: true, subFields: [
          {displayName: '班別', fieldName: 'classCode'},
          {displayName: '學生姓名', fieldName: x=>x.chiName||x.engName||''},
        ]
      },
      { displayName: '5. 增潤課程 (A 校內; B 校外)', fieldName: x=>{
        return [x.eca].concat((x.outside_course||'').split("\n").filter(x=>x).map(x=>"B "+x)).filter(x=>x).join(", ")
      }},
      { displayName: '6. 校外比賽及獎項 (請列明。如得獎附成績)', fieldName: x=>x.prizes.map(x=>{
        return `${x[0]}：${x.filter(x=>x).slice(1).join(" - ")}`
      }).join("\n")},
    ];

    await handleExport(
      {
        section: ()=>true,
        displayName: '人才庫名單 (增潤課程及比賽部分)'
      },
      [],
      _get(find(years, {id: yearId}), ['displayName'], '20__-20__'),
      {data},
      SECTIONDATA
    )
  }catch(err){
    alert("Error!");
    console.log(err);
  }
}

export const genPercentage = (data, grade, talentTypes, students) => {
  if(!talentTypes.length){
    alert("請先設定資優特質");
    return;
  }
  const template = x => grade.map(x=>x.id).concat('全校').reduce((prev,cur)=>({
    ...prev,
    [cur]:typeof x === 'object' && x ? {...x}: x,
  }), {});

  const lengths = template(0);
  lengths['全校'] = students.length;

  for(let s of students){
    lengths[s.grade]++;
  }
  const talentTemplate = talentTypes.reduce((prev,cur)=>({
    ...prev,
    [cur.id]: 0
  }), {});

  const talentLengths = template(talentTemplate);
  const talentStudentsByGrade = groupBy(data, 'grade');

  for(let ch of talentTypes){
    talentLengths['全校'][ch.id] = data.filter(x=>x.other_gifted && x.other_gifted.indexOf(ch.id)>-1).length;

    for(let g of grade){
      const filteredTalentStudents = _get(talentStudentsByGrade, [g.id], []).filter(x=>x.other_gifted && x.other_gifted.indexOf(ch.id)>-1);
      talentLengths[g.id][ch.id] = filteredTalentStudents.length;
    }
  }
  return {
    lengths,
    talentLengths
  }
}

export const genPercentageXLSX = async ({data, grade, years, yearId, talentTypes, students}) => {
  try{

    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);

    const endCol = convertToColumnTitle(4 + grade.length*2);
    const yearName = _get(find(years.array, {id: yearId}), ['displayName'], '20__-20__');

    const studentsByGrade = groupBy(students, 'grade');
    const talentStudentsByGrade = groupBy(data, 'grade');
    
    sheet.range(`A1:${endCol}1`).merged(true).startCell().value(process.env.REACT_APP_SCHOOL_NAME);
    sheet.range(`A2:${endCol}2`).merged(true).startCell().value(`校本資優教育計劃 ${yearName}年度`);
    sheet.range(`A3:${endCol}3`).merged(true).startCell().value('人才庫 (百分比)');

    sheet.range('A5:B5').merged(true);
    sheet.range('A6:B6').merged(true);

    sheet.range('C5:D5').merged(true).startCell().value('全校');
    sheet.cell('C6').value('人數');
    sheet.cell('D6').value('%');
    sheet.column('C').width(5.71);
    sheet.column('D').width(5.71).style("numberFormat", "0.0");

    grade.forEach((g, i)=>{
      g.col = [convertToColumnTitle(5+2*i), convertToColumnTitle(6+2*i)];
      sheet.range(`${g.col[0]}5:${g.col[1]}5`).merged(true).startCell().value(g.engName);
      sheet.cell(g.col[0]+6).value('人數');
      sheet.cell(g.col[1]+6).value('%');
      sheet.column(g.col[0]).width(5.71);
      sheet.column(g.col[1]).width(5.71).style("numberFormat", "0.0");
      if(!g.hasOwnProperty('studentCount'))
        g.studentCount = _get(studentsByGrade, [g.id], []).length;
    });

    if(stdTests.length){
      sheet.range(`A7:A${6+stdTests.length}`).merged(true).startCell().value("1. 標準化測驗\n***(count if IQ at least 110;\ncreativity at least 11)"); 
      stdTests.forEach((t,i)=>{
        const length = data.filter(x=>x[t.fieldName]>=t.threshold).length;
        sheet.cell('B'+(7+i)).value(`1.${i+1} ${t.displayName}`);
        sheet.cell('C'+(7+i)).value(length);
        sheet.cell('D'+(7+i)).value(100*length/students.length);

        for(let g of grade){
          const filteredTalentStudents = _get(talentStudentsByGrade, [g.id], []).filter(x=>+x[t.fieldName]>=t.threshold);
          sheet.cell(g.col[0]+(7+i)).value(filteredTalentStudents.length);
          sheet.cell(g.col[1]+(7+i)).value(100*filteredTalentStudents.length/g.studentCount);
        }
      })
    }
    let cumLen = 7+stdTests.length;

    sheet.range(`A${cumLen}:${endCol}${cumLen}`).merged(true).startCell().value('3. 教師推薦');

    cumLen+=1;

    if(characters.length){
      sheet.range(`A${cumLen}:A${cumLen-1+characters.length}`).merged(true).startCell().value('3.1 資優生行為特質問卷\n***(count if at least 4)');
      characters.forEach((ch,i)=>{
        sheet.cell(`B${cumLen+i}`).value(`3.1.${i+1} ${ch.displayName}`);
        const length = data.filter(x=>+x[ch.fieldName]>=4).length;
        sheet.cell('C'+(cumLen+i)).value(length);
        sheet.cell('D'+(cumLen+i)).value(100*length/students.length);
        
        for(let g of grade){
          const filteredTalentStudents = _get(talentStudentsByGrade, [g.id], []).filter(x=>+x[ch.fieldName]>=4);
          sheet.cell(g.col[0]+(cumLen+i)).value(filteredTalentStudents.length);
          sheet.cell(g.col[1]+(cumLen+i)).value(100*filteredTalentStudents.length/g.studentCount);
        }
      });
    }
    cumLen += characters.length;
    
    if(nonAcademics.length){
      sheet.range(`A${cumLen}:A${cumLen-1+nonAcademics.length}`).merged(true).startCell().value('3.2 非學科');
      nonAcademics.forEach((ch,i)=>{
        sheet.cell(`B${cumLen+i}`).value(`3.2.${i+1} ${ch}`);
        const length = data.filter(x=>x.non_academic && x.non_academic.indexOf(`\b${ch}\b`)>-1).length;
        sheet.cell('C'+(cumLen+i)).value(length);
        sheet.cell('D'+(cumLen+i)).value(100*length/students.length);

        for(let g of grade){
          const filteredTalentStudents = _get(talentStudentsByGrade, [g.id], []).filter(x=>x.non_academic && x.non_academic.indexOf(`\b${ch}\b`)>-1);
          sheet.cell(g.col[0]+(cumLen+i)).value(filteredTalentStudents.length);
          sheet.cell(g.col[1]+(cumLen+i)).value(100*filteredTalentStudents.length/g.studentCount);
        }
      });
    }
    cumLen += nonAcademics.length;
      
    if(talentTypes.length){
      sheet.range(`A${cumLen}:A${cumLen-1+talentTypes.length}`).merged(true).startCell().value('3.3 資優特質');
      talentTypes.forEach((ch,i)=>{
        sheet.cell(`B${cumLen+i}`).value(`3.3.${i+1} ${ch.name}`);
        const length = data.filter(x=>x.other_gifted && x.other_gifted.indexOf(ch.id)>-1).length;
        sheet.cell('C'+(cumLen+i)).value(length);
        sheet.cell('D'+(cumLen+i)).value(100*length/students.length);

        for(let g of grade){
          const filteredTalentStudents = _get(talentStudentsByGrade, [g.id], []).filter(x=>x.other_gifted && x.other_gifted.indexOf(ch.id)>-1);
          sheet.cell(g.col[0]+(cumLen+i)).value(filteredTalentStudents.length);
          sheet.cell(g.col[1]+(cumLen+i)).value(100*filteredTalentStudents.length/g.studentCount);
        }
      });
    }
    cumLen += talentTypes.length;

    // format

    sheet.range(`A5:${endCol}${cumLen-1}`).style({ border: { left: true, right: true, top: true, bottom: true } });
    
    sheet.usedRange().style({ horizontalAlignment: 'center', verticalAlignment: 'center', fontFamily: '標楷體', wrapText: true });
    
    sheet.column('A').width(37).style({horizontalAlignment: 'left'});
    sheet.column('B').width(24).style({horizontalAlignment: 'left'});
    sheet.range(`A1:${endCol}3`).style({fontSize: 16, horizontalAlignment: 'center'});
    
    const file = await workbook.outputAsync();
    exportExcel(file, `${yearName}年度 人才庫 (百分比)`);
  }catch(err){
    alert("Error!");
    console.log(err);
  }
}

const renderTalentStringForList = (x, talentTypes) => {
  let talents = renderTalentString(x, talentTypes);
  if(!x.remark) return talents;
  let remark = escapeHtml(x.remark||'').split("\n").join("<br/>");
  if(talents){
    return talents+'、3.5 備註：<br/>'+remark;
  }
  return '3.5 備註：<br/>'+remark;
}

export const genStudentList = ({filteredData, grade, classMap, years, yearId, talentTypes, searchFilter, andMode}) => {
  const hasSearchOn = checkHasSearchOn(searchFilter);
  const yearName = _get(find(years, {id: yearId}), ['displayName'], '20__-20__');
  const HTMLLayout = `
  <div class='center section break-page'>
    <p>${process.env.REACT_APP_SCHOOL_NAME}</p>
    <p>校本資優教育計劃 ${escapeHtml(yearName)}年度</p>
    <p>學生名單</p>
  </div>
  <table style='width:100%'>
  <colgroup>
    <col style='width:6em'>
    <col>
  </colgroup>
  <tbody>
    <tr>
      <th>年級班別：</th>
      <td>
        ${hasSearchOn ? `<div>${grade.map(x=>CheckBoxWithLabel(
          x.chiName,
          searchFilter.gradeClass && searchFilter.gradeClass[x.id]
        )).join("")}</div>
        <div>${ 
          grade.map(x=>classMap.get(x.id).filter(y=>_get(searchFilter, ['gradeClass',x.id, y.id])).map(x=>CheckBoxWithLabel(x.name, true)).join("")).join("")}</div>`: "全部"}
      </td>
    </tr>
    <tr>
      <th>標準：</th>
      <td style="position:relative">
        <div style="position:absolute;right:.5em;top:.5em;border:1px black solid;padding:.5em">${andMode?'符合下列所有標準':'符合下列任一標準'}</div>
        <div>1. 標準化測驗</div>
        <p>
        ${stdTests.map((x,i)=>CheckBoxWithLabel(
          `1.${i+1} ${x.displayName}`,
          _get(searchFilter, ['stdTest', x.fieldName])
        )).join("")}
        </p>
        <div>2. 校內成績表現</div>
        <div>2.1. 學科全級排名</div>
        <p>
        ${subjects.map((x,i)=>CheckBoxWithLabel(
          `2.1.${i+1} ${x.displayName}`,
          _get(searchFilter, ['grade', x.fieldName])
        )).join("")}
        </p>
        <div>3. 教師推薦</div>
        <div>3.1. 資優生行為特質問卷</div>
        <div>${characters.map((x,i)=>CheckBoxWithLabel(
          `3.1.${i+1} ${x.displayName}`,
          _get(searchFilter, ['char', x.fieldName])
        )).join("")}</div>
        <div>3.2. 非學科</div>
        <div>${nonAcademics.map((x,i)=>CheckBoxWithLabel(
          `3.2.${i+1} ${x}`,
          _get(searchFilter, ['non_academic', x])
        )).join("")}</div>
        <div>3.3. 資優特質</div>
        <div>${talentTypes.map((type,i)=>CheckBoxWithLabel(
          `3.3.${i+1} ${type.name}`,
          _get(searchFilter, ['other_gifted', type.id])
        )).join("")}</div>
        <div>3.4. ${CheckBoxWithLabel(
          `家長推薦`,
          _get(searchFilter, ['parent_suggest'])
        )}</div>
        <div>3.5. ${CheckBoxWithLabel(
          `備註`,
          _get(searchFilter, ['remark'])
        )}</div>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th rowSpan="${+filteredData.length||1}">名單：</th>
      <td>${
        filteredData.length ? `1.
        ${escapeHtml(filteredData[0].STRN)}
        ${escapeHtml(filteredData[0].classCode)}
        ${escapeHtml(filteredData[0].chiName||filteredData[0].engName)}&nbsp;${renderTalentStringForList(filteredData[0], talentTypes)}`:"找不到資料"}</td>
    </tr>
    ${filteredData.length>1?filteredData.slice(1).map((x,i)=>`<tr><td>${i+2}. ${escapeHtml(`${x.STRN} ${x.classCode} ${x.chiName||x.engName}`) + "&nbsp;" + renderTalentStringForList(x, talentTypes)}</td></tr>`).join(""):''}
  </tfoot>
  </table>
  `;
  return new Promise((resolve,reject)=> printLayout(HTMLLayout, `校本資優教育計劃 ${escapeHtml(yearName)} 學生名單`, '', resolve));
}