/* import react */
import React, { Component, memo, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import lodash */
import { find, groupBy, pick, filter } from 'lodash';


/* import components */
import {
	PageHeader,
	ErrorLabel,
	ConfirmModal,
	FullscreenDimmer,
	EmptyTableMsg,
  MySelect
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import {
  selectOptions,
  extractStartYear,
  threeYearPlanAndWhichYearFromYear,
  whetherReadOnlyForTYP
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

import '../../../Styles/RightFloatButton.css';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Table, Segment, Icon } from 'semantic-ui-react';

const RawStrategyTable = ({readOnly, finishedLoading, theConcern, aims, concernId, strategies, aimPrefixMap, strategyMap, modalToggle}) => (
  <Table textAlign='center' celled unstackable fixed compact="very" structured>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={readOnly?4:3} rowSpan={2}>目標</Table.HeaderCell>
        <Table.HeaderCell width={4} colSpan={3}>年度</Table.HeaderCell>
        <Table.HeaderCell width={readOnly?9:8} rowSpan={2}>大綱標題</Table.HeaderCell>
        {!readOnly && <Table.HeaderCell width={2} rowSpan={2}>大綱行動</Table.HeaderCell>}
      </Table.Row>
      <Table.Row>
        {
          ["一", "二", "三"].map((v, i) => (
            <Table.HeaderCell
              className='no-side-padding'
              key={i}
            >
              {v}{finishedLoading && theConcern && theConcern['subTopic'+(i+1)]?(<><br/>({theConcern['subTopic'+(i+1)]})</>):''}
            </Table.HeaderCell>
          ))
        }
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {finishedLoading ? (aims.length === 0 ? <EmptyTableMsg colSpan='6' msg='沒有三年計劃目標' /> : filter(aims, { concernId }).map(aim => {
        
        const filteredStrategies = filter(strategies, { aimId: aim.id });
        const firstCellContent = (<>
          <div className='form-group-margin-narrow'>{aimPrefixMap.get(aim.id)} {aim.title}</div>
          {!readOnly && (<>
            <Button
              circular
              color='blue'
              icon='edit'
              data-aim-id={aim.id}
              data-modalname='isEditAimModalOpen'
              onClick={modalToggle}
              title='編輯目標'
            />
            <Button
              circular
              color='red'
              icon='delete'
              data-aim-id={aim.id}
              data-modalname='isDeleteAimModalOpen'
              onClick={modalToggle}
              title='刪除目標'
            />
          </>)}
        </>);
        return filteredStrategies.length?(<Fragment key={aim.id}>
          {filteredStrategies.map((strategy, j, b) => {
            return (<Table.Row key={"strat" + strategy.id}>
              {!j && <Table.Cell rowSpan={b.length+!readOnly}>{firstCellContent}</Table.Cell>}
              {[0, 1, 2].map(k => (
                <Table.Cell key={k} className="textlessCell">
                  {!!(+strategy['year'+(k+1)]) && <Icon name="check" />}
                </Table.Cell>
              ))}
              <Table.Cell textAlign="left" className="textarea-text">{strategyMap.get(strategy.id)} {strategy.title}</Table.Cell>
              {!readOnly && (<Table.Cell className="textlessCell">
                <Button
                  color='blue'
                  icon='edit'
                  data-selected-id={strategy.id}
                  data-modalname='isEditStrategyModalOpen'
                  onClick={modalToggle}
                  circular
                  title='編輯大綱'
                />
                <Button
                  color='red'
                  icon='delete'
                  data-selected-id={strategy.id}
                  data-modalname='isDeleteStrategyModalOpen'
                  onClick={modalToggle}
                  circular
                  title='刪除大綱'
                />
              </Table.Cell>)}
            </Table.Row>)
          })}
          {!readOnly && (<Table.Row key={"add" + aim.id}>
            <Table.Cell colSpan={5} className='merged-cell-padding'>
              <Button
                disabled={!finishedLoading}
                color='green'
                icon='add'
                data-aim-id={aim.id}
                data-modalname='isEditStrategyModalOpen'
                onClick={modalToggle}
                circular
                content='新增大綱'
              />
            </Table.Cell>
          </Table.Row>)}
        </Fragment>):(
          <Table.Row key={aim.id}>
            <Table.Cell>{firstCellContent}</Table.Cell>
            <Table.Cell colSpan={5} textAlign='center' className='merged-cell-padding'>
              <strong>未有策略大綱</strong>
              {!readOnly && (<Button
                disabled={!finishedLoading}
                color='green'
                icon='add'
                data-aim-id={aim.id}
                data-modalname='isEditStrategyModalOpen'
                onClick={modalToggle}
                circular
                content='新增大綱'
              />)}
            </Table.Cell>
          </Table.Row>
        )})
      ) : <EmptyTableMsg colSpan='6' msg='載入資料中' />}
    </Table.Body>
  </Table>
);

const StrategyTable = memo(RawStrategyTable);

class ThreeYearStrategy extends Component {
  constructor(props) {
    super(props);

    this.state = {

      yearInfo: [],

      TYPOptions: [],
      selectedTYP: null,

      finishedLoading: false,
      dimmerOpen: false,
      readOnly: true,

      threeYearPlans: [],

      concerns: [],
      concernOptions: [],
      concernId: '',

      aims: [],
      aimsOptions: [],

      strategies: [],

      aimId: '',
      title: '',
      eng_title: '',
      year1: false,
      year2: false,
      year3: false,

      isEditStrategyModalOpen: false,
      isDeleteStrategyModalOpen: false,
      selectedId: null,
    };

    this.aimValidator = new SimpleReactValidator({
      element: (message) => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        accepted: '請選擇最少一個年份',
        required: '請輸入有效資料',
      },
    });

    this.strategyValidator = new SimpleReactValidator({
      element: (message) => <ErrorLabel message={message} />,
      autoForceUpdate: this,
      messages: {
        accepted: '請選擇最少一個年份',
        required: '請輸入有效資料',
      },
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;

    try {
      await this.fetch();
      this.setStateAsync({
        finishedLoading: true,
      });
    } catch (err) {
      if(err!=='unmounted') console.log('Error when mounting aims and strategies', err);
    }
  }

  fetch = async () => {
    try {
      const [rawYears, TYPs] = await Promise.all([get('getYear'), get('getTYP')]);
      const [defaultTYP,] = threeYearPlanAndWhichYearFromYear(rawYears, TYPs);
      if(TYPs.length){
        await this.setStateAsync({
          yearInfo: rawYears,
          threeYearPlans: TYPs,
          selectedTYP: this.state.selectedTYP?this.state.selectedTYP:defaultTYP,
          TYPOptions: selectOptions(TYPs, typ => {
            const startYear = extractStartYear(find(rawYears, { id: typ.beginYear }));
            return `${startYear}-${startYear + 3}`;
          }, "id")
        });
      }else{
        alert("三年計劃不存在，請前往 1.1 檢查資料是否出現錯誤");
        await this.setStateAsync({
          yearInfo:[],
          threeYearPlans: [],
          selectedTYP: null,
          TYPOptions: [],
        });
      }
      await this.fetch3YearPlanDetails();
    } catch (err) {
      if(err!=='unmounted') console.log('Error when fetching aims and strategies', err);
    }
  }

  fetch3YearPlanDetails = async () => {
    try {
      const {yearInfo, threeYearPlans, selectedTYP} = this.state;

      let concerns = [];
      if(selectedTYP){
        await this.setStateAsync({
          readOnly: whetherReadOnlyForTYP(yearInfo, threeYearPlans, selectedTYP)
        });
        concerns = await get('get3YearConcern/'+selectedTYP);
      }
      if(concerns.length){
        concerns.forEach((concern, i)=>{
          concern.title = `${i+1}. ${concern.title}`;
        });

        const concernPrefixMap = new Map(concerns.map((x,i)=>[x.id,i+1]));
        const concernId = concernPrefixMap.has(this.state.concernId) ? this.state.concernId : (concerns && concerns.length ? concerns[0].id : '');

        await this.setStateAsync({
          concerns,
          concernId,
          theConcern: find(concerns, {id: concernId}),
          concernOptions: selectOptions(concerns, 'title', 'id'),
        });
        const [aims, strategies] = await Promise.all([
          get('get3YearAim/'+concernId),
          get(`get3YearStrategyByConcern/${concernId}`)
        ]);

        const aimPrefixMap = new Map();

        for(let i=0;i<aims.length;i++){
          aimPrefixMap.set(aims[i].id, `${concernPrefixMap.get(concernId)}.${i+1}.`);
        }

        const groupedStrategies = groupBy(strategies, 'aimId');

        const strategyMap = new Map();

        for(let aimId in groupedStrategies){
          for(let i=0;i<groupedStrategies[aimId].length;i++){
            const s = groupedStrategies[aimId][i];
            strategyMap.set(s.id, `${aimPrefixMap.get(+aimId)}${i+1}.`);
          }
        }
        
        await this.setStateAsync({
          aims,
          aimsOptions: selectOptions(aims, x=>`${aimPrefixMap.get(x.id)} ${x.title}`, "id"),
          strategies,
          aimPrefixMap,
          strategyMap,
        });
      }else{
        alert("三年計劃關注事項不存在，請前往 1.1 檢查資料是否出現錯誤");
        await this.setStateAsync({
          concerns: [],
          concernId: null,
          theConcern: {},
          concernOptions: [],
          aims: [],
          aimsOptions: [],
          strategies: [],
          aimPrefixMap: new Map(),
          strategyMap: new Map(),
        });
      }
    } catch (err) {
      if(err!=='unmounted') console.log('Error when fetching aims and strategies', err);
    }
  }

  /* input update handler */
  inputChange = (event, data) => {
    const {inputType, stateName} = event.target.closest('.ui, textarea, button').dataset;
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value })
  }

  /* 3yearplan change */
  TYPChange = async (_, { value }) => {
    const { selectedTYP } = this.state;
    if (selectedTYP !== value) {
      try{
        await this.setStateAsync({
          finishedLoading: false,
          selectedTYP: value,
        });
        await this.fetch3YearPlanDetails();
        await this.setStateAsync({
          finishedLoading: true,
        });
      }catch(err){
        if(err!=='unmounted') console.log('Error when changing TYP for aims and strategies', err);
      }
    }
  }

  /* concern change */
  concernChange = async (_, { value }) => {
    const { concernId } = this.state;
    if (concernId !== value) {
      try{
        await this.setStateAsync({
          finishedLoading: false,
          concernId: value,
        });
        await this.fetch3YearPlanDetails();
        await this.setStateAsync({
          finishedLoading: true,
        });
      }catch(err){
        if(err!=='unmounted') console.log('Error when changing concern for aims and strategies', err);
      }
    }
  }


  /* modal toggle */
  modalToggle = (eventOrStateName) => {
    try{
      let data = {}, modalData = {};
    
      let modalname = eventOrStateName;
    
      if(typeof eventOrStateName === 'object'){
        if(eventOrStateName.target.classList.contains('modals')){
          modalData = eventOrStateName.target.firstElementChild.dataset;
        }else{
          modalData = eventOrStateName.target.closest('.ui, button').dataset;
        }
        modalname = modalData.modalname;
      }
      data[modalname] = !this.state[modalname];
      let ref;
      switch (modalname) {
        case 'isEditAimModalOpen':
        case 'isDeleteAimModalOpen':
          data.selectedId = modalData.aimId || null;
          ref = find(this.state.aims, { id: +data.selectedId });
          if(ref){
            Object.assign(data, pick(ref, ['title', 'eng_title']));
          }else{
            data.title = data.eng_title = '';
          }
          this.aimValidator.hideMessages();
          break;
        case 'isEditStrategyModalOpen':
        case 'isDeleteStrategyModalOpen':
          data.selectedId = modalData.selectedId || null;
          ref = find(this.state.strategies, { id: +data.selectedId });
          if(ref){
            Object.assign(data, pick(ref, ['title', 'eng_title', 'aimId', 'year1', 'year2', 'year3']));
          }else{
            Object.assign(data, {
              title: '',
              eng_title: '',
              aimId: '',
              year1: 1,
              year2: 1,
              year3: 1,
            });
          }
          if(modalData.aimId){
            data.aimId = +modalData.aimId;
          }
          this.strategyValidator.hideMessages();
          break;
        default:break;
      }
      this.setStateAsync(data);
    }catch(err){
      if(err!=='unmounted') console.log("Error when modal-toggling for aim and strategies",err);
    }
  }

  /* save aim */
  saveAim = async () => {
    if (!this.aimValidator.allValid()) {
      this.aimValidator.showMessages();
      return;
    }
    const {selectedId, title, eng_title, concernId} = this.state;
    const data = {
      title,
      eng_title,
      concernId
    };

    if(selectedId){
      data.id = selectedId;
    }

    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('edit3YearAim', data);
      if (result && result.status) {
        this.aimValidator.hideMessages();
        await this.fetch3YearPlanDetails();
        this.modalToggle('isEditAimModalOpen');
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存三年目標時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  /* save strategy */
  saveStrategy = async () => {
    if (!this.strategyValidator.allValid()) {
      this.strategyValidator.showMessages();
      return;
    }
    const data = pick(this.state, ['aimId', 'title', 'eng_title', 'year1', 'year2', 'year3']);

    if(this.state.selectedId){
      data.id = this.state.selectedId;
    }

    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('edit3YearStrategy', data);
      if (result && result.status) {
        this.strategyValidator.hideMessages();
        await this.fetch3YearPlanDetails();
        this.modalToggle('isEditStrategyModalOpen');
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存三年策略大綱時發生錯誤");
        console.log(err);
      }
    }
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  /* delete aim */
  deleteAim = async () => {
    let data = { id: this.state.selectedId };
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('delete3YearAim', data);
      if (result && result.status) {
        await this.fetch3YearPlanDetails();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("刪除三年目標時發生錯誤");
        console.log(err);
      }
    }
    this.modalToggle('isDeleteAimModalOpen');
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  /* delete strategy */
  deleteStrategy = async () => {
    let data = { id: this.state.selectedId };
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const result = await post('delete3YearStrategy', data);
      if (result && result.status) {
        await this.fetch3YearPlanDetails();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("刪除三年策略大綱時發生錯誤");
        console.log(err);
      }
    }
    this.modalToggle('isDeleteStrategyModalOpen');
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }


  render() {
    const {
      finishedLoading,
      dimmerOpen,
      readOnly,

      TYPOptions,
      selectedTYP,

      theConcern,
      concernOptions,
      concernId,

      aims,
      strategies,

      isEditStrategyModalOpen,
      isEditAimModalOpen,
      isDeleteStrategyModalOpen,
      isDeleteAimModalOpen,

      aimsOptions,
      aimId,

      title,
      eng_title,
      aimPrefixMap,
      strategyMap,
    } = this.state;

    this.aimValidator.purgeFields();
    this.strategyValidator.purgeFields();

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='三年計劃 - 目標及策略大綱' subTitle='為每個關注事項設定目標及策略大綱' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Segment padded>
                <Form>
                  <Form.Group>
                    <Form.Field inline>
                      <label>三年計劃</label>
                      <MySelect
                        compact
                        disabled={!finishedLoading}
                        options={TYPOptions}
                        value={selectedTYP}
                        onChange={this.TYPChange}
                      />
                    </Form.Field>
                    <Form.Field inline>
                      <label>關注事項</label>
                      <MySelect
                        compact
                        disabled={!finishedLoading}
                        options={concernOptions}
                        value={concernId}
                        onChange={this.concernChange}
                        placeholder={concernOptions.length?"關注事項":"沒有關注事項"}
                        error={!concernOptions.length}
                      />
                    </Form.Field>
                    {!readOnly && (
                      <Button
                        disabled={!finishedLoading}
                        color='green'
                        content='新增目標'
                        floated='right'
                        icon='add'
                        data-modalname='isEditAimModalOpen'
                        onClick={this.modalToggle}
                        circular
                      />
                    )}
                  </Form.Group>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <StrategyTable
                readOnly={readOnly}
                finishedLoading={finishedLoading}
                theConcern={theConcern}
                concernId={concernId}
                aims={aims}
                strategies={strategies}
                aimPrefixMap={aimPrefixMap}
                strategyMap={strategyMap}
                modalToggle={this.modalToggle}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen?(<FullscreenDimmer active={dimmerOpen} isLoading={true} />):(
          <>
            <Modal open={isEditStrategyModalOpen} data-modalname='isEditStrategyModalOpen' onClose={this.modalToggle} closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>設定學校策略大綱</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    {finishedLoading && (
                      <Form.Group className='form-group-margin' grouped>
                        <Form.Field>
                          <label>目標</label>
                          <MySelect
                            search
                            options={aimsOptions}
                            value={aimId}
                            data-input-type='select'
                            data-state-name='aimId'
                            placeholder='目標'
                            onChange={this.inputChange}
                          />
                        </Form.Field>
                        {this.strategyValidator.message('aimId', aimId, 'required')}
                      </Form.Group>
                    )}
                    <Form.Group className='form-group-margin' grouped>
                      <Form.Input
                        value={title}
                        data-input-type='text'
                        data-state-name='title'
                        onChange={this.inputChange}
                        label='標題'
                        placeholder='標題'
                        width={12}
                      />
                      <Form.Input
                        value={eng_title}
                        data-input-type='text'
                        data-state-name='eng_title'
                        onChange={this.inputChange}
                        label='英文標題'
                        placeholder='英文標題'
                        width={12}
                      />
                    </Form.Group>
                    {this.strategyValidator.message('strategyTitle', title, 'required')}
                    <Form.Group>
                      <Form.Field>
                        <label>要實行的年度</label>
                      </Form.Field>
                      {["一", "二", "三"].map((v, i) => (
                        <Form.Checkbox
                          key={i}
                          data-input-type='checkbox'
                          data-state-name={'year'+(i+1)}
                          checked={!!(+this.state['year' + (i + 1)])}
                          onChange={this.inputChange}
                          label={`第${v}年` + (finishedLoading && theConcern && theConcern['subTopic' + (i + 1)] ? ` (${theConcern['subTopic' + (i + 1)]})` : '')}
                        />
                      ))}
                    </Form.Group>
                    <Form.Group>
                      {this.strategyValidator.message('strategyYear', !!(this.state.year1 || this.state.year2 || this.state.year3), 'accepted')}
                    </Form.Group>
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' content='取消' icon='cancel' data-modalname='isEditStrategyModalOpen' onClick={this.modalToggle} circular />
                <Button color='green' content='儲存' icon='save' onClick={this.saveStrategy} circular />
              </Modal.Actions>
            </Modal>

            <Modal open={isEditAimModalOpen}  data-modalname='isEditAimModalOpen' onClose={this.modalToggle} closeOnEscape={false} closeOnDimmerClick={false}>
              <Modal.Header>設定三年計劃目標</Modal.Header>
              <Modal.Content>
                <Segment basic>
                  <Form>
                    <Form.Group className='form-group-margin' grouped>
                      <Form.Input
                        value={title}
                        data-input-type='text'
                        data-state-name='title'
                        onChange={this.inputChange}
                        label='標題'
                        placeholder='標題'
                        width={12}
                      />
                      <Form.Input
                        value={eng_title}
                        data-input-type='text'
                        data-state-name='eng_title'
                        onChange={this.inputChange}
                        label='英文標題'
                        placeholder='英文標題'
                        width={12}
                      />
                    </Form.Group>
                    {this.aimValidator.message('aimTitle', title, 'required')}
                  </Form>
                </Segment>
              </Modal.Content>
              <Modal.Actions>
                <Button color='red' content='取消' icon='cancel'  data-modalname='isEditAimModalOpen' onClick={this.modalToggle} circular />
                <Button color='green' content='儲存' icon='save' onClick={this.saveAim} circular />
              </Modal.Actions>
            </Modal>

            <ConfirmModal
              open={isDeleteStrategyModalOpen}
              description={<>確定刪除學校三年計劃策略大綱<span className='red'>「{title}」</span>？注意這會一併刪除屬於它的周年及科組計劃！</>}
              modalname='isDeleteStrategyModalOpen'
              cancel={this.modalToggle}
              confirm={this.deleteStrategy}
            />
            <ConfirmModal
              open={isDeleteAimModalOpen}
              description={<>確定刪除學校三年計劃目標<span className='red'>「{title}」</span>？注意這會一併刪除屬於它的策略大綱！</>}
              modalname='isDeleteAimModalOpen'
              cancel={this.modalToggle}
              confirm={this.deleteAim}
            />
          </>
        )}
      </>
    )
  }
}

export default withRouter(ThreeYearStrategy);