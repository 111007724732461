import React, { useState } from 'react';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { map } from 'lodash';
import { getAppraisalFile } from '../../../Helper/ApiHelper';
import FullscreenDimmer from '../../../Components/FullscreenDimmer';

const DisplayBeforeFile = ({ files, teacherName = null }) => {
	const [loading, setLoading] = useState(false);

	const download = async (appraisalFileId = null, name = '教案') => {
		setLoading(true);
		const appraisalFileIds = !!appraisalFileId ? [appraisalFileId] : map(files, 'id');
		const isZip = !appraisalFileId;
		if (!appraisalFileId && !!teacherName) name = `${teacherName}_${name}`;
		await getAppraisalFile('downloadAppraisalFile', { appraisalFileIds, name, isZip });
		setLoading(false);
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			{files.length > 0 && (
				<Segment>
					<div style={{ marginBottom: 10 }}>
						<b>教案、簡報、工作紙、電子平台截圖(如適用)：</b>
					</div>
					<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
						{files.map(({ id, name }, index) => (
							<div
								key={index}
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: 10,
									marginRight: 20,
									cursor: 'pointer',
								}}
							>
								<div>{name}</div>
								<div style={{ marginLeft: 5 }}>
									<Button onClick={() => download(id, name)} color="blue" icon="download" circular />
								</div>
							</div>
						))}
					</div>
					{files.length > 1 && (
						<Button color="green" content="下載全部" icon="download" type="button" onClick={() => download()} />
					)}
				</Segment>
			)}
		</React.Fragment>
	);
};

export default DisplayBeforeFile;
