/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

/* import components */
import {
	PageHeader,
	FullscreenDimmer,
	ErrorLabel,
	BlockerPrompt,
	EmptyTableMsg,
	MySelect,
  Buttons
} from '../../../../Components';

/* import helper functions */
import { get, post } from '../../../../Helper/ApiHelper';
import { selectOptions, hasDuplicate } from '../../../../Helper/Helper';
import { inputHandler } from '../../../../Helper/FormHelper';

/* import lodash */
import {pick, findIndex} from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Segment, Form, Table, Input } from 'semantic-ui-react';

class NormalGroupEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: false,
      isBlocking: false,
      finishedLoading: false,
      
      yearName: null,
      locationOptions: [],
      groups: null,
    }
    this.addCount = 0;

    this.tableColumnData = [
      {headerName: '編號', width: 5 },
      {headerName: '地點', width: 6 },
      {headerName: '刪除', width: 5 },
    ];

    this.fileInputRef = React.createRef();

    this.validator = new SimpleReactValidator({
      element: message => <ErrorLabel message={message} pointing={false} />,
      autoForceUpdate: this,
      messages: {
        required: '請輸入資料',
        accepted: ':attribute欄位發生重複'
      }
    });
    this.newestDropdown = React.createRef();
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.fetchNormalGroups();
      this.setStateAsync({ dimmerOpen: false, finishedLoading: true });
    }catch(err){}
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try{
      const [year, locations] = await Promise.all([
        get('getOneYear/'+this.props.match.params.yearId),
        get('getLocation'),
      ]);
      
      await this.setStateAsync({
        yearName: year ? year[0].displayName : '-', 
        locationOptions: selectOptions(locations, 'name', 'id'),
      });

    }catch(err){if(err!=='unmounted')console.log("Error when fetching data for Normal Groups", err)}
  }

  fetchNormalGroups = async () => {
    try{
      const groups = await get('getAllFridayActivityNormalGroup/' + this.props.match.params.yearId);
      await this.setStateAsync({
        groups
      });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching groups for Normal Groups", err)}
  }

  addGroup = () => {
    this.setStateAsync({
      groups: this.state.groups.concat({id: "N"+(this.addCount++), code: null, locationId: null}),
      isBlocking: true
    });
  }

  removeGroup = (event) => {
    const {id} = event.target.dataset;
    this.setStateAsync({
      groups: this.state.groups.filter(x=>x.id!==(Number.isInteger(+id)?+id:id)),
      isBlocking: true
    })
  }

  groupChange = (event, data) => {
    const {groups} = this.state;
    const {id, propname, type} = event.target.closest('.ui').dataset;
    const index = findIndex(groups,{id: Number.isInteger(+id)?+id:id});
    if(index>=0){
      this.setStateAsync({
        groups: groups.slice(0,index).concat({...groups[index], [propname]: inputHandler(type, data)}, groups.slice(index+1)),
        isBlocking: true
      })
    }
  }

  /* input update handler */
  inputChange = (inputType, stateName) => (event, data) => {
    let value = inputHandler(inputType, data);
    this.setStateAsync({ [stateName]: value, isBlocking: true });
  }

  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    try{
      await this.setStateAsync({ dimmerOpen: true, finishedLoading: false });
      const data = {
        yearId: this.props.match.params.yearId,
        groups: this.state.groups.map(x=>Number.isInteger(x.id)?x:pick(x,['code','locationId']))
      }
      const result = await post('editAllFridayActivityNormalGroup', data);
      if (result && result.status) {
        await this.setStateAsync({isBlocking: false});
        this.goBack();
        return;
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        this.setStateAsync({ dimmerOpen: false, finishedLoading: true });
        alert(`儲存${this.state.yearName||''}年度普通週五小組時發生錯誤`);
        console.log(err);
      }
    }
  }

  goBack = () => {
    this.props.history.push('/eca/fridayActivity/normalgroup');
  }

  render() {
    const {
      dimmerOpen,
      yearName,
      groups,
      isBlocking,
      finishedLoading
    } = this.state;

    this.validator.purgeFields();

    return (
      <>
        {dimmerOpen && <FullscreenDimmer active={dimmerOpen} isLoading={true} /> }
        <Form>
          <Grid stackable doubling>
            <Grid.Row>
              <Grid.Column>
                <PageHeader title={yearName!==null?`編輯${yearName||'---'}年度學生普通小組`:'資料載入中'} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Segment.Group className='form-group-margin'>
                  <Segment secondary>
                    <Table fixed textAlign="center" selectable celled>
                      <Table.Header>
                        <Table.Row>
                          {this.tableColumnData.map(colData => (
                            <Table.HeaderCell key={colData.headerName} width={colData.width}>{colData.headerName}</Table.HeaderCell>
                          ))}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {finishedLoading ? (
                          groups.length ? (
                            groups.map(item => (
                              <Table.Row key={item.id}>
                                <Table.Cell>
                                  <Form.Field>
                                    <Input
                                      type="text"
                                      value={item.code || ''}
                                      onChange={this.groupChange}
                                      data-id={item.id}
                                      data-propname="code"
                                      data-type="text"
                                    />
                                    {this.validator.message(`${item.id}CODE`, item.code, 'required')}
                                  </Form.Field>
                                </Table.Cell>
                                <Table.Cell className="table-cell-with-dropdown">
                                  <Form.Field>
                                    <MySelect
                                      value={item.locationId}
                                      options={this.state.locationOptions}
                                      onChange={this.groupChange}
                                      search
                                      data-id={item.id}
                                      data-propname="locationId"
                                      data-type="select"
                                      noResultsMessage="找不到地點"
                                    />
                                    {this.validator.message(`${item.id}LOCATION`, item.locationId, 'required')}
                                  </Form.Field>
                                </Table.Cell>
                                <Table.Cell>
                                  <Buttons.DeleteButton
                                    onClick={this.removeGroup}
                                    data-id={item.id}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ))
                          ) : <EmptyTableMsg colSpan={this.tableColumnData.length} msg="找不到資料" />
                        ) : <EmptyTableMsg colSpan={this.tableColumnData.length} msg="載入資料中" />}
                      </Table.Body>
                      {finishedLoading && (<Table.Footer fullWidth>
                        <Table.Row>
                          <Table.HeaderCell colSpan={this.tableColumnData.length}><Buttons.AddButton onClick={this.addGroup}/></Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>)}
                    </Table>
                  </Segment>
                  <Segment secondary>
                    {this.validator.message("位置", hasDuplicate((groups||[]).map(x=>x.locationId)), 'accepted')}
                    {this.validator.message("編號", hasDuplicate((groups||[]).map(x=>x.code)), 'accepted')}
                  </Segment>
                </Segment.Group>

                <div className='text-center'>
                  <Buttons.CancelButton disabled={!finishedLoading} onClick={this.goBack} />
                  <Buttons.SaveButton disabled={!finishedLoading} onClick={this.save} />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(NormalGroupEdit);