import React, {useState, useEffect, useCallback, memo, useMemo} from 'react';
import {Modal, Table, Form, Segment, Select, Input, Checkbox} from 'semantic-ui-react';
import {Buttons} from '../../Components';
import { inputHandler } from '../../Helper/FormHelper';
import {findIndex, get} from 'lodash';

const RawGroupTeacherModalTableRow = (props) => {
  const {changeItem} = props;
  const data = props.groupTeacher;
  return (<Table.Row key={data.id}>
    <Table.Cell>
      {get(props.teacherLookup, [data.teacherId, 'name'], '無效教師')}
    </Table.Cell>
    <Table.Cell>
      <Input
        value={data.title || ''}
        data-id={data.id}
        data-state-name='title'
        data-input-type='text'
        onChange={changeItem}
      />
    </Table.Cell>
    <Table.Cell>
      <Checkbox
        checked={data.isChief===true || data.isChief===1}
        value={1}
        data-id={data.id}
        data-state-name='isChief'
        data-input-type='radio'
        onChange={changeItem}
      />
    </Table.Cell>
    <Table.Cell>
      <Checkbox
        checked={data.isChief===2}
        value={2}
        data-id={data.id}
        data-state-name='isChief'
        data-input-type='radio'
        onChange={changeItem}
      />
    </Table.Cell>
    {props.groupType==='科組' && (
      <Table.Cell>
        <Checkbox
          checked={!!data.isGradeChief}
          data-id={data.id}
          data-state-name='isGradeChief'
          data-input-type='toggle'
          onChange={changeItem}
        />
      </Table.Cell>
    )}
  </Table.Row>);
}

const GroupTeacherModalTableRow = memo(RawGroupTeacherModalTableRow);

export default (props) => {
  const { close, groupId, yearId, teacherOptions } = props;

  const [data, setData] = useState(props.groupTeachers || []);
  useEffect(() => {
    setData(props.groupTeachers || [])
  }, [props.groupTeachers]);

  const teachers = useMemo(()=>{
    return data.map(x=>x.teacherId);
  }, [data.map(x=>x.teacherId).join("\b")]);

  const [newCount, setNewCount] = useState(0);
  useEffect(() => {
    setNewCount(0);
  }, [groupId, yearId]);

  const addCount = (count=1)=>{setNewCount(newCount+count);}

  const changeTeachers = useCallback((event, newData) => {
    const newTeacherSet = new Set(inputHandler('select', newData));
    setData(data => {
      const newTeachers = [];
      for(let gt of data){
        if(newTeacherSet.has(gt.teacherId)){
          newTeachers.push(gt);
          newTeacherSet.delete(gt.teacherId);
          continue;
        }
      }
      const teachersToBeAdded = Array.from(newTeacherSet);
      for(let i=0;i<teachersToBeAdded.length;i++){
        newTeachers.push({
          id: `new${newCount+i}`,
          isChief: false,
          teacherId: teachersToBeAdded[i],
          title: ''
        });
      }
      addCount(teachersToBeAdded.length);
      return newTeachers;
    });
  }, [teachers]);

  const changeItem = useCallback((event, value) => {
    const {id, stateName, inputType} = event.target.closest('.ui, textarea, button').dataset;
    setData(data=>{
      const i = findIndex(data, {id: Number.isNaN(+id)?id:+id});
      if(i>-1){
        const gts = [...data];
        const trueValue = inputHandler(inputType, value);
        gts[i] = {...gts[i], [stateName]:trueValue};
        if(stateName==='isSubChief' && trueValue){
          gts[i].isChief = false;
        }
        if(stateName==='isChief' && trueValue){
          gts[i].isSubChief = false;
        }
        return gts;
      }
      return data;
    });
  }, [setData]);

  const validate = () => {
    props.onConfirm(data);
  }

  return (
    <Modal
      open
      onClose={close}
      data-modal-name='isEditModalOpen'
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>編輯<span className='red'>{props.groupName}</span>教職員</Modal.Header>
      <Modal.Content>
        <Segment basic>
          <Form>
            <Form.Group grouped>
              <Form.Field className='form-group-margin'>
                <label>教職員</label>
                <Select
                  fluid
                  clearable
                  onChange={changeTeachers}
                  value={teachers}
                  multiple
                  search
                  options={teacherOptions}
                  placeholder=""
                  noResultsMessage="找不到教職員"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className='form-group-margin'>
                <Table celled unstackable fixed textAlign="center">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={5}>教職員</Table.HeaderCell>
                      <Table.HeaderCell width={5}>職銜</Table.HeaderCell>
                      <Table.HeaderCell width={2}>組長?</Table.HeaderCell>
                      <Table.HeaderCell width={2}>副組長?</Table.HeaderCell>
                      {props.groupType==='科組' && <Table.HeaderCell width={2}>級主任?</Table.HeaderCell>}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.map((gt) => (
                      <GroupTeacherModalTableRow
                        key={gt.id}
                        groupType={props.groupType}
                        teacherLookup={props.teacherLookup}
                        groupTeacher={gt}
                        changeItem={changeItem}
                      />
                    ))}
                  </Table.Body>
                </Table>
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Buttons.CancelButton
          onClick={close}
          data-modal-name='isEditModalOpen'
        />
        <Buttons.SaveButton
          onClick={validate}
        />
      </Modal.Actions>
    </Modal>
  )
}