/* import react */
import React, { Component, } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
	PageHeader,
	MyTable,
} from '../../../Components';

import {weekdayLookup} from '../../../Const/Const';

/* import helper functions */
import { get } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { defaultYear, selectOptions } from '../../../Helper/Helper';

import "../../../Styles/CenterLabelsInGroups.css";

/* import semantic-ui element */
import { Grid, Select, Tab, Label } from 'semantic-ui-react';

class ActivityConflict extends Component {
  constructor(props) {
    super(props);
    this.state = {
      years: [],
      finishedLoading: false,
      selectedYear: null,
      venueConflict: null,
      studentConflict: [],
    };

    this.venueTableHeader = [
      {headerName: '地點',width: 2, cellRender: 'locationName'},
      {headerName: '活動1',width: 3, cellRender: 'activity1'},
      {headerName: '活動2',width: 3, cellRender: 'activity2'},
      {headerName: '時間',width: 2, cellRender: x=><>星期{weekdayLookup[String(x.weekday+1)]}<br/>{x.collideStart.replace(/:00/,'')}-{x.collideEnd.replace(/:00/,'')}</>},
      {headerName: '日期',width: 5, cellRender: x=>x.dates.length?<Label.Group>{x.dates.map(y=><Label key={y} color='teal'>{y}</Label>)}</Label.Group>:"未有"}
    ];
    
    this.studentTableHeader = [
      {headerName: '活動1',width: 3, cellRender: 'activity1'},
      {headerName: '活動2',width: 3, cellRender: 'activity2'},
      {headerName: '時間',width: 2, cellRender: x=><>星期{weekdayLookup[String(x.weekday+1)]}<br/>{x.collideStart.replace(/:00/,'')}-{x.collideEnd.replace(/:00/,'')}</>},
      {headerName: '日期',width: 4, cellRender: x=>x.dates.length?<Label.Group>{x.dates.map(y=><Label key={y} color='teal'>{y}</Label>)}</Label.Group>:"未有"},
      {headerName: '學生',width: 4, cellRender: x=>x.students.length?<Label.Group>{x.students.map(y=><Label key={y} color='orange'>{y}</Label>)}</Label.Group>:"未有"}
    ];

    this.compact = {compact: true}

    this.panes =  [
      { menuItem: '場地相撞', render: this.displayVenueConflict },
      { menuItem: '學生相撞', render: this.displayStudentConflict },
    ]
  }

  setStateAsync = (state, errMsg = 'error') => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    }).catch(err=>{console.log(errMsg);console.log(err);})
  )

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetch();
    await this.fetchConflictInfo();
    this.setStateAsync({finishedLoading: true});
  }

  fetch = async () => {
    try{
      const yearRes = await get('getYear');
      await this.setStateAsync({
        years: selectOptions(yearRes, 'displayName', 'id'),
        selectedYear: this.state.selectedYear || defaultYear(yearRes)
      })
    }catch(err){if(err!=='unmounted')console.log("Error when getching activity conflict", err)}
  }

  fetchConflictInfo = async () => {
    try{
      const [venueConflict, studentConflict] = await Promise.all(['getConflictingVenues/','getConflictingStudents/'].map(x=>get(x+this.state.selectedYear)));

      await this.setStateAsync({
        venueConflict, studentConflict
      })
    }catch(err){if(err!=='unmounted')console.log("Error when getching activity conflict", err)}
  }

  yearChange = async (event,data) => {
    try{
      await this.setStateAsync({
        selectedYear: inputHandler('select', data)
      })
      await this.fetchConflictInfo();
    }catch(err){if(err!=='unmounted')console.log("Error when getching activity conflict", err)}
  }
  
  handleTabChange = (e, { activeIndex }) => this.setStateAsync({ activeIndex })

  displayVenueConflict = () => (
    <Tab.Pane>
      <MyTable
        tableColumnData={this.venueTableHeader}
        data={this.state.venueConflict}
        tableProps={this.compact}
        finishedLoading={this.state.finishedLoading}
      />
    </Tab.Pane>
  )

  displayStudentConflict = () => (
    <Tab.Pane>
      <MyTable
        tableColumnData={this.studentTableHeader}
        data={this.state.studentConflict}
        tableProps={this.compact}
        finishedLoading={this.state.finishedLoading}
      />
    </Tab.Pane>
  )


  componentWillUnmount = () => {
    this.mounted = false;
  }

  render() {
    const {
      activeIndex,
      years,
      selectedYear,
    } = this.state;

    return (
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='學生場地相撞' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Select onChange={this.yearChange} options={years} value={selectedYear} />
          </Grid.Column>
        </Grid.Row>
        
        <Grid.Row>
          <Grid.Column>
            <Tab
              panes={this.panes}
              activeIndex={activeIndex}
              onTabChange={this.handleTabChange}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(ActivityConflict);