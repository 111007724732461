/* import lodash */
import {keyBy, flatten, groupBy, filter} from 'lodash';

/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  EmptyTableMsg,
  AggregateNumber,
} from '../../../Components';

/* import helper functions */
import { get } from '../../../Helper/ApiHelper';
import {
  selectOptions,
  threeYearPlanAndWhichYearFromYear,
  defaultYear,
  numRound
} from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

/* import semantic-ui element */
import { Grid, Select, Form, Table, Segment } from 'semantic-ui-react';

class OneYearStrategy extends Component {
  constructor(props) {
    super(props);

    this.action = null;
    this.concernLookup = []; //index->id
    this.strategyLookup = {}; //id->index

    this.state = {

      year: [],
      yearInfo: [],
      yearId: '',

      teacherLookup: {},

      finishedLoading: false,

      threeYearPlans: [],
      selectedTYP: '',
      whichYear: 0,

      concerns: [],
      concernOptions: [],
      concernIndex: '',

      aims: [],

      strategies: [],
      selectedStrategy: null,

      actions: [],
      actionTeachers: {},
      aggregateInfo: {},
    }

    this.actionLookup = {};
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when mounting 1 year strategies');
      console.log(err);
    }
  }

  fetch = async () => {
    try{
      const [yearInfo, TYPs, teachers] = await Promise.all([
        get('getYear'),
        get('getTYP'),
        get('getAllTeacherWithAdminInSchool'),
      ]);

      const yearId = defaultYear(yearInfo);
      const [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs, yearId);

      await this.setStateAsync({
        yearInfo,
        year: selectOptions(yearInfo, 'displayName', 'id'),
        yearId,
        threeYearPlans: TYPs,
        selectedTYP,
        whichYear,
        teacherLookup: keyBy(teachers, 'id'),
      });
      
      await this.fetch3YearPlanDetails(true);
    }catch(err){
      console.log('Error when fetching 1 year strategies');
      console.log(err);
    }
  }

  fetch3YearPlanDetails = async (resetConcern = false) => {
    try{
      const concerns = await get(`get3YearConcern/${+this.state.selectedTYP}`);

      this.concernLookup = concerns.map(x=>x.id);

      await this.setStateAsync({
        concerns,
        concernIndex: resetConcern ? (concerns && concerns.length? 0 : '') : this.state.concernIndex,
        concernOptions: selectOptions(concerns, (c,i)=>{
          const wYear = +(this.state.whichYear);
          const subTopic = c["subTopic"+(1+wYear)];
          return `${i+1}. `+(subTopic?`${c.title} (${subTopic})`:c.title)
        }, (c,i)=>i),
      });

      const aims = await get('get3YearAim/'+this.concernLookup[this.state.concernIndex]);
      await this.setStateAsync({
        aims
      });

      const rawStrategies = await Promise.all(aims.map(aim => get(`get3YearStrategy/${+aim.id}`)));

      this.strategyLookup = {};
      rawStrategies.forEach((strategies, i)=>{
        Object.assign(this.strategyLookup, strategies.reduce((prev, s, j)=>({
          ...prev,
          [s.id]: j,
        }), {}));
      })

      const strategies = flatten(rawStrategies);

      const wYear = this.state.whichYear;

      await this.setStateAsync({
        strategies: Number.isInteger(wYear) ? strategies.filter(s => +s[`year${1+wYear}`]) : [],
      });

      const rawActions = await Promise.all(this.state.strategies.map(s => get(`get1YearAction/${this.state.yearId}/${+s.id}`)));
      const actions = flatten(rawActions);

      this.actionLookup = keyBy(actions, "3yearstrategyId");

      await this.setStateAsync({
        actions,
      });

      const rawActionTeachers = await Promise.all(actions.map(action => get(`getActionTeacher/${action.id}`)));

      await this.setStateAsync({
        actionTeachers: flatten(rawActionTeachers)
      });

      const aggregateInfo = await get(`get1yearSurveyResultAggregate/${this.state.yearId}`);

      await this.setStateAsync({ aggregateInfo: keyBy(aggregateInfo, 'Sid') });

    }catch(err){
      console.log('Error when fetching TYP detail for 1 year strategies');
      console.log(err);
    }
  };

  /* input update handler */
  inputChange = (inputType, stateName, callback = () => {}) => async (event, data) => {
    try{
      let value = inputHandler(inputType, data);
      await this.setStateAsync({ [stateName]: value });
      callback();
    }catch(err){
      console.log('Error when changing input for 1 year actions');
      console.log(err);
    }
  }

  /* year change */
  yearChange = async (event, { value }) => {
    try{
      const { yearId, yearInfo } = this.state;
      if (yearId !== value) {
        const [selectedTYP,whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, this.state.threeYearPlans, value);
        const TYPchanged = selectedTYP != this.state.selectedTYP;
        await this.setStateAsync({
          yearId: value,
          selectedTYP,
          whichYear,
          finishedLoading: false,
        });
        
        await this.fetch3YearPlanDetails(TYPchanged);
        await this.setStateAsync({ finishedLoading: true });
      }
    }catch(err){
      console.log("Error when changing year for One Year Action");
      console.log(err);
    }
  }

  /* Concern Change */
  concernChange = async (event, {value}) => {
    try {
      const { concernIndex } = this.state;
      if (concernIndex !== value) {
        await this.setStateAsync({
          concernIndex: value,
          finishedLoading: false,
        });

        await this.fetch3YearPlanDetails();
        await this.setStateAsync({ finishedLoading: true });
      }
    } catch (err) {
      console.log("Error when changing corcern for One Year Action");
      console.log(err);
    }
  }
  

  render() {
    const {
      finishedLoading,

      year,
      yearId,

      concernOptions,
      concernIndex,

      aims,
      strategies,

      actionTeachers,
      aggregateInfo,
      teacherLookup,
    } = this.state;

    const teachersGroupedbyAction = groupBy(actionTeachers, 'actionId');

    return (
      <Grid stackable doubling>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='周年計劃 - 策略項目問卷結果' subTitle='查看每個周年策略項目的平均問卷結果' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
          <Segment padded>
              <Form>
                <Form.Group>
                  <Form.Field inline>
                    <label>周年</label>
                    <Select compact disabled={!finishedLoading} options={year} value={yearId} onChange={this.yearChange} />
                  </Form.Field>
                  <Form.Field inline>
                    <label>關注事項</label>
                    <Select
                      compact
                      disabled={!finishedLoading || !concernOptions.length}
                      options={concernOptions}
                      value={concernIndex}
                      onChange={this.concernChange}
                      placeholder={concernOptions.length?"請選擇":"沒有三年計劃關注事項"}
                      error={!concernOptions.length}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Table textAlign='center' celled unstackable fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={5}>標題</Table.HeaderCell>
                  <Table.HeaderCell width={2}>時間表</Table.HeaderCell>
                  <Table.HeaderCell width={3}>負責人</Table.HeaderCell>
                  <Table.HeaderCell width={2}>所需資源</Table.HeaderCell>
                  <Table.HeaderCell width={2}>目前結果</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {finishedLoading ?
                  <>{aims.length === 0 ?
                    <EmptyTableMsg colSpan='5' msg='沒有策略目標' />
                    : (aims.map((aim, i) => {

                      const aimStrategies = filter(strategies, {aimId: aim.id})

                      return (
                        <Fragment key={aim.id}>
                          <EmptyTableMsg colSpan='5' msg={`${+concernIndex+1}.${+i+1}. ${aim.title}`} />
                          {aimStrategies.length ? aimStrategies.map((s, j) => {
                            const action = this.actionLookup[s.id];
                            let teachers = [];
                            if(action){
                              teachers = teachersGroupedbyAction[action.id] || [];
                              const aggregate = aggregateInfo[s.id] ? numRound(aggregateInfo[s.id].x, 2) : undefined;
                              return (
                                <Table.Row key={s.id}>
                                  <Table.Cell textAlign="left">{+concernIndex+1}.{+i+1}.{+this.strategyLookup[s.id]+1}. {s.title}</Table.Cell>
                                  <Table.Cell>{action.beginMonth||'?'}月—{action.endMonth||'?'}月</Table.Cell>
                                  <Table.Cell>
                                  {
                                  teachers && teachers.length ? teachers.map(x => ((x.id && teacherLookup[x.teacherId]) ? teacherLookup[x.teacherId].shortName || teacherLookup[x.teacherId].name : "?")).join("") : "-"
                                  }
                                  </Table.Cell>
                                  <Table.Cell>{action.resourceNeeded || "-"}</Table.Cell>
                                  <Table.Cell><AggregateNumber value={aggregate}/></Table.Cell>
                                </Table.Row>
                              )
                            }else{
                              return (
                                <Table.Row key={s.id}>
                                  <Table.Cell textAlign="left">{+concernIndex+1}.{+i+1}.{+this.strategyLookup[s.id]+1}. {s.title}</Table.Cell>
                                  <Table.Cell colSpan='4'><strong>未制定周年策略項目</strong></Table.Cell>
                                </Table.Row>
                              )
                            }
                          }): <EmptyTableMsg colSpan='5' msg="未有策略大綱" />}
                        </Fragment>
                      )
                    }))
                  }</>
                  : <EmptyTableMsg colSpan='5' msg='載入資料中'/>
                }
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(OneYearStrategy);