/* import react */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

/* import components */
import {
	FullscreenDimmer,
	PageHeader,
	EmptyTableMsg,
  Buttons
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { genAttendanceString } from '../../../Helper/Helper';
import { displayDate } from '../../../Helper/TimeHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { exportExcel, convertToColumnTitle } from '../../../Helper/ExcelHelper';
import {genLocationString, getFridayActivityPlanInfo } from './FridayHelpers';

import genPlan from '../../../Layout/ECA/fridayActivityPlan';
import genSpecialReport from '../../../Layout/ECA/specialFridayActivityReport';

import {activityReportGradeOptions as gradeOptions} from '../../../Const/Const';

/* import lodash */
import { find, get as _get, keyBy } from 'lodash';
import {fromBlankAsync} from 'xlsx-populate';

/* import semantic-ui element */
import { Grid, Tab, Header, Segment, Label, Table, Modal, Form } from 'semantic-ui-react';

class FridayActivityDetail extends Component {
  constructor(props) {
    super(props);

    let search = this.props.location.search.match(/\d+$/);
    if(search){
      search = +search[0];
      if(search>2)search=2;
      if(search<0)search=0;
    }else{
      search=0;
    }
    
    this.attendanceTableHeader = [
      { headerName: '日期', width: 2},
      { headerName: '出席', width: 2},
      { headerName: '缺席或其他', width: 2},
      { headerName: '備註', width: 8},
      { headerName: '點名', width: 2},
    ];

    this.reportTableHeader = [
      { headerName: '日期', width: 2},
      { headerName: '學習內容及學習活動', width: 6},
      { headerName: '學生學習興趣評估', width: 5},
      { headerName: '編輯', width: 3},
    ];

    this.state = {
      dimmerOpen: true,
      loaderText: '',
      activeIndex: search !==null ? search : 0,
      section: this.props.match.params.section || null,
      type: this.props.match.params.type || null,
      yearId: this.props.match.params.yearId || null,
      fridayActivityId: this.props.match.params.fridayActivityId || null,
      fridayActivity: null,
      fridayActivityTeachers: [],
      fridayActivityClasses: [],
      fridayActivityAttendances: [],
      fridayActivityReports: [],
      yearName: '-',
      attendanceStatusMap: {},

      isSuggestionModalOpen: false,
      editSuggestion: ''
    }
  }
  
  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
    this.setStateAsync({ dimmerOpen: false });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  setStateAsync = (state, errMsg = 'error') => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    }).catch(err=>{console.log(errMsg);console.log(err);})
  )

  fetchData = async () => {
    const {fridayActivityId} = this.state;
    const type = this.state.type === 'special' ? 'Special' : 'Normal';

    try{
      const [[fridayActivity, fridayActivityTeachers], fridayActivityClasses, fridayActivityAttendances, fridayActivityReports, fridayActivityStudents, year, rawAttendanceStatus] = await Promise.all([
        getFridayActivityPlanInfo(fridayActivityId),
        get(`get${type}FridayActivityClass/${fridayActivityId}`),
        get(`getAll${type}FridayActivityAttendance/${fridayActivityId}`),
        get(`get${type}FridayActivityReport/${fridayActivityId}`),
        // problems
        get(type==='Special'?`getSpecialFridayActivityStudent/${fridayActivityId}`:`getNormalFridayActivityStudent/${this.props.match.params.yearId}/${fridayActivityId}`),
        get('getOneYear/'+this.state.yearId),
        get('getAttendanceStatus')
      ]);
      await this.setStateAsync({
        fridayActivity: fridayActivity[0] || {},
        fridayActivityTeachers,
        fridayActivityClasses,
        fridayActivityAttendances,
        fridayActivityReports,
        fridayActivityStudents,
        type: fridayActivity[0]?fridayActivity[0].type:'special',
        yearName: year[0]?year[0].displayName:"-",
        attendanceStatusMap: rawAttendanceStatus.reduce((prev,cur)=>({...prev,[cur.id]: cur.name}),{})
      });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching data for FA", err)};
  }

  panes = () => {
    return [
      { menuItem: '活動詳情', render:this.displayInfo},
      { menuItem: '點名', render:this.displayAttendance},
      { menuItem: '學習紀錄', render:this.displayReport},
    ]
  }

  exportFridayActivity = async () => {
    try {
      await this.setStateAsync({
        dimmerOpen: true,
        loaderText: '從資料庫中獲取資料中'
      });
      const [activityInfo, activityTeacherInfo] = await getFridayActivityPlanInfo(this.state.fridayActivityId);
      if(!activityInfo.length){
        throw('找不到項目');
      }
      const activity = activityInfo[0];
      activity.teachers = activityTeacherInfo;
      this.setStateAsync({ loaderText: '將資料轉換成PDF中'});
      await new Promise((res,rej)=>{genPlan(activity,res)});
      
      await this.setStateAsync({ dimmerOpen: false, });
    } catch (err) {
      await this.setStateAsync({ loaderText: typeof err === 'string'? err : '發生錯誤' });
      setTimeout(
        () => { this.setStateAsync({ dimmerOpen: false, }) }
      , 2000)
    }
  }

  displayInfo = () => {
    const {
      fridayActivity,
      fridayActivityTeachers
    } = this.state;

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top'>
          <Link to={'/eca/fridayActivity/' + this.state.section + '/edit/' + this.state.yearId + '/' + this.state.fridayActivityId}><Buttons.EditButton content='編輯週五興趣小組' /></Link>
          <Buttons.DownloadButton color='green' content='匯出週五興趣小組計劃表' onClick={this.exportFridayActivity} />
        </Segment>
        <Segment vertical className='segment-padding'>
          <Header as='h3'><u>基本資料</u></Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={4}><b>類型:</b></Grid.Column>
              <Grid.Column width={12}>{fridayActivity.type === 'special' ? '專項' : '普通'}小組第 {fridayActivity.code || '-'} 組</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>活動名稱:</b></Grid.Column>
              <Grid.Column width={12}>{fridayActivity.name || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>負責老師:</b></Grid.Column>
              <Grid.Column width={12}>
                {fridayActivityTeachers.length > 0 ?
                  <Label.Group size='large'>
                    {fridayActivityTeachers.map(({ teacherId, name }) => (
                      <Label key={teacherId}>{name || '外聘導師'}</Label>
                    ))}
                  </Label.Group>
                  :
                  '-'
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>地點:</b></Grid.Column>
              <Grid.Column width={12}>{genLocationString(fridayActivity)}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>備註欄:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{fridayActivity.remark || '-'}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment vertical className='segment-padding'>
          <Header as='h3'><u>計劃表</u></Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={4}><b>所需器材:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{fridayActivity.equipment || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>學習材料:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{fridayActivity.material || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>財政預算:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{fridayActivity.budget || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>配合之關注事項:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{fridayActivity.item || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>教學目標:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{fridayActivity.target || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>教學計劃:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{fridayActivity.plan || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>預計成效:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{fridayActivity.outcome || '-'}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}><b>評估方法:</b></Grid.Column>
              <Grid.Column width={12} className='textarea-text'>{fridayActivity.method || '-'}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Tab.Pane>
    )
  }

  downloadAttendance = async () => {
    const students = this.state.fridayActivityStudents;
    const classes = this.state.fridayActivityClasses;
    const attendance = this.state.fridayActivityAttendances;

    const {fridayActivity, attendanceStatusMap} = this.state;
    
    const workbook = await fromBlankAsync();
    const sheet = workbook.sheet(0);

    const dates = classes.map(x=>displayDate(x.date));

    const attendanceLookup = {};

    for(let item of attendance){
      if(attendanceLookup[item.specialFridayActivityClassId]){
        attendanceLookup[item.specialFridayActivityClassId][item.specialFridayActivityStudentId] = genAttendanceString(item, attendanceStatusMap);
      }else{
        attendanceLookup[item.specialFridayActivityClassId] = {[item.specialFridayActivityStudentId]: genAttendanceString(item, attendanceStatusMap)}
      }
    }

    sheet.cell('A3').value("學生");
    sheet.cell('B3').value([dates.concat(Array.from({length: 25 - dates.length}))]);
    sheet.cell('A4').value(students.map(x=>([`${x.classCode} ${x.classNo>9?x.classNo:" "+x.classNo} ${x.chiName||x.engName}`])));

    classes.forEach((classObj, j)=>{
      const colTitle = convertToColumnTitle(j+2);
      students.forEach((student,i)=>{
        if(attendanceLookup[classObj.id] && attendanceLookup[classObj.id][student.id]){
          sheet.cell(`${colTitle}${i+4}`).value(attendanceLookup[classObj.id][student.id]);
        }
      });
      sheet.cell(`${colTitle}${students.length+4}`).value(classObj.remark||'');
    });

    sheet.cell(`A${students.length+4}`).value('備註');

    sheet.usedRange().style({ border: { left: true, right: true, top: true, bottom: true }, fontSize: 12 });

    sheet.range(1,1,2,sheet.usedRange().endCell().columnNumber()).merged(true).startCell().value(`${process.env.REACT_APP_SCHOOL_NAME} ${this.state.yearName}年度週五興趣小組點名表 - ${fridayActivity?fridayActivity.name:"-"} (專項${fridayActivity?fridayActivity.code:'-'})`);
    sheet.range('A1:A2').style({bold: true, fontSize: 16});
    sheet.row(1).height(21);
    sheet.row(2).height(21);

    sheet.usedRange().style({ fontFamily: "標楷體", horizontalAlignment: 'center', verticalAlignment: 'center', wrapText: true});
    for(let i=2;i<=sheet.usedRange().endCell().columnNumber();i++){
      sheet.column(i).width(11);
    }
    sheet.column("A").width(21).style({horizontalAlignment: 'left'});
    
    sheet.range('A1:B1').style({ horizontalAlignment: 'center' });
    
    const file = await workbook.outputAsync();
    exportExcel(file, `週五興趣小組點名表 - ${fridayActivity.name} (專項${fridayActivity?fridayActivity.code:'-'})`);
  }

  displayAttendance = () => {
    const {
      fridayActivityClasses,
      fridayActivityAttendances,
      type
    } = this.state;

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top'>
          <Grid>
            <Grid.Row columns="1">
              <Grid.Column>
                {type==='special'?<Buttons.DownloadButton content='匯出點名表' onClick={this.downloadAttendance} />:"普通小組的點名表可在「普通小組管理」中下載"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Table textAlign='center' padded selectable celled stackable fixed>
                  <Table.Header>
                    <Table.Row>
                      {this.attendanceTableHeader.map(({headerName, width}) => (
                        <Table.HeaderCell key={headerName} width={width}>{headerName}</Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {fridayActivityClasses.length > 0 ? fridayActivityClasses.map(({ id, date, remark }) => {
                      const filteredAttendances = fridayActivityAttendances.filter(x => x.fridayActivityClassId === id);
                      let present = 0, absent = 0;
                      filteredAttendances.forEach(x => {
                        if (x.attendanceStatusId === 1) present++;
                        else absent++;
                      });
                      return (
                        <Table.Row key={id}>
                          <Table.Cell>{displayDate(date)}</Table.Cell>
                          <Table.Cell>{filteredAttendances.length ? present : '-'}</Table.Cell>
                          <Table.Cell>{filteredAttendances.length ? absent : '-'}</Table.Cell>
                          <Table.Cell>{remark}</Table.Cell>
                          <Table.Cell><Link to={'/eca/fridayActivity/' + this.state.section + '/attendance/' + this.state.type + '/' + this.state.yearId + '/' + this.state.fridayActivityId + '/' + id}><Buttons.EditButton/></Link></Table.Cell>
                        </Table.Row>
                      );
                    }) : <EmptyTableMsg colSpan={this.attendanceTableHeader.length} />}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Tab.Pane>
    )
  }

  downloadSpecialReport = async () => {
    const year = await get('getOneYear/'+this.props.match.params.yearId);
    const {fridayActivity, fridayActivityTeachers} = this.state;
    const fridayActivityReports = keyBy(this.state.fridayActivityReports, 'specialFridayActivityClassId');

    const fridayActivityClasses = this.state.fridayActivityClasses.map(x=>{
      let theClass = fridayActivityReports[x.id] || {};
      return {
        date: displayDate(x.date),
        content: theClass.content,
        mark: theClass.mark,
        remark: theClass.remark
      };
    });

    genSpecialReport({
      yearName: year && year[0].displayName,
      suggestion: fridayActivity && fridayActivity.suggestion,
      fridayActivityTitle: fridayActivity && fridayActivity.name,
      fridayActivityCode: fridayActivity && fridayActivity.code,
      fridayActivityTeachers: fridayActivityTeachers && fridayActivityTeachers.map(x=>x&&x.name),
      fridayActivityClasses
    })
  }

  displayReport = () => {
    const {
      type,
      fridayActivity,
      fridayActivityClasses,
      fridayActivityReports,
      isSuggestionModalOpen,
      editSuggestion
    } = this.state;

    return (
      <Tab.Pane className='no-padding-top'>
        <Segment vertical className='no-margin-top'>
          <Grid>
            <Grid.Row columns="1">
              <Grid.Column>
                {type==='special'?<Buttons.DownloadButton content='匯出專項小組評估表' onClick={this.downloadSpecialReport} />:"普通小組的學習紀錄表可在「普通小組管理」中下載"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Table textAlign='center' padded selectable celled stackable>
                  <Table.Header>
                    <Table.Row>
                      {this.reportTableHeader.map(({headerName, width}) => (
                        <Table.HeaderCell key={headerName} width={width}>{headerName}</Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {fridayActivityClasses.length > 0 ?
                      <>
                        {fridayActivityClasses.map(({ id, date }) => {
                          const report = type === 'special' ?
                            find(fridayActivityReports, { specialFridayActivityClassId: id }) :
                            find(fridayActivityReports, { normalFridayActivityClassId: id });
                          return (
                            <Table.Row key={id}>
                              <Table.Cell>{displayDate(date)}</Table.Cell>
                              <Table.Cell>
                                {report ? report.content : '-'}
                              </Table.Cell>
                              <Table.Cell>
                                {report && gradeOptions[report.mark] !== undefined ? (report.mark ? gradeOptions[report.mark] : report.remark) : '-'}
                              </Table.Cell>
                              <Table.Cell>
                                <Link to={'/eca/fridayActivity/' + this.state.section + '/report/' + this.state.type + '/' + this.state.yearId + '/' + this.state.fridayActivityId + '/' + id}><Buttons.EditButton/></Link>
                              </Table.Cell>
                            </Table.Row>
                          )
                        })}
                      </>
                      :
                      <EmptyTableMsg colSpan={this.reportTableHeader.length} />
                    }
                    <Table.Row>
                      <Table.Cell><b>檢討及建議</b></Table.Cell>
                      <Table.Cell colSpan={2} className='textarea-text text-left'>{fridayActivity.suggestion}</Table.Cell>
                      <Table.Cell>
                        <Buttons.EditButton onClick={this.suggestionModalToggle} />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Modal open={isSuggestionModalOpen} onClose={this.suggestionModalToggle} closeOnEscape={false} closeOnDimmerClick={false}>
          <Modal.Header>編輯檢討及建議</Modal.Header>
          <Modal.Content>
            <Segment basic>
              <Form>
                <Form.Group className='form-group-margin' grouped>
                  <Form.TextArea value={editSuggestion} onChange={this.suggestionChange} label='檢討及建議' placeholder='檢討及建議' />
                </Form.Group>
              </Form>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Buttons.CancelButton onClick={this.suggestionModalToggle} />
            <Buttons.SaveButton onClick={this.saveSuggestion} />
          </Modal.Actions>
        </Modal>
      </Tab.Pane>
    )
  }

  suggestionModalToggle = () => {
    this.setStateAsync({
      isSuggestionModalOpen: !this.state.isSuggestionModalOpen,
      editSuggestion: this.state.fridayActivity.suggestion || ''
    });
  }

  suggestionChange = (event, data) => {
    let value = inputHandler('text', data);
    this.setStateAsync({ editSuggestion: value })
  }

  handleTabChange = (e, { activeIndex }) => this.setStateAsync({ activeIndex })

  saveSuggestion = async () => {
    const {fridayActivityId} = this.props.match.params;
    const suggestion = this.state.editSuggestion;
    try{
      await this.setStateAsync({ dimmerOpen: true });
      const result = await post('editFridayActivitySuggestion', {id: fridayActivityId, suggestion}); // can build another handle just to save it.
      if (_get(result, 'status')) {
        await this.setStateAsync({
          fridayActivity: {
            ...this.state.fridayActivity,
            suggestion
          },
          isSuggestionModalOpen: false
        });
      } else {
        throw result;
      }
    }catch(err){
      alert(`儲存${this.state.fridayActivity?this.state.fridayActivity.name:'週五興趣小組'}學習紀錄時發生錯誤`);
      if(err!=='unmounted'){
        console.log("Error when saving suggestion", err)
        this.setStateAsync({ dimmerOpen: false });
      }
    };
  }

  render() {
    const {
      dimmerOpen,
      loaderText,
      activeIndex,
      fridayActivity,
    } = this.state;

    return (
      <>
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title={`週五興趣小組詳情 - ${(fridayActivity && fridayActivity.name) || ''}`} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              {fridayActivity?(
                <Tab
                  panes={this.panes()}
                  activeIndex={activeIndex}
                  onTabChange={this.handleTabChange}
                />
              ):<Header as='h2' textAlign="center">{fridayActivity===null?"找不到週五興趣小組":"載入資料中"}</Header>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <FullscreenDimmer active={dimmerOpen} isLoading={true} loaderText={loaderText} />
      </>
    )
  }
}

export default withRouter(FridayActivityDetail);