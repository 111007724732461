import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal, Segment, Table } from 'semantic-ui-react';
import FullscreenDimmer from '../../../../Components/FullscreenDimmer';
import { map } from 'lodash';
import { post } from '../../../../Helper/ApiHelper';

const FormOrderModal = forwardRef(({ fetch }, ref) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [contents, setContents] = useState([]);

	useImperativeHandle(ref, () => ({
		open: (content) => {
			setContents(content);
			setOpen(true);
		},
	}));

	const up = (index) => {
		setContents((contents) => [
			...contents.slice(0, index - 1),
			contents[index],
			contents[index - 1],
			...contents.slice(index + 1),
		]);
	};

	const down = (index) => {
		setContents((contents) => [
			...contents.slice(0, index),
			contents[index + 1],
			contents[index],
			...contents.slice(index + 2),
		]);
	};

	const saveOrder = async () => {
		const data = {
			appraisalContent: map(contents, ({ id }, index) => ({
				id,
				order: index + 1,
			})),
		};

		setLoading(true);
		const result = await post('editAppraisalContentOrder', data);
		if (result.status) {
			await fetch();
			setLoading(false);
			setOpen(false);
		}
	};

	return (
		<React.Fragment>
			<FullscreenDimmer active={loading} isLoading />
			<Modal open={open} onClose={() => setOpen(false)} closeOnEscape={false} closeOnDimmerClick={false}>
				<Modal.Header>編輯子項目排序</Modal.Header>
				<Modal.Content>
					<Segment basic>
						<Table>
							<Table.Body>
								{contents.map(({ description }, index) => (
									<Table.Row key={index}>
										<Table.Cell>
											<b>{description}</b>
										</Table.Cell>
										<Table.Cell collapsing textAlign={index !== 0 ? 'left' : 'right'}>
											{index !== 0 && <Button color="blue" icon="caret up" onClick={() => up(index)} circular />}
											{index !== contents.length - 1 && (
												<Button color="brown" icon="caret down" onClick={() => down(index)} circular />
											)}
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" content="取消" icon="cancel" onClose={() => setLoading(false)} circular />
					<Button color="green" content="儲存" icon="save" onClick={saveOrder} circular />
				</Modal.Actions>
			</Modal>
		</React.Fragment>
	);
});

export default FormOrderModal;
