/* import react */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import {groupBy, mapValues, find} from 'lodash';

/* import components */
import {
	PageHeader,
  MySortableTable
} from '../../../../Components';

/* import helper functions */
import { get } from '../../../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Segment, Form, Button, Select } from 'semantic-ui-react';

import { genSimpleExcel } from '../../../../Helper/ExcelHelper';

class FridayActivityNormalGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedLoading: false,
      
      yearOptions: [],
      locationLookup: {},
      yearId: '',
      groups: null,
    }
    this.addCount = 0;

    this.tableColumnData = [
      {headerName: '編號', width: 3, cellRender: x=>x.code||'-', allowSort: true, sortMethod: 'code' },
      {headerName: '地點', width: 6, cellRender: x=>this.state.locationLookup[x.locationId]||'-', allowSort: true, sortMethod: 'locationId' },
      {headerName: '學生人數', width: 4, cellRender: x=>x.studentCount||'-', allowSort: true},
      {headerName: '行動', width: 3, cellClassName: 'textlessCell', cellRender: x=>(
        <Link to = {`/eca/fridayActivity/normalgroup/detail/${+x.id}`}>
          <Button
            color="blue"
            circular
            icon="eye"
          />
        </Link>
      ) },
    ];
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.fetchNormalGroups();
      this.setStateAsync({ finishedLoading: true });
    }catch(err){}
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try{
      const [years, locations] = await Promise.all([
        get('getYear'),
        get('getLocation'),
      ]);
      
      await this.setStateAsync({
        yearOptions: selectOptions(years, 'displayName', 'id'),
        yearId: defaultYear(years),
        locationLookup: locations.reduce((prev,cur)=>({...prev, [cur.id]: cur.name}),{}),
      });

    }catch(err){if(err!=='unmounted')console.log("Error when fetching data for Normal Groups", err)}
  }

  fetchNormalGroups = async () => {
    try{
      const [groups, students] = await Promise.all([
        get('getAllFridayActivityNormalGroup/' + this.state.yearId),
        get('getAllNormalFridayActivityStudent/' + this.state.yearId)
      ]);
      const studentCount = mapValues(groupBy(students, 'normalFridayActivityGroupId'), 'length');
      for(let group of groups){
        group.studentCount = studentCount[group.id];
      }
      await this.setStateAsync({
        groups,
      });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching groups for Normal Groups", err)}
  }

  yearChange = async (event, { value }) => {
    try{
      await this.setStateAsync({ finishedLoading: false, yearId: value });
      await this.fetchNormalGroups();
    }catch(err){if(err!=='unmounted')console.log("Error when changing year for FASummary", err);}
    this.setStateAsync({ finishedLoading: true });
  }

  toEdit = () => {
    this.props.history.push('/eca/fridayActivity/normalgroupedit/'+(+this.state.yearId));
  }
  
  downloadNormalFridayGroupList = async () => {

    const year = find(this.state.yearOptions, { value: this.state.yearId });
    const { groups } = this.state;
    
    genSimpleExcel(groups, `週五學生普通小組總表 - ${year?year.text:''}`, [
      {width: 18, headerTitle: '普通小組', render: 'code'},
      {width: 24, headerTitle: '上課地點', render: x=>this.state.locationLookup[x.locationId]||'-'},
      {width: 18, headerTitle: '學生人數', render: x=>x.studentCount||'-'},
    ]);
    
  }

  render() {
    const {
      yearOptions,
      yearId,
      groups,
      finishedLoading
    } = this.state;

    return (
      <>
        <Form>
          <Grid stackable doubling>
            <Grid.Row>
              <Grid.Column>
                <PageHeader title='學生普通小組基本資料' />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Segment padded>
                  <Form.Field inline>
                    <label>周年</label>
                    <Select
                      compact
                      disabled={!finishedLoading}
                      options={yearOptions}
                      value={yearId}
                      onChange={this.yearChange}
                    />
                    <Button
                      content="編輯普通小組"
                      color="green"
                      icon="pencil"
                      onClick={this.toEdit}
                      circular
                      floated="right"
                    />
                    <Button
                      color='blue'
                      className='button-margin-bottom'
                      content='學生普通小組總表'
                      icon='download'
                      disabled={!finishedLoading}
                      onClick={this.downloadNormalFridayGroupList}
                      floated="right"
                      circular
                    />
                  </Form.Field>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Segment.Group className='form-group-margin'>
                  <Segment secondary>
                    <MySortableTable
                      data={groups}
                      tableColumnData={this.tableColumnData}
                      finishedLoading={finishedLoading}
                    />
                  </Segment>
                </Segment.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </>
    )
  }
}

export default withRouter(FridayActivityNormalGroup);