/**
 * To be used in conjunction with FileInputInstantUpload
 * @param {File} xlsFile 
 * @returns {any} data retrieved from the first sheet
 */
export const fileHandling = async (xlsFile, type = 'binary') => {
  //file parse
  const reader = new FileReader();
  try{
    const XLSX = await import('xlsx');
    return new Promise((res,rej)=>{
      reader.onload = (evt) => { //evt = on_file_select event
        /* Parse data */
        const binaryString = evt.target.result;
        const workBook = XLSX.read(binaryString, { type });
        /* Get first worksheet, object {[cellAddress]: value} */
        const wsname = workBook.SheetNames[0];
        const data = workBook.Sheets[wsname];
        res(data);
      };
      reader.onerror = (evt) => {
        console.log('err', evt);
        rej('讀取檔案時發生錯誤');
      }
      reader.readAsBinaryString(xlsFile);
    });
  }catch(err){
    return {status: false, err}
  }
}