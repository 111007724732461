import { get } from '../../../Helper/ApiHelper';

/**
 * Get One Friday Activity Plan Info with Teachers 
 * @param {number} id
 * @returns {Promise<[[Object],Object[]]>} promise resolving into [activityDetail, activityTeachers]
 */
export const getFridayActivityPlanInfo = (id) => Promise.all([
  get(`getOneFridayActivityWithDetails/${+id}`),
  get(`getFridayActivityTeacher/${+id}`),
]);

export const genLocationString = activity => activity ? [activity.locationName, activity.secondaryLocationName ? "*" + activity.secondaryLocationName :''].filter(x=>x).join(' / ') || '-' : '-';