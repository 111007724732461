import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
	PageHeader,
	FullscreenDimmer,
	BlockerPrompt,
	ErrorLabel,
	MySelect,
	MyTable,
  Buttons
} from '../../../Components';

import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { selectOptions } from '../../../Helper/Helper';
import { momentToDate, momentToDbFormat, now, dateToMoment } from '../../../Helper/TimeHelper';

import { findIndex } from 'lodash';

import { DateInput } from 'semantic-ui-calendar-react';
import SimpleReactValidator from 'simple-react-validator';
import { Grid, Segment, Form, TextArea } from 'semantic-ui-react';

class ActivityAttendance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimmerOpen: false,
      finishedLoading: false,
      isBlocking: false,
      activity: {},
      activityClass: {
        activityId: this.props.match.params.activityId || null,
        date: now()
      },
      activityAttendances: [],
      attendanceStatusOptions: [],
    }

    this.stackable = {stackable: true};
    this.newCount = -1;

    this.tableColumnData = [
      { headerName: '班別', width: 2, cellRender: 'classCode', cellClassName: x => !x.status ? "line-through" : undefined },
      { headerName: '姓名', width: 5, cellRender: 'name', cellClassName: x => !x.status ? "line-through" : undefined },
      { headerName: '學號', width: 1, cellRender: 'classNo', cellClassName: x => !x.status ? "line-through" : undefined },
      {
        headerName: '狀態', width: 6, cellClassName: 'table-cell-with-dropdown', cellRender: x => (
        <Form.Group widths='equal' className='form-group-no-margin'>
          <Form.Field
            control={MySelect}
            fluid
            disabled={!x.status}
            data-itemid={x.activityStudentId}
            data-inputtype='select'
            data-propname='attendanceStatusId'
            onChange={this.attendanceChange}
            value={x.attendanceStatusId || ''}
            options={this.state.attendanceStatusOptions}
            placeholder='狀態'
          />
          {x.attendanceStatusId === 5 &&
            <Form.Input
              type='text'
              fluid
              disabled={!x.status}
              data-itemid={x.activityStudentId}
              data-inputtype='text'
              data-propname='remark'
              onChange={this.attendanceChange}
              value={x.remark || ''}
              placeholder='請註明'
            />
          }
        </Form.Group>
      )},
    ];

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => <ErrorLabel message={message} />,
      messages: {
        default: '請輸入資料'
      }
    });
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetchData = async () => {
    const {activityId, activityClassId} = this.props.match.params;
    try{
      const [activity, activityStudents, attendanceStatus] = await Promise.all([
        get('getOneActivity/' + activityId),
        get('getActivityStudent/' + activityId),
        get('getAttendanceStatus')
      ]);

      await this.setStateAsync({
        activity: activity[0] || {},
        attendanceStatusOptions: selectOptions(attendanceStatus, 'name', 'id')
      });

      let [activityClass, activityAttendances] = activityClassId ? await Promise.all(['getOneActivityClass', 'getOneClassActivityAttendance'].map(x=>get(x, [activityClassId]))) : [[],[]];

      for(let aS of activityAttendances) aS.name = aS.chiName || aS.engName;

      const hasAttendance = !!activityAttendances.length;

      const hasAttendanceSet = new Set(activityAttendances.map(x=>x.activityStudentId));
      // if already has attendance data, all other students without attendance data are deemed "NEW" and they shouldn't be added
      if(Array.isArray(activityClass) && !hasAttendance){
        for(let aS of activityStudents){
          if(!hasAttendanceSet.has(aS.id)){
            activityAttendances.push({
              id: this.newCount--, //allocate unique numeric Id
              name: aS.chiName || aS.engName,
              classCode: aS.classCode,
              classNo: aS.classNo,
              status: !!aS.status,
              activityClassId,
              activityStudentId: aS.id,
              attendanceStatusId: !!aS.status && 1,
              remark: null,
            });
          }
        }
      }
      await this.setStateAsync({
        activityStudents,
        activityClass: activityClass[0] ? {
          ...activityClass[0],
          date: dateToMoment(activityClass[0]['date'])
        } : {},
        activityAttendances
      });
    }catch(err){if(err!=='unmounted')console.log("Error when fetching activity data", err)}
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
    this.setStateAsync({ finishedLoading: true });
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  dateChange = (event, data) => {
    let value = inputHandler('date', data);
    this.setStateAsync({
      isBlocking: true,
      activityClass: {
        ...this.state.activityClass,
        date: value
      }
    });
  }

  remarkChange = (event, data) => {
    this.setStateAsync({
      isBlocking: true,
      activityClass: {
        ...this.state.activityClass,
        remark: inputHandler('text', data)
      }
    });
  }

  attendanceChange = (event, data) => {
    const {propname, itemid, inputtype} = event.target.closest('.ui,textarea').dataset;
    let activityAttendances = [...this.state.activityAttendances];
    const index = findIndex(activityAttendances, { activityStudentId: +itemid });
    if(index>-1){
      
      const value = inputHandler(inputtype, data);
      
      activityAttendances[index] = {
        ...activityAttendances[index],
        [propname]: value,
      }

      if(propname === 'attendanceStatusId' && value !== 5){
        activityAttendances[index].remark = null;
      }
      this.setStateAsync({
        isBlocking: true,
        activityAttendances
      });
    }
  }

  return = () => {
    this.props.history.push('/eca/activity/' + this.props.match.params.section + '/detail/' + this.props.match.params.yearId + '/' + this.props.match.params.activityId);
  }

  save = async () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }

    try{
      let data = {
        activityClassId: this.props.match.params.activityClassId,
        activityClass: {
          ...this.state.activityClass,
          activityId: this.props.match.params.activityId,
          date: momentToDbFormat(this.state.activityClass.date)
        },
        activityAttendances: this.state.activityAttendances.filter(x=>x.attendanceStatusId).map(({ id, activityStudentId, attendanceStatusId, remark }) => ({
          id: +id>0?+id:undefined,
          activityStudentId,
          attendanceStatusId,
          remark
        }))
      };

      await this.setStateAsync({ dimmerOpen: true, finishedLoading: false });
      const result = await post('editActivityClass', data);
      if (result && result.status) {
        await this.setStateAsync({ isBlocking: false });
        this.return();
      }else{
        throw result;
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存課外活動課堂記錄時發生錯誤");
        this.setStateAsync({ dimmerOpen: false, finishedLoading: true });
        console.log("Error when saving activity", err);
      }
    }
  }

  render() {
    const {
      dimmerOpen,
      finishedLoading,
      activity,
      activityClass,
      activityAttendances,
      isBlocking
    } = this.state;

    return (
      <>
        {dimmerOpen && <FullscreenDimmer active={dimmerOpen} isLoading={true} />}
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='編輯課外活動課堂記錄' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form>
                <Segment.Group className='form-group-margin'>
                  <Segment className='bold' size='big'>{activity.name || ''}</Segment>
                  <Segment secondary className='form-padding'>
                    <Form.Field className='form-group-margin'>
                      <label>課外活動日期</label>
                      <DateInput
                        onChange={this.dateChange}
                        disabled={!finishedLoading||!!this.props.match.params.activityClassId}
                        value={momentToDate(activityClass.date) || null}
                        placeholder='日期'
                        className='more-opaque-date'
                      />
                      {this.validator.message('date', activityClass.date, 'required')}
                    </Form.Field>
                  </Segment>
                  <Segment secondary>
                    <MyTable
                      tableColumnData={this.tableColumnData}
                      data={activityAttendances}
                      tableProps={this.stackable}
                      finishedLoading={finishedLoading}
                    />
                  </Segment>
                  <Segment secondary>
                    <Form.Field>
                      <label>備註</label>
                      <TextArea
                        onChange={this.remarkChange}
                        disabled={!finishedLoading}
                        value={activityClass.remark||''}
                        placeholder='點名用備註'
                      />
                    </Form.Field>
                  </Segment>
                </Segment.Group>

                <div className='text-center'>
                  <Buttons.CancelButton onClick={this.return} />
                  <Buttons.SaveButton onClick={this.save} />
                </div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    )
  }
}

export default withRouter(ActivityAttendance);