import React, { Component } from 'react';
import moment from 'moment';
import {findIndex} from 'lodash';
import {Form, Input, Grid} from 'semantic-ui-react';
import { withRouter }  from 'react-router-dom';

import { get, post } from '../../../Helper/ApiHelper';
import { selectOptions } from '../../../Helper/Helper';
import { inputHandler } from '../../../Helper/FormHelper';

import {
  PageHeader,
  FullscreenDimmer,
  BlockerPrompt,
  MyTable,
  MySelect,
  Buttons
} from '../../../Components';

import '../../../Styles/OutsideActivityStudentAttendance.css';

class OutsideActivityStudents extends Component{
  constructor(props){
    super(props);
    this.state = {
      dimmerOpen: false,
      finishedLoading: false,
      isBlocking: false,
      attendanceOptions: [],
      activity: null,
      students: [],
      canEdit: false,
    }

    this.tableProps = {structured: true, compact: true};

    this.tableColumnData = [];
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )
  
  componentDidMount = async () => {
    this.mounted = true;
    await this.fetchData();
    this.setStateAsync({finishedLoading: true});
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  fetchData = async () => {
    const id = this.props.match.params.outsideActivityId;
    try{
      const [activity, students, attendances] = await Promise.all([
        get('getOneOutsideActivityWithDetails/' + id),
        get('getOutsideActivityStudent/' + id),
        get('getAttendanceStatus')
      ]);
      if(findIndex(attendances, {name: '出席'})>=0){
        for(let student of students){
          if(!student.attendanceStatus){
            student.attendanceStatus = '出席';
          }
        }
      }
      await this.setStateAsync({
        activity: activity[0],
        students,
        attendanceOptions: selectOptions(attendances, 'name', 'name')
      });
      await this.setStateAsync({
        canEdit: this.state.activity?moment().isBefore(moment(this.state.activity.date).add(1, 'y')):false
      });

      this.tableColumnData = [
        {
          headerName: '學生',
          width: 4,
          cellRender: (item) => <>{item.chiName||item.engName}{item.chiName?' ':<br/>}({item.classCode} {item.classNo})</>
        },
        {
          headerName: '點名',
          width: 5,
          cellRender: (item)=>{
            return (this.state.canEdit?(
              <Form.Group widths='equal' className='form-group-no-margin'>
                <Form.Field
                  control={MySelect}
                  fluid
                  options={this.state.attendanceOptions}
                  value={item.attendanceStatus||''}
                  data-studentid={item.studentId}
                  data-fieldname='attendanceStatus'
                  data-type='select'
                  onChange={this.studentChangeHandler}
                />
                {item.attendanceStatus==='其他' && (
                  <Form.Input
                    control={Input}
                    fluid
                    value={item.remark||''}
                    data-studentid={item.studentId}
                    data-fieldname='remark'
                    data-type='text'
                    onChange={this.studentChangeHandler}
                  />
                )}
              </Form.Group>
          ):item.attendanceStatus
        )}, cellClassName: 'table-cell-with-dropdown' },
        {
          headerName: '備註',
          width: 6,
          cellRender: (item)=>(this.state.canEdit?(
            <Input
              fluid
              value={item.studentRemark||''}
              data-studentid={item.studentId}
              data-fieldname='studentRemark'
              data-type='text'
              onChange={this.studentChangeHandler}
            />
          ):item.studentRemark)
        },
      ];
      
    }catch(err){if(err!=='unmounted')console.log('Error when fetching info', err);}
  }
  
  studentChangeHandler = (event,data) => {
    const {students} = this.state;
    const {studentid, fieldname, type} = event.target.closest('.ui').dataset;
    const index = findIndex(students, {studentId: Number.isInteger(+studentid)?+studentid:studentid});
    if(index < 0)return;
    const value = inputHandler(type, data);
    const student = {...students[index], [fieldname]: value};
    if(fieldname === 'attendanceStatus' && value !== '其他'){
      student.remark = null;
    }
    this.setStateAsync({
      students: students.slice(0,index).concat(student, students.slice(index+1)),
      isBlocking: true
    });
  }
  
  quit = () => {
    const {section, outsideActivityId, yearId} = this.props.match.params;
    this.mounted = false;
    if (outsideActivityId) {
      this.props.history.push(`/eca/outsideActivity/${section}/detail/${yearId}/${outsideActivityId}`);
    } else {
      this.props.history.push(`/eca/outsideActivity/${section}`);
    }
  }

  save = async () => {
    try{
      await this.setStateAsync({
        dimmerOpen: true,
        finishedLoading: false
      })
    }catch(err){if(err!=='unmounted')console.log('Error when fetching info', err);}
    try{
      const result = await post('editOutsideActivityAttendance', {
        students: this.state.students
      });
      if(result && result.status){
        await this.setStateAsync({isBlocking: false});
        this.quit();
        return;
      }
      throw result;
    }catch(err){
      if(err!=='unmounted'){
        alert(`儲存${this.state.activity?this.state.activity.name:"外出帶隊"}點名時發生錯誤`);
        this.setStateAsync({
          dimmerOpen: false,
          finishedLoading: true
        });
        console.log('Error when saving outside activity attendance info', err);
      }
    }
  }

  render = () => {
    const {dimmerOpen, activity, students, finishedLoading, isBlocking, canEdit} = this.state;
    return (
      <>
        {dimmerOpen && <FullscreenDimmer active={true} isLoading={true} />}
        <Grid stackable doubling>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title={'編輯點名'+(activity?` - ${activity.yearName} ${activity.name}`:'')} />
            </Grid.Column>
          </Grid.Row>
  
          <Grid.Row>
            <Grid.Column>
              <Form>
                <MyTable
                  tableColumnData={this.tableColumnData}
                  data={students}
                  noDataMsg="找不到活動學生資料"
                  tableProps={this.tableProps}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
  
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Buttons.CancelButton disabled={!finishedLoading} onClick={this.quit} />
              <Buttons.SaveButton disabled={!finishedLoading || !canEdit} onClick={this.save} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <BlockerPrompt isBlocking={isBlocking}/>
      </>
    );
  }
}
export default withRouter(OutsideActivityStudents);