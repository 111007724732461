import {find, pick} from 'lodash';
import moment from 'moment';

/* import react */
import React, { Component, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import {
  PageHeader,
  ErrorLabel,
  ConfirmModal,
  FullscreenDimmer,
  FileInputInstantUpload,
  DateInput,
  MySortableTable,
  MySelect
} from '../../Components';


/* import helper functions */
import { get, post } from '../../Helper/ApiHelper';
import { defaultYear, selectOptions } from '../../Helper/Helper';
import { inputHandler } from '../../Helper/FormHelper';
import { momentToDate, dobToMoment } from '../../Helper/TimeHelper';
import { floatRight } from '../../Helper/StyleHelper';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Modal, Form, Button, Segment, Checkbox, Input } from 'semantic-ui-react';
import { excelFormats } from '../../Const/Const';


const fieldList = [
  [
    {
      required: false, //20200826 per Wong sir
      InputComponent: Input,
      type: 'text',
      label: 'STRN',
      valProp: 'strn',
    },
    {
      required: true, //20200826 per Wong sir
      InputComponent: Input,
      type: 'text',
      label: '學生註冊編號',
      valProp: 'regNo',
      inputProps: props=>({
        disabled: !!props.id && !!props.regNo,
      })
    },
  ],
  [
    {
      InputComponent: Input,
      label: '中文姓名',
      type: 'text',
      valProp: 'chiName',
      inputProps: {
        fluid: true
      }
    },
    {
      required: true,
      InputComponent: Input,
      label: '英文姓名',
      type: 'text',
      valProp: 'engName',
      inputProps: {
        fluid: true
      }
    },
    {
      required: true,
      InputComponent: Input,
      label: '電郵',
      type: 'text',
      valProp: 'email',
      validatorString: 'email',
      inputProps: {
        fluid: true
      }
    },
  ],
  [
    {
      required: true,
      InputComponent: MySelect,
      label: '性別',
      type: 'select',
      valProp: 'gender',
      inputProps: {
        options:[{ text: 'M', value: 'M' }, { text: 'F', value: 'F' }],
        fluid: true
      }
    },
    {
      required: true,
      InputComponent: DateInput,
      label: '出生日期',
      type: 'date',
      valProp: 'dob',
      postProcessing: momentToDate,
      inputProps: {
        fluid: true
      }
    },
    {
      required: true,
      InputComponent: MySelect,
      label: '班別',
      type: 'select',
      valProp: 'classCode',
      inputProps: props=>({
        options: props.classOption,
        search: true,
        fluid: true
      })
    },
    {
      required: true,
      InputComponent: Input,
      label: '學號',
      type: 'number',
      valProp: 'classNo',
      inputProps: {
        fluid: true
      }
    },
  ]
];

const EditStudentModal = (props) => {
  const {close} = props;

  const validator = useRef(new SimpleReactValidator({
    element: message => <ErrorLabel message={message} />,
    messages: {
      required: '請輸入:attribute',
      email: '請輸入正確電郵格式',
    }
  }));

  const [, forceUpdate] = useState();

  const {inputChange, theStudent: student} = props;

  const validate = () => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate(1);
      return;
    }
    props.onConfirm(student);
  }

  return (
    <Modal
      open
      onClose={close}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>請輸入學生資料</Modal.Header>
      <Modal.Content>
        <Segment basic>
          <Form>
            {fieldList.map((row,i,a)=>(<Form.Group key={i} widths={row.length} className={i+1===a.length?'':'form-group-margin'}>{
              row.map(({required, InputComponent, label, type, postProcessing, valProp, validatorString, inputProps})=>{
                const validatorArr = [];
                if(required) validatorArr.push('required');
                if(validatorString) validatorArr.push(validatorString);
                return (<Form.Field key={valProp} {...{required}}>
                  {label!==undefined && (<label>{label}</label>)}
                  <InputComponent
                    value={postProcessing?postProcessing(student[valProp]):(student[valProp]||'')}
                    data-input-type={type}
                    data-state-name={valProp}
                    onChange={inputChange}
                    placeholder={label}
                    {...(typeof inputProps==='function'?inputProps(props):inputProps)}
                  />
                  {!!validatorArr.length && validator.current.message(label, student[valProp], validatorArr.join('|'))}
                </Form.Field>)
              })
            }</Form.Group>))}
          </Form>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' content='取消' icon='cancel' data-modalname='isEditModalOpen' onClick={close} circular />
        <Button color='green' content='儲存' icon='save' onClick={validate} circular />
      </Modal.Actions>
    </Modal>
  )
}

class Student extends Component {
  constructor(props) {
    super(props);

    this.template = {
      regNo: null,
      strn: null,
      email: null,
      chiName: null,
      engName: null,
      gender: null,
      dob: null,
      classCode: null,
      classNo: null,
    }

    this.state = {

      finishedLoading: false,
      dimmerOpen: false,

      year: [],
      yearInfo: [],
      yearId: '',

      studentInfo: [],
      classOption: [],

      editInfo: {},
      isEditModalOpen: false,
      isInactivateModalOpen: false,
      // isDeleteModalOpen: false,

      theStudent: this.template,

      showHidden: false,
      readOnly: true,

      filterStr: '',

      tableHeader:  [
        { show: true, headerName: 'STRN', width: 3, allowSort: true, cellRender: 'strn' },
        { show: true, headerName: '學生註冊編號', width: 3, allowSort: true, cellRender: 'regNo' },
        { show: true, headerName: '中文姓名', width: 3, allowSort: true, cellRender: 'chiName' },
        { show: true, headerName: '英文姓名', width: 4, allowSort: true, cellRender: 'engName' },
        { show: true, headerName: '班別', width: 2, allowSort: true, cellRender: 'classCode', sortMethod: 'classId' },
        { show: true, headerName: '學號', width: 2, allowSort: true, cellRender: 'classNo', sortMethod: x=>+x.classNo },
        { show: true, headerName: '性別', width: 2, allowSort: true, cellRender: 'gender' },
        { show: true, headerName: '出生日期', width: 3, allowSort: true, cellRender: x=>momentToDate(x.dob), sortMethod: 'dob' },
        { show: true, headerName: '電郵', width: 4, allowSort: true, cellRender: 'email' },
        { show: true, headerName: '行動', width: 3, cellClassName: 'textlessCell', cellRender: x => (
          x.status ? (
            <>
              <Button
                color='blue'
                icon='edit'
                data-id={x.id}
                data-modalname='isEditModalOpen'
                onClick={this.modalToggle}
                circular
              />
              <Button
                color='orange'
                icon='eye slash'
                data-id={x.id}
                data-modalname='isInactivateModalOpen'
                onClick={this.modalToggle}
                circular
              />
            </>
          ) : (
            <>
              <Button
                color='blue'
                icon='edit'
                disabled
                circular
              />
              <Button
                color='green'
                icon='eye'
                data-id={x.id}
                data-modalname='isActivateModalOpen'
                onClick={this.modalToggle}
                circular
              />
            </>
          )
        )},
      ],
      
      genRowClassName: (x => x.status?"":"hidden-row")
    }
    this.tableProps = {unstackable: true, compact: true}
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if (this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  /**
   * Get Student Info
   */
  getStudentInfo = async () => {
    try {
      await this.setStateAsync({studentInfo: null});
      const studentInfo = await get(`getStudent/${+this.state.yearId}`);
      studentInfo.forEach(x => { x.dob = dobToMoment(x.dob) });
      await this.setStateAsync({studentInfo});
    } catch (err) {
      if(err!=='unmounted') console.log("Error when retrieving student info", err);
    }
  }

  getClassInfo = async () => {
    try {
      const rawClass = await get('getClass');
      this.setStateAsync({ classOption: selectOptions(rawClass, 'name', 'name') });
    } catch (err) {
      if(err!=='unmounted') console.log("Error when retrieving class info", err);
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.fetch();
    await this.setStateAsync({ finishedLoading: true });
  }

  fetch = async () => {
    try {
      //get year info
      const yearInfo = await get('getYear');
      const yearId = defaultYear(yearInfo);
      const year = selectOptions(yearInfo, 'displayName', 'id');  
      
      const curYear = find(yearInfo, { id: yearId });

      await this.setStateAsync({
        year, yearId, yearInfo, readOnly: curYear ? new Date(curYear.endDate) < new Date() : true
      });
      await this.refreshTableHeader();

      await Promise.all([this.getStudentInfo(), this.getClassInfo()]);
    } catch (err) {
      if(err!=='unmounted') console.log("Error when fetching", err);
    }
  }

  refreshTableHeader = async () => {
    const {tableHeader} = this.state;
    try{
      await this.setStateAsync({
        tableHeader: tableHeader.slice(0,tableHeader.length-1).concat({...tableHeader[tableHeader.length-1], show: !this.state.readOnly})
      })
    }catch(err){}
  }

  /* input update handler */
  inputChange = (event, data) => {
    const dataObj = {};
    let stateName = '', inputType='text', value = null;
    if(data['data-input-type'] !== 'date'){
      const info = event.target.closest('.ui, button, textarea').dataset;
      inputType = info.inputType;
      stateName = info.stateName;
    }else{
      inputType = data['data-input-type'] ;
      stateName = data['data-state-name'];
    }
    value = dataObj[stateName] = inputHandler(inputType, data);

    const d = pick(this.state, ['filterStr', 'showHidden']);

    switch(stateName){
      case 'showHidden':
      case 'filterStr':
        d[stateName] = value;
        d.filterStr = d.filterStr.toUpperCase();
        if(d.showHidden)
          dataObj.genRowClassName = x => {
            if(`${x.strn}\r${x.chiName}\r${x.engName}\r${x.classCode}${x.classNo}`.toUpperCase().indexOf(
              d.filterStr
            ) === -1) return "hidden-row";
            return x.status?"":"left-school"
          };
        else{
          dataObj.genRowClassName = x => {
            if(`${x.strn}\r${x.chiName}\r${x.engName}\r${x.classCode}${x.classNo}`.toUpperCase().indexOf(
              d.filterStr
            ) === -1) return "hidden-row";
            return x.status?"":"hidden-row";
          }
        }
        this.setStateAsync(dataObj);
        break;
      default: 
        this.setStateAsync({theStudent: {...this.state.theStudent, [stateName]: value}});
        break;
    }
  }

  /* year change */
  yearChange = async (event, { value }) => {
    /* fetch students of that year */
    const { yearId, yearInfo } = this.state;
    if (yearId !== value) {
      const curYear = find(yearInfo, { id: value });
      await this.setStateAsync({
        finishedLoading: false,
        yearId: value,
      });
      await this.getStudentInfo();
      await this.setStateAsync({
        finishedLoading: true,
        readOnly: curYear?new Date(curYear.endDate) < new Date():true
      });
      await this.refreshTableHeader();
    }
  }

  /* modal toggle */
  modalToggle = (eventOrStateName) => {
    let modalData = {};
    if(typeof eventOrStateName === 'object'){
      if(eventOrStateName.target.classList.contains('modals')){
        modalData = eventOrStateName.target.firstElementChild.dataset;
      }else{
        modalData = eventOrStateName.target.closest('.ui, button').dataset;
      }
    }else{
      modalData = {
        modalname: eventOrStateName
      }
    }
    const {modalname, id} = modalData;
    const data = {
      [modalname]: !this.state[modalname],
    };

    let theStudent = {};

    if (!this.state[modalname] && id) {
      const { studentInfo } = this.state;
      theStudent = {...find(studentInfo, { id: +id })};
    }else{
      theStudent = {...this.template}
    }

    this.setStateAsync({
      ...data,
      theStudent,
    });
  }

  /* save student */
  save = async (d) => {
    const {
      yearId,
    } = this.state;

    const data = Object.assign({}, {
      ...d,
      yearId,
      status: 1, //default shown
      dob: momentToDate(d.dob)
    });

    try {
      await this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('editStudent', data);
      if (result && result.status) {
        await this.getStudentInfo();
        this.modalToggle('isEditModalOpen');
      }else{
        throw result;
      }
    } catch (err) {
      if(err!=="unmounted"){
        alert(`無法儲存學生${typeof err === 'string'?'：'+err:''}`);
        console.log("Error when saving student", err);
      }
    }

    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
    });
  }

  activate = async () => {
    try {
      this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('editStudent/', { id: this.state.theStudent.id, status: 1 });
      if(result && result.status){
        await this.getStudentInfo();
      }else{
        throw result;
      }
    } catch (err) {
      if(err!=='unmounted'){
        alert('將學生設為在校時發生錯誤');
        console.log("Error when activating student", err);
      }
    }
    this.modalToggle('isActivateModalOpen');
    this.setStateAsync({ finishedLoading: true, dimmerOpen: false });
  }

  inactivate = async () => {
    try {
      this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
      const result = await post('editStudent/', { id: this.state.theStudent.id, status: null });
      if(result && result.status){
        await this.getStudentInfo();
      }else{
        throw result;
      }
    } catch (err) {
      if(err!=='unmounted'){
        alert('將學生設為退校時發生錯誤');
        console.log("Error when inactivating student", err);
      }
    }
    this.modalToggle('isInactivateModalOpen');
    this.setStateAsync({ finishedLoading: true, dimmerOpen: false });
  }

  /* delete student */
  // delete = async () => {
  //   try {
  //     this.setStateAsync({ finishedLoading: false, dimmerOpen: true });
  //     const result = await post('deleteStudent', { id: this.state.theStudent.id });
  //     if(result && result.status){
  //       await this.getStudentInfo();
  //     }else{
  //       throw result;
  //     }
  //   } catch (err) {
  //     if(err!=='unmounted'){
  //       alert('刪除學生時發生錯誤');
  //       console.log("Error when deleting student", err);
  //     }
  //   }
  //   this.modalToggle('isDeleteModalOpen');
  //   this.setStateAsync({ finishedLoading: true, dimmerOpen: false });
  // }

  fileHandling = async (xlsFile) => {
    //file parse
    const reader = new FileReader();
    try{
      const XLSX = await import('xlsx');
      const result = await new Promise((res,rej)=>{
        reader.onload = (evt) => { //evt = on_file_select event
          /* Parse data */
          const binaryString = evt.target.result;
          const workBook = XLSX.read(binaryString, { type: 'binary' });
          /* Get first worksheet, object {[cellAddress]: value} */
          const wsname = workBook.SheetNames[0];
          const data = workBook.Sheets[wsname];
          this.handleXLSX(data).then(res).catch(err=>{rej(err);});
        };
        reader.onerror = (evt) => {
          console.log('err', evt);
          rej('讀取檔案時發生錯誤');
        }
        reader.readAsBinaryString(xlsFile);
      });
      return result;
    }catch(err){
      return {status: false, err}
    }
  }

  handleXLSX = async (rawData, mode) => {
    const colMap = {
      regNo: 'A',
      strn: 'F',
      classCode: 'D',
      classNo: 'E',
      chiName: 'H',
      engName: 'G',
      gender: 'I',
      dob: 'J',
      email: 'K',
    };
    const {yearId} = this.state;
    const rowCount = +rawData['!ref'].match(/\d+$/g);
    const data = {
      yearId,
      data: [],
      mode
    };
    const emailSet = new Set(), regNoSet = new Set();
    //1 is for title
    for (let i = 2; i <= rowCount; i++) {
      if (!rawData['A' + i]) { break; }
      if(!rawData['K'+i]){
        throw `列#${i}電郵無效`;
      }
      
      if(regNoSet.has(rawData['A'+i].w)){
        throw `列#${i}學生註冊編號重複`;
      }
      regNoSet.add(rawData['A'+i].w);

      if(emailSet.has(rawData['K'+i].w)){
        throw `列#${i}電郵重複`;
      }
      emailSet.add(rawData['K'+i].w);

      const dataRow = {};
      //strn
      Object.entries(colMap).forEach((field) => {
        try {
          // tolerates dd|d/mm|m/yyyy in string or number format.
          if(rawData[field[1] + i].t==="n" && field[0] === "dob"){
            dataRow[field[0]] = momentToDate(moment.unix((rawData[field[1] + i].v - 25569)*24*60*60));
          }else{
            dataRow[field[0]] = rawData[field[1] + i].w;
          }
        } catch (err) {
          dataRow[field[0]] = "";
        }
      });
      dataRow.status = '1';
      data.data.push(dataRow);
    }
    try{
     const response = await post('batchEditStudent/', data);
     if(response && response.status){
       this.getStudentInfo(yearId);
     }
     return response;
    }catch(err){
     console.log('Err caught finally', err);
    }
  }

  render() {
    const {
      year,
      yearId,
      id,
      isEditModalOpen,
      isActivateModalOpen,
      isInactivateModalOpen,
      // isDeleteModalOpen,

      showHidden,
      readOnly,
      tableHeader,
      filterStr,

      studentInfo,
      genRowClassName,

      theStudent,
      classOption,

      finishedLoading,
      dimmerOpen,
    } = this.state;
    
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <PageHeader title='學生管理' subTitle='設定每年度之學生資料' />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MySelect
                disabled={!finishedLoading}
                options={year}
                compact
                value={yearId}
                onChange={this.yearChange}
              />
              <Checkbox
                disabled={!finishedLoading}
                label='顯示中途離校學生'
                checked={showHidden}
                onChange={this.inputChange}
                data-input-type='checkbox'
                data-state-name='showHidden'
                className="margin-left-1rem"
              />
              <Input
                disabled={!finishedLoading}
                value={filterStr}
                onChange={this.inputChange}
                data-input-type='text'
                data-state-name='filterStr'
                placeholder='篩選學生'
                className="margin-left-1rem"
              />
              {!readOnly && (<>
                <Button as='a' href='/download/student_sample.xlsx' download color='orange' content='下載學生檔案範本' icon='download' floated='right' circular />
                <FileInputInstantUpload
                  disabled={!finishedLoading}
                  buttonColor='green'
                  buttonText='上載學生檔案'
                  formatErrorText='請上載正確格式(xlsx)'
                  accept = {excelFormats}
                  wrapperStyle={floatRight}
                  fileHandling={this.fileHandling}
                />
                <Button
                  disabled={!finishedLoading}
                  color='green'
                  content='新增學生'
                  icon='add'
                  floated='right'
                  data-modalname='isEditModalOpen'
                  onClick={this.modalToggle}
                  circular
                />
              </>)}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MySortableTable
                data={studentInfo}
                tableColumnData={tableHeader}
                genRowClassName={genRowClassName}
                finishedLoading={finishedLoading}
                tableProps={this.tableProps}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {dimmerOpen ? (<FullscreenDimmer active={dimmerOpen} isLoading={true} />) : (
          <>
            {isEditModalOpen && (<EditStudentModal
              classOption={classOption}
              theStudent={theStudent}
              data-modalname='isEditModalOpen'
              close={this.modalToggle}
              inputChange={this.inputChange}
              onConfirm={this.save}
            />)}

            <ConfirmModal modalname='isActivateModalOpen' open={isActivateModalOpen} description='確定將學生轉回為「非退校」並預設顯示？' cancel={this.modalToggle} confirm={this.activate} confirmText='取消退校' confirmIcon='eye' />
            <ConfirmModal modalname='isInactivateModalOpen' open={isInactivateModalOpen} description='確定將學生轉為「已退校」並預設隱藏？' cancel={this.modalToggle} confirm={this.inactivate} confirmText='退校' confirmIcon='eye slash' />
            {/* <ConfirmModal modalname='isDeleteModalOpen' open={isDeleteModalOpen} description='確定刪除學生？本操作不可逆轉！' cancel={this.modalToggle} confirm={this.delete} /> */}
          </>
        )}
      </>
    )
  }
}

export default withRouter(Student);