/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {find, pick, flatMap} from 'lodash';

/* import components */
import {
	PageHeader,
	FullscreenDimmer,
	ConfirmModal,
	BlockerPrompt,
	FlexibleTextField,
	ConfirmButtonAndModal,
	MySelect,
} from '../../../Components';

/* import helper functions */
import { get, post } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import {
  threeYearPlanAndWhichYearFromYear,
  selectOptions,
  extractStartYear,
  whetherReadOnlyForTYP
} from '../../../Helper/Helper';

/* import semantic-ui element */
import { Grid, Button, Form, Segment, Header, Table } from 'semantic-ui-react';

class SchoolReview extends Component {
  constructor(props) {
    super(props);
    this.eightIndicators = [
      ['sm', '學校管理'],
      ['pl', '專業領導'],
      ['ca', '課程和評估'],
      ['lt', '學生學習和教學'],
      ['ss', '學生支援'],
      ['sp', '學校夥伴'],
      ['ab', '態度和行為'],
      ['pa', '參與和成就'],
    ];

    this.swop = [
      ['s', '強項'],
      ['w', '弱項'],
      ['o', '契機'],
      ['p', '危機'],
    ]

    this.default = {};

    this.eightIndicators.forEach(([x])=>{
      this.default[x+'Pro'] = this.default[x+'Con'] = '';
    });

    'swop'.split('').forEach(([x])=>{
      this.default[x] = '';
    });

    this.state = {
      finishedLoading: false,
      isSaveModalOpen: false,
      isBlocking: false,
      readOnly: true,
      dimmerOpen: false,

      TYPs: [],
      typOptions: [],
      selectedTYP: '',
      ...this.default
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount = async () => {
    this.mounted = true;
    try{
      await this.fetch();
      await this.setStateAsync({
        finishedLoading: true,
      });
    }catch(err){
      console.log('Error when fetching 3 year Review & SWOP');
      console.log(err);
    }
  }

  setStateAsync = (state) => (
    new Promise((res, rej) => {
      if(this.mounted)
        this.setState(state, res)
      else
        rej('unmounted');
    })
  )

  fetch = async () => {
    try{
      const [yearInfo, TYPs] = await Promise.all([get('getYear'), get('getTYP')]);
      let {selectedTYP, whichYear} = this.state;
  
      if(!selectedTYP&&!whichYear){
        [selectedTYP, whichYear] = threeYearPlanAndWhichYearFromYear(yearInfo, TYPs);
      }
  
      await this.setStateAsync({
        typOptions: selectOptions(TYPs, typ => {
          const startYear = extractStartYear(find(yearInfo, { id: typ.beginYear }));
          return `${startYear}-${startYear + 3}`;
        }, "id"),
        selectedTYP,
        whichYear,
      });
  
      const threeYearPlanData = await get('getTYPWithDetails/' + this.state.selectedTYP);

      if(threeYearPlanData.length){
        await this.setStateAsync({
          ...threeYearPlanData[0],
          readOnly: whetherReadOnlyForTYP(yearInfo, TYPs, this.state.selectedTYP)
        });
      }else{
        alert("三年計劃不存在，請前往 1.1 檢查資料是否出現錯誤");
      }

    }catch(err){
      if(err!=='unmounted'){
        console.log('Error when fetching 3 year Review & SWOT',err);
      }
    }
  }

  save = async () => {
    try{
      await this.setStateAsync({
        finishedLoading: false,
        dimmerOpen: true,
      });
      const fields = flatMap(this.eightIndicators,([field,])=>[field+'Pro',field+'Con']).concat('s','w','o','p');

      const data = pick(this.state, fields);
      if(this.state.selectedTYP){
        data.id = this.state.selectedTYP;
      }
      
      for(let x of fields){
        if(data[x])
          data[x] = data[x].replace(/^[\r\n\s\uFEFF\xA0]+|[\r\n\s\uFEFF\xA0]+$/g,'');
        else
          data[x] = '';
      }
      
      const result = await post('editTYP', data);
      if(result && result.status){
        await this.fetch();
      }else{
        throw(result);
      }
    }catch(err){
      if(err!=='unmounted'){
        alert("儲存三年校情檢視時發生錯誤");
        console.log('Error when saving 3 year Review & SWOT', err);
      }
    }
    this.saveModalToggle();
    this.setStateAsync({
      finishedLoading: true,
      dimmerOpen: false,
      isBlocking: false
    });
  }

  /* modal toggle */
  saveModalToggle = () => {
    this.setStateAsync({
      isSaveModalOpen: !this.state.isSaveModalOpen
    });
  }

  /* input update handler */
  inputChange = (event, data) => {
    const fieldName = event.target.dataset.fieldname;
    this.setStateAsync({
      [fieldName]: inputHandler('text', data),
      isBlocking: true
    })
  }

  changeSelectedTYP = async (_,data) => {
    if(this.state.isBlocking){
      if(!window.confirm("您尚未儲存您的資料，真的要轉換三年計劃嗎？")){
        return;
      }
    }
    try{
      let value = inputHandler('select', data);
      await this.setStateAsync({
        selectedTYP: value,
        finishedLoading: false,
      })
      await this.fetch();
    }catch(err){
      console.log('Error when changing selected TYP');
      console.log(err);
    }
    this.setStateAsync({
      finishedLoading: true,
      isBlocking: false,
    });
  }

  fetchPrevData = async () => {
    return await post('copyTYP', {typId: this.state.selectedTYP});
  }

  render() {
    const {
      isSaveModalOpen,
      finishedLoading,
      isBlocking,
      readOnly,
      dimmerOpen,

      typOptions,
      selectedTYP,
    } = this.state;

    return (<>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PageHeader title='三年計劃 - 校情檢視' subTitle='輸入學校表現指標及強弱機危' />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Segment padded>
              <Form>
                <Form.Field inline>
                  <label>三年計劃</label>
                  <MySelect
                    disabled={!finishedLoading}
                    options={typOptions}
                    value={selectedTYP}
                    onChange={this.changeSelectedTYP}
                  />
                  <ConfirmButtonAndModal
                    buttonProps={{
                      floated: 'right',
                      disabled: readOnly
                    }}
                    onConfirm={this.fetchPrevData}
                    onFinish={this.fetch}
                  />
                </Form.Field>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Form>
              <Header as='h3'>八大指標</Header>

              <Table celled unstackable fixed padded={readOnly}>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell width={2}>指標</Table.HeaderCell>
                    <Table.HeaderCell width={7}><abbr title="(按Enter會自動新增另一點)">主要優點</abbr></Table.HeaderCell>
                    <Table.HeaderCell width={7}><abbr title="(按Enter會自動新增另一點)">尚需改善的地方</abbr></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.eightIndicators.map(([fieldName, title]) => (
                    <Table.Row key={title}>
                      <Table.HeaderCell width={2} textAlign="center">
                        <Header as='h4'>{title}
                        </Header>
                      </Table.HeaderCell>
                      <Table.Cell>
                        <Form.Field>
                          <FlexibleTextField
                            value={this.state[fieldName + 'Pro']}
                            data-fieldname={fieldName+'Pro'}
                            readOnly={readOnly}
                            onChange={this.inputChange}

                            disabled={!finishedLoading}
                            multiline={true}
                            ul={true}
                            rows={4}
                          />
                        </Form.Field>
                      </Table.Cell>
                      <Table.Cell>
                        <Form.Field>
                          <FlexibleTextField
                            value={this.state[fieldName + 'Con']}
                            data-fieldname={fieldName+'Con'}
                            readOnly={readOnly}
                            onChange={this.inputChange}

                            disabled={!finishedLoading}
                            multiline={true}
                            ul={true}
                            rows={4}
                          />
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              
              <Header as='h3'>強弱機危<Header.Subheader>(按Enter會自動新增另一點)</Header.Subheader></Header>
              <Segment padded>
                <Form.Group>
                  {this.swop.slice(0,2).map(([fieldName, title], i) => (
                    <Form.Field width={9-(i%2)*2} key={fieldName}>
                      <label>{title}</label>
                      <FlexibleTextField
                        value={this.state[fieldName]}
                        data-fieldname={fieldName}
                        readOnly={readOnly}
                        onChange={this.inputChange}

                        disabled={!finishedLoading}
                        multiline={true}
                        ul={true}
                        rows={8}
                      />
                    </Form.Field>
                  ))}
                </Form.Group>
                <Form.Group>
                  {this.swop.slice(2).map(([fieldName, title], i) => (
                    <Form.Field width={9-(i%2)*2} key={fieldName}>
                      <label>{title}</label>
                      <FlexibleTextField
                        value={this.state[fieldName]}
                        data-fieldname={fieldName}
                        readOnly={readOnly}
                        onChange={this.inputChange}

                        disabled={!finishedLoading}
                        multiline={true}
                        ul={true}
                        rows={8}
                      />
                    </Form.Field>
                  ))}
                </Form.Group>
              </Segment>
              {!readOnly && (<Segment>
                <Form.Field
                  control={Button}
                  color="green"
                  onClick={this.saveModalToggle}
                  disabled={!finishedLoading}
                >儲存</Form.Field>
              </Segment>)}
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {dimmerOpen?(<FullscreenDimmer active={dimmerOpen} isLoading={true} />):<ConfirmModal
        open={isSaveModalOpen}
        description='確定儲存三年週期校情資料？'
        cancel={this.saveModalToggle}
        confirm={this.save}
        confirmIcon='check'
        confirmText='儲存'
      />}
      <BlockerPrompt isBlocking={isBlocking}/>
    </>)
  }
}

export default withRouter(SchoolReview);