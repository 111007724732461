/* import lodash */
import { forEach, compact } from 'lodash';

/* array to tree package */
import arrayToTree from 'array-to-tree';

export const  buildTree = (data) => {
    const idArray = data.map(({id}) => {
        return id;
    })

    let processedData = compact(data.map((row) => {
        if(row.parentId && idArray.indexOf(row.parentId) < 0) {
            return null;
        }
        return row
    }));

    return arrayToTree(processedData, {parentProperty: 'parentId'});
}

export const treeLayer = (tree) => {
    let layer = 0;
    forEach(tree, ({children}) => {
        if(layer < 1) {
            layer += 1;
        }

        if(children) {
            layer += treeLayer(children);
        }
    })
    return layer;
}

export const treeLength = (tree) => {
    let length = 0;
    forEach(tree, ({children}) => {
        if(children) {
            length += treeLength(children);
        }else{
            length += 1;
        }
    })
    return length;
}