import React, {useMemo} from 'react';
import { Form } from 'semantic-ui-react';

import { MyTable, MySelect, Buttons } from '../../../Components/';


/* KEEP THEM UNDEFINED */
const ActivityStudentTable = ({ add, remove, edit, data, studentOptions, leaveMethodOptions}) => {

  const tableFooterRender = useMemo(() => () => (
    <Buttons.AddButton onClick={add}/>
  ), [add]);

  const tableColumnData = useMemo(() => [
    { headerName: '姓名', width: 8, cellClassName: 'table-cell-with-dropdown', cellRender: x=>(
      <Form.Field
        control={MySelect}
        fluid
        data-item-id={x.id}
        data-inputtype='select'
        data-propname='studentId'
        onChange={edit}
        value={x.studentId || ''}
        options={studentOptions}
        search
        noResultsMessage="找不到學生，請檢查年級"
        placeholder='學生'
      />
    ), allowSort: true },
    { headerName: '放學方式', width: 5, cellClassName: 'table-cell-with-dropdown', cellRender: x=>(
      <Form.Group widths='equal' className='form-group-no-margin'>
        <Form.Field
          control={MySelect}
          fluid
          data-item-id={x.id}
          data-inputtype='select'
          data-propname='leaveMethodId'
          onChange={edit}
          value={x.leaveMethodId || ''}
          options={leaveMethodOptions}
          placeholder='狀態'
        />
        {x.leaveMethodId === 4 &&
          <Form.Input
            type='text'
            fluid
            data-item-id={x.id}
            data-inputtype='text'
            data-propname='remark'
            onChange={edit}
            value={x.remark || ''}
            placeholder='請註明'
          />
        }
      </Form.Group>
    ), allowSort: false },
    { headerName: '刪除', width: 2, cellRender: x=>(<Buttons.DeleteButton
      data-item-id={x.id}
      onClick={remove}
    />), allowSort: false },
  ], [edit, remove, leaveMethodOptions, studentOptions]);
  return (
    <MyTable tableColumnData={tableColumnData} data={data} tableFooterRender={tableFooterRender}/>
  )
}

export default ActivityStudentTable;