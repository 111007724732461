/* import react */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* import components */
import { PageHeader, ErrorLabel, ConfirmModal, FullscreenDimmer, EmptyTableMsg } from '../../../Components';

/* import helper */
import { get, post, ping } from '../../../Helper/ApiHelper';
import { inputHandler } from '../../../Helper/FormHelper';
import { selectOptionsWithCombineName } from '../../../Helper/Helper';
import { momentToDate, htmlInputDate } from '../../../Helper/TimeHelper';
import { exportExcel } from '../../../Helper/ExcelHelper';

import { fromBlankAsync } from 'xlsx-populate';

/* import moment */
import moment from 'moment';

/* Appraisal css */
import '../../../Styles/Appraisal.css';

/* import lodash */
import { filter, find, orderBy, map, uniq, flattenDeep, difference } from 'lodash';

/* import form validator */
import SimpleReactValidator from 'simple-react-validator';

/* import semantic-ui element */
import { Grid, Segment, Button, Modal, Form, Label, Table, Divider, Header, Checkbox } from 'semantic-ui-react';

/* import const */
import { excelFormats } from '../../../Const/Const';

class EditForm extends Component {
	constructor(props) {
		super(props);
		ping(this);

		this.state = {
			dimmerOpen: true,

			yearId: this.props.match.params.yearId,
			appraisalItemId: this.props.match.params.appraisalItemId,
			appraisalItem: {},
			appraisalTarget: [],

			teacher: [],
			teacherOptions: [],
			appraisalGroup: [],
			appraisalGroupOptions: [],

			isEditModalOpen: false,
			isBatchModalOpen: false,
			isDeleteModalOpen: false,

			target: {},
			selectedTeacher: [],
			selectedGroup: [],
			markingTeacher: [],
			filterTeacher: [],

			subjectOptions: [],
			classOptions: [],

			sortColumn: ['ascending', null, null, null, null, null],

			tableHeader: [
				{ displayName: '編號', columnName: 'index' },
				{ displayName: '被評老師', columnName: 'name' },
				{ displayName: '評估科目', columnName: 'subject' },
				{ displayName: '評估班別', columnName: 'className' },
				{ displayName: '評估老師', columnName: 'markingTeacher' },
				{ displayName: '遞交限期', columnName: 'deadline' },
			],

			batchHeader: ['被評老師名稱*', '遞交限期(日/月/年)*', '評估科目', '評估班別', '評分小組名稱', '個別評分老師'],
		};

		this.fileInputRef = React.createRef();

		// form validator
		this.validator = new SimpleReactValidator({
			autoForceUpdate: this,
			element: (message) => <ErrorLabel message={message} />,
			messages: {
				min: '百分比必需為0-100%',
				max: '百分比必需為0-100%',
				default: '請輸入資料',
			},
		});
	}

	//get appraisal target
	fetch = async () => {
		const { yearId, appraisalItemId } = this.state;
		const [
			appraisalItem,
			teacher,
			appraisalTarget,
			appraisalMarketingTeacher,
			appraisalGroup,
			appraisalGroupTeacher,
			subject,
			classInfo,
		] = await Promise.all([
			get('getAppraisalItem/' + appraisalItemId),
			get('getAllTeacherWithAdminWithIndex/' + yearId),
			get('getAppraisalFormTarget/' + appraisalItemId),
			get('getAppraisalFormTargetMarketingTeacher/' + appraisalItemId),
			get('getAllAppraisalGroup/' + yearId),
			get('getAllAppraisalGroupTeacher/' + yearId),
			get('getSubject'),
			get('getClass'),
		]);

		map(appraisalGroup, ({ id }, index) => {
			appraisalGroup[index]['teachers'] = orderBy(filter(appraisalGroupTeacher, { appraisalGroupId: id }), 'index');
		});
		map(appraisalTarget, ({ id, teacherId }, index) => {
			const teacherRecord = find(teacher, { id: teacherId });
			appraisalTarget[index]['index'] = teacherRecord ? teacherRecord.index : null;
			appraisalTarget[index]['name'] = teacherRecord ? teacherRecord.name : null;

			let markingTeacherReocrd = filter(appraisalMarketingTeacher, { appraisalTargetId: id });
			map(markingTeacherReocrd, ({ teacherId }, index) => {
				const teacherRecord = find(teacher, { id: teacherId });
				markingTeacherReocrd[index]['index'] = teacherRecord ? teacherRecord.index : null;
				markingTeacherReocrd[index]['name'] = teacherRecord ? teacherRecord.name : null;
			});

			appraisalTarget[index]['markingTeacher'] = orderBy(markingTeacherReocrd, 'index');
		});

		this.setState({
			appraisalItem: appraisalItem.length > 0 ? appraisalItem[0] : {},
			appraisalTarget: orderBy(appraisalTarget, 'index'),
			teacher,
			teacherOptions: selectOptionsWithCombineName(teacher, ['index', 'name'], 'id'),
			appraisalGroup: orderBy(appraisalGroup, 'name'),
			appraisalGroupOptions: selectOptionsWithCombineName(appraisalGroup, ['name'], 'id'),
			subjectOptions: selectOptionsWithCombineName(subject, ['displayName'], 'displayName'),
			classOptions: selectOptionsWithCombineName(classInfo, ['name'], 'name'),
		});
	};

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidMount = async () => {
		this.mounted = true;
		await this.fetch();
		this.setState({ dimmerOpen: false });
	};

	/* table sorting */
	sorting = (index = null) => {
		const { appraisalTarget, sortColumn, tableHeader } = this.state;
		let column = [],
			order = [],
			newDirection = null;

		if (index >= 0) {
			if (sortColumn[index] === null) newDirection = 'ascending';
			if (sortColumn[index] === 'ascending') newDirection = 'descending';
			sortColumn[index] = newDirection;
		}

		map(sortColumn, (direction, index) => {
			if (direction) {
				column.push(tableHeader[index]['columnName']);
				order.push(direction === 'ascending' ? 'asc' : 'desc');
			}
		});
		this.setState({ sortColumn, appraisalTarget: orderBy(appraisalTarget, column, order) });
	};

	/* modal toggle */
	modalToggle = (modalStateName, id = null) => () => {
		const { appraisalTarget } = this.state;
		let record = find(appraisalTarget, { id });
		let target = {
			appraisalTargetId: id,
			appraisalItemId: this.props.match.params.appraisalItemId,
			deadline: id ? htmlInputDate(record.deadline) : htmlInputDate(),
			teacherId: id ? record.teacherId : '',
			subject: id ? record.subject : '',
			className: id ? record.className : '',
		};
		let markingTeacher = id
			? record.markingTeacher.map(({ index, teacherId, count, displayName, percentage }) => {
					return {
						index,
						teacherId,
						count: Boolean(count),
						isPrincipal: displayName === '校長',
						percentage,
					};
			  })
			: [];
		let filterTeacher = id ? map(record.markingTeacher, 'teacherId') : [];

		this.setState({
			target,
			markingTeacher,
			filterTeacher,
			selectedTeacher: [],
			selectedGroup: [],
			[modalStateName]: !this.state[modalStateName],
		});
	};

	/* Input change */
	inputChange = (inputType, stateName) => (event, data) => {
		let value = inputHandler(inputType, data);
		this.setState({ [stateName]: value });
	};

	targetChange = (inputType, stateName) => (event, data) => {
		this.setState((prevState) => ({
			target: {
				...prevState.target,
				[stateName]: inputHandler(inputType, data),
			},
		}));
	};

	/* add marking teacher */
	addMarkingTeacher = () => {
		const {
			selectedTeacher,
			selectedGroup,
			markingTeacher,
			filterTeacher,
			teacher,
			appraisalGroup,
			target,
		} = this.state;
		let combinedTeacher = [selectedTeacher];

		if (selectedGroup.length > 0) {
			map(selectedGroup, (appraisalGroupId) => {
				const group = find(appraisalGroup, { id: appraisalGroupId });
				combinedTeacher.push(map(group.teachers, 'teacherId'));
			});
		}

		combinedTeacher = difference(uniq(flattenDeep(combinedTeacher)), filterTeacher);

		if (combinedTeacher.length > 0) {
			map(combinedTeacher, (teacherId) => {
				if (teacherId !== target.teacherId) {
					const teacherData = find(teacher, ['id', teacherId]);

					let isPrincipal = teacherData.displayName === '校長' ? true : false;
					let percentage = isPrincipal && markingTeacher.length > 0 ? 50 : 100;

					markingTeacher.push({
						teacherId,
						count: true,
						isPrincipal,
						percentage,
					});

					filterTeacher.push(teacherId);
				}
			});

			this.setState({
				selectedTeacher: [],
				selectedGroup: [],
				markingTeacher: [...markingTeacher],
				filterTeacher: [...filterTeacher],
			});
		}

		this.calculatePercentage();
	};

	/* delete marking teacher */
	deleteMarkingTeacher = (index, teacherId) => () => {
		const { markingTeacher, filterTeacher } = this.state;
		let filterTeacherIndex = filterTeacher.indexOf(teacherId);
		markingTeacher.splice(index, 1);
		filterTeacher.splice(filterTeacherIndex, 1);
		this.setState({
			markingTeacher: [...markingTeacher],
			filterTeacher: [...filterTeacher],
		});

		this.calculatePercentage();
	};

	/* change marking teacher property */
	changeMarkingTeacher = (index, property) => (event, data) => {
		const { markingTeacher } = this.state;
		markingTeacher[index]['count'] = inputHandler('checkbox', data);
		this.setState({ markingTeacher: [...markingTeacher] });
		this.calculatePercentage();
	};

	calculatePercentage = () => {
		const { markingTeacher } = this.state;

		let principalPercentage = 0;
		if (filter(markingTeacher, { count: true, isPrincipal: true }).length > 0) {
			principalPercentage = filter(markingTeacher, { count: true }).length > 1 ? 50 : 100;
		}

		let teacherPercentage =
			(100 - principalPercentage) / filter(markingTeacher, { count: true, isPrincipal: false }).length;

		map(markingTeacher, ({ count, isPrincipal }, index) => {
			if (isPrincipal) {
				markingTeacher[index]['percentage'] = principalPercentage;
			} else {
				markingTeacher[index]['percentage'] = count ? teacherPercentage : 0;
			}
		});

		this.setState({ markingTeacher: [...markingTeacher] });
	};

	save = async (event) => {
		event.preventDefault();

		if (!this.validator.allValid()) {
			this.validator.showMessages();
			return;
		}

		const { target, markingTeacher } = this.state;
		this.setState({ dimmerOpen: true });
		let edit = await post('editAppraisalFormTarget', { target, markingTeacher });
		if (edit.status) {
			await this.fetch();
			this.validator.hideMessages();
			this.forceUpdate();
			this.setState({
				isEditModalOpen: false,
				dimmerOpen: false,
			});
		}
	};

	delete = async () => {
		this.setState({ dimmerOpen: true });
		let remove = await post('deleteAppraisalTarget', { id: this.state.target.appraisalTargetId });
		if (remove.status) {
			await this.fetch();
			this.setState({
				isDeleteModalOpen: false,
				dimmerOpen: false,
			});
		}
	};

	downloadBatchTemplate = async () => {
		const { appraisalItem, batchHeader } = this.state;
		const filename = `${appraisalItem.yearName}${appraisalItem.displayName} - 批量新增`;
		const workbook = await fromBlankAsync();
		const sheet = workbook.sheet(0);

		sheet.cell('A1').value([batchHeader]);
		sheet.column('A').width(20);
		sheet.column('B').width(20);
		sheet.column('C').width(20);
		sheet.column('D').width(20);
		sheet.column('E').width(20);
		sheet.column('F').width(20);

		sheet.usedRange().style({
			fontFamily: '標楷體',
			horizontalAlignment: 'center',
			verticalAlignment: 'center',
		});

		const file = await workbook.outputAsync();
		exportExcel(file, filename);
	};

	upload = () => {
		this.fileInputRef.current.click();
	};

	processBatch = (event) => {
		const file = inputHandler('file', event);
		if (!file) return;

		const reader = new FileReader();
		reader.onload = async (e) => {
			this.setState({ dimmerOpen: true });
			const XLSX = await import('xlsx');
			const workbook = XLSX.read(e.target.result, { type: 'array', cellDates: true });
			const worksheet = workbook.Sheets[workbook.SheetNames[0]];
			const header_data = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });
			const header = header_data[0];

			if (JSON.stringify(header) !== JSON.stringify(this.state.batchHeader)) {
				alert('內容格式錯誤，請下載範本再作修改');
				this.setState({ dimmerOpen: false });
				return;
			} else {
				const { appraisalItemId, teacher, appraisalGroup } = this.state;
				const excel_data = XLSX.utils.sheet_to_json(worksheet, {
					header: 1,
					range: 1,
					blankrows: false,
					dateNF: 'DD/MM/YYYY',
				});
				let batchData = [];

				map(excel_data, ([teacherName, deadline, subject, className, groupName, markingTeacherName]) => {
					teacherName = teacherName ? teacherName.trim() : null;
					subject = subject ? subject.trim() : null;
					className = className ? className.trim() : null;
					groupName = groupName ? groupName.trim() : null;
					markingTeacherName = markingTeacherName ? markingTeacherName.trim() : null;

					const teacherRecord = find(teacher, { name: teacherName });
					const groupRecord = find(appraisalGroup, { name: groupName });
					const markingTeacherRecord = find(teacher, { name: markingTeacherName });

					if (teacherRecord && (groupRecord || markingTeacherRecord) && deadline) {
						let target = {
							appraisalTargetId: null,
							appraisalItemId: appraisalItemId,
							deadline: moment(deadline).add(1, 'days').format('YYYY-MM-DD'),
							teacherId: teacherRecord.id,
							subject,
							className,
						};

						let markingTeacher = [];
						if (groupRecord) {
							map(groupRecord.teachers, ({ teacherId }) => {
								if (teacherId !== teacherRecord.id) {
									let markingTeacherRecord = find(teacher, { id: teacherId });
									let isPrincipal = markingTeacherRecord.displayName === '校長' ? true : false;
									let percentage = isPrincipal && markingTeacher.length > 0 ? 50 : 100;
									markingTeacher.push({
										teacherId,
										count: true,
										isPrincipal,
										percentage,
									});
								}
							});
						}

						if (markingTeacherRecord) {
							if (markingTeacherRecord.id !== teacherRecord.id) {
								let isPrincipal = markingTeacherRecord.displayName === '校長' ? true : false;
								let percentage = isPrincipal && markingTeacher.length > 0 ? 50 : 100;
								markingTeacher.push({
									teacherId: markingTeacherRecord.id,
									count: true,
									isPrincipal,
									percentage,
								});
							}
						}

						let principalPercentage = 0;
						if (filter(markingTeacher, { count: true, isPrincipal: true }).length > 0) {
							principalPercentage = filter(markingTeacher, { count: true }).length > 1 ? 50 : 100;
						}

						let teacherPercentage =
							(100 - principalPercentage) / filter(markingTeacher, { count: true, isPrincipal: false }).length;
						map(markingTeacher, ({ count, isPrincipal }, index) => {
							if (isPrincipal) {
								markingTeacher[index]['percentage'] = principalPercentage;
							} else {
								markingTeacher[index]['percentage'] = count ? teacherPercentage : 0;
							}
						});

						batchData.push({ target, markingTeacher });
					}
				});

				if (batchData.length > 0) {
					let batchInsert = await post('batchInsertAppraisalFormTarget', { appraisalItemId, batchData });
					if (batchInsert.status) {
						await this.fetch();
						this.setState({
							isBatchModalOpen: false,
							dimmerOpen: false,
						});
					}
				} else {
					this.setState({ dimmerOpen: false });
				}
			}
		};
		reader.readAsArrayBuffer(file);
	};

	displayTargetModal = () => {
		this.validator.purgeFields();
		const {
			isEditModalOpen,
			target,
			selectedTeacher,
			selectedGroup,
			teacher,
			markingTeacher,
			filterTeacher,
			teacherOptions,
			appraisalGroupOptions,
			subjectOptions,
			classOptions,
		} = this.state;
		return (
			<Modal
				open={isEditModalOpen}
				onClose={this.modalToggle('isEditModalOpen')}
				closeOnEscape={false}
				closeOnDimmerClick={false}
			>
				<Modal.Header>新增被評老師</Modal.Header>
				<Modal.Content>
					<Segment basic>
						<Form>
							<Header color="blue" as="h2" content="基本資料" />
							<Form.Group widths="2">
								<Form.Select
									className="form-group-margin"
									value={target.teacherId}
									onChange={this.targetChange('select', 'teacherId')}
									options={filter(teacherOptions, (teacher) => {
										return filterTeacher.indexOf(teacher.value) < 0;
									})}
									label="被評老師*"
									placeholder="被評老師*"
									search
									noResultsMessage="找不到老師"
									disabled={target.appraisalTargetId ? true : false}
									error={this.validator.message('teacherId', target.teacherId, 'required')}
								/>
								<Form.Input
									className="form-group-margin"
									type="date"
									onChange={this.targetChange('text', 'deadline')}
									value={target.deadline}
									label="遞交限期*"
									placeholder="遞交限期*"
									error={this.validator.message('deadline', target.deadline, 'required')}
								/>
							</Form.Group>

							<Form.Group widths="2">
								<Form.Select
									className="form-group-margin"
									value={target.subject}
									onChange={this.targetChange('select', 'subject')}
									options={subjectOptions}
									label="評估科目"
									placeholder="評估科目"
									noResultsMessage="找不到科目"
									search
									clearable
								/>

								<Form.Select
									className="form-group-margin"
									value={target.className}
									onChange={this.targetChange('select', 'className')}
									options={classOptions}
									label="評估班別"
									placeholder="評估班別"
									noResultsMessage="找不到班別"
									search
									clearable
								/>
							</Form.Group>

							<Divider />

							<Header color="blue" as="h2" content="評分老師" />
							<Form.Group style={{ alignItems: 'flex-end' }}>
								<Form.Select
									className="form-group-margin"
									value={selectedTeacher}
									onChange={this.inputChange('select', 'selectedTeacher')}
									options={filter(teacherOptions, (teacher) => {
										return teacher.value !== target.teacherId && filterTeacher.indexOf(teacher.value) < 0;
									})}
									label="搜尋老師"
									placeholder="搜尋老師"
									noResultsMessage="找不到老師"
									width="7"
									search
									multiple
								/>
								<Form.Select
									className="form-group-margin"
									value={selectedGroup}
									onChange={this.inputChange('select', 'selectedGroup')}
									options={appraisalGroupOptions}
									label="搜尋評分小組"
									placeholder="搜尋評分小組"
									noResultsMessage="找不評分小組"
									width="7"
									search
									multiple
								/>
								<Form.Button
									className="form-group-margin text-center"
									onClick={this.addMarkingTeacher}
									color="green"
									content="新增"
									width="2"
								/>
							</Form.Group>
							<Segment basic className="overflowX">
								<Table textAlign="center" columns={4} celled selectable unstackable>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>評分老師</Table.HeaderCell>
											<Table.HeaderCell collapsing>計分</Table.HeaderCell>
											<Table.HeaderCell collapsing>所佔百份比（%）</Table.HeaderCell>
											<Table.HeaderCell collapsing>刪除</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{markingTeacher.length > 0 ? (
											<Fragment>
												{markingTeacher.map(({ teacherId, count, percentage }, index) => {
													const teacherData = find(teacher, ['id', teacherId]);
													return (
														<Table.Row key={teacherId}>
															<Table.Cell>
																{teacherData.index} {teacherData.name}
															</Table.Cell>
															<Table.Cell collapsing>
																<Form.Field
																	onChange={this.changeMarkingTeacher(index)}
																	checked={count}
																	control={Checkbox}
																	toggle
																/>
															</Table.Cell>
															<Table.Cell collapsing>
																<Fragment>{percentage.toFixed(2)}%</Fragment>
															</Table.Cell>
															<Table.Cell collapsing>
																<Button
																	onClick={this.deleteMarkingTeacher(index, teacherId)}
																	className="align-bottom-button"
																	color="red"
																	icon="delete"
																	circular
																/>
															</Table.Cell>
														</Table.Row>
													);
												})}
											</Fragment>
										) : (
											<EmptyTableMsg colSpan="4" />
										)}
									</Table.Body>
								</Table>
								<div className="text-center form-group-margin">
									{this.validator.message('markingTeacher', markingTeacher, 'required|min:1,array')}
								</div>
							</Segment>
						</Form>
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" content="取消" icon="cancel" onClick={this.modalToggle('isEditModalOpen')} circular />
					<Button color="green" content="儲存" icon="save" onClick={this.save} circular />
				</Modal.Actions>
			</Modal>
		);
	};

	displayBatchModal = () => {
		const { isBatchModalOpen } = this.state;
		return (
			<Modal
				open={isBatchModalOpen}
				onClose={this.modalToggle('isBatchModalOpen')}
				closeOnEscape={false}
				closeOnDimmerClick={false}
			>
				<Modal.Header>批量新增被評老師</Modal.Header>
				<Modal.Content>
					<Segment basic>
						<div>
							<ul>
								<li>
									請以<b>範本格式</b>製作上載檔案
								</li>
								<li>
									老師名稱請填寫<b>全名</b>
								</li>
								<li>
									遞交限期請以<b>日/月/年</b>格式填寫，例如: 2-12-2020
								</li>
								<li>
									<b>*為必需填寫</b>，資料不全的行數將不會輸入系統
								</li>
								<li>
									評分老師請輸入評分小組名稱，或輸入<b>一位</b>評分老師名稱，不適用欄位可不用填寫。
								</li>
								<li>
									此功能只適用於新增被評老師，<b>不能</b>刪除或修改系統現有內容
								</li>
							</ul>
						</div>
						<br />
						<Button
							className="button-margin-bottom"
							color="green"
							content="上載"
							icon="upload"
							onClick={this.upload}
							circular
						/>
						<input hidden type="file" ref={this.fileInputRef} onChange={this.processBatch} accept={excelFormats} />
						<Button color="blue" content="下載範本" icon="download" onClick={this.downloadBatchTemplate} circular />
					</Segment>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" content="返回" icon="cancel" onClick={this.modalToggle('isBatchModalOpen')} circular />
				</Modal.Actions>
			</Modal>
		);
	};

	render() {
		const { dimmerOpen, appraisalItem, appraisalTarget, isDeleteModalOpen, sortColumn, tableHeader } = this.state;
		return (
			<Fragment>
				{dimmerOpen ? (
					<FullscreenDimmer active={dimmerOpen} isLoading={true} />
				) : (
					<Fragment>
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<PageHeader title="編輯評估對象" prevPage={() => this.props.history.push('/appraisal/target')} />
								</Grid.Column>
							</Grid.Row>

							{appraisalItem && (
								<Fragment>
									<Grid.Row>
										<Grid.Column>
											<div>
												<Segment.Group>
													<Segment className="bold larger-font">
														<Grid columns={2} stackable>
															<Grid.Row verticalAlign="middle">
																<Grid.Column>
																	{appraisalItem.yearName} : {appraisalItem.displayName}
																</Grid.Column>
																<Grid.Column textAlign="right">
																	<Button
																		onClick={this.modalToggle('isBatchModalOpen')}
																		color="green"
																		icon="upload"
																		content="批量新增"
																		circular
																	/>
																	<Button
																		onClick={this.modalToggle('isEditModalOpen')}
																		color="green"
																		icon="add"
																		content="新增"
																		circular
																	/>
																</Grid.Column>
															</Grid.Row>
														</Grid>
													</Segment>
													<Segment padded="very" secondary className="overflowX">
														<Table textAlign="center" sortable selectable celled unstackable>
															<Table.Header>
																<Table.Row>
																	{tableHeader.map(({ displayName }, index) => {
																		return (
																			<Table.HeaderCell
																				key={index}
																				sorted={sortColumn[index]}
																				onClick={() => this.sorting(index)}
																			>
																				{displayName}
																			</Table.HeaderCell>
																		);
																	})}
																	<Table.HeaderCell>行動</Table.HeaderCell>
																</Table.Row>
															</Table.Header>
															<Table.Body>
																{appraisalTarget.length > 0 ? (
																	<Fragment>
																		{appraisalTarget.map(
																			({ id, deadline, index, name, subject, className, markingTeacher }) => {
																				return (
																					<Table.Row key={id}>
																						<Table.Cell>{index}</Table.Cell>
																						<Table.Cell>{name}</Table.Cell>
																						<Table.Cell>{subject || '-'}</Table.Cell>
																						<Table.Cell>{className || '-'}</Table.Cell>
																						<Table.Cell>
																							{markingTeacher.map(({ teacherId, index, name }) => {
																								return (
																									<Label key={teacherId} className="teacherLabel">
																										{index} {name}
																									</Label>
																								);
																							})}
																						</Table.Cell>
																						<Table.Cell>{momentToDate(deadline)}</Table.Cell>
																						<Table.Cell>
																							<Button
																								color="blue"
																								icon="edit"
																								onClick={this.modalToggle('isEditModalOpen', id)}
																								circular
																							/>
																							<Button
																								color="red"
																								icon="delete"
																								onClick={this.modalToggle('isDeleteModalOpen', id)}
																								circular
																							/>
																						</Table.Cell>
																					</Table.Row>
																				);
																			}
																		)}
																	</Fragment>
																) : (
																	<EmptyTableMsg colSpan="7" />
																)}
															</Table.Body>
														</Table>
													</Segment>
												</Segment.Group>
											</div>
										</Grid.Column>
									</Grid.Row>
								</Fragment>
							)}
						</Grid>

						{this.displayTargetModal()}
						{this.displayBatchModal()}

						<ConfirmModal
							open={isDeleteModalOpen}
							description="確定刪除評估對象？"
							cancel={this.modalToggle('isDeleteModalOpen')}
							confirm={this.delete}
						/>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

export default withRouter(EditForm);
